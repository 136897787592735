<div class="columns-editor-dialog-container">
  <tup-mat-title-bar title="Add/remove columns">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose(false)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>

  <mat-divider></mat-divider>

  <snackbar-generic
    class="metric-column-dialog-error"
    *ngIf="noColumnsSelected"
    [data]="unselectedError"
  >
  </snackbar-generic>

  <mat-dialog-content>
    <div class="columns-dialog-container">
      <p class="group-name">
        <mat-checkbox
          id="select-all"
          color="primary"
          [checked]="allSelected"
          (change)="onSelectAll($event)"
        >
          Select All
        </mat-checkbox>
      </p>

      <div class="column-groups-container">
        <div
          class="no-group"
          fxFlex
          fxLayoutAlign="start"
          fxLayout="column"
        >
          <div class="column-group-columns">
            <ng-container
              *ngFor="let column of getColumnsWithoutGroup()"
              [ngTemplateOutlet]="columnItemTemplate"
              [ngTemplateOutletContext]="{
                column: column
              }"
            >
            </ng-container>
          </div>
        </div>

        <div
          class="column-group"
          fxFlex
          fxLayoutAlign="start"
          fxLayout="column"
          *ngFor="let colPosition of groupPositionList"
        >
          <div
            *ngFor="let colGroup of getGroupsByPosition(colPosition)"
            [attr.data-tracking]="'group-' + colGroup.name"
          >
            <ng-container
              [ngTemplateOutlet]="
                getColumnsByGroup(colGroup.name).length == 1
                  ? singleColumnGroupTemplate
                  : multipleColumnsGroupTemplate
              "
              [ngTemplateOutletContext]="{
                columns: getColumnsByGroup(colGroup.name),
                group: colGroup
              }"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-button
      (click)="onClose(false)"
    >
      Cancel
    </button>
    <div fxLayoutAlign="right">
      <button
        mat-button
        (click)="onResetToDefault()"
      >
        Reset to default
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="noColumnsSelected"
        (click)="onClose(true)"
      >
        Save changes
      </button>
    </div>
  </mat-dialog-actions>
</div>

<ng-template
  #multipleColumnsGroupTemplate
  let-columns="columns"
  let-group="group"
  let-groupName="group.name"
>
  <p class="group-name">
    <mat-checkbox
      color="primary"
      [checked]="selectedGroupColumnsCount[groupName].allSelected"
      [indeterminate]="selectedGroupColumnsCount[groupName].intermediate"
      (change)="onSelectAll($event, groupName)"
      [disabled]="group.disabled"
      [matTooltip]="group.disabled ? disabledTooltipMessage : ''"
      [attr.data-tracking]="'group-checkbox-' + groupName"
    >
      {{ groupName }}
    </mat-checkbox>
  </p>
  <div class="column-group-columns">
    <ng-container
      *ngFor="let column of columns"
      [ngTemplateOutlet]="
        (editEffReach && column.columnDef === 'effReach' && effReachChecked) ||
        (editEffReach && column.columnDef === 'effReachPct' && !effReachChecked)
          ? editEffReachTemplate
          : columnItemTemplate
      "
      [ngTemplateOutletContext]="{
        column: column
      }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template
  #columnItemTemplate
  let-column="column"
>
  <p class="column-item">
    <mat-checkbox
      [attr.data-tracking]="'checkbox-' + column.columnDef"
      color="primary"
      [checked]="column.visible"
      (change)="onColumnChanged($event, column)"
      [disabled]="column.disabled"
    >
      {{ column | appendUnits : unitsText }}
    </mat-checkbox>
    <mat-icon [matTooltip]="column.tooltip">info_outline</mat-icon>
  </p>
</ng-template>

<ng-template
  #editEffReachTemplate
  let-column="column"
>
  <div class="eff-reach-edit-marker">
    <div class="eff-reach-data">
      <ng-container
        *ngTemplateOutlet="columnItemTemplate; context: { column: column }"
      ></ng-container>
      <ng-container *ngTemplateOutlet="effReachForm"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #effReachForm>
  <div class="eff-reach-form-container">
    <div
      class="eff-reach-form"
      fxLayout="row"
      fxLayoutGap="16px"
    >
      <number-incremental
        data-tracking="eff-reach-from-value"
        [(value)]="effReachFromValue"
        [min]="1"
        [max]="effReachToValue ? effReachToValue - 1 : undefined"
        [suffix]="!effReachToValue || effReachToValue === 0 ? '+' : ''"
      ></number-incremental>

      <number-incremental
        data-tracking="eff-reach-to-value"
        [(value)]="effReachToValue"
        [min]="effReachFromValue + 1"
        [max]="28"
        [suffix]="!effReachToValue && !effReachToFocused ? '-' : ''"
      ></number-incremental>
    </div>
  </div>
</ng-template>

<ng-template
  #singleColumnGroupTemplate
  let-columns="columns"
>
  <p class="group-name">
    <mat-checkbox
      [attr.data-tracking]="'checkbox-' + columns[0].columnDef"
      color="primary"
      [checked]="columns[0].visible"
      (change)="onColumnChanged($event, columns[0])"
    >
      {{ columns[0].group.name }}
    </mat-checkbox>
    <mat-icon [matTooltip]="columns[0].tooltip">info_outline</mat-icon>
  </p>
</ng-template>
