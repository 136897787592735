import { Injectable } from '@angular/core';
import { NavigationLevel } from '../models/codebook.models';

export enum CacheType {
  targets = 'targets',
  media = 'media',
}

interface Cache {
  [key: string]: NavigationLevel;
}

const ROOT_LEVEL: NavigationLevel = {
  name: 'root',
  key: 'root',
  category: '',
  type: '',
  children: [],
};

@Injectable({
  providedIn: 'root',
})
export class CodebookCacheService {
  survey: { [code: string]: Cache } = {};

  constructor() {
    this.clear();
  }

  clear() {
    this.survey = {};
  }

  /**
   * Save navigation level data in the cache using the supplied key
   *
   * @param {string} key Unique key used store data under the correct parent
   * @param {cacheType} cacheType target tree data or vehicle tree data
   * @param {NavigationLevel[]} data array to save
   * @returns void
   */

  public put(
    key: string,
    cacheType: CacheType,
    data: NavigationLevel[],
    surveyCode: string,
    authGroup: string
  ): void {
    const cache = this.getCache(surveyCode, authGroup);

    // writing the top level (key is null)
    if (!key) {
      cache[cacheType] = {
        ...ROOT_LEVEL,
        children: data,
      };
      return;
    }

    const tree = cache[cacheType];
    const node = this.find(key, tree);
    if (node) {
      node.children = this.clone(data);
    }
  }

  /**
   * Retreive navigation level data from the cache using the supplied key
   *
   * @param {string} key Unique key used store data under the correct parent
   * @param {cacheType} cacheType target tree data or vehicle tree data
   * @param {NavigationLevel[]} data array to fetch
   * @returns {NavigationLevel[]} returns fetches data or an empty array if data not available
   */

  public get(
    key: string,
    cacheType: CacheType,
    surveyCode: string,
    authGroup: string
  ): NavigationLevel[] {
    const cache = this.getCache(surveyCode, authGroup);

    const tree = cache[cacheType];
    if (!key) return this.clone(tree.children); // fetch toplevel as key is null

    const node = this.find(key, tree);
    return node ? this.clone(node.children) : [];
  }

  private getCache(surveyCode: string, authGroup: string): Cache {
    const key = `${surveyCode}|${authGroup}`;
    const cache = this.survey[key];
    if (typeof cache === 'undefined') {
      this.survey[key] = {
        [CacheType.media]: ROOT_LEVEL,
        [CacheType.targets]: ROOT_LEVEL,
      };
    }
    return this.survey[key];
  }

  private clone(data: NavigationLevel[]): NavigationLevel[] {
    return data;
  }

  // fetch the data stored against the given key on the given tree
  private find(key: string, tree: NavigationLevel): NavigationLevel {
    const keys = key.split('|');
    let level: NavigationLevel = tree;

    let searchKey = '';
    for (let i = 0; i < keys.length; i++) {
      searchKey = keys.slice(0, i + 1).join('|');
      level = level.children.find((node) => node.key === searchKey);

      if (level && i === keys.length - 1) break;
    }
    return level;
  }
}
