import { DecimalPipe } from '@angular/common';
import { TreeTableColumn } from '../components/tree-table/tree-table.models';

export const Column_Reached_Exactly: TreeTableColumn = {
  header: 'Reached Exactly',
  style: { width: '100px' },
  columnDef: 'reachedExactly',
  columnType: 'number',
  showUnits: true,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.reachedExactly,
};

export const Column_Reached_Exactly_Pct: TreeTableColumn = {
  header: 'Reached Exactly %',
  style: { width: '100px' },
  columnDef: 'reachedExactlyPct',
  columnType: 'number',
  decimals: 2,
  pipe: { token: DecimalPipe as any, args: ['1.2-2'] },
  cell: (row) => row.data?.reachedExactlyPct,
};

export const Column_Reached_At_Least000: TreeTableColumn = {
  header: 'Reached At Least',
  style: { width: '100px' },
  columnDef: 'reachedAtLeast000',
  columnType: 'number',
  showUnits: true,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.reachedAtLeast000,
};

export const Column_Reached_At_Least_Pct: TreeTableColumn = {
  header: 'Reached At Least %',
  style: { width: '100px' },
  columnDef: 'reachedAtLeastPct',
  columnType: 'number',
  decimals: 2,
  pipe: { token: DecimalPipe as any, args: ['1.2-2'] },
  cell: (row) => row.data?.reachedAtLeastPct,
};

export const Column_Impressions_FD: TreeTableColumn = {
  header: 'Impressions',
  style: { width: '100px' },
  columnDef: 'impressionsFD',
  columnType: 'number',
  showUnits: true,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.impressionsFD,
};

export const frequencyDistributionColumns: TreeTableColumn[] = [
  Column_Reached_Exactly,
  Column_Reached_Exactly_Pct,
  Column_Reached_At_Least000,
  Column_Reached_At_Least_Pct,
  Column_Impressions_FD,
];

export const allFreqDistrChartTableColumns: TreeTableColumn[] = [
  Column_Impressions_FD,
  ...frequencyDistributionColumns.filter((column) =>
    column.columnDef.includes('Pct')
  ),
];
