import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Schedule } from 'src/app/classes/schedule';
import { Target } from 'src/app/classes/target';
import { TreeTableComponent } from '../tree-table/tree-table.component';
import {
  ColumnMenuClickEvent,
  NodeMenuClickEvent,
  TreeTableColumn,
  TreeTableEditEvent,
  TreeTableMenuItem,
  TreeTableNode,
} from '../tree-table/tree-table.models';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import {
  TupSimpleInputDialogModelOptions,
  TupUserMessageService,
} from '@telmar-global/tup-user-message';
import { PlanningValueProviderService } from 'src/app/services/planning-value-provider.service';
import {
  PlanningService,
  ProcessInputResponse,
} from 'src/app/services/planning.service';
import {
  DialogService,
  infoDialogTemplate,
} from 'src/app/services/dialog.service';
import {
  VehicleGroupAction,
  VehicleGroupsDialogModel,
} from '../dialogs/vehicle-groups-dialog/vehicle-groups-dialog.component';
import {
  VehicleGroup,
  VehicleGroupVehicle,
} from 'src/app/classes/vehicle-groups';
import { isNotNullOrUndefined } from 'src/app/pipes/pipeable-operators';
import { forkJoin, Observable, of } from 'rxjs';
import {
  Column_BaseCPM,
  Column_BuyingCPM,
  Column_BuyingCPP,
  Column_BuyingImpressions,
  Column_CPM,
  Column_CPP,
  Column_Duration,
  Column_EffReach,
  Column_EffReachPct,
  Column_EsgScore,
  Column_Group_Addressable,
  Column_Group_DirectMail,
  Column_GRPs,
  Column_Impressions,
  Column_Inserts,
  Column_NumberOfMailItems,
  Column_Reach000,
  Column_ReachPct,
  Column_Survey,
  Column_TotalCost,
  Column_UnitCost,
  columnsThatRetriggerCalculation,
  OPTIMISE_MENU_ITEM,
  READONLY_ROW,
} from 'src/app/models/planning-veh-columns.models';
import { ColumnGroup } from '../tree-table/column-group/table-group.models';
import { Sort } from '@angular/material/sort';
import { ScheduleTotalTag } from 'src/app/classes/schedule-total';
import { TargetVehicle } from 'src/app/classes/vehicle';
import { RowContents, RowType } from 'src/app/models/planning-columns.models';
import { AddAddressableDialogModel } from 'src/app/add-addressable-dialog/add-addressable-dialog.component';
import {
  RenameItemType,
  RenameMediaDialogModel,
} from '../dialogs/rename-media-dialog/rename-media-dialog.component';
import { cloneDeep } from 'lodash';
import { compareOptionKeys } from '../../models/planning.models';
import { MediatypeView } from 'src/app/steps/planning-step/planning-step.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { defaultIfEmpty, map, tap } from 'rxjs/operators';
import { VirtualScrollTableDirective } from '../tree-table/virtual-scroll/virtual-scroll-table.directive';
import {
  SpotplanQuickViewButton,
  SpotplanQuickViewModel,
} from '../dialogs/spotplan-quick-view-dialog/spotplan-quick-view-dialog.component';
import { Router } from '@angular/router';
import { QuestionDialogModelOptions } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { OptimiseService } from 'src/app/services/optimise.service';
import { EngineService } from 'src/app/services/engine.service';
import {
  dialogActionType,
  MixedDataDialogModel,
} from '../dialogs/import-mixed-data-dialog/import-mixed-data-dialog.component';
import { AppendUnitsPipe } from 'src/app/pipes/append-units.pipe';
import { EMPTY_RESULTS, ResultType } from 'src/app/classes/result';
import { MultiSurveyService } from '../../services/multi-survey.service';
import {
  FileType,
  MultiSurveyVehicleConfig,
} from 'src/app/models/multi-survey.models';
import {
  CreateMrfFileResponse,
  MrfCampaignInfo,
} from 'src/app/models/multi-survey-mrf.models';
import { TupAuthService } from '@telmar-global/tup-auth';

const INPUT_DIALOG_OPTIONS: TupSimpleInputDialogModelOptions = {
  useValidation: true,
  minAllowedStringLength: 1,
  useEnterToSubmit: true,
  cancelText: 'Cancel',
  confirmText: 'Apply',
  autoFocus: 'Focus',
  autoFocusDelay: 10,
};

enum EsgTooltipType {
  total,
  group,
  vehicle,
}

@Component({
  selector: 'compare-media',
  templateUrl: './compare-media.component.html',
  styleUrls: ['./compare-media.component.scss'],
})
export class CompareMediaComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('planningTable') planningTable: TreeTableComponent;

  // templates added to the table for additional functionality
  @ViewChild('totalsRow') totalsRow: TemplateRef<any>;
  @ViewChild('esgInfoIcon') esgInfoIcon: TemplateRef<any>;
  @ViewChild('addressableRow') addressableRow: TemplateRef<any>;
  @ViewChild('normalVehicleRow') normalVehicleRow: TemplateRef<any>;
  @ViewChild('broadcastRow') broadcastRow: TemplateRef<any>;
  @ViewChild('containsBroadcastRow') containsBroadcastRow: TemplateRef<any>;
  @ViewChild('importedMediaTypeRow') importedMediaTypeRow: TemplateRef<any>;

  @ViewChild(VirtualScrollTableDirective)
  virtualScrollTable: VirtualScrollTableDirective;

  @Input() schedule: Schedule;
  @Input() target: Target;
  @Input() unitsText: string;
  @Output() planUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addNewSchedule: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeActiveSchedule: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() multiSurveyPopulationChanged: EventEmitter<void> =
    new EventEmitter<void>();

  reload: EventEmitter<null> = new EventEmitter<null>();

  cellEditProcessing: boolean = false;
  _processing: boolean = false;
  @Input() set processing(value: boolean) {
    this._processing = value;
  }
  get processing(): boolean {
    return this._processing;
  }

  get targets(): Target[] {
    return this.mediaplannerService.plan.targets;
  }

  // title according to the total number of vehicles
  get treeTitleMedia(): string {
    return this.target?.vehicles?.length
      ? `Media (${this.target.vehicles.length})`
      : 'Media';
  }

  get schedules(): Schedule[] {
    return this.mediaplannerService.plan.schedules;
  }

  headerInlineMenu: TreeTableMenuItem[] = [
    { label: 'Add/remove columns', data: 'columns|edit', matIcon: 'view_week' },
    OPTIMISE_MENU_ITEM,
    { label: 'Save as new group', data: 'group|save', matIcon: 'library_add' },
    {
      label: 'Add to existing group',
      data: 'group|add',
      disabled: true,
      matIcon: 'ad_group',
    },
  ];

  vehicleGroupTitleInlineMenu: TreeTableMenuItem[] = [
    { label: 'Edit group', data: 'group|edit', matIcon: 'edit' },
    { label: 'Delete group', data: 'group|delete', matIcon: 'delete' },
  ];

  vehicleInlineMenu: TreeTableMenuItem[] = [
    { label: 'Rename media', data: 'vehicle|rename', matIcon: 'text_format' },
    {
      label: 'Duplicate media',
      data: 'vehicle|duplicate',
      matIcon: 'content_copy',
    },
    { label: 'Delete media', data: 'vehicle|delete', matIcon: 'delete' },
  ];

  vehicleIncAddressableInlineMenu: TreeTableMenuItem[] = [
    {
      label: 'Apply addressable audience',
      data: 'vehicle|addressable',
      disabled: false,
      matIcon: 'people',
    },
    {
      label: 'Rename media',
      data: 'vehicle|rename',
      disabled: false,
      matIcon: 'text_format',
    },
    {
      label: 'Duplicate media',
      data: 'vehicle|duplicate',
      disabled: false,
      matIcon: 'content_copy',
    },
    {
      label: 'Remove media',
      data: 'vehicle|delete',
      disabled: false,
      matIcon: 'delete',
    },
  ];

  vehicleGroupVehicleInlineMenu: TreeTableMenuItem[] = [
    {
      label: 'Remove from group',
      data: 'groupVehicle|delete',
      matIcon: 'ad_group_off',
    },
    {
      label: 'Move to another group',
      data: 'groupVehicle|move',
      matIcon: 'move_group',
    },
  ];

  mrfInlineMenu: TreeTableMenuItem[] = [
    { label: 'Rename', data: 'importedMedia|rename', matIcon: 'text_format' },
    {
      label: 'Preferences',
      data: 'importedMedia|preferences',
      matIcon: 'settings',
    },
    { label: 'Delete ', data: 'importedMedia|delete', matIcon: 'delete' },
  ];

  dauInlineMenu: TreeTableMenuItem[] = [
    {
      label: 'Change audience',
      data: 'importedMedia|change_audience',
      matIcon: 'autorenew',
    },
    ...this.mrfInlineMenu,
  ];

  manualInputInlineMenu: TreeTableMenuItem[] = [
    {
      label: 'Update manual entry',
      data: 'manualInput|update',
      matIcon: 'autorenew',
    },
    { label: 'Delete ', data: 'manualInput|delete', matIcon: 'delete' },
  ];

  appendUnits = new AppendUnitsPipe();

  get tableColumnsNumber() {
    return this.mediaplannerService.plan.columns
      .getVisibleColumns()
      .filter((val) => val.hidden !== true).length;
  }

  planningNodesDragSelected: TreeTableNode[] = [];
  vehicleData: TreeTableNode[];
  columns: TreeTableColumn[];
  columnGroups: ColumnGroup[];
  sortOrder: { [key: string]: Sort } = {};
  mediaTypes: MediatypeView[] = [];
  tableExpanded: boolean = true;
  anyGroups: boolean;
  optimisationMenuItemDisabled: boolean = false;

  constructor(
    private router: Router,
    private authService: TupAuthService,
    private mediaplannerService: MediaPlannerService,
    private userMessage: TupUserMessageService,
    private planningValueProviderService: PlanningValueProviderService,
    private planningService: PlanningService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private optimiseService: OptimiseService,
    private engineService: EngineService,
    private multiSurveyService: MultiSurveyService
  ) {}

  ngOnInit(): void {
    // subscribe to refreshing data being sent that requires immediate update
    this.reload.subscribe(() => {
      this.columns.forEach((column) => {
        if (column.columnDef === 'esgScore') {
          column.inlineTemplate = this.esgInfoIcon;
        }
      });
      this.populateTable();
    });
    this.multiSurveyService.multiSurveyStartProcessing.subscribe(
      (currentTab) => {
        if (currentTab === 'planning') {
          this.processing = true;
        }
      }
    );
    this.multiSurveyService.multiSurveyTargetSelected.subscribe((eventData) => {
      if (eventData.currentTab === 'planning') {
        if (eventData.refresh) {
          this.populateTable(); // needed when importing MRF only. ngOnChanges is triggered from multi-survey.service otherwise
        }
        this.multiSurveyPopulationChanged.emit();
        this.processing = false;
      }
    });
    this.columns = this.mediaplannerService.plan.columns.getVisibleColumns();
    this.columnGroups = this.mediaplannerService.plan.columns.columnGroups;
    this.migrateOldGroupData();
    this.anyGroups = !!this.columns.find((col) => col.group);
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const target = changes['target'];
    const schedule = changes['schedule'];

    if (
      this.planningTable &&
      ((target &&
        target.currentValue !== undefined &&
        target.previousValue !== undefined &&
        target.currentValue !== target.previousValue &&
        !target.firstChange) ||
        (schedule &&
          schedule.currentValue !== schedule.previousValue &&
          !schedule.firstChange))
    ) {
      this.processTableColumns();
      this.populateTable();
    }
  }

  ngOnDestroy() {
    this.reload.unsubscribe();
  }

  // main function for populating the tree table with everything
  private populateTable() {
    if (!this.target || !this.schedule) return;

    this.waitForCalculationParameters().subscribe(() => {
      this.mediaTypes = this.buildMediaTypeList();
      const vehicleData: TreeTableNode[] = [];

      this.planningNodesDragSelected = [];

      const target: Target = this.target;
      const schedule: Schedule = this.schedule;

      this.setVisibleColumnsBasedOnPlan();

      // add total to the top
      let valueData = this.mediaplannerService.plan.getTotalsPlanningData(
        'total',
        ScheduleTotalTag.total,
        target,
        schedule
      );
      let data = this.planningValueProviderService.getFormattedCellString(
        valueData,
        this.columns
      );

      vehicleData.push({
        name: `Total`,
        id: 'schedule|total',
        editable: this.getTotalsRowEditable(null),
        rowCss: 'table-total-row',
        css: 'table-total-row',
        cellCss: this.getVehicleCellCss(
          <string>data.esgScore,
          <string>data.esgStability
        ),
        tooltip: this.getTotalTooltips(data.esgStability, EsgTooltipType.total),
        inlineTemplate: this.totalsRow,
        data,
        children: [],
        skipSort: true,
      });

      // mark this as the schedule total row
      vehicleData[vehicleData.length - 1].data.contents = {
        type: RowType.scheduleTotal,
        id: 'total',
      };

      // build table data for vehicles that are not part of a user created group
      const mediaTypesWithoutCustomGroup = this.mediaTypes.filter(
        (mediaType) => !mediaType.customGroupId
      );
      this.populateMetricsData(
        vehicleData,
        mediaTypesWithoutCustomGroup,
        target,
        schedule
      );

      // add data for vehicles that are part of a user created group
      const mediaTypesWithCustomGroup = this.mediaTypes.filter(
        (mediaType) => mediaType.customGroupId
      );
      this.populateMetricsData(
        vehicleData,
        mediaTypesWithCustomGroup,
        target,
        schedule
      );

      this.performSort(vehicleData, 'planning');
      this.vehicleData = vehicleData;
      this.initialiseVirtualTable();
    });
  }

  private initialiseVirtualTable() {
    this.virtualScrollTable
      ? this.virtualScrollTable.viewportUpdated(true)
      : null;
  }

  // iterate through media types writing the total line for each
  populateMetricsData(
    vehicleData: TreeTableNode[],
    mediaTypes: MediatypeView[],
    target: Target,
    schedule: Schedule
  ) {
    // create a child for each media type / multi survey item
    mediaTypes.forEach((mediaType) => {
      const valueData = this.mediaplannerService.plan.getTotalsPlanningData(
        mediaType.name,
        [ScheduleTotalTag.mediatype, ScheduleTotalTag.multiSurvey],
        target,
        schedule
      );

      // search vehicles in this group that have dayparts
      const containsBroadcast = !!target.vehicles
        .filter((vehicle) => vehicle.mediaType === mediaType.name)
        .find((veh) => veh.dayparts && veh.dayparts.length);

      // media type total
      let data = this.planningValueProviderService.getFormattedCellString(
        valueData,
        this.columns
      );
      const mediaTypeTotal = target.vehicles.filter(
        (vehicle) => vehicle.mediaType === mediaType.name
      ).length;

      let mediaTypeInlineMenu = null;
      let mediaTypeInlineTemplate = null;
      let manualInput = false;
      let multiSurveyRowCss = '';

      if (mediaType.customGroupId)
        mediaTypeInlineMenu = this.vehicleGroupTitleInlineMenu;
      if (containsBroadcast)
        mediaTypeInlineTemplate = this.containsBroadcastRow;

      let mediaTypeName = mediaType.name;

      if (mediaType.isMultiSurvey) {
        multiSurveyRowCss = 'multi-survey-title-row';
        const multiSurveyVeh = target.vehicles.find(
          (vehicle) =>
            vehicle.mediaType === mediaType.name &&
            vehicle.isMultiSurvey &&
            vehicle.multiSurveyConfig
        );
        const multiSurveyConfig = multiSurveyVeh
          ? multiSurveyVeh.multiSurveyConfig
          : undefined;
        data['multiSurveyConfig'] = multiSurveyConfig;

        manualInput =
          multiSurveyConfig && multiSurveyVeh.multiSurveyConfig.manualInput;

        if (manualInput) {
          mediaTypeInlineMenu = this.manualInputInlineMenu;
          mediaTypeName = multiSurveyVeh.title;
        } else {
          const multiSurveyFile =
            this.mediaplannerService.plan.multiSurveys.multiSurvey(
              multiSurveyConfig.multiSurveyId
            );
          if (multiSurveyFile.fileType === FileType.DAU)
            mediaTypeInlineMenu = this.dauInlineMenu;
          if (multiSurveyFile.fileType === FileType.MRF)
            mediaTypeInlineMenu = this.mrfInlineMenu;
        }

        mediaTypeInlineTemplate = this.importedMediaTypeRow;
      }

      vehicleData.push({
        id: mediaType.customGroupId,
        editable: this.getTotalsRowEditable(mediaType),
        rowCss: `table-title-row ${multiSurveyRowCss}`,
        css: `table-title-row ${multiSurveyRowCss}`,
        cellCss: this.getVehicleCellCss(
          <string>data.esgScore,
          <string>data.esgStability
        ),
        tooltip: this.getTotalTooltips(data.esgStability, EsgTooltipType.group),
        name: `${mediaTypeName} ${manualInput ? '' : `(${mediaTypeTotal})`}`,
        inlineMenu: mediaTypeInlineMenu,
        inlineTemplate: mediaTypeInlineTemplate,
        expanded: this.tableExpanded,
        data,
        children: [],
      });

      // mark this as a mediatype totals row
      vehicleData[vehicleData.length - 1].data.contents = {
        type: RowType.mediatypeTotal,
        id: mediaType.name,
      };

      // add vehicles to each of the mediatypes
      this.getVehiclesForMediatype(
        vehicleData,
        target,
        schedule,
        mediaType.name,
        vehicleData[vehicleData.length - 1]
      );
    });
  }

  private processTableColumns() {
    const columns = this.columns;
    // re assign the cell function for rendering vehicle specific requirements in the planning table (WIP)
    const target: Target = this.target;
    columns.forEach((column) => {
      if (column.menu) {
        const optimiseIndex = column.menu.indexOf(OPTIMISE_MENU_ITEM);
        if (optimiseIndex !== -1) {
          column.menu[optimiseIndex].disabled =
            this.optimisationMenuItemDisabled;
        }
      }
      if (
        [Column_EffReach.columnDef, Column_EffReachPct.columnDef].includes(
          column.columnDef
        )
      ) {
        this.buildEffectiveReachHeader(column);
      }
      if (column.columnDef === Column_EsgScore.columnDef) {
        column.inlineTemplate = this.esgInfoIcon;
      }
      column.cell = (row: TreeTableNode) => {
        return row.data[column.columnDef];
      };
    });
  }

  setVisibleColumnsBasedOnPlan() {
    const autoUpdate = this.mediaplannerService.plan.columns.autoUpdateColumns;

    // show or hide DM and addressable groups depending if in the campaign or not
    const [showDirectMail, showAddressable] =
      this.mediaplannerService.containsAddressableMedia(this.target);

    // if autoUpdate always force show/hide addressable and DM
    // if not autoupdate, only force a hide

    if (autoUpdate || (!autoUpdate && !showAddressable)) {
      this.mediaplannerService.plan.columns.setColumnGroup(
        Column_Group_Addressable,
        showAddressable
      );
    }

    if (autoUpdate || (!autoUpdate && !showDirectMail)) {
      this.mediaplannerService.plan.columns.setColumnGroup(
        Column_Group_DirectMail,
        showDirectMail
      );
    }

    // only touch broadcast and esg if autoUpdate
    if (autoUpdate) {
      // show or hide the insertions column depending on if press or broadcast is in the campaign
      const surveyCode =
        this.mediaplannerService.plan.targets[0].documentTarget.survey.code;
      const pressOrBroadcast = this.mediaplannerService.plan.surveyMetaData
        .meta(surveyCode)
        .anyPressOrBroadcast(this.mediaplannerService.plan.targets[0].vehicles);
      this.mediaplannerService.plan.columns.setColumns(
        [Column_Inserts],
        pressOrBroadcast
      );

      // does the user have access to ESG score in the current survey
      const esgScore = !!this.target.survey.esgProviders?.length;
      this.mediaplannerService.plan.columns.setColumns(
        [Column_EsgScore],
        esgScore
      );
    }

    this.columns = this.mediaplannerService.plan.columns.getVisibleColumns();
  }

  buildEffectiveReachHeader(column: TreeTableColumn) {
    const { effectiveReach } = this.mediaplannerService.plan;
    if (effectiveReach) {
      // remove previous effReach details
      if (column.header.indexOf('(') !== -1) {
        column.header = column.header.substring(0, column.header.indexOf('('));
      }

      // if Effective Reach column is set to be visible, header must contain values chosen by the user
      if (column.visible) {
        // build new column header text with current effReach details
        let columnHeaderSuffix = effectiveReach.effectiveReachFrom.toString();
        columnHeaderSuffix += effectiveReach.effectiveReachTo
          ? ` - ${effectiveReach.effectiveReachTo}`
          : '+';
        column.header += ` (${columnHeaderSuffix})`;
      }
    }
  }

  // add vehicles to the Tree Table data as children of the supplied parent
  private getVehiclesForMediatype(
    vehicleData: TreeTableNode[],
    target: Target,
    schedule: Schedule,
    mediaType: string,
    parentNode: TreeTableNode
  ) {
    const children = parentNode.children || [];

    target.vehicles
      .filter(
        (vehicle) =>
          vehicle.mediaType === mediaType &&
          !vehicle.multiSurveyConfig?.manualInput
      )
      .forEach((vehicle) => {
        const vehIndex = vehicleData.findIndex((v) => v.id === vehicle.id);
        const vehData = this.mediaplannerService.plan.getVehiclePlanningData(
          target,
          schedule,
          vehicle
        );
        let data = this.planningValueProviderService.getFormattedCellString(
          vehData,
          this.columns,
          vehicle
        );

        data.metaData = data.metaData || {};
        data.metaData['originalTitle'] =
          vehicle.originalTitle !== vehicle.title ? vehicle.originalTitle : '';

        const isBroadcast = vehicle.dayparts && vehicle.dayparts.length;
        if (isBroadcast) {
          data.metaData['hasSpotplan'] = !!this.schedule.spotplans.findSpotplan(
            vehicle.id
          );
        }

        if (vehicle.addressable) {
          data.metaData['addressableActive'] = !!vehicle.addressableConfig;
          data.metaData['addressableTooltip'] = 'Addressable possible';

          if (vehicle.addressableConfig) {
            const tgt = this.targets.find(
              (t) => t.id === vehicle.addressableConfig.targetId
            );
            data.metaData['addressableTooltip'] = tgt
              ? `${tgt.title}`
              : 'configured';
          }
        }

        if (vehIndex !== -1) {
          children[vehIndex].data = vehData;
        } else {
          children.push({
            name: vehicle.title,

            nodeType: '',
            editable:
              this.planningValueProviderService.getVehicleEditable(vehicle),
            tooltip: this.getVehicleTooltips(vehicle, data.esgStability),
            cellCss: this.getVehicleCellCss(
              <string>data.esgScore,
              <string>data.esgStability
            ),
            tooltipCss: this.getVehicleTooltipsCss(vehicle),
            id: vehicle.id,
            data,
            checkbox: !vehicle.isMultiSurvey,
            inlineMenu: !vehicle.isMultiSurvey
              ? this.getVehicleInlineMenu(vehicle)
              : null,
            inlineTemplate: vehicle.addressable
              ? this.addressableRow
              : isBroadcast
              ? this.broadcastRow
              : this.normalVehicleRow,
          });
          children[children.length - 1].data.contents = {
            type: RowType.vehicle,
            id: vehicle.id,
          };
        }
      });
  }

  // return with the correct inline menu
  // addressable (unconfigured),  addressable(configured) or regular vehicle
  private getVehicleInlineMenu(vehicle: TargetVehicle): TreeTableMenuItem[] {
    let menu: TreeTableMenuItem[] = [];
    // vehicle is part of a custom group, so contains info related to its original media type
    if (vehicle.originalMediaType) {
      menu = this.vehicleGroupVehicleInlineMenu.filter(
        (val) =>
          !val.data.includes('groupVehicle|move') ||
          (val.data.includes('groupVehicle|move') &&
            this.mediaplannerService.plan.vehicleGroups.groups.length > 1)
      );
    }
    if (vehicle.addressable) {
      if (!vehicle.addressableConfig) {
        // temporary disable adding addressable if plan has more than 1 survey. Revisit after donor vehicle usecase is clarified
        const vehMenu = cloneDeep(this.vehicleIncAddressableInlineMenu);
        const addMenu = vehMenu.find((m) => m.data === 'vehicle|addressable');
        addMenu.disabled =
          this.mediaplannerService.plan.selectedSurveys &&
          this.mediaplannerService.plan.selectedSurveys.length > 1;
        addMenu.disabled
          ? (addMenu.tooltip =
              'This functionality is disabled at the moment if using more than one survey')
          : null;
        return menu.concat(vehMenu);
      }
      const addressableMenu = cloneDeep(this.vehicleIncAddressableInlineMenu);
      addressableMenu.find((m) => m.data === 'vehicle|addressable').label =
        'Edit addressable';

      const removeAddressableOption = {
        label: 'Remove addressable',
        data: 'vehicle|removeAddressable',
        disabled: false,
        matIcon: 'group_remove',
      };
      addressableMenu.splice(1, 0, removeAddressableOption);

      return menu.concat(addressableMenu);
    } else {
      return menu.concat(this.vehicleInlineMenu);
    }
  }

  private setEsgStabilityTooltip(
    tooltips: { [columnDef: string]: string },
    esgStability: string | number,
    tooltipType: EsgTooltipType
  ) {
    const regex = /(50-59|60-79|80-100)/;
    let message: string;

    switch (tooltipType) {
      case EsgTooltipType.total:
        message =
          'The ESG score for this total plan is ranked as having [HIGH/LOW] stability.';
        break;
      case EsgTooltipType.group:
        message =
          'The ESG score for this media group is ranked as having [HIGH/LOW] stability.';
        break;
      case EsgTooltipType.vehicle:
        message =
          'The ESG score for this media vehicle is ranked as having [HIGH/LOW] stability.';
        break;
    }

    tooltips[Column_EsgScore.columnDef] = regex.test(String(esgStability))
      ? message.replace('[HIGH/LOW]', 'high')
      : message.replace('[HIGH/LOW]', 'low');
  }

  private getTotalTooltips(
    esgStability: string | number,
    tooltipType: EsgTooltipType
  ) {
    const tooltips = {};
    if (esgStability) {
      this.setEsgStabilityTooltip(tooltips, esgStability, tooltipType);
    }
    return tooltips;
  }

  private getVehicleTooltips(
    vehicle: TargetVehicle,
    esgStability: string | number
  ) {
    const tooltips = {};
    if (esgStability) {
      this.setEsgStabilityTooltip(
        tooltips,
        esgStability,
        EsgTooltipType.vehicle
      );
    }

    if (vehicle.addressableConfig) {
      const addrMessage =
        'Addressable media can only be edited via the addressable planning section';
      tooltips[Column_Impressions.columnDef] = addrMessage;
      tooltips[Column_GRPs.columnDef] = addrMessage;
    }
    if (vehicle.survey.title) {
      tooltips[Column_Survey.columnDef] = vehicle.survey.title;
    }
    return tooltips;
  }

  private getVehicleTooltipsCss(vehicle: TargetVehicle) {
    const tooltipsCss = {};
    if (vehicle.addressableConfig) {
      tooltipsCss[Column_Impressions.columnDef] = 'addressable-tooltip active';
      tooltipsCss[Column_GRPs.columnDef] = 'addressable-tooltip active';
    }
    return tooltipsCss;
  }

  getVehicleCellCss(
    esgScore: string,
    esgStabilityCss: string
  ): { [columnDef: string]: string } {
    const cellCss = {};
    const score = parseInt(esgScore);
    if (!isNaN(score) && score !== -1) {
      cellCss[Column_EsgScore.columnDef] = esgStabilityCss;
    }

    return cellCss;
  }

  getTotalsRowEditable(mediaType: MediatypeView): {
    [columnDef: string]: boolean;
  } {
    const isTotal = !mediaType;
    const vehs = this.targets.length
      ? this.targets[0].vehicles.filter((vehicle) =>
          isTotal ? vehicle : vehicle.mediaType === mediaType.name
        )
      : [];
    const addressable = !!vehs.find((veh) => veh.addressable);
    const isDirectMail = vehs[0].isMultiSurvey
      ? false
      : this.mediaplannerService.plan.surveyMetaData
          .meta(vehs[0].survey.code)
          .isDirectMail(vehs[0].mediaTypeId);

    const editable = { ...READONLY_ROW };
    const isEditable =
      !addressable && !isDirectMail && !mediaType?.isMultiSurvey;
    editable[Column_ReachPct.columnDef] = isEditable;
    editable[Column_Reach000.columnDef] = isEditable;
    editable[Column_GRPs.columnDef] = isEditable;
    editable[Column_Impressions.columnDef] = isEditable;
    return editable;
  }

  private buildMediaTypeList(): MediatypeView[] {
    const medTypes: MediatypeView[] = [];
    this.targets.length
      ? this.targets[0].vehicles.forEach((vehicle) => {
          if (!medTypes.find((med) => med.name === vehicle.mediaType))
            medTypes.push({
              name: vehicle.mediaType,
              customGroupId: vehicle.customGroupId,
              isMultiSurvey: vehicle.isMultiSurvey || false,
            });
        })
      : medTypes.push({ name: '** No audiences found **' });

    return medTypes;
  }

  // if a sort order has been set, apply to tree (after a clear and rebuild, otherwise not needed)
  private performSort(tree: TreeTableNode[], table: string): void {
    const sortOrder = this.sortOrder[table];
    if (!sortOrder) return;

    const isAsc = sortOrder.direction == 'asc';
    const isName = sortOrder.active === 'name';
    tree = tree.sort((nodeA: TreeTableNode, nodeB: TreeTableNode) => {
      if (nodeA.skipSort || nodeB.skipSort) {
        // exclude 'Total' row from sort after adding/removing a column
        return;
      }
      const a = isName ? nodeA.name : nodeA.data[sortOrder.active];
      const b = isName ? nodeB.name : nodeB.data[sortOrder.active];
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    });
  }

  onToggleExpand() {
    this.tableExpanded = !this.tableExpanded;

    this.tableExpanded
      ? this.planningTable.expandAll()
      : this.planningTable.collapseAll();
  }

  // inline grid editing finished
  onEdited(events: TreeTableEditEvent[]): void {
    // add to schedule and process results
    this.processInput(events).subscribe((status) => {
      this.showInputMessages(status);
      this.mediaplannerService.dirty();
      this.populateTable();
    });
  }

  // table input processing
  // uses the columnDef to determine what was edited, row.id to get the vehicle id
  processInput(
    events: TreeTableEditEvent[],
    vehIds: string[] = null
  ): Observable<ProcessInputResponse> {
    return new Observable((observable) => {
      const schedule = this.schedule;
      const currTarget = this.target;
      const processList: Observable<any>[] = [];

      this.processing = true;
      this.showEditedRowAnimation(events, vehIds);

      // process each event in the list
      events.forEach((event: TreeTableEditEvent) => {
        // remove the thousand seperator from the value (TODO: find a cleaner way)
        const thousand: string = Intl
          ? new Intl.NumberFormat().format(1000).charAt(1)
          : ',';
        const value: number = parseFloat(
          '' + event.value.replace(thousand, '')
        );

        // process a totals line input
        const isRow =
          !!event.row &&
          (<RowContents>event.row.data.contents)?.type !== RowType.vehicle;
        if (isRow) {
          processList.push(
            this.planningService.processTotalsRow(
              event,
              currTarget,
              this.targets,
              schedule,
              value
            )
          );
        } else {
          // process a vehicle line input
          const vehicleIds: string[] = vehIds || [<string>event.row.id];

          // for each vehicle, call the relevant processing function
          vehicleIds.forEach((vehicleId) => {
            const currVehicle = currTarget.vehicle(vehicleId);
            processList.push(
              currVehicle.dayparts && currVehicle.dayparts.length
                ? this.planningService.processBroadcast(
                    event,
                    currTarget,
                    this.targets,
                    currVehicle,
                    schedule,
                    value
                  )
                : this.planningService.processVehicle(
                    event,
                    currTarget,
                    this.targets,
                    currVehicle,
                    schedule,
                    value
                  )
            );
          });
        }
      });

      // run all the processing and collect errors/messages
      forkJoin(processList).subscribe((results: ProcessInputResponse[]) => {
        const success = results.findIndex((res) => !res.success) == -1;
        const messages: string[] = results
          .filter((res) => res.messages.length)
          .map((res) => res.messages.join(', '));

        const reachRequired =
          results.findIndex((res) => res.reachRequired) !== -1;

        // evaluate R&F
        if (reachRequired) {
          this.planningService
            .evaluate(
              this.targets,
              schedule,
              this.mediaplannerService.plan.vehicleGroups.groups,
              this.mediaplannerService.plan.columns.includeUniqueReach,
              false
            )
            .subscribe((success) => {
              observable.next({ success, messages, reachRequired });
              this.processing = false;
              this.cellEditProcessing = false;
              this.planUpdated.emit(true);
              observable.complete();
            });
        } else {
          this.processing = false;
          this.cellEditProcessing = false;
          this.planUpdated.emit(true);
          observable.next({ success, messages, reachRequired });
          observable.complete();
        }
      });
    });
  }

  showEditedRowAnimation(
    columns: TreeTableEditEvent[],
    vehicleIds: string[] = null
  ) {
    this.cellEditProcessing = true;
    const mediaTypesEdited: string[] = [];
    const vehicleParent: string[] = [];
    const vehiclesEdited: string[] = [];
    const loadingAnimationClass = 'loading-dots';

    // cells edited using 'Fill all with' or 'Fill selected with'
    if (vehicleIds) {
      this.vehicleData.forEach((node) => {
        let vehicleParentMarked = false;
        node.children.map((vehicle) => {
          if (vehicleIds.includes(<string>vehicle.id)) {
            vehicle.rowCss += ` ${loadingAnimationClass}`;
            if (!vehicleParentMarked) {
              node.rowCss += ` ${loadingAnimationClass}`;
              vehicleParentMarked = true;
            }
          }
        });
      });
    } else {
      columns.forEach((col) => {
        if (col.row['treeNode'] && !col.row['treeNode'].parent) {
          // edited cell is at media type row level
          mediaTypesEdited.push(col.row.name);
        } else if (col.row['treeNode'].parent) {
          // edited cell is at vehicle row level
          vehicleParent.push(col.row['treeNode'].parent.name);
          vehiclesEdited.push(<string>col.row.id);
        }
      });

      this.vehicleData.map((node) => {
        if (mediaTypesEdited.includes(node.name)) {
          node.rowCss += ` ${loadingAnimationClass}`;
          node.children.map((vehicle) => {
            vehicle.rowCss += ` ${loadingAnimationClass}`;
          });
        }

        if (vehicleParent.includes(node.name)) {
          node.rowCss += ` ${loadingAnimationClass}`;
          node.children.map((vehicle) => {
            if (vehiclesEdited.includes(<string>vehicle.id)) {
              vehicle.rowCss += ` ${loadingAnimationClass}`;
            }
          });
        }
      });
    }
  }

  removeEditedRowAnimation() {
    this.cellEditProcessing = false;
    const loadingAnimationClass = 'loading-dots';
    this.vehicleData.forEach((node) => {
      node.rowCss = node.rowCss.replace(loadingAnimationClass, '');
      node.children.forEach((child) => {
        if (child.rowCss && child.rowCss.length > 0) {
          child.rowCss = child.rowCss.replace(loadingAnimationClass, '');
        }
      });
    });
  }

  showInputMessages(status: ProcessInputResponse) {
    if (status.messages.length) {
      const messages = [...new Set(status.messages)]; // remove duplicate messages
      this.snackbarService.showWarningSnackBar(messages.join(', '));
    }
  }

  onTreeHeaderMainMenuClick() {
    const selectedVehiclesMediaType = this.planningNodesDragSelected.map(
      (veh) => veh.data.mediaType
    );
    const selectedGroups =
      this.mediaplannerService.plan.vehicleGroups.groups.filter((node) =>
        selectedVehiclesMediaType.includes(node.name)
      );

    this.headerInlineMenu.forEach((menuItem) => {
      if (menuItem.data === 'group|add') {
        const existingGroupsCount =
          this.mediaplannerService.plan.vehicleGroups.groups.length;
        if (
          existingGroupsCount < 1 ||
          (selectedGroups && existingGroupsCount < 1) ||
          (selectedGroups && selectedGroups.length === existingGroupsCount)
        ) {
          menuItem.disabled = true;
        } else {
          menuItem.disabled = false;
        }
      }
    });
  }

  // tree header menu click
  onTreeHeaderMenuClick(item: NodeMenuClickEvent) {
    const [section, action] = item.item.data.split('|');
    if (section === OPTIMISE_MENU_ITEM.data) {
      this.processOptimisation();
    }

    if (section === 'group') {
      const selectedVehiclesMediaType = this.planningNodesDragSelected.map(
        (veh) => veh.data.mediaType
      );
      const selectedGroup =
        this.mediaplannerService.plan.vehicleGroups.groups.find((node) =>
          selectedVehiclesMediaType.includes(node.name)
        );
      this.showVehicleGroupDialog(VehicleGroupAction[action], selectedGroup);
      this.planningTable.selectAll(false);
    }
    if (section === 'columns') {
      this.showEditColumnsDialog();
    }
  }

  // edit addr button clicked in the addr template
  onEditAddressable(row: TreeTableNode) {
    const item = row.inlineMenu.find(
      (menu) => menu.data === 'vehicle|addressable'
    );
    this.onTreeInlineMenuClick({ row, item });
  }

  // context menu for vehicles
  onTreeInlineMenuClick(item: NodeMenuClickEvent) {
    const [section, action] = item.item.data.split('|');

    // group options
    if (section === 'group') {
      if (action === 'edit') {
        this.showVehicleGroupDialog(
          VehicleGroupAction[action],
          this.mediaplannerService.plan.vehicleGroups.group(item.row.id)
        );
      }

      if (action === 'delete') {
        const confirmationMessage = `Are you sure you want to delete '${item.row.name}'?`;
        const options: QuestionDialogModelOptions =
          this.dialogService.getDeleteConfirmationOptions(confirmationMessage);

        this.dialogService
          .confirmation('', 'Are you sure?', options)
          .afterClosed()
          .subscribe((button) => {
            if (button.data === 'delete') {
              this.removeVehicleGroup(item.row.name, item.row.id.toString());
              this.mediaplannerService.plan.vehicleGroups.delete(item.row.id);
              this.recalculateAllAndRefresh();
            }
          });
      }
    }

    // vehicle options
    if (section === 'vehicle') {
      if (action === 'addressable') {
        this.showAddAddressableDialog(item.row.id);
      }

      if (action === 'removeAddressable') {
        this.mediaplannerService.plan.addAddressable(item.row.id, null);
        this.recalculateAllAndRefresh();
      }

      if (action === 'rename') {
        this.showRenameMediaDialog(item.row.id);
      }

      if (action === 'duplicate') {
        const vehicleId = item.row.id as string;
        this.mediaplannerService.plan.duplicateVehicle(vehicleId);
        this.populateTable();
      }

      if (action === 'delete') {
        const vehicleId = item.row.id as string;
        let groupWithSingleElement = null;
        let groupWithElement = null;
        this.mediaplannerService.plan.vehicleGroups.groups.forEach((group) => {
          if (group.vehicles.some((vehicle) => vehicle.id === vehicleId)) {
            const groupElement = this.vehicleData.find(
              (element) => element.id === group.id
            );
            if (groupElement.children.length) {
              groupWithElement = group;
            }
            if (groupElement.children.length === 1) {
              groupWithSingleElement = group;
            }
          }
        });

        if (groupWithSingleElement) {
          this.dialogService
            .showDeleteGroupWarningDialog()
            .subscribe((answer) => {
              if (answer) {
                this.removeVehicleFromGroup(
                  groupWithSingleElement.id,
                  vehicleId as string
                );
                this.mediaplannerService.plan.removeVehicle(vehicleId);
                this.recalculateAllAndRefresh();
              }
            });
        } else {
          if (groupWithElement) {
            this.removeVehicleFromGroup(
              groupWithElement.id,
              vehicleId as string
            );
          }
          this.mediaplannerService.plan.removeVehicle(vehicleId);
          this.recalculateAllAndRefresh();
        }
      }
    }

    // vehicle from group options
    if (section === 'groupVehicle') {
      if (action === 'move') {
        this.planningNodesDragSelected = [item.row];
        this.showVehicleGroupDialog(
          VehicleGroupAction.move,
          this.mediaplannerService.plan.vehicleGroups.groups.find(
            (group) => group.name === item.row.data.mediaType
          )
        );
      }
      if (action === 'delete') {
        const currentGroup =
          this.mediaplannerService.plan.vehicleGroups.groups.find(
            (val) => val.name === item.row.data.mediaType
          );

        if (currentGroup.vehicles.length === 1) {
          this.dialogService
            .showDeleteGroupWarningDialog()
            .subscribe((answer) => {
              if (answer) {
                this.removeVehicleFromGroup(
                  currentGroup.id,
                  item.row.id as string
                );
                this.recalculateAllAndRefresh();
              }
            });
        } else {
          this.removeVehicleFromGroup(currentGroup.id, item.row.id as string);
          this.recalculateAllAndRefresh();
        }
      }
    }

    if (section === 'importedMedia') {
      if (action === 'update') {
        console.log(' Definition of data sync between platforms needed ');
      }
      if (action === 'change_audience') {
        const config: MultiSurveyVehicleConfig =
          item.row.data['multiSurveyConfig'];

        this.dialogService
          .openImportMixedDataDialog({
            action: dialogActionType.changeAudience,
            config,
          })
          .afterClosed()
          .subscribe((data: MixedDataDialogModel) => {
            if (data) {
              this.processing = true;
              this.multiSurveyService.multiSurveyStartProcessing.emit(
                'planning'
              );
              this.multiSurveyService
                .addDAUDataToPlan(data.planAudienceId, data.documentTarget)
                .subscribe(() => {
                  this.multiSurveyService.multiSurveyTargetSelected.emit({
                    currentTab: 'planning',
                  });
                  this.mediaplannerService.dirty();
                  this.processing = false;
                });
            }
          });
      }
      if (action === 'rename') {
        const renameMediaConfig = new RenameMediaDialogModel();
        renameMediaConfig.initialName = item.row.data.contents.id || '';
        renameMediaConfig.newName = item.row.data.contents.id || '';
        renameMediaConfig.itemType = RenameItemType.importedMedia;
        this.dialogService
          .openRenameMediaDialog(renameMediaConfig)
          .afterClosed()
          .pipe(isNotNullOrUndefined())
          .subscribe((data: RenameMediaDialogModel) => {
            this.multiSurveyService.renameImportedMediaType(
              data.initialName,
              data.newName
            );
            this.populateTable();
          });
      }
      if (action === 'preferences') {
        const config: MultiSurveyVehicleConfig =
          item.row.data['multiSurveyConfig'];
        this.dialogService
          .openImportMixedDataDialog({
            action: dialogActionType.preferences,
            config,
          })
          .afterClosed()
          .subscribe((dialogData) => {
            const multiSurvey =
              this.mediaplannerService.plan.multiSurveys.multiSurvey(
                config.multiSurveyId
              );
            if (dialogData.keepThousands !== multiSurvey.keepThousands) {
              this.processing = true;
              this.mediaplannerService.plan.multiSurveys.setPreservedMetric(
                multiSurvey,
                dialogData.keepThousands
              );
              this.planningService
                .evaluate(
                  this.targets,
                  this.schedule,
                  this.mediaplannerService.plan.vehicleGroups.groups,
                  this.mediaplannerService.plan.columns.includeUniqueReach,
                  false
                )
                .subscribe(() => {
                  this.populateTable();
                  this.processing = false;
                });
            }
          });
      }
      if (action === 'delete') {
        const config: MultiSurveyVehicleConfig =
          item.row.data['multiSurveyConfig'];
        const confirmationMessage = `Are you sure you want to delete '${item.row.name}'?`;
        const options: QuestionDialogModelOptions =
          this.dialogService.getDeleteConfirmationOptions(confirmationMessage);
        this.dialogService
          .confirmation('', 'Are you sure?', options)
          .afterClosed()
          .subscribe((button) => {
            if (button.data === 'delete') {
              const index =
                this.mediaplannerService.plan.multiSurveys.multiSurveys.findIndex(
                  (ms) => ms.id === config.multiSurveyId
                );
              this.multiSurveyService.removeMultiSurveyDataFromPlan(index);
              this.recalculateAllAndRefresh();
            }
          });
      }
    }

    if (section === 'manualInput') {
      if (action === 'update') {
        const config: MultiSurveyVehicleConfig =
          item.row.data['multiSurveyConfig'];
        const scheduleIndex = this.schedules.findIndex(
          (sch) => sch.id === this.schedule.id
        );

        this.dialogService
          .openManualEntryUpdateDialog({
            multiSurveyId: config.multiSurveyId,
            scheduleIndex,
          })
          .afterClosed()
          .subscribe((manualInputData) => {
            if (manualInputData) {
              this.processing = true;
              this.multiSurveyService.multiSurveyStartProcessing.emit(
                'planning'
              );
              this.multiSurveyService
                .updateManualInput(
                  manualInputData,
                  config.multiSurveyId,
                  scheduleIndex
                )
                .subscribe(() => {
                  this.multiSurveyService.multiSurveyTargetSelected.emit({
                    currentTab: 'planning',
                  });
                  this.populateTable();
                  this.mediaplannerService.dirty();
                  this.processing = false;
                });
            }
          });
      }

      if (action === 'delete') {
        const config: MultiSurveyVehicleConfig =
          item.row.data['multiSurveyConfig'];
        const confirmationMessage = `Are you sure you want to delete '${item.row.name}'?`;
        const options: QuestionDialogModelOptions =
          this.dialogService.getDeleteConfirmationOptions(confirmationMessage);
        this.dialogService
          .confirmation('', 'Are you sure?', options)
          .afterClosed()
          .subscribe((button) => {
            if (button.data === 'delete') {
              this.mediaplannerService.plan.removeVehicle(config.multiSurveyId);
              this.recalculateAllAndRefresh();
            }
          });
      }
    }
  }

  // elastic drag row selection on the planning grid, captured on mouse up
  onPlanningSelectedNodes(nodes: TreeTableNode[]) {
    this.planningNodesDragSelected = nodes;
  }

  // user initiated a sort on a table
  onSorted(table: string, sortOrder: Sort) {
    this.sortOrder[table] = sortOrder;
    this.initialiseVirtualTable();
  }

  // dropdown clicked on either the planning or totals grid
  onPlanningColumnMenuClick(item: ColumnMenuClickEvent) {
    // Column menu click (Rather than main menu click)
    if (item.column) {
      if (item.item.data === OPTIMISE_MENU_ITEM.data) {
        this.processOptimisation();
      } else {
        const currTarget = this.target;
        const vehicleIds =
          item.item.data == 'fill_sel'
            ? this.planningNodesDragSelected.map((node) => node.id as string)
            : currTarget.vehicles
                .filter((vehicle) => !vehicle.isMultiSurvey)
                .map((veh) => veh.id);

        if (!vehicleIds.length) {
          this.userMessage.openMessageDialog(
            'Please select your vehicles first',
            'Fill selected'
          );
          return;
        }

        const getValue =
          item.item.data === 'clear_all'
            ? of('0')
            : this.userMessage
                .openSimpleInputDialog(
                  this.appendUnits.transform(item.column, this.unitsText),
                  item.item.label,
                  '',
                  INPUT_DIALOG_OPTIONS
                )
                .afterClosed();

        getValue.subscribe((value) => {
          // didnt cancel
          if (value !== null) {
            const column: TreeTableEditEvent = {
              type: 'manual',
              columnDef: item.column.columnDef,
              value,
              row: null,
            };

            // add to schedule and process results
            this.processInput([column], vehicleIds).subscribe((status) => {
              const costColDefs = [
                Column_CPM.columnDef,
                Column_CPP.columnDef,
                Column_BuyingCPM.columnDef,
                Column_BuyingCPP.columnDef,
                Column_BaseCPM.columnDef,
                Column_UnitCost.columnDef,
                Column_TotalCost.columnDef,
              ];
              if (
                item.item.data === 'clear_all' &&
                costColDefs.includes(column.columnDef)
              ) {
                this.planningService.clearCostTotals(
                  this.schedule,
                  this.targets
                );
              }
              this.showInputMessages(status);
              this.mediaplannerService.dirty();
              this.populateTable();
            });
          }
        });
      }
    }
  }

  onContainsBroadcastClick() {
    this.dialogService.showInfoMessageDialog({
      template: infoDialogTemplate.broadcast,
    });
  }

  onBroadcastSpotplanClick(item: TreeTableNode) {
    const spotplanModel: SpotplanQuickViewModel = new SpotplanQuickViewModel(
      this.schedule,
      this.target.vehicle(item.id),
      this.target
    );

    this.dialogService
      .showSpotplanQuickView(spotplanModel)
      .subscribe((model) => {
        const spotplan = this.schedule.spotplans.findSpotplan(<string>item.id);
        if (model.buttonAction === SpotplanQuickViewButton.schedule) {
          this.navigateToSpotplan(spotplan.id);
          console.log(model);
        }
      });
  }

  navigateToSpotplan(spotplanId: string) {
    const docId = this.mediaplannerService.plan.documentId;
    this.mediaplannerService.plan.campaignRedirecting = true;
    this.mediaplannerService.plan.campaignSpotplan = spotplanId;

    if (docId && docId !== 'unsaved') {
      this.router.navigate([`edit/${docId}/data`], {
        queryParams: {
          tab: 3,
        },
      });
    } else {
      this.router.navigate(['new/data'], {
        queryParams: {
          tab: 3,
        },
      });
    }
  }

  onImportedMediaInfoClick(row: TreeTableNode) {
    const config: MultiSurveyVehicleConfig = row.data['multiSurveyConfig'];
    this.dialogService.showInfoMessageDialog({
      template: infoDialogTemplate.importedMedia,
      title: row.name,
      content:
        this.mediaplannerService.plan.multiSurveys.getInfoDialogData(config),
    });
  }

  showRenameMediaDialog(vehicleId: number | string) {
    const renameMediaConfig = new RenameMediaDialogModel();
    renameMediaConfig.initialName =
      this.target.vehicle(vehicleId).originalTitle || '';
    renameMediaConfig.newName = this.target.vehicle(vehicleId).title;

    this.dialogService
      .openRenameMediaDialog(renameMediaConfig)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data: RenameMediaDialogModel) => {
        this.mediaplannerService.plan.renameVehicle(vehicleId, data.newName);
        this.mediaplannerService.plan.renameSpotplan(vehicleId, data.newName);
        this.populateTable();
      });
  }

  showAddAddressableDialog(vehicleId: number | string) {
    const addressableConfig = new AddAddressableDialogModel();
    const vehicle = this.target.vehicle(vehicleId);

    if (vehicle.addressableConfig) {
      addressableConfig.selectedTarget = this.targets.find(
        (target) => target.id === vehicle.addressableConfig.targetId
      );
    }

    addressableConfig.targets = this.targets;
    addressableConfig.vehicle = vehicle;
    addressableConfig.newTitle = addressableConfig.vehicle.title;
    addressableConfig.survey = vehicle.survey
      ? this.mediaplannerService.plan.selectedSurveys.find(
          (survey) =>
            survey.code === vehicle.survey.code &&
            survey.authorizationGroup === vehicle.survey.authGroup
        )
      : this.targets[0].survey;

    this.dialogService
      .openAddAddressableDialog(addressableConfig)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data: AddAddressableDialogModel) => {
        this.mediaplannerService.plan.addAddressable(
          data.vehicle.id,
          data.selectedTarget
        );

        if (data.newTitle !== data.vehicle.title) {
          this.mediaplannerService.plan.renameVehicle(
            data.vehicle.id,
            data.newTitle
          );
        }

        this.mediaplannerService.dirty();

        // perform full R&F recalc if required
        const req = data.fullRecalcRequired
          ? this.recalculateAllAndRefresh()
          : this.populateTable();
      });
  }

  // create a new vehicle group dialog based on selected vehicles
  showVehicleGroupDialog(
    action: VehicleGroupAction,
    editGroup: VehicleGroup = null
  ) {
    if (
      action === VehicleGroupAction.add &&
      this.mediaplannerService.plan.vehicleGroups.groups.length === 0
    ) {
      action = VehicleGroupAction.save;
    }

    const groupConfig = new VehicleGroupsDialogModel(action);

    groupConfig.vehicles = this.planningNodesDragSelected.map((node) => {
      const vehicle = this.target.vehicle(node.id);
      return {
        id: vehicle.id,
        daypartIds: vehicle.dayparts.map((daypart) => daypart.id),
        name: node.name,
      };
    });

    // configure based on saving, adding to existing, or editing existing
    switch (action) {
      case VehicleGroupAction.save:
        groupConfig.name = 'New group';
        break;

      case VehicleGroupAction.add:
        groupConfig.groups = editGroup
          ? this.mediaplannerService.plan.vehicleGroups.groups.filter(
              (group) => group.name !== editGroup?.name
            )
          : this.mediaplannerService.plan.vehicleGroups.groups;
        break;

      case VehicleGroupAction.edit:
        groupConfig.name = editGroup.name;
        groupConfig.group = editGroup;
        groupConfig.vehicles = editGroup.vehicles;
        break;

      case VehicleGroupAction.move:
        groupConfig.groups =
          this.mediaplannerService.plan.vehicleGroups.groups.filter(
            (group) => group.name !== editGroup?.name
          );
        break;
    }

    if (!groupConfig.vehicles.length) {
      this.snackbarService.showWarningSnackBar(
        'Please first select the vehicle(s) you want to group together.',
        'Close'
      );
      return;
    }

    this.dialogService
      .openVehicleGroupsDialog(groupConfig)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((groupResult: VehicleGroupsDialogModel) => {
        // create a new vehicle group
        if (action === VehicleGroupAction.save) {
          const group = this.mediaplannerService.plan.vehicleGroups.add(
            groupResult.name,
            groupResult.vehicles
          );
          this.moveVehiclesToGroup(
            groupResult.vehicles,
            groupResult.name,
            group.id
          );
        }

        //add vehicles to or edit an existing group
        if (
          action === VehicleGroupAction.add ||
          action === VehicleGroupAction.edit ||
          action === VehicleGroupAction.move
        ) {
          if (action === VehicleGroupAction.move) {
            this.removeVehicleFromGroup(
              editGroup.id,
              this.planningNodesDragSelected[0].id as string
            );
            this.planningNodesDragSelected = [];
          }
          const group = this.mediaplannerService.plan.vehicleGroups.group(
            groupResult.group.id
          );
          group.name = groupResult.name;
          this.mediaplannerService.plan.vehicleGroups.addVehicles(
            group.id,
            groupResult.vehicles,
            action === VehicleGroupAction.edit
          );

          this.moveVehiclesToGroup(
            groupResult.vehicles,
            groupResult.name,
            groupResult.group.id,
            action === VehicleGroupAction.edit
          );
        }

        // intitiate R&F in case any vehicles added to a group already have inserts
        this.recalculateAll().subscribe((res) => {
          this.mediaplannerService.dirty();
          this.populateTable();
        });
      });
  }

  moveVehiclesToGroup(
    vehicles: VehicleGroupVehicle[],
    groupName: string,
    groupId: string,
    clearFirst: boolean = false
  ) {
    this.targets.forEach((target) => {
      // if group is edited, reset all vehicles to originalMediaType
      if (clearFirst) {
        target.vehicles
          .filter((targetVehicle) => targetVehicle.customGroupId === groupId)
          .map((vehicle) => {
            vehicle.mediaType = vehicle.originalMediaType;
            vehicle.customGroupId = null;
          });
      }
      vehicles.forEach((newGroupVehicle) => {
        const vehicleWithGroup = target.vehicles.find(
          (targetVehicle) =>
            targetVehicle.customGroupId &&
            newGroupVehicle.id === targetVehicle.id
        );
        if (vehicleWithGroup) {
          this.removeVehicleFromGroup(
            vehicleWithGroup.customGroupId,
            vehicleWithGroup.id
          );
        }
        // place received vehicles list should in the received group
        const vehicleToMove = target.vehicles.find(
          (targetVehicle) => targetVehicle.id === newGroupVehicle.id
        );
        vehicleToMove.originalMediaType = vehicleToMove.mediaType;
        vehicleToMove.customGroupId = groupId;
        vehicleToMove.mediaType = groupName;
      });
    });
  }

  removeVehicleFromGroup(groupId: string, vehicleId: string) {
    this.targets.forEach((target) => {
      const vehicleToRemove = target.vehicles.find(
        (targetVehicle) => targetVehicle.id === vehicleId
      );
      const indexToUpdate = target.vehicles.indexOf(vehicleToRemove);
      target.vehicles[indexToUpdate].mediaType =
        vehicleToRemove.originalMediaType;
      target.vehicles[indexToUpdate].customGroupId = null;
      target.vehicles[indexToUpdate].originalMediaType = null;
    });
    this.mediaplannerService.plan.vehicleGroups.removeVehicle(
      groupId,
      vehicleId
    );
    if (
      this.mediaplannerService.plan.vehicleGroups.group(groupId).vehicles
        .length === 0
    ) {
      this.mediaplannerService.plan.vehicleGroups.delete(groupId);
    }
  }

  removeVehicleGroup(groupName: string, groupId: string) {
    this.targets.forEach((target) => {
      target.vehicles
        .filter((targetVehicle) => targetVehicle.customGroupId === groupId)
        .map((vehicle) => {
          vehicle.mediaType = vehicle.originalMediaType;
          vehicle.customGroupId = null;
          vehicle.originalMediaType = null;
        });
    });
  }
  // TODO this can be removed after app runs once for users that previously saved groups in the old format
  migrateOldGroupData() {
    if (this.mediaplannerService.plan.vehicleGroups.groups.length) {
      this.mediaplannerService.plan.vehicleGroups.groups.forEach((group) => {
        // if group id is not assigned to any target vehicles, group was created in previous flow
        const groupFoundInTargetVehicles = this.targets[0].vehicles.find(
          (targetVehicle) => targetVehicle.customGroupId === group.id
        );

        // mark target vehicles with group id
        if (!groupFoundInTargetVehicles) {
          this.moveVehiclesToGroup(group.vehicles, group.name, group.id);
        }
      });
    }
  }

  private tableNeedsEvaluating(
    columnsBefore: TreeTableColumn[],
    columnsAfter: TreeTableColumn[],
    dialogEffReach
  ) {
    const columnsDefWithCalculation = columnsThatRetriggerCalculation.map(
      (col) => col.columnDef
    );
    const columnsDefBefore = columnsBefore.map((col) => col.columnDef);
    const columnsDefAfter = columnsAfter.map((col) => col.columnDef);
    const addedColumns = columnsDefAfter.filter(
      (colAfter) => !columnsDefBefore.includes(colAfter)
    );
    const planEffReach = this.mediaplannerService.plan.effectiveReach;
    const sameEffReachLevel =
      dialogEffReach?.effectiveReachFrom === planEffReach?.effectiveReachFrom &&
      dialogEffReach?.effectiveReachTo === planEffReach?.effectiveReachTo;

    // check if newly added columns are amongst ones that should trigger evaluation call or if eff reach level changed
    if (
      (addedColumns.length &&
        addedColumns.find((addedCol) =>
          columnsDefWithCalculation.includes(addedCol)
        )) ||
      (dialogEffReach?.active && !sameEffReachLevel)
    ) {
      return true;
    }

    return false;
  }

  private processTableOnColumnsDialogClose(dialogColumns: TreeTableColumn[]) {
    this.processing = false;
    const dialogCols = dialogColumns;
    dialogCols.forEach((column) => {
      //if the column is collapsed then also set the group to be hidden
      if (column.collapsed) {
        column = { ...column, group: { ...column.group, hidden: true } };
      }
    });
    this.columns = dialogCols;
    this.anyGroups = !!this.columns.find((col) => col.group);
    this.processTableColumns();
    this.populateTable();
  }

  showEditColumnsDialog() {
    const [containsDirectMail, containsAddressable] =
      this.mediaplannerService.containsAddressableMedia(this.target);
    const visibleColumnsBefore =
      this.mediaplannerService.plan.columns.getVisibleColumns();
    this.dialogService
      .openEditColumnsDialog({
        planColumnData: this.mediaplannerService.plan.columns,
        effectiveReachData: this.mediaplannerService.plan.effectiveReach,
        currentCompareOption: compareOptionKeys.media, // compare by media view
        disabledGroups: {
          addressable: !containsAddressable,
          directMail: !containsDirectMail,
          esgScore: !this.target.survey.esgProviders?.length,
        },
      })
      .afterClosed()
      .subscribe((dialogData) => {
        if (dialogData) {
          const needsEvaluating = this.tableNeedsEvaluating(
            visibleColumnsBefore,
            dialogData.columns,
            dialogData.effectiveReach
          );

          this.mediaplannerService.plan.columns.autoUpdateColumns = false;
          this.mediaplannerService.plan.effectiveReach =
            dialogData.effectiveReach;

          this.processing = true;
          if (needsEvaluating) {
            const scheduleRequests: Observable<boolean>[] = this.schedules.map(
              (schedule) =>
                this.planningService.evaluate(
                  this.targets,
                  schedule,
                  this.mediaplannerService.plan.vehicleGroups.groups,
                  this.mediaplannerService.plan.columns.includeUniqueReach,
                  false
                )
            );
            forkJoin(scheduleRequests).subscribe((data) => {
              this.processTableOnColumnsDialogClose(dialogData.columns);
            });
          } else {
            setTimeout(() => {
              this.processTableOnColumnsDialogClose(dialogData.columns);
            }, 200);
          }
        }
      });
  }

  /**
   * Using the current target, schedule and vehicle list,
   * 1. show the optimisation dialog box
   * 2. If optimisation is to be started,
   *   - prepare vehicle structures for optimisation
   *   - call the optimisation service with the current plan details, splits, selected strategy and goal
   *   - when optimisation is finished, populate internal structures with the optimised results
   *   - call the engine service evaluation for optimised reach and frequency, then update main table
   */
  processOptimisation() {
    this.dialogService
      .showOptimiseDialog({
        selectedAudience: this.target.title,
        selectedPlan: this.schedule.name,
        isBudgetEnabled: !!this.schedule.vehicles.find(
          (veh) => veh.result.cpm > 0
        ), // at least 1 cpm somewhere
      })
      .afterClosed()
      .subscribe((val) => {
        if (val) {
          this.optimisationMenuItemDisabled = true;
          this.processTableColumns();
          const currTarget = this.target;
          const { goal, value, mediaTypes, plan, constraints } = val;
          if (plan === 'Create new plan') {
            this.addNewSchedule.emit();
          } else {
            const selectedSchedule =
              this.mediaplannerService.plan.schedules.find(
                (val) => val.name === plan
              );
            const selectedScheduleIndex =
              this.mediaplannerService.plan.schedules.indexOf(selectedSchedule);
            this.changeActiveSchedule.emit(selectedScheduleIndex);
          }
          const vehicleIds = currTarget.vehicles
            .filter((vehicle) =>
              mediaTypes.find((media) => media.title === vehicle.mediaType)
            )
            .map((val) => val.id);
          const selectedVehicles = this.schedule.vehicles.filter(
            (val) =>
              val.targetId === this.target.id &&
              vehicleIds.find((id) => id === val.vehicleId)
          );

          const filteredVehicles = selectedVehicles.filter((vehicle) => {
            const mediaVehicle = this.target.vehicle(vehicle.vehicleId);
            if (
              this.mediaplannerService.plan.surveyMetaData
                .meta(mediaVehicle.survey.code)
                .isDirectMail(mediaVehicle.mediaTypeId)
            ) {
              // verify direct mail definetely has a buying target
              return !!mediaVehicle.addressableConfig;
            }

            return true;
          });

          const { vehiclesReq: parsedVehicles, buyingTargetDefinitions } =
            this.optimiseService.prepareVehicles(
              this.target,
              filteredVehicles,
              this.schedule,
              this.targets,
              constraints
            );
          setTimeout(() => {
            this.processing = true;
            this.showEditedRowAnimation(
              this.columns as any,
              filteredVehicles.map((val) => val.vehicleId)
            );

            this.optimiseService
              .optimsePlan(
                currTarget,
                parsedVehicles,
                goal,
                value,
                this.mediaplannerService.plan.surveyList,
                constraints,
                buyingTargetDefinitions
              )
              .subscribe((optimisePlanResponse) => {
                if (
                  !optimisePlanResponse ||
                  optimisePlanResponse?.success === false
                ) {
                  this.processing = false;
                  this.cellEditProcessing = false;
                  this.processing = false;
                  this.removeEditedRowAnimation();
                  this.optimiseService?.optimiseProgressSnackbar?.dismiss();
                  this.optimisationMenuItemDisabled = false;
                  this.processTableColumns();
                  return;
                }

                const processList: Observable<any>[] = [];
                filteredVehicles.forEach((vehicle) => {
                  const selectedVehicle = this.target.vehicle(
                    vehicle.vehicleId
                  );
                  if (
                    selectedVehicle.dayparts &&
                    selectedVehicle.dayparts.length > 0
                  ) {
                    // process dayparts
                    const weeksToProcess: number[] = [];
                    const currSpotplan =
                      this.schedule.spotplans.addSpotplan(selectedVehicle);
                    const scheduleVehicle = this.schedule.vehicle(
                      currTarget,
                      selectedVehicle.id
                    );

                    // start with a clean slate
                    scheduleVehicle.result.addResults(EMPTY_RESULTS);
                    currSpotplan.clearSpotplan();
                    let inserts = 0;
                    currSpotplan.dayparts.forEach((daypart) => {
                      const optimiseDaypart = optimisePlanResponse.results.find(
                        (val) => val.id === daypart.id
                      );

                      // this daypart has inserts to be recorded
                      if (optimiseDaypart) {
                        inserts += optimiseDaypart.numberOfInserts;
                        daypart.result.addResults(0, currTarget.id, {
                          inserts: optimiseDaypart.numberOfInserts,
                          duration: optimiseDaypart.duration,
                          impressions: optimiseDaypart.impressions || 0,
                        });
                      }
                    });
                    currSpotplan.setWeekTotal(0, currTarget.id, {
                      inserts,
                    });
                    scheduleVehicle.result.addResults({
                      inserts,
                      type: ResultType.broadcast,
                    });
                  } else {
                    const foundVehicle = optimisePlanResponse.results.find(
                      (val) => val.id === vehicle.vehicleId
                    );
                    if (selectedVehicle.addressableConfig) {
                      if (selectedVehicle.addressableConfig.isDirectMail) {
                        processList.push(
                          this.planningService.processVehicle(
                            {
                              columnDef: Column_NumberOfMailItems.columnDef,
                            } as any,
                            currTarget,
                            this.targets,
                            selectedVehicle,
                            this.schedule,
                            foundVehicle.numberOfInserts *
                              selectedVehicle.addressableConfig.population // contains DMInserts and duration
                          )
                        );
                      } else {
                        processList.push(
                          this.planningService.processVehicle(
                            {
                              columnDef: Column_BuyingImpressions.columnDef,
                            } as any,
                            currTarget,
                            this.targets,
                            selectedVehicle,
                            this.schedule,
                            foundVehicle.impressions
                          )
                        );
                      }
                    } else {
                      const isPercentage =
                        this.mediaplannerService.plan.surveyMetaData.reachFreq.isPercentageInserts(
                          selectedVehicle
                        );
                      processList.push(
                        this.planningService.processVehicle(
                          { columnDef: Column_Duration.columnDef } as any,
                          currTarget,
                          this.targets,
                          selectedVehicle,
                          this.schedule,
                          foundVehicle.duration
                        )
                      );
                      processList.push(
                        this.planningService.processVehicle(
                          { columnDef: Column_Inserts.columnDef } as any,
                          currTarget,
                          this.targets,
                          selectedVehicle,
                          this.schedule,
                          foundVehicle.numberOfInserts *
                            (isPercentage ? 100 : 1)
                        )
                      );
                    }
                  }
                });

                forkJoin(processList)
                  .pipe(defaultIfEmpty([]))
                  .subscribe((results) => {
                    const messages: string[] = results
                      .filter((res) => res.messages && res.messages.length)
                      .map((res) => res.messages.join(', '));
                    this.planningService
                      .evaluate(
                        this.targets,
                        this.schedule,
                        this.mediaplannerService.plan.vehicleGroups.groups,
                        this.mediaplannerService.plan.columns
                          .includeUniqueReach,
                        false
                      )
                      .subscribe((success) => {
                        const status: ProcessInputResponse = {
                          messages,
                          success,
                          reachRequired: false,
                        };
                        this.showInputMessages(status);
                        this.processing = false;
                        this.cellEditProcessing = false;
                        this.processing = false;
                        this.removeEditedRowAnimation();
                        this.optimiseService.optimiseProgressSnackbar.dismiss();
                        this.optimisationMenuItemDisabled = false;
                        this.processTableColumns();
                        this.planUpdated.emit(true);
                        this.mediaplannerService.dirty();
                        this.populateTable();
                      });
                  });
              });
          }, 100);
        }
      });
  }

  exportMrf() {
    // basic campaign info that needs exporting
    const campaigninfo: MrfCampaignInfo = {
      campaignTitle: this.mediaplannerService.plan.title,
      campaignNotes: this.mediaplannerService.plan.description,
      campaignDate: new Date().toLocaleString(),
      planningSystemUser: this.authService.user?.attributes.email,
    };

    this.processing = true;
    this.engineService
      .createMrfFile(
        this.target,
        this.targets,
        this.schedule,
        this.mediaplannerService.plan.vehicleGroups.groups,
        campaigninfo
      )
      .subscribe((mrfFileResult: CreateMrfFileResponse) => {
        this.processing = false;
        mrfFileResult.success
          ? this.multiSurveyService.downloadMrfFile(mrfFileResult.results)
          : null;
      });
  }

  waitForCalculationParameters(): Observable<boolean> {
    return this.mediaplannerService.plan.surveyMetaData.ready(
      this.mediaplannerService.plan.primarySurvey.code
    )
      ? of(true)
      : new Observable((ob) => {
          const id = setInterval(() => {
            if (
              this.mediaplannerService.plan.surveyMetaData.ready(
                this.mediaplannerService.plan.primarySurvey.code
              )
            ) {
              clearInterval(id);
              ob.next(true);
              ob.complete();
            }
          }, 200);
        });
  }

  /**
   * Performs a full Reach and Frequency of all targets and schedules in the plan
   * this assumes all target populations and vehicle audiences are correct and up to date
   *
   * @returns Observable<boolean>
   */
  recalculateAll(): Observable<boolean> {
    this.processing = true;
    return this.planningService
      .evaluateAllSchedules(
        this.targets,
        this.schedules,
        this.mediaplannerService.plan.vehicleGroups.groups,
        this.mediaplannerService.plan.columns.includeUniqueReach,
        false
      )
      .pipe(
        map((b: boolean[]) => !b.includes(false)),
        tap(() => (this.processing = false))
      );
  }

  recalculateAllAndRefresh() {
    this.recalculateAll().subscribe((res) => {
      this.mediaplannerService.dirty();
      this.populateTable();
    });
  }

  onEsgInfoIconClick() {
    this.dialogService.showInfoMessageDialog({
      template: infoDialogTemplate.esgScore,
    });
  }
}
