<div
  class="view-container"
  fxFlex="100%"
  fxLayout="column"
>
  <ng-template #chartsToolbarContent>
    <!-- top settings band  -->
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      class="chart-settings-row"
    >
      <div fxFlex></div>
      <div
        fxFlex
        fxLayoutAlign="end center"
        fxLayoutGap="14px"
      >
        <simple-selection
          title="Plans"
          display="dropdown"
          width="200px"
          [multi]="planModeToggle.selected"
          (dropdownToggle)="onDropdownToggle($event)"
          [topOption]="planModeToggle"
          [options]="filterSchedules"
        ></simple-selection>

        <simple-selection
          title="Audiences"
          display="dropdown"
          [multi]="true"
          width="200px"
          (dropdownToggle)="onDropdownToggle($event)"
          [topOption]="targetModeToggle"
          [options]="filterTargets"
        ></simple-selection>

        <mat-form-field
          appearance="outline"
          tupMatFormField
          class="regular-select"
          style="width: 200px"
        >
          <mat-label>Media mode</mat-label>
          <mat-select
            #mediaSelect
            [(ngModel)]="currentMediaMode"
            (selectionChange)="onChartModeChange()"
            [disabled]="
              (targetModeToggle.selected && planModeToggle.selected) ||
              chartType === 'freq-distr'
            "
          >
            <mat-select-trigger>{{ currentMediaModeLabel }}</mat-select-trigger>
            <mat-option
              style="padding-right: 0px"
              *ngFor="let mode of mediaModes"
              [value]="mode.id"
            >
              <span>{{ mode.label }}</span>
              <span fxFlex></span>
              <mat-divider
                style="margin: 6px 0px 6px 0px"
                vertical
              ></mat-divider>
              <button
                mat-icon-button
                matTooltip="Select..."
                (click)="onSelectMedia($event, mode.id)"
              >
                <mat-icon class="tiny-icon">chevron_right</mat-icon>
                <!-- keyboard_double_arrow_right -->
              </button>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-stroked-button
          color="primary"
          tupMatButton
          appearance="tiny"
          [matTooltip]="!showTable ? 'Show charts table' : 'Hide charts table'"
          (click)="openTables()"
          class="open-table-icon"
        >
          <mat-icon class="tiny-icon">table_chart</mat-icon>
        </button>
        <button
          class="toolbutton-center"
          mat-stroked-button
          color="primary"
          tupMatButton
          (click)="onOpenGlobalSettings()"
        >
          <mat-icon class="material-icons-outlined">settings</mat-icon>
          Global settings
        </button>
      </div>
    </div>
  </ng-template>

  <!-- main scrollable chart container -->
  <!-- Virtual scrolling perhaps not needed as there are only n targets charts maximum -->
  <div class="chart-container">
    <!-- <cdk-virtual-scroll-viewport itemSize="100" class="chart-scrollbar" > -->
    <div
      fxLayout="row wrap"
      fxLayoutGap="10px"
      *ngIf="processing !== true"
    >
      <!-- *cdkVirtualFor="let chart of charts" -->
      <div
        *ngFor="let chart of charts; let i = index"
        [ngClass]="{ full: chart.fullWidth, 'combined-chart': false }"
        class="example-item chart"
      >
        <chart
          [chartData]="chart.data"
          [chartType]="chartType"
          [chartSettings]="chart.settings"
          (colorChange)="refreshViewColorChange()"
          (fullWidthNeeded)="onFullWidthNeededChange($event, i)"
        >
        </chart>
      </div>
    </div>
    <div
      class="charts-spinner-container"
      *ngIf="processing"
    >
      <mat-spinner
        [diameter]="80"
        [strokeWidth]="13"
      ></mat-spinner>
    </div>
    <!-- </cdk-virtual-scroll-viewport> -->
  </div>
</div>
