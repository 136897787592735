<ng-container [ngSwitch]="display">
  <ng-container *ngSwitchCase="'dropdown'">
    <ng-container [ngTemplateOutlet]="dropdownTemplate"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'list'">
    <ng-container [ngTemplateOutlet]="listTemplate"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'button'">
    <ng-container [ngTemplateOutlet]="buttonTemplate"></ng-container>
  </ng-container>
</ng-container>

<!-- dropdown template -->
<ng-template #dropdownTemplate>
  <mat-form-field
    class="regular-select full-width"
    appearance="outline"
    [ngStyle]="{ width: width }"
    tupMatFormField
    fxFlex
    matTooltip="{{ showTooltip() }}"
    title=""
  >
    <mat-label
      >{{ title }} <strong>{{ selectedCount() }}</strong></mat-label
    >
    <mat-select
      #selector
      [(value)]="default"
      (openedChange)="onDropdownToggle($event)"
    >
      <mat-select-trigger>
        {{ showSelected() }}
      </mat-select-trigger>

      <mat-option
        *ngIf="topOption"
        [value]="topOption.id"
        (click)="topOption.selected = !topOption.selected; selector.open()"
      >
        <mat-slide-toggle
          style="user-select: none"
          disableRipple
          color="primary"
          [(ngModel)]="topOption.selected"
          [disabled]="topOption.disabled"
          (click)="onMenuItemClick($event)"
        >
          <strong>
            {{
              topOption.selected && topOption.selectedLabel
                ? topOption.selectedLabel
                : topOption.label
            }}
          </strong>
        </mat-slide-toggle>
      </mat-option>

      <mat-divider *ngIf="topOption"></mat-divider>
      <mat-option
        *ngFor="let option of options"
        [value]="option.id"
        (click)="option.selected = !option.selected; selector.open()"
      >
        <!-- checkbox indicating multi select -->
        <mat-checkbox
          *ngIf="multi"
          style="user-select: none"
          disableRipple
          tupMatCheckbox
          appearance="small"
          color="primary"
          [(ngModel)]="option.selected"
          [disabled]="option.disabled"
          (click)="onMenuItemClick($event)"
        >
          {{
            option.selected && option.selectedLabel
              ? option.selectedLabel
              : option.label
          }}
        </mat-checkbox>

        <!-- single select radio button-->
        <mat-radio-button
          *ngIf="!multi"
          style="user-select: none"
          disableRipple
          tupMatCheckbox
          appearance="small"
          color="primary"
          [checked]="option.selected"
          (change)="onRadioButtonChange(option, $event)"
          [disabled]="option.disabled"
          (click)="onMenuItemClick($event)"
        >
          {{
            option.selected && option.selectedLabel
              ? option.selectedLabel
              : option.label
          }}
        </mat-radio-button>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- listbox template -->
<ng-template #listTemplate>
  <h4
    *ngIf="title"
    class="title"
  >
    {{ title }}
  </h4>

  <mat-selection-list
    #shoes
    [multiple]="false"
  >
    <mat-list-option
      *ngFor="let option of options"
      [value]="option"
    >
      <div
        fxFlex="column"
        fxLayoutAlign="start center"
      >
        <mat-checkbox
          disableRipple
          tupMatCheckbox
          appearance="small"
          color="primary"
          [(ngModel)]="option.selection"
          (ngModelChange)="onChange()"
        >
          {{ option.label }}
        </mat-checkbox>
      </div>
    </mat-list-option>
  </mat-selection-list>
</ng-template>

<!-- button template -->
<ng-template #buttonTemplate>
  <button
    tupMatButton
    mat-icon-button
    appearance="nano"
    [matMenuTriggerFor]="menu"
    (menuOpened)="onDropdownToggle(true)"
    (menuClosed)="onDropdownToggle(false)"
    class="trigger-btn"
  >
    <mat-icon>{{ buttonIcon }}</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div
      *ngIf="topOption"
      fxLayout="column"
      fxLayoutGap="8px"
      class="toggle-wrapper filter-container"
    >
      <label *ngIf="buttonTemplateToggleTitle">{{
        buttonTemplateToggleTitle
      }}</label>
      <div class="option-wrapper">
        <span class="toggle-label"> Title </span>
        <mat-slide-toggle
          style="user-select: none"
          disableRipple
          color="primary"
          [(ngModel)]="topOption.selected"
          [disabled]="topOption.disabled"
          (click)="onMenuItemClick($event)"
        >
        </mat-slide-toggle>
        <span class="toggle-label"> Coding </span>
      </div>
    </div>

    <mat-divider *ngIf="topOption"></mat-divider>
    <div
      class="options-wrapper filter-container"
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <label *ngIf="buttonTemplateOptionsTitle">{{
        buttonTemplateOptionsTitle
      }}</label>
      <div>
        <mat-option
          *ngFor="let option of options"
          [value]="option.id"
          (click)="option.selected = !option.selected"
        >
          <!-- checkbox indicating multi select -->
          <mat-checkbox
            *ngIf="multi"
            style="user-select: none"
            disableRipple
            tupMatCheckbox
            appearance="small"
            color="primary"
            [(ngModel)]="option.selected"
            [disabled]="option.disabled"
            (click)="onMenuItemClick($event)"
          >
            {{
              option.selected && option.selectedLabel
                ? option.selectedLabel
                : option.label
            }}
          </mat-checkbox>

          <!-- single select radio button-->
          <mat-radio-button
            *ngIf="!multi"
            style="user-select: none"
            disableRipple
            tupMatCheckbox
            appearance="small"
            color="primary"
            [checked]="option.selected"
            (change)="onRadioButtonChange(option, $event)"
            [disabled]="option.disabled"
            (click)="onMenuItemClick($event)"
          >
            {{
              option.selected && option.selectedLabel
                ? option.selectedLabel
                : option.label
            }}
          </mat-radio-button>
        </mat-option>
      </div>
    </div>
  </mat-menu>
</ng-template>
