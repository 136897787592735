<div
  class="import-options-container"
  fxLayoutGap="4px"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div class="import-options-header">
    What type of target metric do you wish to preserve?
  </div>
  <div class="import-options">
    <mat-radio-group
      fxLayout="row"
      fxLayoutAlign="center center"
      [(ngModel)]="preservedMetric"
    >
      <mat-radio-button
        *ngFor="let option of importOptions"
        [value]="option.value"
        [checked]="option.value === preservedMetric"
        >{{ option.label }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
</div>
