import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface InfoMessageDialogModel {
  template: number; // 0 - broadcast, 1 - esgScore, 2 - importedMedia
  title?: string;
  content?: string[];
}

@Component({
  selector: 'info-message-dialog',
  templateUrl: './info-message-dialog.component.html',
  styleUrls: ['./info-message-dialog.component.scss'],
})
export class InfoMessageDialogComponent implements OnInit {
  title: string;
  template: number;
  content: SafeHtml[];

  constructor(
    public dialogRef: MatDialogRef<InfoMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoMessageDialogModel,
    private sanitizer: DomSanitizer
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.template = this.data.template;

    switch (this.template) {
      case 0:
        this.title = 'Info: TV/Radio have natural delivery';
        break;
      case 1:
        this.title = 'Info | ESG score';
        break;
      case 2:
        this.content = this.data.content.map((data) =>
          this.sanitizer.bypassSecurityTrustHtml(data)
        );
        this.title = this.data.title || 'Imported file details';
        break;
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
