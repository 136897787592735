import { DocumentSchedule } from '../models/document.model';
import {
  CostMethod,
  EMPTY_COSTS,
  EMPTY_RESULTS,
  Metrics,
  Result,
} from './result';
import { ScheduleTotal, ScheduleTotalTag } from './schedule-total';
import { SpotplanSchedules } from './spotplan-schedules';
import { Target } from './target';
import { ScheduleVehicle } from './vehicle';
import uniqid from 'uniqid';

export const EMPTY_SCHEDULE: DocumentSchedule = {
  name: 'Plan 1',
  vehicles: [],
  scheduleTotal: [],
  spotplans: [],
};

export const TOTAL_SCHEDULE_NAME: string = 'tots';
export const SCH_DELIMITER: string = '--';

export class Schedule {
  id: string;
  name: string;
  vehicles: ScheduleVehicle[] = [];
  scheduleTotal: ScheduleTotal[] = [];
  spotplans: SpotplanSchedules;

  totalsRecalcRequired: boolean = false;

  constructor(name: string) {
    this.name = name;
    this.id = uniqid();
    this.spotplans = new SpotplanSchedules();
  }

  addInserts(target: Target, vehicleId: string, inserts: number) {
    const veh = this.vehicle(target, vehicleId);
    veh.result.addResults({ inserts });
  }

  addCosts(
    target: Target,
    vehicleId: string,
    value: number,
    costMethod: CostMethod,
    costTargetId?: string
  ) {
    const veh = this.vehicle(target, vehicleId);
    const method: string = CostMethod[costMethod];
    veh.result.addResults({ [method]: value, costMethod, costTargetId });
  }

  addResults(target: Target, vehicleId: string, values: Metrics) {
    const veh = this.vehicle(target, vehicleId);
    veh.result.addResults(values);
  }

  vehicleResultsDirty() {
    this.vehicles.forEach((vehicle) => {
      vehicle.result.addResults({ dirty: true });
    });
  }

  // copy inserts, duration and cost from all schedule vehicles under the given target into all in the toTargets list
  copyVehicleMetricsFromTarget(fromTarget: Target, toTargets: Target[]) {
    // for each target in the list (skipping the existing one we're copying from)
    toTargets.forEach((toTarget) => {
      // skip our source copy
      if (toTarget.id !== fromTarget.id) {
        // get all the schedule vehicles holding metrics for the fromTarget
        const vehs = this.vehicles.filter(
          (veh) => veh.targetId === fromTarget.id
        );

        // for each of the vehicles, create a veh for the toTarget and populate with stuff
        vehs.forEach((fromVehicle) => {
          // copy its inserts, costs and duration
          const toVehicle = this.vehicle(toTarget, fromVehicle.vehicleId); // create a schedule vehicle for this target
          toVehicle.result.addResults(fromVehicle.result);
          toVehicle.result.addResults({
            impressions: 0,
            reach: 0,
            population: toTarget.population,
          });
        });
      }
    });
  }

  // all the inserts (reach and impressions) for vehicles against the given target
  clearInserts(target: Target) {
    const vehs = this.vehicles.filter((veh) => veh.targetId === target.id);
    vehs.forEach((v) => {
      v.result.addResults(EMPTY_RESULTS);
    });
  }

  // clear all the totals for the given target.  Multi Survey totals cannot be cleared
  clearTotals(
    target: Target,
    preserveInserts?: boolean,
    clearCost: boolean = false
  ) {
    const tots = this.scheduleTotal.filter((tot) => tot.targetId === target.id);
    let inserts;
    tots.forEach((tot) => {
      if (tot.tag !== ScheduleTotalTag.multiSurvey) {
        inserts = preserveInserts ? tot.result.inserts : EMPTY_RESULTS.inserts;
        tot.result.addResults(clearCost ? EMPTY_COSTS : EMPTY_RESULTS);
        tot.result.addResults({ inserts });
      }
    });
  }

  clearAll() {
    this.vehicles = [];
    this.spotplans.clearAll();
  }

  // get the vehicle if already in the schedule, else create a new one and return it, also target specific
  vehicle(target: Target, vehicleId: string): ScheduleVehicle {
    let veh = this.vehicles.find(
      (v) => v.vehicleId === vehicleId && v.targetId === target.id
    );
    if (!veh) {
      this.vehicles.push({
        vehicleId,
        targetId: target.id,
        result: new Result(target.population),
      });
      veh = this.vehicles[this.vehicles.length - 1];
    }
    return veh;
  }

  getTotal(): ScheduleTotal {
    return this.scheduleTotal.find(
      (tot) => tot.group === 'total' && tot.tag === ScheduleTotalTag.total
    );
  }

  getScheduleTotal(
    group: string,
    tag: ScheduleTotalTag | ScheduleTotalTag[],
    target: Target
  ): ScheduleTotal {
    let sched = this.scheduleTotal.find(
      (sched) =>
        sched.targetId === target.id &&
        sched.group === group &&
        (Array.isArray(tag) ? tag.includes(sched.tag) : tag === sched.tag)
    );

    if (!sched && target) {
      return this.addScheduleTotal(
        group,
        Array.isArray(tag) ? tag[0] : tag,
        target
      );
    }
    return sched;
  }

  addScheduleTotal(
    group: string,
    tag: ScheduleTotalTag,
    target: Target
  ): ScheduleTotal {
    this.scheduleTotal.push({
      group,
      tag,
      targetId: target.id,
      result: new Result(target.population),
    });
    return this.scheduleTotal[this.scheduleTotal.length - 1];
  }

  removeScheduleTotal(
    group: string,
    tag: ScheduleTotalTag,
    target: Target
  ): boolean {
    const schIndex = this.scheduleTotal.findIndex(
      (sched) =>
        sched.targetId === target.id &&
        sched.group === group &&
        tag === sched.tag
    );
    if (schIndex !== -1) {
      this.scheduleTotal.splice(schIndex, 1);
    }
    return true;
  }

  asDocument(): DocumentSchedule {
    const document: DocumentSchedule = {
      name: this.name,
      vehicles: this.vehicles,
      scheduleTotal: this.scheduleTotal,
      spotplans: this.spotplans.asDocument(),
    };
    return document;
  }
}
