import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ExportFileType, ExportType } from 'src/app/models/export.model';

@Component({
  selector: 'base-step',
  templateUrl: './base-step.component.html',
  styleUrls: ['./base-step.component.scss'],
})
export class BaseStepComponent implements OnInit {
  @Output() navigation: EventEmitter<number> = new EventEmitter<number>();
  @Output() showProcessing: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() pulse: EventEmitter<number> = new EventEmitter<number>();

  label: string;
  constructor() {}

  ngOnInit(): void {}

  loadData(logText: string = '') {
    logText ? console.log(`Loading ${logText}`) : null;
  }

  export(fileType: ExportFileType, type: ExportType) {}

  saveData(passive: boolean = false): Observable<boolean> {
    return of(true);
  }
}
