import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface TargetLoading {
  target: string;
  isLoading: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RequestLoadingService {
  private loadingSubject = new BehaviorSubject<TargetLoading>({
    target: 'global',
    isLoading: false,
  });
  public loading$ = this.loadingSubject.asObservable();

  public setLoading(targetLoading: TargetLoading): void {
    this.loadingSubject.next(targetLoading);
  }
}
