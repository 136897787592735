import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DocumentSurvey, DocumentTarget } from 'src/app/models/document.model';
import { TargetStatement } from 'src/app/models/engine.models';
import { MultiTargetEvaluationResponse } from 'src/app/models/engine.target-evaluation.models';
import { EngineService } from 'src/app/services/engine.service';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';

export interface NewCodeBuilderDialogModel {
  survey: DocumentSurvey;
  title: string;
  coding: string;
  confirmText?: string;
  dialogtitle?: string;
  population: number;
  sample: number;
  jsonCoding?: TargetStatement;
}

@Component({
  templateUrl: './new-code-builder-dialog.component.html',
  styleUrls: ['./new-code-builder-dialog.component.scss'],
})
export class NewCodeBuilderDialogComponent implements OnInit {
  readonly NEW_TARGET_TITLE = 'New title';
  readonly NEW_TARGET_CODING = 'Enter your code here';

  public selectedSurvey: DocumentSurvey;
  public resultTarget: DocumentTarget = null;

  public title: string = '';
  public coding: string = '';
  public confirmText: string = '';
  public dialogTitle: string = '';

  // TODO: Figure out what this is for
  public codingError: SnackbarGenericOptionModel = null;
  public processing = false;
  public isResultDirty = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NewCodeBuilderDialogModel,
    public dialogRef: MatDialogRef<NewCodeBuilderDialogComponent>,
    private engineService: EngineService
  ) {
    this.selectedSurvey = this.data.survey;
    this.resultTarget = {
      title: this.data.title || '',
      coding: this.data.coding || '',
      survey: this.data.survey,
      manual: true,
      population: this.data.population || 0,
      sample: this.data.sample || 0,
      jsonCoding: this.data.jsonCoding,
    };

    this.title = this.resultTarget.title;
    this.coding = this.resultTarget.coding;
    this.confirmText = this.data.confirmText || 'Add';
    this.dialogTitle = this.data.dialogtitle || 'Add own code';

    if (this.resultTarget.coding) {
      this.testCoding().subscribe();
    }
  }

  ngOnInit(): void {}

  public onButtonClick(ok: boolean): void {
    this.updateTitleAndCoding();

    if (!ok) {
      this.dialogRef.close(null);
      return;
    }

    this.testCoding().subscribe((success) => {
      if (success) {
        const data: NewCodeBuilderDialogModel = {
          survey: this.data.survey,
          title: this.resultTarget.title,
          coding: this.resultTarget.coding,
          population: this.resultTarget.population,
          sample: this.resultTarget.sample,
          jsonCoding: this.resultTarget.jsonCoding,
        };
        this.dialogRef.close(data);
      }
    });
  }

  public onTestCoding(): void {
    this.testCoding().subscribe();
  }

  public testCoding(): Observable<boolean> {
    this.updateTitleAndCoding();

    return new Observable((observable) => {
      // already has coding, no need to do it again
      this.resultTarget.title = this.title;
      this.resultTarget.coding = this.coding;

      if (this.resultTarget.jsonCoding) {
        observable.next(true);
        observable.complete();
        return;
      }

      this.processing = true;
      this.resultTarget.jsonCoding = null;

      this.engineService
        .getTargetCoding([this.resultTarget])
        .subscribe((success) => {
          if (success) {
            this.engineService
              .getMultiTargetEvaluation([this.resultTarget], true)
              .pipe(
                tap(
                  () => (
                    (this.processing = false), (this.isResultDirty = false)
                  )
                )
              )
              .subscribe((result: MultiTargetEvaluationResponse) => {
                if (result.status.success) {
                  this.resultTarget.population = result.populations[0];
                  this.resultTarget.sample = result.samples[0];
                } else {
                  this.setCodingError(result.status.message);
                  this.resultTarget.population =
                    this.resultTarget.sample =
                    this.resultTarget.jsonCoding =
                      null;
                }

                observable.next(result.status.success);
                observable.complete();
              });
          } else {
            this.setCodingError(
              'There are problems with your coding statement'
            );
          }
        });
    });
  }

  private updateTitleAndCoding(): void {
    this.coding = this.coding.replace(/[\n\t]+/g, ' '); // remove tabs and newlines
    this.title = this.title || this.coding; // missing title, then just use the coding
    this.setCodingError();
  }

  private setCodingError(message?: string) {
    this.codingError = message
      ? {
          type: 'error',
          align: 'left',
          message: `${message}.  Please contact your account owner`,
          width: '100%',
          icon: StatusSnackbarIcon.Error,
        }
      : null;
  }
}
