<div
  class="container compare-by-table-wrapper"
  [style.--columns-number]="tableColumnsNumber"
  #compareByAudiencesTable
>
  <tree-table
    class="tree-table primary-color-string compare-by-table"
    [inlineMenuWithIcons]="true"
    treeTitle="Metrics"
    treeWidth="238px"
    (treeHeaderMenuClick)="onTreeHeaderMenuClick($event)"
    [treeHeaderInlineMenu]="targetsInlineMenu"
    [data]="tableData"
    [showProcessing]="processing"
    [columns]="tableColumns"
    [disableDragging]="true"
    [allowSelectAll]="false"
    [unitsText]="unitsText"
  >
  </tree-table>
</div>
