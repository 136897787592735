import { Component, OnInit, Input } from '@angular/core';

export enum StatusSnackbarIcon {
  Warning,
  Error,
  Success,
}

export interface SnackbarGenericOptionModel {
  type: 'error' | 'warning' | 'success';
  align?: 'left' | 'center' | 'right';
  message: string;
  icon: StatusSnackbarIcon;
  width?: string;
}

@Component({
  selector: 'snackbar-generic',
  templateUrl: './snackbar-generic.component.html',
  styleUrls: ['./snackbar-generic.component.scss'],
})
export class SnackbarGenericComponent implements OnInit {
  @Input() data: SnackbarGenericOptionModel;

  constructor() {}

  iconSelection: { [key: number]: { icon: string; class?: string } } = {
    [StatusSnackbarIcon.Warning]: { icon: 'error' },
    [StatusSnackbarIcon.Error]: { icon: 'cancel_icon' },
    [StatusSnackbarIcon.Success]: { icon: 'check_circle' },
  };
  icon: { icon: string; class?: string };

  ngOnInit(): void {
    this.icon = this.iconSelection[this.data.icon];
    this.data.align = this.data.align || 'left';
  }
}
