<div
  class="survey-info-dialog"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    [title]="type === SurveyInfoType.copyright ? 'Survey(s) copyright' : 'Info'"
    class="title-bar"
  >
    <span fxFlex></span>
    <button
      data-tracking="copyright-close"
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose('cancel')"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>

  <!-- copright display -->
  <mat-dialog-content
    fxLayout="column"
    *ngIf="type === SurveyInfoType.copyright"
  >
    <div class="copyright-content">
      <p class="surveys-available-text">Surveys available:</p>
      <div
        class="survey-copyright-container"
        *ngFor="let survey of surveys"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <p class="survey-copyright__info-title">{{ survey.surveyTitle }}</p>
        </div>

        <div>
          <span *ngIf="!survey.copyrightInfo"
            >Provided by:
            <strong>{{ survey.provider }}.</strong>
          </span>
          <p class="survey-copyright__info-title">{{ survey.copyrightInfo }}</p>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <!-- fill survey info display -->
  <mat-dialog-content
    fxLayout="column"
    *ngIf="type === SurveyInfoType.info"
    class="survey-info__info"
  >
    <div id="print-section">
      <h2 class="survey-info__info-title">
        {{ surveys[0].surveyTitle }}
      </h2>

      <p class="survey-info__info-content">
        {{ surveys[0].surveyInfo }}
      </p>
    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions
    *ngIf="type === SurveyInfoType.copyright"
    class="app-dialog-actions"
    data-tracking="surveyinfo-close"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="8px"
  >
    <button
      mat-raised-button
      tupMatButton
      color="primary"
      class="cancel btn-action"
      (click)="onClose('cancel')"
    >
      Close
    </button>
  </mat-dialog-actions>

  <mat-dialog-actions
    *ngIf="type === SurveyInfoType.info"
    class="app-dialog-actions"
    data-tracking="surveyinfo-close"
    fxLayout="row"
    fxLayoutAlign="space-around center"
    fxLayoutGap="8px"
  >
    <button
      mat-stroked-button
      tupMatButton
      class="cancel btn-action"
      (click)="onClose('cancel')"
    >
      Cancel
    </button>
    <div fxFlex></div>
    <button
      mat-stroked-button
      ngxPrint
      tupMatButton
      class="cancel btn-action"
      [printTitle]="surveys[0].surveyTitle"
      [printStyle]="{ p: { 'white-space': 'break-spaces' } }"
      printSectionId="print-section"
      (click)="onClose('print')"
    >
      Print
    </button>

    <button
      mat-raised-button
      tupMatButton
      color="primary"
      class="btn-action"
      (click)="onClose('download')"
    >
      <mat-icon class="material-icons-outlined">file_download</mat-icon>
      Save
    </button>
  </mat-dialog-actions>
</div>
