import { Component, Inject, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

export enum StatusIcon {
  Info,
  Success,
  Warning,
  Error,
}

export interface SnackbarOptionsModel {
  type?: 'success' | 'warning' | 'error' | 'info';
  title?: string;
  message: string;
  duration?: number;
  icon?: StatusIcon;
  dismissButton?: string;
}

@Component({
  selector: 'app-snackbar-customised',
  templateUrl: './snackbar-customised.component.html',
  styleUrls: ['./snackbar-customised.component.scss'],
})
export class SnackbarCustomisedComponent implements OnInit {
  iconSelection: { [key: number]: { icon: string; class?: string } } = {
    [StatusIcon.Success]: { icon: 'check_circle' },
    [StatusIcon.Warning]: { icon: 'error' },
    [StatusIcon.Error]: { icon: 'cancel_icon' },
    [StatusIcon.Info]: { icon: '' },
  };
  icon: { icon: string; class?: string };

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarCustomisedComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarOptionsModel
  ) {}

  ngOnInit(): void {
    this.icon =
      this.iconSelection[this.data.icon] || this.iconSelection[StatusIcon.Info];
  }
}
