<h3>{{ demo.class }}</h3>

<ul>
  <li
    class="margin"
    *ngFor="let value of demo.values"
  >
    <mat-checkbox
      [(ngModel)]="value.selected"
      tupMatCheckbox
      appearance="small"
      color="primary"
      >{{ value.value }}</mat-checkbox
    >
  </li>
</ul>

<!-- <mat-selection-list [multiple]="false"> -->
<!-- <mat-option color="primary" > -->
<!-- <mat-checkbox  [(ngModel)]="value.selected" tupMatCheckbox appearance="small" color="primary"> {{value.value}} {{value.dataReference}}</mat-checkbox> -->
<!-- </mat-option> -->
<!-- </mat-selection-list> -->
