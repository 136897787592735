<div
  fxLayout="column"
  fxLayoutGap="5px"
>
  <div
    fxLayout="row"
    fxLayoutAlign="end"
  >
    <mat-icon
      (click)="onClose()"
      class="clickable-icon"
      >close</mat-icon
    >
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
  >
    <h1 *ngIf="targetTitle">Target: {{ targetTitle }}</h1>
    <color-block
      [color]="chosenColor"
      (onChangeComplete)="handleColorChange($event)"
      [colors]="colors"
    >
    </color-block>
  </div>
</div>
