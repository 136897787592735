<div class="doc-container">
  <tup-document-storage
    #latestCampaignTable
    [options]="campaignTableOptions"
  >
    <ng-template tupDocumentStorageCardContent>
      <ng-container *ngTemplateOutlet="filter"></ng-container>
    </ng-template>
    <!-- Content projection: inserts messaging / signposting indicating if / when there is no content to display -->
    <ng-template tupDocumentStorageCardNoContent>
      <div
        class="no-search-results"
        *ngIf="latestCampaignTable.nameFilterService.value; else getStarted"
      >
        <ng-container *ngTemplateOutlet="filter"></ng-container>
        <p
          class="mat-error"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-icon fontSet="material-icons-outlined">warning</mat-icon>
          <span>No campaigns yet</span>
        </p>
      </div>
      <ng-template #getStarted>
        <div
          class="get-started"
          data-tracking="no-campaigns-yet"
          fxFlex
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="24px"
          *ngIf="
            !(latestCampaignTable.dataSource.loading | async);
            else loading
          "
        >
          <img src="assets/images/campaing_dashboard_icon.svg" />
          <h3>Get started</h3>
          <p class="description">
            As you create new campaigns, they will be conveniently displayed
            here, providing you with quick access to your most recent data
            analysis. Embark on your analytical adventure and transform this
            space into a valuable resource for informed decision-making.
          </p>
          <button
            data-tracking="start-campaign-btn"
            mat-flat-button
            tupMatButton
            class="documents-header-btn"
            color="primary"
            (click)="createNewCampaign()"
          >
            <mat-icon>add</mat-icon>
            Start campaign
          </button>
        </div>
      </ng-template>
    </ng-template>

    <!-- Content projection: inserts a custom card 'action' after the table, in the card footer -->
    <ng-template
      tupDocumentStorageCardActions
      *ngIf="latestCampaignTable.dataSource?.length"
    >
      <!-- placeholder, nothing to see here -->
      <div
        fxFlex
        fxLayout="row"
        class="see-all"
        (click)="seeAllClick()"
        fxLayoutAlign="start center"
      >
        <mat-icon color="primary">arrow_forward</mat-icon>
        See all campaigns ({{ latestCampaignTable.dataSource.length }})
      </div>
    </ng-template>
  </tup-document-storage>
</div>
<ng-template #filter>
  <tup-document-filter
    data-tracking="report-search"
    formFieldAppearance="outline"
    formFieldLabel="Search campaigns"
    [filterFor]="latestCampaignTable"
  >
  </tup-document-filter>
</ng-template>
<ng-template #loading><div class="get-started"></div></ng-template>
