import { Component, Input, OnInit } from '@angular/core';
import { SurveyMetrics } from 'src/app/models/planning.models';

@Component({
  selector: 'audience-toolbar',
  templateUrl: './audience-toolbar.component.html',
  styleUrls: ['./audience-toolbar.component.scss'],
})
export class AudienceToolbarComponent implements OnInit {
  @Input() survey: SurveyMetrics;

  constructor() {}

  ngOnInit(): void {}
}
