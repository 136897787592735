// https://stackoverflow.com/questions/4492678/swap-rows-with-columns-transposition-of-a-matrix-in-javascript
export const transpose = (original) => {
  const copy = [];
  for (let i = 0; i < original.length; ++i) {
    for (let j = 0; j < original[i].length; ++j) {
      // skip undefined values to preserve sparse array
      if (original[i][j] === undefined) {
        continue;
      }
      // create row if it doesn't exist yet
      if (copy[j] === undefined) {
        copy[j] = [];
      }
      // swap the x and y coords for the copy
      copy[j][i] = original[i][j];
    }
  }
  return copy;
};

export const scaleFunction = (data, dataMinMax) => {
  const scaledArray = [];
  data.forEach((element) =>
    scaledArray.push(
      Math.abs(
        ((element - dataMinMax.min) / (dataMinMax.max - dataMinMax.min)) * 50
      )
    )
  );
  return scaledArray;
};

export const findAllOccurences = (array, valueToFind) => {
  const indexList = [];

  for (let i = 0; i < array.length; i++) {
    if (array[i] === valueToFind) {
      indexList.push(i);
    }
  }

  return indexList;
};
