import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { filter, first } from 'rxjs/operators';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import {
  ENVIRONMENT,
  TupUserPreferenceStorageService,
} from '@telmar-global/tup-user-preference-storage';
import { combineLatest } from 'rxjs';
import { cloneDeep, uniqBy } from 'lodash';
import { CodebookSelectionService } from '../../../services/codebook-selection.service';
import * as _ from 'lodash';
import { ChangeContext, Options } from 'ng5-slider';
import { DocumentFullSurvey } from 'src/app/models/document.model';
import { CodebookService } from 'src/app/services/codebook.service';
import { SurveyMediaTypesTableDialogComponent } from '../survey-media-types-table-dialog/survey-media-types-table-dialog.component';
import { StatusSnackbarIcon } from '../snackbar-generic/snackbar-generic.component';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { QuestionDialogModelOptions } from 'src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';

interface FilterListItem {
  name: string;
  value: number;
}

interface SurveyPreferenceItem {
  name: string;
  value: number;
}

const SURVEY_TYPES: FilterListItem[] = [
  {
    name: 'All',
    value: 0,
  },
  {
    name: 'Trendable',
    value: 1,
  },
  {
    name: 'Non-Trendable',
    value: 2,
  },
  {
    name: 'Multibased',
    value: 3,
  },
  {
    name: 'Mappable',
    value: 4,
  },
];

const SURVEY_ORDERS: FilterListItem[] = [
  {
    name: 'By Recently Used',
    value: 0,
  },
  {
    name: 'By Name Asc',
    value: 1,
  },
  {
    name: 'By Name Desc',
    value: 2,
  },
  {
    name: 'By Year Asc',
    value: 3,
  },
  {
    name: 'By Year Desc',
    value: 4,
  },
  {
    name: 'Hit Counts Asc',
    value: 5,
  },
  {
    name: 'Hit Counts Desc',
    value: 6,
  },
];

const SURVEY_ORDERS_SIMPLIFIED: FilterListItem[] = [
  {
    name: 'Recently used',
    value: 0,
  },
  {
    name: 'Name ascending',
    value: 1,
  },
  {
    name: 'Name descending',
    value: 2,
  },
  {
    name: 'Year ascending',
    value: 3,
  },
  {
    name: 'Year descending',
    value: 4,
  },
  {
    name: 'Hit Counts Asc',
    value: 5,
  },
  {
    name: 'Hit Counts Desc',
    value: 6,
  },
  {
    name: 'Release date',
    value: 7,
  },
];

const SURVEY_PREFERENCES: SurveyPreferenceItem[] = [
  {
    name: 'Always ask me to select surveys',
    value: 0,
  },
  {
    name: 'Always open my last used surveys for new plans',
    value: 1,
  },
];

export interface ChangeSurveysResult {
  surveys: DocumentFullSurvey[];
  clearExisting: boolean;
}

export interface SurveySelectorEnvironment {
  userPreferences: {
    lastTenSurveysUsed: string;
    selectedSurveysUsed: string;
  };
}

export enum singleSelectionFilterTypes {
  providerFilter,
  languageFilter,
  yearFilter,
}

export interface SingleSelectionFilter {
  type: singleSelectionFilterTypes;
  label: string;
  visible: boolean;
}

enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

@Component({
  selector: 'survey-selection-dialog',
  templateUrl: './survey-selection-dialog.component.html',
  styleUrls: ['./survey-selection-dialog.component.scss'],
})
export class SurveySelectionDialogComponent implements OnInit {
  public allSurveyData: DocumentFullSurvey[] = [];
  public surveyData: DocumentFullSurvey[] = [];

  public filteredSurveyData: DocumentFullSurvey[] = [];
  @ViewChild('filteredSurveysContainer')
  filteredSurveyContainer: SelectContainerComponent;
  public selectedSurveys: DocumentFullSurvey[] = [];

  public selectedSurveyData: DocumentFullSurvey[] = [];
  @ViewChild('selectedSurveysContainer')
  selectedSurveysContainer: SelectContainerComponent;
  public activeSelectedSurveys: DocumentFullSurvey[] = [];

  @ViewChild('singleSelectionFiltersDialog')
  singleSelectionFiltersDialog: TemplateRef<any>;
  public startValueYearInterval: number = 0;
  public endValueYearInterval: number = 0;
  public sliderOptions: Options = {
    floor: 0,
    ceil: 0,
    step: 1,
    animate: false,
    draggableRange: true,
    combineLabels: (minValue: string, maxValue: string): string => {
      return minValue === maxValue ? minValue : minValue + ' - ' + maxValue;
    },
  };
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  public selectedSurveyProvider: string;
  public selectedSurveyTrendable: boolean;

  public disableAllSurveys: boolean = false;
  public disableNonMrfSurveys: boolean = false;
  public containsGlobalDemoMap: boolean = false;

  public selectedLanguage = 0;
  public languages: FilterListItem[] = [];
  public selectedProvider = 0;
  public providers: FilterListItem[] = [];
  public selectedSurveyType = 0;
  public surveyTypes: FilterListItem[] = SURVEY_TYPES;
  public surveyPreferences: FilterListItem[] = SURVEY_PREFERENCES;
  public selectedSurveyPreference = 0;

  public singleSelectionFiltersSelectedProvider = 0;
  public singleSelectionFiltersSelectedLanguage = 0;
  public singleSelectionFilters: SingleSelectionFilter[] = [];
  public showNoResultsMessage: boolean = false;
  public sliderRefreshed: boolean = false;
  public filtersDialogOpen: boolean = false;

  public selectedSurveyOrder = 0;
  public surveyOrders: FilterListItem[];
  public selectedSearchYearFrom = 0;
  public selectedSearchYearTo = 0;
  public searchYears: FilterListItem[] = [];
  public reversedSearchYears: FilterListItem[] = [];
  public searchText = '';
  public isLoading = false;
  public recentSurveys: DocumentFullSurvey[] = [];
  public filterSurveysBy: Map<string, boolean> = new Map();
  public maxSelectedTrendableSurveys: number;
  public defaultSurveyPreference: number;

  public filtersDialogRef: MatDialogRef<any>;

  public shouldRenderAdvancedFiltersContent = false;

  constructor(
    public dialogRef: MatDialogRef<SurveySelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      previouslySelectedSurveys: DocumentFullSurvey[];
      filterSurveysBy: Map<string, boolean>;
      maxSelectedTrendableSurveys: number;
      defaultSurveyPreference: number;
      dialogService: DialogService;
    },
    @Inject(ENVIRONMENT) private environment: SurveySelectorEnvironment,
    private codebookSelectionService: CodebookSelectionService,
    private codebookService: CodebookService,
    private messageService: TupUserMessageService,
    private userPreferenceService: TupUserPreferenceStorageService,
    private dialog: MatDialog,
    private mediaplannerService: MediaPlannerService
  ) {
    this.filterSurveysBy = data.filterSurveysBy;
    this.surveyOrders = SURVEY_ORDERS_SIMPLIFIED;
    this.maxSelectedTrendableSurveys = data.maxSelectedTrendableSurveys;
    this.selectedSurveyPreference = data.defaultSurveyPreference
      ? data.defaultSurveyPreference
      : 0;

    if (this.data.previouslySelectedSurveys.length > 0) {
      this.selectedSurveyData = data.previouslySelectedSurveys;
    }
  }

  ngOnInit(): void {
    this.loadSurveys();
    setTimeout(() => {
      this.shouldRenderAdvancedFiltersContent = true;
    }, 200);
  }

  public onButtonClick(): void {
    const selectedSurveys = this.selectedSurveys;
    if (selectedSurveys.length < 1) {
      this.messageService.openMessageDialog(
        'Please select a survey to start',
        'Alert'
      );
      return;
    }
    const onClose = () => {
      this.dialogRef.close({
        surveys: selectedSurveys,
        surveyPreference: {
          options: this.surveyPreferences,
          selectedOption: this.selectedSurveyPreference,
        },
      });
    };
    let surveysHaveChanged = false;
    let primarySurveyRemoved = false;
    let surveyWithVehiclesRemoved = false;
    const surveyCodes: string[] = [];
    this.mediaplannerService.plan.selectedSurveys.forEach((selectedSurvey) => {
      const availableSurvey = selectedSurveys.find(
        (val) =>
          val.code === selectedSurvey.code &&
          val.authorizationGroup === selectedSurvey.authorizationGroup
      );
      if (!availableSurvey) {
        surveysHaveChanged = true;
        surveyCodes.push(selectedSurvey.code);
        if (selectedSurvey.isPrimary) {
          primarySurveyRemoved = true;
        }
      }
    });
    const target = this.mediaplannerService.plan.targets[0];
    surveyWithVehiclesRemoved = !!target?.vehicles?.find((vehicle) =>
      surveyCodes.includes(vehicle.survey.code)
    );

    if (
      this.mediaplannerService.plan.selectedSurveys.length > 1 &&
      surveysHaveChanged &&
      (primarySurveyRemoved || surveyWithVehiclesRemoved)
    ) {
      const options: QuestionDialogModelOptions = {
        buttons: [
          { caption: 'Cancel', data: 'cancel' },
          { caption: 'Proceed', data: 'confirm', flat: true },
        ],
        closeButton: { caption: 'Close', data: 'cancel' },
        snackbar: {
          type: 'warning',
          message: `This survey uses audiences and/or media currently active in your campaign, removing this survey will also remove those audience and/or media items.`,
          align: 'center',
          icon: StatusSnackbarIcon.Warning,
        },
      };
      this.data.dialogService
        .confirmation('', 'Are you sure?', options)
        .afterClosed()
        .subscribe((dialogButton) => {
          if (dialogButton.data === 'confirm') {
            onClose();
          }
        });
    } else {
      onClose();
    }
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public resetFilters(): void {
    this.unsetFiltersAndSearch();
    this.refreshSurveyData();
    this.filterSurveys();
  }

  public async searchWord(): Promise<void> {
    const matchText = this.searchText.trim();

    this.isLoading = true;
    this.codebookSelectionService
      .getSurveys(this.filterSurveysBy, matchText)
      .subscribe(
        (data: DocumentFullSurvey[]) => {
          this.surveyData = data;
          this.filterSurveys();
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  public resetSearchText(): void {
    if (!this.searchText) {
      return;
    }

    this.searchText = '';
    this.refreshSurveyData();
    this.filterSurveys();
  }

  public onSurveyClick(survey: DocumentFullSurvey): void {
    if (this.disableAllSurveys && !survey.checked) {
      return;
    }

    // used !! to be sure it's boolean
    const isMrfGlobaldemomap: boolean = !!survey.meta['is-mrf-globaldemomap'];

    if (this.disableNonMrfSurveys && !isMrfGlobaldemomap) {
      return;
    }

    const noSelectedSurveys = this.selectedSurveys.length === 0;

    if (noSelectedSurveys) {
      this.disableNonMrfSurveys = isMrfGlobaldemomap;
      this.disableAllSurveys = !isMrfGlobaldemomap;
    }

    survey.checked = !survey.checked;

    if (survey.checked) {
      this.selectedSurveys.push(survey);
    } else {
      const index = this.selectedSurveys.findIndex(
        (item) =>
          item.code === survey.code &&
          item.authorizationGroup === survey.authorizationGroup
      );
      if (index !== -1) {
        this.selectedSurveys.splice(index, 1);
      }
    }

    if (this.selectedSurveys.length === 0) {
      this.disableNonMrfSurveys = false;
      this.disableAllSurveys = false;
    }

    // Update the corresponding survey in allSurveyData
    const allSurveyIndex = this.allSurveyData.findIndex(
      (item) =>
        item.code === survey.code &&
        item.authorizationGroup === survey.authorizationGroup
    );
    if (allSurveyIndex !== -1) {
      this.allSurveyData[allSurveyIndex].checked = survey.checked;
    }
  }

  public hasSelectedGlobalDemoMapSurvey(): boolean {
    return this.selectedSurveys.some(
      (survey) => survey.meta['is-mrf-globaldemomap'] === true
    );
  }

  public removeSelected(): void {
    if (!this.hasActiveSelectedSurveys()) {
      return;
    }
    const activeSelectedSurveyCodes = this.activeSelectedSurveys.map(
      (survey: DocumentFullSurvey) => this.formatUniqueSurveyKey(survey)
    );
    this.selectedSurveyData = this.selectedSurveyData.filter(
      (survey: DocumentFullSurvey) =>
        !activeSelectedSurveyCodes.includes(this.formatUniqueSurveyKey(survey))
    );

    const hasNoSelectedSurveys = this.selectedSurveyData.length < 1;
    if (hasNoSelectedSurveys) {
      this.selectedSurveyProvider = null;
      this.selectedSurveyTrendable = null;
      this.selectedSurveyType = 0;
      this.selectedProvider = 0;
    } else {
      if (this.selectedSurveyTrendable) {
        this.selectedProvider =
          this.providers.find(
            (provider: FilterListItem) =>
              provider.name === this.selectedSurveyProvider
          )?.value || 0;
        this.selectedSurveyType = 1; // Trendable
      }
    }

    this.updateFilteredSurveyData();
  }

  public moveUp() {
    if (!this.hasActiveSelectedSurveys()) {
      return;
    }

    if (this.hasMultipleActiveSelectedSurveys()) {
      this.showSelectOnlyOneSurveyDialog();
      return;
    }

    const selectedSurveyIndex = this.getSelectedSurveyIndex();
    if (selectedSurveyIndex === 0) {
      return;
    }

    const tempSelectedSurveyData = this.selectedSurveyData;
    const selectedSurvey = tempSelectedSurveyData[selectedSurveyIndex];
    const preSurvey = tempSelectedSurveyData[selectedSurveyIndex - 1];
    tempSelectedSurveyData[selectedSurveyIndex - 1] = selectedSurvey;
    tempSelectedSurveyData[selectedSurveyIndex] = preSurvey;
    this.selectedSurveyData = [...tempSelectedSurveyData];
  }

  public moveDown() {
    if (!this.hasActiveSelectedSurveys()) {
      return;
    }

    if (this.hasMultipleActiveSelectedSurveys()) {
      this.showSelectOnlyOneSurveyDialog();
      return;
    }

    const selectedSurveyIndex = this.getSelectedSurveyIndex();
    if (selectedSurveyIndex === this.selectedSurveyData.length - 1) {
      return;
    }

    const tempSelectedSurveyData = this.selectedSurveyData;
    const selectedSurvey = tempSelectedSurveyData[selectedSurveyIndex];
    const nextSurvey = tempSelectedSurveyData[selectedSurveyIndex + 1];
    tempSelectedSurveyData[selectedSurveyIndex + 1] = selectedSurvey;
    tempSelectedSurveyData[selectedSurveyIndex] = nextSurvey;
    this.selectedSurveyData = [...tempSelectedSurveyData];
  }

  public onAdvancedSearchExpandedChange(): void {
    setTimeout(() => {
      this.filteredSurveyContainer.update();
      if (this.selectedSurveysContainer) {
        this.selectedSurveysContainer.update();
      }
    }, 500);
  }

  public filterSurveys(): void {
    this.updateFilteredSurveyData();
    setTimeout(() => {
      this.checkSurveysThatAreSelected();
    }, 300);
  }

  public checkPreselectedSurveys(): void {
    setTimeout(() => {
      if (this.selectedSurveyData.length > 0) {
        this.filteredSurveyData.forEach((item) => {
          if (
            this.selectedSurveyData.some(
              (selectedItem) =>
                selectedItem.code === item.code &&
                selectedItem.authorizationGroup === item.authorizationGroup
            )
          ) {
            this.onSurveyClick(item);
          }
        });
      }
    }, 500);
  }

  public onClearSelectionClick(): void {
    this.filteredSurveyData.forEach((survey) => {
      if (survey.checked) {
        survey.checked = false;
      }
    });
    this.selectedSurveys = [];
    this.disableAllSurveys = false;
    this.disableNonMrfSurveys = false;
  }

  public checkSurveysThatAreSelected(): void {
    this.filteredSurveyData.forEach((item) => {
      if (
        this.selectedSurveys.some(
          (selectedItem) =>
            selectedItem.code === item.code &&
            selectedItem.authorizationGroup === item.authorizationGroup
        )
      ) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
  }

  public dbClickSelectedSurvey(): void {
    this.removeSelected();
  }

  private refreshSlider() {
    setTimeout(() => {
      this.manualRefresh.emit();
      this.sliderRefreshed = true;
    }, 300);
  }

  public openFiltersDialog(): void {
    this.filtersDialogOpen = true;
    this.sliderRefreshed = false;
    this.sliderOptions.ceil = Number(this.searchYears[0].name);
    this.sliderOptions.floor = Number(
      this.reverseYearArray(this.searchYears)[0].name
    );
    if (!this.startValueYearInterval) {
      this.startValueYearInterval = this.sliderOptions.floor;
    }
    if (!this.endValueYearInterval) {
      this.endValueYearInterval = this.sliderOptions.ceil;
    }
    this.filtersDialogRef = this.dialog.open(this.singleSelectionFiltersDialog);
    this.filtersDialogRef.afterClosed().subscribe(() => {
      this.filtersDialogOpen = false;
    });
    this.refreshSlider();
  }

  public resetSingleSelectionFiltersDialog(): void {
    this.singleSelectionFiltersSelectedProvider = 0;
    this.singleSelectionFiltersSelectedLanguage = 0;
    this.startValueYearInterval = Number(this.reversedSearchYears[0].name);
    this.endValueYearInterval = Number(this.searchYears[0].name);

    this.singleSelectionFilters = [];
    this.saveSingleSelectionFiltersDialog();
  }

  public removeFilter(index: number): void {
    const selectedFilterType = this.singleSelectionFilters[index].type;
    this.singleSelectionFilters.splice(index, 1);
    switch (selectedFilterType) {
      case singleSelectionFilterTypes.providerFilter:
        this.singleSelectionFiltersSelectedProvider = 0;
        break;
      case singleSelectionFilterTypes.languageFilter:
        this.singleSelectionFiltersSelectedLanguage = 0;
        break;
      case singleSelectionFilterTypes.yearFilter:
        this.startValueYearInterval = Number(this.reversedSearchYears[0].name);
        this.endValueYearInterval = Number(this.searchYears[0].name);
        break;
      default:
        break;
    }

    this.saveSingleSelectionFiltersDialog();
  }

  public onAvailableSurveysInfoIconClick() {
    if (this.selectedSurveys?.length && !this.isLoading) {
      this.dialog.open(SurveyMediaTypesTableDialogComponent, {
        data: {
          surveys: this.selectedSurveys,
        },
      });
    }
  }

  private addFiltersToArray() {
    const providerLabel = this.providers[this.selectedProvider].name;
    const languageFilterLabel = this.languages[this.selectedLanguage].name;
    const yearFilterLabel =
      this.startValueYearInterval !== this.endValueYearInterval
        ? `${this.startValueYearInterval} - ${this.endValueYearInterval}`
        : `${this.startValueYearInterval}`;
    this.singleSelectionFilters = [
      {
        type: singleSelectionFilterTypes.providerFilter,
        label: providerLabel,
        visible: this.selectedProvider !== 0,
      },
      {
        type: singleSelectionFilterTypes.languageFilter,
        label: languageFilterLabel,
        visible: this.selectedLanguage !== 0,
      },
      {
        type: singleSelectionFilterTypes.yearFilter,
        label: yearFilterLabel,
        visible:
          this.selectedSearchYearFrom !== 0 || this.selectedSearchYearTo !== 0,
      },
    ];
  }

  public saveSingleSelectionFiltersDialog(): void {
    this.selectedProvider = this.singleSelectionFiltersSelectedProvider;
    this.selectedLanguage = this.singleSelectionFiltersSelectedLanguage;
    if (this.searchYears?.length && this.reversedSearchYears?.length) {
      this.selectedSearchYearFrom = this.reversedSearchYears.find(
        (year) => year.name === this.startValueYearInterval.toString()
      ).value;
      this.selectedSearchYearTo = this.searchYears.find(
        (year) => year.name === this.endValueYearInterval.toString()
      ).value;
    }

    this.addFiltersToArray();
    this.filterSurveys();
    this.filtersDialogRef.close();
  }

  public onUserChange(changeContext: ChangeContext): void {
    this.startValueYearInterval = changeContext.value;
    this.endValueYearInterval = changeContext.highValue;
  }

  private loadSurveys(): void {
    this.isLoading = true;
    const tenSurveysUsed = this.environment.userPreferences?.lastTenSurveysUsed;
    if (tenSurveysUsed) {
      combineLatest([
        this.userPreferenceService
          .getItem<DocumentFullSurvey[]>(tenSurveysUsed)
          .pipe(first()),
        this.codebookSelectionService
          .getSurveys(this.filterSurveysBy)
          .pipe(first()),
      ]).subscribe(
        ([surveyList, data]: [DocumentFullSurvey[], DocumentFullSurvey[]]) => {
          if (data.length === 0) {
            this.showNoSurveyDialog();
            return;
          }
          const responseData = cloneDeep(data);
          this.recentSurveys = (
            this.removeDuplicatedRecentSurveys(cloneDeep(surveyList)) || []
          )
            .map(
              (survey: DocumentFullSurvey) =>
                responseData.find(
                  (surveyItem: DocumentFullSurvey) =>
                    surveyItem.code === survey.code &&
                    surveyItem.authorizationGroup === survey.authorizationGroup
                ) || null
            )
            .filter((surveyItem) => surveyItem !== null);
          this.allSurveyData = responseData;
          this.surveyData = responseData;
          this.populateFilters(responseData);
          this.filterSurveys();
          this.checkPreselectedSurveys();
        }
      );
    } else {
      this.codebookSelectionService
        .getSurveys(this.filterSurveysBy)
        .pipe(first())
        .subscribe((data: DocumentFullSurvey[]) => {
          if (data.length === 0) {
            this.showNoSurveyDialog();
            return;
          }
          const responseData = cloneDeep(data);
          this.recentSurveys = [];
          this.allSurveyData = responseData;
          this.surveyData = responseData;
          this.populateFilters(responseData);
          this.filterSurveys();
          this.checkPreselectedSurveys();
        });
    }
  }

  private sortSurveyData(data: DocumentFullSurvey[]): DocumentFullSurvey[] {
    let sortedSurveyData: DocumentFullSurvey[];
    switch (this.selectedSurveyOrder) {
      case 0:
        sortedSurveyData = this.sortSurveysByRecentThenYearDesc(data);
        break;
      case 1:
        sortedSurveyData = this.sortSurveysByName(data, SortOrder.asc);
        break;
      case 2:
        sortedSurveyData = this.sortSurveysByName(data, SortOrder.desc);
        break;
      case 3:
        sortedSurveyData = this.sortSurveysByYear(data, SortOrder.asc);
        break;
      case 4:
        sortedSurveyData = this.sortSurveysByYear(data, SortOrder.desc);
        break;
      case 5:
        sortedSurveyData = this.sortSurveysByHitCounts(data, SortOrder.asc);
        break;
      case 6:
        sortedSurveyData = this.sortSurveysByHitCounts(data, SortOrder.desc);
        break;
      case 7:
        sortedSurveyData = this.sortSurveyByReleaseDate(data, SortOrder.desc);
    }

    return sortedSurveyData;
  }

  private sortSurveysByRecentThenYearDesc(
    data: DocumentFullSurvey[]
  ): DocumentFullSurvey[] {
    let recentSurveyCodes = this.recentSurveys.map(
      (survey: DocumentFullSurvey) => this.formatUniqueSurveyKey(survey)
    );
    let recentSurveys = this.recentSurveys;
    if (this.searchText) {
      const searchResultCodes = data.reduce(
        (prev, survey: DocumentFullSurvey) => ({
          ...prev,
          [survey.code]: survey.occurrenceCount,
        }),
        {}
      );
      recentSurveys = this.recentSurveys
        .filter(
          (survey: DocumentFullSurvey) => survey.code in searchResultCodes
        )
        .map((survey: DocumentFullSurvey) => ({
          ...survey,
          occurrenceCount: searchResultCodes[survey.code],
        }));
      recentSurveyCodes = recentSurveys.map((survey: DocumentFullSurvey) =>
        this.formatUniqueSurveyKey(survey)
      );
    }
    const tempSurvey = data.filter(
      (survey: DocumentFullSurvey) =>
        !recentSurveyCodes.includes(this.formatUniqueSurveyKey(survey))
    );

    return [
      ...this.filterSurveyData(recentSurveys),
      ...this.sortSurveysByYear(tempSurvey, SortOrder.desc),
    ];
  }

  private sortSurveysByName(
    data: DocumentFullSurvey[],
    sortOrder: SortOrder
  ): DocumentFullSurvey[] {
    const tempSurveys = data;
    if (sortOrder === SortOrder.desc) {
      tempSurveys.sort((a: DocumentFullSurvey, b: DocumentFullSurvey) =>
        this.compareSurveyByName(a, b, SortOrder.desc)
      );
    } else {
      tempSurveys.sort((a: DocumentFullSurvey, b: DocumentFullSurvey) =>
        this.compareSurveyByName(a, b, SortOrder.asc)
      );
    }

    return tempSurveys;
  }

  private compareSurveyByName(
    a: DocumentFullSurvey,
    b: DocumentFullSurvey,
    sortOrder: SortOrder
  ): number {
    if (sortOrder === SortOrder.desc) {
      if (a.title > b.title) {
        return -1;
      }
      if (a.title < b.title) {
        return 1;
      }
      return 0;
    } else {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    }
  }

  private compareSurveyReleaseDate(
    a: DocumentFullSurvey,
    b: DocumentFullSurvey,
    sortOrder: SortOrder
  ): number {
    const aReleaseDate = a.meta['study-release-date'] || '';
    const bReleaseDate = b.meta['study-release-date'] || '';
    if (sortOrder === SortOrder.desc) {
      if (aReleaseDate > bReleaseDate) {
        return -1;
      }
      if (aReleaseDate < bReleaseDate) {
        return 1;
      }
      return 0;
    } else {
      if (aReleaseDate < bReleaseDate) {
        return -1;
      }
      if (aReleaseDate > bReleaseDate) {
        return 1;
      }
      return 0;
    }
  }

  private sortSurveysByYear(
    data: DocumentFullSurvey[],
    sortOrder: SortOrder
  ): DocumentFullSurvey[] {
    const tempSurveys = data;
    if (sortOrder === SortOrder.desc) {
      tempSurveys.sort((a: DocumentFullSurvey, b: DocumentFullSurvey) => {
        if (a.year < b.year) {
          return 1;
        } else if (a.year > b.year) {
          return -1;
        } else {
          return this.compareSurveyReleaseDate(a, b, SortOrder.desc);
        }
      });
    } else {
      tempSurveys.sort((a: DocumentFullSurvey, b: DocumentFullSurvey) => {
        if (a.year > b.year) {
          return 1;
        } else if (a.year < b.year) {
          return -1;
        } else {
          return this.compareSurveyReleaseDate(a, b, SortOrder.asc);
        }
      });
    }

    return tempSurveys;
  }

  private sortSurveysByHitCounts(
    data: DocumentFullSurvey[],
    sortOrder: SortOrder
  ): DocumentFullSurvey[] {
    const tempSurveys = data;
    if (sortOrder === SortOrder.desc) {
      tempSurveys.sort((a: DocumentFullSurvey, b: DocumentFullSurvey) => {
        if (a.occurrenceCount < b.occurrenceCount) {
          return 1;
        } else if (a.occurrenceCount > b.occurrenceCount) {
          return -1;
        } else {
          return this.compareSurveyByName(a, b, SortOrder.asc);
        }
      });
    } else {
      tempSurveys.sort((a: DocumentFullSurvey, b: DocumentFullSurvey) => {
        if (a.occurrenceCount > b.occurrenceCount) {
          return 1;
        } else if (a.occurrenceCount < b.occurrenceCount) {
          return -1;
        } else {
          return this.compareSurveyByName(a, b, SortOrder.asc);
        }
      });
    }

    return tempSurveys;
  }

  private sortSurveyByReleaseDate(
    data: DocumentFullSurvey[],
    sortOrder: SortOrder
  ): DocumentFullSurvey[] {
    const tempSurveys = data;

    if (sortOrder === SortOrder.desc) {
      tempSurveys.sort((a: DocumentFullSurvey, b: DocumentFullSurvey) => {
        const dateA = new Date(a.meta['study-release-date'] as string);
        const dateB = new Date(b.meta['study-release-date'] as string);

        if (dateA < dateB) {
          return 1;
        } else if (dateA > dateB) {
          return -1;
        } else {
          return this.compareSurveyByName(a, b, SortOrder.asc);
        }
      });
    } else {
      tempSurveys.sort((a: DocumentFullSurvey, b: DocumentFullSurvey) => {
        const dateA = new Date(a.meta['study-release-date'] as string);
        const dateB = new Date(b.meta['study-release-date'] as string);

        if (dateA > dateB) {
          return 1;
        } else if (dateA < dateB) {
          return -1;
        } else {
          return this.compareSurveyByName(a, b, SortOrder.asc);
        }
      });
    }

    return tempSurveys;
  }

  private populateFilters(data: DocumentFullSurvey[]): void {
    this.populateProviders(data);
    this.populateLanguages(data);
    this.populateYears(data);
  }

  private showNoSurveyDialog(): void {
    this.messageService
      .openMessageDialog(
        'There is an issue getting your assigned surveys. Please contact your Telmar representative for more details.',
        'DocumentFullSurvey Alert',
        { cancelText: 'Okay' }
      )
      .afterClosed()
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  private populateProviders(data: DocumentFullSurvey[]): void {
    const providers = [
      ...new Set(data.map((survey: DocumentFullSurvey) => survey.provider)),
    ].sort((s1: string, s2: string) => {
      const l = s1.toLowerCase();
      const m = s2.toLowerCase();
      return l === m ? 0 : l > m ? 1 : -1;
    });

    this.providers = [
      {
        name: 'All',
        value: 0,
      },
      ...providers.map((provider: string, index: number) => ({
        name: provider,
        value: index + 1,
      })),
    ];

    this.selectedProvider =
      this.providers.find(
        (provider: FilterListItem) =>
          provider.name === this.selectedSurveyProvider
      )?.value || 0;
    this.selectedSurveyType = this.selectedSurveyData[0]?.isTrendable ? 1 : 0;
  }

  private populateLanguages(data: DocumentFullSurvey[]): void {
    const languages = [
      ...new Set(data.map((survey: DocumentFullSurvey) => survey.language)),
    ];

    this.languages = [
      {
        name: 'All',
        value: 0,
      },
      ...languages.map((language: string, index: number) => ({
        name: language,
        value: index + 1,
      })),
    ];
  }

  private populateYears(data: DocumentFullSurvey[]): void {
    const years = [
      ...new Set(data.map((survey: DocumentFullSurvey) => survey.year)),
    ].sort((a: number, b: number) => b - a);
    // Function to add missing years for the range year slider
    function addMissingYears(yearsArray: number[]): number[] {
      const missingYears = [];
      for (let i = 0; i < yearsArray.length - 1; i++) {
        const currentYear = yearsArray[i];
        const nextYear = yearsArray[i + 1];
        for (let year = currentYear - 1; year > nextYear; year--) {
          missingYears.push(year);
        }
      }
      return missingYears;
    }

    const missingYears = addMissingYears(years);

    // Merge the original years array with the missing years and sort it again
    const allYears = [...years, ...missingYears].sort((a, b) => b - a);
    this.searchYears = [
      ...allYears.map((year: number, index: number) => ({
        name: `${year}`,
        value: index,
      })),
    ];

    this.reversedSearchYears = this.reverseYearArray(this.searchYears);
  }

  private reverseYearArray(yearArray: FilterListItem[]): FilterListItem[] {
    return yearArray
      .slice()
      .reverse()
      .map((year, index: number) => ({
        name: year.name,
        value: index,
      }));
  }

  private updateFilteredSurveyData(): void {
    this.isLoading = true;
    this.filteredSurveyData = this.sortSurveyData(
      this.filterSurveyData(this.surveyData)
    );

    this.showNoResultsMessage = this.filteredSurveyData.length === 0;
    this.isLoading = false;
  }

  private filterSurveyData(data: DocumentFullSurvey[]): DocumentFullSurvey[] {
    const selectedLanguage = this.languages[this.selectedLanguage].name;
    const fromYear = this.reverseYearArray(this.searchYears)[
      this.selectedSearchYearFrom
    ].name;
    const toYear = this.searchYears[this.selectedSearchYearTo].name;

    const selectedProvider = this.providers[this.selectedProvider].name;
    const selectedSurveyType = this.surveyTypes[this.selectedSurveyType].name;
    // const selectedSurveys = this.selectedSurveys;
    // const selectedSurveyCodes = selectedSurveys.map(
    //   (survey: DocumentFullSurvey) => survey.code
    // );

    return data.filter((survey: DocumentFullSurvey) => {
      if (
        this.selectedSurveyTrendable &&
        this.selectedSurveyProvider &&
        survey.provider !== this.selectedSurveyProvider
      ) {
        return false;
      }

      // if (
      //   selectedSurveyCodes.length > 0 &&
      //   selectedSurveyCodes.includes(survey.code)
      // ) {
      //   return false;
      // }

      if (selectedLanguage !== 'All' && survey.language !== selectedLanguage) {
        return false;
      }

      if (
        !(survey.year <= parseInt(toYear, 10)) ||
        !(survey.year >= parseInt(fromYear, 10))
      ) {
        return false;
      }

      if (selectedProvider !== 'All' && survey.provider !== selectedProvider) {
        return false;
      }

      if (selectedSurveyType !== 'All') {
        switch (selectedSurveyType) {
          case 'Non-Trendable':
            if (survey.isTrendable) {
              return false;
            }
            break;
          case 'Trendable':
            if (!survey.isTrendable) {
              return false;
            }
            break;
          case 'Multibased':
            if (!survey.isMultibased) {
              return false;
            }
            break;
          case 'Mappable':
            if (!survey.isMappable) {
              return false;
            }
            break;
        }
      }

      return true;
    });
  }

  private clearSelection(): void {
    this.filteredSurveyData.forEach((survey) => {
      if (survey['checked']) {
        survey['checked'] = false;
      }
    });
  }

  private unsetFiltersAndSearch(): void {
    this.selectedProvider = 0;
    this.selectedSearchYearFrom = 0;
    this.selectedSearchYearTo = 0;
    this.selectedSurveyType = 0;
    this.selectedSurveyOrder = 0;
    this.selectedLanguage = 0;
    this.searchText = '';
  }

  e;

  private hasActiveSelectedSurveys(): boolean {
    return this.activeSelectedSurveys && this.activeSelectedSurveys.length > 0;
  }

  private hasMultipleActiveSelectedSurveys(): boolean {
    return this.activeSelectedSurveys.length > 1;
  }

  private showSelectOnlyOneSurveyDialog(): void {
    this.messageService.openMessageDialog(
      'Please select only one survey',
      'Survey Alert'
    );
  }

  private getSelectedSurveyIndex(): number {
    return this.selectedSurveyData
      .map((survey: DocumentFullSurvey) => this.formatUniqueSurveyKey(survey))
      .indexOf(this.formatUniqueSurveyKey(this.activeSelectedSurveys[0]));
  }

  private refreshSurveyData(): void {
    if (this.allSurveyData.length > 0) {
      this.surveyData = cloneDeep(this.allSurveyData);
    }
  }

  private removeDuplicatedRecentSurveys(
    surveys: DocumentFullSurvey[]
  ): DocumentFullSurvey[] {
    return uniqBy(surveys, (survey) => [
      survey.code,
      survey.authorizationGroup,
    ]);
  }

  private formatUniqueSurveyKey(survey: DocumentFullSurvey): string {
    return `${survey.code}_${survey.authorizationGroup}`;
  }
}
