import { DocumentSpotplanSchedule } from '../models/document.model';
import { DaypartResult } from './daypart-result';
import {
  DaypartResultByWeekByTarget,
  DefaultSpotplanNaturalDelivery,
  SpotplanSchedule,
  SpotplanScheduleDaypart,
  SpotplanStrategy,
  SpotplanWeekBreakdown,
} from './spotplan-schedule';
import { TargetVehicle } from './vehicle';
import { CostMethod } from './result';

export class SpotplanSchedules {
  spotplans: SpotplanSchedule[] = [];

  any(): boolean {
    return !!this.spotplans.length;
  }

  findSpotplan(
    broadcastVehicleId: String,
    VehicleIdSuffix: string = ''
  ): SpotplanSchedule {
    return this.spotplans.find(
      (schedule) =>
        schedule.vehicleId === `${broadcastVehicleId}${VehicleIdSuffix}`
    );
  }

  addSpotplan(
    vehicle: TargetVehicle,
    name?: string,
    VehicleIdSuffix?: string
  ): SpotplanSchedule {
    const existingSchedule = this.findSpotplan(vehicle.id, VehicleIdSuffix);
    if (existingSchedule) return existingSchedule;

    const schedule: SpotplanSchedule = new SpotplanSchedule(
      name || vehicle.title
    );
    schedule.assignVehicle(vehicle, VehicleIdSuffix);
    this.spotplans.push(schedule);
    return this.spotplans[this.spotplans.length - 1];
  }

  deleteSpotplan(id: string): void {
    const index = this.spotplans.findIndex((sch) => sch.id === id);
    if (index !== -1) this.spotplans.splice(index, 1);
  }

  clearAll() {
    this.spotplans = [];
  }

  // remove the spotplan associated with a vehicle (only the first in the list if multi spotplans were created for that vehicle)
  removeVehicle(vehicleId: string) {
    const index = this.spotplans.findIndex(
      (spot) => spot.vehicleId === vehicleId
    );
    if (index !== -1) {
      this.spotplans.splice(index, 1);
    }
  }

  deleteTarget(targetId: string, remainingCostTargetIds: string[]) {
    this.spotplans.forEach((spotplan) => {
      spotplan.deleteTarget(targetId);

      // check if the daypart has costs.  If so reasign the costTargetId accordingly
      spotplan.dayparts.forEach((daypart) => {
        daypart.result.results.forEach((dpResult) => {
          if (
            dpResult.costTargetId &&
            !remainingCostTargetIds.includes(dpResult.costTargetId)
          ) {
            dpResult.addResults({ costTargetId: remainingCostTargetIds[0] });
          }
        });
      });
    });
  }

  // present spotplans object as an interface ready for document saving
  asDocument(): DocumentSpotplanSchedule[] {
    const docSchedules: DocumentSpotplanSchedule[] = this.spotplans.map(
      (spotplan) => {
        return {
          id: spotplan.id,
          name: spotplan.name,
          vehicleId: spotplan.vehicleId,
          naturalDelivery: spotplan.naturalDelivery,
          weekCount: spotplan.weekCount,
          allocation: spotplan.allocation,

          dayparts: spotplan.dayparts.map((dp) => {
            return {
              id: dp.id,
              mnemonic: dp.mnemonic,
              title: dp.title,
              startDay: dp.startDay,
              startTime: dp.startTime,
              endDay: dp.endDay,
              endTime: dp.endTime,
              result: dp.result.copy(),
            };
          }),
          weeks: spotplan.weeks.map((week) => week.copy()),
          days: spotplan.days.map((day) => day.copy()),
        };
      }
    );

    return docSchedules;
  }

  // consume document object and create required classes and structures
  loadCampaign(docSpotplans: DocumentSpotplanSchedule[]): void {
    this.clearAll();
    docSpotplans.forEach((docSpotplan: DocumentSpotplanSchedule) => {
      const spotplan: SpotplanSchedule = new SpotplanSchedule(docSpotplan.name);
      spotplan.id = docSpotplan.id;
      spotplan.vehicleId = docSpotplan.vehicleId;
      spotplan.naturalDelivery = docSpotplan.naturalDelivery || {
        ...DefaultSpotplanNaturalDelivery,
      };

      if (typeof spotplan.naturalDelivery.breakdown === 'undefined')
        spotplan.naturalDelivery.breakdown = SpotplanWeekBreakdown.allweek;
      if (typeof spotplan.naturalDelivery.strategy === 'undefined')
        spotplan.naturalDelivery.strategy = SpotplanStrategy.GRPs;

      spotplan.allocation = docSpotplan.allocation;
      spotplan.weekCount = docSpotplan.weekCount;

      // set up dayparts
      spotplan.dayparts = docSpotplan.dayparts.map((sp) => {
        const daypartItem: SpotplanScheduleDaypart = {
          id: sp.id,
          mnemonic: sp.mnemonic,
          title: sp.title,
          startDay: sp.startDay,
          startTime: sp.startTime,
          endDay: sp.endDay,
          endTime: sp.endTime,
          result: new DaypartResultByWeekByTarget(),
        };
        sp.result.forEach((res) => {
          daypartItem.result.addResults(res.week, res.targetId, res);

          if (res.costMethod !== CostMethod.none && !res.costTargetId) {
            daypartItem.result.addResults(res.week, res.targetId, {
              costTargetId: res.targetId,
            });
          }
        });
        return daypartItem;
      });

      // set up daypart week results
      spotplan.weeks = docSpotplan.weeks.map((wk) => new DaypartResult(wk));
      spotplan.days = docSpotplan.days
        ? docSpotplan.days.map((day) => new DaypartResult(day))
        : [];
      this.spotplans.push(spotplan);
    });
  }

  copy(copyFrom: SpotplanSchedules) {
    this.clearAll();
    copyFrom.spotplans.forEach((copySpotplan: SpotplanSchedule) => {
      const spotplan: SpotplanSchedule = new SpotplanSchedule(
        copySpotplan.name
      );
      spotplan.allocation = copySpotplan.allocation;
      spotplan.naturalDelivery = { ...copySpotplan.naturalDelivery };
      spotplan.vehicleId = copySpotplan.vehicleId;
      spotplan._weekCount = copySpotplan._weekCount;

      // weekly results
      spotplan.weeks = [];
      copySpotplan.weeks.forEach((copyWeek) =>
        spotplan.weeks.push(new DaypartResult(copyWeek.copy()))
      );

      // daypart details + results
      spotplan.dayparts = [];
      copySpotplan.dayparts.forEach((copyDaypart) => {
        spotplan.dayparts.push({
          endDay: copyDaypart.endDay,
          id: copyDaypart.id,
          endTime: copyDaypart.endTime,
          mnemonic: copyDaypart.mnemonic,
          startDay: copyDaypart.startDay,
          startTime: copyDaypart.startTime,
          title: copyDaypart.title,
          result: new DaypartResultByWeekByTarget(),
        });

        const res = spotplan.dayparts[spotplan.dayparts.length - 1].result;
        const copyResults = copyDaypart.result.copy();
        copyResults.forEach((copyRes) =>
          res.addResults(copyRes.week, copyRes.targetId, copyRes)
        );
      });
      this.spotplans.push(spotplan);
    });
  }
}
