import { TargetStatement } from './engine.models';

// used for requesting the audiences and resps by target from a list of vehicle mnemonics

export interface VehicleDefinition {
  id: string;
  mnemonic: string;
  surveyCode: string;
}

export interface SurveyInfo {
  surveyCode: string;
  authorizationGroup: string;
  ESGProvider?: string;
  KeepThousands?: boolean;
}
export interface TargetAudienceSetMultiSurveyRequest {
  surveyCode: string;
  vehicles: VehicleDefinition[];
  targetDefinitions: TargetDefinitionRequest[];
  weightSet: number;
  authorizationGroup: string;
  engineMode: string;
  surveyList?: SurveyInfo[];
}
export interface TargetAudienceSetRequest {
  surveyCode: string;
  vehicles: VehicleDefinition[];
  targetDefinitions: TargetDefinitionRequest[];
  weightSet: number;
  authorizationGroup: string;
  engineMode: string;
}

export interface TargetAudienceSetVehicleResponse {
  Id: string;
  audience: number;
  grossaudience: number;
  sample: number;
  potentialReach: number;
  dayparts?: TargetAudienceSetVehicleResponse[];
}

export interface TargetAudienceSetTargetResponse {
  targetId: number;
  sample: number;
  universe: number;
  vehicles: TargetAudienceSetVehicleResponse[];
}

export interface TargetAudienceSetVehicle {
  id: string;
  mnemonic: string;
  surveyCode: string;
  daypartIds?: string[];
}

export interface TargetAudienceSetResponse {
  success: boolean;
  results: TargetAudienceSetTargetResponse[];
}

export interface TargetDefinitionRequest {
  id: number;
  code: TargetStatement;
}

/// natural delivery requests and responses
export interface NaturalDeliveryRequest {
  surveyCode: string;
  targetDefinitions: TargetDefinitionRequest[];
  targetUniverse: number;
  weightSet: number;
  authorizationGroup: string;
  vehicles: NaturalDeliveryVehicle[];
  useIntegerInsertions: boolean;
  goal: NaturalDeliveryGoal;
  engineMode: string;
  surveyList: SurveyInfo[];
}

export enum NaturalDeliveryGoalMetric {
  GRP = 'GRP',
  Impressions = 'Impressions',
  Reach = 'Reach',
  Frequency = 'Frequency',
}
export interface NaturalDeliveryGoal {
  GRP?: number;
  Impressions?: number;
  Reach?: number;
  Frequency?: number;
}

export interface NaturalDeliveryVehicle {
  id: string;
  mnemonic: string;
  audience: number;
  surveyCode: string;
  numberOfInitialInserts?: number;
  initialDuration?: number;
  minInserts?: number;
  maxInserts?: number;
  reference?: string; // used as a lookup for dayparts in planningService.naturalDelivery()
}

export interface NaturalDeliveryResultResponse {
  id: string;
  numberOfInserts: number;
  duration: number;
  impressionsBought: number;
  frequencyCap: number;
  mediaUsage: string[];
  buyingTargetId: number;
}

export interface NaturalDeliveryResponse {
  success: boolean;
  error?: string;
  results?: NaturalDeliveryResultResponse[];
}

export interface NaturalDeliveryResponseMessage {
  success: boolean;
  message: string;
}
