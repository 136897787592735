<div class="info-message-dialog">
  <tup-mat-title-bar title="{{ title }}">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>

  <mat-dialog-content
    fxLayout="column"
    class="content"
    [ngSwitch]="template"
  >
    <ng-container
      *ngSwitchCase="0"
      [ngTemplateOutlet]="broadcastTemplate"
    ></ng-container>
    <ng-container
      *ngSwitchCase="1"
      [ngTemplateOutlet]="esgTemplate"
    ></ng-container>
    <ng-container
      *ngSwitchCase="2"
      [ngTemplateOutlet]="importedFileTemplate"
    ></ng-container>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions
    class="dialog-actions"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button
      mat-raised-button
      tupMatButton
      class="btn-action"
      color="primary"
      (click)="onClose()"
    >
      Close
    </button>
  </mat-dialog-actions>
</div>

<ng-template #broadcastTemplate>
  <h2>Optimisation</h2>
  <p>
    Gains the highest possible reach for the least amount of spend by
    determining the most efficient media vehicles for the chosen target
  </p>
  <h2>Natural Delivery</h2>
  <p>
    Ratings are divided among media vehicles in ratio to their audience size
    resulting in average reach for the media
  </p>
</ng-template>

<ng-template #esgTemplate>
  <h2>ESG score</h2>
  <p>
    ESG scores are calculated by analyzing a range of data points related to a
    company's environmental impact, social responsibility, and corporate
    governance practices.
  </p>
  <br />
  <p>A score of <b>50+ = Good</b> and <b>60+ = Very Good.</b></p>
  <br />
  <h2>Color Scale</h2>
  <p>
    The color scale related to the score shows the
    <b>stability of the score.</b> The stability of the score is based on the
    media which have ESG values used in your campaign; and to what level you use
    them, such as how many of your media items have scores identified and how
    many GRP's you have planned for them. For example, if you use a media
    vehicle with no score and have very high GRP usage of it, you would expect a
    colour which is very unstable, regardless of a good or bad score itself.
  </p>
  <br />
  <img
    src="/assets/images/esg-color-scale.png"
    alt="esg-color-scale"
  />
  <div class="stability-text-container">
    <b>Unstable score</b>
    <b>Stable score</b>
  </div>
</ng-template>

<ng-template #importedFileTemplate>
  <div *ngFor="let item of content">
    <p [innerHTML]="item"></p>
  </div>
</ng-template>
