<div
  virtualScrollTable
  [itemHeight]="42"
  (scrollUpdate)="planningTable.scrollUpdate = $event"
  class="container"
  [style.--columns-number]="tableColumnsNumber"
>
  <tree-table
    #planningTable
    class="tree-table compare-media-table"
    [ngClass]="{ 'no-groups': !anyGroups }"
    [inlineMenuWithIcons]="true"
    [treeTitle]="treeTitleMedia"
    [virtualScrolling]="true"
    treeWidth="450px"
    stickyColumn="name"
    data-tracking="media-table"
    [treeHeaderInlineMenu]="headerInlineMenu"
    (treeHeaderMainMenuClick)="onTreeHeaderMainMenuClick()"
    (treeHeaderMenuClick)="onTreeHeaderMenuClick($event)"
    (treeInlineMenuClick)="onTreeInlineMenuClick($event)"
    [readonly]="processing"
    (edited)="onEdited($event)"
    [data]="vehicleData"
    [cellEditProcessing]="cellEditProcessing"
    [showProcessing]="processing"
    [disableDragging]="true"
    [allowSelectAll]="true"
    [columns]="columns"
    [columnGroups]="columnGroups"
    [unitsText]="unitsText"
    (columnMenuClick)="onPlanningColumnMenuClick($event)"
    (selectedNodesChange)="onPlanningSelectedNodes($event)"
    (sorted)="onSorted('planning', $event)"
  >
  </tree-table>
</div>

<!-- inline templates for adding custom icons and functionality as needed to nodes -->
<!-- Add a chevron for expanding and collapsing th etable from the Totals line -->
<ng-template
  #totalsRow
  let-row="row"
>
  <button
    mat-icon-button
    class="inline-button"
    (click)="onToggleExpand()"
  >
    <mat-icon>
      {{ tableExpanded ? "expand_more" : "chevron_right" }}
    </mat-icon>
  </button>
  {{ row.name }}
</ng-template>

<!-- added to vehicles that have additional addressable functionality -->
<ng-template
  #addressableRow
  let-row="row"
>
  <button
    mat-icon-button
    class="inline-button"
  >
    <mat-icon
      data-tracking="addressable-icon"
      [ngClass]="
        row.data.metaData['addressableActive']
          ? 'icon-colour-active'
          : 'icon-colour-inactive'
      "
      matTooltipClass="{{
        'addressable-tooltip' +
          (row.data.metaData['addressableActive'] ? ' active' : '')
      }}"
      [matTooltip]="
        row.data.metaData['addressableActive']
          ? row.data.metaData['addressableTooltip']
          : 'Addressable available'
      "
      >{{ row.data.metaData["addressableActive"] ? "people" : "crisis_alert" }}
    </mat-icon>
  </button>
  <span
    matTooltipPosition="right"
    [matTooltip]="row.data.metaData['originalTitle']"
  >
    {{ row.name }}
  </span>
</ng-template>

<!-- added to vehicles that dont have anything special -->
<ng-template
  #normalVehicleRow
  let-row="row"
>
  <span
    matTooltipPosition="right"
    [matTooltip]="row.data.metaData['originalTitle']"
  >
    {{ row.name }}
  </span>
</ng-template>

<!-- added to mediatype and group total rows that contain broadcast media in their list -->
<ng-template
  #containsBroadcastRow
  let-row="row"
>
  <span>
    {{ row.name }}
  </span>

  <button
    mat-icon-button
    class="inline-button"
    (click)="onContainsBroadcastClick()"
  >
    <mat-icon>info_outline</mat-icon>
  </button>
</ng-template>

<!-- added to broadcast vehicles containing the schedules button logic-->
<ng-template
  #broadcastRow
  let-row="row"
>
  <button
    mat-icon-button
    class="inline-button"
    (click)="$event.stopPropagation(); onBroadcastSpotplanClick(row)"
    [disabled]="!row.data.metaData['hasSpotplan']"
  >
    <mat-icon
      [ngClass]="
        row.data.metaData['hasSpotplan']
          ? 'icon-colour-active'
          : 'icon-colour-inactive'
      "
      [matTooltip]="
        row.data.metaData['hasSpotplan'] ? 'Spots for ' + row.name : ''
      "
      >event_available</mat-icon
    >
  </button>

  <span>
    {{ row.name }}
  </span>
</ng-template>

<ng-template
  #importedMediaTypeRow
  let-row="row"
>
  <span>
    {{ row.name }}
  </span>

  <button
    mat-icon-button
    class="inline-button inline-template-button"
    (click)="onImportedMediaInfoClick(row)"
  >
    <mat-icon>info_outline</mat-icon>
  </button>
</ng-template>

<ng-template #esgInfoIcon>
  <mat-icon
    class="esg-info-icon"
    (click)="onEsgInfoIconClick()"
    >info_outline</mat-icon
  >
</ng-template>
