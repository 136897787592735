<div class="doc-container">
  <tup-document-storage
    #latestTemplatesTable
    [options]="latestTemplateTableOptions"
  >
    <ng-template tupDocumentStorageCardContent>
      <ng-container *ngTemplateOutlet="filter"></ng-container>
    </ng-template>
    <!-- Content projection: inserts messaging / signposting indicating if / when there is no content to display -->
    <ng-template tupDocumentStorageCardNoContent>
      <div
        class="no-search-results"
        *ngIf="latestTemplatesTable.nameFilterService.value; else getStarted"
      >
        <ng-container *ngTemplateOutlet="filter"></ng-container>
        <p
          class="mat-error"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-icon fontSet="material-icons-outlined">warning</mat-icon>
          <span>No templates yet</span>
        </p>
      </div>

      <ng-template #getStarted>
        <div
          class="get-started"
          data-tracking="no-templates-yet"
          fxFlex
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="24px"
          *ngIf="
            !(latestTemplatesTable.dataSource.loading | async);
            else loading
          "
        >
          <img src="assets/images/template_dashboard_icon.svg" />
          <h3>Get started</h3>
          <p class="description">
            You can easily create campaigns and reuse them as templates. Simply
            choose to save your campaigns as templates and they will be
            conveniently stored here in 'My latest templates' section. Making it
            quicker for you to keep reusing popular campaign formats.
          </p>
        </div>
      </ng-template>
    </ng-template>

    <!-- Content projection: inserts a custom card 'action' after the table, in the card footer -->
    <ng-template
      tupDocumentStorageCardActions
      *ngIf="latestTemplatesTable.dataSource?.length"
    >
      <!-- placeholder, nothing to see here -->
      <div
        fxFlex
        fxLayout="row"
        class="see-all"
        (click)="seeAllClick()"
        fxLayoutAlign="start center"
      >
        <mat-icon color="primary">arrow_forward</mat-icon>
        See all templates ({{ latestTemplatesTable.dataSource.length }})
      </div>
    </ng-template>
  </tup-document-storage>
</div>
<ng-template #filter>
  <tup-document-filter
    data-tracking="report-search"
    formFieldAppearance="outline"
    formFieldLabel="Search campaigns"
    [filterFor]="latestTemplatesTable"
  >
  </tup-document-filter>
</ng-template>
<ng-template #loading><div class="get-started"></div></ng-template>
