import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Schedule } from 'src/app/classes/schedule';
import {
  SpotplanAllocationText,
  SpotplanSchedule,
  SpotplanStrategyText,
  SpotplanWeekBreakdownText,
} from 'src/app/classes/spotplan-schedule';
import { Target } from 'src/app/classes/target';
import { TargetVehicle } from 'src/app/classes/vehicle';

export enum SpotplanQuickViewButton {
  cancel,
  detach,
  schedule,
}

export class SpotplanQuickViewModel {
  buttonAction: SpotplanQuickViewButton;
  constructor(
    public schedule: Schedule,
    public vehicle: TargetVehicle,
    public target: Target
  ) {}
}

@Component({
  selector: 'app-spotplan-quick-view-dialog',
  templateUrl: './spotplan-quick-view-dialog.component.html',
  styleUrls: ['./spotplan-quick-view-dialog.component.scss'],
})
export class SpotplanQuickViewDialogComponent implements OnInit {
  spotplan: SpotplanSchedule;
  vehicle: TargetVehicle;
  target: Target;
  spotCount: number;

  goal: number;
  strategy: string;
  allocation: string;
  breakdown: string;

  constructor(
    public dialogRef: MatDialogRef<SpotplanQuickViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SpotplanQuickViewModel
  ) {}

  ngOnInit(): void {
    this.vehicle = this.data.vehicle;
    this.target = this.data.target;

    this.spotplan = this.data.schedule.spotplans.findSpotplan(this.vehicle.id);
    this.spotCount = this.data.schedule.vehicle(
      this.target,
      this.vehicle.id
    ).result.inserts;

    const week1Total = this.spotplan.getWeekTotal(0, this.target.id);

    this.goal = this.spotplan.naturalDelivery.goal;
    this.strategy =
      SpotplanStrategyText[this.spotplan.naturalDelivery.strategy];
    this.breakdown =
      SpotplanWeekBreakdownText[this.spotplan.naturalDelivery.breakdown];
    this.allocation = SpotplanAllocationText[this.spotplan.allocation];
  }

  onClose(action: SpotplanQuickViewButton) {
    this.data.buttonAction = action;
    this.dialogRef.close(this.data);
  }
}
