import { Cell, CellValue, Color, Row } from 'exceljs';
import { TupXlsxBuilder } from '@telmar-global/tup-document-exporter';

export interface ExcelCell {
  value: CellValue;
  decimals?: number;
  background?: string;
  cellType?: string; // Total row, Media Type row or vehicle that needs to be highlighted
}

export enum xlsxCellType {
  total = 'total',
  mediaType = 'mediaType',
  highlightedVehicle = 'highlightedVehicle',
  boldCell = 'boldCell',
}

export class CrossMediaTableXlsxBuilder extends TupXlsxBuilder {
  private readonly colours: any = {
    red: {
      fill: '00B1FAC6',
      border: '004A7D4A',
    },
    green: {
      fill: '00FFE8F4',
      border: '00D2285A',
    },
  };

  public addEmptyRow(): CrossMediaTableXlsxBuilder {
    this.worksheet.addRows([[]]);
    return this;
  }

  public addRow(
    cells: ExcelCell[] | string[],
    firstCellBold: boolean = true
  ): Cell[] {
    const rowsToAdd: CellValue[] = [];
    cells.forEach((cell) => {
      typeof cell === 'string'
        ? rowsToAdd.push(cell)
        : rowsToAdd.push(cell.value);
    });

    const rows = this.worksheet.addRows([rowsToAdd]);
    if (firstCellBold)
      rows.forEach((row: Row) => this.embolden(row.getCell('A')));

    rows.forEach((row) => {
      cells.forEach((cell: ExcelCell | string, index) => {
        if (typeof cell === 'object')
          this.setDecimalPlaces(row.getCell(index + 1), cell.decimals);
      });
    });

    const returnCells: Cell[] = [];
    rows.length ? rows[0].eachCell((cell) => returnCells.push(cell)) : [];
    return returnCells;
  }

  public addTable(
    columns: string[],
    rows: ExcelCell[],
    data: ExcelCell[][]
  ): CrossMediaTableXlsxBuilder {
    let cols = this.addRow(
      [''].concat(columns).map((c) => {
        return { value: c };
      })
    );
    cols.forEach((cell) => this.embolden(cell));

    let line: ExcelCell[] = [];
    rows.forEach((row, rowIndex) => {
      line.push({ ...row });
      columns.forEach((column, columnIndex) => {
        line.push(data[rowIndex][columnIndex]);
      });
      const cells = this.addRow(line, line[0].cellType !== undefined); // bold only if it has cellType set
      cells.forEach((cell, index) => {
        if (line[index].cellType) {
          this.applyStyleByCellType(this.embolden(cell), line[index].cellType);
        }
      });

      line = [];
    });
    return this;
  }

  public setDecimalPlaces(cell: Cell, fractionDigits: number): Cell {
    if (typeof fractionDigits === 'undefined') return cell;
    cell.numFmt = fractionDigits
      ? `#,##0.${'0'.repeat(fractionDigits)}`
      : '#,##0';

    return cell;
  }

  public fitToContents(columnIndex: number | string): void {
    const column = this.worksheet.getColumn(columnIndex);

    column.width = Math.max(
      ...column.values
        .map((value: CellValue) => value.toString().length)
        .filter((value: CellValue) => typeof value === 'number')
    );
  }

  public setColumnWidths(newWidth: number): void {
    const cols = Array.from({ length: 30 }, (arr, i) => i + 1);
    const columns = cols.map((value) => this.worksheet.getColumn(value));

    columns.forEach((column) => (column.width = newWidth));
  }

  private applyStyleByCellType(cell: Cell, cellType: string): Cell {
    switch (cellType) {
      case xlsxCellType.total:
        // styling cells on the 'Total' row (blue background and white bold text)
        this.applyFill(cell, { argb: '00083D9C' });
        cell.font = { color: { argb: '00FFFFFF' }, bold: true };
        break;
      case xlsxCellType.mediaType:
        // styling cells on a Media Type row
        this.applyFill(cell, { argb: '00B1CAFF' });
        break;
      case xlsxCellType.highlightedVehicle:
        // styling cells on a Media Vehicle row
        this.applyFill(cell, { argb: '00E8EBF2' });
        break;
      case xlsxCellType.boldCell:
        // styling bold cells: white background
        this.applyFill(cell, { argb: '00FFFFFF' });
        break;
    }

    return cell;
  }

  private applyFill(cell: Cell, fgColor: Partial<Color>): Cell {
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor };
    return cell;
  }

  private embolden(cell: Cell): Cell {
    cell.font = { bold: true };
    return cell;
  }
}
