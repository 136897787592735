import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import {
  SurveyInfoDialogModel,
  SurveyInfoType,
} from '../dialogs/survey-info-dialog/survey-info-dialog.component';
import { DialogService } from 'src/app/services/dialog.service';
import { DocumentSurvey } from '../../models/document.model';

@Component({
  selector: 'survey-info-bar',
  templateUrl: './survey-info-bar.component.html',
  styleUrls: ['./survey-info-bar.component.scss'],
})
export class SurveyInfoBarComponent implements OnInit {
  showIcons: boolean = true;

  @Input() hasSurveyDropdown: boolean = true;
  @Input() allowChangeSurvey: boolean = true;
  @Input() showManageSurvey: boolean = true;
  @Input() disabled: boolean = false;
  @Input() hasSurveyInfo: boolean = false;
  @Input() hasChildContent: boolean = true;
  @Input() currentSurvey: DocumentSurvey;
  @Input() selectedSurveys: DocumentSurvey[];
  @Output() surveyChangeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() currentSurveyChange: EventEmitter<any> = new EventEmitter<any>();
  currentTarget: number;

  constructor(
    private mediaplanService: MediaPlannerService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (!this.selectedSurveys) {
      this.selectedSurveys = this.mediaplanService.plan.selectedSurveys;
    }
  }

  onSurveySelectionChange(event: MatSelectChange) {
    this.mediaplanService.plan.selectedSurveys.forEach(
      (selectedSurvey) =>
        (selectedSurvey.isCurrent = selectedSurvey.code === event.value)
    );
    this.currentSurveyChange.emit();
  }

  onSurveySelection() {
    this.surveyChangeClick.emit();
  }

  onSurveyInfoClick() {
    const primarySurvey = this.mediaplanService.plan.primarySurvey;
    const surveyInfo = this.mediaplanService
      .getSelectedSurveysMetrics()
      .find(
        (val) =>
          val.surveyCode === primarySurvey.code &&
          val.authorizationGroup === primarySurvey.authorizationGroup
      );
    if (!!surveyInfo.surveyInfo) {
      const options = new SurveyInfoDialogModel();
      options.type = SurveyInfoType.info;
      options.surveys = [surveyInfo];
      this.dialogService.openSurveyInfo(options);
    }
  }
}
