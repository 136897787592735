<tup-mat-card
  class="insight-chart"
  fxLayout="column"
>
  <!-- top toolbar options -->
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="8px"
    class="settings-container"
  >
    <!-- <div class="export-chart-checkbox">
      <mat-checkbox ngIf="false" disabled tupMatCheckbox 
        appearance="small" 
        color="primary"        
        [checked]="exportSelected"
        [(ngModel)]="exportSelected"
      ></mat-checkbox>
    </div> -->

    <!-- <span>{{ id }}</span> -->

    <button
      mat-stroked-button
      color="primary"
      tupMatButton
      appearance="tiny"
      (click)="onExportSingle()"
    >
      <mat-icon
        class="tiny-icon"
        matTooltip="Export to PPT"
        class="material-icons-outlined"
      >
        file_download
      </mat-icon>
    </button>
    <button
      mat-stroked-button
      color="primary"
      tupMatButton
      appearance="tiny"
      [matTooltip]="
        !localChartSettings.showDataTable
          ? 'Show chart table'
          : 'Hide chart table'
      "
      (click)="openTable()"
    >
      <mat-icon class="tiny-icon">table_chart</mat-icon>
    </button>

    <!-- <button 
      mat-icon-button
      (click)="toggleNote()"
    >
      <mat-icon class="tiny-icon">edit_note</mat-icon>
    </button> -->
    <button
      mat-stroked-button
      color="primary"
      tupMatButton
      appearance="tiny"
      (click)="onChartSettings()"
    >
      <mat-icon class="tiny-icon material-icons-outlined">settings</mat-icon>
    </button>
  </div>

  <!-- Start of Chart rendering -->
  <div
    fxLayout="row"
    fxFlex="100"
  >
    <charts-table
      *ngIf="localChartSettings.showDataTable && !note"
      class="chart-data-table table-scroll"
      [data]="tableData"
    ></charts-table>
    <div
      fxFlex
      fxLayout="column"
      fxGrow="1"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-evenly center"
      >
        <div fxLayout="column">
          <h3 class="target-title">
            {{ localChartSettings.title }}

            <!-- <ng-container
            *ngIf="localChartSettings.subTitle; else defaultChartTitle"
          >
            {{ localChartSettings.subTitle }} 
          </ng-container>

          <ng-template #defaultChartTitle>
            {{ targetTitle }} - {{ chartDataLabel( dataItemSelection, ' - ' ) }}
            <span *ngIf="activeGraph.graphType === 'tupScatter' || secondaryChartType.graphType"> - {{ secondaryDataItemSelection }} </span>
          </ng-template> -->
          </h3>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-evenly center"
      >
        <h5
          class="group-name"
          fxLayoutAlign="space-evenly center"
        >
          {{ localChartSettings.subTitle }}
          {{ chartDataLabel(dataItemSelection, " - ") }}
        </h5>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxFlex
        fxLayoutAlign="space-evenly start"
        fxLayoutAlign.lt-md="space-evenly center"
        fxLayoutGap="20px"
        [class.is-table-visible]="localChartSettings.showDataTable && !note"
      >
        <div
          *ngIf="refreshChart"
          class="temp-chart-placeholder"
          [style.height]="tempChartPlaceholderHeight + 'px'"
        ></div>

        <!-- single series charts -->
        <div
          *ngIf="!refreshChart"
          #chartContainer
          class="chart-container"
          [class.is-note-visible]="note"
        >
          <tup-charts
            *ngIf="!multipleDatasets"
            [class.single-combined-chart--no-table]="
              !localChartSettings.showDataTable
            "
            [type]="activeGraph.graphType"
            [data]="finalData"
            [options]="finalOptions"
            [scatterChartData]="secondaryDataItemValues"
            [percentBarChart]="activeGraph.percentBarChart"
            [waterfall]="activeGraph.waterfall"
            [areaChart]="activeGraph.areaChart || secondaryChartType.areaChart"
            [pyramidChart]="activeGraph.pyramidChart"
            [hideLegend]="localChartSettings.hideChartLegend"
            [secondaryChartType]="secondaryChartType.graphType"
            [secondaryChartData]="secondaryDataItemValues"
            [secondaryDataType]="localChartSettings.secondaryDataItem"
            [decimalPlaces]="localChartSettings.decimalPlaces"
            [showDataFlag]="localChartSettings.flagRowResps"
            [showDataLabel]="localChartSettings.showChartLabel"
            [aspectRatio]="activeGraph.aspectRatio"
            [maintainAspectRatio]="false"
            chartWidth="100%"
            chartHeight="100%"
            [legendPosition]="'top'"
            [axisLabelLength]="25"
            [legendLabelLength]="30"
            [dataItemSelection]="dataItemSelection"
            [secondaryDataItemSelection]="secondaryDataItemSelection"
          ></tup-charts>

          <!-- multiple datasets (multi series charts) -->
          <ng-container *ngIf="multipleDatasets">
            <tup-charts
              *ngIf="
                multipleDatasets &&
                !refreshChart &&
                !unsuitableMultipleCharts.includes(activeGraph.value)
              "
              [type]="activeGraph.graphType"
              [class.centeredCharts]="
                activeGraph.graphType === 'tupMultiSeriesDonut' ||
                activeGraph.graphType === 'tupRadar' ||
                activeGraph.graphType === 'tupFilledRadar' ||
                activeGraph.graphType === 'tupPolar'
              "
              [data]="finalData"
              [options]="finalOptions"
              [aspectRatio]="activeGraph.aspectRatio"
              [scatterChartData]="secondaryDataItemValues"
              [percentBarChart]="activeGraph.percentBarChart"
              [waterfall]="activeGraph.waterfall"
              [areaChart]="
                activeGraph.areaChart || secondaryChartType.areaChart
              "
              [hideLegend]="localChartSettings.hideChartLegend"
              [secondaryChartType]="secondaryChartType.graphType"
              [secondaryChartData]="secondaryDataItemValues"
              [secondaryDataType]="localChartSettings.secondaryDataItem"
              [decimalPlaces]="localChartSettings.decimalPlaces"
              [showDataFlag]="localChartSettings.flagRowResps"
              [showDataLabel]="localChartSettings.showChartLabel"
              [aspectRatio]="activeGraph.aspectRatio"
              [maintainAspectRatio]="false"
              chartWidth="100%"
              chartHeight="100%"
              [legendPosition]="'left'"
              [legendWidth]="legendWidth"
              [axisLabelLength]="25"
              [legendLabelLength]="targets.length > 15 ? (note ? 20 : 30) : 45"
              [dataItemSelection]="dataItemSelection"
              [secondaryDataItemSelection]="secondaryDataItemSelection"
            ></tup-charts>

            <div
              *ngIf="unsuitableMultipleCharts.includes(activeGraph.value)"
              fxLayout="col wrap"
              fxLayoutAlign="space-evenly start"
              class="scroll"
            >
              <div
                *ngFor="
                  let dataset of datasetsForUnsuitableCharts;
                  let i = index
                "
                class="multiple-combined-charts"
                [class.multiple-combined-charts--single-row]="
                  localChartSettings.showDataTable &&
                  chartsInSingleRow.includes(activeGraph.value)
                "
              >
                <h5>{{ chartData.targetTitles[i] }}</h5>
                <tup-charts
                  [class.single-row-chart]="
                    chartsInSingleRow.includes(activeGraph.value)
                  "
                  [type]="activeGraph.graphType"
                  [data]="dataset"
                  [pyramidChart]="activeGraph.pyramidChart"
                  [hideLegend]="
                    localChartSettings.hideChartLegend ||
                    localChartSettings.showDataTable
                  "
                  [decimalPlaces]="localChartSettings.decimalPlaces"
                  [showDataFlag]="true"
                  [showDataLabel]="localChartSettings.showChartLabel"
                  [aspectRatio]="activeGraph.aspectRatio"
                  [maintainAspectRatio]="false"
                  chartWidth="100%"
                  chartHeight="100%"
                  [legendPosition]="'left'"
                  [axisLabelLength]="30"
                  [legendLabelLength]="note ? 30 : 45"
                  [dataItemSelection]="dataItemSelection"
                  [secondaryDataItemSelection]="secondaryDataItemSelection"
                ></tup-charts>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- <chart-note
        *ngIf="note"
        [associatedTarget]="associatedTarget"
        [associatedInsight]="insightTitle"
      ></chart-note> -->
      </div>

      <!-- <div
      *ngIf="localChartSettings.flagRowResps"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="chart-data-flag"
    >
      <span
        >* indicates Respondents <=
        {{ localChartSettings.flagRowRespsValue }}</span
      >
    </div> -->
    </div>
  </div>
  <div>
    <strong class="copyrights">
      {{ localChartSettings.showFooter ? footer : "" }}
    </strong>
    &nbsp;
  </div>
  <div>
    <strong class="copyrights"> {{ surveySource || "" }}</strong> &nbsp;
  </div>
</tup-mat-card>
