import { Result } from '../classes/result';

export enum FileType {
  DAU = 'dau',
  MRF = 'mrf',
}

// TODO decide which fields are mandatory after checking data available in DAU files
export interface FileHeader {
  dataSource: string; // Confluence doc: dataSource: input, is the surveycode
  planFilename: string;
  planExportDate: Date; // file's last modified date

  uniqueMarketId?: string; // (e.g. UK,US,Can,etc.)
  campaignTitle?: string;
  campaignDate?: Date;
  campaignNotes?: string;
  campaignMediaDepth?: number;
  planningSystem?: string;
  planningSystemUrl?: string; // Confluence doc: Nic to confirm
  userId?: string;
}

export const EMPTY_MULTI_SURVEY: SurveyData = {
  title: '',
  code: '',
  authGroup: '',
};

export const MULTI_SURVEY_DAU = 'multi-survey-dau';
export const MULTI_SURVEY_MRF = 'multi-survey-mrf';
export const MULTI_SURVEY_MANUAL = 'multi-survey-manual';

export interface SurveyData {
  title: string;
  code: string; // looks like it will be FileHeader.dataSource
  authGroup: string;
}

export interface MultiSurveyTarget {
  name: string;
  targetCodeId?: string;
  cellProportions?: number[];
  population?: number; // ? maybe market.popnInSurvey field from in json documentation
  sample?: number;
  scale?: number;
}

export interface MultiSurveyVehicle {
  title: string;
  code: string;
  mediaType: string;
  mediaTypeId: number;
  audience: number;
  selfPair?: number;
  cost: number;
  ESG?: number;
  ESGStability?: number;
  result?: Result;
}

export interface MultiSurveyVehicleConfig {
  multiSurveyId: string;
  manualInput?: boolean;
  vehicleRef?: string;
  groupRef?: string;
}

export interface MultiSurveyManualInput {
  title: string;
  reachPct: number;
  grp: number;
  avgFreq: number;
  inserts?: number;
  totalCost?: number;
}

export interface ManualEntryDialogModel {
  multiSurveyId: string;
  scheduleIndex?: number;
}
