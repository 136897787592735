<div
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <mat-radio-button
    (click)="onClick($event)"
    [checked]="checked"
    [value]="target.id"
    name="{{ target.title }}"
    matTooltip="{{
      disabled
        ? 'Target not compatible with selected vehicle survey'
        : target.title
    }}"
    [disabled]="disabled"
  >
    {{ target.title }}
  </mat-radio-button>
  <div fxFlex></div>
  <button
    *ngIf="target.id !== 'none'"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    (click)="onEditItem()"
    data-tracking="edit-audience-btn"
    [disabled]="disabled"
  >
    <mat-icon color="{{ disabled ? '' : 'primary' }}">edit</mat-icon>
  </button>
</div>
