import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  NodeMenuClickEvent,
  TreeTableColumn,
  TreeTableMenuItem,
  TreeTableNode,
} from '../tree-table/tree-table.models';
import { Target } from 'src/app/classes/target';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { DialogService } from 'src/app/services/dialog.service';
import { Schedule } from 'src/app/classes/schedule';
import { TreeTableComponent } from '../tree-table/tree-table.component';
import { ScheduleTotalTag } from 'src/app/classes/schedule-total';
import {
  MEDIATYPE_ALL,
  MediatypeView,
} from 'src/app/steps/planning-step/planning-step.component';
import { PlanningValueProviderService } from 'src/app/services/planning-value-provider.service';
import { VehiclePlanningData } from 'src/app/classes/planning-data';
import { compareOptionKeys } from '../../models/planning.models';
import { AppendUnitsPipe } from 'src/app/pipes/append-units.pipe';

@Component({
  selector: 'compare-audiences',
  templateUrl: './compare-audiences.component.html',
  styleUrls: ['./compare-audiences.component.scss'],
})
export class CompareAudiencesComponent implements OnInit, OnChanges {
  @Input() currentSchedule: Schedule;
  @Input() selectedTargets: Target[] = [];
  @Input() unitsText: string;

  @ViewChild('compareByAudiencesTable')
  compareByAudiencesTable: TreeTableComponent;

  totalsData: { [target: string]: VehiclePlanningData } = {};

  targetsInlineMenu: TreeTableMenuItem[] = [
    { label: 'Add/remove columns', data: 'metrics|edit', matIcon: 'view_week' },
  ];

  metrics: TreeTableColumn[];

  tableColumnsNumber: number = 0;

  mediaTypes: MediatypeView[] = [];

  tableData: TreeTableNode[] = [];
  tableColumns: TreeTableColumn[] = [];

  appendUnits = new AppendUnitsPipe();

  constructor(
    private mediaplannerService: MediaPlannerService,
    private planningValueProviderService: PlanningValueProviderService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedTargets = changes['selectedTargets'];
    if (
      selectedTargets &&
      selectedTargets.currentValue !== selectedTargets.previousValue &&
      !selectedTargets.firstChange
    ) {
      this.loadData();
    }

    const currentSchedule = changes['currentSchedule'];
    if (
      currentSchedule &&
      currentSchedule.currentValue !== currentSchedule.previousValue
    ) {
      this.loadData();
    }
  }

  private loadData() {
    this.mediaTypes = [];
    this.tableData = [];
    this.tableColumns = [];
    this.totalsData = {};
    this.metrics = [];

    this.metrics = this.mediaplannerService.plan.columns.getVisibleColumns();
    this.mediaTypes = this.buildMediaTypeList();
    this.buildTableColumns();
    this.tableColumnsNumber = this.tableColumns.length + 1;
    this.generateTotalsData();
  }

  private buildMediaTypeList(): MediatypeView[] {
    const medTypes: MediatypeView[] = [];

    this.selectedTargets.length
      ? this.selectedTargets[0].vehicles.forEach((vehicle) => {
          if (!medTypes.find((med) => med.name === vehicle.mediaType))
            medTypes.push({
              name: vehicle.mediaType,
              isMultiSurvey: vehicle.isMultiSurvey || false,
            });
        })
      : medTypes.push({ name: '** No audiences found **' });

    if (medTypes.length > 1) medTypes.unshift({ name: MEDIATYPE_ALL });

    return medTypes;
  }

  buildTableColumns() {
    if (this.selectedTargets.length) {
      this.selectedTargets.forEach((target) => {
        const targetColumn: TreeTableColumn = {
          header: target.documentTarget.title,
          columnDef: target.id,
          columnType: 'number',
          tooltip: target.documentTarget.title,
          cell: (row) => {
            return row?.data?.hasOwnProperty(target.id)
              ? row.data[target.id]
              : '';
          },
        };
        this.tableColumns.push(targetColumn);
      });
    }
  }

  generateTotalsData() {
    const totalsData: TreeTableNode[] = [];

    const createChildrenData = () => {
      return this.metrics.map((metric) => ({
        name: this.appendUnits.transform(metric, this.unitsText),
        def: metric.columnDef,
        data: {},
      }));
    };

    totalsData.push({
      name: 'Total',
      id: totalsData.length,
      css: 'total-table-row',
      rowCss: 'table-total-row',
      expanded: true,
      children: [],
      skipSort: true,
    });

    const totalsChildrenList = createChildrenData();

    this.selectedTargets.forEach((target) => {
      const valueData = this.mediaplannerService.plan.getTotalsPlanningData(
        'total',
        ScheduleTotalTag.total,
        target,
        this.currentSchedule
      );

      const data = this.planningValueProviderService.getFormattedCellString(
        valueData,
        this.metrics
      );

      totalsChildrenList.forEach((children) => {
        children.data[target.id] = data[children.def];
      });
    });

    totalsData[totalsData.length - 1].children.push(...totalsChildrenList);

    // create objects for each media type
    this.mediaTypes.forEach((mediaType) => {
      if (mediaType.name === MEDIATYPE_ALL) return;

      const multiSurveyRowCss = mediaType.isMultiSurvey
        ? 'multi-survey-title-row'
        : '';
      const mediaTypeNode = {
        name: mediaType.name,
        id: totalsData.length,
        rowCss: `table-title-row ${multiSurveyRowCss}`,
        expanded: true,
        children: [],
      };

      totalsData.push(mediaTypeNode);

      const mediaChildrenList = createChildrenData();

      this.selectedTargets.forEach((target) => {
        const valueData = this.mediaplannerService.plan.getTotalsPlanningData(
          mediaType.name,
          ScheduleTotalTag.mediatype,
          target,
          this.currentSchedule
        );

        const data = this.planningValueProviderService.getFormattedCellString(
          valueData,
          this.metrics
        );

        mediaChildrenList.forEach((children) => {
          children.data[target.id] = data[children.def];
        });
      });

      const perMediaVehicleChildren = [];

      this.selectedTargets.forEach((target, targetIndex) => {
        const vehiclesForCurrentTarget = target.vehicles.filter(
          (vehicle) => vehicle.mediaType === mediaType.name
        );

        vehiclesForCurrentTarget.forEach((vehicle, vehicleIndex) => {
          const vehicleValueData =
            this.mediaplannerService.plan.getVehiclePlanningData(
              target,
              this.currentSchedule,
              vehicle
            );

          const vehicleData =
            this.planningValueProviderService.getFormattedCellString(
              vehicleValueData,
              this.metrics
            );

          const currentVehicleChildren = createChildrenData();
          currentVehicleChildren.forEach((vehicleChildren) => {
            vehicleChildren.data[target.id] = vehicleData[vehicleChildren.def];
          });

          //if first target push a new object else update existing object
          if (targetIndex === 0) {
            perMediaVehicleChildren.push({
              name: vehicle.title,
              id: perMediaVehicleChildren.length,
              expanded: true,
              children: [...currentVehicleChildren],
            });
          } else {
            perMediaVehicleChildren[vehicleIndex].children.forEach(
              (vehicleChildren) => {
                vehicleChildren.data[target.id] =
                  vehicleData[vehicleChildren.def];
              }
            );
          }
        });
      });

      mediaTypeNode.children.push(...mediaChildrenList, {
        name: 'Per Media Vehicle',
        id: mediaChildrenList.length,
        rowCss: 'table-title-per-vehicle',
        expanded: false,
        children: [...perMediaVehicleChildren],
      });
    });

    this.tableData = totalsData;
  }

  // tree header menu click
  onTreeHeaderMenuClick(item: NodeMenuClickEvent) {
    const [section, action] = item.item.data.split('|');

    if (section === 'metrics' && action === 'edit') {
      this.showEditColumnsDialog();
    }
  }

  showEditColumnsDialog() {
    const [containsDirectMail, containsAddressable] =
      this.mediaplannerService.containsAddressableMedia(
        this.selectedTargets[0]
      );
    this.dialogService
      .openEditColumnsDialog({
        planColumnData: this.mediaplannerService.plan.columns,
        currentCompareOption: compareOptionKeys.audiences,
        disabledGroups: {
          addressable: !containsAddressable,
          directMail: !containsDirectMail,
          esgScore: this.selectedTargets?.length
            ? !this.selectedTargets[0].survey.esgProviders?.length
            : true,
        },
      })
      .afterClosed()
      .subscribe((visibleColumns: TreeTableColumn[]) => {
        if (visibleColumns) {
          this.metrics = visibleColumns;
          this.loadData();
        }
      });
  }
}
