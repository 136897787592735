import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  SnackbarCustomisedComponent,
  SnackbarOptionsModel,
  StatusIcon,
} from '../components/dialogs/snackbar-customised/snackbar-customised.component';
import {
  OptimiseProgressSnackbarComponent,
  OptimiseProgressSnackbarOptionsModel,
} from '../components/dialogs/optimise-progress-snackbar/optimise-progress-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showOptimiseProgressBar(
    options: OptimiseProgressSnackbarOptionsModel
  ): MatSnackBarRef<OptimiseProgressSnackbarComponent> {
    return this.snackBar.openFromComponent(OptimiseProgressSnackbarComponent, {
      data: options,
      duration: 0,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: 'optimise-progress-bar',
    });
  }

  showSnackBarCustomised(
    options: SnackbarOptionsModel
  ): MatSnackBarRef<SnackbarCustomisedComponent> {
    return this.snackBar.openFromComponent(SnackbarCustomisedComponent, {
      data: options,
      duration: options.duration || 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: 'snack-bar-customised',
    });
  }

  showSuccessSnackBar(message: string) {
    const options: SnackbarOptionsModel = {
      type: 'success',
      icon: StatusIcon.Success,
      message: message,
    };
    this.showSnackBarCustomised(options);
  }

  showWarningSnackBar(message: string, dismissButton?: string) {
    const options: SnackbarOptionsModel = {
      type: 'warning',
      icon: StatusIcon.Warning,
      message: message,
      dismissButton,
    };
    this.showSnackBarCustomised(options);
  }

  showErrorSnackBar(message: string) {
    const options: SnackbarOptionsModel = {
      type: 'error',
      icon: StatusIcon.Error,
      message: message,
    };
    this.showSnackBarCustomised(options);
  }

  showInfoSnackBar(message: string) {
    const options: SnackbarOptionsModel = {
      type: 'info',
      icon: StatusIcon.Info,
      message: message,
    };
    this.showSnackBarCustomised(options);
  }
}
