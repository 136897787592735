<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="false"
>
  <tup-mat-title-bar
    title="{{ dialogTitle }}"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onButtonClick(false)"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      class="builder-container"
    >
      <mat-progress-bar
        color="accent"
        mode="indeterminate"
        *ngIf="processing"
      ></mat-progress-bar>

      <snackbar-generic
        class="error-container"
        *ngIf="codingError"
        [data]="codingError"
      >
      </snackbar-generic>

      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="display-container"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex="grow"
          class="display-text-field"
        >
          <mat-label>Title</mat-label>
          <input
            matInput
            [(ngModel)]="title"
            matInput
            required
            placeholder="{{ NEW_TARGET_TITLE }}"
          />
        </mat-form-field>
      </div>

      <div
        fxFlex
        fxLayout="column"
        fxGrow="1"
        class="manual-coding-container"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex="grow"
          class="display-text-field"
        >
          <mat-label>Code</mat-label>
          <textarea
            [(ngModel)]="coding"
            matInput
            required
            placeholder="{{ NEW_TARGET_CODING }}"
            (ngModelChange)="
              isResultDirty = true; resultTarget.jsonCoding = null
            "
            class="display-text-field-textarea"
            data-qa="new-coding-container-textarea"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div
      fxLayout="column"
      fxLayoutAlign="start"
    >
      <!-- popn, sample and test coding buttons -->
      <div
        class="coding-preview-container"
        fxLayout="row"
        fxLayoutAlign=" center"
        fxLayoutGap="5px"
        *ngIf="resultTarget"
      >
        <div>
          <span class="coding-preview-label">Population: </span>
          <span>{{
            !processing
              ? (resultTarget.population * (resultTarget.survey.units || 1)
                  | number : "1.0-0") || "-"
              : "..."
          }}</span>
        </div>
        <span>|</span>
        <div>
          <span class="coding-preview-label">Sample: </span>
          <span>{{
            !processing
              ? (resultTarget.sample | number : "1.0-0") || "-"
              : "..."
          }}</span>
        </div>

        <div fxFlex></div>

        <button
          mat-stroked-button
          color="primary"
          tupMatButton
          (click)="onTestCoding()"
        >
          Check code
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      class="btn-action"
      appearance="small"
      tupMatButton
      (click)="onButtonClick(false)"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      class="btn-action"
      appearance="small"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick(true)"
      data-qa="new-code-builder-ok-button"
    >
      {{ confirmText }}
    </button>
  </mat-dialog-actions>
</div>
