import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';

export const deleteLastVehicleWarning: SnackbarGenericOptionModel = {
  type: 'warning',
  message: 'Warning! If you remove all items, this group will also be deleted',
  icon: StatusSnackbarIcon.Warning,
};
export interface GroupWarningDialogModel {
  message: string;
}

@Component({
  selector: 'app-group-warning-dialog',
  templateUrl: './group-warning-dialog.component.html',
  styleUrls: ['./group-warning-dialog.component.scss'],
})
export class GroupWarningDialogComponent implements OnInit {
  lastVehicleWarning: SnackbarGenericOptionModel = deleteLastVehicleWarning;

  constructor(
    public dialogRef: MatDialogRef<GroupWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupWarningDialogModel
  ) {}

  ngOnInit(): void {}

  onClose(save: boolean) {
    this.dialogRef.close(save);
  }
}
