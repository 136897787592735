import { Component, Inject, OnInit } from '@angular/core';
import { ColumnCollection } from '../../../classes/column-collection';
import { TreeTableColumn } from '../../tree-table/tree-table.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { GAEvents } from '../../../models/analytics.model';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';

export interface SpotColumnsDialogModel {
  planColumnData: ColumnCollection;
}

@Component({
  selector: 'spot-columns-dialog',
  templateUrl: './spot-columns-dialog.component.html',
  styleUrls: ['./spot-columns-dialog.component.scss'],
})
export class SpotColumnsDialogComponent implements OnInit {
  allColumns: TreeTableColumn[];
  unitsText: string;
  allSelected: boolean;
  noColumnsSelected: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SpotColumnsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SpotColumnsDialogModel,
    private mediaplannerService: MediaPlannerService,
    private analyticsService: TupAnalyticsService
  ) {}

  ngOnInit(): void {
    this.unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
      this.mediaplannerService.plan.currentSurvey.code
    ).reportUnitText;
    this.noColumnsSelected = false;

    this.allColumns = this.data.planColumnData.allSpotColumns.map((column) => {
      return { ...column };
    });
    this.analyticsService.e(GAEvents.columns_dialog, {
      action: 'spots plan',
    });
  }

  onClose(save: boolean) {
    let returnData = {};
    if (save) {
      this.data.planColumnData.allSpotColumns = [...this.allColumns];

      returnData = {
        columns: this.data.planColumnData.allSpotColumns.filter(
          (col) =>
            this.allColumns.find((c) => c.columnDef === col.columnDef).visible
        ),
      };
    }
    this.dialogRef.close(save ? returnData : null);
  }

  onColumnChanged(data, col: TreeTableColumn = null) {
    col.visible = data.checked;

    this.noColumnsSelected = this.verifyIfNoColumnSelected();
  }

  onSelectAll(data) {
    let columns = this.allColumns;

    columns.map((col) => {
      if (!col.disabled) {
        col.visible = data.checked;
      }
    });
    this.noColumnsSelected = this.verifyIfNoColumnSelected();
  }

  verifyIfNoColumnSelected() {
    return this.allColumns.find((col) => col.visible === true) === undefined;
  }
}
