import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';

@Component({
  selector: 'app-exported-google-file-dialog',
  templateUrl: './exported-google-file-dialog.component.html',
  styleUrls: ['./exported-google-file-dialog.component.scss'],
})
export class ExportedGoogleFileDialogComponent implements OnInit {
  public exportedFileLink: string;

  successMessage: SnackbarGenericOptionModel = {
    type: 'success',
    message: 'Your file has been successfully exported.',
    icon: StatusSnackbarIcon.Success,
  };

  constructor(
    public dialogRef: MatDialogRef<ExportedGoogleFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    @Inject(Window) private window: Window
  ) {
    this.exportedFileLink = data;
  }

  ngOnInit(): void {}

  onButtonClick(): void {
    if (this.exportedFileLink) {
      this.window.open(this.exportedFileLink, '_blank');
    }

    this.onCloseClick();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
