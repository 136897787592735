import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentFullSurvey } from 'src/app/models/document.model';

@Component({
  selector: 'app-survey-media-types-table-dialog',
  templateUrl: './survey-media-types-table-dialog.component.html',
  styleUrls: ['./survey-media-types-table-dialog.component.scss'],
})
export class SurveyMediaTypesTableDialogComponent implements OnInit {
  public surveys: DocumentFullSurvey[] = [];
  displayedColumns: string[] = ['title', 'mediaTypes'];
  dataSource = [];

  constructor(
    public dialogRef: MatDialogRef<SurveyMediaTypesTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      surveys: DocumentFullSurvey[];
    }
  ) {
    this.surveys = data.surveys;
  }

  ngOnInit(): void {
    this.surveys.forEach((survey) => {
      const mediaTypesArray = survey.mediaTypes?.length
        ? JSON.parse(survey.mediaTypes)
        : ['-']; // add a hyphen if no media types
      this.dataSource.push({
        title: survey.title,
        mediaTypes: mediaTypesArray.join(', '),
      });
    });
  }

  close() {
    this.dialogRef.close();
  }
}
