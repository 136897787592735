import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadFile } from '../../models/file-upload-model';

@Component({
  selector: 'documents-toolbar',
  templateUrl: './documents-toolbar.component.html',
  styleUrls: ['./documents-toolbar.component.scss'],
})
export class DocumentsToolbarComponent implements OnInit {
  @Input() dataSource;
  @Input() initialising: boolean;
  @Input() singleDocumentText: string;
  @Input() multipleDocumentsText: string;
  @Input() searchLabel: string;

  @Output() onNewCampaignClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onImportFromLocalFileClick: EventEmitter<UploadFile> =
    new EventEmitter<UploadFile>();

  constructor() {}

  ngOnInit(): void {}

  importFromLocalFile() {
    this.onImportFromLocalFileClick.emit();
  }

  createNewCampaign() {
    this.onNewCampaignClick.emit();
  }

  onFileUploaded(file: UploadFile) {
    this.onImportFromLocalFileClick.emit(file);
  }
}
