<div class="dialog-wrapper">
  <tup-mat-title-bar>
    <span fxFlex>
      <h2>Settings</h2>
    </span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose(false)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>

  <mat-dialog-content class="container">
    <mat-divider></mat-divider>
    <div
      class="main-content"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="8px"
      >
        <span>Show frequency level to:</span>
        <number-incremental
          [(value)]="freqLevelTo"
          [min]="2"
          [max]="29"
          suffix=" +"
          data-tracking="freq_level_to_input"
        ></number-incremental>
      </div>
      <div>
        <mat-slide-toggle
          style="user-select: none"
          disableRipple
          color="primary"
          [(ngModel)]="isGroupingOn"
          data-tracking="grouping_toggle"
        >
          Groups
        </mat-slide-toggle>
      </div>
    </div>
    <div
      class="main-content groups-container"
      *ngIf="isGroupingOn"
      fxLayoutAlign="space-between center"
      data-tracking="groups_container"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="8px"
        class="group"
      >
        <span>Show all frequencies up to:</span>
        <number-incremental
          [(value)]="freqUpTo"
          [min]="1"
          [max]="freqLevelTo - 1"
          data-tracking="freq_up_to_input"
        ></number-incremental>
      </div>
      <div class="vertical-divider"></div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="8px"
        class="group"
      >
        <span>After that, show in groups of:</span>
        <number-incremental
          [(value)]="groupsOf"
          [min]="2"
          data-tracking="groups_of_input"
        ></number-incremental>
      </div>
    </div>
    <mat-divider></mat-divider>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-button
      (click)="onClose(false)"
    >
      CANCEL
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="onClose(true)"
      data-tracking="save_btn"
    >
      SAVE CHANGES
    </button>
  </mat-dialog-actions>
</div>
