<div class="multiple-tabs-dialog">
  <tup-mat-title-bar [title]="dialogTitle">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>

  <snackbar-generic
    class="metric-column-dialog-error"
    *ngIf="duplicateManualTitle !== '' || incompatibleFileFormat"
    [data]="snackbarError"
  >
  </snackbar-generic>

  <breadcrumbs
    *ngIf="showBreadcrumbs"
    [breadcrumbsSteps]="breadcrumbsSteps"
    [currentStep]="currentStep"
    (stepChanged)="onBreadCrumbsStepChange($event)"
  ></breadcrumbs>

  <!-- File import flow when starting new campaign or media/plan screen  -->
  <div
    *ngIf="
      [dialogActions.dashboardImport, dialogActions.appImport].includes(
        data.action
      )
    "
  >
    <ng-container *ngIf="currentStep === 1; then step1Template"> </ng-container>

    <ng-container *ngIf="currentStep === 2; then step2Template"> </ng-container>

    <ng-container *ngIf="currentStep === 3; then step3Template"> </ng-container>

    <ng-container *ngIf="currentStep === 4; then selectAudienceForManualRF">
    </ng-container>
  </div>

  <ng-container
    cdkDrop
    [ngSwitch]="data.action"
  >
    <ng-container *ngSwitchCase="dialogActions.changeAudience">
      <ng-container *ngTemplateOutlet="changeAudienceTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="dialogActions.preferences">
      <import-preferences
        #importPreferences
        [multiSurveyId]="multiSurveyId"
      ></import-preferences>
      <mat-divider></mat-divider>
      <ng-container *ngTemplateOutlet="updateDialogButtons"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #step1Template>
  <div *ngIf="uploadStarted">
    <ng-container *ngTemplateOutlet="uploadedFileDetails"></ng-container>
  </div>
  <div [ngClass]="{ hidden: uploadStarted }">
    <ng-container
      *ngTemplateOutlet="
        data.action === dialogActions.appImport ? tabsTemplate : uploadTemplate
      "
    ></ng-container>
  </div>
  <div *ngIf="fileUploaded">
    <import-preferences #importPreferences> </import-preferences>
  </div>
  <mat-divider
    [ngClass]="{
      'divider-without-top-space': !fileUploaded
    }"
  ></mat-divider>
  <mat-dialog-actions fxLayoutAlign="space-between center">
    <!--    Left button: Back or Cancel -->
    <button
      *ngIf="data.action === dialogActions.dashboardImport"
      mat-button
      tupMatButton
      (click)="onBack()"
    >
      Back
    </button>
    <button
      *ngIf="data.action === dialogActions.appImport"
      mat-button
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>

    <!--    Right button -->
    <ng-container
      *ngIf="
        data.action === dialogActions.dashboardImport || selectedDialogTab === 0
      "
    >
      <button
        [disabled]="!fileUploaded"
        mat-flat-button
        tupMatButton
        color="primary"
        (click)="onSelectDataFromFile()"
      >
        Use data from file
      </button>
    </ng-container>
    <ng-container *ngIf="selectedDialogTab === 1">
      <button
        *ngIf="planTargets.length === 1"
        [disabled]="
          !manualEntryForm.manualMediaTitle ||
          !manualEntryForm.manualReachPct ||
          !manualEntryForm.manualAvgFreq ||
          manualEntryForm.maxReachExceededMessage !== '' ||
          manualEntryForm.duplicateManualTitle !== ''
        "
        mat-flat-button
        tupMatButton
        color="primary"
        (click)="onAddManualRF()"
      >
        Add
      </button>
      <button
        *ngIf="planTargets.length > 1"
        [disabled]="
          !manualEntryForm.manualMediaTitle ||
          !manualEntryForm.manualReachPct ||
          !manualEntryForm.manualAvgFreq ||
          manualEntryForm.maxReachExceededMessage !== '' ||
          manualEntryForm.duplicateManualTitle !== ''
        "
        mat-flat-button
        tupMatButton
        color="primary"
        (click)="onNextPressed(4)"
      >
        Next
      </button>
    </ng-container>
  </mat-dialog-actions>
</ng-template>

<ng-template #step2Template>
  <ng-container *ngTemplateOutlet="uploadedFileDetails"></ng-container>
  <ng-container *ngTemplateOutlet="audienceListTemplate"></ng-container>

  <mat-divider></mat-divider>

  <mat-dialog-actions fxLayoutAlign="space-between center">
    <!--    Left button: [Back] or [Cancel] -->
    <button
      *ngIf="data.action === dialogActions.changeAudience"
      mat-button
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      *ngIf="data.action !== dialogActions.changeAudience"
      mat-button
      tupMatButton
      (click)="onBack()"
    >
      Back
    </button>

    <!--    Right button: [Add - if only MRF files uploaded] or [Next - when at least 1 DAU file uploaded] -->
    <button
      *ngIf="!dauCont"
      mat-flat-button
      tupMatButton
      color="primary"
      (click)="onAdd()"
    >
      Add
    </button>
    <button
      *ngIf="dauCont"
      [disabled]="
        multiSurveyArray[0].fileType === 'dau' &&
        (!selectedAudienceChanged || noSelectedAudience)
      "
      mat-flat-button
      tupMatButton
      color="primary"
      (click)="onNextPressed()"
    >
      Next
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #step3Template>
  <ng-container *ngTemplateOutlet="uploadedFileDetails"></ng-container>
  <ng-container *ngTemplateOutlet="selectPlanAudience"></ng-container>

  <mat-divider> </mat-divider>

  <mat-dialog-actions fxLayoutAlign="space-between center">
    <!--    Left button: Back  -->
    <button
      mat-button
      tupMatButton
      (click)="onBack()"
    >
      Back
    </button>

    <!--    Right button -->
    <button
      [disabled]="
        !selectedPlanAudience ||
        (data.action === dialogActions.dashboardImport &&
          selectedSurveyIndex === null)
      "
      mat-flat-button
      tupMatButton
      color="primary"
      (click)="onAdd()"
    >
      Add
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #selectAudienceForManualRF>
  <ng-container *ngTemplateOutlet="selectPlanAudience"></ng-container>

  <mat-divider> </mat-divider>

  <mat-dialog-actions fxLayoutAlign="space-between center">
    <!--    Left button: Back  -->
    <button
      mat-button
      tupMatButton
      (click)="onStepChange(1)"
    >
      Back
    </button>

    <!--    Right button -->
    <button
      [disabled]="!selectedPlanAudience"
      mat-flat-button
      tupMatButton
      color="primary"
      (click)="onAddManualRF()"
    >
      Add
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #changeAudienceTemplate>
  <ng-container *ngIf="currentStep === 2; then step2Template"></ng-container>
  <ng-container *ngIf="currentStep === 3; then step3Template"></ng-container>
</ng-template>

<ng-template #uploadedFileDetails>
  <div
    class="uploaded-file-container"
    fxLayout="column"
    fxLayoutGap="16px"
    fxLayoutAlign="center center"
  >
    <div
      class="uploaded-file"
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <div
        class="file-name"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div>{{ fileName }}</div>
        <mat-icon
          [ngClass]="{
            'cancel-icon': uploadProgress < 100,
            'check-icon': uploadProgress === 100
          }"
          >{{ uploadProgress < 100 ? "cancel" : "check_circle" }}</mat-icon
        >
      </div>
      <div
        *ngIf="uploadProgress < 100"
        fxLayout="column"
        fxLayoutGap="6px"
      >
        <div
          class="upload-details file-size"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div>{{ fileSize }}</div>
          <div>{{ uploadProgress }}%</div>
        </div>
        <mat-progress-bar
          mode="determinate"
          value="{{ uploadProgress }}"
        ></mat-progress-bar>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tabsTemplate>
  <mat-tab-group
    mat-stretch-tabs
    animationDuration="0ms"
    (selectedTabChange)="onTabChanged($event)"
  >
    <mat-tab label="Import file">
      <ng-container *ngTemplateOutlet="uploadTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Input manual entry">
      <div class="tab-container">
        <manual-entry
          #manualEntryForm
          [multiSurveyId]="multiSurveyId"
          (duplicateTitle)="onDuplicateTitleCheck($event)"
        ></manual-entry>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template #uploadTemplate>
  <div
    class="import-form-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="16px"
  >
    <file-upload
      data-tracking="import-document-btn"
      [disabled]="false"
      (uploadStarted)="onUploadStarted($event)"
      (uploadProgress)="onUploadProgress($event)"
      (uploaded)="onFileUploaded($event)"
      [text]="'Select file...'"
      [acceptedFileType]="allowedFileExtensions.join(',')"
      [showIcon]="false"
    >
    </file-upload>
    <p class="file_upload_hint">
      Upload files from other <b>TelmarHelixa</b> applications into Plan.
    </p>
  </div>
</ng-template>

<ng-template #audienceListTemplate>
  <p class="audience-list-header">
    The following available target plan(s) have been found:
  </p>
  <div
    class="audience-container"
    fxLayout="column"
    fxLayoutAlign="start start"
  >
    <mat-radio-group class="audience-list-container">
      <div
        *ngFor="let file of multiSurveyArray; let fileIndex = index"
        fxLayout="column"
      >
        <div class="file-title-container">
          <span class="file-title">{{ file.fileTitle }}</span>
          <mat-icon
            class="remove-file-icon"
            (click)="onRemoveFileClick(fileIndex)"
            *ngIf="multiSurveyArray.length > 1"
            >close</mat-icon
          >
        </div>
        <mat-radio-group
          [(ngModel)]="selectedTargetPerFile[fileIndex]"
          (change)="onSelectedAudienceChange()"
        >
          <mat-radio-button
            *ngFor="let fileItem of file.fileItems; let audIndex = index"
            class="audience-list-radio-button"
            [value]="audIndex"
            [checked]="selectedTargetPerFile[fileIndex] === audIndex"
            [disabled]="fileItem.disabled"
            name="{{ fileItem.name }}"
            matTooltip="{{ fileItem.tooltip }}"
          >
            {{ fileItem.name }} {{ fileItem.extraDetails }}
          </mat-radio-button>
          <mat-radio-button
            *ngIf="dauCont > 1 && file.fileType === 'dau'"
            class="audience-list-radio-button"
            [value]="-1"
            (change)="onSelectedAudienceChange()"
            >None</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </mat-radio-group>
  </div>
</ng-template>

<ng-template #selectPlanAudience>
  <snackbar-generic
    *ngIf="data.action === dialogActions.appImport"
    class="snackbar-bottom-spacing"
    [data]="selectAudienceSnackbarWarning"
  >
  </snackbar-generic>
  <mat-form-field
    appearance="outline"
    class="full-width sort-selectxx"
    tupMatFormField
  >
    <mat-label>Select audience</mat-label>
    <mat-select
      [(value)]="selectedPlanAudience"
      panelClass="select-audience-dropdown"
    >
      <mat-select-trigger>
        {{ selectedAudienceValue }}
      </mat-select-trigger>

      <mat-option
        *ngFor="let file of filteredFileAudiences"
        [value]="file.fileId"
      >
        {{ file.audienceName }}
        <mat-icon *ngIf="selectedPlanAudience === file.fileId">check</mat-icon>
      </mat-option>
      <mat-option
        *ngIf="data.action === dialogActions.dashboardImport"
        [value]="audienceActions.buildOwnAud"
        >I build my own audience
        <mat-icon *ngIf="selectedPlanAudience === audienceActions.buildOwnAud"
          >check</mat-icon
        >
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    class="full-width sort-selectxx"
    *ngIf="data.action === dialogActions.dashboardImport"
    tupMatFormField
  >
    <mat-label>Select survey</mat-label>
    <mat-select
      [(value)]="selectedSurveyIndex"
      panelClass="select-audience-dropdown"
    >
      <mat-select-trigger>
        {{
          surveyList[selectedSurveyIndex]
            ? surveyList[selectedSurveyIndex]?.title
            : ""
        }}
      </mat-select-trigger>
      <mat-form-field
        class="search-survey-form-field"
        appearance="outline"
        tupMatFormField
        *ngIf="!disabledSearchInput"
      >
        <button
          matPrefix
          mat-icon-button
          matTooltip="Search"
          class="search-btn"
        >
          <mat-icon *ngIf="!surveySearchLoading">search</mat-icon>
          <processing
            *ngIf="surveySearchLoading"
            diameter="20"
          ></processing>
        </button>
        <input
          matInput
          type="text"
          placeholder="Search"
          [(ngModel)]="searchSurveyText"
          (input)="triggerSearch($event.target.value)"
          (blur)="onSurveyInputBlur()"
        />
      </mat-form-field>
      <mat-option
        *ngFor="let survey of surveyList; let i = index"
        [value]="i"
      >
        {{ survey.title }}
        <mat-icon *ngIf="selectedSurveyIndex === i">check</mat-icon>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #updateDialogButtons>
  <mat-dialog-actions fxLayoutAlign="space-between center">
    <button
      mat-button
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      tupMatButton
      color="primary"
      (click)="onUpdate()"
    >
      Update
    </button>
  </mat-dialog-actions>
</ng-template>
