import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

export interface DataRow {
  data: DataCell[];
}

export interface DataCell {
  columnHeader: string;
  columnHeaderId: string;
  value: number | string;
}

export interface TableColumn {
  name: string;
  title: string;
}

export interface AvailableDataItem {
  id: number;
  label: string;
}

@Component({
  selector: 'charts-table',
  templateUrl: './charts-table.component.html',
  styleUrls: ['./charts-table.component.scss'],
})
export class ChartsTableComponent implements OnInit {
  @Input() data: DataRow[];
  targetColumns: TableColumn[];
  displayedColumns: string[];
  public dataSource: MatTableDataSource<DataRow> = new MatTableDataSource([]);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.setupTable(this.data);
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
  }

  private setupTable(data: DataRow[]): void {
    this.clearCurrentData();
    this.populateTableData(data);
    this.addTableColumns();
  }

  private populateTableData(data: DataRow[]): void {
    this.dataSource.data = data;
  }

  private addTableColumns(): void {
    this.targetColumns = [
      ...this.dataSource.data[0].data.map((cell: DataCell) => ({
        name: this.formatColumnDef(cell),
        title: cell.columnHeader,
      })),
    ];

    this.displayedColumns.push(
      ...this.targetColumns.map((column: any) => column.name)
    );
  }

  private formatColumnDef(cell: DataCell): string {
    return `${cell.columnHeaderId}`;
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
    this.targetColumns = [];
    this.displayedColumns = [];
  }
}
