<mat-dialog-content class="container">
  <h2 class="placeholder-message">
    <mat-icon>school</mat-icon><br />Cross media planning training material
    coming soon
  </h2>
  <!-- <iframe
    src="https://drive.google.com/file/d/1bSybAuJQ5-5lBtExoImma4-lrnsATuxE/preview?start=false&loop=true&delayms=3000"
    width="100%" height="100%" frameborder="0" allow="autoplay" allowfullscreen="true" mozallowfullscreen="true"
    webkitallowfullscreen="true">
  </iframe> -->
</mat-dialog-content>

<hr />

<mat-dialog-actions
  class="app-dialog-actions"
  fxLayoutAlign="space-between center"
>
  <!-- https://drive.google.com/file/d/1SYKEMaJrovBWr6-DdFjAmC1JdCB73oPt/view?usp=sharing -->

  <a
    class="download"
    href=""
  >
    <button
      mat-menu-item
      (click)="openedGuide($event)"
    >
      <mat-icon>description</mat-icon>
      <span>Download Quick Guide</span>
    </button>
  </a>
  <button
    mat-flat-button
    tupMatButton
    appearance="small"
    color="primary"
    (click)="close()"
  >
    Close
  </button>
</mat-dialog-actions>
