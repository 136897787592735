import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Target } from 'src/app/classes/target';
import { DocumentSurvey } from '../../models/document.model';

@Component({
  selector: 'app-audience-item',
  templateUrl: './audience-item.component.html',
  styleUrls: ['./audience-item.component.scss'],
})
export class AudienceItemComponent implements OnInit {
  @Input() audienceTarget: Target;
  @Input() vehicleSurvey: DocumentSurvey;
  @Input() checked: boolean;
  @Output() selected: EventEmitter<Target> = new EventEmitter<Target>();
  @Output() editClicked: EventEmitter<Target> = new EventEmitter<Target>();

  disabled: boolean = false;

  constructor() {}

  get target(): Partial<Target> {
    return (
      this.audienceTarget || {
        id: 'none',
        title: 'No addressable audience',
      }
    );
  }

  ngOnInit(): void {
    if (this.audienceTarget) {
      this.disabled =
        this.audienceTarget.survey.code !== this.vehicleSurvey.code ||
        this.audienceTarget.survey.authorizationGroup !==
          this.vehicleSurvey.authorizationGroup;
    }
  }

  onClick(event) {
    this.selected.emit(this.audienceTarget);
  }

  onEditItem() {
    this.editClicked.emit(this.audienceTarget);
  }
}
