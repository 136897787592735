import { Injectable } from '@angular/core';
import {
  LoadDocumentResult,
  MediaPlannerService,
} from './media-planner.service';
import { Observable, forkJoin } from 'rxjs';
import { cloneDeep } from 'lodash';
import { TupDocument } from '@telmar-global/tup-document-storage';
import {
  DocumentCampaign,
  DocumentFullSurvey,
  DocumentOrigin,
  DocumentSchedule,
  DocumentSurvey,
  DocumentTargetResults,
  UNSAVED_DOCUMENT,
} from '../models/document.model';
import { CodebookService } from './codebook.service';
import { TupAuthService } from '@telmar-global/tup-auth';
import { TargetDaypart, TargetVehicle } from '../classes/vehicle';
import { EngineService } from './engine.service';
import { DocumentService } from './document.service';
import { Compression } from '../models/compression';

enum failureReason {
  notACampaign = 'This does not appear to be a Mediaplan campaign import file',
  noAudiences = "Campaign doesn't contain any audiences",
  missingSurvey = "Survey not available or you're not cleared to use it",
  addressableVehicle = 'Addressable vehicle removed',
}

const CalcMethodLookup: { [v1CalcMethod: string]: string } = {
  RCHPOISSON: 'poisson',
  RCHADDRMEDIA: 'addressable',
  RCHPROBTVADJUSTED: 'probabilityTVAdjusted',
  RCHPROBS: 'binomial',
  RCHUKOM: 'ukom',
  RCHNBD: 'NBD 2-parameter',
  RCHPOISSON3POINT: '3PointPoisson',
  RCHJICMAILTP: 'jicmailaddressable',
};

@Injectable({
  providedIn: 'root',
})
export class MigrationService {
  constructor(
    private documentService: DocumentService,
    private codebookService: CodebookService,
    private engineService: EngineService,
    private mediaplannerService: MediaPlannerService,
    private authService: TupAuthService
  ) {}

  parseCampaignObject(file: string): Observable<LoadDocumentResult> {
    return new Observable((observable) => {
      const handleComplete = (result: LoadDocumentResult) => {
        observable.next(result);
        observable.complete();
      };

      try {
        const campaignFile = <TupDocument<DocumentCampaign>>JSON.parse(file);
        const isCompressed = !!campaignFile.content.header.version;

        if (isCompressed) {
          campaignFile.content.baseTarget = Compression.decompress(
            <string[]>campaignFile.content.baseTarget
          );

          campaignFile.content.targets = Compression.decompress(
            <string[]>campaignFile.content.targets
          );

          campaignFile.content.schedules = Compression.decompress(
            <string[]>campaignFile.content.schedules
          ) as DocumentSchedule[];

          campaignFile.content.multiSurveys = campaignFile.content.multiSurveys
            ? Compression.decompress(
                <string[]>campaignFile.content.multiSurveys
              )
            : [];

          // decompression finished -
          //set version to 0 so the object can be passed to loadCampaign without being decompressed again
          campaignFile.content.header.version = 0;
        }

        campaignFile.metadata.container = cloneDeep(
          this.documentService.container
        );

        campaignFile.content.brief =
          this.mediaplannerService.plan.clearBriefing(
            campaignFile.content.brief.plannerName ||
              this.authService.user.attributes?.name
          );

        // 1. check all the surveys are valid and cleared
        this.verifySurveyClearances(campaignFile).subscribe(
          (result: LoadDocumentResult) => {
            if (result.success) {
              // 2. Fill in any missing pieces and validate target vehicles
              this.verifyTargetVehicles(result.campaignFile).subscribe(
                (result: LoadDocumentResult) => {
                  handleComplete(result);
                }
              );
            } else {
              handleComplete(result);
            }
          }
        );
      } catch (error) {
        handleComplete({
          success: false,
          errors: [failureReason.notACampaign],
          campaignFile: null,
        });
      }
    });
  }

  // Verify the surveys from the campaign file are available in the V2 platform
  // Locate the survey and (either regular or tagged) and ensure the authorizationGroup is asigned correctly
  private verifySurveyClearances(
    campaignFile: TupDocument<DocumentCampaign>
  ): Observable<LoadDocumentResult> {
    return new Observable((observable) => {
      const handleComplete = (success: boolean, errors: Set<string>) => {
        observable.next({ success, errors: Array.from(errors), campaignFile });
        observable.complete();
      };
      const errors = new Set<string>();
      let { survey, targets } = campaignFile.content;

      if (!targets || !targets.length) {
        errors.add(failureReason.noAudiences);
        handleComplete(false, errors);
        return;
      }

      // fetch all the cleared surveys and verify the surveycode and authGroup
      this.codebookService
        .getSurveys()
        .subscribe((surveys: DocumentSurvey[]) => {
          const clearedSurveys = surveys.map((clearedSurvey) =>
            clearedSurvey.code.toLowerCase()
          );

          let code;
          (<DocumentTargetResults[]>targets).forEach((target) => {
            code = target.documentTarget.survey.code.toLowerCase();
            if (clearedSurveys.includes(code)) {
              // locate the survey and assign the default auth group
              const survey = surveys.find(
                (sur) =>
                  sur.code.toLowerCase() ===
                  target.documentTarget.survey.code.toLocaleLowerCase()
              );
              target.documentTarget.survey.title = survey.title;
              target.documentTarget.survey.code = survey.code;
              target.documentTarget.survey.authorizationGroup =
                survey.authorizationGroups[0];
            } else {
              // survey not found.. See if it's a tagged survey by checking all the authGroups.
              for (let survey of surveys) {
                const authGroups = survey.authorizationGroups.map((gr) =>
                  gr.toLowerCase()
                );
                if (authGroups.includes(code)) {
                  target.documentTarget.survey.title = survey.title;
                  target.documentTarget.survey.code = survey.code;
                  target.documentTarget.survey.authorizationGroup =
                    code.toUpperCase();
                  break;
                }
              }
            }

            if (!target.documentTarget.survey.authorizationGroup) {
              errors.add(
                `${failureReason.missingSurvey} (${target.documentTarget.survey.code})`
              );
            }
          });

          // any issues, dont continue
          if (errors.size) {
            handleComplete(false, errors);
            return;
          }

          const target = <DocumentTargetResults>targets[0];

          // Get full contents of the main full surveys (copyrights, etc)
          survey.code = target.documentTarget.survey.code;
          survey.authorizationGroup =
            target.documentTarget.survey.authorizationGroup;
          this.codebookService
            .getSurvey(survey.code, survey.authorizationGroup)
            .subscribe(
              (fetchedSurvey: DocumentFullSurvey) => {
                if (fetchedSurvey) {
                  campaignFile.content.survey = cloneDeep(fetchedSurvey);
                } else {
                  errors.add(`${failureReason.missingSurvey} (${survey.code})`);
                }

                handleComplete(!!fetchedSurvey, errors);
              },
              (error) => {
                console.log(error);
              }
            );
        });
    });
  }

  // fill in the bits missing on target vehicles and remove any vehicles that cannot be used (addressable)
  private verifyTargetVehicles(
    campaignFile: TupDocument<DocumentCampaign>
  ): Observable<LoadDocumentResult> {
    return new Observable((observable) => {
      const result: LoadDocumentResult = {
        success: true,
        errors: [],
        warnings: [],
        campaignFile,
      };

      this.mediaplannerService
        .getSurveyMetaData(campaignFile.content.survey)
        .subscribe(() => {
          let { targets } = campaignFile.content;
          targets = <DocumentTargetResults[]>targets;

          // for each target, add the additonal data and correct the vehicles
          targets.forEach((target) => {
            const exclude: TargetVehicle[] = [];

            target.vehicles.forEach((vehicle: TargetVehicle) => {
              vehicle.mnemonic = vehicle.mnemonic || vehicle.id;
              vehicle.originalTitle = vehicle.title;
              vehicle.calculationMethod =
                CalcMethodLookup[vehicle.calculationMethod];
              vehicle.addressable = this.mediaplannerService.plan.surveyMetaData
                .meta(campaignFile.content.survey.code)
                .isAddressable(vehicle.mediaTypeId);
              vehicle.grossAudience = vehicle.audience;

              // additional daypart values
              if (vehicle.dayparts) {
                vehicle.dayparts.forEach((daypart: TargetDaypart) => {
                  daypart.mnemonic = daypart.mnemonic || daypart.id;
                  daypart.grossAudience = daypart.audience;
                });
              } else {
                vehicle.dayparts = [];
              }

              if (
                vehicle.calculationMethod === CalcMethodLookup['RCHADDRMEDIA']
              )
                exclude.push(vehicle);
            });

            // remove invalid vehicles and ad to warnings array
            if (exclude.length) {
              const excludeIds = exclude.map((ex) => ex.id);
              target.vehicles = target.vehicles.filter(
                (veh) => !excludeIds.includes(veh.id)
              );

              // remove the vehicles from any results arrays
              (<DocumentSchedule[]>campaignFile.content.schedules).forEach(
                (schedule) => {
                  schedule.vehicles = schedule.vehicles.filter(
                    (veh) => !excludeIds.includes(veh.vehicleId)
                  );
                }
              );

              result.warnings.push(
                ...exclude.map(
                  (ex) => ` ${failureReason.addressableVehicle}: ${ex.title}`
                )
              );
            }
          });

          result.warnings = Array.from(new Set<string>(result.warnings));
          observable.next(result);
          observable.complete();
        });
    });
  }
}
