import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { UserContainer } from '@telmar-global/tup-auth';
import {
  TupUserContainerSelectorComponent,
  TupTagEditorComponent,
  TupTag,
  TupDocument,
} from '@telmar-global/tup-document-storage';
import { DocumentCampaign } from '../../../models/document.model';

export interface SaveDocumentDialogOptions {
  dialogTitle?: string;
  nameFormControlValue?: string;
  descriptionFormControlValue?: string;
  skipButtonText?: string;
  enableSaveOnInit?: boolean;
  autoFocus?: SaveDocumentDialogAutoFocus;
  showDrives: boolean;
  document?: TupDocument<DocumentCampaign>;
}

export interface SaveDocumentDialogData extends SaveDocumentDialogOptions {
  dialogTitle: string;
  nameFormControlLabel: string;
  descriptionFormControlLabel: string;
  cancelButtonText: string;
  saveButtonText: string;
  showDrives: boolean;
}

export interface SaveDocumentDialogAutoFocus {
  formControl: InputType;
  select?: boolean;
  delay?: number;
}

type SaveType = 'Save' | 'Duplicate';
type DocumentType = 'Document' | 'Template' | 'Campaign';
type InputType = 'Name' | 'Description';

export function getSaveDocumentDialogData(
  saveType: SaveType = 'Save',
  documentType: DocumentType = 'Document',
  options?: SaveDocumentDialogOptions,
  descriptionLabel?: string
): SaveDocumentDialogData {
  return Object.assign(
    {
      dialogTitle: `${saveType} ${documentType.toLocaleLowerCase()}`,
      nameFormControlLabel: `${documentType} name`,
      descriptionFormControlLabel: descriptionLabel
        ? descriptionLabel
        : `${documentType} description`,
      cancelButtonText: 'Cancel',
      saveButtonText: saveType,
    },
    options
  );
}

export interface SaveDocumentDialogResult {
  name?: string;
  description?: string;
  container?: UserContainer;
  tags: TupTag[];
}

@Component({
  templateUrl: 'save-document-dialog.component.html',
  styleUrls: ['./save-document-dialog.component.scss'],
})
export class SaveDocumentDialogComponent implements OnInit, AfterViewInit {
  public formGroup: FormGroup;
  @ViewChild('name') nameInput: ElementRef;
  @ViewChild('description') descriptionInput: ElementRef;
  @ViewChild('confirmButton') confirmButton: MatButton;
  @ViewChild(TupUserContainerSelectorComponent, { static: true })
  public userContainerSelector: TupUserContainerSelectorComponent;
  @ViewChild('tagEditor') tagEditor: TupTagEditorComponent;

  public container: UserContainer;
  public showDrives: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SaveDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveDocumentDialogData
  ) {}

  public get disabled(): boolean {
    return (
      (!this.data.enableSaveOnInit &&
        this.formGroup.untouched &&
        this.formGroup.pristine) ||
      this.formGroup.invalid ||
      this.tagEditor?.content.formGroup.invalid
    );
  }

  public ngOnInit(): void {
    this.showDrives = this.data.showDrives;

    this.formGroup = this.formBuilder.group({
      name: [
        this.data.nameFormControlValue
          ? `${this.data.saveButtonText === 'Duplicate' ? 'Copy of - ' : ''}${
              this.data.nameFormControlValue
            }`
          : undefined,
        Validators.required,
      ],
      description: [this.data.descriptionFormControlValue],
    });
  }

  ngAfterViewInit() {
    this.container = this.userContainerSelector?.container;

    const delay = this.data.autoFocus ? this.data.autoFocus.delay || 0 : 0;
    setTimeout(() => {
      if (this.data.autoFocus) {
        const control =
          this.data.autoFocus.formControl === 'Name'
            ? this.nameInput.nativeElement
            : this.descriptionInput.nativeElement;
        control.focus();
        this.data.autoFocus.select ? control.select() : {};
      } else {
        this.confirmButton.focus();
      }
    }, delay);
  }

  public selectionChange(event: MatSelectionListChange): void {
    this.container = event.option.value;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public skip(): void {
    this.dialogRef.close({});
  }

  public save(): void {
    const result: SaveDocumentDialogResult = this.formGroup.getRawValue();
    result.container = this.container;
    result.tags = this.tagEditor?.content.formGroupRawValueToTags;

    // Remove null or undefined values
    Object.keys(result).forEach(
      (key: string) =>
        (result[key] === null || result[key] === undefined) &&
        delete result[key]
    );

    this.dialogRef.close(result);
  }
}
