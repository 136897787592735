import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  TupDocument,
  TupDocumentStatus,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import { Observable } from 'rxjs';
import { DocumentCampaign } from '../models/document.model';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class AutoSaveService {
  document: TupDocument<DocumentCampaign>;

  autoSaveTimer: EventEmitter<any> = new EventEmitter<any>();
  saveFrequency: number = 3000;

  private timer: ReturnType<typeof setTimeout> = null;

  private enabledSubject = new BehaviorSubject<boolean>(true);
  enabled$ = this.enabledSubject.asObservable();

  constructor(private documentService: DocumentService) {}

  findDocument(): Observable<TupDocument<DocumentCampaign>> {
    return new Observable((observable) => {
      const handleComplete = (document: TupDocument<DocumentCampaign>) => {
        observable.next(document);
        this.document = document;
        observable.complete();
      };

      this.documentService
        .searchDocuments(
          0,
          25,
          { by: 'metadata.modified', direction: 'desc' },
          [TupDocumentTypeId.CMP_CURRENT_RUN],
          [TupDocumentStatus.ACTIVE, TupDocumentStatus.ARCHIVED],
          this.documentService.myDriveContainer,
          null,
          ['metadata']
        )
        .subscribe(
          (documents) => {
            const allDocs = (documents as any)
              .hits as TupDocument<DocumentCampaign>[];

            if (allDocs.length) {
              const docToLoad = allDocs.shift();

              this.cleanupDocuments(allDocs);
              this.documentService
                .get(
                  docToLoad.metadata.id,
                  this.documentService.myDriveContainer.name
                )
                .subscribe((document: TupDocument<DocumentCampaign>) => {
                  handleComplete(document);
                });
            } else {
              handleComplete(null);
            }
          },
          (err) => {
            handleComplete(null);
          }
        );
    });
  }

  cleanupDocuments(docs: TupDocument<DocumentCampaign>[]) {
    docs.length ? console.log('Autosave document cleanup ', docs) : null;
    docs.forEach((doc) => this.documentService.deleteDocument(doc));
  }

  ngOnDestroy() {
    this.stop();
  }

  setEnabled(enabled: boolean) {
    this.enabledSubject.next(enabled);
  }

  dirty(value: boolean = true) {
    this.enabled$.subscribe((enabled) => {
      if (enabled) {
        value ? this.start() : this.stop();
      }
    });
  }

  saveNow() {
    this.stop();
    this.emit();
  }

  stop() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
  }

  start() {
    this.stop();

    console.log('autoSaveTimer.start', this.saveFrequency);
    this.timer = setTimeout(() => {
      console.log('autoSaveTimer.emit');
      this.emit();
    }, this.saveFrequency);
  }

  emit() {
    this.autoSaveTimer.emit();
  }
}
