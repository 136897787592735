import { Component, Input, OnInit } from '@angular/core';
import { TreeTableColumn } from '../tree-table.models';
import { ColumnState } from './table-group.models';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { GAEvents } from '../../../models/analytics.model';

@Component({
  selector: 'column-group',
  templateUrl: './column-group.component.html',
  styleUrls: ['./column-group.component.scss'],
})
export class ColumnGroupComponent implements OnInit {
  @Input() column: TreeTableColumn;
  @Input() columns: TreeTableColumn[];

  get columnState(): string {
    return this.column.group.hidden
      ? ColumnState[ColumnState.collapsed]
      : ColumnState[ColumnState.expanded];
  }

  constructor(private analyticsService: TupAnalyticsService) {}

  ngOnInit(): void {}

  // show / hide column as required.  Set hidden prop in all columns part of the group
  toggle() {
    this.analyticsService.e(GAEvents.column_group, {
      action: `${this.column.group.name}|${
        this.column.hidden ? 'expand' : 'collapse'
      }`,
    });

    if (this.column.group) {
      this.column.group.hidden = !this.column.group.hidden;
      const groupColumns = this.columns.filter(
        (column) => column.group?.name === this.column.group.name
      );
      groupColumns.forEach((column, columnIndex) => {
        column.hidden = this.column.group.hidden;

        // If hidden the first column will also be the last
        if (this.column.hidden && columnIndex === 0) {
          column.last = true;
        }

        // If not hidden set the first column not to be the last
        if (
          !this.column.hidden &&
          groupColumns.length > 1 &&
          columnIndex === 0
        ) {
          column.last = false;
        }
      });
    }

    if (this.column.group.hidden) {
      this.column.hidden = !this.column.group.hidden;
      this.column.collapsed = true;
    } else {
      this.column.collapsed = false;
    }
  }
}
