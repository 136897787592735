import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CONNECTED, CONNECTION_CLOSED } from '../models/optimise.model';
@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private sockets: Map<string, WebSocket> = new Map();
  private messagesReceived: Map<string, Subject<string>> = new Map();

  constructor() {}

  connect(url: string, withConnectMessage = true): void {
    if (this.sockets.has(url)) {
      console.log(`Already connected to ${url}`);
      return;
    }

    const socket = new WebSocket(url);
    const messageReceived = new Subject<string>();

    socket.onopen = () => {
      if (withConnectMessage) {
        messageReceived.next(CONNECTED);
      }
      console.log(`WebSocket connection established with ${url}`);
    };

    socket.onmessage = (event) => {
      const message = event.data;
      messageReceived.next(message);
      console.log(`Received message from ${url}:`, event.data);
    };

    socket.onclose = (event) => {
      console.log(`WebSocket connection closed with ${url}:`, event);
      messageReceived.complete();
      this.sockets.delete(url);
      this.messagesReceived.delete(url);
    };

    socket.onerror = (error) => {
      console.error(`WebSocket error with ${url}:`, error);
      messageReceived.error(error);
    };

    // Store the socket and its corresponding subject
    this.sockets.set(url, socket);
    this.messagesReceived.set(url, messageReceived);
  }

  sendMessage(url: string, message: any): void {
    const socket = this.sockets.get(url);
    if (socket) {
      console.log(`Sending message to ${url}:::`, message);
      socket.send(JSON.stringify(message));
    } else {
      console.error(`No active WebSocket connection for ${url}`);
    }
  }

  closeConnection(url: string, withDisconnectMessage = true): void {
    const socket = this.sockets.get(url);
    const messageReceived = this.messagesReceived.get(url);

    if (socket && messageReceived) {
      if (withDisconnectMessage) {
        messageReceived.next(CONNECTION_CLOSED);
      }
      messageReceived.complete();
      socket.close();
    } else {
      console.error(`No active WebSocket connection for ${url}`);
    }
  }
  getMessageReceived(url: string): Observable<string> {
    const messageReceived = this.messagesReceived.get(url);
    if (messageReceived) {
      return messageReceived.asObservable();
    } else {
      throw new Error(`No active WebSocket connection for ${url}`);
    }
  }
}
