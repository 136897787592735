import uniqid from 'uniqid';
import { DaypartDay, TargetVehicle, daysOfTheWeek } from './vehicle';
import { Result } from './result';
import {
  DaypartMetrics,
  DaypartResult,
  EMPTY_DAYPART_RESULTS,
} from './daypart-result';

export enum SpotplanAllocation {
  manual,
  naturalDelivery,
  optimisation,
}

export enum SpotplanWeekBreakdown {
  weekdays,
  weekend,
  allweek,
}

export enum SpotplanStrategy {
  GRPs = 'GRPs',
  impressions = 'impressions',
  reachPct = 'reachPct',
  reach000 = 'reach000',
  effectiveReach = 'effectiveReach',
}

export interface SpotplanNaturalDelivery {
  strategy: SpotplanStrategy;
  breakdown: SpotplanWeekBreakdown;
  goal: number;
}

export const DefaultSpotplanNaturalDelivery: SpotplanNaturalDelivery = {
  strategy: SpotplanStrategy.GRPs,
  breakdown: SpotplanWeekBreakdown.allweek,
  goal: 0,
};

export const SpotplanAllocationText: { [key: number]: string } = {
  [SpotplanAllocation.manual]: 'Manual plan',
  [SpotplanAllocation.naturalDelivery]: 'Natural delivery',
  [SpotplanAllocation.optimisation]: 'Optimisation',
};

export const SpotplanStrategyText: { [key: string]: string } = {
  [SpotplanStrategy.GRPs]: 'GRPs',
  [SpotplanStrategy.impressions]: 'Impressions',
  [SpotplanStrategy.reachPct]: 'Reach percent',
  [SpotplanStrategy.reach000]: 'Reach',
  [SpotplanStrategy.effectiveReach]: 'Effective reach',
};

export const SpotplanWeekBreakdownText: { [key: number]: string } = {
  [SpotplanWeekBreakdown.weekdays]: 'Weekdays',
  [SpotplanWeekBreakdown.weekend]: 'Weekends',
  [SpotplanWeekBreakdown.allweek]: 'Full week',
};

export interface SpotplanScheduleDaypart {
  id: string;
  mnemonic: string;
  title: string;
  startDay: DaypartDay;
  startTime: string;
  endDay: DaypartDay;
  endTime: string;
  result: DaypartResultByWeekByTarget; // results (by weeks and tagetIds) for a single daypart
}

export class DaypartResultByWeekByTarget {
  results: DaypartResult[] = [];

  constructor(week?: number, targetId?: string, metrics?: DaypartMetrics) {
    if (metrics) {
      this.addResults(week, targetId, metrics);
    }
  }

  result(week: number, targetId: string): DaypartResult {
    return this.results.find((r) => r.week === week && r.targetId === targetId);
  }

  zeroResults(dpResults: DaypartResult[]) {
    dpResults.forEach((dpResult) => dpResult.zeroResults());
  }

  deleteWeeks(weeks: number[]) {
    this.results = this.results.filter((res) => !weeks.includes(res.week));
  }

  deleteTarget(targetId: string) {
    this.results = this.results.filter((res) => res.targetId !== targetId);
  }

  addResults(
    week: number,
    targetId: string,
    metrics: DaypartMetrics
  ): DaypartResult {
    metrics.week = week;
    metrics.targetId = targetId;

    let res = this.results.find(
      (r) => r.week === week && r.targetId === targetId
    );

    if (!res) {
      this.results.push(new DaypartResult(metrics));
      res = this.results[this.results.length - 1];
    } else {
      res.addResults(metrics);
    }
    return res;
  }

  copy(): DaypartMetrics[] {
    return this.results.map((result) => result.copy());
  }
}

/**
 * SpotplanSchedule structure
 *
 *   SpotplanSchedule  holds the name, goal, strategy, vehicleId (broadcast) it represents etc
 *       weeks[]
 *           total[]  result for each week by target
 *       dayparts[]   mnemonic, id, title, start date/time etc
 *           result[]    indexed by targetId to get spots, reach, impressions, etc
 *
 */

export class SpotplanSchedule {
  id: string;
  name: string;
  vehicleId: string;
  dayparts: SpotplanScheduleDaypart[] = [];

  allocation: SpotplanAllocation = SpotplanAllocation.manual;
  naturalDelivery: SpotplanNaturalDelivery = {
    ...DefaultSpotplanNaturalDelivery,
  };

  weeks: DaypartResult[] = [];
  days: DaypartResult[] = [];
  _weekCount: number;
  get weekCount(): number {
    return this._weekCount;
  }

  set weekCount(value: number) {
    this.setWeekCount(value);
  }

  constructor(name: string) {
    this.name = name;
    this.id = uniqid();
    this.setWeekCount(1);
  }

  /**
   * Get totals from the weeks array
   *
   * @param {number} week week number
   * @param {string} targetId Id of the target results are required against
   * @returns {DaypartMetrics} Result interface or an empty interface
   */
  getWeekTotal(week: number, targetId: string): DaypartMetrics {
    return (
      this.weeks.find((w) => w.week === week && w.targetId === targetId) ||
      EMPTY_DAYPART_RESULTS
    );
  }

  getGrandTotalByDay(day: string, targetId: string): DaypartMetrics {
    const resultedDaypartTotal: DaypartMetrics = {
      inserts: 0,
      impressions: 0,
      GRPs: 0,
      unitCost: 0,
      totalCost: 0,
      reach000: 0,
      reachPct: 0,
    };

    const dayNumber = daysOfTheWeek.indexOf(day);

    const tot = this.days.find(
      (d) => d.day === dayNumber && d.week === -1 && d.targetId === targetId
    );

    if (!tot) {
      return resultedDaypartTotal;
    }

    const metrics = Object.keys(resultedDaypartTotal);
    Object.keys(tot).forEach((key) => {
      if (metrics.includes(key)) {
        resultedDaypartTotal[key] = tot[key];
      }
    });

    resultedDaypartTotal.inserts = this.calculateInserts(
      -1,
      dayNumber,
      targetId
    );

    return resultedDaypartTotal;
  }

  getWeekTotalByDay(
    week: number,
    day: string,
    targetId: string
  ): DaypartMetrics {
    const resultedDaypartTotal: DaypartMetrics = {
      inserts: 0,
      impressions: 0,
      GRPs: 0,
      unitCost: 0,
      totalCost: 0,
      reach000: 0,
      reachPct: 0,
    };

    const dayNumber = daysOfTheWeek.indexOf(day);

    const tot = this.days.find(
      (d) => d.day === dayNumber && d.week === week && d.targetId === targetId
    );

    if (!tot) {
      return resultedDaypartTotal;
    }

    const metrics = Object.keys(resultedDaypartTotal);
    Object.keys(tot).forEach((key) => {
      if (metrics.includes(key)) {
        resultedDaypartTotal[key] = tot[key];
      }
    });

    resultedDaypartTotal.inserts = this.calculateInserts(
      week,
      dayNumber,
      targetId
    );

    return resultedDaypartTotal;
  }

  getDaypartsForDay(day: string) {
    return this.dayparts.filter(
      (daypart) => DaypartDay[daypart.startDay].toLowerCase() === day
    );
  }

  /**
   * Set the weekly totals to be stored in the weeks array
   *
   * @param {number} week week number to store results against
   * @param {string} targetId Id of the target results are required against
   * @param {DaypartMetrics} results object containing results to store
   */
  setWeekTotal(week: number, targetId: string, results: DaypartMetrics): void {
    results.week = week;
    results.targetId = targetId;
    results.inserts = this.calculateInserts(week, -1, targetId);

    const tot = this.weeks.find(
      (w) => w.week === week && w.targetId === targetId
    );
    tot ? tot.addResults(results) : this.weeks.push(new DaypartResult(results));
  }

  /**
   * Set the daily totals to be stored in the days array
   *
   * @param {number} day day number to store results against
   * @param {number} week week number to store results against
   * @param {string} targetId Id of the target results are required against
   * @param {DaypartMetrics} results object containing results to store
   */
  setWeekTotalByDayTotal(
    day: number,
    week: number,
    targetId: string,
    results: DaypartMetrics
  ): void {
    results.day = day;
    results.week = week;
    results.targetId = targetId;

    const tot = this.days.find(
      (d) => d.day === day && d.week === week && d.targetId === targetId
    );

    tot ? tot.addResults(results) : this.days.push(new DaypartResult(results));
  }

  /**
   * calculate the spots directly from the daypart results
   *
   * @param {number} week week number to calculate results for. Pass week as -1 to calc all weeks
   * @param {number} day day number to calculate results for. Pass day as -1 to calc all days
   * @param {string} targetId Id of the target results are required against
   * @returns {number} total spot count
   */
  calculateInserts(week: number, day: number, targetId: string): number {
    let spots = 0;
    const [startWeek, endWeek] =
      week == -1 ? [0, this.weekCount - 1] : [week, week];

    for (let w = startWeek; w <= endWeek; w++) {
      this.dayparts.forEach((dp) => {
        if (day === -1 || dp.startDay === day) {
          const res = dp.result.result(w, targetId);
          if (res) spots += res.inserts;
        }
      });
    }
    return spots;
  }

  clearVehicle() {
    this.vehicleId = '';
    this.name = '';
    this.dayparts = [];
  }

  assignVehicle(vehicle: TargetVehicle, VehicleIdSuffix: string = '') {
    this.vehicleId = `${vehicle.id}${VehicleIdSuffix}`;
    this.dayparts = vehicle.dayparts.map((dp) => {
      return {
        id: `${dp.id}${VehicleIdSuffix}`,
        mnemonic: dp.mnemonic,
        startDay: dp.startDay,
        startTime: dp.startTime,
        endDay: dp.endDay,
        endTime: dp.endTime,
        title: dp.title,
        result: new DaypartResultByWeekByTarget(),
      };
    });
    this.clearWeeks();
  }

  clearWeeks() {
    this.weeks.forEach((week) => {
      week.zeroResults();
    });
  }

  clearDayparts() {
    this.dayparts.forEach((daypart) => {
      daypart.result.results.forEach((daypartResult) =>
        daypartResult.zeroResults()
      );
    });
  }

  clearSpotplan() {
    this.clearWeeks();
    this.clearDayparts();
  }

  // results cleanup by targetId when deleting targets
  deleteTarget(targetId: string) {
    this.dayparts.forEach((daypart) => daypart.result.deleteTarget(targetId));
    this.weeks = this.weeks.filter((week) => week.targetId !== targetId);
    this.days = this.days.filter((day) => day.targetId !== targetId);
  }

  // only keep results for weeks that fit in with the new weekCount
  private setWeekCount(value: number) {
    this._weekCount = value;

    // only keep items from the weeks array that are less than weekcount
    this.weeks = this.weeks.filter((week) => week.week < this.weekCount);

    // delete daypart results for weeks we no longer need
    const delWeeks: number[] = [];
    for (let w = value; w < this.weekCount; w++) {
      delWeeks.push(w);
    }
    delWeeks.length
      ? this.dayparts.forEach((daypart) => daypart.result.deleteWeeks(delWeeks))
      : null;
  }

  daypart(daypartId: string): SpotplanScheduleDaypart {
    return this.dayparts.find((dp) => dp.id === daypartId);
  }

  zeroResults(targetId: string, week: number) {
    this.dayparts.forEach((daypart) => {
      daypart.result.addResults(week, targetId, {
        week,
        targetId,
        inserts: 0,
        impressions: 0,
        GRPs: 0,
        reach000: 0,
        reachPct: 0,
        totalCost: 0,
      });
    });
  }

  addResults(
    daypartId: string,
    targetId: string,
    week: number,
    metrics: DaypartMetrics
  ) {
    const daypart = this.daypart(daypartId);

    metrics.week = week;
    metrics.targetId = targetId;
    daypart ? daypart.result.addResults(week, targetId, metrics) : null;
  }
}
