<div
  fxFlex="100%"
  fxLayoutAlign="end"
>
  <!-- Survey Metrics -->
  <div
    *ngIf="!!survey"
    fxFlex="1 1 100%"
    fxLayout="row"
    fxLayoutAlign="end"
  >
    <survey-metrics
      [hasSurveyTitleTooltip]="true"
      [survey]="survey"
      [basePopulation]="false"
    ></survey-metrics>
  </div>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
