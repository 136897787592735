import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, BehaviorSubject } from 'rxjs';
import { InternetConnectionDialogComponent } from '../components/dialogs/internet-connection-dialog/internet-connection-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class InternetConnectionService {
  private onlineStatus = new BehaviorSubject<boolean>(navigator.onLine);

  constructor(private snackBar: MatSnackBar) {
    window.addEventListener('online', () => {
      this.onlineStatus.next(true);
    });

    window.addEventListener('offline', () => {
      this.onlineStatus.next(false);
    });
  }

  isOnline(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }

  showOnlineDialog() {
    return this.snackBar.openFromComponent(InternetConnectionDialogComponent, {
      data: { type: 'online' },
    });
  }

  showOfflineDialog() {
    return this.snackBar.openFromComponent(InternetConnectionDialogComponent, {
      data: { type: 'offline' },
    });
  }
}
