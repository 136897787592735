<div
  *ngIf="anyGroups"
  class="container"
  style="position: 'relative'"
>
  <div class="empty-cell"></div>
  <div
    *ngFor="let column of columns"
    class="column-group-element"
    [ngStyle]="{
      'min-width': columnWidth(column) + 'px',
      width: columnWidth(column) + 'px'
    }"
    [ngClass]="{ 'existing-element': column.group && column.group.count }"
  >
    <column-group
      *ngIf="column.group && column.group.count"
      [column]="column"
      [columns]="columns"
    ></column-group>
  </div>
</div>
