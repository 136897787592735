import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

export interface OptimiseProgressSnackbarOptionsModel {
  progress: Observable<number>;
  cancelOptimisation: () => void;
}
@Component({
  selector: 'app-optimise-progress-snackbar',
  templateUrl: './optimise-progress-snackbar.component.html',
  styleUrls: ['./optimise-progress-snackbar.component.scss'],
})
export class OptimiseProgressSnackbarComponent implements OnInit {
  collapsed = false;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: OptimiseProgressSnackbarOptionsModel
  ) {}

  ngOnInit(): void {}

  canceOptimisation() {
    this.data.cancelOptimisation();
  }
}
