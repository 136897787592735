import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManageAudienceMetricsComponent } from '../manage-audience-metrics/manage-audience-metrics.component';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import {
  TreeTableColumn,
  TreeTableEditEvent,
  TreeTableNode,
} from '../../tree-table/tree-table.models';
import {
  optimeseColumns,
  Column_TotalPct,
  Column_TotalMinPct,
  Column_TotalMaxPct,
  Column_Actual,
} from 'src/app/models/optimise-columns.models';
import { TreeTableComponent } from '../../tree-table/tree-table.component';
import { MatSelectChange } from '@angular/material/select';
import { OptimiseMetrics, SPLIT_ID } from 'src/app/models/optimise.model';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';
import { Schedule } from 'src/app/classes/schedule';

export interface OptimiseDialogModel {
  selectedAudience: string;
  selectedPlan: string;
  isBudgetEnabled: boolean;
}

interface stragetyType {
  [key: string]: TemplateRef<any>;
}

interface OptimiseSchedule {
  name: string;
  value: string;
}

interface OptimiseMediaType {
  edited: boolean;
  checked: boolean;
  title: string;
  isMultiSurvey: boolean;
  data: { [columnDef: string]: number };
  cellCss: { [columnDef: string]: string };
}

@Component({
  selector: 'optimise-dialog',
  templateUrl: './optimise-dialog.component.html',
  styleUrls: ['./optimise-dialog.component.scss'],
})
export class OptimiseDialogComponent implements OnInit {
  @ViewChild('optimiseTable') optimiseTable: TreeTableComponent;
  reachValue = OptimiseMetrics.reachThenFrequency;
  selectedPlan = '';
  selectedStrategy = OptimiseMetrics.grps;
  selectedSplit = 'noSplit';
  selectedSplitType = Column_TotalPct.columnDef;
  audience = '';
  sliderValue = 1;
  strategyValue: string | number = '0';
  strategyTemplates: stragetyType = {};
  sliderNumbers = ['1', '2', '3', '4', '5', '6', '7', ' 8'];
  mediaTypes: OptimiseMediaType[] = [];
  optimiseData: TreeTableNode[];
  tableClomuns: TreeTableColumn[] = [
    optimeseColumns[
      optimeseColumns.indexOf(
        optimeseColumns.find((val) => val.columnDef === this.selectedSplitType)
      )
    ],
  ];
  schedules: OptimiseSchedule[] = [];
  get unselectedError() {
    return {
      type: 'error',
      message: `The total amount of the filled values needs to be ${
        this.selectedSplitType === Column_Actual.columnDef
          ? `${this.strategyValue}`
          : '100%'
      }`,
      icon: StatusSnackbarIcon.Error,
    };
  }
  emptyStrategyValue: SnackbarGenericOptionModel = {
    type: 'error',
    message: 'Please fill first the Strategy value',
    icon: StatusSnackbarIcon.Error,
  };
  maxValueError: SnackbarGenericOptionModel = {
    type: 'error',
    message: 'The total amount of the filled values needs to be maximum 100%.',
    icon: StatusSnackbarIcon.Error,
  };
  mediaTypeSelected: SnackbarGenericOptionModel = {
    type: 'error',
    message: 'Please select at least two media types to optimise',
    icon: StatusSnackbarIcon.Error,
  };
  snackbarError = this.unselectedError;
  strategies = [
    { name: 'GRPs', value: OptimiseMetrics.grps, disabled: false },
    {
      name: 'Impressions',
      value: OptimiseMetrics.impressions,
      disabled: false,
    },
    {
      name: 'Reach (%)',
      value: OptimiseMetrics.reachThenFrequency,
      disabled: false,
    },
    {
      name: 'Average frequency',
      value: OptimiseMetrics.avgFrequency,
      disabled: false,
    },
    {
      name: 'Budget',
      value: OptimiseMetrics.budget,
      disabled: true,
      tooltip: 'Cost has not been applied to table',
    },
  ];
  strategyValueLimits = {
    [OptimiseMetrics.grps]: null,
    [OptimiseMetrics.impressions]: null,
    [OptimiseMetrics.reachThenFrequency]: 100,
    [OptimiseMetrics.avgFrequency]: null,
    [OptimiseMetrics.budget]: null,
  };

  splitValues = [
    { name: 'No Split', value: 'noSplit' },
    { name: 'Impressions', value: 'splitImpressions' },
    { name: 'Budget', value: 'splitBudget' },
    { name: 'GRPs', value: 'splitGRPs' },
  ];

  splitTypeValues = optimeseColumns.map((val) => ({
    name: val.header,
    value: val.columnDef,
  }));

  get displayedStrategy() {
    return this.selectedStrategy.length > 0
      ? this.strategies.find((val) => val.value === this.selectedStrategy).name
      : '';
  }

  get selectedSplitName() {
    return this.splitValues.find((val) => val.value === this.selectedSplit)
      .name;
  }
  get selectedSplitTypeName() {
    return this.splitTypeValues.find(
      (val) => val.value === this.selectedSplitType
    ).name;
  }

  get selectedMediaTypes() {
    return this.mediaTypes.filter((type) => type.checked);
  }

  get splitValuesFilled() {
    if (this.selectedSplit !== 'noSplit') {
      const selectedeMediaTypesData = this.mediaTypes
        .filter((val) => val.checked)
        .map((val) => val.data);
      let foundedColumnDef: string = null;
      let filledItems: number = 0;
      selectedeMediaTypesData.forEach((values) => {
        const foundedKey = Object.keys(values).find((key) => values[key] !== 0);
        if (foundedKey) {
          filledItems++;
          foundedColumnDef = foundedKey;
        }
      });
      if (filledItems === selectedeMediaTypesData.length) {
        let total = 0;
        selectedeMediaTypesData.forEach((values) => {
          total += values[foundedColumnDef];
        });
        if (
          total === 100 &&
          this.selectedSplitType === Column_TotalPct.columnDef
        ) {
          return true;
        }
        if (
          total === this.parseStrategyValue(`${this.strategyValue}`) &&
          this.selectedSplitType === Column_Actual.columnDef
        ) {
          return true;
        }
        if (
          total <= 100 &&
          this.selectedSplitType === Column_TotalMinPct.columnDef
        ) {
          return true;
        }
        if (this.selectedSplitType === Column_TotalMaxPct.columnDef) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  get splitValueFilledCorrectly() {
    let columnDef: string;
    if (
      this.mediaTypes.filter((media) => media.checked).length < 2 &&
      this.selectedSplit !== 'noSplit'
    ) {
      this.snackbarError = this.mediaTypeSelected;
      return false;
    }
    this.mediaTypes
      .filter((val) => val.checked)
      .forEach((val) => {
        columnDef = Object.keys(val.data).find((key) => val.data[key] !== 0);
      });
    const selectedMediaTypes = this.mediaTypes.filter((val) => val.checked);
    if (
      columnDef &&
      (columnDef === Column_TotalPct.columnDef ||
        columnDef === Column_Actual.columnDef) &&
      selectedMediaTypes.length ===
        selectedMediaTypes.filter((val) => val.data[columnDef] !== 0).length
    ) {
      this.snackbarError = this.unselectedError;

      if (
        columnDef === Column_Actual.columnDef &&
        this.parseStrategyValue(`${this.strategyValue}`) <= 0
      ) {
        this.snackbarError = this.emptyStrategyValue;
        return false;
      }

      let total = 0;
      this.mediaTypes
        .filter((val) => val.checked)
        .forEach((val) => {
          total += val.data[columnDef];
        });
      let totalMax = 0;
      if (columnDef === Column_Actual.columnDef) {
        totalMax = this.parseStrategyValue(`${this.strategyValue}`);
      } else {
        totalMax = 100;
      }
      if (total === totalMax) {
        return true;
      }
      return false;
    }
    if (columnDef && columnDef === Column_TotalMinPct.columnDef) {
      this.snackbarError = this.maxValueError;

      let total = 0;
      this.mediaTypes
        .filter((val) => val.checked)
        .forEach((val) => {
          total += val.data[columnDef];
        });
      if (total <= 100) {
        return true;
      }
      return false;
    }
    return true;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OptimiseDialogModel,
    private dialogRef: MatDialogRef<ManageAudienceMetricsComponent>,
    private mediaplannerService: MediaPlannerService
  ) {}

  ngOnInit(): void {
    const unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
      this.mediaplannerService.plan.primarySurvey.code
    ).reportUnitText;
    this.audience = this.data.selectedAudience;
    this.strategies.find(
      (val) => val.value === OptimiseMetrics.impressions
    ).name = `Impressions ${unitsText}`;
    this.schedules = this.mediaplannerService.plan.schedules.map((val) => ({
      name: val.name,
      value: val.name,
    }));
    this.selectedPlan = this.data.selectedPlan;
    this.strategies.find(
      (val) => val.value === OptimiseMetrics.budget
    ).disabled = !this.data.isBudgetEnabled;
    this.loadMediaTypes();
    this.loadTableData();
  }

  loadMediaTypes() {
    const plan = this.mediaplannerService.plan;
    const mediaTypes = [];
    plan.targets[0].vehicles.forEach((vehicle) => {
      if (!mediaTypes.find((media) => media.title == vehicle.mediaType)) {
        mediaTypes.push({
          edited: false,
          checked: true,
          title: vehicle.mediaType,
          isMultiSurvey: vehicle.isMultiSurvey,
          data: {
            [Column_TotalPct.columnDef]: 0,
            [Column_TotalMinPct.columnDef]: 0,
            [Column_TotalMaxPct.columnDef]: 0,
            [Column_Actual.columnDef]: 0,
          },
        });
      }
    });
    this.mediaTypes = mediaTypes;
  }

  loadTableData() {
    const tableData = [];
    this.mediaTypes.forEach((val) => {
      const checkedMediaTypes = this.mediaTypes.filter(
        (val) => val.checked
      ).length;

      const disabled = val.isMultiSurvey || this.isMediaTypeDisabled(val.title);
      if (disabled) val.checked = false;

      tableData.push({
        name: val.title,
        rowCss: `media-row ${
          (checkedMediaTypes < 2 && this.selectedSplit !== 'noSplit') ||
          this.selectedSplit === 'noSplit' ||
          !val.checked
            ? 'cell-edit-disable'
            : ''
        }`,
        cellCss: val.cellCss,
        editable: true,
        tooltip: disabled
          ? {
              name: val.isMultiSurvey
                ? 'Not available for optimisation'
                : 'You cannot apply a budget strategy to this media type as complete costs are not available',
            }
          : null,
        id: val.title,
        data: val.data,
        checkbox: true,
        disabled,
        checked: val.checked,
      });
    });
    this.optimiseData = tableData;
  }

  isMediaTypeDisabled(mediaType: string): boolean {
    if (this.selectedStrategy !== OptimiseMetrics.budget) return false;

    // get the schedule we're working with (or fallback to first one)
    const schedule: Schedule =
      this.mediaplannerService.plan.schedules.find(
        (sch) => sch.name === this.selectedPlan
      ) || this.mediaplannerService.plan.schedules[0];
    const target = this.mediaplannerService.plan.targets[0]; // target not currently selectable

    // all vehicles within this mediatype
    const allVehicles = target.vehicles
      .filter((veh) => veh.mediaType === mediaType)
      .map((v) => v.id);
    const vehiclesWithCosts = schedule.vehicles.filter(
      (veh) =>
        allVehicles.includes(veh.vehicleId) &&
        veh.targetId === target.id &&
        veh.result.cpm > 0
    );

    return !(vehiclesWithCosts.length === allVehicles.length);
  }

  onClose() {
    this.dialogRef.close(false);
  }

  transformActualValueToPercentage(
    val: number,
    isFirstValue: boolean,
    mediaTypeIndex: number
  ) {
    if (!isFirstValue) {
      return Math.floor(
        (val / this.parseStrategyValue(`${this.strategyValue}`)) * 100
      );
    }
    let totalValuesAmmount = 0;
    this.mediaTypes
      .filter((val, index) => val.checked && index !== mediaTypeIndex)
      .forEach((val) => {
        totalValuesAmmount += Math.floor(
          (val.data[Column_Actual.columnDef] /
            this.parseStrategyValue(`${this.strategyValue}`)) *
            100
        );
      });
    return 100 - totalValuesAmmount;
  }

  save() {
    let value: number | Object = this.parseStrategyValue(
      this.strategyValue as string
    );
    if (this.selectedStrategy === OptimiseMetrics.reachThenFrequency) {
      value = {
        [OptimiseMetrics.reach]: this.parseStrategyValue(
          this.strategyValue as string
        ),
        [OptimiseMetrics.avgFrequency]: this.sliderValue,
      };
    }
    let constraints = undefined;
    if (this.selectedSplit !== 'noSplit') {
      constraints = [
        {
          splitId: SPLIT_ID,
          splitMode:
            this.selectedSplit === 'splitGRPs'
              ? 'splitImpressions'
              : this.selectedSplit,
          splitPercentages: this.mediaTypes
            .filter((val) => val.checked)
            .map((val, index) => {
              const { data, title } = val;
              const filledKey = Object.keys(data).find(
                (index) => data[index] !== 0
              );
              return {
                value: title,
                percentage:
                  this.selectedSplitType === Column_Actual.columnDef
                    ? this.transformActualValueToPercentage(
                        data[filledKey],
                        this.selectedMediaTypes[0].title === val.title,
                        index
                      )
                    : data[filledKey],
              };
            }),
        },
      ];
    }
    this.dialogRef.close({
      plan: this.selectedPlan,
      goal: this.selectedStrategy,
      value,
      mediaTypes: this.selectedMediaTypes,
      constraints,
    });
  }

  onEdited(events: TreeTableEditEvent[]) {
    events.forEach((event) => {
      const thousand: string = Intl
        ? new Intl.NumberFormat().format(1000).charAt(1)
        : ',';
      const value: number = parseFloat('' + event.value.replace(thousand, ''));
      const editedMediaType = this.mediaTypes.find(
        (val) => val.title === event.row.name
      );
      const editedMediatypeIndex = this.mediaTypes.indexOf(editedMediaType);
      let editedValue = value;
      if (value) {
        if (this.selectedSplitType === Column_Actual.columnDef) {
          if (value > this.parseStrategyValue(`${this.strategyValue}`)) {
            editedValue = this.parseStrategyValue(`${this.strategyValue}`);
          } else {
            editedValue = parseFloat(value.toFixed(2));
          }
        } else if (value > 100) {
          editedValue = 100;
        } else {
          editedValue = parseFloat(value.toFixed(2));
        }
      } else {
        editedValue = 0;
      }
      this.mediaTypes[editedMediatypeIndex].data[event.columnDef] = editedValue;
      this.mediaTypes[editedMediatypeIndex].edited = true;
      if (editedValue !== 0) {
        const cellCss = {};
        optimeseColumns
          .map((val) => val.columnDef)
          .filter((val) => val !== event.columnDef)
          .forEach((val) => {
            cellCss[val] = 'cell-edit-disable';
          });
        this.mediaTypes.forEach((val) => {
          val.cellCss = cellCss;
        });
      } else {
        let allValuesAreEmpty = true;
        this.mediaTypes
          .filter((val) => val.checked)
          .forEach((val) => {
            if (val.data[event.columnDef] !== 0) {
              allValuesAreEmpty = false;
            }
          });

        if (allValuesAreEmpty) {
          this.mediaTypes.forEach((val) => {
            val.cellCss = {};
          });
        }
      }
      if (
        this.selectedSplitType === Column_TotalPct.columnDef ||
        this.selectedSplitType === Column_Actual.columnDef
      ) {
        this.mediaTypes.forEach((val) => {
          Object.assign(
            val,
            this.getMediaUpdatedValues(val.title, val.checked, val.edited)
          );
        });
      }
    });
    this.updateTableValues();
  }

  updateTableValues() {
    this.loadTableData();
    this.optimiseTable
      ? this.optimiseTable.refresh.emit({ newData: this.optimiseData })
      : undefined;
  }

  onSelectedNodes(selectedNodes: TreeTableNode[]) {
    this.mediaTypes.forEach((media) => {
      const checked = !!selectedNodes.find((node) => node.name === media.title);
      media.checked = checked;
      if (!checked) {
        media.edited = false;
      }
    });
    this.mediaTypes.forEach((media) => {
      if (!selectedNodes.find((node) => node.name === media.title)) {
        media.data = {
          [Column_TotalPct.columnDef]: 0,
          [Column_TotalMinPct.columnDef]: 0,
          [Column_TotalMaxPct.columnDef]: 0,
          [Column_Actual.columnDef]: 0,
        };
      } else {
        Object.assign(
          media,
          this.getMediaUpdatedValues(media.title, media.checked, media.edited)
        );
      }
    });
    this.updateTableValues();
  }

  onSelectedSplitChange(event: MatSelectChange) {
    this.tableClomuns = [
      optimeseColumns[
        optimeseColumns.indexOf(
          optimeseColumns.find(
            (val) => val.columnDef === this.selectedSplitType
          )
        )
      ],
    ];
    if (
      this.tableClomuns.find((val) => val.columnDef === event.value) ||
      event.value === 'noSplit'
    ) {
      this.mediaTypes.forEach((val) => {
        Object.assign(val, { edited: false });
      });
    }

    this.mediaTypes.forEach((val) =>
      Object.assign(
        val,
        this.getMediaUpdatedValues(val.title, val.checked, val.edited)
      )
    );
    this.updateTableValues();
  }

  getMediaUpdatedValues(title: string, checked: boolean, edited = false) {
    let mediaData = {
      [Column_TotalPct.columnDef]: 0,
      [Column_TotalMinPct.columnDef]: 0,
      [Column_TotalMaxPct.columnDef]: 0,
      [Column_Actual.columnDef]: 0,
    };
    let firstTotalPct = 0;

    if (
      this.selectedSplit !== 'noSplit' &&
      (this.selectedSplitType === Column_TotalPct.columnDef ||
        this.selectedSplitType === Column_Actual.columnDef) &&
      this.selectedMediaTypes.length > 1 &&
      checked
    ) {
      let maxSplitValue;
      if (this.selectedSplitType === Column_TotalPct.columnDef) {
        maxSplitValue = 100;
      }
      if (this.selectedSplitType === Column_Actual.columnDef) {
        maxSplitValue = this.parseStrategyValue(`${this.strategyValue}`);
      }
      let splitValueToRemove = 0;
      let editedItems = 0;
      this.mediaTypes.forEach((val) => {
        if (val.edited) {
          editedItems += 1;
          switch (this.selectedSplitType) {
            case Column_TotalPct.columnDef:
              splitValueToRemove += val.data[Column_TotalPct.columnDef];
              break;
            case Column_Actual.columnDef:
              splitValueToRemove += val.data[Column_Actual.columnDef];
              break;
          }
        }
      });
      maxSplitValue -= splitValueToRemove;
      const totalPctValue = Math.max(
        1,
        Math.floor(
          maxSplitValue / (this.selectedMediaTypes.length - editedItems)
        )
      );
      switch (this.selectedSplitType) {
        case Column_TotalPct.columnDef:
          mediaData[Column_TotalPct.columnDef] = totalPctValue;
          break;
        case Column_Actual.columnDef:
          mediaData[Column_Actual.columnDef] = totalPctValue;
          break;
      }
      if (
        totalPctValue * (this.selectedMediaTypes.length - editedItems) <
        maxSplitValue
      ) {
        firstTotalPct = Math.max(
          1,
          maxSplitValue -
            Math.floor(
              totalPctValue * (this.selectedMediaTypes.length - editedItems - 1)
            )
        );
      } else {
        firstTotalPct = totalPctValue;
      }
    }
    return {
      cellCss: {},
      edited: edited,
      ...(!edited && {
        data: {
          ...mediaData,
          ...(this.selectedSplitType === Column_TotalPct.columnDef && {
            [Column_TotalPct.columnDef]:
              this.selectedMediaTypes[0].title === title
                ? firstTotalPct
                : mediaData[Column_TotalPct.columnDef],
          }),
          ...(this.selectedSplitType === Column_Actual.columnDef && {
            [Column_Actual.columnDef]:
              this.selectedMediaTypes[0].title === title
                ? firstTotalPct
                : mediaData[Column_Actual.columnDef],
          }),
        },
      }),
    };
  }

  onSelectedStrategyChange() {
    this.strategyValue = '0';
    this.loadTableData();
    this.updateTableValues();
  }

  parseStrategyValue(initialValue: string) {
    const value = initialValue.replace('%', '');
    const thousand: string = Intl
      ? new Intl.NumberFormat().format(1000).charAt(1)
      : ',';
    return parseFloat('' + value.replace(thousand, ''));
  }
  onStrategyValueChange(event: any) {
    let parsedValue: number = this.parseStrategyValue(event.target.value);
    const strategyLimit = this.strategyValueLimits[this.selectedStrategy];
    if (parsedValue) {
      if (strategyLimit && parsedValue > strategyLimit) {
        this.strategyValue =
          this.selectedStrategy === OptimiseMetrics.reachThenFrequency
            ? `${strategyLimit.toFixed(
                parsedValue.toString().split('.')[1] &&
                  parsedValue.toString().split('.')[1].length
                  ? 2
                  : 0
              )}%`
            : `${strategyLimit}`;
      } else {
        this.strategyValue =
          this.selectedStrategy === OptimiseMetrics.reachThenFrequency
            ? `${parsedValue.toFixed(
                parsedValue.toString().split('.')[1] &&
                  parsedValue.toString().split('.')[1].length
                  ? 2
                  : 0
              )}%`
            : parsedValue
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      this.strategyValue = 0;
    }
  }
}
