import { Result } from './result';

export enum ScheduleTotalTag {
  total,
  mediatype,
  vehicleGroup,
  vehicle,
  multiSurvey,
}

export const tagToString = (tag: ScheduleTotalTag): string => {
  return ScheduleTotalTag[tag];
};

export const stringToTag = (str: string): ScheduleTotalTag => {
  return ScheduleTotalTag[str];
};

// storing result objects, using group and tag fields to describe and categorise what is stored
export interface ScheduleTotal {
  group: string;
  tag: ScheduleTotalTag;
  targetId: string;
  result: Result;
}
