import {
  OnInit,
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentEventHandlerDirective,
  TupDocumentStatus,
  TupDocumentStorageComponent,
  TupDocumentTypeId,
  TupDocumentTypes,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';
import { DialogService } from '../../services/dialog.service';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import {
  LoadDocumentResult,
  MediaPlannerService,
} from 'src/app/services/media-planner.service';
import { DocumentService } from 'src/app/services/document.service';
import {
  CampaignTableColumns,
  CampaignTags,
  PlanningTemplateTableColumns,
} from 'src/app/models/doc-storage.models';
import {
  APPLICATION_NAME,
  DocumentCampaign,
  DocumentOrigin,
  UNSAVED_DOCUMENT,
} from 'src/app/models/document.model';
import { AutoSaveService } from 'src/app/services/auto-save.service';
import {
  TupSaveDocumentDialogResult,
  TupUserMessageService,
} from '@telmar-global/tup-user-message';
import { isNotNullOrUndefined } from 'src/app/pipes/pipeable-operators';
import { StartPlanningDialogModel } from 'src/app/components/dialogs/start-planning-dialog/start-planning-dialog.component';
import { ConverterService } from 'src/app/services/converter.service';
import {
  SaveDocumentDialogData,
  SaveDocumentDialogOptions,
  getSaveDocumentDialogData,
} from 'src/app/components/dialogs/save-document-dialog/save-document-dialog.component';
import { DocumentDialogService } from 'src/app/services/document-dialog.service';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import { IchabodsTabsComponent } from '@telmar-global/tup-material';
import {
  QuestionDialogModelButtonOptions,
  QuestionDialogModelOptions,
} from 'src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { StatusSnackbarIcon } from 'src/app/components/dialogs/snackbar-generic/snackbar-generic.component';
import { DashboardLatestCampaignsComponent } from 'src/app/components/dashboard-latest-campaigns/dashboard-latest-campaigns.component';
import { GAEvents } from '../../models/analytics.model';
import { Observable, of } from 'rxjs';
import { CampaignPreparationResponse } from 'src/app/models/planning.models';
import { MigrationService } from 'src/app/services/migration.service';
import { UploadFile } from '../../models/file-upload-model';
import {
  audienceActionType,
  dialogActionType,
  MixedDataDialogModel,
} from '../../components/dialogs/import-mixed-data-dialog/import-mixed-data-dialog.component';
import { MultiSurveyService } from '../../services/multi-survey.service';

@Component({
  selector: 'documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TupDocumentEventEmitterDirective, { static: false })
  public documentEventEmitter: TupDocumentEventEmitterDirective;
  @ViewChild(TupDocumentEventHandlerDirective, { static: false })
  public documentEventHandler: TupDocumentEventHandlerDirective;
  public userName: string;
  public userInitials: string;
  public selectedIndex: number = 0;
  public quickLinks;
  public navigationTabs: string[] = [
    'Dashboard',
    'My campaigns',
    'My templates',
  ];

  public initialising: boolean = false;
  @ViewChild('ichabodsTab', { static: false })
  ichabodsTab: IchabodsTabsComponent;

  @ViewChild('myLatestCampaigns')
  public latestCampaigns: DashboardLatestCampaignsComponent;

  @ViewChild('templateTable')
  public templateTable: TupDocumentStorageComponent;

  @ViewChild('campaignTable')
  public campaignTable: TupDocumentStorageComponent;

  // doc storage options for the campaign table
  campaignTableOptions = {
    types: [TupDocumentTypeId.CMP_CAMPAIGN],
    statuses: [TupDocumentStatus.ACTIVE, TupDocumentStatus.ARCHIVED],
    _source: ['metadata', 'content.header'],
    columns: CampaignTableColumns, // ( V1 MPF(X) files )
    documentReplacementText: 'campaign',
    tags: CampaignTags,
    actions: [
      'click:edit:edit',
      'click:duplicate:file_copy',
      'click:delete:delete_outline',
      'update_tags',
    ],
  };

  // doc storage options for the template table
  templateTableOptions = {
    types: [TupDocumentTypeId.CMP_CAMPAIGN_TEMPLATE],
    statuses: [TupDocumentStatus.ACTIVE, TupDocumentStatus.ARCHIVED],
    _source: ['metadata', 'content.header'],
    columns: PlanningTemplateTableColumns, // ( V1 DEU files )
    documentReplacementText: 'template',
    tags: CampaignTags,
    actions: [
      'click:Start campaign:add_circle_outline',
      'click:delete:delete_outline',
      'update_tags',
    ],
  };

  applicationName: string = APPLICATION_NAME;
  steps = ['audience', 'media', 'planning'];

  get documentId(): string {
    return this.mediaplannerService.plan.documentId;
  }

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private documentDialogService: DocumentDialogService,
    private documentService: DocumentService,
    private mediaplannerService: MediaPlannerService,
    private userMessage: TupUserMessageService,
    private userContainerService: TupUserContainerService,
    private migrationService: MigrationService,
    private autoSaveService: AutoSaveService,
    private analyticsService: TupAnalyticsService,
    private authService: TupAuthService,
    private cdr: ChangeDetectorRef,
    private multiSurveyService: MultiSurveyService
  ) {}

  ngOnInit() {
    this.initialising = false;
    this.setUserInfo();
    this.searchAutoSaveCampaign();
    this.analyticsService.sendPageview(this.navigationTabs[this.selectedIndex]);
  }

  ngAfterViewChecked() {
    if (this.campaignTable?.dataSource?.length) {
      this.campaignTableOptions.columns.forEach((val) => {
        if (val.columnDef === 'metadata.name.keyword') {
          val.header =
            val.header.split('(')[0] +
            `(${this.campaignTable?.dataSource?.length || 0})`;
          this.cdr.detectChanges();
        }
      });
    }
    if (this.templateTable?.dataSource?.length) {
      this.templateTableOptions.columns.forEach((val) => {
        if (val.columnDef === 'metadata.name.keyword') {
          val.header =
            val.header.split('(')[0] +
            `(${this.templateTable?.dataSource?.length || 0})`;
          this.cdr.detectChanges();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    //this.engineService.warmUpEngine();
    this.documentEventEmitter.click.forEach(
      (documentEvent: TupDocumentEvent) => {
        const document = {
          ...documentEvent.document,
        } as TupDocument<DocumentCampaign>;

        let action = documentEvent.data
          ? documentEvent.data.action
          : documentEvent.eventType;

        // LOAD A DOCUMENT OR TEMPLATE READY FOR EDITING
        if (['edit', 'click'].includes(action)) {
          if (
            document.metadata.type.id ===
            TupDocumentTypeId.CMP_CAMPAIGN_TEMPLATE
          ) {
            action = 'Start campaign';
          } else {
            this.initialising = true;
            this.documentService
              .get(document.metadata.id)
              .subscribe((document: TupDocument<DocumentCampaign>) => {
                this.mediaplannerService
                  .loadDocument(document, DocumentOrigin.normal)
                  .subscribe((loaded: LoadDocumentResult) => {
                    this.mediaplannerService
                      .setRecentSurveysList([
                        ConverterService.getSurvey(document.content.survey),
                      ])
                      .subscribe();

                    this.initialising = false;
                    if (loaded.success) {
                      // route to the edit page onto the correct tab (saved before media were added, for example.)
                      this.router.navigate(
                        [
                          `/edit/${this.mediaplannerService.plan.documentId}/data`,
                        ],
                        {
                          queryParams: {
                            tab: this.steps.indexOf(
                              this.mediaplannerService.plan.getCampaignStage()
                            ),
                          },
                        }
                      );
                    }
                  });
              });
          }
        } // CAMPAIGN LOAD

        // CREATE A CAMPAIGN FROM A TEMPLATE
        if (action === 'Start campaign') {
          this.initialising = true;
          this.mediaplannerService.plan.documentId = UNSAVED_DOCUMENT;

          this.documentService
            .get(document.metadata.id)
            .subscribe((document: TupDocument<DocumentCampaign>) => {
              this.mediaplannerService
                .loadDocument(document, DocumentOrigin.template)
                .subscribe((loaded: LoadDocumentResult) => {
                  this.initialising = false;
                  if (loaded.success) {
                    this.createNewCampaign(document);
                  }
                });
            });
        }

        // DUPLICATE A CAMPAIGN
        if (action === 'duplicate') {
          const reportToDuplicate = document;
          const dialogOptions: SaveDocumentDialogOptions = {
            enableSaveOnInit: true,
            autoFocus: { formControl: 'Name', delay: 251 },
            nameFormControlValue: reportToDuplicate.metadata.name,
            showDrives: false,
            descriptionFormControlValue: reportToDuplicate.metadata.description,
          };

          const dialogData: SaveDocumentDialogData = getSaveDocumentDialogData(
            'Duplicate',
            'Document',
            dialogOptions
          );

          this.documentDialogService
            .openSaveDocumentDialog(dialogData)
            .pipe(isNotNullOrUndefined())
            .subscribe((dialogResult: TupSaveDocumentDialogResult) => {
              this.documentService
                .get(reportToDuplicate.metadata.id)
                .subscribe((document: TupDocument<DocumentCampaign>) => {
                  document.metadata.name = dialogResult.name;
                  document.metadata.description = dialogResult.description;
                  document.content.header.description =
                    dialogResult.description;

                  this.documentService
                    .createDocument(document)
                    .subscribe(() => {
                      this.documentEventHandler.reload.emit(true);
                    });
                });
            });
        }

        // DELETE A DOCUMENT OR TEMPLATE (AFTER A CONFIRMATION)
        if (action === 'delete') {
          const docType =
            document.metadata.type.id === TupDocumentTypeId.CMP_CAMPAIGN
              ? 'plan'
              : 'template';
          this.analyticsService.e(GAEvents.documents, {
            action: 'documents_considering_to_delete_document',
          });
          const confirmationMessage = `Are you sure you want to delete the ${docType} '${document.metadata.name}'?`;
          const options: QuestionDialogModelOptions =
            this.dialogService.getDeleteConfirmationOptions(
              confirmationMessage
            );

          this.dialogService
            .confirmation('', 'Confirm delete', options)
            .afterClosed()
            .subscribe((button) => {
              if (button.data === 'delete') {
                this.documentService.deleteDocument(document, false, () =>
                  this.documentEventHandler.reload.emit(true)
                );
                this.analyticsService.e(GAEvents.documents, {
                  action: 'documents_insight_deleted',
                });
              }
            });
        }
      }
    );

    this.createQuickLinks();
  }
  createQuickLinks() {
    this.latestCampaigns.myLatestCampaigns.dataSource.documents.subscribe(
      (documents) => {
        this.quickLinks = documents;
      }
    );
  }

  openExistingCampaign(campaign: any) {
    this.initialising = true;
    this.documentService
      .get(campaign.metadata.id)
      .subscribe((document: TupDocument<DocumentCampaign>) => {
        this.mediaplannerService
          .loadDocument(document, DocumentOrigin.normal)
          .subscribe((loaded: LoadDocumentResult) => {
            this.mediaplannerService
              .setRecentSurveysList([
                ConverterService.getSurvey(document.content.survey),
              ])
              .subscribe();

            this.initialising = false;
            if (loaded.success) {
              this.router.navigate(
                [`/edit/${this.mediaplannerService.plan.documentId}`],
                {
                  queryParams: {
                    tab: this.steps.indexOf(
                      this.mediaplannerService.plan.getCampaignStage()
                    ),
                  },
                }
              );
            }
          });
      });
  }

  setUserInfo() {
    const user = this.authService.user;
    const username = user.attributes.name.split(' ')[0];
    const initials =
      user.attributes.given_name.slice(0, 1) +
      user.attributes.family_name.slice(0, 1);
    this.userName = username;
    this.userInitials = initials;
  }

  // Load the auto saved doc as the main campaign doc
  onLoadAutoSavedDocument() {
    this.autoSaveService.findDocument().subscribe((autosavedDocument) => {
      this.mediaplannerService
        .loadDocument(autosavedDocument, DocumentOrigin.autosaved)
        .subscribe((loaded: LoadDocumentResult) => {
          if (loaded.success) {
            this.autoSaveService.setEnabled(true);
            // route to the edit page onto the correct tab (saved before media were added, for example.)
            this.router.navigate(
              [`/edit/${this.mediaplannerService.plan.documentId}/data`],
              {
                queryParams: {
                  tab: this.steps.indexOf(
                    this.mediaplannerService.plan.getCampaignStage()
                  ),
                },
              }
            );
          }
        });
    });
  }

  // file selected by upload-file component, whole file passed as string
  onFileUploaded(file: UploadFile) {
    this.initialising = true;
    this.migrationService
      .parseCampaignObject(file.fileContent)
      .subscribe((loadResult: LoadDocumentResult) => {
        this.initialising = false;

        // report any warnings or error mesages
        this.reportImportDocumentMessages(loadResult).subscribe(() => {
          // quit if there were errors
          if (!loadResult.success) return;

          // load the campaign
          this.initialising = true;
          this.mediaplannerService.plan.documentId = UNSAVED_DOCUMENT;
          this.mediaplannerService
            .loadDocument(
              loadResult.campaignFile as TupDocument<DocumentCampaign>,
              DocumentOrigin.upload
            )
            .subscribe((result: LoadDocumentResult) => {
              this.initialising = false;
              if (!result.success) {
                const options: QuestionDialogModelOptions = {
                  buttons: [{ caption: 'Ok', data: 'ok', flat: true }],
                  closeButton: { caption: 'Ok', data: 'ok' },
                  buttonAlignment: 'end',
                  snackbar: {
                    type: 'error',
                    message: 'Your campaign could not be imported',
                    align: 'center',
                    icon: StatusSnackbarIcon.Error,
                  },
                };

                this.dialogService
                  .confirmation(undefined, 'Import campaign', options)
                  .afterClosed();
                return;
              }
              // load success, autosave and add to recents
              if (result.success) {
                this.mediaplannerService.updateAutoSaveCampaign();
                this.mediaplannerService
                  .setRecentSurveysList([
                    ConverterService.getSurvey(
                      loadResult.campaignFile.content.survey
                    ),
                  ])
                  .subscribe();

                // prompt for save
                this.mediaplannerService
                  .saveCampaignWithPrompts(true, true, true)
                  .subscribe((wasSaved: boolean) => {
                    // route to the edit page onto the correct tab (saved before media were added, for example.)
                    const nav: string[] = wasSaved
                      ? [
                          `/edit/${this.mediaplannerService.plan.documentId}/data`,
                        ]
                      : [`/new/data`];
                    this.router.navigate(nav, {
                      queryParams: {
                        tab: this.steps.indexOf(
                          this.mediaplannerService.plan.getCampaignStage()
                        ),
                      },
                    });
                  });
              }
            });
        });
      });
  }

  reportImportDocumentMessages(
    result: LoadDocumentResult
  ): Observable<QuestionDialogModelButtonOptions> {
    if (!result.errors?.length && !result.warnings?.length) return of(null);
    else {
      const success: number = +result.success;
      const header: { [key: number]: any } = {
        1: {
          type: 'warning',
          banner: 'Campaign imported successfully, but with warnings.',
          icon: StatusSnackbarIcon.Warning,
        },
        0: {
          type: 'error',
          banner: 'Your campaign could not be imported.',
          icon: StatusSnackbarIcon.Error,
        },
      };

      const options: QuestionDialogModelOptions = {
        buttons: [{ caption: 'Ok', data: 'ok', flat: true }],
        closeButton: { caption: 'Ok', data: 'ok' },
        buttonAlignment: 'end',
        snackbar: {
          type: header[success].type,
          message: header[success].banner,
          align: 'center',
          icon: header[success].icon,
        },
      };

      const errors = result.errors || [];
      const warnings = result.warnings || [];
      const messagesCount = errors.length + warnings.length;
      const messages: string[] = [
        ...errors.map((s) => `${messagesCount > 1 ? '&bull; ' : ''}${s}`),
        ...warnings.map((s) => `${messagesCount > 1 ? '&bull; ' : ''}${s}`),
      ];

      return this.dialogService
        .confirmation(messages, 'Import campaign', options)
        .afterClosed();
    }
  }

  ngOnDestroy(): void {
    //  this.unsubscribe.next();
    // this.unsubscribe.complete();
  }

  // find any documents with the CMP_CURRENT_RUN type and save a copy in the autosave service
  searchAutoSaveCampaign() {
    this.autoSaveService.findDocument().subscribe();
  }

  private buildRouteToDocumentOnCampaignCreate(
    fromTemplate,
    createCampaignDialogData: StartPlanningDialogModel,
    save: boolean,
    mixedDataDialogData?: MixedDataDialogModel
  ) {
    // update the newly created campaign with the template, if required
    const initialiseFromTemplate = (
      template: TupDocument<DocumentCampaign>,
      model: StartPlanningDialogModel,
      save: boolean
    ): Observable<LoadDocumentResult> => {
      return new Observable((ob) => {
        template.metadata.container = model.container;
        template.metadata.id = this.mediaplannerService.plan.documentId;
        template.metadata.type =
          TupDocumentTypes[TupDocumentTypeId.CMP_CAMPAIGN];
        template.metadata.name = model.title;
        template.metadata.description = model.description;

        const saveCampaign = save
          ? this.mediaplannerService.updateCampaign(true, template)
          : of(true);
        saveCampaign.subscribe(() => {
          this.mediaplannerService
            .loadDocument(template, DocumentOrigin.normal)
            .subscribe((success: LoadDocumentResult) => {
              ob.next(success);
              ob.complete();
            });
        });
      });
    };

    let routeToDocument = of({ success: true });
    if (fromTemplate)
      routeToDocument = initialiseFromTemplate(
        fromTemplate,
        createCampaignDialogData,
        save
      );
    if (createCampaignDialogData.action === 'import') {
      if (
        mixedDataDialogData.audienceAction !== audienceActionType.buildOwnAud &&
        mixedDataDialogData.planAudienceId
      ) {
        routeToDocument = this.multiSurveyService.addDAUFromDashboard(true);
      }
      if (
        mixedDataDialogData.audienceAction === audienceActionType.buildOwnAud
      ) {
        routeToDocument = this.multiSurveyService.addDAUFromDashboard(false);
      }
    }

    return routeToDocument;
  }

  // prompts for a title and description, then saves the project from the outset
  public createNewCampaign(fromTemplate?: TupDocument<DocumentCampaign>): void {
    // new planning component
    const planningOptions = new StartPlanningDialogModel();
    planningOptions.selected = 'new';
    planningOptions.allowPickup = !fromTemplate;
    planningOptions.dialogTitle = fromTemplate
      ? `Create campaign from '${fromTemplate.metadata.name}'`
      : 'New campaign';

    this.openNewCampaignDialog(planningOptions, fromTemplate);
  }

  openNewCampaignDialog(
    planningOptions: StartPlanningDialogModel,
    fromTemplate
  ) {
    const handleCampaignCreation = (
      dialogResult: StartPlanningDialogModel,
      mixedDataDialogResult?: MixedDataDialogModel
    ) => {
      this.initialising = true;
      this.mediaplannerService
        .prepare()
        .subscribe((prepResponse: CampaignPreparationResponse) => {
          this.initialising = false;
          if (!prepResponse.success) {
            this.dialogService.reportCampaignPreparationError(
              prepResponse.message
            );
            return;
          }

          // continue where you left off
          if (dialogResult.selected === 'continue') {
            this.onLoadAutoSavedDocument(); // assuming user has one.
          }

          const skip = !dialogResult.title;
          // create campaign stub and navigate
          if (dialogResult.selected === 'new') {
            // has no title:
            // load template as campaign, dont save
            if (!dialogResult.title) {
              if (fromTemplate) {
                dialogResult.title = 'New campaign';
                dialogResult.description = `Created from template '${fromTemplate.metadata.name}'`;
                this.mediaplannerService.plan.title = dialogResult.title;
                this.mediaplannerService.plan.description =
                  dialogResult.description;
              }

              const routeToDocument = this.buildRouteToDocumentOnCampaignCreate(
                fromTemplate,
                dialogResult,
                false,
                mixedDataDialogResult
              );

              routeToDocument.subscribe(() => {
                this.mediaplannerService.dirty();
                this.mediaplannerService.plan.campaignStarted = true;
                const forcedCampaignStage =
                  dialogResult.action === 'import' ? 0 : undefined;
                this.router.navigate(['/new/data'], {
                  queryParams: {
                    tab: this.steps.indexOf(
                      this.mediaplannerService.plan.getCampaignStage(
                        forcedCampaignStage
                      )
                    ),
                  },
                });
              });
            }

            // contains title:
            // create blank campaign, load template as campaign, update campaign
            if (dialogResult.title && !skip) {
              this.mediaplannerService.plan.title = dialogResult.title;
              this.mediaplannerService.plan.description =
                dialogResult.description;
              this.mediaplannerService.dirty(false);
              this.mediaplannerService
                .createCampaign(
                  TupDocumentTypeId.CMP_CAMPAIGN,
                  dialogResult.container
                )
                .subscribe((success) => {
                  if (success) {
                    const routeToDocument =
                      this.buildRouteToDocumentOnCampaignCreate(
                        fromTemplate,
                        dialogResult,
                        true,
                        mixedDataDialogResult
                      );

                    routeToDocument.subscribe(() => {
                      this.mediaplannerService.plan.campaignStarted = true;
                      const forcedCampaignStage =
                        dialogResult.action === 'import' ? 0 : undefined;
                      this.router.navigate(
                        [
                          `/edit/${this.mediaplannerService.plan.documentId}/data`,
                        ],
                        {
                          queryParams: {
                            tab: this.steps.indexOf(
                              this.mediaplannerService.plan.getCampaignStage(
                                forcedCampaignStage
                              )
                            ),
                          },
                        }
                      );
                    });
                  }
                });
            }
          }
        });
    };

    // clear multi survey array. Clearing it in mediaplannerService.prepare() will remove the potential file uploaded on plan creation
    this.mediaplannerService.plan.multiSurveys.clearAll();

    this.documentDialogService
      .openStartPlanningDialog(planningOptions)
      .afterClosed()
      .subscribe((dialogResult: StartPlanningDialogModel) => {
        if (dialogResult.action === 'import') {
          this.dialogService
            .openImportMixedDataDialog({
              action: dialogActionType.dashboardImport,
            })
            .afterClosed()
            .subscribe((importDialogRes) => {
              if (importDialogRes.continue) {
                handleCampaignCreation(dialogResult, importDialogRes);
              } else {
                this.openNewCampaignDialog(planningOptions, fromTemplate);
              }
            });
          return;
        }

        if (dialogResult.action === 'cancel') return;

        if (dialogResult.selected === 'continue') {
          this.autoSaveService.setEnabled(false);
        }
        handleCampaignCreation(dialogResult);
      });
  }

  changeSelectedIndex(selectedIndex: number) {
    this.selectedIndex = selectedIndex;
    this.ichabodsTab.activeTabIndex = selectedIndex;
    this.analyticsService.sendPageview(this.navigationTabs[selectedIndex]);
  }
}
