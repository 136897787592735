import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { SurveyMetrics } from 'src/app/models/planning.models';

@Component({
  selector: 'planning-toolbar',
  templateUrl: './planning-toolbar.component.html',
  styleUrls: ['./planning-toolbar.component.scss'],
})
export class PlanningToolbarComponent implements OnInit {
  @Input() content: TemplateRef<any>;
  @Input() survey: SurveyMetrics;

  constructor() {}

  ngOnInit(): void {}
}
