import { Component, Input, OnInit } from '@angular/core';
import { SurveyMetrics } from 'src/app/models/planning.models';
import { DialogService } from 'src/app/services/dialog.service';
import {
  SurveyInfoDialogModel,
  SurveyInfoType,
} from '../dialogs/survey-info-dialog/survey-info-dialog.component';

@Component({
  selector: 'survey-metrics',
  templateUrl: './survey-metrics.component.html',
  styleUrls: ['./survey-metrics.component.scss'],
})
export class SurveyMetricsComponent implements OnInit {
  @Input() survey: SurveyMetrics;
  @Input() hasSurveyTitleTooltip: boolean = false;
  @Input() basePopulation: boolean = false;

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {}

  onSurveyTitleClick() {
    if (!!this.survey.surveyInfo) {
      const options = new SurveyInfoDialogModel();
      options.type = SurveyInfoType.info;
      options.surveys = [this.survey];
      this.dialogService.openSurveyInfo(options);
    }
  }
}
