import { Subject, timer } from 'rxjs';
import { CellEditComponent } from '../cell-edit/cell-edit.component';
import { TreeTableEditEvent } from '../tree-table.models';
import { EventEmitter } from '@angular/core';

export interface CellNavigationEvent {
  editor: CellEditComponent;
  direction: string;
}

export class EditSelectionHandler {
  cellEditors: CellEditComponent[] = [];

  emitEvents: Subject<TreeTableEditEvent[]> = new Subject<
    TreeTableEditEvent[]
  >();

  editStatus: EventEmitter<null> = new EventEmitter<null>();

  queue: TreeTableEditEvent[] = [];
  lastRow: number = 0;
  isEditing: boolean = false;

  constructor() {
    this.editStatus.subscribe(() => {
      timer(100).subscribe(() => {
        if (!this.isEditing) this.emitQueue();
      });
    });
  }

  addEditor(editor: CellEditComponent) {
    // keep the latest copy of the component (sent through each time it's created)
    this.cellEditors = this.cellEditors.filter(
      (cell) => cell.reference !== editor.reference
    );
    this.cellEditors.push(editor);

    const [columnDef, row] = editor.reference.split('|');
    this.lastRow = Math.max(this.lastRow, parseInt(row));
  }

  // up,down arrows or enter key used to navigate
  navigation(event: CellNavigationEvent) {
    const { editor } = event;
    const direction = event.direction === 'up' ? -1 : 1;

    // reference tracks the column and row so the next row can be referenced
    let [columnDef, row] = editor.reference.split('|');
    let searching = true;
    let rowIndex = parseInt(row);
    let nextEditor: CellEditComponent = null;

    while (searching) {
      rowIndex += direction;
      const nextCell = `${columnDef}|${rowIndex}`;
      nextEditor = this.cellEditors.find(
        (editor) => editor.reference === nextCell && !editor.readonly
      );

      searching = !nextEditor;
      if (searching) {
        if (rowIndex < 1) rowIndex = this.lastRow + 1;
        if (rowIndex > this.lastRow + 1) rowIndex = 1;
      }
    }

    this.isEditing = !!nextEditor;
    if (nextEditor) {
      nextEditor.startEditing.emit();
    }
  }

  emitQueue() {
    this.emitEvents.next(this.queue);
    this.queue = [];
  }

  // called when a cell gets onBlur
  addToQueue(value: TreeTableEditEvent) {
    const index = this.queue.findIndex(
      (qu) =>
        (qu.columnDef === value.columnDef &&
          value.row.id &&
          qu.row.id === value.row.id) ||
        (qu.columnDef === value.columnDef &&
          !value.row.id &&
          qu.row.data.contents &&
          value.row.data.contents &&
          qu.row.data.contents.id === value.row.data.contents.id)
    );
    if (index !== -1) {
      this.queue.splice(index, 1);
    }
    this.queue.push(value);
  }

  checkEditStatus() {
    this.isEditing = false;
    this.editStatus.emit();
  }
}
