<div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
>
  <mat-card
    class="total-documents-card"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <span>{{ dataSource.length || 0 }}</span
    >{{ dataSource.length === 1 ? singleDocumentText : multipleDocumentsText }}
  </mat-card>
  <div class="vertical_separator"></div>
  <tup-document-filter
    fxFlex
    formFieldAppearance="outline"
    [formFieldLabel]="searchLabel"
    class="document_filter"
    [filterFor]="dataSource"
  >
  </tup-document-filter>

  <file-upload
    data-tracking="import-document-btn"
    [disabled]="initialising"
    (uploaded)="onFileUploaded($event)"
    [text]="'Import campaign'"
  >
  </file-upload>

  <button
    data-tracking="create-document-btn"
    mat-flat-button
    tupMatButton
    class="documents-header-btn"
    color="primary"
    (click)="createNewCampaign()"
    [disabled]="initialising"
  >
    <mat-icon>add</mat-icon>
    New campaign
  </button>
</div>
