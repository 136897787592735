import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { DndModule } from 'ngx-drag-drop';
import { ColorBlockModule } from 'ngx-color/block';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPrintModule } from 'ngx-print';

import { AppRoutingModule, routes } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TupAnalyticsModule } from '@telmar-global/tup-analytics';
import { MatDialogModule } from '@angular/material/dialog';
import { TupMaterialModule } from '@telmar-global/tup-material';
import { TupAuthModule } from '@telmar-global/tup-auth';
import {
  TupDocumentStorageEnvironment,
  TupDocumentStorageModule,
} from '@telmar-global/tup-document-storage';
import { TupAudienceGroupsModule } from '@telmar-global/tup-audience-groups';
import {
  TupUserPreferenceStorageEnvironment,
  TupUserPreferenceStorageModule,
} from '@telmar-global/tup-user-preference-storage';

import { TupUserMessageModule } from '@telmar-global/tup-user-message';
import { TupDocumentExporterModule } from '@telmar-global/tup-document-exporter';
import { TupGoogleApiModule } from '@telmar-global/tup-google-api';
import { AppMaterialModule } from './modules/app-material.module';
import { environment } from 'src/environments/environment';
import { DialogService } from './services/dialog.service';
import { ApiService } from './services/api.service';
import { DocumentService } from './services/document.service';
import { StateService } from './services/state.service';
import { TabLessTabsDirective } from './directives/tab-less-tabs.directive';
import { HoverClassDirective } from './directives/hover-class.directive';
import { TrainingHelpDialogComponent } from './components/dialogs/training-help-dialog/training-help-dialog.component';
import { MainComponent } from './pages/main/main.component';
import { EditorComponent } from './pages/editor/editor.component';
import { LoginComponent } from './pages/login/login.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { BriefingStepComponent } from './steps/briefing-step/briefing-step.component';
import { AudienceStepComponent } from './steps/audience-step/audience-step.component';
import { MediaStepComponent } from './steps/media-step/media-step.component';
import { PlanningStepComponent } from './steps/planning-step/planning-step.component';
import { BaseStepComponent } from './steps/base-step/base-step.component';
import { TreeTableComponent } from './components/tree-table/tree-table.component';
import { ResizeColumnDirective } from '../app/components/tree-table/resize-column.directive';
import { FilterTargetPipe } from './pipes/filter-target.pipe';
import { FilterSurveyPipe } from './pipes/filter-survey.pipe';
import { CustomPipe } from './components/tree-table/custom.pipe';
import { CellEditComponent } from './components/tree-table/cell-edit/cell-edit.component';
import { SurveySelectionDialogComponent } from './components/dialogs/survey-selection-dialog/survey-selection-dialog.component';
import { CodebookTableComponent } from './components/codebook-table/codebook-table.component';
import { CoreDemoSetComponent } from './core-demo-set/core-demo-set.component';
import { MediaTableComponent } from './media-table/media-table.component';
import { CtrlShiftDirective } from './directives/ctrl-shift.directive';
import { ProcessingComponent } from './components/processing/processing.component';
import { TabStepperComponent } from './components/tab-stepper/tab-stepper.component';
import { TabStepperTabComponent } from './components/tab-stepper/tab-stepper-tab/tab-stepper-tab.component';
import { SurveyInfoBarComponent } from './components/survey-info-bar/survey-info-bar.component';
import { DocumentEditorBarComponent } from './components/document-editor-bar/document-editor-bar.component';
import { TupLoggerModule } from '@telmar-global/tup-logger-angular';
import { ColumnMenuComponent } from './components/tree-table/column-menu/column-menu.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { TreeContextMenuComponent } from './components/tree-table/tree-context-menu/tree-context-menu.component';
import { TextareaAutosizeDirective } from './directives/textarea-autosize.directive';
import { StartPlanningDialogComponent } from './components/dialogs/start-planning-dialog/start-planning-dialog.component';
import { VehicleGroupsDialogComponent } from './components/dialogs/vehicle-groups-dialog/vehicle-groups-dialog.component';
import { SnackbarCustomisedComponent } from './components/dialogs/snackbar-customised/snackbar-customised.component';
import { ExportOptionsDialogComponent } from './components/dialogs/export-options-dialog/export-options.component';
import { ChartSettingsDialogComponent } from './components/dialogs/chart-settings-dialog/chart-settings-dialog.component';
import { SimpleSelectionComponent } from './components/simple-selection/simple-selection.component';
import { PlanningChartsComponent } from './planning-charts/planning-charts.component';
import { NewCodeBuilderDialogComponent } from './components/dialogs/new-code-builder-dialog/new-code-builder-dialog.component';

import { TupChartsModule } from '@telmar-global/tup-charts';
import { ColorPickerService } from './services/color-picker.service';
import { ChartComponent } from './components/chart/chart.component';
import { ChartColorDialogComponent } from './components/dialogs/chart-color-dialog/chart-color-dialog.component';
import { SelectionDialogComponent } from './components/dialogs/selection-dialog/selection-dialog.component';
import { ExporterService } from './services/exporter.service';
import { XlsxService } from './services/xlsx.service';
import { SurveyMetricsComponent } from './components/survey-metrics/survey-metrics.component';
import { AudienceToolbarComponent } from './components/audience-toolbar/audience-toolbar.component';
import { MediaToolbarComponent } from './components/media-toolbar/media-toolbar.component';
import { PlanningToolbarComponent } from './components/planning-toolbar/planning-toolbar.component';
import { ExportedGoogleFileDialogComponent } from './components/dialogs/exported-google-file-dialog/exported-google-file-dialog.component';
import { TreeInlineMenuComponent } from './components/tree-table/tree-inline-menu/tree-inline-menu.component';
import { ColumnGroupComponent } from './components/tree-table/column-group/column-group.component';
import { ColumnGroupsComponent } from './components/tree-table/column-groups/column-groups.component';
import { VisualCodeBuilderComponent } from './components/visual-code-builder/visual-code-builder.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CompareMediaComponent } from './components/compare-media/compare-media.component';
import { CompareAudiencesComponent } from './components/compare-audiences/compare-audiences.component';
import { ComparePlansComponent } from './components/compare-plans/compare-plans.component';

import { TargetsBarComponent } from './components/targets-bar/targets-bar.component';
import { ScrollableDirective } from './directives/scrollable.directive';
import { RenameMediaDialogComponent } from './components/dialogs/rename-media-dialog/rename-media-dialog.component';
import { AddAddressableDialogComponent } from './add-addressable-dialog/add-addressable-dialog.component';
import { AudienceItemComponent } from './add-addressable-dialog/audience-item/audience-item.component';
import { MetricColumnsDialogComponent } from './components/dialogs/metric-columns-dialog/metric-columns-dialog.component';
import { SnackbarGenericComponent } from './components/dialogs/snackbar-generic/snackbar-generic.component';
import { FilterAddressablePipe } from './pipes/filter-addressable.pipe';
import { TargetEditorDialogComponent } from './components/dialogs/target-editor-dialog/target-editor-dialog.component';
import { AddressableWarningDialogComponent } from './add-addressable-dialog/addressable-warning-dialog/addressable-warning-dialog.component';
import { CompareFreqDistributionComponent } from './components/compare-freq-distribution/compare-freq-distribution.component';
import { NumberIncrementalComponent } from './components/number-incremental/number-incremental.component';
import { FrequencyDistributionGroupingDialogComponent } from './components/dialogs/frequency-distribution-grouping-dialog/frequency-distribution-grouping-dialog.component';
import { SurveyInfoDialogComponent } from './components/dialogs/survey-info-dialog/survey-info-dialog.component';
import { DocumentDirtyGuard } from './guards/document.guard';
import { ChartsGuard } from './guards/charts.guard';
import { TabsGuard } from './guards/tabs.guard';
import { SaveDocumentDialogComponent } from './components/dialogs/save-document-dialog/save-document-dialog.component';
import { DocumentDialogService } from './services/document-dialog.service';
import { GroupWarningDialogComponent } from './components/dialogs/group-warning-dialog/group-warning-dialog.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { DocumentsToolbarComponent } from './components/documents-toolbar/documents-toolbar.component';
import { DashboardLatestCampaignsComponent } from './components/dashboard-latest-campaigns/dashboard-latest-campaigns.component';
import { DashboardLatestTamplatesComponent } from './components/dashboard-latest-tamplates/dashboard-latest-tamplates.component';
import { CountCodingDialogComponent } from './components/dialogs/count-coding-dialog/count-coding-dialog.component';
import { ViewModeFilterPipe } from './pipes/view-mode-filter.pipe';
import { CountCodingDialogService } from './services/count-coding-dialog.service';
import { MigrationService } from './services/migration.service';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { InternetConnectionDialogComponent } from './components/dialogs/internet-connection-dialog/internet-connection-dialog.component';
import { AppendUnitsPipe } from './pipes/append-units.pipe';
import { CellEditorPipe } from './components/tree-table/cell-editor.pipe';
import { VirtualScrollComponent } from './components/tree-table/virtual-scroll/virtual-scroll.component';
import { VirtualScrollTableDirective } from './components/tree-table/virtual-scroll/virtual-scroll-table.directive';
import { ManageAudienceMetricsComponent } from './components/dialogs/manage-audience-metrics/manage-audience-metrics.component';
import { SpotStepComponent } from './steps/spot-step/spot-step.component';
import { SpotplanQuickViewDialogComponent } from './components/dialogs/spotplan-quick-view-dialog/spotplan-quick-view-dialog.component';
import { SpotColumnsDialogComponent } from './components/dialogs/spot-columns-dialog/spot-columns-dialog.component';
import { SpotSettingsDialogComponent } from './components/dialogs/spot-settings-dialog/spot-settings-dialog.component';
import { ChartsTableComponent } from './components/charts-table/charts-table.component';
import { ConfirmSelectionComponent } from './components/confirm-selection/confirm-selection.component';
import { LoadingDotsComponent } from './components/loading-dots/loading-dots.component';
import { OptimiseDialogComponent } from './components/dialogs/optimise-dialog/optimise-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { ImportMixedDataDialogComponent } from './components/dialogs/import-mixed-data-dialog/import-mixed-data-dialog.component';
import { InfoMessageDialogComponent } from './components/dialogs/info-message-dialog/info-message-dialog.component';
import { ImportPreferencesComponent } from './components/dialogs/import-mixed-data-dialog/import-preferences/import-preferences.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { OptimiseProgressSnackbarComponent } from './components/dialogs/optimise-progress-snackbar/optimise-progress-snackbar.component';
import { ManualEntryComponent } from './components/dialogs/import-mixed-data-dialog/manual-entry/manual-entry.component';
import { ManualEntryDialogComponent } from './components/dialogs/import-mixed-data-dialog/manual-entry/manual-entry-dialog/manual-entry-dialog.component';
import { ClearExistingDataWarningDialogComponent } from './components/dialogs/clear-existing-data-warning-dialog/clear-existing-data-warning-dialog.component';
import { Ng5SliderModule } from 'ng5-slider';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { ENVIRONMENT } from '@telmar-global/tup-user-preference-storage';
import { SurveyMediaTypesTableDialogComponent } from './components/dialogs/survey-media-types-table-dialog/survey-media-types-table-dialog.component';

@NgModule({
  declarations: [
    TabLessTabsDirective,
    HoverClassDirective,
    AppComponent,
    LoginComponent,
    DocumentsComponent,
    EditorComponent,
    MainComponent,
    BriefingStepComponent,
    AudienceStepComponent,
    MediaStepComponent,
    PlanningStepComponent,
    BaseStepComponent,
    TreeTableComponent,
    ResizeColumnDirective,
    FilterTargetPipe,
    CustomPipe,
    FilterSurveyPipe,
    CellEditComponent,
    TrainingHelpDialogComponent,
    SurveySelectionDialogComponent,
    CodebookTableComponent,
    CoreDemoSetComponent,
    MediaTableComponent,
    CtrlShiftDirective,
    ProcessingComponent,
    TabStepperComponent,
    TabStepperTabComponent,
    SurveyInfoBarComponent,
    DocumentEditorBarComponent,
    ColumnMenuComponent,
    ContextMenuComponent,
    TreeContextMenuComponent,
    TextareaAutosizeDirective,
    StartPlanningDialogComponent,
    VehicleGroupsDialogComponent,
    SnackbarCustomisedComponent,
    ExportOptionsDialogComponent,
    ChartSettingsDialogComponent,
    SimpleSelectionComponent,
    PlanningChartsComponent,
    NewCodeBuilderDialogComponent,
    ChartComponent,
    ChartColorDialogComponent,
    SelectionDialogComponent,
    SurveyMetricsComponent,
    AudienceToolbarComponent,
    MediaToolbarComponent,
    PlanningToolbarComponent,
    ExportedGoogleFileDialogComponent,
    TreeInlineMenuComponent,
    ColumnGroupComponent,
    ColumnGroupsComponent,
    VisualCodeBuilderComponent,
    TruncatePipe,
    CompareMediaComponent,
    CompareAudiencesComponent,
    ComparePlansComponent,
    TargetsBarComponent,
    ScrollableDirective,
    RenameMediaDialogComponent,
    ConfirmDialogComponent,
    AddAddressableDialogComponent,
    AudienceItemComponent,
    MetricColumnsDialogComponent,
    SnackbarGenericComponent,
    FilterAddressablePipe,
    TargetEditorDialogComponent,
    AddressableWarningDialogComponent,
    CompareFreqDistributionComponent,
    NumberIncrementalComponent,
    FrequencyDistributionGroupingDialogComponent,
    SurveyInfoDialogComponent,
    SaveDocumentDialogComponent,
    GroupWarningDialogComponent,
    DocumentsToolbarComponent,
    DashboardLatestCampaignsComponent,
    DashboardLatestTamplatesComponent,
    CountCodingDialogComponent,
    ViewModeFilterPipe,
    FileUploadComponent,
    InternetConnectionDialogComponent,
    AppendUnitsPipe,
    CellEditorPipe,
    VirtualScrollComponent,
    VirtualScrollTableDirective,
    ManageAudienceMetricsComponent,
    SpotStepComponent,
    SpotplanQuickViewDialogComponent,
    SpotColumnsDialogComponent,
    SpotSettingsDialogComponent,
    ChartsTableComponent,
    ConfirmSelectionComponent,
    LoadingDotsComponent,
    OptimiseDialogComponent,
    ImportMixedDataDialogComponent,
    InfoMessageDialogComponent,
    ImportPreferencesComponent,
    BreadcrumbsComponent,
    OptimiseProgressSnackbarComponent,
    ManualEntryComponent,
    ManualEntryDialogComponent,
    ClearExistingDataWarningDialogComponent,
    SurveyMediaTypesTableDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppMaterialModule,
    TupMaterialModule,
    MatDialogModule,
    TupUserMessageModule,
    TupChartsModule,
    InfiniteScrollModule,
    TupDocumentExporterModule.forRoot(environment),
    TupGoogleApiModule.forRoot(environment.googleApi),
    TupAnalyticsModule.forRoot(environment.analytics),
    TupAuthModule.forRoot(environment, routes),
    TupLoggerModule.forRoot(environment),
    TupDocumentStorageModule.forRoot(
      environment as unknown as TupDocumentStorageEnvironment
    ),
    TupUserPreferenceStorageModule.forRoot(
      environment as TupUserPreferenceStorageEnvironment
    ),
    TupAudienceGroupsModule.forRoot(environment),
    DndModule,
    ColorBlockModule,
    NgxPrintModule,
    MatSliderModule,
    Ng5SliderModule,
    DragToSelectModule.forRoot(),
  ],
  providers: [
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    CountCodingDialogService,
    DialogService,
    ColorPickerService,
    ApiService,
    DocumentService,
    StateService,
    XlsxService,
    ExporterService,
    MigrationService,
    DocumentDirtyGuard,
    DocumentDialogService,
    ChartsGuard,
    TabsGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
