<div
  fxFlex="100%"
  fxLayoutAlign="end center"
>
  <!-- Survey Metrics -->
  <div fxLayout="row">
    <survey-metrics
      [survey]="survey"
      [basePopulation]="true"
    ></survey-metrics>
  </div>
</div>
