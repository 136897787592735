import { TupDocument, TupTag } from '@telmar-global/tup-document-storage';
import {
  DocumentCampaignHeader,
  DocumentBrief,
  DocumentCampaign,
  DocumentSurvey,
  DocumentTarget,
  UNSAVED_DOCUMENT,
  FrequencySettings,
  DocumentFullSurvey,
  DOCUMENT_CAMPAIGN_VERSION,
  COMPRESS_CAMPAIGNS,
  DocumentTargetResults,
  DocumentSchedule,
} from '../models/document.model';
import { Result, ResultType } from './result';
import { EMPTY_SCHEDULE, Schedule } from './schedule';
import { Target, TargetStatus } from './target';
import { ColumnCollection } from './column-collection';
import { ScheduleVehicle, TargetVehicle } from './vehicle';
import {
  EMPTY_NUMBER_CELL,
  EMPTY_STRING_CELL,
  VehiclePlanningData,
} from './planning-data';
import { ScheduleTotalTag } from './schedule-total';
import { VehicleGroups } from './vehicle-groups';
import { SurveyMetaData, SurveysMetaData } from './survey-metadata';
import uniqid from 'uniqid';
import { cloneDeep } from 'lodash';
import { ViewMode } from '../components/tab-stepper/tab-stepper.component';
import { TreeTableColumn } from '../components/tree-table/tree-table.models';
import { ChartSettings } from '../models/charts.model';
import { MultiSurvey, MultiSurveys } from './multi-survey';
import {
  MULTI_SURVEY_DAU,
  MULTI_SURVEY_MRF,
} from '../models/multi-survey.models';
import { Compression } from '../models/compression';
import { SurveyInfo } from '../models/engine.media-evaluation.models';

/*
Proposed structure for holding targets/suveys/vehicles/schedules/results (subject to change)
A possible structure for holding all the arrays of the various parts required to fit spec.  Subject to change

** vehicle audiences and survey and target popualations are stored under the target object
** inserts, costs and schedule totals are stored under the schedules object.

Target[]
    documentTarget: DocumentTarget
    survey: DocumentSurvey
    vehicles: TargetVehicle[]
        vehicle:
            id
            audience

Schedules[]
    shedule: Schedule
        vehicles: ScheduleVehicle[]
            vehicle:
                id:  string
                inserts: number
                costs: number
                result: Result
        scheduleTotal: Result (total sched)
        mediatypeTotals: Result[]
*/
const initialMediaSettings = {
  selectedMetrics: [],
  metricsOrder: [],
  sortBy: '',
  orderBy: '',
  selectedColumn: '',
};
export const defaultFreqDistributionSettings = {
  freqLevelTo: 9,
  isGroupingOn: false,
  freqUpTo: 2,
  groupsOf: 3,
};
export interface EffectiveReach {
  effectiveReachFrom: number;
  effectiveReachTo?: number;
  active?: boolean;
}

export interface MediaSettings {
  selectedMetrics: TreeTableColumn[];
  metricsOrder: TreeTableColumn[];
  sortBy: string;
  orderBy: string;
  selectedColumn: string;
}

export class MediaPlanner {
  document: TupDocument<DocumentCampaign>;
  documentId: string;
  autoSaveDocumentId: string;

  title: string;
  description: string;
  tags: TupTag[];

  campaignStarted: boolean = false;
  campaignDirty: boolean = false;
  campaignRedirecting: boolean = false;
  campaignSpotplan: string = '';
  campaignStage: string;
  tabReady: string;
  viewMode: string = ViewMode.Tables;

  selectedSurveys: DocumentFullSurvey[];
  get primarySurvey() {
    return this.selectedSurveys.find((val) => val.isPrimary);
  }
  get currentSurvey() {
    return this.selectedSurveys.find((val) => val.isCurrent);
  } // currently selected survey for building a plan (codebookservice.clearedSurveys for full list)

  get surveyList(): SurveyInfo[] {
    return this.selectedSurveys.map((survey) => ({
      surveyCode: survey.code,
      authorizationGroup: survey.authorizationGroup,
      ESGProvider: survey.esgProviders[0],
    }));
  }

  get surveyListForMultiSurvey(): SurveyInfo[] {
    if (this.selectedSurveys.length > 1) {
      return this.surveyList;
    }
    return undefined;
  }

  surveyMetaData: SurveysMetaData;
  brief: DocumentBrief; // campaign brief

  columns: ColumnCollection;
  spotColumns: ColumnCollection;
  baseTarget: Target;
  targets: Target[] = [];
  schedules: Schedule[] = [];
  vehicleGroups: VehicleGroups;
  effectiveReach: EffectiveReach;
  freqDistributionSettings: FrequencySettings;
  mediaSettings: MediaSettings;
  chartSettings: { [key: string]: ChartSettings } = {};
  multiSurveys: MultiSurveys;

  constructor() {
    this.baseTarget = new Target();
    this.columns = new ColumnCollection();
    this.spotColumns = new ColumnCollection();
    this.mediaSettings = initialMediaSettings;
    this.surveyMetaData = new SurveysMetaData();
    this.vehicleGroups = new VehicleGroups();
    this.multiSurveys = new MultiSurveys();
    this.clearAll(); // initialise
  }

  addRemoveSurveyColumn(remove = false) {
    if (remove) {
      this.columns.removeSurveyColumn();
    } else {
      this.columns.addSurveyColumn();
    }
  }

  /**
   * get everything together for the given vehicle,schedule,target for the treeTable
   *
   * @param {Target} target target required
   * @param {Schedule} schedule schedule required
   * @param {TargetVehicle} vehicle vehicle required
   * @returns {VehiclePlanningData} vehicle result data
   */
  getVehiclePlanningData(
    target: Target,
    schedule: Schedule,
    vehicle: TargetVehicle
  ): VehiclePlanningData {
    const scheduleVehicle = schedule.vehicle(target, vehicle.id);
    const scheduleResult = scheduleVehicle.result;
    const isDMAddressable =
      vehicle.addressableConfig &&
      this.surveyMetaData
        .meta(vehicle.survey.code)
        .isDirectMail(vehicle.mediaTypeId);
    const isAddr = !!vehicle.addressableConfig;
    const broadcast = !!(vehicle.dayparts && vehicle.dayparts.length);
    const multiSurvey = [MULTI_SURVEY_DAU, MULTI_SURVEY_MRF].includes(
      vehicle.calculationMethod
    );
    const population = multiSurvey
      ? scheduleResult.population
      : target.population;

    return {
      mediaType: vehicle.mediaType,
      potentialReach000: multiSurvey
        ? EMPTY_NUMBER_CELL
        : vehicle.potentialReach,
      potentialReachPct: multiSurvey
        ? EMPTY_NUMBER_CELL
        : population
        ? (vehicle.potentialReach / population) * 100
        : 0,
      resps: vehicle.resps,
      audience: vehicle.audience,
      audiencePct: population ? (vehicle.audience / population) * 100 : 0,
      reach000: scheduleResult.reach000,
      reachPct: scheduleResult.reachPct,
      impressions: scheduleResult.impressions,
      grps: scheduleResult.grps,
      buyingImpressions: isAddr
        ? scheduleResult.buyingImpressions
        : EMPTY_NUMBER_CELL,
      buyingGrps: isAddr ? scheduleResult.buyingGrps : EMPTY_NUMBER_CELL,
      avgFrequency: scheduleResult.avgFrequency,
      inserts: this.formatView('inserts', vehicle, scheduleVehicle),
      surveyCode: vehicle?.survey?.code || EMPTY_STRING_CELL,
      duration:
        vehicle.isMultiSurvey ||
        !this.surveyMetaData
          .meta(vehicle.survey.code)
          .isDirectMail(vehicle.mediaTypeId)
          ? scheduleResult.duration
          : EMPTY_NUMBER_CELL,
      freqCapping: isAddr ? scheduleResult.freqCapping : EMPTY_NUMBER_CELL, // only show values for web FREQ_CAPPING
      audienceBasis: this.surveyMetaData
        .meta(vehicle.survey.code || this.primarySurvey.code)
        .getMeasurementBasis(vehicle.mediaTypeId),
      // avgMinOnSite: EMPTY_STRING_CELL,
      uniqueReachPct: scheduleResult.uniqueReachPct || EMPTY_STRING_CELL,
      uniqueReach000: scheduleResult.uniqueReach000 || EMPTY_STRING_CELL,
      compositionIndex: vehicle.compositionIndex || EMPTY_STRING_CELL,
      compositionPct: vehicle.compositionPct || EMPTY_STRING_CELL,
      effReach: scheduleResult.effectiveReach,
      effReachPct: scheduleResult.effectiveReachPct,
      cpm: scheduleResult.cpm,
      cpp: scheduleResult.cpp,
      buyingCpm: isAddr ? scheduleResult.buyingCpm : EMPTY_NUMBER_CELL,
      buyingCpp: isAddr ? scheduleResult.buyingCpp : EMPTY_NUMBER_CELL,
      baseCpm:
        isAddr || isDMAddressable ? EMPTY_NUMBER_CELL : scheduleResult.baseCpm,
      unitCost:
        isAddr || isDMAddressable || broadcast
          ? EMPTY_NUMBER_CELL
          : scheduleResult.unitCost,
      totalCost: scheduleResult.totalCost,
      esgScore: scheduleResult.esgScore,
      esgStability: scheduleResult.esgStability,
      insertsDM: isDMAddressable
        ? this.formatView('insertsDM', vehicle, scheduleVehicle)
        : EMPTY_NUMBER_CELL,
      noOfMailItems: isDMAddressable
        ? scheduleResult.noOfMailItems
        : EMPTY_NUMBER_CELL,
      durationDM: isDMAddressable
        ? scheduleResult.durationDM
        : EMPTY_NUMBER_CELL,
    };
  }

  getFreqDistData(
    group: string,
    tag: ScheduleTotalTag | ScheduleTotalTag[],
    target: Target,
    schedule: Schedule,
    freqDistArray: number[],
    freqLevelTo: number
  ) {
    const res = schedule.getScheduleTotal(group, tag, target).result;

    const data = {
      reachedExactly: 0,
      reachedExactlyPct: 0,
      reachedAtLeast000: 0,
      reachedAtLeastPct: 0,
      impressionsFD: 0,
    };

    if (
      res.reachedExactly.length === 0 ||
      res.reachedExactlyPct.length === 0 ||
      res.reachedAtLeast000.length === 0 ||
      res.reachedAtLeastPct.length === 0
    ) {
      return data;
    }

    if (freqDistArray.length > 1) {
      freqDistArray.forEach((freqLevel) => {
        data.reachedExactly += res.reachedExactly[freqLevel];
        data.reachedExactlyPct += res.reachedExactlyPct[freqLevel];
        data.impressionsFD += res.impressionsFD[freqLevel];

        data.reachedAtLeast000 = res.reachedAtLeast000[freqDistArray[0]];
        data.reachedAtLeastPct = res.reachedAtLeastPct[freqDistArray[0]];
      });
      return data;
    }

    //if last row
    if (freqLevelTo === freqDistArray[0]) {
      return {
        reachedExactly: res.reachedAtLeast000[freqDistArray[0]],
        reachedExactlyPct: res.reachedAtLeastPct[freqDistArray[0]],
        reachedAtLeast000: res.reachedAtLeast000[freqDistArray[0]],
        reachedAtLeastPct: res.reachedAtLeastPct[freqDistArray[0]],
        impressionsFD: res.impressionsFD[freqDistArray[0]],
      };
    }
    return {
      reachedExactly: res.reachedExactly[freqDistArray[0]],
      reachedExactlyPct: res.reachedExactlyPct[freqDistArray[0]],
      reachedAtLeast000: res.reachedAtLeast000[freqDistArray[0]],
      reachedAtLeastPct: res.reachedAtLeastPct[freqDistArray[0]],
      impressionsFD: res.impressionsFD[freqDistArray[0]],
    };
  }

  /**
   * Provide schedule resuts at a group level
   *
   * @param {string} group group results are required for
   * @param {ScheduletotalTag} tag results tag
   * @param {Target} target target required
   * @param {Schedule} schedule schedule required
   * @returns {VehiclePlanningData} result data
   */
  getTotalsPlanningData(
    group: string,
    tag: ScheduleTotalTag | ScheduleTotalTag[],
    target: Target,
    schedule: Schedule
  ): VehiclePlanningData {
    const res = schedule.getScheduleTotal(group, tag, target).result;
    let isDirectMail = false;
    if (group !== 'total') {
      const currentMediaType = target.vehicles.find(
        (veh) => veh.mediaType === group
      );
      isDirectMail = this.surveyMetaData
        .meta(target.survey.code)
        .isDirectMail(currentMediaType.mediaTypeId);
    }

    const onlyPressOrBroadcast = this.surveyMetaData
      .meta(target.survey.code)
      .onlyPressOrBroadcast(target.vehicles, group);
    const manualMediaOrMRF = target.vehicles.find(
      (veh) =>
        veh.isMultiSurvey &&
        veh.multiSurveyConfig &&
        (veh.multiSurveyConfig.manualInput || veh.multiSurveyConfig.vehicleRef)
    );

    return {
      audience: EMPTY_NUMBER_CELL,
      audiencePct: EMPTY_NUMBER_CELL,
      mediaType: EMPTY_STRING_CELL,
      potentialReach000: EMPTY_NUMBER_CELL,
      buyingImpressions: EMPTY_NUMBER_CELL,
      buyingGrps: EMPTY_NUMBER_CELL,
      resps: EMPTY_NUMBER_CELL,
      reach000: res.reach000,
      reachPct: res.reachPct,
      impressions: res.impressions,
      grps: res.GRPs,
      avgFrequency: res.avgFrequency,
      inserts:
        onlyPressOrBroadcast || manualMediaOrMRF
          ? res.inserts
          : EMPTY_NUMBER_CELL,
      duration: !isDirectMail ? res.duration : EMPTY_NUMBER_CELL,
      audienceBasis: EMPTY_STRING_CELL,
      // avgMinOnSite: EMPTY_NUMBER_CELL,
      potentialReachPct: EMPTY_NUMBER_CELL,
      surveyCode: EMPTY_STRING_CELL,
      uniqueReachPct:
        group === 'total' ? EMPTY_NUMBER_CELL : res.uniqueReachPct,
      uniqueReach000:
        group === 'total' ? EMPTY_NUMBER_CELL : res.uniqueReach000,
      effReach: res.effectiveReach,
      effReachPct: res.effectiveReachPct,
      freqCapping: EMPTY_NUMBER_CELL,
      cpm: res.cpm,
      cpp: res.cpp,
      buyingCpm: EMPTY_NUMBER_CELL,
      buyingCpp: EMPTY_NUMBER_CELL,
      baseCpm: isDirectMail ? EMPTY_NUMBER_CELL : res.baseCpm,
      unitCost: EMPTY_NUMBER_CELL,
      totalCost: res.totalCost,
      esgScore: res.esgScore,
      esgStability: res.esgStability,
      durationDM: EMPTY_NUMBER_CELL,
      insertsDM: EMPTY_NUMBER_CELL,
      noOfMailItems: EMPTY_NUMBER_CELL,
    };
  }

  // formatting views by vehicle for the table.
  // Currently just handling digital differences but will expand as needed
  formatView(
    columnDef: string,
    vehicle: TargetVehicle,
    scheduleVehicle: ScheduleVehicle
  ): number {
    switch (columnDef) {
      case 'inserts':
      case 'insertsDM':
        const mult = this.surveyMetaData.reachFreq.isPercentageInserts(vehicle)
          ? 100
          : 1;
        const num = scheduleVehicle.result[columnDef] * mult;
        return num;
        break;

      default:
        break;
    }
  }

  addSchedule(name: string, copyFrom?: Schedule) {
    const schedule = new Schedule(name);

    // duplicating an existing schedule
    if (copyFrom) {
      // copy vehicles
      schedule.vehicles = [];
      copyFrom.vehicles.forEach((veh) => {
        schedule.vehicles.push({
          targetId: veh.targetId,
          vehicleId: veh.vehicleId,
          result: new Result(veh.result.population, veh.result.copy()),
        });
      });

      // copy totals
      schedule.scheduleTotal = [];
      copyFrom.scheduleTotal.forEach((copy) => {
        schedule.scheduleTotal.push({
          group: copy.group,
          tag: copy.tag,
          targetId: copy.targetId,
          result: new Result(copy.result.population, copy.result.copy()),
        });
      });

      schedule.spotplans.copy(copyFrom.spotplans);
    }

    this.schedules.push(schedule);
  }

  deleteSchedule(index: number) {
    this.schedules.splice(index, 1);
  }

  removeVehicle(vehicleId: string) {
    // remove any results it might have from all schedules
    this.schedules.forEach((schedule) => {
      const veh = this.targets[0].vehicle(vehicleId);
      const ids = veh?.dayparts?.length
        ? veh?.dayparts?.map((dayPart) => dayPart.id)
        : [];
      ids.push(vehicleId);

      schedule.vehicles = schedule.vehicles.filter(
        (veh) => !ids.includes(veh.vehicleId)
      );

      schedule.spotplans.removeVehicle(vehicleId);
    });

    // remove from all targets
    this.targets.forEach((target) => target.removeVehicle(vehicleId));
  }

  duplicateVehicle(vehicleId: string) {
    // for each target, locate the vehicle, clone it, make a unique ID and add it
    const proxy = uniqid();
    this.targets.forEach((target) => {
      const vehicle = cloneDeep(target.vehicle(vehicleId));
      vehicle.id = `${vehicle.mnemonic}@${proxy}`;

      if (vehicle.dayparts) {
        vehicle.dayparts.forEach((dp) => {
          dp.id = `${dp.mnemonic}@${proxy}`;
        });
      }

      target.addVehicles([vehicle], true);
    });
  }

  // extract vehicles from each target then reconcile against each of the schedules
  // vehicles that are in the schedule but not in the target should be removed (also dayparts if their parent has gone)
  // done the long winded way one array at a time in case schedules and targets have different vehicle lists in the future
  reconcileVehiclesWithSchedules() {
    const DELETE = '_MARKED_FOR_DELETE_';
    this.targets.forEach((target) => {
      // get all ids within this target  (broadcast parents and regaular vehicles only)
      const targetVehIds = target.vehicles.map((veh) => veh.id);

      this.schedules.forEach((schedule) => {
        // loop through schedule vehicles working only with the selected target
        const targetScheduleVehicles = schedule.vehicles.filter(
          (v) => v.targetId === target.id
        );
        targetScheduleVehicles.forEach((veh: ScheduleVehicle) => {
          if (!targetVehIds.includes(veh.vehicleId)) {
            switch (veh.result.type) {
              case ResultType.vehicle: // mark normal vehicle for delete
                veh.vehicleId = DELETE;
                break;

              case ResultType.broadcast: // mark broadcast for delete and locate all the dayparts to delete too
                const dayparts = targetScheduleVehicles.filter(
                  (v) => v.result.reference === veh.vehicleId
                );
                dayparts.forEach((v) => (v.vehicleId = DELETE));
                veh.vehicleId = DELETE; // parent
                break;

              default:
                break;
            }
          }
        });

        // remove those marked for deletion within this schedule
        const beforeCount = schedule.vehicles.length;
        schedule.vehicles = schedule.vehicles.filter(
          (veh) => veh.vehicleId !== DELETE
        ); //veh.targetId === target.id &&

        schedule.totalsRecalcRequired =
          schedule.totalsRecalcRequired ||
          schedule.vehicles.length !== beforeCount;
        if (schedule.totalsRecalcRequired) schedule.clearTotals(target);
      });
    });

    this.targets.length
      ? this.vehicleGroups.reconcileVehicles(this.targets[0].vehicles)
      : {};
  }

  anyResults(schedules: Schedule[] = null, targets: Target[] = null): boolean {
    let tot = 0;
    const scheds = schedules || this.schedules;
    const tgts = targets || this.targets;

    for (let sch of scheds) {
      for (let tgt of tgts) {
        tot += sch.getScheduleTotal('total', ScheduleTotalTag.total, tgt).result
          .reach;
      }
      if (tot > 0) break;
    }
    return !!tot;
  }

  // set a vehicle with an addressable target across all planning targets
  addAddressable(
    vehicleId: number | string,
    addressableTarget: Target,
    targets: Target[] = []
  ) {
    const tgts = targets.length ? targets : this.targets;
    tgts.forEach((tgt) => {
      const veh = tgt.vehicle(vehicleId);
      const added = !veh.addressableConfig && !!addressableTarget; // from no config to config
      const removed = veh.addressableConfig && !addressableTarget; // from config to no config
      tgt.addAddressable(
        veh,
        addressableTarget,
        this.surveyMetaData.meta(veh.survey.code).isDirectMail(veh.mediaTypeId)
      );

      // if addressable config has been added or removed, then do a results cleanup
      if (added || removed) {
        this.schedules.forEach((schedule) => {
          if (added)
            schedule
              .vehicle(tgt, vehicleId as string)
              .result.addResults({ inserts: 0, duration: 0, impressions: 0 });
          if (removed)
            schedule.vehicle(tgt, vehicleId as string).result.zeroResults();
        });
      }
    });
  }

  // pass new title or empty string to default back to originalTitle
  renameVehicle(
    vehicleId: number | string,
    newTitle: string,
    targets: Target[] = []
  ) {
    const tgts = targets.length ? targets : this.targets;
    tgts.forEach((tgt) => {
      const vehicle = tgt.vehicle(vehicleId);
      vehicle ? tgt.renameVehicle(vehicle, newTitle) : null;
    });
  }

  renameSpotplan(vehicleId: number | string, newTitle: string) {
    this.schedules.forEach((schedule) => {
      const spotplan = schedule.spotplans.findSpotplan(vehicleId as string);
      spotplan ? (spotplan.name = newTitle) : null;
    });
  }

  // add (new) or update (existing) target in array
  addTarget(
    documentTarget: DocumentTarget,
    vehicles: TargetVehicle[] = null,
    allowDuplicate: boolean = false
  ): Target {
    const coding = documentTarget.coding || this.getCoding(documentTarget);
    const customPop = documentTarget.customPopulation;
    let target = this.targets.find((tgt) => tgt.coding === coding);

    if (target && !allowDuplicate) {
      target.documentTarget.title = documentTarget.title;

      target.status =
        target.documentTarget.coding !== coding ||
        target.documentTarget.customPopulation !== customPop
          ? TargetStatus.updated
          : TargetStatus.noChange;
      target.documentTarget.coding = coding; // update coding
      target.documentTarget.addressableTarget =
        documentTarget.addressableTarget;
      target.documentTarget.planningTarget = documentTarget.planningTarget;
      target.documentTarget.customPopulation = customPop;
      target.documentTarget.ownPopulation = target.documentTarget.population;
      if (documentTarget.survey) {
        target.documentTarget.survey = documentTarget.survey;
      }
    } else {
      target = new Target();
      documentTarget.coding = coding;
      target.documentTarget = cloneDeep(documentTarget);
      this.targets.push(target);

      vehicles
        ? target.addVehicles(vehicles, false)
        : this.intialiseTargetVehicles(target);
    }

    return target;
  }

  deleteTarget(index: number, passive: boolean): void {
    // for each schedule, remove vehicle and total inputs for the given id
    const target = this.targets[index];
    if (passive) target.status = TargetStatus.disabled;
    else {
      const remainingCostTargetIds = this.targets
        .filter((tgt) => tgt.id !== target.id)
        .map((tgt) => tgt.id);
      this.schedules.forEach((schedule) => {
        schedule.vehicles = schedule.vehicles.filter(
          (vehicle) => vehicle.targetId !== target.id
        );

        schedule.scheduleTotal = schedule.scheduleTotal.filter(
          (total) => total.targetId !== target.id
        );

        schedule.vehicles.forEach((veh) => {
          if (
            veh.result.costTargetId &&
            !remainingCostTargetIds.includes(veh.result.costTargetId)
          ) {
            veh.result.addResults({ costTargetId: remainingCostTargetIds[0] });
          }
        });

        //Spotplan object to cleanup target related results
        schedule.spotplans.deleteTarget(target.id, remainingCostTargetIds);
      });

      // loop vehicles in each taget and remove the addressableConfigs that refer to the target being deleted
      this.targets.forEach((tgt) =>
        tgt.vehicles.forEach(
          (tgtVeh) =>
            (tgtVeh.addressableConfig =
              tgtVeh.addressableConfig &&
              tgtVeh.addressableConfig.targetId === target.id
                ? null
                : tgtVeh.addressableConfig)
        )
      );

      this.targets.splice(index, 1);
    }
  }

  duplicateTarget(target: Target): Target {
    const newTarget = this.addTarget(target.documentTarget, null, true);
    return newTarget;
  }

  // get vehicles from one of the other targets in the array and fetch audiences if any are found
  private intialiseTargetVehicles(newTarget: Target) {
    const copyFrom = this.targets.find((target) => target.vehicles.length);
    if (copyFrom) newTarget.addVehicles(copyFrom.vehicles, false);
  }

  clearAll() {
    this.brief = this.clearBriefing();
    this.clearOnSurveyChange();
    this.documentId = UNSAVED_DOCUMENT;
    this.document = null;
    this.freqDistributionSettings = defaultFreqDistributionSettings;
    this.effectiveReach = { effectiveReachFrom: 3, active: false };
    // general things
    this.campaignStarted = false;
    this.title = '';
    this.description = '';
  }

  // default briefing values
  clearBriefing(plannerName: string = ''): DocumentBrief {
    return {
      campaignName: '',
      advertiserName: '',
      plannerName: plannerName,
      startDate: new Date().toISOString().split('T')[0],
      campaignId: '12345',

      briefObjective: 'Conversion',
      KPI1: 'Sales Conversions',
      KPI2: 'GRPs',
      KPI3: 'CPM',
      flightDateStart: '',
      flightDateEnd: '',
      budget: '',
      constraints: '',
      comments: '',
    };
  }

  clearOnSurveyChange(singleSurvey: boolean = true) {
    this.schedules = [];
    this.chartSettings = {};
    this.columns.clearAll();
    this.mediaSettings = initialMediaSettings;
    this.baseTarget.clear();
    this.surveyMetaData.clear();
    this.campaignStage = ''; // clear preset satges so they use the fallback
    this.tabReady = '';
    this.viewMode = ViewMode.Tables;
    this.vehicleGroups.clearAll();
    if (singleSurvey) {
      this.selectedSurveys = [];
      this.targets = [];
    }
  }

  /**
   * Prepare and return a campaign file to be passed into document storage for saving
   *
   * @returns DocumentCampaign object representing the current campaign, ready to go to the doc service
   */
  asDocumentCampaign(includeSchedules: boolean = true): DocumentCampaign {
    const doCompression = COMPRESS_CAMPAIGNS;

    const doc: DocumentCampaign = new DocumentCampaign();
    doc.brief = this.brief;
    doc.survey = this.primarySurvey;
    doc.selectedSurveys = this.selectedSurveys;
    doc.header = this.getCampaignHeader(doCompression);

    const baseTarget = this.baseTarget.asDocument();
    doc.baseTarget = doCompression
      ? Compression.compress<DocumentTargetResults>(baseTarget)
      : baseTarget;

    const targets = this.targets.map((target) => target.asDocument());
    doc.targets = doCompression
      ? Compression.compress<DocumentTargetResults[]>(targets)
      : targets;

    doc.freqDistributionSettings = this.freqDistributionSettings;
    doc.effectiveReach = this.effectiveReach;

    const schedules = includeSchedules
      ? this.schedules.map((schedule) => schedule.asDocument())
      : [EMPTY_SCHEDULE];
    doc.schedules = doCompression
      ? Compression.compress<DocumentSchedule[]>(schedules)
      : schedules;

    doc.vehicleGroups = this.vehicleGroups.asDocument();
    doc.columns = this.columns.asDocument();
    doc.mediaSettings = this.mediaSettings;
    doc.chartSettings = this.chartSettings;

    const multiSurveys = cloneDeep(this.multiSurveys.multiSurveys);
    doc.multiSurveys = doCompression
      ? Compression.compress<MultiSurvey[]>(multiSurveys)
      : multiSurveys;

    return doc;
  }

  //Summary data about the campaign, mostly for use in the document table for users to view
  getCampaignHeader(doCompression: boolean): DocumentCampaignHeader {
    const result =
      this.schedules.length && this.targets.length
        ? this.schedules[0].getScheduleTotal(
            'total',
            ScheduleTotalTag.total,
            this.targets[0]
          ).result
        : new Result(0);

    const mediaTypes = this.targets.length
      ? this.targets[0].getMediaTypes()
      : '';

    let surveyTitles = [];
    let surveyCodes = [];

    this.selectedSurveys.forEach((survey) => {
      surveyTitles.push(survey.title);
      surveyCodes.push(survey.code);
    });

    let survey = surveyTitles.join(', ');
    let surveyCode = surveyCodes.join(', ');

    const unitsText = this.currentSurvey?.unitsText || '';
    const units = this.currentSurvey?.units || 0;
    const campaignStage = this.campaignStage;

    return {
      version: doCompression ? DOCUMENT_CAMPAIGN_VERSION : 0,
      GRPs: result.GRPs,
      reach: result.reach000,
      frequency: result.avgFrequency,
      targetCount: this.targets.length,
      targetNames: this.targets.map((target) => target.title).join(', '),
      description: this.description || '',
      survey,
      surveyCode,
      unitsText,
      units,
      mediaTypes,
      campaignStage,
    };
  }

  // after a load campaign, determine whats missing so which editor screen should be routed
  getCampaignStage(forcedStage?: number): string {
    const stages = ['audience', 'media', 'planning', 'spots'];
    let stage = '';

    if (this.targets.length === 0) stage = stages[0]; // forced to audience
    else if (this.targets[0].vehicles.length === 0) stage = stages[0]; // targets, but no media, forced to audience
    if (forcedStage !== undefined && forcedStage <= 3)
      stage = stages[forcedStage];
    if (!stage) stage = this.anyResults() ? stages[2] : stages[1]; // if not forced, choose planning or media

    const forced = stages.indexOf(stage);
    const requested = this.campaignStage
      ? stages.indexOf(this.campaignStage)
      : forced;

    if (
      this.campaignStage &&
      (this.campaignStage === 'planning' || this.campaignStage === 'spots') &&
      this.targets[0].vehicles.length > 0
    ) {
      this.campaignStage = stages[requested];
    } else {
      this.campaignStage = stages[Math.min(forced, requested)];
    }
    return this.campaignStage;
  }

  private getCoding(target: DocumentTarget): string {
    let coding = '';
    target.targets.forEach((tgt, index) => {
      coding += tgt.coding;
      if (index < target.targets.length - 1) coding += ` ${tgt.operator} `;
    });

    return coding;
  }
}
