<div
  fxLayout="row"
  fxFlex="grow"
  fxLayoutAlign="start center"
  [ngClass]="{ 'editing-pad': editing }"
>
  <!-- display label only (non edit mode) -->
  <div
    *ngIf="!editing"
    fxLayout="row"
    fxLayoutAlign="space-around center"
    class="not-focused"
    hover-class="hover-focus"
  >
    <div>
      <strong>{{ title }}</strong>
    </div>
    <button
      class="inline-button"
      appearance="nano"
      matTooltip="Edit"
      tupMatButton
      mat-icon-button
      (click)="onEdit()"
      data-tracking="edit-document-name"
    >
      <mat-icon color="primary">edit</mat-icon>
    </button>
  </div>

  <!-- display inline editor -->
  <div
    *ngIf="editing"
    fxLayout="row"
    class="focused"
  >
    <div fxFlex>
      <input
        #editor
        matInput
        fxFlex
        style="margin-left: 10px; font-weight: 700"
        value=""
        (blur)="onBlur()"
        [(ngModel)]="title"
        (keyup.enter)="onCloseEdit(true)"
        (keydown.escape)="onCloseEdit(false)"
      />
      <div
        matSuffix
        fxLayout="row"
      >
        <button
          class="inline-button"
          appearance="tiny"
          matTooltip="Cancel"
          tupMatButton
          (click)="onCloseEdit(false)"
          mat-icon-button
          matSuffix
        >
          <mat-icon color="primary">close</mat-icon>
        </button>
        <button
          class="inline-button"
          appearance="tiny"
          matTooltip="Ok"
          tupMatButton
          (click)="onCloseEdit(true)"
          mat-icon-button
          matSuffix
        >
          <mat-icon color="primary">check</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
