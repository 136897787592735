import { Pipe, PipeTransform } from '@angular/core';
import { FlatTreeNode, TreeTableColumn } from './tree-table.models';

@Pipe({
  name: 'cellEditor',
})
export class CellEditorPipe implements PipeTransform {
  transform(
    column: TreeTableColumn,
    rowEditable: { [columnDef: string]: boolean } | undefined
  ): boolean {
    return rowEditable && typeof rowEditable[column.columnDef] === 'boolean'
      ? rowEditable[column.columnDef]
      : column.editable;
  }
}
