import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TreeTableColumn } from '../tree-table.models';
import { ColumnGroupComponent } from '../column-group/column-group.component';

@Component({
  selector: 'column-groups',
  templateUrl: './column-groups.component.html',
  styleUrls: ['./column-groups.component.scss'],
})
export class ColumnGroupsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() defaultColumnWidth: number = 0;
  @Input() treeWidth: string = '';
  @Input() columns: TreeTableColumn[];

  anyGroups: boolean;
  treeWidthValue: number = 0;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const columns = changes['columns'];
    if (columns && columns.previousValue !== columns.currentValue) {
      this.anyGroups = !!this.columns.find((col) => col.group);

      // For each collpased column, set to hidden all the other columns from the same group
      this.columns.forEach((column) => {
        if (column.collapsed) {
          const columnsWithSameGroupName = this.columns.filter(
            (col) => col.group && col.group.name === column.group.name
          );

          columnsWithSameGroupName.forEach((filteredCol) => {
            if (!filteredCol.collapsed) {
              filteredCol.hidden = true;
            }
          });

          //set the group to be hidden if the column is collapsed
          column.group.hidden = column.collapsed;
        }
      });
    }

    const treeWidth = changes['treeWidth'];
    if (treeWidth && treeWidth.previousValue !== treeWidth.currentValue) {
      this.treeWidthValue = parseInt(this.treeWidth.replace(/\D/g, '') || '0');
    }
  }

  columnWidth(column: TreeTableColumn) {
    const width = column.width || this.defaultColumnWidth;
    if (column.group) {
      const columnGroupWidth =
        column.group.count > 0 ? column.group.count * width + 2 : 0;

      return column.group.hidden ? 33 : columnGroupWidth;
    } else {
      return width;
    }
  }
}
