<tup-mat-title-bar title="{{ getDialogTitle() }}">
  <span fxFlex></span>
  <button
    mat-icon-button
    tupMatButton
    appearance="tiny"
    (click)="onClose(false)"
  >
    <mat-icon>clear</mat-icon>
  </button>
</tup-mat-title-bar>

<mat-dialog-content class="container">
  <div class="selection-container">
    <ng-container [ngSwitch]="data.action">
      <ng-container *ngSwitchCase="0">
        <ng-container *ngTemplateOutlet="saveTemplate"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="1">
        <ng-container *ngTemplateOutlet="saveTemplate"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <ng-container *ngTemplateOutlet="addTemplate"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <ng-container *ngTemplateOutlet="moveTemplate"></ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!-- template for saving media groups -->
  <ng-template #saveTemplate>
    <div class="snackbar-generic-container">
      <snackbar-generic
        *ngIf="duplicateGroupName !== ''"
        [data]="duplicateError"
      ></snackbar-generic>
    </div>

    <div class="snackbar-generic-container">
      <snackbar-generic
        *ngIf="data.action !== 0 && data.vehicles.length === 1"
        [data]="lastVehicleWarning"
      ></snackbar-generic>
    </div>
    <mat-form-field
      class="full-width"
      appearance="outline"
      tupMatFormField
    >
      <mat-label>Name of group</mat-label>
      <input
        #groupName
        matInput
        type="text"
        (keyup)="onKeyUp()"
        [(ngModel)]="data.name"
        data-tracking="group-name-input"
      />
    </mat-form-field>

    <!-- vehicle selection -->
    <ng-container *ngTemplateOutlet="vehicleList"></ng-container>
  </ng-template>

  <!-- template for editing an existing media group -->
  <ng-template #addTemplate>
    <div
      class="selected-vehicle"
      *ngIf="data.vehicles.length === 1"
    >
      <div class="label">Channel/Brand/Device</div>
      <b class="vehicle">{{ data.vehicles[0].name }}</b>
    </div>
    <div class="snackbar-generic-container">
      <snackbar-generic
        *ngIf="showWarningMessageOnAddAction"
        [data]="lastVehicleWarning"
      ></snackbar-generic>
    </div>
    <!-- groups dropdown -->
    <mat-form-field
      appearance="outline"
      class="regular-select full-width"
      tupMatFormField
    >
      <mat-label>Select group</mat-label>
      <mat-select [(ngModel)]="currentGroup">
        <mat-option
          *ngFor="let group of data.groups"
          [value]="group.id"
        >
          {{ group.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="data.vehicles.length > 1">
      <ng-container *ngTemplateOutlet="vehicleList"></ng-container>
    </div>
  </ng-template>

  <!-- template for moving a vehicle -->
  <ng-template #moveTemplate>
    <div class="snackbar-generic-container">
      <snackbar-generic
        *ngIf="data.action === 3 && originalVehicleGroup.vehicles.length === 1"
        [data]="lastVehicleWarning"
      ></snackbar-generic>
    </div>
    <div class="selected-vehicle">
      <div class="label">Channel/Brand/Device</div>
      <b class="vehicle">{{ data.vehicles[0].name }}</b>
    </div>
    <!-- groups dropdown -->
    <mat-form-field
      appearance="outline"
      class="regular-select full-width"
      tupMatFormField
    >
      <mat-label>Select group</mat-label>
      <mat-select [(ngModel)]="currentGroup">
        <mat-option
          *ngFor="let group of data.groups"
          [value]="group.id"
        >
          {{ group.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
</mat-dialog-content>

<ng-template #vehicleList>
  <div class="vehicle-list-container">
    <div
      class="vehicle-list-item"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      *ngFor="let vehicle of data.vehicles"
    >
      {{ vehicle.name }}
      <button
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        (click)="onRemoveVehicle(vehicle)"
        [disabled]="data.vehicles.length === 1 && data.action === 0"
        [matTooltip]="
          data.action === 0 ? 'Remove from group' : 'Remove from selection'
        "
      >
        <mat-icon color="primary">delete_forever</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<mat-dialog-actions
  class="app-dialog-actions"
  fxLayoutAlign="space-between center"
>
  <button
    mat-stroked-button
    tupMatButton
    (click)="onClose(false)"
    data-tracking="cancel-btn"
  >
    Cancel
  </button>

  <button
    mat-flat-button
    tupMatButton
    color="primary"
    (click)="onClose(true)"
    data-tracking="submit-btn"
    [disabled]="duplicateGroupName !== ''"
  >
    {{ getSaveButtonText() }}
  </button>
</mat-dialog-actions>
