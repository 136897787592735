<span (contextmenu)="onContextMenu($event)">
  <span
    style="visibility: none; position: fixed"
    [matMenuTriggerFor]="rightMenu"
    [style.left.px]="menuTopLeftPosition.x"
    [style.top.px]="menuTopLeftPosition.y"
  >
  </span>
  <ng-content></ng-content>
</span>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template
    matMenuContent
    let-item="item"
  >
    <button
      mat-menu-item
      *ngFor="let menuItem of menu"
      [disabled]="menuItem.disabled"
      (click)="onClick(menuItem)"
    >
      <mat-icon *ngIf="menuItem.matIcon">{{ menuItem.matIcon }}</mat-icon>
      {{ menuItem.label }}
    </button>
  </ng-template>
</mat-menu>
