<div
  class="table-container"
  [dndDropzone]
  (dndDrop)="onDragDrop($event)"
  (dragenter)="onDragEnter($event)"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  [ngClass]="{
    'no-border-bottom': dataSource.length === 0 && allowNoDataPanel
  }"
>
  <mat-table
    class="audience-codebook-table"
    multiTemplateDataRows
    cdkDropList
    (ctrlShift)="onCtrlShift($event)"
    [cdkDropListDisabled]="dragDisabled"
    [dataSource]="tableDataSource"
    (cdkDropListDropped)="onTableItemDragAndDrop($event)"
  >
    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.columnDef"
    >
      <mat-header-cell
        [ngClass]="{
          hidden: column.hidden,
          'mat-col-string': column.type == 'string'
        }"
        *matHeaderCellDef
        style="padding: 0; padding-left: 0.8em"
        class="column-header"
      >
        <!-- add the select all checkbox over the action menu column -->
        <div *ngIf="column.type === 'action' && showActions">
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleAllRows() : null"
            [checked]="isAllSelected()"
            [indeterminate]="isAllSelected(true)"
          >
          </mat-checkbox>

          <span *ngIf="!showHeaderMenu">&nbsp;&nbsp;&nbsp;</span>

          <button
            *ngIf="showHeaderMenu"
            mat-icon-button
            [matMenuTriggerFor]="headerActionsMenu"
            (click)="onHeaderMenuOpened()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <mat-menu #headerActionsMenu="matMenu">
          <button
            *ngFor="let menuItem of headerMenu"
            (click)="menuItem.disabled ? null : onHeaderMenuClick(menuItem)"
            [ngClass]="{ 'disabled-option': menuItem.disabled }"
            [matTooltip]="menuItem.tooltip || ''"
            mat-menu-item
          >
            <mat-icon
              *ngIf="menuItem.matIcon"
              class="header-menu-icon material-symbols-outlined"
              >{{ menuItem.matIcon }}</mat-icon
            >
            {{ menuItem.label }}
          </button>
          <button
            [matMenuTriggerFor]="combineSelectedMenu"
            mat-menu-item
            [disabled]="combineSelectedDisabled"
          >
            <mat-icon class="header-menu-icon material-symbols-outlined">
              join
            </mat-icon>
            Combine selected
          </button>
        </mat-menu>
        <mat-menu #combineSelectedMenu="matMenu">
          <button
            *ngFor="let combineOption of combineOptions"
            (click)="onHeaderMenuClick(combineOption)"
            mat-menu-item
          >
            {{ combineOption.label }}
          </button>
        </mat-menu>
        <div
          fxLayout="column"
          fxLayoutAlign="start end"
        >
          <div>{{ column.header0 }}</div>
          <div>{{ column.header1 }}</div>
        </div>
      </mat-header-cell>
      <mat-cell
        [ngClass]="{
          hidden: column.hidden,
          'mat-col-string': column.type == 'string'
        }"
        *matCellDef="let row; let i = dataIndex"
        style="padding: 0.8em 0 0.8em 0.8em"
      >
        <ng-container
          cdkDrop
          [ngSwitch]="column.type"
        >
          <ng-container *ngSwitchCase="'action'">
            <ng-container
              *ngTemplateOutlet="
                actionTemplate;
                context: { row: row, column: column, index: i }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'value'">
            <ng-container
              *ngTemplateOutlet="
                valueTemplate;
                context: { row: row, column: column, index: i }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'string'">
            <ng-container
              *ngTemplateOutlet="
                stringTemplate;
                context: { row: row, column: column, index: i }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'boolean'">
            <ng-container
              *ngTemplateOutlet="
                checkboxTemplate;
                context: { row: row, column: column, index: i }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container
              *ngTemplateOutlet="
                stringTemplate;
                context: { row: row, column: column, index: i }
              "
            ></ng-container>
          </ng-container>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let row; let i = dataIndex"
        style="padding: 5px 0.8em 0.8em"
        [attr.colspan]="displayedColumns.length"
        class="fullWidth"
      >
        <div
          class="visualcodebook-container element-edit"
          [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'"
        >
          <div *ngIf="row == expandedElement">
            <visual-code-builder
              #visualCodeBuilder
              [target]="visualEditingTarget"
              [dropChipWidth]="dropChipWidth"
              (targetChange)="onVisualEditorTargetChanged($event)"
              (manualCodingClick)="onManualCodingClick($event)"
              (newManualCodingClick)="onNewManualCodingClick($event)"
              (cleanupVCBDataAfterDestroy)="onVCBDestroy()"
              (dropNode)="onVisualEditingDropNode($event)"
              [layout]="visualEditorLayout"
            ></visual-code-builder>

            <div
              *ngIf="showConfirmButtons"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="8px"
            >
              <button
                tupMatButton
                mat-button
                class="cancel btn-action"
                (click)="onVisualEditorCloseClick(i)"
                appearance="medium"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                tupMatButton
                color="primary"
                class="btn-action"
                [disabled]="hasMultiSurveyAudience && vcbUpdateBtnDisabled"
                (click)="onVisualEditorSaveClick(row, i)"
                appearance="medium"
              >
                Update audience
              </button>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true; let i = index"
      class="column-header"
    ></mat-header-row>

    <mat-row
      cdkDrag
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        'target-editing': row === expandedElement || row.rename,
        selected: row.selected,
        error: row.error,
        row: true
      }"
      [attr.row-index]="row.id"
    ></mat-row>
    <mat-row
      [class]="element != expandedElement ? 'hidden' : 'target-editing'"
      *matRowDef="let element; columns: ['expandedDetail']"
    >
    </mat-row>
  </mat-table>
  <div
    *ngIf="allowNoDataPanel && dataSource.length === 0"
    class="table-with-no-data-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="8px"
  >
    <mat-icon>groups</mat-icon>
    <p>Select items from the builder on the left to add to your audiences.</p>
  </div>
  <div
    *ngIf="!expandedElement"
    class="drop-area-container"
  >
    <span class="drop-code-text"
      >DROP CODE ITEMS HERE<strong>&nbsp;OR&nbsp;</strong></span
    >
    <span
      class="drop-code-manual-click drop-code-text"
      (click)="onManualCoding()"
    >
      CLICK TO ADD OWN CODE
    </span>
  </div>
</div>

<!-- action menu template -->
<ng-template
  #actionTemplate
  let-row="row"
  let-column="column"
  let-index="index"
>
  <mat-checkbox
    *ngIf="showActions && !row.target.isMultiSurvey"
    color="primary"
    (change)="onCheckboxChange(row, column.columnDef, $event.checked)"
    [checked]="row.selected"
    [(ngModel)]="row.selected"
  >
  </mat-checkbox>

  <button
    *ngIf="showActions"
    mat-icon-button
    [matMenuTriggerFor]="actionsMenu"
    class="action-menu-row"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #actionsMenu="matMenu">
    <button
      *ngFor="let menuItem of audienceMenu"
      (click)="onActionItem(index, row, menuItem.data)"
      [disabled]="
        menuItem.disabled ||
        (row.target.isMultiSurvey && menuItem.disabledForMultiSurvey)
      "
      mat-menu-item
    >
      <mat-icon
        *ngIf="menuItem.matIcon"
        class="header-menu-icon material-symbols-outlined"
        >{{ menuItem.matIcon }}
      </mat-icon>
      {{ menuItem.label }}
    </button>
  </mat-menu>
  <cell-edit
    *ngIf="row.rename"
    [value]="column.cell(row)"
    [VCBTitleEditor]="!!row.VCBTitleEditor"
    [defaultTitle]="row.target.ownTitle"
    cellType="string"
    align="left"
    [(editing)]="row.rename"
    (edited)="onSave(column, row, index, $event)"
    (editTitle)="onEditTitle(column, row, index, $event)"
    style="width: 100%"
  ></cell-edit>
  <span
    *ngIf="!row.rename"
    [matTooltip]="row.target.ownTitle"
  >
    {{ column.cell(row) }}
  </span>
</ng-template>

<!-- value template -->
<ng-template
  #valueTemplate
  let-row="row"
  let-column="column"
  let-index="index"
>
  <div *ngIf="row.target.population === -1">
    <mat-progress-spinner
      style="margin: 0 auto; padding: 0"
      mode="indeterminate"
      diameter="17"
    >
    </mat-progress-spinner>
  </div>

  <!--  make population editable when only one target is added to audience table -->
  <div
    *ngIf="
      dataSource.length === 1 &&
      row.target.population !== -1 &&
      column.columnDef === 'popn'
    "
    class="population-container"
  >
    <cell-edit
      *ngIf="column.columnDef === 'popn'"
      [value]="column.cell(row) | number : '1.0-0'"
      cellType="number"
      align="right"
      [menu]="column.menu"
      (itemClick)="onPopulationMenuClick(index, $event)"
      (edited)="onPopulationEdited(index, row, $event)"
      style="width: 100%"
      class="editable-population-cell"
    ></cell-edit>
    <mat-icon
      *ngIf="row.target.customPopulation"
      class="editable-population-info-icon"
      >error</mat-icon
    >
  </div>

  <span *ngIf="column.columnDef !== 'popn' || dataSource.length > 1">
    {{ column.cell(row) | number : "1.0-0" }}
  </span>
</ng-template>

<!-- checkbox (boolean) template -->
<ng-template
  #checkboxTemplate
  let-row="row"
  let-column="column"
>
  <mat-checkbox
    color="primary"
    [checked]="column.cell(row)"
    [disabled]="
      hasMultiSurveyAudience
        ? row.target.addressableTarget &&
          (!row.target.isMultiSurvey || !!row.target.customPopulation)
        : row.target?.survey?.code &&
          primarySurvey?.code &&
          row.target.survey.code !== primarySurvey.code
    "
    [matTooltip]="
      row.target?.survey?.code &&
      primarySurvey?.code &&
      row.target.survey.code !== primarySurvey.code
        ? NOT_PRIMARY_SURVEY
        : ''
    "
    (change)="onCheckboxChange(row, column.columnDef, $event.checked)"
  ></mat-checkbox>
</ng-template>

<!-- string (and default) template -->
<ng-template
  #stringTemplate
  let-row="row"
  let-column="column"
>
  <span [matTooltip]="column.tooltip ? column.tooltip(row) : column.cell(row)">
    {{ column.cell(row) }}
  </span>
</ng-template>
