import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { MatRadioChange } from '@angular/material/radio';
import { UserContainer } from '@telmar-global/tup-auth';
import { TupUserContainerSelectorComponent } from '@telmar-global/tup-document-storage';
import _ from 'lodash';

export class StartPlanningDialogModel {
  selected: string = 'new'; //  continue | new
  title: string;
  description: string;
  action: string;
  container: UserContainer;
  allowPickup: boolean;
  dialogTitle: String;
  constructor() {}
}

@Component({
  selector: 'app-start-planning-dialog',
  templateUrl: './start-planning-dialog.component.html',
  styleUrls: ['./start-planning-dialog.component.scss'],
})
export class StartPlanningDialogComponent implements OnInit, AfterViewInit {
  campaignOptions = [
    { name: 'Start new campaign', value: 'new', disabled: false },
    { name: 'Pick up where you left off', value: 'continue', disabled: false },
  ];
  selectedOption: string = this.campaignOptions[0].value;
  dialogTitle: String;
  disabledInputText: string =
    'This option is disabled as you are proceeding with a campaign you have already started.';

  @ViewChild('documentName') documentName: ElementRef;
  @ViewChild(TupUserContainerSelectorComponent, { static: true })
  public userContainerSelector: TupUserContainerSelectorComponent;

  public container: UserContainer;

  constructor(
    public dialogRef: MatDialogRef<StartPlanningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StartPlanningDialogModel
  ) {
    dialogRef.disableClose = true;
    this.selectedOption = this.data.selected;
    this.dialogTitle = this.data.dialogTitle || 'New campaign';
    if (!data.allowPickup)
      this.campaignOptions.find(
        (option) => option.value === 'continue'
      ).disabled = true;
  }

  get documentTitle() {
    return this.data.title;
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.focusDocumentName();
    this.container = this.userContainerSelector.container;
  }

  public selectionChange(event: MatSelectionListChange): void {
    this.container = event.option.value;
  }

  onChange(change: MatRadioChange) {
    this.focusDocumentName();
  }

  private focusDocumentName() {
    setTimeout(() => {
      this.selectedOption === 'new' && this.documentName
        ? this.documentName.nativeElement.focus()
        : {};
    }, 500);
  }

  onClose(action: string) {
    this.data.selected = this.selectedOption;
    this.data.action = action;
    this.data.container = this.container;
    this.dialogRef.close(this.data);
  }
}
