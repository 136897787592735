import AWS_RESOURCES from './aws-resources.json';

import {
  TupDocumentStatus,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import { TupLogLevel } from '@telmar-global/tup-logger-angular';

export const environment = {
  production: false,

  // key used by api service so the api key used in calls can differ from the login key
  engines: {
    tupApiKey: 'Vyje8Vcl9b7G7fRnyw2m02o3CRKMc9sM3DT4u933',
  },
  analytics: {
    google: {
      use: false,
      url: 'https://www.googletagmanager.com/gtag/js',
      trackingId: 'G-B1DMWZRD94', // local testing dashboard
    },
    hotjar: {
      use: false,
      url: undefined,
      trackingId: undefined,
    },
    productFruits: {
      workspaceCode: 'mVY9P1cyvV82oVup',
      language: 'en',
    },
  },

  // login
  tupApiKey: 'Vyje8Vcl9b7G7fRnyw2m02o3CRKMc9sM3DT4u933',
  tupAuth: {
    loginLocation: '/login',
    defaultLocation: '/',
    appName: 'CrossMediaPlanner',

    // APP level clearance
    notAuthorizedLocation: '/404',
    notFoundLocation: '/404',
    platformHomepage: {
      url: 'https://dev.platform.telmar.com',
      redirects: {
        '/404': 10,
      },
    },
  },

  amplifyAuth: {
    region: AWS_RESOURCES.REGION,
    userPoolId: AWS_RESOURCES.COGNITO_USER_POOL_ID,
    userPoolWebClientId: AWS_RESOURCES.COGNITO_USER_POOL_CLIENT_ID,
    //
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: AWS_RESOURCES.WEBHOSTING_DOMAIN,
      expires: 365,
      secure: true,
    },
    // clientMetadata: { myCustomKey: 'myCustomValue' },  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  },

  tupCrmApi: {
    urls: {
      interface: 'https://api.telmar.com/tup-crm/v1/crm/interface',
    },
  },

  // in app options
  appOptions: {
    platformHomepage: 'https://dev.platform.telmar.com',
  },

  // engine endpoints
  api: {
    codebook: {
      url: 'https://stage.engines.api.telmar.com/codebook/plan/stage/v4',
      endPoint: {
        getCalculationParameters: '/getcalculationparameters',
        getMediaTypesId: '/getmediatypesid',
        getSurveys: '/getsurveys',
        getSurvey: '/getsurvey',
        vehicleNavigation: '/vehiclenavigation',
        codebookNavigation: '/codebooknavigation',
        getCoreDemoset: '/getcoredemoset',
        search: '/search',
      },
    },
    cmp: {
      url: 'https://stage.engines.api.telmar.com/plan/stage/v9',
      endPoint: {
        getMultiTargetEvaluation: '/multitargetsevaluation',
        getAudiencesForTargetSet: '/getaudiencefortargetset',
        getAudiencesForTargetSetMultisurvey:
          '/getaudiencefortargetsetmultisurvey',
        evaluateMediaPlan: '/evaluatemediaplan',
        evaluateMultiMediaPlan: '/evaluatemultimediaplan',
        evaluateMultiSurveyPlan: '/evaluatemultisurveyplan',
        getNaturalDeliverySchedule: '/getnaturaldeliveryschedule',
        getNaturalDeliveryScheduleMultisurvey:
          '/getnaturaldeliveryschedulemultisurvey',
        createMrfFile: '/createmrffile',
        getoptimisedschedule: '/getoptimisedschedule',
        getasyncresults: '/getasyncresults',
      },
    },
    websocket: {
      url: 'https://stage.engines.api.telmar.com/ws-info/stage/v1',
      endPoint: {
        websocketurl: '/websocketurl',
      },
    },
    coding: {
      url: 'https://stage.engines.api.telmar.com/coding/stage/v1',
      endPoint: {
        getTargetCoding: '/user/targetcoding',
      },
    },
    surveyInfo: {
      url: 'https://stage.engines.api.telmar.com/survey-info/stage/v2',
      endPoint: {
        getSurveyMetadata: '/getsurveymetadata',
      },
    },
  },

  tupDocumentStorage: {
    baseUrl: 'https://6awq45bcp5.execute-api.us-east-1.amazonaws.com/prd',
    paths: {
      documents: '/documents',
    },
    options: {
      // Configure navigation between apps / Modules (only required on the platform hompage)
      applications: [],
      types: [TupDocumentTypeId.CMP_CAMPAIGN],
      statuses: [TupDocumentStatus.ACTIVE, TupDocumentStatus.ARCHIVED],
      reloadPeriodInMs: 30 * 1000,
      appearance: 'tup-material',
      actionsMenuTrigger: 'stroked',
      pageIndex: 0,
      pageSize: 50,
      tags: {
        showBadges: false, // set to true when UI is fixed in the library
        suggested: ['Client', 'Project'],
      },
    },
  },

  tupUserPreferenceStorage: {
    appSyncClientOptions: {
      region: 'us-east-1',
      graphqlEndpoint:
        'https://566u6iatc5bctla7v72ekfujt4.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },

  tupLogger: {
    logLevel: TupLogLevel.DEBUG,
  },

  tupAudienceGroups: {
    documentTypes: [TupDocumentTypeId.OWN_CODES_TARGET],
    status: [TupDocumentStatus.ACTIVE, TupDocumentStatus.ARCHIVED],
  },

  googleApi: {
    clientId:
      '269176030796-an367pn9tec4p5sk795kabkh9j4hp2dh.apps.googleusercontent.com',
    apiKey: 'AIzaSyD0w6xvezia2W6VUBJ8U7hvheiY6-vhCAA',
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    ],
    scope: 'https://www.googleapis.com/auth/drive.file',
  },

  googleDrive: {
    folder: {
      name: 'cross-media-planner',
    },
  },

  userPreferences: {
    lastTenSurveysUsed: 'cross-media-planning.last-ten-surveys-used',
    selectedSurveysUsed: 'cross-media-planning.selected-surveys-used',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
