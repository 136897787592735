import { TargetVehicle } from './vehicle';

export class ReachFreqParameters {
  parameters: CalculationParameter[];
  percentageInserts: string[] = [];
  ready: boolean = false;

  isPercentageInserts(vehicle: TargetVehicle): boolean {
    return this.percentageInserts.includes(vehicle.calculationMethod);
  }

  isMediaGroup(vehicle: TargetVehicle): boolean {
    return vehicle.calculationMethod === 'composedVehicle';
  }
}

export interface CalculationParameter {
  calculationMethod: string;
  name: string;
  type: string;
}
