import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthEvent, TupAuthService } from '@telmar-global/tup-auth';
import { BaseStepComponent } from '../base-step/base-step.component';
import { Router } from '@angular/router';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'briefing-step',
  templateUrl: './briefing-step.component.html',
  styleUrls: ['./briefing-step.component.scss'],
})
export class BriefingStepComponent extends BaseStepComponent {
  briefObjectiveOptions: string[] = [
    'Awareness',
    'Conversion',
    'Consideration',
  ];
  KPIOptions: string[] = [
    'Reach',
    'Frequency',
    'GRPs',
    'Sales',
    'Sales Conversions',
    'CPM',
    'Other',
  ];

  campaignName = new FormControl();
  advertiserName = new FormControl();
  plannerName = new FormControl();
  startDate = new FormControl();
  campaignId = new FormControl();

  briefObjective = new FormControl();
  KPI1 = new FormControl();
  KPI2 = new FormControl();
  KPI3 = new FormControl();
  flightDateStart = new FormControl();
  flightDateEnd = new FormControl();
  budget = new FormControl();
  constraints = new FormControl();
  comments = new FormControl();

  constructor(
    public fb: FormBuilder,
    public mediaplannerService: MediaPlannerService,
    private authService: TupAuthService
  ) {
    super();
  }

  ngOnInit(): void {}

  saveData(): Observable<boolean> {
    super.saveData();
    return of(true);
  }

  loadData() {
    super.loadData('briefing');

    // prepare settings from service
    this.campaignName.setValue(
      this.mediaplannerService.plan.brief.campaignName
    );
    this.advertiserName.setValue(
      this.mediaplannerService.plan.brief.advertiserName
    );
    this.plannerName.setValue(this.mediaplannerService.plan.brief.plannerName);
    this.startDate.setValue(this.mediaplannerService.plan.brief.startDate);

    this.campaignId.setValue(this.mediaplannerService.plan.brief.campaignId);

    this.briefObjective.setValue(
      this.mediaplannerService.plan.brief.briefObjective
    );
    this.KPI1.setValue(this.mediaplannerService.plan.brief.KPI1);
    this.KPI2.setValue(this.mediaplannerService.plan.brief.KPI2);
    this.KPI3.setValue(this.mediaplannerService.plan.brief.KPI3);
    this.flightDateStart.setValue(
      this.mediaplannerService.plan.brief.flightDateStart
    );
    this.flightDateEnd.setValue(
      this.mediaplannerService.plan.brief.flightDateEnd
    );
    this.budget.setValue(this.mediaplannerService.plan.brief.budget);
    this.constraints.setValue(this.mediaplannerService.plan.brief.constraints);
    this.comments.setValue(this.mediaplannerService.plan.brief.comments);
  }

  onClickNextPage() {
    this.navigation.emit(1);
  }
}
