<tup-mat-title-bar title="{{ dialogTitle }}">
  <span fxFlex></span>
  <button
    mat-icon-button
    tupMatButton
    appearance="tiny"
    (click)="onClose(false)"
  >
    <mat-icon>clear</mat-icon>
  </button>
</tup-mat-title-bar>

<breadcrumbs
  [breadcrumbsSteps]="breadcrumbsSteps"
  [currentStep]="breadcrumbsCurrentStep"
  (stepChanged)="onBreadCrumbsStepChange($event)"
></breadcrumbs>

<mat-dialog-content class="container target-editor-dialog">
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="24px"
  >
    <!-- left side. codebook tree -->
    <div
      fxFlex="0 0 405px"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <!-- tree content -->
      <div
        fxFlexFill
        fxLayout="column"
      >
        <!-- options section -->
        <div fxLayout="row">
          <!-- survey selection -->
          <survey-info-bar
            fxFlex="100"
            [disabled]="true"
            [hasSurveyInfo]="false"
            [hasSurveyDropdown]="true"
            [allowChangeSurvey]="false"
            [showManageSurvey]="false"
            [currentSurvey]="data.survey"
            [hasChildContent]="false"
          >
          </survey-info-bar>
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="start center"
        >
          <!-- tree search textbox -->
          <mat-form-field
            appearance="outline"
            tupMatFormField
            class="input-form-field-search"
          >
            <button
              matPrefix
              mat-icon-button
              matTooltip="Search"
              (click)="onSearchClick(treeSearch)"
              class="search-btn"
            >
              <mat-icon *ngIf="!searching">search</mat-icon>
              <processing
                *ngIf="searching"
                diameter="20"
              ></processing>
            </button>
            <input
              matInput
              type="text"
              placeholder="Search"
              [(ngModel)]="treeSearch"
              (keyup.enter)="onSearchClick(treeSearch)"
            />
            <!-- advanced search options -->
            <simple-selection
              matSuffix
              display="button"
              [topOption]="advancedSearchOptions.titleCodingToggle"
              [options]="advancedSearchOptions.options"
              [multi]="false"
              (dropdownToggle)="onAdvancedSearchOptionChange($event)"
              buttonIcon="tune"
              matTooltip="Advanced search"
            >
            </simple-selection>
          </mat-form-field>

          <!-- option -->
          <button
            mat-stroked-button
            tupMatButton
            appearance="tiny"
            color="primary"
            matTooltip="Reset"
            (click)="resetSearch()"
            class="reset-btn"
          >
            <mat-icon color="primary">autorenew</mat-icon>
          </button>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="8px"
        >
          <div
            fxFlex="100%"
            fxLayout="column"
          >
            <!-- category dropdown -->
            <mat-form-field
              appearance="outline"
              class="select-small"
              tupMatFormField
            >
              <mat-select
                [(ngModel)]="currentCategory"
                (ngModelChange)="onCategoryChange($event)"
                class="select-small codebook-category"
              >
                <mat-option
                  *ngFor="let category of categories"
                  class="category-text"
                  [value]="category"
                  >{{ category }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <!-- operator buttons -->
          <button
            mat-stroked-button
            tupMatButton
            *ngFor="let operator of operators"
            class="operator-btn {{ operator.labelType }}"
            appearance="small"
            color="primary"
            [matTooltip]="operator.toolTip"
            (click)="onOperatorClick(operator)"
          >
            <ng-container *ngIf="operator.labelType === 'string'">
              {{ operator.label }}
            </ng-container>
            <mat-icon
              *ngIf="operator.labelType === 'icon' && operator.label === 'join'"
              class="material-symbols-outlined"
              [matMenuTriggerFor]="menu"
            >
              {{ operator.label }}
            </mat-icon>
            <mat-icon
              *ngIf="operator.labelType === 'icon' && operator.label !== 'join'"
            >
              {{ operator.label }}
            </mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              *ngFor="let booleanLogicOption of booleanLogicOptions"
              (click)="onBooleanLogicOperatorClick(booleanLogicOption)"
            >
              {{ booleanLogicOption | uppercase }}
            </button>
          </mat-menu>
        </div>

        <!-- tree -->
        <div
          class="tree-container"
          #treeContainer
        >
          <div
            *ngIf="treeData.length > 0"
            class="clear-all"
            (click)="unSelectAll()"
          >
            <span matTooltip="Unselect all"> Clear selection</span>
          </div>

          <tree-table
            class="audience-tree-table"
            [textOnMultipleLines]="true"
            #codingTree
            cdkDropList
            [hidden]="loadingAudiences"
            [(selectedNodes)]="preSelectedNodes"
            (selectedNodesChanged)="onSelectedNodesChanged($event)"
            treeWidth="auto"
            [data]="treeData"
            [disableDragging]="false"
            (expand)="OnTreeExpand($event)"
            (nodeDblClick)="onDoubleClick($event)"
            (treeNodeMenuClick)="onTreeNodeMenuClick($event)"
          ></tree-table>
        </div>
      </div>
    </div>

    <!-- right hand side - selected coding -->
    <div
      fxFlex="100%"
      class="xxcodebook-table-container"
    >
      <!-- style="height: calc(100vh - 108px)" -->
      <div
        fxLayout="column"
        style="height: calc(65vh - 20px); overflow: auto"
      >
        <div
          fxFlex
          style="padding: 0"
        >
          <codebook-table
            #codebookTable
            class="codebook-table-container"
            [unitsText]="unitsText"
            [showConfirmButtons]="false"
            [showHeaderMenu]="false"
            [showActions]="false"
            [dropChipWidth]="'calc(70vw - 560px)'"
            [(dataSource)]="targetStatements"
            [primarySurvey]="data.primarySurvey"
            (manualCoding)="onManualCoding()"
            (manualCodingClick)="onManualCodingClick($event, false)"
            (newManualCodingClick)="onManualCodingClick($event, true)"
            (targetsChanged)="onTargetsChanged($event)"
            [surveyHasAddressable]="surveyHasAddressable"
            (drop)="onDropCodebookItem($event)"
            (targetHasChanged)="onVisualEditorTargetChanged($event)"
            (dropNode)="onVisualEditingDropNode($event)"
            [visualEditingTarget]="visualEditingTarget"
            visualEditorLayout="full"
            fxFlex
            (populationMenuClick)="onPopulationMenuClick($event)"
            (populationEdited)="onPopulationEdited($event)"
          ></codebook-table>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-divider class="divider"></mat-divider>

<mat-dialog-actions
  class="app-dialog-actions"
  fxLayoutAlign="space-between center"
  fxLayoutGap="20px"
>
  <button
    mat-button
    tupMatButton
    appearance="small"
    (click)="onClose(false)"
    data-tracking="edit-audience-cancel-btn"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    tupMatButton
    appearance="small"
    color="primary"
    [disabled]="!visualEditingTarget || !visualEditingDocumentTarget.coding"
    (click)="onClose(true)"
    data-tracking="edit-audience-save-btn"
  >
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
