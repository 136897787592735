import { DocumentAudienceGroup } from '@telmar-global/tup-audience-groups';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { cloneDeep } from 'lodash';

export enum DisplayMode {
  title = 0,
  code = 1,
}

export enum DisplayType {
  title = 'title',
  shortTitle = 'shortTitle',
  ownTitle = 'ownTitle',
  coding = 'coding',
  levels = 'levels',
  group = 'group',
}

export enum Operator {
  or = 'OR',
  and = 'AND',
  andNot = 'AND NOT',
  orNot = 'OR NOT',
  separate = 'SEPARATE',
  count = 'COUNT',
  auto = 'AUTO', // note: is actually OR operator
  plus = '+',
  greaterThan = '>',
  greaterThanOrEqual = '>=',
  lessThan = '<',
  lessThanOrEqual = '<=',
  equal = '=',
  notEqual = '<>',
  range = 'RAN',
  any = 'ANY',
  all = 'ALL',
  solus = 'SOLUS',
  sum = 'SUM',
}

// used by codebook
export interface Statement {
  description: string;
  path: string;
  coding?: string;
  pos?: number;
  children?: Statement[];
  selected?: boolean; // added for codebook navigation (instead of mapping to a new object)
  hiddenCategory?: boolean; // added for codebook navigation (instead of mapping to a new object)
  hiddenFilter?: boolean; // added for codebook navigation (instead of mapping to a new object)
  isCustomCoding?: boolean;
  shouldHighlight?: boolean;
  canRefresh?: boolean;
  canEditTitle?: boolean;
  shouldDisableAdding?: boolean;
  category?: string;
  customData?: {
    index: number;
    jsonTarget: string;
    document?: TupDocument<DocumentAudienceGroup>;
  };
}

export interface CountCodingModel {
  operator: Operator;
  value: string;
}

export interface VisualCodingTarget {
  id: string;
  fileVersion: number;
  title: string;
  titleLevels?: number[];
  shortTitle?: string;
  ownTitle?: string;
  activeTitleMode?: DisplayType;
  manual: boolean;
  titlePrefix?: Operator; // note: only for solus operator and the target cannot be expanded
  coding: string;
  countCoding?: {
    operator: Operator;
    value: string;
  };
  operator: Operator;
  created: number;
  audience?: number;
  resps?: number;
  percent?: number;
  targets: VisualCodingTarget[];
  groupName?: string;
}

export enum CodebookNavigationType {
  codebook = 0,
  workspace = 1,
}

export const levelSeparator = '~>';

export const titleSeparator = '~';

export const SURVEYTIME_TARGET_VERSION = 1;

export const MAX_COMBINED_TARGETS = 20;

export interface DropDataContext<T> {
  selectedNodes: Statement[];
  selectedTargets?: VisualCodingTarget[];
  context: T;
  handleDropNode: (context: T, selectedNodes: Statement[]) => void;
}

export interface CreateTargetCallback {
  (options?: Partial<VisualCodingTarget>): VisualCodingTarget;
}

export interface StatementsToTargetsCallback {
  (statements: Statement[]): VisualCodingTarget[];
}

export interface UpdateTitleAndCodingCallback {
  (target: VisualCodingTarget): VisualCodingTarget;
}

export interface groupTargetsCallback {
  (
    targets: VisualCodingTarget[],
    operator: Operator,
    forceGrouping?: boolean
  ): VisualCodingTarget[];
}

export interface autoGroupTargetsCallback {
  (targets: VisualCodingTarget[]): VisualCodingTarget[];
}
export interface hasMoreThanMaxCombinedTargetsCallback {
  (targets: VisualCodingTarget[]): boolean;
}
export class TreeNodeSelection {
  nbChildren: number = 0;
  nbSelectedChildren: number = 0;
}
export class StatementFactory {
  static cloneData(
    data: Statement,
    ignoreChildren: boolean = false,
    selected?: boolean
  ): Statement {
    const copy: Statement = cloneDeep(data);
    if (selected !== undefined) {
      copy.selected = selected;
    }
    if (!ignoreChildren) {
      data.children?.forEach((child) => {
        copy.children.push(
          StatementFactory.cloneData(child, false, copy.selected)
        );
      });
    }

    return copy;
  }

  static getNodeFromTree(node: Statement, tree: Statement[]): Statement {
    for (let branch of tree) {
      if (node.path.startsWith(branch.path)) {
        if (node.path === branch.path) {
          return branch;
        }
        return StatementFactory.getNodeFromTree(node, branch.children);
      }
    }
    return null;
  }

  static getMissingNodes(
    nodes: Statement[],
    missing?: Statement[]
  ): Statement[] {
    if (!missing) {
      missing = [];
    }
    nodes.forEach((node) => {
      if (node.children.length === 0 && !node.coding) {
        missing.push(node);
      } else {
        StatementFactory.getMissingNodes(node.children, missing);
      }
    });
    return missing;
  }

  static getSelectedNodes(nodes: Statement[], selectedNodes?: Statement[]) {
    if (!selectedNodes) {
      selectedNodes = [];
    }
    nodes.forEach((node) => {
      if (node.children.length === 0) {
        // we are on a leaf
        if (node.selected) {
          selectedNodes.push(StatementFactory.cloneData(node, true));
        }
      } else {
        // check children
        let selectedChildren: Statement[] = [];
        StatementFactory.getSelectedNodes(node.children, selectedChildren);
        if (selectedChildren.length > 0) {
          let clone = StatementFactory.cloneData(node, true);
          clone.children = selectedChildren;
          selectedNodes.push(clone);
        }
      }
    });
    return selectedNodes;
  }

  static selectChildren(node: Statement) {
    if (node.children.length === 0) {
      node.selected = true;
    } else {
      node.selected = undefined;
      node.children.forEach((child) => {
        StatementFactory.selectChildren(child);
      });
    }
  }

  static unselectChildren(node: Statement) {
    if (node.children.length === 0) {
      node.selected = false;
    } else {
      node.selected = undefined;
      node.children.forEach((child) => {
        StatementFactory.unselectChildren(child);
      });
    }
  }

  static atLeastOneSelectedDescendant(data: Statement): boolean {
    if (data.children.length === 0) {
      return data.selected === true;
    }
    for (let child of data.children) {
      if (StatementFactory.atLeastOneSelectedDescendant(child)) {
        return true;
      }
    }
    return false;
  }

  static descendantsAllSelected(data: Statement): boolean {
    if (data.children.length === 0) {
      return data.selected === true;
    }
    for (let child of data.children) {
      if (!StatementFactory.descendantsAllSelected(child)) {
        return false;
      }
    }
    return true;
  }

  static descendantsPartiallySelected(
    data: Statement,
    selection?: TreeNodeSelection
  ): TreeNodeSelection {
    if (!selection) {
      selection = new TreeNodeSelection();
    }
    if (data.children.length === 0) {
      selection.nbChildren++;
      if (data.selected === true) {
        selection.nbSelectedChildren++;
      }
    }
    for (let child of data.children) {
      StatementFactory.descendantsPartiallySelected(child, selection);
      if (
        selection.nbSelectedChildren > 0 &&
        selection.nbChildren !== selection.nbSelectedChildren
      ) {
        break;
      }
    }
    return selection;
  }

  static getPath(node: Statement, nodes: Statement[]): Statement[] {
    for (let child of nodes) {
      if (child === node) {
        // found the node
        return [StatementFactory.cloneData(node, false)];
      }
      const path: Statement[] = StatementFactory.getPath(node, child.children);
      if (path.length > 0) {
        // found on this branch
        return [
          { description: child.description, path: child.path, children: path },
        ];
      }
    }
    return [];
  }

  static getParent(path: string): string {
    const paths: string[] = path.split('|');
    paths.pop();
    return paths.join('|');
  }
}
