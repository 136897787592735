import { Pipe, PipeTransform } from '@angular/core';
import { DocumentSurvey } from 'src/app/models/document.model';

@Pipe({
  name: 'FilterSurvey',
})
export class FilterSurveyPipe implements PipeTransform {
  transform(list: DocumentSurvey[], searchSurvey: string): unknown {
    if (list && searchSurvey) {
      return list.filter(
        (survey) =>
          survey.title.toLowerCase().indexOf(searchSurvey.toLowerCase()) >= 0
      );
    } else {
      return list;
    }
  }
}
