import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Survey,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import {
  TupDocument,
  TupDocumentEventEmitterDirective,
} from '@telmar-global/tup-document-storage';
import { TupUserPreferenceStorageService } from '@telmar-global/tup-user-preference-storage';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  TabStepperComponent,
  ViewMode,
} from 'src/app/components/tab-stepper/tab-stepper.component';
import {
  DocumentFullSurvey,
  DocumentOrigin,
  UNSAVED_DOCUMENT,
} from 'src/app/models/document.model';
import { AutoSaveService } from 'src/app/services/auto-save.service';
import { ChartSettingsService } from 'src/app/services/chart-settings.service';
import { CodebookService } from 'src/app/services/codebook.service';
import {
  LoadDocumentResult,
  LoadExploreReportResult,
  MediaPlannerService,
} from 'src/app/services/media-planner.service';
import { AudienceStepComponent } from 'src/app/steps/audience-step/audience-step.component';
import { DialogService } from '../../services/dialog.service';
import { BaseStepComponent } from '../../steps/base-step/base-step.component';
import { ExportFileType, ExportType } from 'src/app/models/export.model';
import {
  CampaignPreparationResponse,
  SurveyMetrics,
} from 'src/app/models/planning.models';
import {
  SurveyInfoDialogModel,
  SurveyInfoType,
} from 'src/app/components/dialogs/survey-info-dialog/survey-info-dialog.component';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { GAEvents } from '../../models/analytics.model';
import { Target } from 'src/app/classes/target';
import { HttpClient } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import {
  audienceActionType,
  dialogActionType,
  MixedDataDialogModel,
} from '../../components/dialogs/import-mixed-data-dialog/import-mixed-data-dialog.component';
import { MultiSurveyService } from '../../services/multi-survey.service';
import { FileType } from '../../models/multi-survey.models';
import { isNotNullOrUndefined } from '../../pipes/pipeable-operators';
import { ChangeSurveysResult } from 'src/app/components/dialogs/survey-selection-dialog/survey-selection-dialog.component';
import { PlanningStepComponent } from 'src/app/steps/planning-step/planning-step.component';
import { MediaStepComponent } from 'src/app/steps/media-step/media-step.component';

export interface SurveyPreference {
  options: SurveyPreferenceItem[];
  selectedOption: number;
}

export interface SurveyPreferenceItem {
  name: string;
  value: number;
}

export interface SaveAsDialogOptionsModel {
  campaignName: string;
  campaignDescription: string;
}

export const noSearchResultsMessageHTML = `
<h4>Sorry, we couldn't find any results.</h4>
<p>Here are some suggestions:</p>
<br />
<ul>
  <li>Make sure all words are spelt correctly.</li>
  <li>Search again with different keywords.</li>
  <li>Try more general keywords</li>
</ul>
`;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [TupDocumentEventEmitterDirective],
})
export class EditorComponent implements OnInit, AfterViewInit {
  @ViewChildren('stepItem') stepItemCollection: QueryList<BaseStepComponent>;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('tabs') tabs: TabStepperComponent;

  chartsActiveIndex: number = 4;
  rightSidebarOpened: boolean = false;
  stepProcessing: boolean = false;
  viewMode: ViewMode = ViewMode.Tables;
  activeScheduleIndex: number = 0;

  // keep all steps in an array for easy navigation
  stepItems: BaseStepComponent[];
  steps: string[] = [
    'audience',
    'media',
    'planning',
    'spots',
    'charts',
    'freqDistribution',
  ];
  stepTabs: string[] = ['Audience', 'Media', 'Planning', 'Spots'];

  readyForAudiences = new BehaviorSubject<boolean>(true);
  readyForMedia = new BehaviorSubject<boolean>(false);
  readyForPlanning = new BehaviorSubject<boolean>(false);
  readyForSpot = new BehaviorSubject<boolean>(false);
  readyToShowSpot = new BehaviorSubject<boolean>(false);

  previousTab: number = -1;
  currentTab: number = 0;
  currentPulse: number = -1;
  chartsExportType = ExportType.charts;
  chartsExportsList = [
    { label: 'XLSX', type: ExportFileType.xlsx },
    { label: 'PPTX', type: ExportFileType.pptx },
    { label: 'Google Sheets', type: ExportFileType.googleSheets },
    { label: 'Google Slides', type: ExportFileType.googleSlides },
  ];
  tableExportType = ExportType.data;
  tableExportsList = [
    { label: 'Spreadsheet (XLSX)', type: ExportFileType.xlsx },
    { label: 'Shareable (PLAN)', type: ExportFileType.json },
    { label: 'Multisource (MRF)', type: ExportFileType.mrf, disabled: true },
  ];
  surveyBarSurvey: SurveyMetrics[];
  selectedSurveys: DocumentFullSurvey[];
  currentCompareOption: string = 'media';
  documentId: string = '';
  selectedSurveyPreferenceOption: number = 0;
  importedReport: boolean;
  exploreReportId: string = '';

  exploreReport: TupDocument<any>;
  exploreReportTargets: Target[] = [];

  get documentTitle(): string {
    return this.mediaplannerService.plan.title;
  }

  get targets(): Target[] {
    return this.mediaplannerService.plan.targets;
  }

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private autoSaveService: AutoSaveService,
    private mediaplannerService: MediaPlannerService,
    private codebookService: CodebookService,
    private audienceGroupsService: TupAudienceGroupsService,
    private chartSettingsService: ChartSettingsService,
    private userPreferences: TupUserPreferenceStorageService,
    private dialogService: DialogService,
    private analyticsService: TupAnalyticsService,
    private http: HttpClient,
    private multiSurveyService: MultiSurveyService
  ) {}

  ngOnInit(): void {
    this.setupListeners();
    this.documentId =
      this.route.snapshot.params['document'] === 'undefined'
        ? undefined
        : this.route.snapshot.params['document'];
    this.selectedSurveyPreferenceOption =
      this.mediaplannerService.surveyPreferenceOption;

    if (this.route.snapshot.queryParams?.exploreReportId !== '') {
      this.importedReport = true;
      this.exploreReportId = this.route.snapshot.queryParams.exploreReportId;
    }

    this.tableExportsListUpdate();
  }

  // listen for window unloading (exit browser) and prompt if campaign not saved.
  @HostListener('window:beforeunload', ['$event'])
  beforeunload(event: BeforeUnloadEvent): void {
    if (this.mediaplannerService.plan.campaignDirty) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  private setupListeners(): void {
    this.mediaplannerService.campaignStatus.readyForAudiences.subscribe(
      (ready) => this.readyForAudiences.next(ready)
    );

    this.mediaplannerService.campaignStatus.readyForMedia.subscribe((ready) =>
      this.readyForMedia.next(ready)
    );

    this.mediaplannerService.campaignStatus.readyForPlanning.subscribe(
      (ready) => this.readyForPlanning.next(ready)
    );

    this.mediaplannerService.campaignStatus.readyForSpot.subscribe((ready) =>
      this.readyForSpot.next(ready)
    );

    this.mediaplannerService.campaignStatus.readyToShowSpot.subscribe((ready) =>
      this.readyToShowSpot.next(ready)
    );
  }

  ngAfterViewInit(): void {
    this.stepItems = this.stepItemCollection.toArray();
    this.surveyBarUpdate();

    if (this.importedReport && this.exploreReportId) {
      // if there is an exploreReportId in the querry params create a new campaign and fetch the data by id
      // if no document found or survey not available then redirect to dashboard page
      this.mediaplannerService.plan.addSchedule('Plan 1');
      this.mediaplannerService.plan.title = 'New campaign';
      this.codebookService.clearCache();

      this.mediaplannerService
        .loadExploreReportFromId(this.exploreReportId)
        .subscribe((loadReportResult: LoadExploreReportResult) => {
          if (!loadReportResult.success) {
            this.router.navigate(['/documents']);
            return;
          }

          this.exploreReport = cloneDeep(loadReportResult.exploreFile);
          const importedSurvey = this.exploreReport.content.surveys[0];

          this.mediaplannerService
            .prepareSurveys([
              {
                code: importedSurvey.code,
                authGroup: importedSurvey.authorizationGroup,
              },
            ])
            .subscribe((prepResponse: CampaignPreparationResponse) => {
              if (prepResponse.success) {
                this.exploreReportTargets = this.getSelectedTargetsForPlan(
                  this.exploreReport
                );
                this.surveyBarUpdate();
                this.listenToQueryParamsAndNavigate();

                const saveAsDialogOptions: SaveAsDialogOptionsModel = {
                  campaignName: this.exploreReport.metadata.name || '',
                  campaignDescription:
                    this.exploreReport.metadata.description || '',
                };
                this.onSaveCampaign(true, saveAsDialogOptions);
              } else {
                this.router.navigate(['/documents']);
                return;
              }
            });
        });
    } else {
      // subscribe to changes in the route when on the editor component
      this.route.params.subscribe((params) => {
        // jump to the desired step if we're up and running.
        if (this.mediaplannerService.plan.campaignStarted) {
          setTimeout(() => {
            this.listenToQueryParamsAndNavigate();
          });
        }
        // no running campaign started sooo:  (The case of a browser refresh or starting app from a bookmark)
        //  - check querystring and load named document if possible
        //  - else find the autosaved doc and attempt to load
        //  - if all fails, jump to /documents else go to relevant tab
        else {
          // attempt to load the document from the querystring (return false if there is no documentId)
          const documentId: string = params['document'];
          const loadDocument =
            !documentId || documentId === UNSAVED_DOCUMENT
              ? of({ success: false })
              : this.mediaplannerService.loadDocumentFromId(
                  documentId,
                  DocumentOrigin.normal
                );
          loadDocument.subscribe(
            (queryStringDocumentSuccess: LoadDocumentResult) => {
              // received request that even though we are loading a campaign, always try and load the unsaved one as well (?)

              // load document failed
              if (!queryStringDocumentSuccess.success) {
                // failed to load a document from the querystring, try and load autosave doc
                this.autoSaveService
                  .findDocument()
                  .subscribe((autoSavedDocument) => {
                    if (autoSavedDocument) {
                      this.mediaplannerService
                        .loadDocument(
                          autoSavedDocument,
                          DocumentOrigin.autosaved
                        )
                        .subscribe((autoSaveResult: LoadDocumentResult) => {
                          if (autoSaveResult.success) {
                            this.surveyBarUpdate();
                            this.listenToQueryParamsAndNavigate();
                          }
                        });
                    } else {
                      // was no autosaved doc so go to documents
                      this.surveyBarUpdate();
                      this.router.navigate(['/documents']);
                    }
                  });
              } else {
                // querystring doc load success
                this.route.queryParams.subscribe((queryParams) => {
                  let tab = Number(queryParams.tab);
                  //if doc load successfully and tabReady doesn't exist set tabReady = campaignStage
                  if (!this.mediaplannerService.plan.tabReady) {
                    this.mediaplannerService.plan.tabReady =
                      this.mediaplannerService.plan.getCampaignStage();
                    tab = this.steps.indexOf(
                      this.mediaplannerService.plan.tabReady
                    );
                  }
                  if (tab < 4) {
                    this.handleStepsTabs(tab);
                  } else {
                    this.handleChartsTabs(tab);
                  }
                });
              }
            }
          );
        }
      });
    }
  }

  listenToQueryParamsAndNavigate() {
    this.route.queryParams.subscribe((queryParams) => {
      const tab = queryParams.tab;
      let step = this.mediaplannerService.plan.getCampaignStage();
      if (
        !this.mediaplannerService.plan.tabReady ||
        this.steps.indexOf(this.mediaplannerService.plan.tabReady) <
          this.steps.indexOf(this.mediaplannerService.plan.getCampaignStage())
      ) {
        this.mediaplannerService.plan.tabReady =
          this.mediaplannerService.plan.getCampaignStage();
      }
      if (this.mediaplannerService.plan.viewMode) {
        this.viewMode = this.mediaplannerService.plan.viewMode as ViewMode;
      }
      if (tab) {
        step = this.steps[tab];
      }

      this.navigation(step);
    });
  }

  handleChartsTabs(tab: number) {
    if (this.viewMode !== ViewMode.Charts) {
      this.viewMode = ViewMode.Charts;
    }
    this.chartsActiveIndex = tab;
  }

  handleStepsTabs(tab: number) {
    //if tab is not ready navigate to campaign stage tab
    this.onStepperChange(tab);
  }

  surveyBarUpdate(): void {
    this.surveyBarSurvey = this.mediaplannerService.getSelectedSurveysMetrics();
    this.selectedSurveys = this.mediaplannerService.plan.selectedSurveys;
  }

  // pulse the next tab to indicate progress (emits from step component)
  onShowPulse(tabIndex: number) {
    this.tabs.pulse.emit(tabIndex);
  }

  // Called from View
  onNavigation(stepIndex: number) {
    this.navigation(this.steps[stepIndex]);
  }

  tableExportsListUpdate() {
    const mrfExportOption = this.tableExportsList.find(
      (exportOption) => exportOption.type === ExportFileType.mrf
    );
    mrfExportOption.disabled =
      !this.mediaplannerService.isSurveyMrfCompatible('export') ||
      this.mediaplannerService.plan.selectedSurveys.length > 1;
  }

  // navigation triggered
  navigation(step: string) {
    //let index = (typeof step === "number") ? step : this.steps.indexOf( step );
    let index = this.steps.indexOf(step);

    index = Math.max(0, index);

    if (index > 3) {
      this.handleChartsTabs(index);
    } else {
      this.handleStepsTabs(index);
    }
  }

  // stepper change has been triggered.  Save the state of the current step and load the state of the next, then move steps
  onStepperChange(selectedIndex: number) {
    const saveData =
      this.previousTab === -1
        ? of(true)
        : this.stepItems[this.previousTab].saveData();

    saveData.subscribe(() => {
      this.autoSaveService.dirty(true);
      this.surveyBarUpdate();

      this.viewMode = ViewMode.Tables;
      this.stepItems[selectedIndex].loadData();
      this.mediaplannerService.plan.campaignStage =
        this.stepItems[selectedIndex].label;
      if (this.mediaplannerService.plan.viewMode !== ViewMode.Tables) {
        this.mediaplannerService.plan.viewMode = ViewMode.Tables;
      }
      if (
        this.steps.indexOf(this.mediaplannerService.plan.tabReady) <
        selectedIndex
      ) {
        this.mediaplannerService.plan.tabReady = this.steps[selectedIndex];
      }
      this.previousTab = selectedIndex;
      this.currentTab = selectedIndex;
      this.analyticsService.sendPageview(this.stepItems[selectedIndex].label);
    });
  }

  navigateToTab(tabIndex: number) {
    if (tabIndex < 2) {
      const selectedSurveys = cloneDeep(
        this.mediaplannerService.plan.selectedSurveys
      );
      selectedSurveys.forEach((val) => {
        val.isCurrent = val.isPrimary;
      });
      this.mediaplannerService.plan.selectedSurveys = selectedSurveys;
      this.surveyBarUpdate();
    }
    if (this.steps.indexOf(this.mediaplannerService.plan.tabReady) < tabIndex) {
      this.mediaplannerService.plan.tabReady = this.steps[tabIndex];
    }
    if (this.documentId !== undefined) {
      this.router.navigate([`edit/${this.documentId}/data`], {
        queryParams: {
          tab: tabIndex,
        },
      });
    } else {
      this.router.navigate(['new/data'], {
        queryParams: {
          tab: tabIndex,
        },
      });
    }
  }

  onChartsActiveIndexChange(selectedIndex: number) {
    this.chartsActiveIndex = selectedIndex;
    if (this.documentId !== undefined) {
      this.router.navigate([`edit/${this.documentId}/chart`], {
        queryParams: {
          tab: selectedIndex,
        },
      });
    } else {
      this.router.navigate(['new/chart'], {
        queryParams: {
          tab: selectedIndex,
        },
      });
    }
  }

  onActiveScheduleTabChange(scheduleIndex: number) {
    this.activeScheduleIndex = scheduleIndex;
  }

  onCompareOptionChange(compareOption: string) {
    this.currentCompareOption = compareOption;
  }

  // Tables / Charts radio group change.. Steps that react to this already have @Input()s
  onViewModeChange(viewMode: string) {
    this.mediaplannerService.plan.viewMode = viewMode;
    if (viewMode === ViewMode.Charts && this.documentId !== undefined) {
      this.router.navigate([`edit/${this.documentId}/chart`], {
        queryParams: {
          tab: this.chartsActiveIndex,
        },
      });
    }
    if (
      viewMode === ViewMode.Tables &&
      this.documentId !== undefined &&
      this.documentId !== UNSAVED_DOCUMENT
    ) {
      this.router.navigate([`edit/${this.documentId}/data`], {
        queryParams: {
          tab: this.steps.indexOf(
            this.mediaplannerService.plan.getCampaignStage()
          ),
        },
      });
    }

    if (viewMode === ViewMode.Charts && this.documentId === undefined) {
      this.router.navigate(['new/chart'], {
        queryParams: {
          tab: this.chartsActiveIndex,
        },
      });
    }
    if (viewMode === ViewMode.Tables && this.documentId === undefined) {
      this.router.navigate(['new/data'], {
        queryParams: {
          tab: this.steps.indexOf(
            this.mediaplannerService.plan.getCampaignStage()
          ),
        },
      });
    }

    if (viewMode === ViewMode.Charts) {
      // tracking when switching to Charts view. Tables case (i.e Audience,Media,Plan screens) are covered in onStepperChange()
      this.analyticsService.sendPageview(viewMode);
    }
  }

  // document editor 'edit' button clicked on a new campaign.
  onDocumentTitleEditing() {
    this.saveCampaignWithPrompts(false, true, false); // force a UI prompt but not saving from new
  }

  onSaveCampaign(
    saveAs: boolean,
    saveAsDialogOptions?: SaveAsDialogOptionsModel
  ) {
    this.saveCampaignWithPrompts(saveAs, saveAs, saveAs, saveAsDialogOptions);
  }

  // UI click.  Save a campaign either with as a simple rename or a full save As experience
  saveCampaignWithPrompts(
    createNewCampaign: boolean,
    forcePrompt: boolean,
    showDrives: boolean,
    saveAsDialogOptions?: SaveAsDialogOptionsModel
  ) {
    // firstly ensure the current tab has saved its data before saving the campaign
    this.stepItems[this.currentTab].saveData().subscribe(() => {
      this.mediaplannerService
        .saveCampaignWithPrompts(
          createNewCampaign,
          forcePrompt,
          showDrives,
          saveAsDialogOptions
        )
        .subscribe((success) => {
          if (success) {
            const viewType =
              this.viewMode === ViewMode.Tables ? 'data' : 'chart';
            const tabValue =
              this.viewMode === ViewMode.Tables
                ? this.currentTab
                : this.chartsActiveIndex;

            this.router.navigate(
              [`/edit/${this.mediaplannerService.plan.documentId}/${viewType}`],
              {
                queryParams: {
                  tab: tabValue,
                },
              }
            );
            this.analyticsService.e(GAEvents.save, { action: 'campaign' });
          }
        });
    });
  }

  // UI click. save a template (a campaign file but with one empty schedule)
  onSaveTemplate() {
    // firstly ensure the current tab has saved its data before saving the campaign
    this.stepItems[this.currentTab].saveData().subscribe(() => {
      this.mediaplannerService.saveCampaignAsTemplate();
      this.analyticsService.e(GAEvents.save, { action: 'template' });
    });
  }

  // export button pressed.  Show export options dialog then initiate export library
  onExport(fileType: ExportFileType, type: ExportType) {
    // call the export on the relevant step
    this.stepItems[this.currentTab].export(fileType, type);
    this.trackExport(fileType, type);
  }

  trackExport(fileType: ExportFileType, type: ExportType) {
    let exportType = '';
    let exportFileType = '';
    switch (type) {
      case ExportType.charts:
        exportType = GAEvents.export_charts;
        switch (fileType) {
          case ExportFileType.pptx:
            exportFileType = GAEvents.export_file_pptx;
            break;
          case ExportFileType.xlsx:
            exportFileType = GAEvents.export_file_xlsx;
            break;
          case ExportFileType.googleSlides:
            exportFileType = GAEvents.export_file_googleSlides;
            break;
          case ExportFileType.googleSheets:
            exportFileType = GAEvents.export_file_googleSheets;
            break;
          default:
            exportFileType = 'untracked_export_file_type';
        }
        break;
      case ExportType.data:
        exportType = GAEvents.export_data;
        switch (fileType) {
          case ExportFileType.xlsx:
            exportFileType = GAEvents.export_file_xlsx;
            break;
          default:
            exportFileType = 'untracked_export_file_type';
        }
        break;
      default:
        exportType = 'untracked_export_type';
    }
    this.analyticsService.e(exportType, { action: exportFileType });
  }

  public surveySelector(
    previouslySelectedSurveys?: DocumentFullSurvey[],
    filterSurveysBy?: Map<string, boolean>,
    maxSelectedTrendableSurveys?: number,
    defaultSurveyPreference?: number
  ): Observable<
    | Survey[]
    | ChangeSurveysResult
    | { surveys: Survey[]; surveyPreference: SurveyPreference }
  > {
    return this.dialogService
      .surveySelector(
        previouslySelectedSurveys,
        filterSurveysBy,
        maxSelectedTrendableSurveys,
        defaultSurveyPreference
      )
      .afterClosed();
  }

  // a new survey may be selected.  If so be sure to clear the current run completely.
  onSurveySelection() {
    const filter = new Map<string, boolean>();
    filter.set('hasVehicles', true);
    filter.set('fetchMediaTypes', true);

    // default option that will be preselected in dialog
    const defaultSurveyPreferenceOption = Number(
      this.selectedSurveyPreferenceOption
    );

    // show survey dialog
    this.surveySelector(
      this.mediaplannerService.plan.selectedSurveys,
      filter,
      0,
      defaultSurveyPreferenceOption
    ).subscribe(
      (result: { surveys: Survey[]; surveyPreference: SurveyPreference }) => {
        if (result && (result.surveys as Survey[]).length) {
          const newSurvey = <Survey>result.surveys[0];
          const newSurveys = result.surveys.map((survey) => ({
            code: survey.code,
            authGroup: survey.authorizationGroup,
          }));
          const newSurveyPreference = <SurveyPreference>result.surveyPreference;

          this.mediaplannerService
            .setSurveyPreferenceOption(newSurveyPreference.selectedOption)
            .subscribe();

          this.selectedSurveyPreferenceOption =
            newSurveyPreference.selectedOption;

          const audienceStep = this.stepItems[0] as AudienceStepComponent;
          const mediaStep = this.stepItems[1] as MediaStepComponent;
          const planningStep = this.stepItems[2] as PlanningStepComponent;

          const addedItems = newSurveys.filter(
            (item2) =>
              !this.mediaplannerService.plan.selectedSurveys.some(
                (item1) =>
                  item1.code === item2.code &&
                  item1.authorizationGroup === item2.authGroup
              )
          );

          // something changed to selectedSurveys
          if (this.mediaplannerService.plan.selectedSurveys.length > 1) {
            let primarySurveyRemoved = false;
            const surveyCodesRemoved = [];
            this.mediaplannerService.plan.selectedSurveys.forEach(
              (selectedSurvey) => {
                const availableSurvey = newSurveys.find(
                  (val) =>
                    val.code === selectedSurvey.code &&
                    val.authGroup === selectedSurvey.authorizationGroup
                );
                if (!availableSurvey) {
                  surveyCodesRemoved.push(selectedSurvey.code);
                  if (selectedSurvey.isPrimary) {
                    primarySurveyRemoved = true;
                  }
                }
              }
            );
            this.stepProcessing = true;
            // if primary survey removed or removed all the selectedSurveys
            if (
              primarySurveyRemoved ||
              surveyCodesRemoved.length ===
                this.mediaplannerService.plan.selectedSurveys.length
            ) {
              this.mediaplannerService.campaignStatus.readyForSpot.next(false);
              this.mediaplannerService.campaignStatus.readyForPlanning.next(
                false
              );
              this.mediaplannerService.campaignStatus.readyForMedia.next(false);
              this.mediaplannerService.campaignStatus.readyForAudiences.next(
                true
              );
              this.codebookService.clearCache();
              this.mediaplannerService.clearOnSurveyChange(false);
              audienceStep.cleanupAfterSurveyChanged();
              audienceStep.loadingAudiences = true;
              audienceStep.cleanupDOM();
              this.mediaplannerService
                .prepareSurveys(newSurveys)
                .subscribe((prepResponse: CampaignPreparationResponse) => {
                  if (prepResponse.success) {
                    newSurvey.code = prepResponse.surveyCode;
                    newSurvey.authorizationGroup =
                      prepResponse.authorizationGroup;
                    this.mediaplannerService
                      .setRecentSurveysList(result.surveys)
                      .subscribe();
                    this.mediaplannerService
                      .setSelectedSurveysUsed(result.surveys)
                      .subscribe();
                    this.surveyBarUpdate();
                    this.mediaplannerService.plan.addSchedule('Plan 1');
                    this.stepProcessing = false;
                    audienceStep.refresh();
                    this.tableExportsListUpdate();
                    this.mediaplannerService.plan.addRemoveSurveyColumn(
                      this.mediaplannerService.plan.selectedSurveys.length <= 1
                    );
                    mediaStep.removeMediaBySurveyCode(surveyCodesRemoved);
                    return;
                  }
                });
              return;
            }
            if (surveyCodesRemoved.length) {
              // update selectedSurveys removing the one not using
              this.mediaplannerService.plan.selectedSurveys =
                this.mediaplannerService.plan.selectedSurveys.filter(
                  (selectedSurvey) =>
                    newSurveys.find(
                      (newSurvey) =>
                        newSurvey.code === selectedSurvey.code &&
                        newSurvey.authGroup ===
                          selectedSurvey.authorizationGroup
                    )
                );
              this.mediaplannerService
                .setSelectedSurveysUsed(
                  this.mediaplannerService.plan.selectedSurveys
                )
                .subscribe();

              // if currentSurvey was removed update to be the primary or the first available
              if (
                !this.mediaplannerService.plan.selectedSurveys.find(
                  (selectedSurvey) => selectedSurvey.isCurrent
                )
              ) {
                const primaryIndex =
                  this.mediaplannerService.plan.selectedSurveys.indexOf(
                    this.mediaplannerService.plan.primarySurvey
                  );
                primaryIndex &&
                this.mediaplannerService.plan.selectedSurveys[primaryIndex]
                  ? (this.mediaplannerService.plan.selectedSurveys[
                      primaryIndex
                    ].isCurrent = true)
                  : (this.mediaplannerService.plan.selectedSurveys[0].isCurrent =
                      true);
              }
              this.surveyBarUpdate();
              audienceStep.onCurrentSurveyChange();
              this.mediaplannerService.plan.addRemoveSurveyColumn(
                this.mediaplannerService.plan.selectedSurveys.length <= 1
              );
              mediaStep.removeMediaBySurveyCode(surveyCodesRemoved);
            }
            if (addedItems.length) {
              this.mediaplannerService
                .prepareSurveys(newSurveys)
                .subscribe((prepResponse: CampaignPreparationResponse) => {
                  if (prepResponse.success) {
                    this.mediaplannerService
                      .setRecentSurveysList(result.surveys)
                      .subscribe();
                    this.mediaplannerService
                      .setSelectedSurveysUsed(result.surveys)
                      .subscribe();
                    this.surveyBarUpdate();
                    this.stepProcessing = false;
                    this.tableExportsListUpdate();
                    this.mediaplannerService.plan.addRemoveSurveyColumn(
                      this.mediaplannerService.plan.selectedSurveys.length <= 1
                    );
                  }
                });
            }

            // TO-DO: remove media from unselected survey
            this.stepProcessing = false;
          }
          // survey list or authorization group has changed.
          else if (
            this.mediaplannerService.plan.currentSurvey.code !==
              newSurvey.code ||
            this.mediaplannerService.plan.currentSurvey.authorizationGroup !==
              newSurvey.authorizationGroup ||
            newSurveys.length > 1
          ) {
            const containsCurrentSurvey = newSurveys.some(
              (newSurvey) =>
                newSurvey.code ===
                  this.mediaplannerService.plan.currentSurvey.code &&
                newSurvey.authGroup ===
                  this.mediaplannerService.plan.currentSurvey.authorizationGroup
            );
            if (newSurveys.length > 1 && containsCurrentSurvey) {
              this.mediaplannerService
                .prepareSurveys(newSurveys)
                .subscribe((prepResponse: CampaignPreparationResponse) => {
                  if (prepResponse.success) {
                    this.mediaplannerService
                      .setRecentSurveysList(result.surveys)
                      .subscribe();
                    this.mediaplannerService
                      .setSelectedSurveysUsed(result.surveys)
                      .subscribe();
                    this.surveyBarUpdate();
                    this.stepProcessing = false;
                    this.tableExportsListUpdate();
                    this.mediaplannerService.plan.addRemoveSurveyColumn(
                      this.mediaplannerService.plan.selectedSurveys.length <= 1
                    );
                  }
                });
            } else {
              this.confirmSurveyChange().subscribe((ans) => {
                // new survey requested.  This needs a full campaign reset and route back to the audience step
                if (ans) {
                  audienceStep.cleanupAfterSurveyChanged();
                  audienceStep.loadingAudiences = true;
                  audienceStep.cleanupDOM();

                  this.stepProcessing = true;
                  //this.codebookService.clearCache(false);
                  this.mediaplannerService.clearOnSurveyChange();
                  this.mediaplannerService.surveyChanged.emit(); // let any step components do their own cleanup
                  this.mediaplannerService.dirty(); // update autosave doc

                  // reload surveys, select passed in, build base target and fetch populations
                  this.mediaplannerService
                    .prepareSurveys(newSurveys)
                    .subscribe((prepResponse: CampaignPreparationResponse) => {
                      if (prepResponse.success) {
                        newSurvey.code = prepResponse.surveyCode;
                        newSurvey.authorizationGroup =
                          prepResponse.authorizationGroup; // fix the authGroup (backwards compatibility)

                        this.mediaplannerService
                          .setRecentSurveysList(
                            result.surveys.length > 1
                              ? result.surveys
                              : [newSurvey]
                          )
                          .subscribe();

                        this.mediaplannerService
                          .setSelectedSurveysUsed(result.surveys)
                          .subscribe();

                        this.surveyBarUpdate();
                        this.mediaplannerService.plan.addRemoveSurveyColumn(
                          this.mediaplannerService.plan.selectedSurveys
                            .length <= 1
                        );
                        this.mediaplannerService.plan.addSchedule('Plan 1');
                        this.stepProcessing = false;
                        this.navigation('audience');
                        this.tableExportsListUpdate();
                      }
                    });
                }
              });
            }
          } else {
            this.mediaplannerService.plan.addRemoveSurveyColumn(
              this.mediaplannerService.plan.selectedSurveys.length <= 1
            );
          }
        }
      }
    );
  }

  onCopyrightClick() {
    const options = new SurveyInfoDialogModel();
    options.type = SurveyInfoType.copyright;
    options.surveys = this.surveyBarSurvey;
    this.dialogService.openSurveyInfo(options);
  }

  // ask user for comfirmation if there are some targets (therefore an existing plan)
  confirmSurveyChange(): Observable<boolean> {
    if (
      this.currentTab === 0 && // showing aud tab
      (this.stepItems[0] as AudienceStepComponent).targetStatements.length ===
        0 && // aud tab has no targets yet
      this.mediaplannerService.plan.targets.length === 0
    )
      return of(true); // campaign has no targets, so good to swap

    return this.dialogService.question(
      'Changing surveys will clear your current campaign, are you sure?',
      'Changing surveys'
    );
  }

  /**
   * Retrieves all the selected targets for a given Explore Report from the columns and rows arrays
   * @param {TupDocument<any>} exploreReport - The explore report containing columns and rows
   * @returns {Target[]} - An array of selected targets
   */
  getSelectedTargetsForPlan(exploreReport: TupDocument<any>): Target[] {
    const targetsForPlan: Target[] = [];
    exploreReport.content.columns.forEach((col) => {
      if (col.selectedForPlan) {
        col.targets.forEach((tgt) => {
          targetsForPlan.push(tgt);
        });
      }
    });

    exploreReport.content.rows.forEach((row) => {
      if (row.selectedForPlan) {
        row.targets.forEach((tgt) => {
          targetsForPlan.push(tgt);
        });
      }
    });

    return targetsForPlan;
  }

  onImportMediaClick() {
    return this.dialogService
      .openImportMixedDataDialog({
        action: dialogActionType.appImport,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((dialogData: MixedDataDialogModel) => {
        const currentTab = this.steps[this.currentTab];
        let MRFOnly: boolean = false;
        let multiSurveyAction = of(true);

        if (dialogData.manualInputData) {
          multiSurveyAction = this.multiSurveyService.addManualInput(
            dialogData.manualInputData,
            this.activeScheduleIndex,
            dialogData.planAudienceId
          );
        } else {
          const uploadedMRF =
            this.mediaplannerService.plan.multiSurveys.multiSurveys.map(
              (ms) => ms.fileType === FileType.MRF
            );
          MRFOnly = uploadedMRF
            ? uploadedMRF.length ===
              this.mediaplannerService.plan.multiSurveys.multiSurveys.length
            : false;

          if (MRFOnly)
            multiSurveyAction = this.multiSurveyService.addMRFDataToPlan();
          if (dialogData.planAudienceId) {
            multiSurveyAction =
              dialogData.audienceAction === audienceActionType.useExistingAud
                ? this.multiSurveyService.addMultiSurveyVehiclesToCurrentTarget()
                : this.multiSurveyService.addDAUDataToPlan(
                    dialogData.planAudienceId,
                    dialogData.documentTarget
                  );
          }
        }

        this.multiSurveyService.multiSurveyStartProcessing.emit(currentTab);
        multiSurveyAction.subscribe((success) => {
          this.multiSurveyService.multiSurveyTargetSelected.emit({
            currentTab,
            refresh:
              success && (MRFOnly || dialogData.refreshOnCloseWithoutSave),
          });
          this.mediaplannerService.dirty();
        });
      });
  }
}
