<div class="breadcrumbs-container">
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngFor="let step of breadcrumbsSteps"
  >
    <div *ngIf="step.isVisible === true && step.title !== ''">
      <span
        (click)="step.isClickable ? onStepChange(step) : null"
        [ngClass]="{
          'clickable-link': step.isClickable
        }"
      >
        {{ step.title }}
      </span>
      <span class="slash-between-breadcrumbs">{{
        step.isClickable ? " / " : ""
      }}</span>
    </div>
  </div>
</div>
