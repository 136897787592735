import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentTarget } from 'src/app/models/document.model';

export enum AddressableWarningDialogComponentModelAction {
  copy,
  edit,
  cancel,
}

export class AddressableWarningDialogComponentModel {
  vehicles: string[];
  targetTitle: string;
  vehicleTitle: string;
  action: AddressableWarningDialogComponentModelAction;
  constructor() {}
}

@Component({
  selector: 'app-addressable-warning-dialog',
  templateUrl: './addressable-warning-dialog.component.html',
  styleUrls: ['./addressable-warning-dialog.component.scss'],
})
export class AddressableWarningDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddressableWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressableWarningDialogComponentModel
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  onClose(button: string) {
    this.data.action = AddressableWarningDialogComponentModelAction[button];
    this.dialogRef.close(this.data);
  }
}
