<tup-mat-title-bar
  title="Run options"
  class="dialog-title"
>
  <mat-icon
    title="Close dialog"
    class="close-icon"
    (click)="close()"
    >close</mat-icon
  >
</tup-mat-title-bar>

<mat-dialog-content>
  <div
    fxLayout="column"
    fxLayoutGap="10px"
    class="dialog-content-wrapper"
  >
    <mat-radio-group
      class="tup-mat-radio-small"
      [(ngModel)]="data.clearExisting"
      fxFlex
      fxLayout="column"
      fxLayoutGap="10px"
    >
      <mat-radio-button [value]="true"
        >Clear existing rows and columns</mat-radio-button
      >
      <mat-radio-button
        [value]="false"
        [disabled]="data.shouldDisableUseExistingOption"
        >Use existing rows and columns</mat-radio-button
      >
    </mat-radio-group>
    <span
      ><b>Note: </b>
      <span class="warning-text"
        >Please verify that the existing codes are valid, otherwise this may
        lead to exception in results.</span
      ></span
    >
  </div>
</mat-dialog-content>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="dialog-actions"
>
  <button
    mat-stroked-button
    color="primary"
    appearance="medium"
    tupMatButton
    (click)="close()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    appearance="medium"
    tupMatButton
    cdkFocusRegionstart
    (click)="onButtonClick()"
  >
    Continue
  </button>
</mat-dialog-actions>
