import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  CountCodingDialogComponent,
  CountCodingModel,
} from '../components/dialogs/count-coding-dialog/count-coding-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CountCodingDialogService {
  constructor(private dialog: MatDialog) {}

  openCountCoding(): Observable<CountCodingModel> {
    return new Observable((observable) => {
      this.dialog
        .open(CountCodingDialogComponent, { closeOnNavigation: true })
        .afterClosed()
        .subscribe((countCoding: CountCodingModel) => {
          observable.next(countCoding);
          observable.complete();
        });
    });
  }
}
