import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface FrequencyDistributionGroupingDialogModel {
  isGroupingOn: boolean;
  freqLevelTo: number;
  freqUpTo: number;
  groupsOf: number;
}

@Component({
  selector: 'frequency-distribution-grouping-dialog',
  templateUrl: './frequency-distribution-grouping-dialog.component.html',
  styleUrls: ['./frequency-distribution-grouping-dialog.component.scss'],
})
export class FrequencyDistributionGroupingDialogComponent implements OnInit {
  freqLevelTo: number;
  freqUpTo: number;
  groupsOf: number;
  isGroupingOn: boolean;

  constructor(
    public dialogRef: MatDialogRef<FrequencyDistributionGroupingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: FrequencyDistributionGroupingDialogModel
  ) {
    this.freqLevelTo = data.freqLevelTo || 9;
    this.freqUpTo = data.freqUpTo || 2;
    this.groupsOf = data.groupsOf || 3;
    this.isGroupingOn = data.isGroupingOn || false;
  }

  ngOnInit(): void {}

  onClose(save: boolean) {
    let returnData = {};
    if (true) {
      returnData = {
        freqLevelTo: this.freqLevelTo,
        freqUpTo: this.freqUpTo,
        groupsOf: this.groupsOf,
        isGroupingOn: this.isGroupingOn,
      };
    }

    this.dialogRef.close(save ? returnData : null);
  }
}
