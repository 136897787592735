<div
  fxFlex="100%"
  fxLayout="column"
  class="spot-step-wrapper"
>
  <!-- survey selection card and toolbar -->
  <survey-info-bar
    [hasSurveyDropdown]="false"
    [allowChangeSurvey]="false"
  >
    <div
      fxFlex="100%"
      fxLayout="row"
      fxLayoutAlign="end"
    >
      <!-- plans wrapper -->
      <div
        fxFlex="calc(100vw - 671px)"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="8px"
        class="header-container"
      >
        <!-- spot tabs -->
        <div
          mat-tab-nav-bar
          #spotTabGroup
          fxFlex="100"
          style="width: calc(100vw - 671px)"
          [disablePagination]="false"
          class="schedule-tab-wrapper"
        >
          <a
            mat-tab-link
            *ngFor="let spotplan of screenSpotplans; let i = index"
            [active]="screenSpotplanIndex === i"
            (click)="onSpotplanChange(spotplan)"
            [matTooltip]="spotplan.scheduleName"
            [ngClass]="{
              'active-link': screenSpotplanIndex === i
            }"
          >
            <div
              fxFlex="100%"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div
                fxFlex="100%"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  class="step-icon"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon class="step-icon">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    (click)="onSettings(i)"
                    mat-menu-item
                  >
                    <mat-icon class="material-symbols-outlined"
                      >settings</mat-icon
                    >
                    Settings
                  </button>

                  <!-- Spotplanning phase 2 -->
                  <!-- <button
                    (click)="onCopyToOtherMedia()"
                    mat-menu-item
                  >
                    <mat-icon>arrow_forward</mat-icon> Copy to other media
                  </button> -->
                  <button
                    (click)="onRenameSpotplan(i)"
                    mat-menu-item
                  >
                    <mat-icon>text_format</mat-icon> Rename spot
                  </button>

                  <!-- Spotplanning phase 2 -->
                  <!-- <button
                    (click)="onDuplicateSpotplan(i)"
                    mat-menu-item
                  >
                    <mat-icon>content_copy</mat-icon> Duplicate spot
                  </button> -->
                  <button
                    (click)="onDeleteSpotplan(i)"
                    mat-menu-item
                  >
                    <mat-icon>delete</mat-icon> Delete spot
                  </button>
                </mat-menu>
                <div
                  class="tab-name"
                  [matTooltip]="spotplan.spotName"
                >
                  {{ spotplan.name }}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <!-- chip containing current schedule  -->
      <mat-chip-list>
        <mat-chip class="schedule-chip">
          <span>{{ screenSpotplans[0]?.scheduleName }}</span>
          <mat-icon
            [matTooltip]="scheduleTooltip"
            matTooltipClass="multiline-tooltip"
            >info_outline
          </mat-icon>
        </mat-chip>
      </mat-chip-list>

      <!-- Compare By dropdown to be added for Phase 2 -->
      <!-- <div
        fxFlex="1 1 250px"
        fxLayoutAlign="space-between center"
        fxLayout="row wrap"
        fxLayoutGap="14px"
      >
        <mat-form-field
          appearance="outline"
          class="regular-select full-width compare-by-select"
          tupMatFormField
        >
          <mat-label class="compare-by-label"
            ><mat-icon class="compare-icon">compare_arrows</mat-icon
            ><span>Compare By: </span></mat-label
          >
          <mat-select
            (selectionChange)="onCompareOptionChange($event)"
            [value]="currentCompareOption"
          >
            <mat-option
              *ngFor="let compareOption of compareOptions"
              [value]="compareOption.data"
            >
              {{ compareOption.label }}
            </mat-option>
          </mat-select>
          <mat-icon class="custom-arrow">keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div> -->

      <!-- filter button -->
      <confirm-selection
        matSuffix
        buttonIcon="filter_list"
        [options]="spotFilterOptions"
        matTooltip="Plan filter"
        title="Filter"
        subTitle="Plans"
        (confirm)="onSpotFilter($event)"
      >
      </confirm-selection>
    </div>
  </survey-info-bar>

  <mat-tab-group
    tab-less-tabs
    class="main-container"
  >
    <!-- TABLES TAB -->
    <mat-tab label="Tables">
      <targets-bar
        [targetItems]="allTargets"
        [targetTooltipData]="targetTooltips"
        [selectedTargets]="selectedTargets"
        (targetSelected)="onTargetChange($event)"
        [isMultiSelect]="false"
      >
      </targets-bar>

      <div class="container">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
        >
          <div
            virtualScrollTable
            [itemHeight]="42"
            (scrollUpdate)="spotTable.scrollUpdate = $event"
            class="virtual-container"
            [style.--collapsed-columns-number]="collapsedColumnsNumber"
            [style.--expanded-columns-number]="expandedColumnsNumber"
          >
            <tree-table
              #spotTable
              class="tree-table spot-table"
              [treeTitle]="treeTitle"
              [virtualScrolling]="true"
              treeWidth="250px"
              stickyColumn="name"
              data-tracking="spot-table"
              [treeHeaderInlineMenu]="headerInlineMenu"
              (treeHeaderMainMenuClick)="onTreeHeaderMainMenuClick()"
              (treeHeaderMenuClick)="onTreeHeaderMenuClick($event)"
              (treeInlineMenuClick)="onTreeInlineMenuClick($event)"
              [readonly]="_processing"
              (edited)="onEdited($event)"
              [data]="tableData"
              [showProcessing]="_processing"
              [disableDragging]="true"
              [allowSelectAll]="true"
              [columns]="tableColumns"
              [columnGroups]="columnGroups"
              [unitsText]="unitsText"
              (columnMenuClick)="onPlanningColumnMenuClick($event)"
            >
            </tree-table>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- inline templates for adding custom icons and functionality as needed to nodes -->
<!-- Add a chevron for expanding and collapsing th etable from the Totals line -->
<ng-template
  #totalsRow
  let-row="row"
>
  <button
    mat-icon-button
    class="inline-button"
    (click)="onToggleExpand()"
  >
    <mat-icon>
      {{ tableExpanded ? "expand_more" : "chevron_right" }}
    </mat-icon>
  </button>
  {{ row.name }}
</ng-template>

<!-- used by cells that are readonly (nonEditable) -->
<!-- if value ==-1 then show a '-' else parse the real number -->
<ng-template
  #nonEditableCell
  let-row="row"
  let-column="column"
>
  <span>
    {{
      row.data[column.columnDef] === -1
        ? "-"
        : (column.cell(row)
          | customPipe : column.pipe?.token : column.pipe?.args)
    }}
  </span>
</ng-template>
