import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FlatTreeNode } from '../tree-table.models';
import { Subject } from 'rxjs';
import { VirtualScrollData } from './virtual-scroll-table.directive';

@Component({
  selector: 'virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss'],
})
export class VirtualScrollComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enabled: boolean = false;
  @Input() scrollUpdate: VirtualScrollData;
  @Input() rows: FlatTreeNode[] = [];

  currViewCount: number;
  currScrollTop: number;
  update: Subject<null> = new Subject<null>();

  private forceUpdate: boolean = false;
  private timer: ReturnType<typeof setTimeout> = null;
  readonly delay: number = 5; // redraw delay in ms
  readonly padding = 2; // no. of rows to render outside of the view to ease scrolling

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const scrollUpdate = changes['scrollUpdate'];
    const rows = changes['rows'];

    if (
      scrollUpdate &&
      (scrollUpdate.currentValue !== scrollUpdate.previousValue ||
        scrollUpdate.firstChange)
    ) {
      this.start();
    }

    if (
      rows &&
      (rows.currentValue !== rows.previousValue || rows.firstChange)
    ) {
      this.forceUpdate = true;
      this.start();
    }
  }

  ngOnInit(): void {
    this.update.subscribe(() => {
      this.calculateVisibility();
    });
  }

  ngOnDestroy(): void {
    this.stop();
  }

  stop() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
  }

  start() {
    this.stop();
    if (!this.enabled) return;

    this.timer = setTimeout(() => {
      this.update.next();
    }, this.delay);
  }

  calculateVisibility() {
    if (!this.scrollUpdate || !this.enabled) return;

    const beforeView =
      Math.floor(this.scrollUpdate.scrollTop / this.scrollUpdate.itemHeight) -
      this.padding;
    const afterView =
      Math.ceil(
        this.scrollUpdate.scrollHeight / this.scrollUpdate.itemHeight +
          beforeView
      ) +
      this.padding * 2;

    // determine if updates are needed
    if (!this.forceUpdate) {
      if (
        afterView - beforeView === this.currViewCount && // same number of items visible
        Math.abs(this.scrollUpdate.scrollTop - this.currScrollTop) <
          this.scrollUpdate.itemHeight && // scrollbar not moved by more than an item
        !this.scrollUpdate.firstChange
      ) {
        // no initial refresh
        return;
      }
    }

    this.currViewCount = afterView - beforeView;
    this.currScrollTop = this.scrollUpdate.scrollTop;
    this.scrollUpdate.firstChange = false;
    this.forceUpdate = false;

    // set hide fields according by row falls outside the current view
    // this.rows array mimics what is seen on screen so has already taken into account rows hidden due to being collapsed.
    this.rows.forEach((row, index) => {
      row._hide = index < beforeView || index > afterView;
      row.treeNode._hide = row._hide;
    });
  }
}
