import { DatePipe, DecimalPipe } from '@angular/common';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { DocumentCampaign } from './document.model';

// Column definition here for reference

// export interface Column {
//     columnDef: string;
//     columnType: string;
//     header: string;
//     sortable: boolean;
//     cell(document: TupDocument<unknown>): any;
//     pipe?: {
//         token: Type<PipeTransform>;
//         args?: any[];
//     };
//     tooltip?(document: TupDocument<unknown>): any;
//     tooltipPosition?: string;
//     tooltipShowDelay?: number;
//     tooltipHideDelay?: number;
// }

export const CampaignTags = {
  // Automatically tag a document based on its content
  automatic: [
    { key: 'Survey Code', path: 'content.header?.surveyCode' },
    { key: 'Survey Title', path: 'content.header?.survey' },
  ],
  showBadges: true,
};

// column definitions for the campaign columns table on the documents route
export const CampaignTableColumns = [
  {
    columnDef: 'actions',
    sortable: false,
    header: '',
    columnType: 'actions',
    cell: () => '',
  },
  {
    columnDef: 'metadata.name.keyword',
    sortable: true,
    header: 'My campaigns (0)',
    columnType: 'string',
    cell: (document: TupDocument<DocumentCampaign>) => document.metadata?.name,
  },
  {
    columnDef: 'metadata.description',
    sortable: false,
    header: 'Description',
    columnType: 'string',
    tooltip: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.description,
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.metadata?.description,
  },
  {
    columnDef: 'metadata.by.attributes.name.keyword',
    sortable: true,
    header: 'Owner',
    columnType: 'string',
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.by.attributes.name,
  },
  {
    columnDef: 'metadata.modified',
    sortable: true,
    header: 'Last modified',
    columnType: 'pipe',
    tooltip: (document: TupDocument<DocumentCampaign>) => {
      const d = new Date(document.metadata.modified);
      return `${d.toDateString()} - ${d.toLocaleTimeString()}`;
    },
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.modified,
    pipe: { token: DatePipe, args: ['HH:mm, d MMMM y'] },
  },
  {
    columnDef: 'content.header.survey',
    sortable: false,
    header: 'Surveys used',
    columnType: 'string',
    tooltip: (document: TupDocument<DocumentCampaign>) =>
      document.content.header?.survey,
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.content.header?.surveyCode || document.content.header?.survey,
  },
  {
    columnDef: 'empty',
    sortable: false,
    header: '',
    columnType: 'string',
    cell: () => '',
  },
];

export const LatestCampaignTableColumns = [
  {
    columnDef: 'actions',
    sortable: false,
    header: '',
    columnType: 'actions',
    cell: () => '',
  },
  {
    columnDef: 'metadata.name.keyword',
    sortable: true,
    header: 'My campaigns',
    columnType: 'string',
    tooltip: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.description,
    cell: (document: TupDocument<DocumentCampaign>) => document.metadata?.name,
  },
  {
    columnDef: 'metadata.modified',
    sortable: true,
    header: 'Last modified',
    columnType: 'pipe',
    tooltip: (document: TupDocument<DocumentCampaign>) => {
      const d = new Date(document.metadata.modified);
      return `${d.toDateString()} - ${d.toLocaleTimeString()}`;
    },
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.modified,
    pipe: { token: DatePipe, args: ['HH:mm, d MMMM y'] },
  },
  {
    columnDef: 'content.header.survey',
    sortable: false,
    header: 'Surveys used',
    columnType: 'string',
    tooltip: (document: TupDocument<DocumentCampaign>) =>
      document.content.header?.survey,
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.content.header?.surveyCode || document.content.header?.survey,
  },
  {
    columnDef: 'empty',
    sortable: false,
    header: '',
    columnType: 'string',
    cell: () => '',
  },
];

export const PlanningTemplateTableColumns = [
  {
    columnDef: 'actions',
    sortable: false,
    header: '',
    columnType: 'actions',
    cell: () => '',
  },
  {
    columnDef: 'metadata.name.keyword',
    sortable: true,
    header: 'My campaigns (0)',
    columnType: 'string',
    cell: (document: TupDocument<DocumentCampaign>) => document.metadata?.name,
  },
  {
    columnDef: 'metadata.description',
    sortable: false,
    header: 'Description',
    columnType: 'string',
    tooltip: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.description,
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.metadata?.description,
  },
  {
    columnDef: 'metadata.by.attributes.name.keyword',
    sortable: true,
    header: 'Owner',
    columnType: 'string',
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.by.attributes.name,
  },
  {
    columnDef: 'metadata.modified',
    sortable: true,
    header: 'Last modified',
    columnType: 'pipe',
    tooltip: (document: TupDocument<DocumentCampaign>) => {
      const d = new Date(document.metadata.modified);
      return `${d.toDateString()} - ${d.toLocaleTimeString()}`;
    },
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.modified,
    pipe: { token: DatePipe, args: ['HH:mm, d MMMM y'] },
  },
  {
    columnDef: 'content.header.survey',
    sortable: false,
    header: 'Surveys used',
    columnType: 'string',
    tooltip: (document: TupDocument<DocumentCampaign>) =>
      document.content.header?.survey,
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.content.header?.surveyCode || document.content.header?.survey,
  },
  {
    columnDef: 'empty',
    sortable: false,
    header: '',
    columnType: 'string',
    cell: () => '',
  },
];

export const LatestTemplateTableColumns = [
  {
    columnDef: 'actions',
    sortable: false,
    header: '',
    columnType: 'actions',
    cell: () => '',
  },
  {
    columnDef: 'metadata.name.keyword',
    sortable: true,
    header: 'My campaigns',
    columnType: 'string',
    tooltip: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.description,
    cell: (document: TupDocument<DocumentCampaign>) => document.metadata?.name,
  },
  {
    columnDef: 'metadata.modified',
    sortable: true,
    header: 'Last modified',
    columnType: 'pipe',
    tooltip: (document: TupDocument<DocumentCampaign>) => {
      const d = new Date(document.metadata.modified);
      return `${d.toDateString()} - ${d.toLocaleTimeString()}`;
    },
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.metadata.modified,
    pipe: { token: DatePipe, args: ['HH:mm, d MMMM, y'] },
  },
  {
    columnDef: 'content.header.survey',
    sortable: false,
    header: 'Surveys used',
    columnType: 'string',
    tooltip: (document: TupDocument<DocumentCampaign>) =>
      document.content.header?.survey,
    cell: (document: TupDocument<DocumentCampaign>) =>
      document.content.header?.surveyCode || document.content.header?.survey,
  },
  {
    columnDef: 'empty',
    sortable: false,
    header: '',
    columnType: 'string',
    cell: () => '',
  },
];
