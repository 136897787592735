import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { MatRipple, RippleConfig, ThemePalette } from '@angular/material/core';
import { TabStepperTabComponent } from './tab-stepper-tab/tab-stepper-tab.component';

export enum ViewMode {
  Tables = 'tables',
  Charts = 'charts',
}

@Component({
  selector: 'tab-stepper',
  templateUrl: './tab-stepper.component.html',
  styleUrls: ['./tab-stepper.component.scss'],
})
export class TabStepperComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChildren(MatRipple) rippleList: QueryList<MatRipple>;
  @ContentChildren(TabStepperTabComponent)
  tabs: QueryList<TabStepperTabComponent>;
  tabArray: TabStepperTabComponent[] = [];

  @Input() pulseColor: string = 'rgba(0, 84, 255, 0.4)';
  @Input() pulseFrequency: number = 2000;
  @Input() pulseDuration: number = 10000; // 5 pulses  (10000 / 2000)
  @Input() activeIndex: number;
  @Input() chartsActiveIndex: number;
  @Input() viewMode: ViewMode;
  @Input() currentCompareOption: string;
  @Output() viewModeChange: EventEmitter<ViewMode> =
    new EventEmitter<ViewMode>();
  @Output() activeIndexChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() chartsActiveIndexChange: EventEmitter<number> =
    new EventEmitter<number>();

  selectedTabIndex: number = 0;

  pulse: EventEmitter<number> = new EventEmitter<number>();

  background: ThemePalette = 'accent';
  private viewReady: boolean = false;
  private intervalId: ReturnType<typeof setInterval> = null;
  private ripples: MatRipple[];

  activePulseIndex: number = -1;

  constructor() {}

  ngOnInit(): void {
    this.pulse.subscribe((tabIndex: number) => this.internalPulse(tabIndex));
  }

  ngAfterViewInit(): void {
    this.tabArray = this.tabs.toArray();
    this.ripples = this.rippleList.toArray();
    this.viewReady = true;
    this.selectedTabIndex = this.activeIndex;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const activeIndexChange = changes['activeIndex'];
    const chartsActiveIndexChange = changes['chartsActiveIndex'];
    const viewModeChange = changes['viewMode'];

    if (viewModeChange && !viewModeChange.firstChange) {
      this.selectedTabIndex =
        viewModeChange.currentValue === ViewMode.Charts
          ? this.chartsActiveIndex - 4
          : this.activeIndex;
    }

    if (
      activeIndexChange &&
      activeIndexChange.currentValue !== this.selectedTabIndex &&
      !activeIndexChange.firstChange
    ) {
      this.selectedTabIndex = activeIndexChange.currentValue;
    }

    if (
      chartsActiveIndexChange &&
      chartsActiveIndexChange.currentValue !== this.selectedTabIndex &&
      !chartsActiveIndexChange.firstChange
    ) {
      this.selectedTabIndex = chartsActiveIndexChange.currentValue - 4;
    }
  }

  ngOnDestroy(): void {
    this.pulse.unsubscribe();
  }

  onSelectedIndexChange(selectedIndex: number) {
    this.internalPulse();
    if (this.viewMode === ViewMode.Tables) {
      this.activeIndexChange.emit(selectedIndex);
    }
    if (this.viewMode === ViewMode.Charts) {
      this.chartsActiveIndexChange.emit(selectedIndex + 4);
    }
  }

  onToggle() {
    if (this.activeIndex !== 2) return;
    this.viewMode =
      this.viewMode === ViewMode.Charts ? ViewMode.Tables : ViewMode.Charts;
    this.chartsActiveIndex =
      this.currentCompareOption === 'freqDistribution' ? 5 : 4;
    this.viewModeChange.emit(this.viewMode);
  }

  private internalPulse(tabIndex: number = -1) {
    if (tabIndex === this.activePulseIndex || !this.viewReady) return;

    // first, clear any old one
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.activePulseIndex = -1;
    }

    // now set (if needed)
    if (tabIndex > -1) {
      this.activePulseIndex = tabIndex;

      const config: RippleConfig = {
        color: this.pulseColor,
        centered: true,
        // radius: 100,
        persistent: false,
        animation: { enterDuration: 1000, exitDuration: 2000 },
        terminateOnPointerUp: false,
      };

      // allow to run for pulseDuration
      if (this.pulseDuration) {
        const durationId = setTimeout(() => {
          clearTimeout(durationId);
          this.internalPulse();
        }, this.pulseDuration);
      }

      // keep launching every pulseFrequency
      this.ripples[tabIndex].launch(config);
      this.intervalId = setInterval(() => {
        this.ripples[tabIndex].launch(config);
      }, this.pulseFrequency);
    }
  }
}
