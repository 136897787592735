<column-groups
  class="column-groups"
  [defaultColumnWidth]="DEFAULT_COLUMN_WIDTH"
  [columns]="columns"
  [treeWidth]="treeWidth"
>
</column-groups>

<mat-progress-bar
  class="sticky-progress-bar"
  *ngIf="treeTitle.length !== 0"
  value="0"
  [mode]="showProcessing ? 'indeterminate' : 'determinate'"
></mat-progress-bar>

<!-- MAIN TABLE -->
<virtual-scroll
  [enabled]="virtualScrolling"
  [scrollUpdate]="scrollUpdate"
  [rows]="database.treeNodesView"
>
  <mat-table
    fxLayout="column"
    fxLayoutAlign="start stretch"
    matSort
    (ctrlShift)="onCtrlShift($event)"
    (matSortChange)="onSortColumn($event)"
    [dataSource]="dataSource"
    class="tree-table-container"
    [ngClass]="{
      'tree-table-border': !!columnGroups,
      'multiple-line-table': textOnMultipleLines
    }"
  >
    <!-- first column tree, header and tree render -->
    <ng-container
      matColumnDef="name"
      [sticky]="stickyColumn === 'name'"
    >
      <mat-header-cell
        [ngClass]="{
          'column-header': true,
          divider: columns.length > 0,
          'last-column': stickyColumn === 'name'
        }"
        *matHeaderCellDef
        [ngStyle]="{
          flex: '1 0 ' + treeWidth,
          'justify-content': 'space-between'
        }"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span
            [style.paddingLeft.px]="5"
            *ngIf="allowSelectAll"
          >
            <mat-checkbox
              appearance="small"
              class="node-item"
              color="primary"
              [checked]="isSelectAllChecked()"
              [indeterminate]="selectAllCheckedIndeterminate()"
              (change)="onSelectAllCheckboxChange($event)"
            >
            </mat-checkbox>
          </span>

          <tree-inline-menu
            [panelClass]="inlineMenuWithIcons && 'panel-with-icons'"
            [menu]="treeHeaderInlineMenu"
            (menuClick)="onTreeHeaderMainMenuClick()"
            (itemClick)="onTreeHeaderMenuClick($event)"
            class="header-tree-inline-menu"
          ></tree-inline-menu>

          <span
            mat-sort-header
            disableClear
            >{{ treeTitle }}</span
          >
        </div>
        <div
          *ngIf="cellEditProcessing"
          class="calculating"
        >
          Calculating
          <loading-dots
            [smallDots]="true"
            [whiteDots]="true"
          ></loading-dots>
        </div>
      </mat-header-cell>

      <!-- tree node render -->
      <mat-cell
        class="nowrap cell {{ data.rowCss }}"
        [ngClass]="{
          divider: columns.length > 0,
          'multiple-line-cell': textOnMultipleLines,
          'last-column': stickyColumn === 'name'
        }"
        [ngStyle]="{
          flex: '1 0 ' + treeWidth,
          'justify-content': 'space-between'
        }"
        *matCellDef="let data"
      >
        <span
          class="nohighlight"
          (dblclick)="onTreeNodeDoubleClick(data)"
          [ngClass]="{
            'multiple-line': textOnMultipleLines,
            'single-line': !textOnMultipleLines,
            'expandable-tree-node': data.expandable
          }"
        >
          <!-- second level checkbox -->
          <mat-checkbox
            *ngIf="isSecondLevelNodeWithCheckbox(data)"
            appearance="small"
            class="node-item"
            [checked]="data.checked"
            [disabled]="data.disabled"
            [indeterminate]="checkedIndeterminate(data.treeNode)"
            [style.marginLeft.px]="data.level * 24"
            (click)="onSecondLevelCheckboxClick(data, $event)"
            color="primary"
          >
          </mat-checkbox>

          <!-- chevron button -->
          <button
            mat-icon-button
            class="node-item"
            [style.width]="!data.expandable ? '0px' : '24px'"
            [style.visibility]="!data.expandable ? 'hidden' : ''"
            [style.marginLeft.px]="
              isSecondLevelNodeWithCheckbox(data) ? data.level : data.level * 24
            "
            (click)="toggle(data)"
          >
            <!-- show chevron icon... -->

            <mat-icon
              *ngIf="!data.expanding; else loadingNode"
              class="mat-icon-rtl-mirror {{ data.css || '' }}"
            >
              {{
                treeControl.isExpanded(data) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>

          <!-- or spinner -->
          <ng-template #loadingNode>
            <mat-progress-spinner
              class="loading-node"
              mode="indeterminate"
              diameter="17"
            ></mat-progress-spinner>
          </ng-template>

          <ng-container
            [ngTemplateOutlet]="
              data.checkbox ? checkboxTemplate : labelTemplate
            "
            [ngTemplateOutletContext]="{ row: data }"
          >
          </ng-container>
        </span>
        <loading-dots
          *ngIf="
            cellEditProcessing &&
            (data.rowCss?.includes('loading-dots') ||
              data.rowCss?.includes('table-total-row'))
          "
          [whiteDots]="data.rowCss.includes('table-total-row')"
        ></loading-dots>
      </mat-cell>
    </ng-container>

    <!-- table columns render -->
    <ng-container
      *ngFor="let column of columns; trackBy: columnTrackBy"
      matColumnDef="{{ column.columnDef }}"
    >
      <ng-container
        [ngTemplateOutlet]="headerTemplate"
        [ngTemplateOutletContext]="{ column: column }"
      >
      </ng-container>

      <!-- markup for the column header -->
      <ng-template
        #headerTemplate
        let-column="column"
      >
        <mat-header-cell
          class="column-header divider {{ column.group?.cellCss }} {{
            column.css
          }}"
          [ngStyle]="{
            flex:
              '0 0 ' +
              (column?.collapsed
                ? 31
                : column.width
                ? column.width
                : DEFAULT_COLUMN_WIDTH) +
              'px',
            'justify-content': 'flex-end'
          }"
          [ngClass]="{
            'column-string':
              column.columnType === 'string' || !column.columnType,
            'column-number': column.columnType === 'number',
            'hide-column': !!column.hidden,
            'first-column':
              column.group &&
              column.group.count !== undefined &&
              column.group.count !== 0,
            'last-column': column.last !== undefined && column.last === true
          }"
          [matTooltip]="column.tooltip"
          *matHeaderCellDef
          [attr.data-tracking]="'column-' + column.columnDef"
        >
          <span
            class="{{ column.css }} {{
              column.group?.cellCss
            }} column-header-label"
            disableClear
            *ngIf="!column?.collapsed"
            >{{
              column.showUnits
                ? (column | appendUnits : unitsText)
                : column.header
            }}
            <br *ngIf="column.header2" />
            {{ column.header2 ? " " + column.header2 : "" }}
          </span>
          <ng-container [ngTemplateOutlet]="column.inlineTemplate">
          </ng-container>
        </mat-header-cell>
      </ng-template>

      <!-- each cell, whether editable or not -->
      <mat-cell
        class="divider cell with-sort-icon {{ column.group?.cellCss }} {{
          row.rowCss
        }} {{ row.cellCss ? row.cellCss[column.columnDef] : '' }}"
        [ngClass]="{
          'column-string': column.columnType === 'string' || !column.columnType,
          'column-number': column.columnType === 'number',
          'hide-column': !!column.hidden,
          'collapse-column': !!column?.colapsed,
          'first-column':
            column.group &&
            column.group.count !== undefined &&
            column.group.count !== 0,
          'last-column': column.last !== undefined && column.last === true
        }"
        [ngStyle]="{
          flex:
            '0 0 ' +
            (column?.collapsed
              ? 31
              : column.width
              ? column.width
              : DEFAULT_COLUMN_WIDTH) +
            'px'
        }"
        *matCellDef="let row; let index = index"
      >
        <div *ngIf="row.id === 'schedule|total'; else noSort">
          <div mat-sort-header></div>
        </div>
        <ng-template #noSort></ng-template>
        <ng-container
          [ngTemplateOutlet]="
            row._hide && row.rowCss !== 'table-total-row'
              ? skeletonTemplate
              : (column | cellEditor : row.editable)
              ? editorTemplate
              : simpleTemplate
          "
          [ngTemplateOutletContext]="{ row: row, column: column, index: index }"
        >
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- table columns template hookup -->
    <mat-header-row
      [ngClass]="{ 'hide-column': columns.length == 0 && !treeTitle }"
      class="columns-row"
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      class="row"
      [ngClass]="{ 'total-row': row.rowCss === 'table-total-row' }"
      [attr.data-tracking]="row.name"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>
</virtual-scroll>

<!-- tree node checkbox markup -->
<ng-template
  #checkboxTemplate
  let-row="row"
>
  <mat-checkbox
    appearance="small"
    class="node-item"
    [checked]="isChecked(row)"
    [disabled]="row.disabled"
    [indeterminate]="checkedIndeterminate(row.treeNode)"
    (click)="onCheckboxClicked(row, $event)"
    color="primary"
  >
  </mat-checkbox>
  <tree-inline-menu
    *ngIf="row.inlineMenu"
    [menu]="row.inlineMenu"
    appearance="small"
    (itemClick)="onInlineMenuClick(row, $event)"
    [panelClass]="inlineMenuWithIcons && 'panel-with-icons'"
  ></tree-inline-menu>

  <div
    *ngIf="!row.menu; else rightClickMenu"
    [matTooltip]="row.tooltip ? row.tooltip['name'] : ''"
    [matTooltipClass]="row.tooltipCss ? row.tooltipCss['name'] : ''"
    class="checkbox-label {{ row.css || '' }}"
    (click)="onCheckboxLabelClick(row)"
    [ngClass]="{ disabled: row.disabled, link: row.nodeType === 'link' }"
    [dndDisableIf]="disableDragging || row.disabled"
    [dndDraggable]="getDragData(row)"
    [dndDisableDragIf]="row.expanding || row.disabled || disableDragging"
    [dndEffectAllowed]="'copy'"
    (dndStart)="onDragStart($event, row)"
    (dndEnd)="onDragEnd()"
  >
    <ng-container
      [ngTemplateOutlet]="row.inlineTemplate"
      [ngTemplateOutletContext]="{ row: row }"
    ></ng-container>
    {{ row.inlineTemplate ? "" : row.name }}
  </div>

  <ng-template #rightClickMenu>
    <tree-context-menu
      [data]="row"
      [menu]="row.menu"
      (itemClick)="onTreeNodeMenuClick(row, $event)"
    >
      <div
        class="checkbox-label {{ row.css || '' }}"
        [ngClass]="{ disabled: row.disabled, link: row.nodeType === 'link' }"
        (click)="onCheckboxLabelClick(row)"
        [dndDisableIf]="disableDragging"
        [dndDraggable]="getDragData(row)"
        [dndDisableDragIf]="row.expanding || disableDragging"
        [dndEffectAllowed]="'copy'"
        (dndStart)="onDragStart($event, row)"
        (dndEnd)="onDragEnd()"
      >
        <ng-container
          [ngTemplateOutlet]="row.inlineTemplate"
          [ngTemplateOutletContext]="{ row: row }"
        ></ng-container>
        {{ row.inlineTemplate ? "" : row.name }}
      </div>
    </tree-context-menu>
  </ng-template>
</ng-template>

<!-- simple label render tree node (no checkbox) -->
<ng-template
  #labelTemplate
  let-row="row"
>
  <tree-inline-menu
    [menu]="row.inlineMenu"
    appearance="small"
    (itemClick)="onInlineMenuClick(row, $event)"
    [panelClass]="inlineMenuWithIcons && 'panel-with-icons'"
  ></tree-inline-menu>

  <ng-template #rightClickMenu>
    <tree-context-menu
      [data]="row"
      [menu]="row.menu"
      (itemClick)="onTreeNodeMenuClick(row, $event)"
    >
      <span
        style="display: inline"
        class="{{ row.css || '' }}"
        [ngClass]="{ link: row.nodeType === 'link' }"
        [dndDisableIf]="disableDragging"
        [dndDraggable]="getDragData(row)"
        [dndDisableDragIf]="row.expanding || disableDragging"
        [dndEffectAllowed]="'copy'"
        (dndStart)="onDragStart($event, row)"
        (dndEnd)="onDragEnd()"
        (click)="onLabelClick(row)"
      >
        <ng-container
          [ngTemplateOutlet]="row.inlineTemplate"
          [ngTemplateOutletContext]="{ row: row }"
        ></ng-container>
        {{ row.inlineTemplate ? "" : row.name }}
      </span>
    </tree-context-menu>
  </ng-template>

  <span
    *ngIf="!row.menu; else rightClickMenu"
    style="display: inline"
    class="{{ row.css || '' }} label-text"
    [ngClass]="{ link: row.nodeType === 'link' }"
    [dndDisableIf]="disableDragging"
    [dndDraggable]="getDragData(row)"
    [dndDisableDragIf]="row.expanding || disableDragging"
    [dndEffectAllowed]="'copy'"
    (dndStart)="onDragStart($event, row)"
    (dndEnd)="onDragEnd()"
    (click)="onLabelClick(row)"
  >
    <ng-container
      [ngTemplateOutlet]="row.inlineTemplate"
      [ngTemplateOutletContext]="{ row: row }"
    ></ng-container>
    {{ row.inlineTemplate ? "" : row.name }}
  </span>
</ng-template>

<!-- editor value display -->
<ng-template
  #editorTemplate
  let-row="row"
  let-column="column"
  let-index="index"
>
  <cell-edit
    data-tracking="plan-page-table-editable-cell"
    reference="{{ column.columnDef }}|{{ index }}"
    [matTooltip]="row.tooltip ? row.tooltip[column.columnDef] : ''"
    [matTooltipClass]="row.tooltipClass"
    [value]="
      row.data
        ? (column.cell(row)
          | customPipe : column.pipe?.token : column.pipe?.args)
        : 0
    "
    [cellType]="column.columnType"
    [menu]="column.menu"
    (itemClick)="onColumnMenuClick(column, $event)"
    (edited)="onEditorSave(column, row, $event)"
    [readonly]="readonly"
    (blur)="editSelectionHandler.checkEditStatus()"
    (captureInstance)="editSelectionHandler.addEditor($event)"
    (navigation)="editSelectionHandler.navigation($event)"
    class="cell-contents-editor"
    [ngClass]="{ 'collapse-cell': column.collapsed }"
  >
  </cell-edit>
</ng-template>

<!-- empty template to display when the cell is off screen.controlled with ._hide on FlatTreeNode -->
<ng-template #skeletonTemplate>
  <div class="skeleton-item"></div>
</ng-template>

<!-- simple functionality render column item -->
<ng-template
  #simpleTemplate
  let-row="row"
  let-column="column"
  let-index="index"
>
  <span
    class="cell-contents"
    [ngClass]="{
      'empty-cell':
        (row.data ? row.data[column.columnDef] : column.cell(row)) ==
        emptyStringCell
    }"
    [matTooltip]="row.tooltip ? row.tooltip[column.columnDef] : ''"
    [matTooltipClass]="row.tooltipCss ? row.tooltipCss[column.columnDef] : ''"
    [ngClass]="{ 'collapse-cell': column.collapsed }"
  >
    <ng-container
      [ngTemplateOutlet]="
        row.cellTemplate ? row.cellTemplate : defaultCellTemplate
      "
      [ngTemplateOutletContext]="{ row: row, column: column }"
    >
    </ng-container>
  </span>
</ng-template>

<ng-template
  #defaultCellTemplate
  let-row="row"
  let-column="column"
>
  {{ column.cell(row) | customPipe : column.pipe?.token : column.pipe?.args }}
</ng-template>

<!-- drag image when dragging -->
<div
  #dndDragImage
  class="dnd-image"
>
  <span
    *ngFor="let item of draggedItems | slice : 0 : 5"
    class="dnd-image-item"
    >{{ item }}</span
  >
  <span
    *ngIf="draggedItems.length > 5"
    class="dnd-image-item-count"
  >
    ... {{ draggedItems.length }} items selected
  </span>
</div>
