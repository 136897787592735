<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Count coding"
    class="dialog-title"
    data-tracking="count-dialog"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
    >
      close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content>
    <mat-label>Set count statement parameters</mat-label>
    <div
      fxLayout="column"
      class="count-controls-container"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <mat-form-field
          appearance="outline"
          class="count-control"
        >
          <mat-select
            [(ngModel)]="selectedParameter"
            disableOptionCentering
            panelClass="disable-centering"
            data-tracking="selected-boolean-parameter"
          >
            <mat-option
              *ngFor="let parameter of parameters"
              [value]="parameter"
              [attr.data-tracking]="parameter"
            >
              {{ parameter }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          *ngIf="selectedParameter !== operator.range"
          class="count-control"
        >
          <input
            matInput
            type="number"
            [(ngModel)]="countValue"
            min="1"
            data-tracking="count-value-input"
          />
        </mat-form-field>
      </div>

      <div
        *ngIf="selectedParameter === operator.range"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <mat-form-field
          appearance="outline"
          class="count-control"
        >
          <mat-label>Start</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="startValue"
            min="1"
            data-tracking="start-value-input"
          />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="count-control"
        >
          <mat-label>End</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="endValue"
            min="1"
            data-tracking="end-value-input"
          />
        </mat-form-field>
      </div>

      <div
        *ngIf="warning"
        class="warning"
      >
        {{ warning }}
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onButtonClick(false)"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick(true)"
      data-tracking="count-coding-ok-button"
    >
      OK
    </button>
  </mat-dialog-actions>
</div>
