import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { TupApiServiceBase } from '@telmar-global/tup-auth';
import uniqid from 'uniqid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from './snackbar.service';

const tupApiKeyEnv = environment.production ? environment : environment.engines;

@Injectable({
  providedIn: 'root',
})
export class ApiService extends TupApiServiceBase {
  constructor(
    http: HttpClient,
    private snackBar: MatSnackBar,
    private snackbarService: SnackbarService
  ) {
    super(http, tupApiKeyEnv);
  }

  request(
    method: string,
    rootUrl: string,
    endPoint: string,
    options?: any
  ): Observable<any> {
    let url = rootUrl + endPoint;

    const uid = uniqid();
    const stopTimer = this.logStart(url, uid);

    return super
      .request(method, url, options)
      .pipe(this.handleHttpErrorResponse(method, url, stopTimer, options));
  }

  // Some calls are just made and forgotten about and not observed
  unobservedRequest(
    method: string,
    rootUrl: string,
    endPoint: string,
    options?: any
  ): void {
    const url = rootUrl + endPoint;
    super.request(method, url, options);
  }

  handleHttpErrorResponse(
    method: string,
    url: string,
    stopTimer: Function,
    options?: any
  ) {
    return pipe(
      tap(() => this.logEnd(url, stopTimer)),

      catchError((error: HttpErrorResponse) => {
        console.log(`ERROR DURING CALL: ${url}`, error);
        const suppressErrors = options ? options.body?.suppressErrors : false;

        const err = error.error?.message || error.error?.error;
        const errorMessage = err
          ? typeof err === 'string'
            ? err
            : err.join(' ')
          : '';

        if (errorMessage && !suppressErrors) this.showMessage(errorMessage);

        return throwError(errorMessage);
      }) // catchError
    ); //pipe
  }

  public responseTimer(startTime: number = new Date().getTime()): () => number {
    return (): number => new Date().getTime() - startTime;
  }

  private logStart(url: string, uid: string): Function {
    console.group('request', url);
    return this.responseTimer();
  }

  private logEnd(url: string, stopTimer: Function) {
    console.log(`${url} completed in: ${stopTimer()}ms`);
    console.groupEnd();
  }

  // error message handler specific for errors during calls (eventually replaced with a reporting system)
  // no longer using the dialogService as it was causing a circular reference issue
  showMessage(message: string | string[]) {
    const messageArray = typeof message === 'string' ? [message] : message;
    this.snackbarService.showErrorSnackBar(messageArray.join(' '));
  }
}
