import { Injectable } from '@angular/core';
import * as tot from 'src/app/models/planning-tot-columns.models';
import * as veh from 'src/app/models/planning-veh-columns.models';
import { TreeTableColumn } from '../components/tree-table/tree-table.models';
import {
  ColumnData,
  MediaTypeColumn,
  SurveyMediaTypeColumn,
} from '../models/planning-columns.models';

@Injectable({
  providedIn: 'root',
})
export class PlanningColumnsService {
  columns: SurveyMediaTypeColumn[] = [];

  totalColumns: TreeTableColumn[] = [
    tot.Column_Tot_AvgFreq,
    tot.Column_Tot_CPT,
    tot.Column_Tot_GRPs,
    tot.Column_Tot_Impressions,
    tot.Column_Tot_Inserts,
    tot.Column_Tot_Reach000,
    tot.Column_Tot_ReachPct,
    tot.Column_Tot_TotalCost,
    tot.Column_Tot_UniqueReach000,
    tot.Column_Tot_EffReach,
    tot.Column_Tot_UniqueReachPct,
  ];
  vehicleColumns: TreeTableColumn[] = [
    veh.Column_Audience000,
    veh.Column_AudiencePct,
    veh.Column_AvgFreq,
    veh.Column_Duration,
    veh.Column_GRPs,
    veh.Column_Impressions,
    veh.Column_Inserts,
    veh.Column_MediaType,
    veh.Column_Reach000,
    veh.Column_ReachPct,
  ];

  constructor() {}

  getColumns(surveyCode: String): SurveyMediaTypeColumn {
    return this.columns.find((column) => column.surveyCode === surveyCode);
  }

  // return column definitions for the given surveycode and mediatype
  getColumnsForMediaType(surveyCode: String, mediaType: string): ColumnData[] {
    const cols = this.getColumns(surveyCode);
    const med = cols
      ? cols.mediaType.find((med) => med.mediaType === mediaType)
      : null;

    return med ? med.columns : null;
  }

  // called from codebook service with column definitions to be parsed into a columnSet, stored as columns:SurveyMediaTypeColumn[]
  parseColumns(surveyCode: string, columns: any) {
    if (!columns) return;

    // find existing and delete
    const index = this.columns.findIndex(
      (column) => column.surveyCode === surveyCode
    );
    if (index !== -1) this.columns.splice(index, 1);

    const columnSet: SurveyMediaTypeColumn = {
      surveyCode: surveyCode,
      mediaType: [],
    };

    // parse all the mediatypes in this columns object
    const mediaTypes = Object.keys(columns); //'Digital', 'Print', 'General': strings
    mediaTypes.forEach((mediaType) => {
      const mediaSet = columns[mediaType]; // 'Digital' objects
      const mediaColumns = Object.keys(mediaSet); // 'ins', 'cpt', 'audPct' strings

      const cols: ColumnData[] = mediaColumns.map((mediaColumn) => {
        const [header, tooltip] = mediaSet[mediaColumn].split(';');
        return { columnDef: mediaColumn, header, tooltip: tooltip || '' };
      });

      columnSet.mediaType.push({
        mediaType,
        columns: cols,
      });
    });

    this.columns.push(columnSet);
  }
}
