import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NodeMenuClickEvent,
  TreeTableColumn,
  TreeTableMenuItem,
  TreeTableNode,
} from '../components/tree-table/tree-table.models';
import { CtrlShiftKeyStates } from '../directives/ctrl-shift.directive';
import { MediaVehicle } from '../models/codebook.models';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { GAEvents } from '../models/analytics.model';
import { DialogService, infoDialogTemplate } from '../services/dialog.service';
import {
  Column_Audience000,
  Column_AudiencePct,
  Column_CompositionIndex,
  Column_CompositionPct,
  Column_PotentialReach000,
  Column_PotentialReachPct,
  READONLY_ROW,
} from '../models/planning-veh-columns.models';
import { MediaPlannerService } from '../services/media-planner.service';
import { AppendUnitsPipe } from '../pipes/append-units.pipe';
import { EMPTY_STRING_CELL } from '../classes/planning-data';
import { TreeTableComponent } from '../components/tree-table/tree-table.component';
import {
  FileType,
  MultiSurveyVehicleConfig,
} from '../models/multi-survey.models';
import {
  dialogActionType,
  MixedDataDialogModel,
} from '../components/dialogs/import-mixed-data-dialog/import-mixed-data-dialog.component';
import { MultiSurveyService } from '../services/multi-survey.service';
import { QuestionDialogModelOptions } from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import {
  RenameItemType,
  RenameMediaDialogModel,
} from '../components/dialogs/rename-media-dialog/rename-media-dialog.component';
import { isNotNullOrUndefined } from '../pipes/pipeable-operators';

const MEDIATYPE_ALL = 'All Mediatypes';
const SAVE_AS_CUSTOM_MEDIA_MULTISURVEY_DISABLED_MESSAGE =
  'You cannot save the selected vehicles as Custom Media as they are coming from multiple surveys';

export interface MediaResult {
  audience: number;
  grossAudience: number;
  resps: number;
  potentialReach: number;
  compositionIndex: number;
  compositionPct: number;
}

export interface MediaStatement {
  selected: boolean;
  error?: boolean;
  id: string;
  title: string;
  addressableMessage: string;
  vehicle: MediaVehicle;
  targets: MediaResult[];
  hidden?: boolean;
  level?: number;
}

export enum ESGColumnStatus {
  unavailable,
  hidden,
  shown,
}

@Component({
  selector: 'media-table',
  templateUrl: './media-table.component.html',
  styleUrls: ['./media-table.component.scss'],
})
export class MediaTableComponent implements OnInit, OnChanges {
  @ViewChild('addressableRow') addressableRow: TemplateRef<any>;
  @ViewChild('cell') cell: TemplateRef<any>;
  @ViewChild('mediaTable') mediaTable: TreeTableComponent;
  @ViewChild('importedMediaTypeRow') importedMediaTypeRow: TemplateRef<any>;

  @Input() unitsText: string = '';
  @Input() targets: { title: string; population: number }[];
  @Input() dataSource: MediaStatement[];
  @Input() processing: boolean;
  @Input() mediaTypeFilter: string;
  @Input() esgScores: ESGColumnStatus;
  @Input() loadingTreeTable: boolean = false;

  @Output() dataSourceChange: EventEmitter<MediaStatement[]> = new EventEmitter<
    MediaStatement[]
  >();
  @Output() dbleClick: EventEmitter<MediaStatement> =
    new EventEmitter<MediaStatement>();
  @Output() deleteRows: EventEmitter<MediaStatement[]> = new EventEmitter<
    MediaStatement[]
  >();
  @Output() dropListDropped: EventEmitter<any> = new EventEmitter<any>();
  @Output() headerMenuItemClick: EventEmitter<TreeTableMenuItem> =
    new EventEmitter<TreeTableMenuItem>();
  appendUnits = new AppendUnitsPipe();
  vehicleData: TreeTableNode[];
  ESGMenuItems: TreeTableMenuItem[] = [
    { label: 'Show ESG score', data: 'esg', matIcon: 'visibility' },
    { label: 'Hide ESG score', data: 'esg', matIcon: 'visibility_off' },
  ];
  SurveyMenuItems: TreeTableMenuItem[] = [
    { label: 'Show surveys', data: 'survey', matIcon: 'visibility' },
    { label: 'Hide surveys', data: 'survey', matIcon: 'visibility_off' },
  ];
  suveyColumnToBeShown = true;

  headerMenu: TreeTableMenuItem[] = [
    { label: 'Manage audience metrics', data: 'open', matIcon: 'table_rows' },
    {
      label: 'Save as Custom Media',
      data: 'owncodes',
      matIcon: 'star',
      disabled: false,
      tooltip: '',
    },
    { label: 'Remove selected', data: 'sel', matIcon: 'delete' },
  ];

  esgColumn = {
    columnDef: 'esg',
    header: 'ESG score',
    type: 'esg',
    columnType: 'number',
    targetId: -2,
    cell: (row: TreeTableNode) => {
      if (row.level === 0) {
        return '';
      }
      const val = row.data.item.vehicle.ESG?.length
        ? '' + row.data.item.vehicle.ESG[0].value
        : '-1';
      return val === '-1' ? '-' : val;
    },
  };

  surveyColumn = {
    columnDef: 'survey',
    header: 'Survey',
    type: 'survey',
    columnType: 'string',
    targetId: -3,
    cell: (row: TreeTableNode) => {
      if (row.level === 0) {
        return '';
      }
      return row.data.item.vehicle.survey.code;
    },
  };

  vehicleMenu: TreeTableMenuItem[] = [
    {
      label: 'Add to Custom Media',
      data: 'owncodes',
      matIcon: 'star',
      disabled: true,
    },
    { label: 'Remove media', data: 'sel', matIcon: 'delete' },
  ];

  mrfInlineMenu: TreeTableMenuItem[] = [
    { label: 'Rename', data: 'importedMedia|rename', matIcon: 'text_format' },
    { label: 'Delete ', data: 'importedMedia|delete', matIcon: 'delete' },
  ];

  dauInlineMenu: TreeTableMenuItem[] = [
    {
      label: 'Change audience',
      data: 'importedMedia|change_audience',
      matIcon: 'autorenew',
    },
    ...this.mrfInlineMenu,
  ];

  manualInputInlineMenu: TreeTableMenuItem[] = [
    {
      label: 'Update manual entry',
      data: 'manualInput|update',
      matIcon: 'autorenew',
    },
    { label: 'Delete ', data: 'manualInput|delete', matIcon: 'delete' },
  ];

  columns = [];

  get treeTitleMedia() {
    return `Media ${
      this.dataSource?.length ? `(${this.dataSource.length})` : ''
    }`;
  }

  displayedColumns = this.columns.map((c) => c.columnDef);

  keyStates: CtrlShiftKeyStates = {
    ctrlPressed: false,
    shiftPressed: false,
  };
  lastClicked: MediaStatement;

  get selectedMetrics(): TreeTableColumn[] {
    return this.mediaPlannerService.plan.mediaSettings.selectedMetrics;
  }
  get metricsOrder(): TreeTableColumn[] {
    return this.mediaPlannerService.plan.mediaSettings.metricsOrder;
  }

  get orderMetric(): string {
    return this.mediaPlannerService.plan.mediaSettings.sortBy;
  }
  get selectedColumn(): string {
    return this.mediaPlannerService.plan.mediaSettings.selectedColumn;
  }

  get selectedMetricsOrdered(): TreeTableColumn[] {
    const metrics = this.metricsOrder?.filter((metricOrder) =>
      this.selectedMetrics.find(
        (selectedMetric) => metricOrder.columnDef === selectedMetric.columnDef
      )
    );
    return metrics.length > 0 ? metrics : [Column_PotentialReach000];
  }

  get primarySurveyCode(): string {
    return this.mediaPlannerService.plan?.primarySurvey?.code || '';
  }

  constructor(
    private analyticsService: TupAnalyticsService,
    private dialogService: DialogService,
    private mediaPlannerService: MediaPlannerService,
    private multiSurveyService: MultiSurveyService
  ) {}

  ngOnInit(): void {
    this.columns.push({
      columnDef: 'metrics',
      header: 'Metrics',
      type: 'metrics',
      columnType: 'string',
      tooltip: undefined,
      targetId: -1,
      cell: () => '',
    });
    this.buildTargetColumns();
    this.filterByMediaType();
    this.populateTable();
    this.setESGColumnAndHeader();
    this.setSurveyColumnAndHeader();
  }

  populateTable() {
    const vehicleData: TreeTableNode[] = [];
    const mediaTypes = this.buildMediaTypes();

    mediaTypes.forEach((mediaType) => {
      const mediaTypeName = mediaType.mediaType;
      let items = this.dataSource.filter(
        (val) => val.vehicle.mediaType === mediaTypeName && !val.hidden
      );
      const mediaTypeTotal = items.length;
      let mediaTypeInlineMenu = null;

      let multiSurveyConfig;
      let multiSurveyRowCss = '';
      if (mediaType.isMultiSurvey) {
        multiSurveyRowCss = 'multi-survey-title-row';
        const multiSurveyVeh = this.mediaPlannerService.plan.targets.length
          ? this.mediaPlannerService.plan.targets[0].vehicles.find(
              (veh) =>
                veh.mediaType === mediaTypeName &&
                veh.isMultiSurvey &&
                veh.multiSurveyConfig
            )
          : null;
        multiSurveyConfig = multiSurveyVeh
          ? multiSurveyVeh.multiSurveyConfig
          : undefined;

        // don't show vehicles of manual input media type
        if (multiSurveyConfig.manualInput) {
          items = items.filter((item) => item.id !== multiSurveyVeh.id);
        }

        if (multiSurveyConfig.manualInput) {
          mediaTypeInlineMenu = this.manualInputInlineMenu;
        } else {
          const multiSurveyFile =
            this.mediaPlannerService.plan.multiSurveys.multiSurvey(
              multiSurveyConfig.multiSurveyId
            );
          if (multiSurveyFile.fileType === FileType.DAU)
            mediaTypeInlineMenu = this.dauInlineMenu;
          if (multiSurveyFile.fileType === FileType.MRF)
            mediaTypeInlineMenu = this.mrfInlineMenu;
        }
      }

      vehicleData.push({
        editable: READONLY_ROW,
        rowCss: `table-title-row ${multiSurveyRowCss}`,
        css: `table-title-row ${multiSurveyRowCss}`,
        name: `${mediaTypeName} ${
          multiSurveyConfig?.manualInput ? '' : `(${mediaTypeTotal})`
        }`,
        expanded: true,
        data: { name: mediaTypeName, multiSurveyConfig },
        inlineMenu: mediaTypeInlineMenu,
        inlineTemplate: mediaType.isMultiSurvey
          ? this.importedMediaTypeRow
          : null,
        children: items.map((item) => {
          return {
            name: item.title,
            rowCss: 'media-row',
            nodeType: item.vehicle.addressable ? 'addressable' : '',
            tooltip: { [`name`]: this.getTooltipText(item) },
            tooltipCss: { [`name`]: this.getTooltipClass(item) },
            editable: READONLY_ROW,
            id: item.id,
            data: {
              item,
              values: this.columns.map((column) => ({
                id: column.targetId,

                // potential reach000
                [Column_PotentialReach000.columnDef]: item.targets[
                  column.targetId
                ]?.potentialReach
                  ? this.formatFloat(
                      item.targets[column.targetId].potentialReach || 0,
                      Column_PotentialReach000.decimals
                    )
                  : EMPTY_STRING_CELL,

                // potential reach %
                [Column_PotentialReachPct.columnDef]: item.targets[
                  column.targetId
                ]?.potentialReach
                  ? this.formatFloat(
                      (item.targets[column.targetId].potentialReach /
                        (this.targets[column.targetId].population || 1)) *
                        100,
                      Column_PotentialReachPct.decimals
                    )
                  : EMPTY_STRING_CELL,

                // audience 000
                [Column_Audience000.columnDef]: item.targets[column.targetId]
                  ?.audience
                  ? this.formatFloat(
                      item.targets[column.targetId].audience,
                      Column_Audience000.decimals
                    )
                  : EMPTY_STRING_CELL,

                // audience %
                [Column_AudiencePct.columnDef]: item.targets[column.targetId]
                  ?.audience
                  ? this.formatFloat(
                      (item.targets[column.targetId].audience /
                        (this.targets[column.targetId].population || 1)) *
                        100,
                      Column_AudiencePct.decimals
                    )
                  : EMPTY_STRING_CELL,

                // composition index
                [Column_CompositionIndex.columnDef]: item.targets[
                  column.targetId
                ]?.compositionIndex
                  ? this.formatFloat(
                      item.targets[column.targetId].compositionIndex,
                      Column_CompositionIndex.decimals
                    )
                  : EMPTY_STRING_CELL,

                // composition %
                [Column_CompositionPct.columnDef]: item.targets[column.targetId]
                  ?.compositionPct
                  ? this.formatFloat(
                      item.targets[column.targetId].compositionPct,
                      Column_CompositionPct.decimals
                    )
                  : EMPTY_STRING_CELL,
              })),
            },
            checkbox: !item.vehicle.isMultiSurvey,
            inlineMenu: !item.vehicle.isMultiSurvey ? this.vehicleMenu : null,
            cellTemplate: this.cell,
            inlineTemplate: item.vehicle.addressable
              ? this.addressableRow
              : undefined,
          };
        }),
      });
    });
    this.vehicleData = vehicleData;
  }
  buildMediaTypes() {
    const mediaTypes = [];
    this.dataSource
      .filter((val) =>
        this.mediaTypeFilter !== MEDIATYPE_ALL
          ? val.vehicle.mediaType === this.mediaTypeFilter
          : val
      )
      .forEach((val) => {
        if (
          !mediaTypes.find((type) => type.mediaType === val.vehicle.mediaType)
        ) {
          const { mediaType, isMultiSurvey } = val.vehicle;
          mediaTypes.push({ mediaType, isMultiSurvey });
        }
      });
    return mediaTypes;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const showESG = changes['esgScores'];
    const loadingTreeTable = changes['loadingTreeTable'];

    if (
      loadingTreeTable &&
      loadingTreeTable.currentValue !== loadingTreeTable.previousValue
    ) {
      const menuToUpdate = this.headerMenu.find(
        (menu) => menu.label === 'Save as Custom Media'
      );

      if (menuToUpdate) {
        menuToUpdate.disabled = loadingTreeTable.currentValue;
      }
    }

    if (showESG && showESG.previousValue !== showESG.currentValue) {
      this.setESGColumnAndHeader();
    }
    const mediaTypeFilter = changes['mediaTypeFilter'];
    if (
      mediaTypeFilter &&
      !mediaTypeFilter.firstChange &&
      mediaTypeFilter.previousValue !== mediaTypeFilter.currentValue
    ) {
      this.filterByMediaType();
      this.populateTable();
      this.mediaTable
        ? this.mediaTable.refresh.emit({ newData: this.vehicleData })
        : undefined;
    }
    const dataSource = changes['dataSource'];
    if (dataSource && dataSource.previousValue !== dataSource.currentValue) {
      this.setSurveyColumnAndHeader();
      this.buildTargetColumns();
      this.filterByMediaType();
      this.populateTable();
    }

    if (this.mediaPlannerService.plan.currentSurvey && !this.unitsText) {
      const surveyMeta = this.mediaPlannerService.plan.surveyMetaData.meta(
        this.mediaPlannerService.plan.currentSurvey.code
      );
      this.unitsText = surveyMeta ? surveyMeta.reportUnitText : '';
    }
  }

  setSurveyColumnAndHeader() {
    let index = 0;
    index = this.headerMenu.findIndex(
      (menuItems) => menuItems.data === 'survey'
    );
    const menuIndex = this.suveyColumnToBeShown ? 1 : 0;

    if (index === -1)
      this.headerMenu.splice(1, 0, this.SurveyMenuItems[menuIndex]);
    if (index !== -1) this.headerMenu[index] = this.SurveyMenuItems[menuIndex];

    // do column visibility
    index = this.columns.findIndex((column) => column.columnDef === 'survey');
    if (this.suveyColumnToBeShown && index === -1) {
      this.columns = [this.surveyColumn, ...this.columns]; // show
    }
    if (!this.suveyColumnToBeShown && index !== -1)
      this.columns = this.columns.filter((val) => val.columnDef !== 'survey');
  }

  setESGColumnAndHeader() {
    let index = 0;

    // ensure menu item and column are gone
    if (this.esgScores === ESGColumnStatus.unavailable) {
      index = this.headerMenu.findIndex(
        (menuItems) => menuItems.data === 'esg'
      );
      if (index !== -1) this.headerMenu.splice(index, 1); // if found, kill it

      index = this.columns.findIndex((column) => column.columnDef === 'esg');
      if (index !== -1) this.columns.splice(index, 1); // if found, kill it
    } else {
      // else, do a show/hide, and ensure the menu item is available with correct label
      const menuIndex = this.esgScores === ESGColumnStatus.shown ? 1 : 0;

      // add to header menu
      index = this.headerMenu.findIndex(
        (menuItems) => menuItems.data === 'esg'
      );
      if (index === -1)
        this.headerMenu.splice(1, 0, this.ESGMenuItems[menuIndex]); // not found: add it
      if (index !== -1) this.headerMenu[index] = this.ESGMenuItems[menuIndex]; // was found: use correct label

      // do column visibility
      index = this.columns.findIndex((column) => column.columnDef === 'esg');
      if (this.esgScores === ESGColumnStatus.shown && index === -1) {
        this.columns = [this.esgColumn, ...this.columns]; // show
      }
      if (this.esgScores === ESGColumnStatus.hidden && index !== -1)
        this.columns = this.columns.filter((val) => val.columnDef !== 'esg');
    }
  }

  refresh() {
    this.populateTable();
    this.buildTargetColumns();
  }

  onSave(column: any, row: MediaStatement, value: string) {
    row[column.columnDef] = value;
  }

  onHeaderMenuClick(menu: NodeMenuClickEvent) {
    if (['all', 'sel', 'open'].includes(menu.item.data)) {
      if (menu.item.data === 'open') {
        this.dialogService
          .showMediaAudienceMetrics()
          .afterClosed()
          .subscribe((saved) => {
            if (saved) {
              if (this.orderMetric.length > 0) {
                const column = this.columns.find(
                  (val) =>
                    val.columnDef ===
                    this.mediaPlannerService.plan.mediaSettings.selectedColumn
                );
                const sortDirecion =
                  this.mediaPlannerService.plan.mediaSettings.orderBy;
                this.dataSource.sort((a, b) => {
                  const metric1 =
                    a.targets[column.targetId][
                      this.orderMetric
                        .replace('potentialReach000', 'potentialReach')
                        .replace('audiencePct', 'audience')
                        .replace('potentialReachPct', 'potentialReach')
                    ];
                  const metric2 =
                    b.targets[column.targetId][
                      this.orderMetric
                        .replace('potentialReach000', 'potentialReach')
                        .replace('audiencePct', 'audience')
                        .replace('potentialReachPct', 'potentialReach')
                    ];
                  return sortDirecion === 'asc'
                    ? metric1 - metric2
                    : metric2 - metric1;
                });
                this.populateTable();
                this.mediaTable
                  ? this.mediaTable.refresh.emit({ newData: this.vehicleData })
                  : undefined;
              }
            }
          });
      } else {
        if (menu.item.data === 'all') {
          this.dataSource.forEach((row) => (row.selected = true));
        }
        this.deleteRows.emit(this.dataSource.filter((row) => row.selected));
      }
    }
    if (menu.item.data === 'survey') {
      this.suveyColumnToBeShown = !this.suveyColumnToBeShown;
      this.setSurveyColumnAndHeader();
    }

    this.headerMenuItemClick.emit(menu.item);
    this.analyticsService.e(GAEvents.media_inline_menu, {
      action: `header|${menu.item.label.toLowerCase()}`,
    });
  }
  onTreeInlineMenuClick(event: NodeMenuClickEvent) {
    const { item, row } = event;
    if (item.data === 'sel') {
      this.deleteRows.emit(
        this.dataSource.filter((item) => item.id === row.id)
      );
    }
    if (item.data === 'importedMedia|change_audience') {
      const config: MultiSurveyVehicleConfig = row.data['multiSurveyConfig'];

      this.dialogService
        .openImportMixedDataDialog({
          action: dialogActionType.changeAudience,
          config,
        })
        .afterClosed()
        .subscribe((data: MixedDataDialogModel) => {
          if (data) {
            this.multiSurveyService.multiSurveyStartProcessing.emit('media');
            this.multiSurveyService
              .addDAUDataToPlan(data.planAudienceId, data.documentTarget)
              .subscribe(() => {
                this.multiSurveyService.multiSurveyTargetSelected.emit({
                  currentTab: 'media',
                });
                this.mediaPlannerService.dirty();
              });
          }
        });
    }

    if (item.data === 'importedMedia|rename') {
      const renameMediaConfig = new RenameMediaDialogModel();
      renameMediaConfig.initialName = row.data.name || '';
      renameMediaConfig.newName = row.data.name || '';
      renameMediaConfig.itemType = RenameItemType.importedMedia;
      this.dialogService
        .openRenameMediaDialog(renameMediaConfig)
        .afterClosed()
        .pipe(isNotNullOrUndefined())
        .subscribe((data: RenameMediaDialogModel) => {
          this.multiSurveyService.renameImportedMediaType(
            data.initialName,
            data.newName
          );
          this.dataSource = this.dataSource.map((val) => {
            if (val.vehicle.mediaType === data.initialName) {
              val.vehicle.mediaType = data.newName;
            }
            return val;
          });
          this.dataSourceChange.emit(this.dataSource);
          this.populateTable();
        });
    }

    if (item.data === 'importedMedia|delete') {
      const config: MultiSurveyVehicleConfig = row.data['multiSurveyConfig'];
      const confirmationMessage = `Are you sure you want to delete '${row.name}'?`;
      const options: QuestionDialogModelOptions =
        this.dialogService.getDeleteConfirmationOptions(confirmationMessage);
      this.dialogService
        .confirmation('', 'Are you sure?', options)
        .afterClosed()
        .subscribe((button) => {
          if (button.data === 'delete') {
            const vehicles: string[] = [];
            this.mediaPlannerService.plan.targets[0].vehicles.forEach((veh) => {
              if (
                veh.multiSurveyConfig &&
                veh.multiSurveyConfig.multiSurveyId === config.multiSurveyId
              ) {
                vehicles.push(veh.id);
              }
            });
            this.dataSource.forEach(
              (row) => (row.selected = vehicles.includes(row.id))
            );
            this.deleteRows.emit(this.dataSource.filter((row) => row.selected));
            this.multiSurveyService.removeMultiSurveyFile(config.multiSurveyId);
          }
        });
    }

    if (item.data === 'manualInput|update') {
      const config: MultiSurveyVehicleConfig = row.data['multiSurveyConfig'];
      this.dialogService
        .openManualEntryUpdateDialog({ multiSurveyId: config.multiSurveyId })
        .afterClosed()
        .subscribe((manualInputData) => {
          if (manualInputData) {
            this.multiSurveyService.multiSurveyStartProcessing.emit('media');
            this.multiSurveyService
              .updateManualInput(manualInputData, config.multiSurveyId)
              .subscribe(() => {
                this.multiSurveyService.multiSurveyTargetSelected.emit({
                  currentTab: 'media',
                });
              });
          }
        });
    }

    if (item.data === 'manualInput|delete') {
      const config: MultiSurveyVehicleConfig = row.data['multiSurveyConfig'];
      const confirmationMessage = `Are you sure you want to delete '${row.data.name}'?`;
      const options: QuestionDialogModelOptions =
        this.dialogService.getDeleteConfirmationOptions(confirmationMessage);
      this.dialogService
        .confirmation('', 'Are you sure?', options)
        .afterClosed()
        .subscribe((button) => {
          if (button.data === 'delete') {
            this.deleteRows.emit(
              this.dataSource.filter((row) => row.id === config.multiSurveyId)
            );
          }
        });
    }
    this.analyticsService.e(GAEvents.media_inline_menu, {
      action: `item|${item.label.toLowerCase()}`,
    });
  }

  onVehicleMenuClick(menu: TreeTableMenuItem, data: MediaStatement) {
    if (menu.data === 'sel') {
      this.deleteRows.emit(this.dataSource.filter((row) => row.id === data.id));
    }
    this.analyticsService.e(GAEvents.media_inline_menu, {
      action: `item|${menu.label.toLowerCase()}`,
    });
  }

  onAddMediaToFavourite(data: MediaStatement) {
    console.log('[onAddMediaToFavourite] To Be Implemented', data);
  }

  getTooltipText(row: any): string {
    if (row.vehicle.addressable && !row.vehicle.addressableConfig) {
      return 'Available for addressable audience';
    } else if (row.vehicle.addressable && row.vehicle.addressableConfig) {
      return row.addressableMessage;
    } else {
      return '';
    }
  }

  getTooltipClass(row: any): string {
    if (row.vehicle.addressable && row.vehicle.addressableConfig) {
      return 'addressable-tooltip active';
    } else {
      return 'addressable-tooltip';
    }
  }

  toggleAllRows() {
    const sel = this.dataSource.length ? !this.dataSource[0].selected : false;
    this.dataSource.forEach((d) => (d.selected = sel));

    //sel ? this.selectContainer.selectAll(): this.selectContainer.deselectItems( row=> (row as MediaStatement).selected === sel  );
  }

  isAllSelected(indeterminate: boolean = false): boolean {
    const count = this.dataSource.filter((d) => d.selected).length;
    if (!this.dataSource.length || !count) return false;

    const val = count === this.dataSource.length;
    return indeterminate ? !val : val;
  }

  // called from the view to limit target title length
  formatTargetTitle(title: string): string {
    return title.length < 18 ? title : `${title.slice(0, 15)}...`;
  }

  // checkbox change
  onSelectedChange(row: MediaStatement, value: boolean) {}

  // mat-cell double click emit
  onDbleClick(row: MediaStatement) {
    this.dataSource.forEach((row) => (row.selected = false));
    this.dataSourceChange.emit(this.dataSource);
    this.dbleClick.emit(row);
  }

  buildTargetColumns() {
    if (this.targets && this.targets.length) {
      this.targets.forEach((target, index) => {
        const col = this.columns.find(
          (col) => col.columnDef === `audience_${index}`
        );
        if (col) {
          col.header = `${target.title}`; // ensure name (if renamed) is up to date
          col.tooltip = `${target.title}`;
        } else {
          this.columns.push({
            // add new target
            columnDef: `audience_${index}`,
            columnType: 'number',
            header: `${target.title}`,
            tooltip: `${target.title}`,
            targetId: index,
            type: 'target',
            cell: () => '',
          });
        }
      });

      // remove any misc colums (user removed targets from the audience screen then came here)
      this.columns = this.columns.filter(
        (col) => col.targetId === -1 || col.targetId < this.targets.length
      );
    }

    this.displayedColumns = this.columns.map((c) => c.columnDef);
  }

  getTargetCellValue(metric: any, row: TreeTableNode, column: any) {
    if (column.targetId >= 0) {
      const values = row.data.values.find((row) => row.id === column.targetId);
      return values ? values[metric.columnDef] : '-';
    }
  }

  // return number as correctly formatted string
  formatFloat(value: number, decimals: number = 0): string {
    const options = {
      maximumFractionDigits: decimals == -1 ? 2 : decimals,
      minimumFractionDigits: decimals == -1 ? 2 : 0,
    };

    return value.toLocaleString(undefined, options); // "1,234.57"
  }

  // drag handling events
  // notify the parent so targt objects can be built properly
  onDrop(e) {
    this.dropListDropped.emit();
  }
  onDragEnter(e) {}
  onDragLeave(e) {}
  onDragOver(e) {}

  private filterByMediaType() {
    if (this.dataSource.length > 0 && this.mediaTypeFilter.length > 0) {
      if (this.mediaTypeFilter === MEDIATYPE_ALL) {
        this.dataSource.forEach(
          (media: MediaStatement) => (media.hidden = false)
        );
      } else {
        this.dataSource.forEach((media: MediaStatement) => {
          media.hidden = media.vehicle.mediaType !== this.mediaTypeFilter;
        });
      }
    }
  }

  onPlanningSelectedNodes(nodes: TreeTableNode[]) {
    const nodesId = nodes.map((node) => node.id);
    this.dataSource.forEach((val) => {
      val.selected = nodesId.includes(val.id);
    });
  }

  onTreeHeaderMainMenuClick() {
    this.headerMenu
      .filter((menu) => menu.data === 'owncodes' || menu.data === 'sel')
      .forEach(
        (optionMenu) =>
          (optionMenu.disabled =
            this.dataSource.filter((data) => data.selected).length === 0)
      );

    const surveysUsed = [];
    const ownCodesMenu = this.headerMenu.find(
      (menu) => menu.data === 'owncodes'
    );

    if (ownCodesMenu) {
      this.dataSource
        .filter((data) => data.selected)
        .forEach((data) => {
          if (!surveysUsed.includes(data.vehicle.survey.code)) {
            surveysUsed.push(data.vehicle.survey.code);
          }
        });

      ownCodesMenu.disabled = surveysUsed.length !== 1;

      // Update tooltip only if surveysUsed has 2 or more survey codes
      if (surveysUsed.length >= 2) {
        ownCodesMenu.tooltip =
          SAVE_AS_CUSTOM_MEDIA_MULTISURVEY_DISABLED_MESSAGE;
      } else {
        ownCodesMenu.tooltip = '';
      }
    }
  }

  onImportedMediaInfoClick(row: TreeTableNode) {
    const config: MultiSurveyVehicleConfig = row.data['multiSurveyConfig'];
    this.dialogService.showInfoMessageDialog({
      template: infoDialogTemplate.importedMedia,
      title: row.data.name,
      content:
        this.mediaPlannerService.plan.multiSurveys.getInfoDialogData(config),
    });
  }
}
