<div
  fxLayout="column"
  fxLayoutGap="8px"
>
  <mat-form-field
    appearance="outline"
    tupMatFormField
  >
    <mat-label> Title </mat-label>
    <input
      #manualInputTitleRef
      (keyup)="onKeyUp()"
      matInput
      [(ngModel)]="manualMediaTitle"
    />
  </mat-form-field>

  <div
    fxLayout="row"
    fxLayoutGap="8px"
  >
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex="33"
      [ngClass]="{
        'error-border': maxReachExceededMessage !== ''
      }"
    >
      <mat-label> Reach % </mat-label>
      <input
        matInput
        [(ngModel)]="manualReachPct"
        (input)="onReachInputChange($event)"
        (blur)="onRFInputBlur('reach', $event.target.value)"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex="33"
    >
      <mat-label> Avg. Freq. </mat-label>
      <input
        matInput
        [(ngModel)]="manualAvgFreq"
        (blur)="onRFInputBlur('avgFreq', $event.target.value)"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex="33"
    >
      <mat-label> GRPs </mat-label>
      <input
        matInput
        [(ngModel)]="manualGRPs"
        (blur)="onRFInputBlur('grp', $event.target.value)"
      />
    </mat-form-field>
  </div>
  <div
    fxLayout="row"
    fxLayoutGap="8px"
  >
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex="50"
    >
      <mat-label>Insertions</mat-label>
      <input
        matInput
        [(ngModel)]="manualInserts"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex="50"
    >
      <mat-label>Total cost</mat-label>
      <input
        matInput
        [(ngModel)]="manualTotalCost"
      />
    </mat-form-field>
  </div>
  <div
    *ngIf="maxReachExceededMessage !== ''"
    class="validation-error-message-container"
  >
    <span
      ><strong>{{ maxReachExceededMessage }}</strong></span
    >
  </div>
</div>
