import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
import {
  CONNECTED,
  CONNECTION_CLOSED,
  OPTIMISATION_FINISHED,
  OPTIMISATION_STARTED,
  OptimiseMetrics,
  OptimiseRequest,
  OptimiseRequestConstraints,
  OptimiseResponse,
  OptimiseVehicle,
  SPLIT_ID,
} from '../models/optimise.model';
import { DocumentTarget } from '../models/document.model';
import { EngineService } from './engine.service';
import { ScheduleVehicle } from '../classes/vehicle';
import { Target } from '../classes/target';
import { ResultType } from '../classes/result';
import { WebsocketService } from './websocket.service';
import { Auth } from 'aws-amplify';
import { Schedule } from '../classes/schedule';
import { TargetStatementWithId } from '../models/engine.models';
import { SnackbarService } from './snackbar.service';
import { OptimiseProgressSnackbarComponent } from '../components/dialogs/optimise-progress-snackbar/optimise-progress-snackbar.component';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import uniqid from 'uniqid';

@Injectable({
  providedIn: 'root',
})
export class OptimiseService {
  HARDCODED_WEIGHT_TO_ONE: number = 1;
  optimiseProgressPercentage: Subject<number>;
  optimiseProgressSnackbar: MatSnackBarRef<OptimiseProgressSnackbarComponent> =
    null;
  constructor(
    private apiService: ApiService,
    private engineService: EngineService,
    private wsService: WebsocketService,
    private snackbarService: SnackbarService
  ) {}
  public prepareVehicles(
    target: Target,
    vehicles: ScheduleVehicle[],
    schedule: Schedule,
    targets: Target[],
    constraints?: OptimiseRequestConstraints[]
  ) {
    const vehiclesReq: OptimiseVehicle[] = [];
    const buyingTargetDefinitions: TargetStatementWithId[] = [];
    vehicles.forEach((vehicle) => {
      const isDaypart =
        vehicle.result.type === ResultType.broadcast ||
        target.vehicle(vehicle.vehicleId).dayparts?.length > 0;
      const hasCpm = vehicle.result.cpm !== 0;
      let splitValues: any = undefined;

      const mediaVehicle = target.vehicle(vehicle.vehicleId);
      if (!!constraints && mediaVehicle) {
        splitValues = [
          {
            splitId: SPLIT_ID,
            value: constraints[0].splitPercentages.find(
              (split) => split.value === mediaVehicle.mediaType
            ).value,
          },
        ];
      }

      if (isDaypart) {
        let spotplan = schedule.spotplans.findSpotplan(vehicle.vehicleId);
        if (!spotplan) {
          spotplan = schedule.spotplans.addSpotplan(mediaVehicle);
        }
        spotplan.dayparts.forEach((val) => {
          const result = val.result.results.filter(
            (result) => result.targetId === vehicle.targetId
          );
          let cpm = 0;
          if (result && result.length) {
            result.forEach((val) => (cpm += val.cpm));
          }
          vehiclesReq.push({
            id: val.id,
            mnemonic: val.mnemonic || val.id,
            ...(cpm && cpm > 0 && { costPerThousand: cpm }),
            ...(splitValues && { splitValues }),
          });
        });
      } else {
        const id = vehicle.vehicleId;
        const mnemonic = mediaVehicle.mnemonic;

        // grab the addressable target and build the definition for it
        if (mediaVehicle.addressableConfig) {
          let buyingTargetId = undefined; // assigned the buying target if this is an addressable media
          // check if already in the request definition
          const exists = buyingTargetDefinitions.find(
            (buyingTarget) =>
              buyingTarget.targetId === mediaVehicle.addressableConfig.targetId
          );

          exists ? (buyingTargetId = exists.id) : null;
          if (!exists) {
            const addrTarget = targets.find(
              (target) => target.id === mediaVehicle.addressableConfig.targetId
            );
            buyingTargetId = buyingTargetDefinitions.length;
            buyingTargetDefinitions.push({
              id: buyingTargetId,
              targetId: mediaVehicle.addressableConfig.targetId,
              code: addrTarget.documentTarget.jsonCoding,
            });
          }
          vehiclesReq.push({
            id,
            mnemonic,
            buyingTargetId,
            ...(hasCpm && { costPerThousand: vehicle.result.cpm }),
            ...(splitValues && { splitValues }),
          });
        } else {
          vehiclesReq.push({
            id,
            mnemonic,
            ...(hasCpm && { costPerThousand: vehicle.result.cpm }),
            ...(splitValues && { splitValues }),
          });
        }
      }
    });

    return { vehiclesReq, buyingTargetDefinitions };
  }
  public optimsePlan(
    target: DocumentTarget,
    vehicles: OptimiseVehicle[],
    goal: OptimiseMetrics,
    value: number,
    constraints?: OptimiseRequestConstraints[],
    buyingTargetDefinitions?: TargetStatementWithId[]
  ): Observable<OptimiseResponse> {
    return new Observable((observable) => {
      this.engineService.getTargetCoding([target]).subscribe(() => {
        const options: OptimiseRequest = {
          surveyCode: target.survey.code,
          targetDefinitions: [target].map((target, id) => {
            return { id, code: target.jsonCoding };
          }),
          buyingTargetDefinitions,
          targetUniverse: target.population,
          weightSet: this.HARDCODED_WEIGHT_TO_ONE,
          authorizationGroup: target.survey.authorizationGroup,
          vehicles,
          useIntegerInsertions: true,
          goal: { [goal]: value },
          engineMode: 'async',
        };
        if (constraints) {
          options.constraints = constraints;
          options.splits = [
            {
              id: SPLIT_ID,
              name: 'MediaType',
              values: constraints[0].splitPercentages.map((val) => ({
                value: val.value,
                name: val.value,
              })),
            },
          ];
        }
        this.apiService
          .request(
            'GET',
            environment.api.websocket.url,
            environment.api.websocket.endPoint.websocketurl,
            { body: { engineMode: 'async' } }
          )
          .pipe(
            catchError((err) => {
              return of({
                success: false,
                results: [],
              });
            })
          )
          .subscribe((data) => {
            const websocketUrl = data.url;
            const browserTab = `${Date.now()}_${uniqid()}`;
            Auth.currentSession().then((session) => {
              const token = session.getIdToken().getJwtToken();
              let jobId: string = null;
              let runId: string = null;
              const connectionUrl = `${websocketUrl}?browser_tab=${browserTab}&idToken=${token}`;
              this.wsService.connect(connectionUrl);
              this.wsService
                .getMessageReceived(connectionUrl)
                .subscribe((message) => {
                  if (message === CONNECTED) {
                    this.optimiseProgressPercentage = new Subject<number>();
                    this.apiService
                      .request(
                        'POST',
                        environment.api.cmp.url,
                        environment.api.cmp.endPoint.getoptimisedschedule,
                        { body: options }
                      )
                      .pipe(
                        // any errors are reported by the api Service, so not just return success false for component cleanup
                        catchError((err) => {
                          observable.next();
                          observable.complete();
                          this.snackbarService.showErrorSnackBar(
                            'Something went wrong'
                          );
                          return of({ success: false, results: [] });
                        })
                      )
                      .subscribe((data) => {
                        jobId = data.runId;
                        if (data.runId) {
                          this.wsService.sendMessage(connectionUrl, {
                            action: 'subscribe',
                            browser_tab: browserTab,
                            info: {
                              job_id: jobId,
                              engine: 'cmp',
                              endpoint: 'getoptimisedschedule',
                            },
                          });
                        } else {
                          observable.next(data);
                          observable.complete();
                        }
                      });
                  } else if (message === CONNECTION_CLOSED) {
                    if (runId) {
                      this.apiService
                        .request(
                          'POST',
                          environment.api.cmp.url,
                          environment.api.cmp.endPoint.getasyncresults,
                          { body: { runId, engineMode: 'async' } }
                        )
                        .pipe(
                          // any errors are reported by the api Service, so not just return success false for component cleanup
                          catchError((err) => {
                            return of({
                              success: false,
                              results: [],
                              error: err,
                            });
                          })
                        )
                        .subscribe((data) => {
                          observable.next(data);
                          observable.complete();
                        });
                    } else {
                      this.wsService.connect(
                        `${connectionUrl}&reconnect`,
                        false
                      );
                    }
                  } else {
                    const parsedMessage = JSON.parse(message);
                    const { info, event } = parsedMessage;
                    if (event === 'notify' && info && info.message) {
                      if (info.message.includes(OPTIMISATION_STARTED)) {
                        this.optimiseProgressSnackbar =
                          this.snackbarService.showOptimiseProgressBar({
                            progress: this.optimiseProgressPercentage,
                            cancelOptimisation: () => {
                              this.wsService.closeConnection(
                                connectionUrl,
                                false
                              );
                              this.optimiseProgressSnackbar.dismiss();
                              observable.next();
                              observable.complete();
                            },
                          });
                        this.optimiseProgressPercentage.next(0);
                      }
                      try {
                        const notifyObject = JSON.parse(info.message);
                        const { progresspercentage } = notifyObject;
                        if (progresspercentage) {
                          if (progresspercentage < 100) {
                            this.optimiseProgressPercentage.next(
                              progresspercentage
                            );
                          }
                        }
                      } catch (error) {
                        // NOT AN OBJECT
                      }
                    }
                    if (info && info.success === true) {
                      if (info?.message.includes('error')) {
                        observable.next();
                        observable.complete();
                        this.snackbarService.showErrorSnackBar(
                          'Something went wrong'
                        );
                      } else if (
                        info?.message.trim().includes(OPTIMISATION_FINISHED)
                      ) {
                        this.optimiseProgressPercentage.next(100);
                        this.optimiseProgressPercentage.complete();
                        runId = info.id;
                        this.wsService.sendMessage(connectionUrl, {
                          action: 'unsubscribe',
                          browser_tab: browserTab,
                          info: {
                            job_id: jobId,
                            engine: 'cmp',
                            endpoint: 'getoptimisedschedule',
                          },
                        });
                      }
                    }

                    if (info?.status === 'unsubscribed') {
                      this.wsService.closeConnection(connectionUrl);
                    }
                    if (info && info.success === false) {
                      observable.next();
                      observable.complete();
                      this.snackbarService.showErrorSnackBar(
                        'Something went wrong'
                      );
                    }
                    console.log('messageReceived::', parsedMessage);
                  }
                });
            });
          });
      });
    });
  }
}
