import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TargetVehicle } from 'src/app/classes/vehicle';

export const RenameItemType = {
  media: 'media',
  spotplan: 'spotplan',
  importedMedia: 'importedMedia',
};

export class RenameMediaDialogModel {
  newName: string;
  initialName: string;
  itemType: string;
  spotplan?: boolean;
  constructor() {}
}
@Component({
  selector: 'app-rename-media-dialog',
  templateUrl: './rename-media-dialog.component.html',
  styleUrls: ['./rename-media-dialog.component.scss'],
})
export class RenameMediaDialogComponent implements OnInit {
  dialogTitle: string;
  originalNameLabel: string;
  newNameLabel: string;

  constructor(
    public dialogRef: MatDialogRef<RenameMediaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RenameMediaDialogModel
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    switch (this.data.itemType) {
      case RenameItemType.spotplan:
        this.dialogTitle = 'Rename schedule';
        this.originalNameLabel = 'Spot';
        break;
      case RenameItemType.importedMedia:
        this.dialogTitle = 'Rename';
        this.originalNameLabel = 'Original file name';
        break;
      default:
        this.dialogTitle = 'Rename media';
        this.originalNameLabel = 'Media';
        break;
    }
  }

  onClose(save: boolean) {
    if (save) {
    }
    this.dialogRef.close(save ? this.data : null);
  }
}
