import { Pipe, PipeTransform } from '@angular/core';
import { Target } from '../classes/target';

@Pipe({
  name: 'filterAddressable',
})
export class FilterAddressablePipe implements PipeTransform {
  transform(targets: Target[], filterType: string): Target[] {
    if (!targets || !filterType) {
      return targets;
    }

    return targets.filter((target) => target[filterType]);
  }
}
