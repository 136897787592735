import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TargetVehicle } from '../classes/vehicle';
import {
  TargetEditorDialogComponent,
  TargetEditorDialogModel,
} from '../components/dialogs/target-editor-dialog/target-editor-dialog.component';
import { DocumentSurvey, DocumentTarget } from '../models/document.model';
import { Target } from '../classes/target';
import { cloneDeep } from 'lodash';
import {
  AddressableWarningDialogComponent,
  AddressableWarningDialogComponentModel,
  AddressableWarningDialogComponentModelAction,
} from './addressable-warning-dialog/addressable-warning-dialog.component';
import { Observable, of } from 'rxjs';
import { isNotNullOrUndefined } from '../pipes/pipeable-operators';
import { EngineService } from '../services/engine.service';
import { MediaPlannerService } from '../services/media-planner.service';
import { MultiTargetEvaluationResponse } from '../models/engine.target-evaluation.models';

export class AddAddressableDialogModel {
  survey: DocumentSurvey;
  targets: Target[];
  vehicle: TargetVehicle;
  newTitle: string;
  selectedTarget: Target;
  fullRecalcRequired: boolean = false;
  constructor() {}
}

@Component({
  selector: 'app-add-addressable-dialog',
  templateUrl: './add-addressable-dialog.component.html',
  styleUrls: ['./add-addressable-dialog.component.scss'],
})
export class AddAddressableDialogComponent implements OnInit {
  title: string;
  targets: Target[];
  processing: boolean = false;
  canCancel: boolean = true;
  addressableSource: string;
  addressableTargetSources: {
    addressableTarget: string;
    planningTarget: string;
  };

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddAddressableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddAddressableDialogModel,
    private engineService: EngineService,
    private mediaplannerService: MediaPlannerService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.title = 'Apply addressable audience';
    this.addressableTargetSources = {
      addressableTarget: 'addressableTarget',
      planningTarget: 'planningTarget',
    };
    this.targets = cloneDeep(this.data.targets);
    this.data.fullRecalcRequired = false;
  }

  onClose(save: boolean) {
    this.dialogRef.close(save ? this.data : null);
  }

  onSelected(target: Target, tab: string) {
    this.data.selectedTarget = target;
    this.data.fullRecalcRequired = true;
    this.addressableSource = tab;
  }

  /**
   * Check if any other addressable vehicles are using this target.  If so prompt a warning
   *
   * @param target Target to check all vehicles against
   * @returns AddressableWarningDialogComponentModelAction containing the selected action
   */
  checkDuplicateAddressableTargets(
    target: Target
  ): Observable<AddressableWarningDialogComponentModelAction> {
    // count vehicles using this target
    const vehicles = this.mediaplannerService.plan.targets[0].vehicles
      .filter(
        (vehicle) =>
          vehicle.addressableConfig &&
          vehicle.addressableConfig.targetId === target.id
      )
      .map((veh) => veh.title);

    return vehicles.length < 2
      ? of(AddressableWarningDialogComponentModelAction.edit)
      : new Observable((observable) => {
          const addressableWarning =
            new AddressableWarningDialogComponentModel();
          addressableWarning.vehicles = vehicles;
          addressableWarning.targetTitle = target.title;
          addressableWarning.vehicleTitle = this.data.vehicle.title;

          const options = {
            data: addressableWarning,
            width: '40vw',
            autoFocus: false,
            closeOnNavigation: false,
            disableClose: true,
          };

          this.dialog
            .open(AddressableWarningDialogComponent, options)
            .afterClosed()
            .subscribe((answer: AddressableWarningDialogComponentModel) => {
              observable.next(answer.action);
              observable.complete();
            });
        });
  }

  /**
   * Called from the view, a request to edit a specific.
   * If successfull then target popn, sample will be recalculated,
   * including all the audiences of the vegicles under that target
   *
   * @param target Target to check all vehicles against
   */

  onItemEditClicked(target: Target) {
    // check if this target has been used for any other addressable, respond to answer
    this.checkDuplicateAddressableTargets(target).subscribe((answer) => {
      if (answer !== AddressableWarningDialogComponentModelAction.cancel) {
        const dialogData: TargetEditorDialogModel =
          new TargetEditorDialogModel();
        dialogData.survey = this.data.survey;
        dialogData.primarySurvey = this.mediaplannerService.plan.primarySurvey;

        dialogData.dialogTitle = 'Edit addressable audience';
        dialogData.confirmButtonText = 'Update addressable audience';

        let copyTarget = false;

        if (answer === AddressableWarningDialogComponentModelAction.copy) {
          dialogData.dialogTitle = 'Copy of addressable audience';
          dialogData.confirmButtonText = 'Save new addressable audience';
          // 1 copy this target
          const newTarget =
            this.mediaplannerService.plan.duplicateTarget(target);
          newTarget.documentTarget.title += ' (copy)';

          // 3 start editing this new copied target
          answer = AddressableWarningDialogComponentModelAction.edit;
          target = newTarget;
          copyTarget = true;
        }

        // target to be edited so show target editor dialog
        if (answer === AddressableWarningDialogComponentModelAction.edit) {
          dialogData.documentTarget = cloneDeep(target.documentTarget);

          const options = {
            data: dialogData,
            closeOnNavigation: false,
            disableClose: true,
            minWidth: '70vw',
            maxWidth: '70vw',
            autoFocus: false,
          };

          this.dialog
            .open(TargetEditorDialogComponent, options)
            .afterClosed()
            .subscribe((editedTarget: TargetEditorDialogModel) => {
              // grab the updated target
              if (editedTarget) {
                this.canCancel = false;
                target.documentTarget = cloneDeep(editedTarget.documentTarget);

                // if we were working with a copy, then use that
                if (copyTarget) {
                  this.mediaplannerService.plan.addAddressable(
                    this.data.vehicle.id,
                    target
                  );
                }

                // calcuate its population and sample
                this.calculateTargetAudience(target.documentTarget).subscribe(
                  (result) => {
                    this.data.selectedTarget = target;

                    // at this point we need to calcuated audiences of all the vehicles again
                    this.processing = true;
                    this.mediaplannerService
                      .updateTarget(target)
                      .subscribe((resultingTarget: Target) => {
                        this.targets = cloneDeep(this.data.targets); // trigger change detection
                        this.processing = false;
                      });
                  }
                );
              } else {
                // editedtarget is null, so if we were working with a copy, then delete it.
                if (copyTarget) {
                  const targetIndex =
                    this.mediaplannerService.plan.targets.findIndex(
                      (tgt) => tgt.id === target.id
                    );
                  this.mediaplannerService.plan.deleteTarget(
                    targetIndex,
                    false
                  );
                }
              }
            });
        }
      }
    });
  }

  /**
   * Open the target editor with a new blank target
   * If a populated target is returned then its population, sample will be calculated,
   * along with the audiences of all the vehicles in the campaign
   */
  onCreateNewAudience() {
    const dialogData: TargetEditorDialogModel = new TargetEditorDialogModel();
    dialogData.survey = this.data.survey;
    dialogData.primarySurvey = this.mediaplannerService.plan.primarySurvey;
    dialogData.dialogTitle = 'Create new addressable audience';
    dialogData.confirmButtonText = 'Save';
    dialogData.documentTarget = null; // starting with a blank new target

    const options = {
      data: dialogData,
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '70vw',
      maxWidth: '70vw',
      autoFocus: false,
    };

    this.dialog
      .open(TargetEditorDialogComponent, options)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((resultData: TargetEditorDialogModel) => {
        this.calculateTargetAudience(resultData.documentTarget).subscribe(
          (res) => {
            // add the target and calculate all the vehicle audiences along with it
            this.processing = true;
            this.canCancel = false;
            // if new target already existed as plannable, keep the plannable property
            const target = this.mediaplannerService.plan.targets.find(
              (tgt) => tgt.coding === resultData.documentTarget.coding
            );
            if (target) {
              resultData.documentTarget.planningTarget = true;
            }
            this.mediaplannerService
              .addTarget(resultData.documentTarget)
              .subscribe((res) => {
                this.targets = cloneDeep(this.data.targets); // trigger change detection
                this.processing = false;
                this.data.selectedTarget = res;
                this.addressableSource =
                  this.addressableTargetSources.addressableTarget;
              });
          }
        );
      });
  }

  // update the popn and sample of the given target
  calculateTargetAudience(target: DocumentTarget): Observable<boolean> {
    return new Observable((ob) => {
      this.engineService.getMultiTargetEvaluation([target]).subscribe(
        (res: MultiTargetEvaluationResponse) => {
          ob.next(res.status.success);
          ob.complete();
        },
        (err) => {
          console.log(err);
          ob.next(false);
          ob.complete();
        }
      );
    });
  }
}
