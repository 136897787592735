<div class="multiple-tabs-dialog">
  <tup-mat-title-bar title="Export settings">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose(false)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>
  <div class="snackbar-container">
    <snackbar-generic
      class="metric-column-dialog-error"
      *ngIf="noOptionSelected"
      [data]="unselectedError"
    >
    </snackbar-generic>
  </div>

  <mat-dialog-content class="container">
    <mat-tab-group
      mat-stretch-tabs
      animationDuration="0ms"
    >
      <mat-tab label="Select plan(s)">
        <div class="tab-container">
          <p class="column-item">
            <mat-checkbox
              color="primary"
              [disabled]="data.compareOption === 'plans'"
              [checked]="schedulesSelectedCount === data.schedules.length"
              [indeterminate]="
                schedulesSelectedCount > 0 &&
                schedulesSelectedCount < data.schedules.length
              "
              (change)="onSelectAll(tabs.schedules, $event.checked)"
              >All plans</mat-checkbox
            >
          </p>
          <p
            class="column-item"
            *ngFor="let schedule of data.schedules"
          >
            <mat-checkbox
              color="primary"
              [disabled]="data.compareOption === 'plans'"
              [checked]="schedule.checked"
              (change)="
                onOptionChanged(tabs.schedules, schedule, $event.checked)
              "
              [matTooltip]="schedule.label"
            >
              {{ schedule.label }}
            </mat-checkbox>
          </p>
        </div>
      </mat-tab>
      <mat-tab label="Select audience(s)">
        <div class="tab-container">
          <p class="column-item">
            <mat-checkbox
              color="primary"
              [checked]="targetsSelectedCount === data.targets.length"
              [indeterminate]="
                targetsSelectedCount > 0 &&
                targetsSelectedCount < data.targets.length
              "
              (change)="onSelectAll(tabs.targets, $event.checked)"
              >All audiences</mat-checkbox
            >
          </p>
          <p
            class="column-item"
            *ngFor="let target of data.targets"
          >
            <mat-checkbox
              color="primary"
              [checked]="target.checked"
              (change)="onOptionChanged(tabs.targets, target, $event.checked)"
              [matTooltip]="target.label"
            >
              {{ target.label }}
            </mat-checkbox>
          </p>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions
    class="app-dialog-actions"
    fxLayoutAlign="space-between center"
  >
    <button
      mat-button
      tupMatButton
      appearance="small"
      (click)="onClose(false)"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      tupMatButton
      appearance="small"
      color="primary"
      [disabled]="noOptionSelected"
      (click)="onClose(true)"
    >
      <mat-icon>file_download</mat-icon> Export
    </button>
  </mat-dialog-actions>
</div>
