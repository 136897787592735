import { TreeTableColumn } from '../components/tree-table/tree-table.models';
import { DocumentColumns } from '../models/document.model';
import { SurveyMediaTypeColumn } from '../models/planning-columns.models';
import {
  Column_Audience000,
  Column_AudienceBasis,
  Column_AudiencePct,
  Column_AvgFreq,
  Column_Impressions,
  Column_PotentialReach000,
  Column_PotentialReachPct,
  Column_Reach000,
  Column_ReachPct,
  allColumns,
  allColumnGroups,
  Column_UniqueReach000,
  Column_UniqueReachPct,
  Column_EffReach,
  Column_EffReachPct,
  Column_GRPs,
  Column_Survey,
  Column_Group_Survey,
} from 'src/app/models/planning-veh-columns.models';

import * as Spot_Columns from 'src/app/models/spot-columns.models';

import { ColumnGroup } from '../components/tree-table/column-group/table-group.models';
import { EffectiveReach } from './media-planner';
import { cloneDeep } from 'lodash';

const defaultColumns = [
  Column_ReachPct,
  Column_Reach000,
  Column_AvgFreq,
  Column_GRPs,
  Column_Survey,
  Column_Impressions,
  Column_PotentialReach000,
  Column_PotentialReachPct,
  Column_Audience000,
  Column_AudiencePct,
  Column_AudienceBasis,
];

const defaultSpotColumns = [
  Spot_Columns.Column_Spots,
  Spot_Columns.Column_GRPs,
];

const allSpotColumns = [
  Spot_Columns.Column_Spots,
  Spot_Columns.Column_GRPs,
  Spot_Columns.Column_Impressions,
  Spot_Columns.Column_Reach000,
  Spot_Columns.Column_ReachPct,
];

export class ColumnCollection {
  defaultColumns: TreeTableColumn[];
  allColumns: TreeTableColumn[];
  allSpotColumns: TreeTableColumn[];
  columnGroups: ColumnGroup[];
  columns: SurveyMediaTypeColumn;
  defaultSpotColumns: TreeTableColumn[];
  columnSpotGroups: ColumnGroup[];

  autoUpdateColumns: boolean = true;

  constructor() {
    this.defaultColumns = defaultColumns;
    this.columnGroups = allColumnGroups;
    this.allColumns = this.buildDefaultColumns();

    this.defaultSpotColumns = defaultSpotColumns;
    this.columnSpotGroups = Spot_Columns.allSpotColumnGroups;
    this.allSpotColumns = this.buildDefaultSpotColumns();
  }

  // used during reach and frequency calls to determine if unique reach evaluation is required
  get includeUniqueReach(): boolean {
    const uniques = [
      Column_UniqueReach000.columnDef,
      Column_UniqueReachPct.columnDef,
    ];
    return !!this.getVisibleColumns().find((column) =>
      uniques.includes(column.columnDef)
    );
  }

  removeSurveyColumn() {
    const defaultColumns = cloneDeep(this.defaultColumns);
    const allColumns = cloneDeep(this.allColumns);

    this.defaultColumns = defaultColumns.filter(
      (val) => val.columnDef !== Column_Survey.columnDef
    );
    const surveyColumn = this.allColumns.find(
      (val) => val.columnDef === Column_Survey.columnDef
    );
    const surveyColumnIndex = this.allColumns.indexOf(surveyColumn);
    if (surveyColumnIndex) {
      allColumns[surveyColumnIndex].visible = false;
      this.allColumns = allColumns;
    }
  }

  addSurveyColumn() {
    const allColumns = cloneDeep(this.allColumns);
    this.defaultColumns = defaultColumns;
    this.columnGroups = allColumnGroups;
    const surveyColumn = this.allColumns.find(
      (val) => val.columnDef === Column_Survey.columnDef
    );
    const surveyColumnIndex = this.allColumns.indexOf(surveyColumn);
    allColumns[surveyColumnIndex] = {
      ...allColumns[surveyColumnIndex],
      visible: true,
    };
    this.allColumns = allColumns;
  }

  getVisibleColumns() {
    return this.allColumns.filter((col) => col.visible === true);
  }

  getAllSpotColumns() {
    return this.allSpotColumns;
  }

  getVisibleSpotColumns() {
    return this.allSpotColumns.filter((col) => col.visible === true);
  }

  disableOptimiseOptionOnColumns(disabled: boolean) {
    this.allColumns.forEach((column) => {
      if (column.menu) {
        const optimiseIndex = column.menu.findIndex(
          (menuOption) => menuOption.data === 'optimise'
        );
        if (optimiseIndex !== -1) {
          column.menu[optimiseIndex].disabled = disabled;
        }
      }
    });
  }

  setColumns(columns: TreeTableColumn[], visible: boolean) {
    columns.forEach((column) => {
      const allColumn = this.allColumns.find(
        (col) => col.columnDef === column.columnDef
      );
      allColumn ? (allColumn.visible = visible) : null;
    });
  }

  setSpotColumns(columns: TreeTableColumn[], visible: boolean) {
    columns.forEach((column) => {
      const allColumn = this.allSpotColumns.find(
        (col) => col.columnDef === column.columnDef
      );
      allColumn ? (allColumn.visible = visible) : null;
    });
  }

  setColumnGroup(group: ColumnGroup, visible: boolean) {
    this.allColumns
      .filter((col) => col.group?.name === group.name)
      .forEach((col) => (col.visible = visible));
    group.hidden = false; // expanded it
  }

  clearAll() {
    this.allColumns = this.buildDefaultColumns();
    this.autoUpdateColumns = true;
    this.columns = null;
  }

  buildDefaultColumns(): TreeTableColumn[] {
    const columns: TreeTableColumn[] = [];
    const defaultColumnsDef = this.defaultColumns.map((col) => col.columnDef);

    allColumns.forEach((column) => {
      columns.push({
        ...column,
        visible: defaultColumnsDef.indexOf(column.columnDef) !== -1,
      });
    });
    return columns;
  }

  buildDefaultSpotColumns(): TreeTableColumn[] {
    const columns: TreeTableColumn[] = [];
    const defaultColumnsDef = this.defaultSpotColumns.map(
      (col) => col.columnDef
    );

    allSpotColumns.forEach((column) => {
      columns.push({
        ...column,
        visible: defaultColumnsDef.indexOf(column.columnDef) !== -1,
      });
    });
    return columns;
  }

  // called from the mediaplanner service, SurveyMediaTypeColumn object processed by the columns service after a getSurveys() call
  prepare(columns: SurveyMediaTypeColumn) {
    this.columns = columns;
  }

  asDocument(): DocumentColumns {
    return {
      visibleColumns: this.getVisibleColumns().map(
        (column) => column.columnDef
      ),
      frequencyDistributionColumns: null,
    };
  }

  loadDocument(documentColumns: string[], effectiveReach: EffectiveReach) {
    this.allColumns.forEach(
      (column) =>
        (column.visible = documentColumns.indexOf(column.columnDef) !== -1)
    );

    this.buildEffectiveReachHeader(effectiveReach);
  }

  buildEffectiveReachHeader(effectiveReach: EffectiveReach) {
    if (effectiveReach) {
      const columns = this.allColumns.filter((col) =>
        [Column_EffReach.columnDef, Column_EffReachPct.columnDef].includes(
          col.columnDef
        )
      );
      columns.forEach((column) => {
        // remove previous effReach details
        if (column.header.indexOf('(') !== -1) {
          column.header = column.header.substring(
            0,
            column.header.indexOf('(')
          );
        }

        // build new column header text with current effReach details
        let columnHeaderSuffix = effectiveReach.effectiveReachFrom.toString();
        columnHeaderSuffix += effectiveReach.effectiveReachTo
          ? ` - ${effectiveReach.effectiveReachTo}`
          : '+';
        column.header += ` (${columnHeaderSuffix})`;
      });
    }
  }
}
