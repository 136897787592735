import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ManualEntryDialogModel,
  MultiSurveyManualInput,
} from '../../../../../models/multi-survey.models';
import { ManualEntryComponent } from '../manual-entry.component';
import { MediaPlannerService } from '../../../../../services/media-planner.service';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../../../snackbar-generic/snackbar-generic.component';

@Component({
  selector: 'manual-entry-dialog',
  templateUrl: './manual-entry-dialog.component.html',
  styleUrls: ['./manual-entry-dialog.component.scss'],
})
export class ManualEntryDialogComponent implements AfterViewInit {
  @ViewChild('manualEntryForm') manualEntryForm: ManualEntryComponent;

  multiSurveyId: string;
  scheduleIndex: number;
  manualInputData?: MultiSurveyManualInput;
  snackbarError: SnackbarGenericOptionModel = {
    type: 'error',
    message: '',
    icon: StatusSnackbarIcon.Error,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ManualEntryDialogModel,
    public dialogRef: MatDialogRef<ManualEntryDialogComponent>,
    private mediaplannerService: MediaPlannerService
  ) {
    this.multiSurveyId = data.multiSurveyId;
    this.scheduleIndex = data.scheduleIndex ?? 0;
  }

  ngAfterViewInit(): void {
    this.setManualInputData();
    this.focusManualInputTitle();
  }

  private setManualInputData() {
    this.manualInputData = {
      title: this.manualEntryForm.manualMediaTitle.trim(),
      reachPct: Number(this.manualEntryForm.manualReachPct),
      grp: Number(this.manualEntryForm.manualGRPs),
      avgFreq: Number(this.manualEntryForm.manualAvgFreq),
      inserts: Number(this.manualEntryForm.manualInserts),
      totalCost: Number(this.manualEntryForm.manualTotalCost),
    };
  }

  private focusManualInputTitle() {
    setTimeout(() => {
      this.manualEntryForm.manualInputTitleRef.nativeElement.focus();
    }, 251);
  }

  private manualInputDataChanged() {
    return (
      this.manualEntryForm.manualMediaTitle !== this.manualInputData.title ||
      this.manualEntryForm.manualReachPct !== this.manualInputData.reachPct ||
      this.manualEntryForm.manualAvgFreq !== this.manualInputData.avgFreq ||
      this.manualEntryForm.manualInserts !== this.manualInputData.inserts ||
      this.manualEntryForm.manualTotalCost !== this.manualInputData.totalCost
    );
  }

  private checkAndUpdateManualInputTitle() {
    if (this.manualEntryForm.manualMediaTitle !== this.manualInputData.title) {
      this.mediaplannerService.plan.targets.forEach((target) => {
        const vehicle = target.vehicle(this.multiSurveyId);
        target.renameVehicle(vehicle, this.manualEntryForm.manualMediaTitle);
        vehicle.mediaType = this.manualEntryForm.manualMediaTitle;
      });
    }
  }

  onDuplicateTitleCheck(title: string) {
    this.snackbarError.message = `The name "${title}" is already being used. Please choose a different name.`;
  }

  onClose(save: boolean = false) {
    if (save) {
      this.checkAndUpdateManualInputTitle();
      if (this.manualInputDataChanged()) {
        this.setManualInputData();
        this.dialogRef.close(this.manualInputData);
      } else {
        this.onClose();
      }
    } else {
      this.dialogRef.close();
    }
  }
}
