import { Component, OnInit } from '@angular/core';
import {
  Column_Audience000,
  Column_AudiencePct,
  Column_CompositionIndex,
  Column_CompositionPct,
  Column_PotentialReach000,
  Column_PotentialReachPct,
} from 'src/app/models/planning-veh-columns.models';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AppendUnitsPipe } from 'src/app/pipes/append-units.pipe';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TreeTableColumn } from '../../tree-table/tree-table.models';

export const Metrics = [
  Column_PotentialReach000,
  Column_PotentialReachPct,
  Column_CompositionIndex,
  Column_CompositionPct,
  Column_AudiencePct,
  Column_Audience000,
];

@Component({
  selector: 'app-manage-audience-metrics',
  templateUrl: './manage-audience-metrics.component.html',
  styleUrls: ['./manage-audience-metrics.component.scss'],
})
export class ManageAudienceMetricsComponent implements OnInit {
  appendUnits = new AppendUnitsPipe();
  noMetricSelected: boolean = false;
  allSelected: boolean;
  unselectedError: SnackbarGenericOptionModel = {
    type: 'error',
    message: 'Please select at least one metric to proceed.',
    icon: StatusSnackbarIcon.Error,
  };
  filters = [...Metrics];
  column = '';
  columns = [];
  reorderFilters = [];
  unitsText: string;
  sortValue = '';
  orderValue = '';
  orderValues = [
    { value: 'asc', title: 'Ascending' },
    { value: 'desc', title: 'Descending' },
  ];
  get selectedOrderValues() {
    return this.orderValues.find((val) => val.value === this.orderValue).title;
  }
  get selectedColumn() {
    return (
      this.columns.find((val) => val.columnDef === this.column)?.title ||
      this.columns[0].columnDef
    );
  }
  get selectedSortValue() {
    const filter = this.filters.find((val) => val.columnDef === this.sortValue);
    return filter && this.unitsText.length > 0
      ? this.appendUnits.transform(filter, this.unitsText)
      : '';
  }
  constructor(
    public mediaplannerService: MediaPlannerService,
    public dialogRef: MatDialogRef<ManageAudienceMetricsComponent>
  ) {}

  ngOnInit(): void {
    this.unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
      this.mediaplannerService.plan.currentSurvey.code
    ).reportUnitText;

    this.columns = this.mediaplannerService.plan.targets.map((val, index) => ({
      columnDef: `audience_${index}`,
      title: val.title,
    }));
    const mediaSettings = this.mediaplannerService.plan.mediaSettings;
    if (mediaSettings.selectedMetrics.length > 0) {
      this.filters.forEach((val) => {
        if (
          mediaSettings.selectedMetrics.find(
            (selected) => selected.columnDef === val.columnDef
          )
        ) {
          val.visible = true;
        } else {
          val.visible = false;
        }
      });
    } else {
      this.filters = this.filters.map((val) => ({
        ...val,
        visible: val.columnDef === 'potentialReach000',
      }));
    }
    if (mediaSettings.metricsOrder.length > 0) {
      this.reorderFilters = [...mediaSettings.metricsOrder];
    } else {
      this.reorderFilters = [...this.filters];
    }

    if (mediaSettings.orderBy.length > 0) {
      this.orderValue = mediaSettings.orderBy;
    } else {
      this.orderValue = 'asc';
    }
    if (mediaSettings.sortBy.length > 0) {
      this.sortValue = mediaSettings.sortBy;
    }
    if (mediaSettings.selectedColumn.length > 0) {
      this.column = mediaSettings.selectedColumn;
    } else {
      this.column = this.columns[0].columnDef;
    }
  }

  onColumnChanged(data, col: TreeTableColumn = null) {
    col.visible = data.checked;
    this.noMetricSelected =
      this.filters.filter((val) => val.visible).length === 0;
  }

  onSelectAll(data) {
    this.filters.forEach((val) => (val.visible = data.checked));
    this.noMetricSelected = !data.checked;
  }

  onClose() {
    this.dialogRef.close(false);
  }

  drop(event: CdkDragDrop<TreeTableColumn[]>) {
    moveItemInArray(
      this.reorderFilters,
      event.previousIndex,
      event.currentIndex
    );
  }

  resetToDefault() {
    this.orderValue = 'asc';
    this.sortValue = '';
    this.column = '';
    this.filters = this.filters.map((val) => ({
      ...val,
      visible: val.columnDef === 'potentialReach000',
    }));
    this.reorderFilters = [...this.filters];
    this.allSelected = false;
    this.noMetricSelected = false;
  }
  save() {
    this.mediaplannerService.plan.mediaSettings.orderBy = this.orderValue;
    this.mediaplannerService.plan.mediaSettings.selectedColumn = this.column;
    this.mediaplannerService.plan.mediaSettings.sortBy = this.sortValue;
    this.mediaplannerService.plan.mediaSettings.selectedMetrics =
      this.filters.filter((val) => val.visible);
    this.mediaplannerService.plan.mediaSettings.metricsOrder =
      this.reorderFilters;
    this.dialogRef.close(true);
  }
}
