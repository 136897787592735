<div fxLayout="column">
  <tup-mat-title-bar
    title="Export success"
    class="export-title"
  ></tup-mat-title-bar>

  <div class="export-content">
    <ng-container *ngIf="exportedFileLink">
      <snackbar-generic
        class="metric-column-dialog-error"
        [data]="successMessage"
      >
      </snackbar-generic>
    </ng-container>
    <ng-container *ngIf="!exportedFileLink">
      Google Drive <strong>cross-media-planner</strong> folder.
    </ng-container>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="action-buttons"
  >
    <button
      mat-stroked-button
      tupMatButton
      (click)="onCloseClick()"
    >
      Close
    </button>
    <button
      mat-flat-button
      tupMatButton
      color="primary"
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      Go to file
    </button>
  </div>
</div>
