<div
  class="table-container"
  [ngClass]="{ 'no-border-bottom': dataSource.length === 0 }"
>
  <!-- <button (click)="testSort()">click for order</button> -->
  <tree-table
    #mediaTable
    class="tree-table media-tree-table-main"
    [inlineMenuWithIcons]="true"
    [treeTitle]="treeTitleMedia"
    treeWidth="500px"
    stickyColumn="name"
    data-tracking="media-screen-table"
    [data]="vehicleData"
    [showProcessing]="processing"
    [treeHeaderInlineMenu]="headerMenu"
    (treeHeaderMenuClick)="onHeaderMenuClick($event)"
    (treeInlineMenuClick)="onTreeInlineMenuClick($event)"
    (treeHeaderMainMenuClick)="onTreeHeaderMainMenuClick()"
    [disableDragging]="true"
    [allowSelectAll]="true"
    [columns]="columns"
    (selectedNodesChange)="onPlanningSelectedNodes($event)"
    [unitsText]="unitsText"
  >
  </tree-table>
  <div
    *ngIf="dataSource.length === 0"
    class="table-with-no-data-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="8px"
  >
    <mat-icon>live_tv</mat-icon>
    <p>Select items from the builder on the left to add to your media.</p>
  </div>
</div>

<ng-template
  #addressableRow
  let-row="row"
  let-index="index"
>
  <mat-icon
    *ngIf="
      row.data.item.vehicle.addressable &&
      !row.data.item.vehicle.addressableConfig
    "
    class="icon-colour-inactive"
    >crisis_alert</mat-icon
  >
  <mat-icon
    *ngIf="
      row.data.item.vehicle.addressable &&
      row.data.item.vehicle.addressableConfig
    "
    class="icon-colour-primary"
    >crisis_alert</mat-icon
  >
  <span>{{ row.name }}</span>
</ng-template>

<ng-template
  #importedMediaTypeRow
  let-row="row"
>
  <span>
    {{ row.name }}
  </span>

  <button
    mat-icon-button
    class="inline-button inline-template-button"
    (click)="onImportedMediaInfoClick(row)"
  >
    <mat-icon>info_outline</mat-icon>
  </button>
</ng-template>

<ng-template
  #cell
  let-row="row"
  let-column="column"
  let-index="index"
>
  <ng-container
    [ngTemplateOutlet]="
      column.targetId === -3
        ? surveyTemplate
        : column.targetId === -2
        ? esgTemplate
        : column.targetId >= 0
        ? targetCelltemplate
        : metricTemplate
    "
    [ngTemplateOutletContext]="{ row: row, column: column, index: index }"
  ></ng-container>
</ng-template>

<ng-template
  #metricTemplate
  let-row="row"
  let-column="column"
>
  <div
    *ngFor="let metric of selectedMetricsOrdered"
    class="metric available-metric"
    [attr.data-tracking]="metric.columnDef"
    [ngClass]="{
      selected: metric.columnDef === orderMetric
    }"
  >
    {{ metric | appendUnits : unitsText }}
  </div>
</ng-template>
<ng-template
  #targetCelltemplate
  let-row="row"
  let-column="column"
>
  <div
    *ngFor="let metric of selectedMetricsOrdered"
    class="metric"
    [matTooltip]="
      row.data.item.vehicle?.survey?.code !== primarySurveyCode
        ? 'The numbers per vehicles are based on the audience selected'
        : ''
    "
    [attr.data-tracking]="metric.columnDef"
    [ngClass]="{
      selected:
        metric.columnDef === orderMetric && column.columnDef === selectedColumn
    }"
  >
    {{ getTargetCellValue(metric, row, column) }}
    <span
      class="not-primary"
      *ngIf="row.data.item.vehicle?.survey?.code !== primarySurveyCode"
      >*</span
    >
  </div>
</ng-template>

<ng-template
  #esgTemplate
  let-row="row"
  let-column="column"
>
  <div class="align-right">{{ column.cell(row) }}</div>
</ng-template>

<ng-template
  #surveyTemplate
  let-row="row"
  let-column="column"
>
  <div matTooltip="{{ row.data.item.vehicle?.survey?.title }}">
    {{ column.cell(row) }}
  </div>
</ng-template>
