import { Component, Input, OnInit } from '@angular/core';
import { TransitionCheckState } from '@angular/material/checkbox';
import { CoreDemoSet } from '../models/codebook.core-demo-sets.models';

interface CoreDemoSetSelectionsValues {
  value: string;
  dataReference: string;
  selected: boolean;
}

interface CoreDemoSetSelections {
  class: string;
  datatype: string;
  values: CoreDemoSetSelectionsValues[];
}

@Component({
  selector: 'core-demo-set',
  templateUrl: './core-demo-set.component.html',
  styleUrls: ['./core-demo-set.component.scss'],
})
export class CoreDemoSetComponent implements OnInit {
  @Input() demo: CoreDemoSet;
  // demoSelections: CoreDemoSetSelections;

  constructor() {}

  ngOnInit(): void {
    this.demo.values.forEach((value) => (value.selected = false));
    // this.demoSelections = {class: this.demo.class,
    //                        datatype: this.demo.datatype,
    //                        values: this.demo.values.map( value => {
    //                             return { value: value.value, dataReference: value.dataReference, selected: false }
    //                           })
    // };
  }
}
