import { flatten } from 'lodash';
import {
  TupChartData,
  TupChartType,
  TupComboChartData,
} from '@telmar-global/tup-document-exporter';
import { ChartSettings } from '../models/charts.model';
import {
  isData,
  Data,
  Dataset,
  ExportProps,
  CrossMediaProps,
} from '../models/export.model';
import { ChartComponent } from '../components/chart/chart.component';

export const getCrossMediaProps = (chart: ChartComponent): CrossMediaProps => {
  const subtitleItems = [
    chart.localChartSettings.subTitle,
    chart.localChartSettings.chartDataKeys[
      chart.localChartSettings.primaryDataItem
    ],
  ];
  const chartSubtitle: string = subtitleItems
    .filter((val) => Boolean(val))
    .join(' - ');
  const sourceItems = [chart.footer, chart.surveySource];
  const source = sourceItems.filter((val) => Boolean(val)).join(' \n');
  return {
    settings: chart.localChartSettings,
    primaryData: chart.finalData,
    secondaryData: chart.secondaryDataItemValues,
    charts: chart.charts,
    title: chart.localChartSettings.title,
    subtitle: chartSubtitle,
    source: source,
    copyright: chart.copyright,
    selected: chart.exportSelected,
  };
};

/**
 * Extracts catAxisTitle / valAxisTitle PptxGenJS IChartOpts from Chart.js settings.
 *
 * @param secondaryChartType The secondary chart type.
 * @param settings The chart settings.
 */
export const getAxisTitles = (
  secondaryChartType: TupChartType,
  settings: ChartSettings
): any => {
  // Combo charts
  if (secondaryChartType) {
    return {
      valAxes: [
        {
          valAxisTitle: settings.primaryDataItem,
        },
        {
          valAxisTitle: settings.secondaryDataItem,
        },
      ],
    };
    // Core charts
  } else {
    return {
      catAxisTitle: settings.primaryDataItem,
      valAxisTitle: settings.secondaryDataItem,
    };
  }
};

/**
 * Extracts chartColors PptxGenJS IChartOpt from Chart.js settings.
 *
 * @param data The data.
 */
export const getChartColors = (data: Data): string[] => {
  const chartColors: string[] =
    data.datasets.length === 1
      ? data.datasets[0].backgroundColor
      : data.datasets.map((dataset: Dataset) => dataset.backgroundColor[0]);

  return chartColors.map((chartColor: string) =>
    chartColor.toUpperCase().replace('#', '')
  );
};

/**
 * Extracts PptxGenJS IChartOpts from Chart.js settings.
 *
 * @param secondaryChartType The secondary chart type.
 * @param settings The chart settings.
 */
export const getChartOpts = (
  chartColors: string[],
  settings: ChartSettings,
  secondaryChartType: TupChartType
): { [key: string]: any } => {
  return {
    chartColors,
    showValue: settings.showChartLabel,
    showLabel: settings.showChartLabel,
    showDataTable: settings.showDataTable,
    showLegend: !settings.hideChartLegend,
    ...getAxisTitles(secondaryChartType, settings),
    dataLabelFormatCode:
      '#,###' +
      (settings.decimalPlaces > 0
        ? '.' + '#'.repeat(settings.decimalPlaces)
        : ''),
  };
};

/**
 * Converts secondary data into a consistent format.
 *
 * Secondary data (ChartComponent.secondaryDataValues) does not have a type.
 *
 * Sometimes it's in the same format as the primary data, other times, it's a 2D Array of numbers ¯\_(ツ)_/¯.
 */
export const formatSecondaryData = (
  primaryData: Data,
  secondaryData: number[][] | Data
): Data => {
  const callbackFn = (dataset: Dataset, index: number): Dataset =>
    ({
      label: dataset.label,
      data: flatten(secondaryData[index]),
    } as Dataset);

  return isData(secondaryData)
    ? secondaryData
    : {
        labels: primaryData.labels,
        datasets: primaryData.datasets.map(callbackFn),
      };
};

/**
 * Converts Chart.js data into PptxGenJS core chart data that can be consumed by the export library.
 *
 * @param data The Chart.js data.
 */
export const getCoreChartData = (data: Data): TupChartData[] => {
  return data.datasets.map((dataset: Dataset) => ({
    name: dataset.label,
    labels: data.labels,
    values: dataset.data,
  }));
};

/**
 * Converts Chart.js settings and data into PptxGenJS core chart data that can be consumed by the export library.
 */
export const getExportProps = (
  settings: ChartSettings,
  primaryData: Data,
  secondaryData: number[][] | Data
): ExportProps => {
  const primaryChartType: TupChartType =
    TupChartType[settings.primaryChartType];

  const primaryChartData: TupChartData[] = getCoreChartData(primaryData);

  const secondaryChartType: TupChartType =
    settings.secondaryDataItem !== 'None'
      ? TupChartType[settings.secondaryChartType]
      : undefined;

  const secondaryChartData: TupChartData[] = secondaryData
    ? getCoreChartData(formatSecondaryData(primaryData, secondaryData))
    : undefined;

  if (primaryChartType === TupChartType.tupScatter) {
    primaryChartData.push(...secondaryChartData);
  }

  const chartColors: string[] = getChartColors(primaryData);

  const chartOpts: { [key: string]: any } = getChartOpts(
    chartColors,
    settings,
    secondaryChartType
  );

  return {
    primaryChartType,
    primaryChartData,
    secondaryChartType,
    secondaryChartData,
    chartColors,
    chartOpts,
  };
};

/**
 * Converts PptxGenJS core chart data into combo chart data (the core chart and combo chart function signatures are slightly different)
 *
 * @param settings The chart settings
 * @param primaryChartData The primary data
 * @param secondaryChartData The secondary data
 * @param chartColors An Array of chart colours
 */
export const getComboChartData = (
  settings: ChartSettings,
  primaryChartData: TupChartData[],
  secondaryChartData: TupChartData[],
  chartColors: string[]
): TupComboChartData[] => {
  return [
    {
      type: TupChartType[settings.primaryChartType],
      data: primaryChartData,
      options: {
        chartColors,
      },
    },
    {
      type: TupChartType[settings.secondaryChartType],
      data: secondaryChartData,
      options: {
        chartColors,
        secondaryCatAxis: true,
        secondaryValAxis: true,
      },
    },
  ];
};

export const buildFreqRows = (
  frequencyLevelTo: number,
  isGroupingOn: boolean,
  freqUpTo: number,
  groupsOf: number
) => {
  let step = 0;
  let freqLevelTo = Math.min(frequencyLevelTo, 29);
  const freqRows = [];

  while (
    step <= freqLevelTo ||
    (isGroupingOn && freqLevelTo >= step + groupsOf - 1)
  ) {
    let label = step === freqLevelTo && !isGroupingOn ? `${step}+` : `${step}`;
    const data = [];

    if (
      isGroupingOn &&
      step > freqUpTo &&
      freqUpTo > 0 &&
      groupsOf > 0 &&
      freqLevelTo >= step + groupsOf - 1
    ) {
      let rangeEnd = step + groupsOf - 1;
      for (let i = step; i <= rangeEnd; i++) {
        data.push(i);
      }
      step = rangeEnd + 1;

      if (data.length > 1) {
        label += `-${rangeEnd}`;
      }
    } else {
      if (step <= freqUpTo || !isGroupingOn) {
        data.push(step);
      }
      step++;
    }

    if (data.length > 0) {
      freqRows.push({
        label,
        data,
      });
    }
  }

  return freqRows;
};
