import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DocumentsDataSource,
  TupDocumentStatus,
  TupDocumentStorageComponent,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import {
  LatestCampaignTableColumns,
  CampaignTags,
} from 'src/app/models/doc-storage.models';

@Component({
  selector: 'dashboard-latest-campaigns',
  templateUrl: './dashboard-latest-campaigns.component.html',
  styleUrls: ['./dashboard-latest-campaigns.component.scss'],
})
export class DashboardLatestCampaignsComponent implements OnInit {
  campaignTableOptions = {
    types: [TupDocumentTypeId.CMP_CAMPAIGN],
    statuses: [TupDocumentStatus.ACTIVE, TupDocumentStatus.ARCHIVED],
    _source: ['metadata', 'content.header'],
    columns: LatestCampaignTableColumns,
    documentReplacementText: 'latest campaign',
    tags: CampaignTags,
    pageSize: 8,
    panel: false,
    actions: [
      'click:edit:edit',
      'click:duplicate:file_copy',
      'click:delete:delete_outline',
      'update_tags',
    ],
  };
  @ViewChild('latestCampaignTable')
  public myLatestCampaigns: TupDocumentStorageComponent;
  public dataSource: DocumentsDataSource;

  @Output() onSeeAllClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() onCreateNewCampaign: EventEmitter<number> =
    new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  seeAllClick() {
    this.onSeeAllClick.emit(1);
  }
  createNewCampaign() {
    this.onCreateNewCampaign.emit(1);
  }
}
