<tup-mat-title-bar
  class="title"
  title="Addressable audience applied to multiple media"
>
  <span fxFlex></span>
  <button
    mat-icon-button
    tupMatButton
    appearance="tiny"
    (click)="onClose('cancel')"
  >
    <mat-icon>clear</mat-icon>
  </button>
</tup-mat-title-bar>

<mat-dialog-content>
  <div
    class="alert-container"
    fxLayoutAlign="start center"
  >
    <mat-icon>error</mat-icon>
    <div>
      This audience is being applied to the following media in your campaign
      <ul>
        <li *ngFor="let vehicle of data.vehicles">&quot;{{ vehicle }}&quot;</li>
      </ul>
    </div>
  </div>

  <div class="message-container">
    <div class="target-title-container">
      {{ data.targetTitle }}
    </div>

    <div class="warning-text-container">
      If you edit this audience it will update the audience for all media it is
      applied to. <br /><br />
      If you only want it to apply to ‘{{ data.vehicleTitle }}’ then please make
      a copy of this audience to create a new variant of this addressable
      audience.
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  class="app-dialog-actions"
  fxLayout="row"
  fxLayoutAlign="space-around center"
  fxLayoutGap="8px"
>
  <button
    mat-stroked-button
    tupMatButton
    class="cancel btn-action"
    (click)="onClose('cancel')"
  >
    Cancel
  </button>
  <button
    mat-stroked-button
    tupMatButton
    class="cancel btn-action"
    (click)="onClose('copy')"
  >
    Make a copy
  </button>
  <button
    mat-raised-button
    tupMatButton
    color="primary"
    class="btn-action"
    (click)="onClose('edit')"
  >
    Edit audience and apply to all
  </button>
</mat-dialog-actions>
