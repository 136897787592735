<div class="targets-bar-container">
  <section class="list-with-scroll">
    <!--  scrollUnit = 3 chip items + their margins  -->
    <div
      class="list"
      appScrollable
      #list="appScrollable"
      [scrollUnit]="175 * 3 + 24"
      #scrollableListRef
    >
      <mat-chip-list>
        <div
          class="toggle-display-targets"
          [ngClass]="{ scroll: !allVisible }"
        >
          <ng-container *ngFor="let target of targetItems; let i = index">
            <mat-chip
              [value]="i"
              (click)="
                isMultiSelect ? onMultipleTargetSelect(i) : onTargetSelect(i)
              "
              [ngClass]="{ 'mat-chip-active': currentTargets.includes(i) }"
              *ngIf="target.documentTarget.planningTarget"
            >
              <mat-icon
                *ngIf="isMultiSelect && currentTargets.includes(i)"
                class="check-icon"
              >
                check
              </mat-icon>
              <span class="target-title"> {{ target.title }} </span>
              <mat-icon
                *ngIf="!isMultiSelect"
                [matTooltip]="targetTooltipData[i]"
                matTooltipClass="multiline-tooltip"
                >info_outline</mat-icon
              >
            </mat-chip>
          </ng-container>
        </div>
      </mat-chip-list>
    </div>

    <div
      class="scroll-button"
      [ngClass]="{ disable: !list.canScrollStart, hidden: !list.isOverflow }"
      (click)="list.scroll(-1)"
      id="scroll-left"
      data-tracking="scroll-left"
    >
      <mat-icon>navigate_before</mat-icon>
    </div>

    <div
      class="scroll-button"
      [ngClass]="{ disable: !list.canScrollEnd, hidden: !list.isOverflow }"
      (click)="list.scroll(1)"
      data-tracking="scroll-right"
    >
      <mat-icon>navigate_next</mat-icon>
    </div>
  </section>

  <span
    *ngIf="list.isOverflow || listToggled"
    class="audience-toggle-button tab-group"
    (click)="onVisibleItemsButtonClick()"
    >{{ allVisible ? "SHOW LESS" : "SHOW MORE" }}</span
  >
</div>
