import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'document-editor-bar',
  templateUrl: './document-editor-bar.component.html',
  styleUrls: ['./document-editor-bar.component.scss'],
})
export class DocumentEditorBarComponent implements OnInit {
  @ViewChild('editor') editor: ElementRef;

  @Input() highlightOnEdit: boolean = false;
  @Input() label: string = 'Campaign name';
  @Input() title: string = '';
  @Input() inlineEditing: boolean = true;

  @Output() titleChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() editClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  editing: boolean = false;
  prevTitle: string;

  constructor() {}

  ngOnInit(): void {}

  onEdit() {
    if (this.inlineEditing) {
      this.editing = true;
      this.prevTitle = this.title;

      setTimeout(() => {
        this.highlightOnEdit
          ? this.editor.nativeElement.select()
          : this.editor.nativeElement.focus();
      });
    } else {
      this.editClicked.emit();
    }
  }

  onCloseEdit(confirmed: boolean) {
    this.editing = false;

    if (confirmed && this.title !== this.prevTitle)
      this.titleChanged.emit(this.title);
    this.title = this.prevTitle;
  }

  onBlur() {
    // if the blur was triggered by a button click, give the click event time to respond.
    setTimeout(() => {
      this.onCloseEdit(true);
    }, 200);
  }
}
