import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatRadioChange } from '@angular/material/radio';

export interface SelectionOption {
  id: number | String;
  label: string;
  selectedLabel?: string;
  selected?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'confirm-selection',
  templateUrl: './confirm-selection.component.html',
  styleUrls: ['./confirm-selection.component.scss'],
})
export class ConfirmSelectionComponent implements OnInit {
  @Input() title: string = 'Filter';
  @Input() subTitle: string = 'Advanced search';
  @Input() options: SelectionOption[] = [];
  @Input() buttonIcon: string = 'settings';
  @Output() optionsChange: EventEmitter<SelectionOption[]> = new EventEmitter<
    SelectionOption[]
  >();
  @Output() change: EventEmitter<SelectionOption[]> = new EventEmitter<
    SelectionOption[]
  >();
  @Output() confirm: EventEmitter<SelectionOption> =
    new EventEmitter<SelectionOption>();
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  currentSelected: SelectionOption;

  constructor() {}

  ngOnInit(): void {}

  onDropdownToggle(opened: boolean) {
    if (opened) {
      this.currentSelected = this.options.find((opt) => opt.selected);
    }
  }

  onMenuItemClick(event) {
    event.stopPropagation();
    this.onChange();
  }

  onRadioButtonChange(option: SelectionOption, event: MatRadioChange) {
    this.options.forEach((opt) => (opt.selected = opt === option));
  }

  onChange() {
    this.optionsChange.emit(this.options);
    this.change.emit(this.options);
  }

  onConfirmation(save: boolean) {
    this.menuTrigger.closeMenu();

    // restore intially selected
    if (!save && this.currentSelected) {
      this.options.forEach(
        (opt) => (opt.selected = opt.id === this.currentSelected.id)
      );
    }

    this.confirm.emit(save ? this.options.find((opt) => opt.selected) : null);
  }
}
