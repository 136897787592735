import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { SurveyMetrics } from 'src/app/models/planning.models';
import { Metrics } from '../dialogs/manage-audience-metrics/manage-audience-metrics.component';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { AppendUnitsPipe } from 'src/app/pipes/append-units.pipe';

@Component({
  selector: 'media-toolbar',
  templateUrl: './media-toolbar.component.html',
  styleUrls: ['./media-toolbar.component.scss'],
})
export class MediaToolbarComponent implements OnInit, OnChanges {
  @Input() survey: SurveyMetrics;
  @Input() mediaSelectorTemplate: TemplateRef<any>;
  @Input() meadiaTebleSortFilter?: string;
  unitsText = '';
  appendUnits = new AppendUnitsPipe();
  get selectedFilter() {
    const foundMetric = Metrics.find(
      (val) => val.columnDef === this.meadiaTebleSortFilter
    );
    let selectedMetric = foundMetric?.header || '';
    if (this.unitsText) {
      selectedMetric = this.appendUnits.transform(foundMetric, this.unitsText);
    }
    return selectedMetric;
  }

  constructor(private mediaplannerService: MediaPlannerService) {}
  ngOnChanges(changes: SimpleChanges): void {
    const surveyChanged = changes['survey'];
    if (surveyChanged && surveyChanged.currentValue) {
      this.unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
        surveyChanged.currentValue.surveyCode
      ).reportUnitText;
    }
  }

  ngOnInit(): void {}

  clearFilter() {
    this.mediaplannerService.plan.mediaSettings.sortBy = '';
  }
}
