import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  TupDocumentStatus,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import {
  CampaignTags,
  LatestTemplateTableColumns,
} from 'src/app/models/doc-storage.models';

@Component({
  selector: 'dashboard-latest-tamplates',
  templateUrl: './dashboard-latest-tamplates.component.html',
  styleUrls: ['./dashboard-latest-tamplates.component.scss'],
})
export class DashboardLatestTamplatesComponent implements OnInit {
  latestTemplateTableOptions = {
    types: [TupDocumentTypeId.CMP_CAMPAIGN_TEMPLATE],
    statuses: [TupDocumentStatus.ACTIVE, TupDocumentStatus.ARCHIVED],
    _source: ['metadata', 'content.header'],
    columns: LatestTemplateTableColumns,
    documentReplacementText: 'latest template',
    tags: CampaignTags,
    pageSize: 8,
    panel: false,
    actions: [
      'click:Start campaign:add_circle_outline',
      'click:delete:delete_outline',
      'update_tags',
    ],
  };
  @Output() onSeeAllClick: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}
  seeAllClick() {
    this.onSeeAllClick.emit(2);
  }
}
