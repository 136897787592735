import { DecimalPipe } from '@angular/common';
import { TreeTableColumn } from '../components/tree-table/tree-table.models';

export const Column_Tot_Inserts: TreeTableColumn = {
  header: 'Inserts',
  style: { width: '100px' },
  columnDef: 'inserts',
  columnType: 'number',
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.inserts,
};

export const Column_Tot_Impressions: TreeTableColumn = {
  header: 'Impressions',
  style: { width: '100px' },
  columnDef: 'impressions',
  columnType: 'number',
  showUnits: true,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.impressions,
};

export const Column_Tot_GRPs: TreeTableColumn = {
  header: 'GRPs',
  style: { width: '100px' },
  columnDef: 'grps',
  columnType: 'number',
  decimals: 1,
  pipe: { token: DecimalPipe as any, args: ['1.1-1'] },
  cell: (row) => row.data?.grps,
};

export const Column_Tot_ReachPct: TreeTableColumn = {
  header: 'Reach %',
  style: { width: '100px' },
  columnDef: 'reachPct',
  columnType: 'number',
  decimals: 2,
  pipe: { token: DecimalPipe as any, args: ['1.2-2'] },
  cell: (row) => row.data?.reachPct,
};

export const Column_Tot_Reach000: TreeTableColumn = {
  header: 'Reach',
  style: { width: '100px' },
  columnDef: 'reach000',
  columnType: 'number',
  showUnits: true,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.reach000,
};

export const Column_Tot_AvgFreq: TreeTableColumn = {
  header: 'Avg. frequency',
  style: { width: '100px' },
  columnDef: 'avgFrequency',
  columnType: 'number',
  decimals: 1,
  pipe: { token: DecimalPipe as any, args: ['1.1-1'] },
  cell: (row) => row.data?.avgFrequency,
};

export const Column_Tot_TotalCost: TreeTableColumn = {
  header: 'Total cost',
  style: { width: '100px' },
  columnDef: 'totalCost',
  columnType: 'number',
  decimals: 1,
  pipe: { token: DecimalPipe as any, args: ['1.1-1'] },
  cell: (row) => row.data?.totalCost,
};

export const Column_Tot_CPT: TreeTableColumn = {
  header: 'CPT',
  style: { width: '100px' },
  columnDef: 'CPT',
  columnType: 'number',
  decimals: 1,
  pipe: { token: DecimalPipe as any, args: ['1.1-1'] },
  cell: (row) => row.data?.CPT,
};

export const Column_Tot_UniqueReach000: TreeTableColumn = {
  header: 'Unique reach',
  style: { width: '100px' },
  columnDef: 'uniqueReach000',
  columnType: 'number',
  showUnits: true,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.uniqueReach000,
};

export const Column_Tot_EffReach: TreeTableColumn = {
  header: 'Effective reach',
  style: { width: '100px' },
  columnDef: 'effReach',
  columnType: 'number',
  showUnits: true,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.effReach,
};

export const Column_Tot_UniqueReachPct: TreeTableColumn = {
  header: 'Unique reach %',
  style: { width: '100px' },
  columnDef: 'uniqueReachPct',
  columnType: 'number',
  decimals: 2,
  pipe: { token: DecimalPipe as any, args: ['1.2-2'] },
  cell: (row) => row.data?.uniqueReachPct,
};

export const allColumns: TreeTableColumn[] = [
  Column_Tot_Inserts,
  Column_Tot_Impressions,
  Column_Tot_GRPs,
  Column_Tot_ReachPct,
  Column_Tot_Reach000,
  Column_Tot_AvgFreq,
  Column_Tot_TotalCost,
  Column_Tot_CPT,
  Column_Tot_UniqueReach000,
  Column_Tot_EffReach,
  Column_Tot_UniqueReachPct,
];

export const allChartableColumns: TreeTableColumn[] = allColumns.filter(
  (column) => column.columnType === 'number'
);
