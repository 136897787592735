import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { GAEvents } from '../../../models/analytics.model';

@Component({
  selector: 'app-training-help-dialog',
  templateUrl: './training-help-dialog.component.html',
  styleUrls: ['./training-help-dialog.component.scss'],
})
export class TrainingHelpDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TrainingHelpDialogComponent>,
    private analyticsService: TupAnalyticsService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  openedGuide(e) {
    e.preventDefault();
    this.analyticsService.e(GAEvents.help_guide, {
      action: 'downloaded_quick_guide',
    });
  }
}
