import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  TreeTableNode,
  TreeTableColumn,
  TreeTableMenuItem,
} from '../tree-table/tree-table.models';

export interface ContextMenuData {
  row: TreeTableNode;
  column: TreeTableColumn;
}

@Component({
  selector: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  @Input() auto: boolean = true;
  @Input() data: ContextMenuData;
  @Input() menu: TreeTableMenuItem[];
  @Output() itemClick: EventEmitter<TreeTableMenuItem> =
    new EventEmitter<TreeTableMenuItem>();
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  menuTopLeftPosition = { x: 0, y: 0 };

  constructor() {}

  ngOnInit(): void {}

  onClick(item: TreeTableMenuItem) {
    this.itemClick.emit(item);
  }

  // public method - can be called from host
  openMenu(clientX: number, clientY: number) {
    this.doOpen(clientX, clientY);
  }

  onContextMenu(event: MouseEvent) {
    if (!this.auto) return;

    event.preventDefault();
    this.doOpen(event.clientX, event.clientY);
  }

  private doOpen(x: number, y: number) {
    if (this.menu && this.menu.length) {
      this.menuTopLeftPosition.x = x;
      this.menuTopLeftPosition.y = y;

      this.matMenuTrigger.menuData = { item: { content: 'addl data req?' } };
      this.matMenuTrigger.openMenu();
    }
  }
}
