import {
  ExportMrfResult,
  MrfMediaResult,
} from '../models/multi-survey-mrf.models';
import {
  MultiSurveyTarget,
  MultiSurveyVehicle,
  SurveyData,
} from '../models/multi-survey.models';
import { Result } from './result';

export const MRF_PREFIX = 'mrf^';

export class MrfFile {
  product: string = '';
  errors: string[] = [];
  warnings: string[] = [];
  vehicles: MultiSurveyVehicle[] = [];

  fileData: ExportMrfResult = null;

  constructor() {}

  clearAll() {
    this.vehicles = [];
    this.errors = [];
    this.warnings = [];
    this.product = '';
    this.fileData = null;
  }

  // recursively build the vehicles array based on the data from the MRF file
  // If the name is prefixed with 'broadcast' or 'mediatype' then that's stripped out which is only the case if exported from CMP
  processMedia(parent: MrfMediaResult, media: MrfMediaResult[]) {
    media.forEach((element) => {
      // the array of subMedia passed in, those with no children much be a vehicle
      if (!element.subMedia || element.subMedia.length === 0) {
        const [type, name] = parent.name.split('|'); // trim off the type if found, e.g:  "mediatype|PAMCO Print Vehicles"
        const mediaTypeName = typeof name === 'undefined' ? type : name;
        const duration = element.duration || 1;

        this.vehicles.push({
          title: element.name,
          code: element.name,
          mediaTypeId: -1,
          mediaType: mediaTypeName,
          audience: -1,
          ESG: element.ESG,
          ESGStability: element.ESGStability,
          cost: element.cost,
          result: new Result(0, {
            totalCost: element.cost || 0,
            unitCost:
              element.cost && element.insertions
                ? element.cost / (element.insertions * duration)
                : 0,
            inserts: element.insertions || 0,
            duration,
          }),
        });
      } else {
        this.processMedia(element, element.subMedia);
      }
    });
  }

  load(fileContent: string): boolean {
    this.clearAll();

    try {
      this.fileData = JSON.parse(fileContent);
      if (!this.fileData.header?.planningSystem) {
        this.errors.push('Not a valid file.  Missing Section: [header]');
      } else {
        this.product = this.fileData.header.planningSystem;
      }
      if (!this.fileData.media.subMedia) {
        this.errors.push('Not a valid file.  Missing Section: [media]');
      } else {
        this.processMedia(this.fileData.media, this.fileData.media.subMedia);
      }
      return true;
    } catch (error) {
      return false;
    }
  }
}
