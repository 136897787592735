<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Chart settings"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <!-- <h2
      class="mat-h4 group-name"
      *ngIf="groupName"
    >
      Group name: {{ groupName }} 
    </h2> -->

    <!-- <h2 class="group-name"> {{ chartSettings.id }} </h2> -->

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Primary data-item</mat-label>
        <mat-select
          (ngModelChange)="onPrimaryDataItemChange($event)"
          [(ngModel)]="chartSettings.primaryDataItem"
        >
          <mat-option
            *ngFor="let dataItem of chartDataItems"
            [value]="dataItem.value"
          >
            {{ dataItem.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Primary chart type</mat-label>
        <mat-select
          [(ngModel)]="chartSettings.primaryChartType"
          (selectionChange)="onPrimaryChartTypeChange()"
        >
          <mat-option
            *ngFor="let chartType of chartTypes"
            [value]="chartType.value"
          >
            {{ chartType.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- secondary dataitem and chart type removed untli enhanced chart story is started -->
    <div
      *ngIf="false"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      [ngClass]="{ 'is-hidden': !isSuitableChartForMixing }"
    >
      <!-- Secondary data-item -->
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Secondary data-item</mat-label>
        <mat-select
          (ngModelChange)="onSecondaryDataItemChange($event)"
          [(ngModel)]="chartSettings.secondaryDataItem"
        >
          <mat-option value="None"> None </mat-option>
          <mat-option
            *ngFor="let dataItem of chartDataItems"
            [value]="dataItem.value"
          >
            {{ dataItem.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Secondary chart type -->
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        [ngClass]="{ 'is-invisible': isSecondaryChartTypeDisabled }"
        class="input-form-field"
      >
        <mat-label>Secondary chart type</mat-label>
        <mat-select [(ngModel)]="chartSettings.secondaryChartType">
          <mat-option
            *ngFor="let chartType of secondaryChartTypes"
            [value]="chartType.value"
          >
            {{ chartType.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      *ngIf="chartSettingsMode !== chartSettingsModeType.global"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <!-- title -->
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Chart title</mat-label>
        <input
          matInput
          [(ngModel)]="chartSettings.title"
          [value]="chartSettings.title"
        />
      </mat-form-field>

      <!-- sub title -->
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Chart subtitle</mat-label>
        <input
          matInput
          [(ngModel)]="chartSettings.subTitle"
          [value]="chartSettings.subTitle"
        />
      </mat-form-field>

      <!-- <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Top rows count</mat-label>
        <input
          matInput
          type="number"
          min="1"
          [max]="chartSettings.maxRowsCount"
          [(ngModel)]="chartSettings.topRowsCount"
          (change)="onTopRowsCountChange()"
          (keyup)="onTopRowsCountChange()"
        />
      </mat-form-field> -->
    </div>

    <!--<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-form-field appearance="outline" tupMatFormField fxFlex>
        <mat-label>No. of Char in Axis label</mat-label>
        <input
          matInput
          type="number"
          min="0"
          [(ngModel)]="chartSettings.numberOfChartInAxisLabel"
          (change)="onNumberOfChartInAxisLabelChange()"
          (keyup)="onNumberOfChartInAxisLabelChange()"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" tupMatFormField fxFlex>
        <mat-label>Axis label angle</mat-label>
        <input
          matInput
          type="number"
          [max]="maxAxisLabelAngle"
          min="0"
          [(ngModel)]="chartSettings.axisLabelAngle"
          (change)="onAxisLabelAngleChange()"
          (keyup)="onAxisLabelAngleChange()"
        />
      </mat-form-field>
    </div>-->

    <div
      *ngIf="chartSettingsMode !== chartSettingsModeType.global"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Sort column</mat-label>
        <mat-select [(ngModel)]="chartSettings.sortColumn">
          <ng-container>
            <mat-option
              *ngFor="let target of targets"
              [value]="target.id"
              >{{ target.title }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Column sort order</mat-label>
        <mat-select [(ngModel)]="chartSettings.columnSortOrder">
          <mat-option
            *ngFor="let columnSortOrder of columnSortOrders"
            [value]="columnSortOrder"
          >
            {{ columnSortOrder }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <!-- show data label -->
      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="chartSettings.showChartLabel"
        >Show data labels
      </mat-checkbox>

      <!-- Show footer label -->
      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="chartSettings.showFooter"
        >Show campaign label
      </mat-checkbox>

      <!-- Hide chart legend -->
      <mat-checkbox
        *ngIf="
          chartTargetMode !== chartTargetModeType.combined ||
          !chartSettings.showDataTable
        "
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        [(ngModel)]="chartSettings.hideChartLegend"
        class="tiny-checkbox margin-adjustment"
        disabled="{{ chartSettings.primaryChartType === 'tupWordcloud' }}"
        >Hide chart legend
      </mat-checkbox>

      <!-- Flag show resps has been disabled / hidden.  Logic still in the component just in case -->
      <!-- <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        fxFlex="100"
      >
        <mat-checkbox
          tupMatCheckbox
          appearance="small"
          color="primary"
          fxFlex
          fxGrow="1"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="chartSettings.flagRowResps"
          >Flag row resps</mat-checkbox
        >

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field-without-label"
        >
          <input
            matInput
            type="number"
            min="0"
            [disabled]="!chartSettings.flagRowResps"
            [(ngModel)]="chartSettings.flagRowRespsValue"
            (change)="onFlagRowRespsChange()"
            (keyup)="onFlagRowRespsChange()"
          />
        </mat-form-field>
      </div> -->
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="margin-adjustment"
    >
      <!-- <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        [(ngModel)]="chartSettings.showDataTable"
        class="tiny-checkbox"
        >Show data table</mat-checkbox
      > -->
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex="100"
        class="input-form-field"
      >
        <mat-label>Decimal places</mat-label>
        <input
          matInput
          type="number"
          [max]="maxDecimalPlaces"
          min="0"
          [(ngModel)]="chartSettings.decimalPlaces"
          (change)="onDecimalPlacesChange()"
          (keyup)="onDecimalPlacesChange()"
        />
      </mat-form-field>

      <mat-checkbox
        fxFlex="100"
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment is-invisible"
        [(ngModel)]="chartSettings.swapData"
        >Swap data</mat-checkbox
      >
    </div>

    <!-- Extra table settings disabled -->

    <!-- <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="margin-adjustment"
    >
      <h3 class="mat-h4 setting-section-title">Extra table settings</h3>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="15px"
        fxFlex
        fxGrow="1"
      >
        <mat-checkbox
          *ngFor="let settingItem of extraTableSettings"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox"
          [checked]="chartSettings.extraTableSettings.includes(settingItem)"
          (change)="onExtraTableSettingsChange($event, settingItem)"
          [disabled]="
            settingItem === chartSettings.primaryDataItem ||
            settingItem === chartSettings.secondaryDataItem
          "
          >{{ settingItem }}</mat-checkbox
        >
      </div>
    </div> -->

    <mat-accordion>
      <!-- additional Series selection -->
      <mat-expansion-panel
        *ngIf="chartSettings.showTargetSelection && targets.length > 0 && ready"
        class="expand-panel"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>Series selection</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div
          *ngFor="let target of targets"
          style="margin: 3px"
        >
          <mat-checkbox
            tupMatCheckbox
            [(ngModel)]="target.visible"
            color="primary"
            appearance="small"
            (change)="chartSettings.editedTargetSelection = true"
          >
            {{ target.title }}
          </mat-checkbox>
        </div>
      </mat-expansion-panel>

      <!-- series color selection -->
      <mat-expansion-panel
        *ngIf="
          targets.length > 0 && chartSettings.allowSeriesColorEdit && ready
        "
        class="expand-panel"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>Edit series color</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div
          fxLayout="column"
          fxFlex="100"
          fxLayoutGap="5px"
          fxFill
        >
          <div
            *ngFor="let chartColor of chartColors; let i = index"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="space-between center"
          >
            <h4
              class="mat-h5 target-color-title"
              fxLayout="row"
              fxFlex="100"
            >
              {{ chartColor.title }}
            </h4>
            <div
              fxLayout="row"
              fxFlex="100"
              fxLayoutGap="10px"
            >
              <button
                *ngFor="let color of chartColor.colors; let colorIndex = index"
                mat-mini-fab
                class="color-dot"
                [ngStyle]="{ 'background-color': color.color }"
                [ngClass]="{ 'color-dot-border': color.isSelected }"
                (click)="changeColor(i, colorIndex, color.color)"
                [disableRipple]="true"
              ></button>
              <button
                mat-mini-fab
                (click)="colorPickerDialog(i)"
                class="color-dot add-color-btn"
              >
                <mat-icon class="tiny-icon">add</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- additional Series selection -->
    <!-- <div
      *ngIf="chartSettings.showTargetSelection && targets.length > 0"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      class="edit-color-container margin-adjustment"
    >
    <h3
    class="mat-h4 setting-section-title"
    fxLayout="row"
    fxFlex="100"
    fxFill
    fxLayoutAlign="space-between center"
  >
    <span>Series selection</span>
    <button
      mat-stroked-button
      color="primary"
      appearance="small"
      tupMatButton
      [matTooltip]="'Open Menu'"
      class="expand-color-button"
      (click)="onTargetSelectionToggle()"
    >
      <mat-icon class="tiny-icon">{{
        isColorExpanded ? "expand_less" : "expand_more"
      }}</mat-icon>
    </button>
  </h3>
  <div
    *ngIf="isAdditionalSeries"
    fxLayout="column"
    fxFlex="100"
    fxLayoutGap="5px"
    fxFill
  >
    <div *ngFor="let target of targets" >
      <mat-checkbox
        tupMatCheckbox
        [(ngModel)]="target.visible"
        color="primary"
        appearance="small"
        (change)="chartSettings.editedTargetSelection = true"
        >
          {{ target.title }}
      </mat-checkbox>
    </div>
  </div>
  </div> -->

    <!-- edit series colors
    <div
      *ngIf="chartSettingsMode !== chartSettingsModeType.global && targets.length > 0"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      class="edit-color-container margin-adjustment"
    >
      <h3
        class="mat-h4 setting-section-title"
        fxLayout="row"
        fxFlex="100"
        fxFill
        fxLayoutAlign="space-between center"
      >
        <span>Edit series color:</span>
        <button
          mat-stroked-button
          color="primary"
          appearance="small"
          tupMatButton
          [matTooltip]="'Open Menu'"
          class="expand-color-button"
          (click)="onEditColorToggle()"
        >
          <mat-icon class="tiny-icon">{{
            isColorExpanded ? "expand_less" : "expand_more"
          }}</mat-icon>
        </button>
      </h3>
      <div
        *ngIf="isColorExpanded"
        fxLayout="column"
        fxFlex="100"
        fxLayoutGap="5px"
        fxFill
      >
        <div
          *ngFor="let chartColor of chartColors; let i = index"
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="space-between center"
        >
          <h4
            class="mat-h5 target-color-title"
            fxLayout="row"
            fxFlex="100"
          >
            {{ chartColor.title }}
          </h4>
          <div
            fxLayout="row"
            fxFlex="100"
            fxLayoutGap="10px"
          >
            <button
              *ngFor="let color of chartColor.colors; let colorIndex = index"
              mat-mini-fab
              class="color-dot"
              [ngStyle]="{ 'background-color': color.color }"
              [ngClass]="{ 'color-dot-border': color.isSelected }"
              (click)="changeColor(i, colorIndex, color.color)"
              [disableRipple]="true"
            ></button>
            <button
              mat-mini-fab
              (click)="colorPickerDialog(i)"
              class="color-dot"
              [ngStyle]="{ 'background-color': 'white' }"
            >
              <mat-icon class="tiny-icon">add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Filters   disabled for now - perhaps not needed at all in CMP (?) -->
    <div
      *ngIf="false"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <h3 class="mat-h4 setting-section-title">Filters:</h3>
      <div
        fxLayout="column"
        fxFlex="100"
        fxFill
      >
        <div
          *ngFor="let filter of chartSettings.filters; let i = index"
          fxLayout="row"
          fxFlex="100"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <mat-icon
            class="tiny-icon remove-filter-icon margin-adjustment"
            (click)="onFilterCancelClick(filter)"
            >cancel</mat-icon
          >

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label"
          >
            <mat-select [(ngModel)]="filter.target">
              <mat-option
                *ngIf="chartSettingsMode === chartSettingsModeType.global"
                [value]="filter.target"
              >
                {{ i === 0 ? "Primary" : "Secondary" }}
              </mat-option>

              <ng-container
                *ngIf="chartSettingsMode !== chartSettingsModeType.global"
              >
                <mat-option value="None"> None </mat-option>
                <mat-option
                  *ngFor="let target of targets"
                  [value]="target.id"
                  >{{ target.title }}</mat-option
                >
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label"
          >
            <mat-select [(ngModel)]="filter.dataItem">
              <mat-option value="None"> None </mat-option>
              <mat-option
                *ngFor="let dataItem of chartDataItems"
                [value]="dataItem.value"
              >
                {{ dataItem.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label"
          >
            <mat-select [(ngModel)]="filter.operator">
              <mat-option
                *ngFor="let filterOperator of filterOperators"
                [value]="filterOperator"
              >
                {{ filterOperator }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="5px"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFlex
              class="input-form-field-without-label filter-value-field"
            >
              <input
                matInput
                type="number"
                min="0"
                [(ngModel)]="filter.value[0]"
                (change)="onFilterValueChange(filter)"
                (keyup)="onFilterValueChange(filter)"
              />
            </mat-form-field>
            <ng-container *ngIf="filter.operator === 'between'">
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="5px"
              >
                <span class="margin-adjustment">To</span>
                <mat-form-field
                  appearance="outline"
                  tupMatFormField
                  fxFlex
                  class="input-form-field-without-label filter-value-field"
                >
                  <input
                    matInput
                    type="number"
                    min="0"
                    [(ngModel)]="filter.value[1]"
                    (change)="onFilterValueChange(filter)"
                    (keyup)="onFilterValueChange(filter)"
                  />
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onButtonClick(false)"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick(true)"
    >
      {{
        chartSettingsMode === chartSettingsModeType.global
          ? "View updated charts"
          : "View chart"
      }}
    </button>
  </mat-dialog-actions>
</div>
