import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  TreeTableColumn,
  TreeTableMenuItem,
  TreeTableNode,
} from '../tree-table.models';

export interface conextMenuData {
  row: TreeTableNode;
  column: TreeTableColumn;
}

@Component({
  selector: 'tree-context-menu',
  templateUrl: './tree-context-menu.component.html',
  styleUrls: ['./tree-context-menu.component.scss'],
})
export class TreeContextMenuComponent implements OnInit {
  @Input() data: conextMenuData;
  @Input() menu: TreeTableMenuItem[];
  @Output() itemClick: EventEmitter<TreeTableMenuItem> =
    new EventEmitter<TreeTableMenuItem>();
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  constructor() {}

  ngOnInit(): void {}

  onClick(item: TreeTableMenuItem) {
    this.itemClick.emit(item);
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();

    if (this.menu && this.menu.length) {
      this.matMenuTrigger.menuData = { item: { data: this.data } };
      this.matMenuTrigger.openMenu();
    }
  }
}
