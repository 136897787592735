import { Injectable } from '@angular/core';

import {
  TupXlsxBuilder,
  TupXlsxExporterService,
} from '@telmar-global/tup-document-exporter';

@Injectable({
  providedIn: 'root',
})
export class XlsxService {
  constructor(private exporterService: TupXlsxExporterService) {}

  public async saveAs(
    builder: TupXlsxBuilder,
    filename?: string
  ): Promise<void> {
    this.exporterService.saveAs(this.exporterService.build(builder), filename);
  }

  public async exportToSheets(
    builder: TupXlsxBuilder,
    filename?: string
  ): Promise<void> {
    this.exporterService.exportToSheets(
      this.exporterService.build(builder),
      filename
    );
  }
}
