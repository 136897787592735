import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Target } from 'src/app/classes/target';

@Component({
  selector: 'targets-bar',
  templateUrl: './targets-bar.component.html',
  styleUrls: ['./targets-bar.component.scss'],
})
export class TargetsBarComponent implements OnInit {
  @Input() targetItems: Target[];
  @Input() selectedTargets: Target[];
  @Input() targetTooltipData: string[];
  @Input() isMultiSelect: boolean;
  @ViewChild('scrollableListRef') scrollableList: ElementRef;
  @Output() targetSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() multipleTargetsSelected: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  currentTargets: number[] = [];
  allVisible = false;
  listToggled = false;
  scrolledAmount: number;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    const isMultiSelect = changes['isMultiSelect'];
    const selectedTargets = changes['selectedTargets'];

    if (
      selectedTargets &&
      selectedTargets.previousValue !== selectedTargets.currentValue &&
      !selectedTargets.firstChange
    ) {
      if (
        this.currentTargets?.length === 1 &&
        selectedTargets.currentValue?.length === 1
      ) {
        const currentIndex = this.targetItems.indexOf(
          selectedTargets.currentValue[0]
        );
        if (this.currentTargets[0] !== currentIndex) {
          this.currentTargets = [currentIndex];
        }
      }

      //set default value as first selectedTarget
      if (selectedTargets.previousValue.length === 0) {
        this.currentTargets.push(
          this.targetItems.indexOf(selectedTargets.currentValue[0])
        );
      }
    }

    if (
      isMultiSelect &&
      isMultiSelect.previousValue !== isMultiSelect.currentValue &&
      isMultiSelect.currentValue === false &&
      !isMultiSelect.firstChange
    ) {
      this.currentTargets = [
        this.targetItems.findIndex((tgt) => tgt.documentTarget.planningTarget),
      ];
    }

    if (
      isMultiSelect &&
      isMultiSelect.previousValue !== isMultiSelect.currentValue &&
      isMultiSelect.currentValue === true
    ) {
      if (this.selectedTargets.length) {
        this.currentTargets = [];
        this.targetItems.forEach((target, index) => {
          if (this.selectedTargets.includes(target)) {
            this.currentTargets.push(index);
          }
        });
      }
    }
  }

  onTargetSelect(targetIndex: number) {
    this.currentTargets = [targetIndex];
    this.targetSelected.emit(targetIndex);
  }

  onMultipleTargetSelect(targetIndex: number) {
    if (this.currentTargets.includes(targetIndex)) {
      if (this.currentTargets.length > 1) {
        const selectedIndex = this.currentTargets.indexOf(targetIndex);
        this.currentTargets.splice(selectedIndex, 1);
      }
    } else {
      this.currentTargets.push(targetIndex);
    }
    this.multipleTargetsSelected.emit(this.currentTargets);
  }

  onVisibleItemsButtonClick() {
    // before showing all targets, save how much the list was scrolled
    if (!this.allVisible) {
      this.scrolledAmount = this.scrollableList.nativeElement.scrollLeft;
    }

    this.allVisible = !this.allVisible;
    this.listToggled = true;

    // when the targets list gets collapsed to scroll view, apply any scroll previously made to the list
    if (!this.allVisible && this.scrolledAmount > 0) {
      setTimeout(() => {
        this.scrollableList.nativeElement.scroll({
          top: 0,
          left: this.scrolledAmount,
          behavior: 'instant',
        });
      }, 10);
    }
  }
}
