<tup-mat-card
  fxLayout="column"
  class="briefing-container"
>
  <h3>Campaign Header</h3>
  <div fxLayout="row">
    <!-- advertiser Name -->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Advertiser Name</mat-label>
      <input
        class="text-field"
        matInput
        [formControl]="advertiserName"
      />
    </mat-form-field>

    <!-- campaign Name -->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Campaign Name</mat-label>
      <input
        class="text-field"
        matInput
        [formControl]="campaignName"
      />
    </mat-form-field>
  </div>

  <div fxLayout="row">
    <!-- planner Name -->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Planner Name</mat-label>
      <input
        class="text-field"
        matInput
        [formControl]="plannerName"
      />
    </mat-form-field>

    <!-- Start Date-->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Date</mat-label>
      <input
        type="date"
        matInput
        [formControl]="startDate"
      />
    </mat-form-field>

    <!-- campaign ID -->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Campaign ID</mat-label>
      <input
        class="text-field"
        matInput
        [formControl]="campaignId"
      />
    </mat-form-field>
  </div>

  <hr />
  <h3>Media Brief</h3>
  <div fxLayout="row">
    <!-- objectives dropdown -->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      data-tracking="survey-select"
      fxFlex="1 0"
    >
      <mat-label>Objective</mat-label>
      <mat-select [formControl]="briefObjective">
        <mat-option
          *ngFor="let obj of briefObjectiveOptions"
          [value]="obj"
          >{{ obj }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- KPI 1 -->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      data-tracking="survey-select"
      fxFlex="1 0"
    >
      <mat-label>KPI 1</mat-label>
      <mat-select [formControl]="KPI1">
        <mat-option
          *ngFor="let kpi of KPIOptions"
          [value]="kpi"
          >{{ kpi }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- KPI 2 -->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      data-tracking="survey-select"
      fxFlex="1 0"
    >
      <mat-label>KPI 2</mat-label>
      <mat-select [formControl]="KPI2">
        <mat-option
          *ngFor="let kpi of KPIOptions"
          [value]="kpi"
          >{{ kpi }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <!-- KPI 3 -->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      data-tracking="survey-select"
      fxFlex="1 0"
    >
      <mat-label>KPI 3</mat-label>
      <mat-select [formControl]="KPI3">
        <mat-option
          *ngFor="let kpi of KPIOptions"
          [value]="kpi"
          >{{ kpi }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row">
    <!-- Flight Start-->
    <mat-form-field
      appearance="outline"
      tupMatFormField
    >
      <mat-label>Flight Date Start</mat-label>
      <input
        type="date"
        matInput
        [formControl]="flightDateStart"
      />
    </mat-form-field>

    <!-- Flight End-->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Flight Date End</mat-label>
      <input
        type="date"
        matInput
        [formControl]="flightDateEnd"
      />
    </mat-form-field>

    <!-- Budget-->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Budget</mat-label>
      <input
        type="number"
        matInput
        [formControl]="budget"
      />
    </mat-form-field>

    <!-- Constraints-->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Constraints</mat-label>
      <input
        type="text"
        class="text-field"
        matInput
        [formControl]="constraints"
      />
    </mat-form-field>
  </div>

  <div fxLayout="row">
    <!-- Comments-->
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
    >
      <mat-label>Additional comments</mat-label>
      <input
        class="text-field"
        matInput
        [formControl]="comments"
      />
    </mat-form-field>
  </div>

  <!-- <hr />
    <h3>Launch Plan</h3> -->

  <div fxFlex="0 0 auto">
    <button
      (click)="onClickNextPage()"
      appearance="small"
      mat-flat-button
      tupMatButton
      color="primary"
    >
      Audiences >>
    </button>
  </div>
</tup-mat-card>
