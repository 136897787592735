<tup-mat-title-bar [title]="dialogTitle">
  <span fxFlex></span>
  <button
    mat-icon-button
    tupMatButton
    appearance="tiny"
    (click)="onClose('cancel')"
  >
    <mat-icon>clear</mat-icon>
  </button>
</tup-mat-title-bar>
<mat-dialog-content class="container">
  <mat-radio-group
    fxLayout="row"
    fxLayoutAlign="start"
    fxLayoutGap="20px"
    [(ngModel)]="selectedOption"
  >
    <mat-radio-button
      *ngFor="let option of campaignOptions"
      class="radio-option"
      [checked]="selectedOption === option.value"
      [value]="option.value"
      [disabled]="option.disabled"
      (change)="onChange($event)"
    >
      {{ option.name }}
    </mat-radio-button>
  </mat-radio-group>
  <div fxLayout="row">
    <div>
      <div class="selection-container">
        <mat-form-field
          class="full-width"
          [ngClass]="{ 'disabled-field': selectedOption === 'continue' }"
          [matTooltip]="
            selectedOption === 'continue' ? disabledInputText : null
          "
          [matTooltipDisabled]="selectedOption !== 'continue'"
          appearance="outline"
          tupMatFormField
        >
          <mat-label>Campaign name * </mat-label>
          <input
            #documentName
            matInput
            type="text"
            [(ngModel)]="data.title"
            [disabled]="selectedOption === 'continue'"
          />
        </mat-form-field>

        <mat-form-field
          class="full-width"
          [ngClass]="{ 'disabled-field': selectedOption === 'continue' }"
          [matTooltip]="
            selectedOption === 'continue' ? disabledInputText : null
          "
          [matTooltipDisabled]="selectedOption !== 'continue'"
          appearance="outline"
          tupMatFormField
        >
          <mat-label>Campaign description</mat-label>
          <textarea
            matInput
            type="text"
            [(ngModel)]="data.description"
            [disabled]="selectedOption === 'continue'"
          ></textarea>
        </mat-form-field>
        <button
          class="import-mixed-data-button"
          mat-stroked-button
          tupMatButton
          appearance="large"
          color="primary"
          (click)="onClose('import')"
          [disabled]="selectedOption === 'continue'"
          [matTooltip]="
            selectedOption === 'continue' ? disabledInputText : null
          "
          [matTooltipDisabled]="selectedOption !== 'continue'"
        >
          <mat-icon class="material-symbols-outlined"> upload </mat-icon>
          Import mixed data
        </button>
      </div>
    </div>

    <mat-divider
      class="divider"
      [vertical]="true"
    ></mat-divider>

    <div
      class="drives-container"
      [matTooltip]="selectedOption === 'continue' ? disabledInputText : null"
      [matTooltipDisabled]="selectedOption !== 'continue'"
    >
      <tup-user-container-selector
        #userContainerSelector
        [options]="{ showSubheader: true, showDivider: true }"
        (selectionChange)="selectionChange($event)"
        [ngClass]="{ 'disabled-field': selectedOption === 'continue' }"
      >
      </tup-user-container-selector>
    </div>
  </div>
</mat-dialog-content>

<mat-divider class="horizontal-divider"></mat-divider>

<mat-dialog-actions
  class="app-dialog-actions"
  fxLayoutAlign="space-between center"
  fxLayout="row"
>
  <button
    mat-button
    (click)="onClose('cancel')"
  >
    Cancel
  </button>

  <div fxLayoutAlign="right">
    <ng-container *ngIf="selectedOption === 'continue'">
      <button
        mat-flat-button
        color="primary"
        (click)="onClose('continue')"
      >
        Continue
      </button>
    </ng-container>

    <ng-container *ngIf="selectedOption === 'new'">
      <button
        [disabled]="documentTitle?.length > 0"
        mat-button
        (click)="onClose('skip')"
      >
        Skip
      </button>
      <button
        [disabled]="!documentTitle"
        mat-flat-button
        tupMatButton
        color="primary"
        (click)="onClose('save')"
        data-tracking="save-campaign-btn"
      >
        Start
      </button>
    </ng-container>
  </div>
</mat-dialog-actions>
