import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserContainer } from '@telmar-global/tup-auth';
import {
  TupDocument,
  TupDocumentStatus,
  TupDocumentTypes,
  TupDocumentTypeId,
  TupDocumentService,
  TupUserContainerService,
  Sort,
  Empty,
} from '@telmar-global/tup-document-storage';
import { TupLoggerService } from '@telmar-global/tup-logger-angular';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DocumentTarget } from '../models/document.model';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private defaultContainer: UserContainer = null;
  public container: UserContainer;
  public myDriveContainer: UserContainer;

  constructor(
    private _documentService: TupDocumentService,
    private userContainerService: TupUserContainerService,
    private loggerService: TupLoggerService,
    private snackbarService: SnackbarService
  ) {
    this.containerReady().subscribe();
  }

  targetLibrary: TupDocument<DocumentTarget>[] = [];

  /**
   * TupDocument factory function
   *
   * @param type The document type, i.e. InsightReport or Target
   * @param name The document name
   * @param args args to be passed to the InsightReport or Target constructor
   * @returns A TupDocument
   */
  public createDocumentObject<T>(
    type: { new (name: string, ...args: any[]): T },
    name: string,
    tupDocumentTypeId: number,
    ...args: any[]
  ): TupDocument<T> {
    return {
      metadata: {
        name: name,
        type: TupDocumentTypes[tupDocumentTypeId],
        // These properties are populated by the API
        // by: {
        //   username: this.authService.user.username,
        //   attributes: {
        //     name: this.authService.user.attributes.name,
        //     email: this.authService.user.attributes.email
        //   }
        // },
        //created: Date.now(),
        status: TupDocumentStatus.ACTIVE,
      },
      content: new type(name, ...args),
    };
  }

  public searchDocuments(
    from: number,
    size: number,
    sort: Sort,
    types: TupDocumentTypeId[],
    statuses: TupDocumentStatus[],
    container?: UserContainer,
    tags?: Record<string, string[]>,
    _source?: string[]
  ): Observable<TupDocument<unknown>[]> {
    return new Observable<TupDocument<unknown>[]>((ob) => {
      this.containerReady().subscribe(() => {
        this._documentService
          .search(
            container ? container.name : this.container.name,
            from,
            size,
            sort,
            types,
            tags,
            statuses,
            null,
            _source
          )
          .subscribe((searchResults) => {
            const searchHits =
              searchResults.hits as unknown as TupDocument<unknown>[];
            ob.next(searchHits);
            ob.complete();
          });
      });
    });
  }

  public get(
    documentId: string,
    containerName: string = null
  ): Observable<TupDocument<unknown>> {
    return new Observable((observable) => {
      this.containerReady().subscribe(() => {
        this._documentService
          .get(containerName || this.container.name, documentId)
          .subscribe(
            (document) => {
              observable.next(document);
              observable.complete();
            },
            (error) => {
              observable.next(null);
              observable.complete();
            }
          );
      });
    });
  }

  /**
   * Create a document within the supplied container or using the current one
   *
   * @param document Actual content part of the campaign to save
   * @param container Container to save to (in the case of autosave docs where it's forced to user container)
   * @returns Observable<bool>
   */
  public createDocument(
    document: TupDocument<unknown>,
    container?: UserContainer
  ): Observable<boolean> {
    return new Observable((ob) => {
      this.containerReady().subscribe(() => {
        this._documentService
          .create(container ? container.name : this.container.name, document)
          .subscribe(
            (response: HttpResponse<any>) => {
              document.metadata.id = response.headers
                .get('Location')
                .split('/')
                .pop();
              const clonedContainer = cloneDeep(container || this.container);
              document.metadata.container = clonedContainer;
              this.handleSuccess('Created', document);
              ob.next(true);
              ob.complete();
            },
            (error: HttpErrorResponse) => {
              this.handleError('creating', document, error);
              ob.next(false);
              ob.complete();
            }
          );
      });
    });
  }

  public updateDocument(
    document: TupDocument<unknown>,
    silent: boolean
  ): Observable<boolean> {
    return new Observable((ob) => {
      this._documentService
        .update(document.metadata.container.name, document)
        .subscribe(
          () => {
            this.handleSuccess('Updated', document, silent);
            ob.next(true);
            ob.complete();
          },
          (error: HttpErrorResponse) => {
            this.handleError('updating', document, error);
            ob.next(false);
            ob.complete();
          }
        );
    });
  }

  public updateDocumentThenAction(
    document: TupDocument<unknown>
  ): Observable<Empty> {
    return this._documentService.update(
      document.metadata.container.name,
      document
    );
  }

  public deleteDocument(
    document: TupDocument<unknown>,
    silent: boolean = false,
    complete: () => void = () => {}
  ): void {
    this._documentService
      .delete(document.metadata.container.name, document.metadata.id)
      .subscribe(
        () => this.handleSuccess('Deleted', document, silent),
        (error: HttpErrorResponse) =>
          this.handleError('deleting', document, error),
        complete
      );
  }

  setContainer(container: UserContainer) {
    this.userContainerService.setContainer(container);
  }

  private handleSuccess(
    operation: string,
    document: TupDocument<any>,
    silent: boolean = false
  ): void {
    const fileType =
      document.metadata.type.id === TupDocumentTypeId.CMP_CAMPAIGN
        ? 'campaign'
        : document.metadata.type.id === TupDocumentTypeId.CMP_CAMPAIGN_TEMPLATE
        ? 'template'
        : '';

    if (fileType && !silent)
      this.snackbarService.showSuccessSnackBar(
        `${operation} ${fileType} "${document.metadata.name}"`
      );
  }

  private handleError(
    operation: string,
    document: TupDocument<any>,
    error
  ): void {
    const fileType =
      document.metadata.type.id === TupDocumentTypeId.CMP_CAMPAIGN
        ? 'campaign'
        : document.metadata.type.id === TupDocumentTypeId.CMP_CAMPAIGN_TEMPLATE
        ? 'template'
        : '';

    if (fileType)
      this.snackbarService.showErrorSnackBar(
        `Error ${operation} ${fileType} "${document.metadata.name}"`
      );
  }

  // return true if/when container object is ready to be used
  // TODO: refactor horrible code
  public containerReady(): Observable<boolean> {
    return this.container
      ? of(true)
      : new Observable((ready) => {
          this.userContainerService.container
            .pipe(
              filter(
                (value: UserContainer) => value !== null && value !== undefined
              )
            )
            .subscribe((container: UserContainer) => {
              this.container = container;
              ready.next(true);
              ready.complete();
            });
        });
  }
}
