<tup-mat-title-bar title="{{ dialogTitle }}">
  <span fxFlex></span>
  <button
    mat-icon-button
    tupMatButton
    appearance="tiny"
    (click)="onClose(false)"
  >
    <mat-icon>clear</mat-icon>
  </button>
</tup-mat-title-bar>

<mat-dialog-content
  class="container"
  fxLayout="column"
  fxLayoutGap="16px"
>
  <div class="media-container">
    {{ originalNameLabel }}
    <div [style.fontWeight]="800">{{ data.initialName }}</div>
  </div>
  <div class="selection-container">
    <mat-form-field
      class="full-width"
      appearance="outline"
      tupMatFormField
    >
      <mat-label
        >Rename {{ data.itemType === "spotplan" ? "spot" : "media" }}</mat-label
      >
      <input
        matInput
        type="text"
        [(ngModel)]="data.newName"
      />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions
  class="app-dialog-actions"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="8px"
>
  <button
    mat-stroked-button
    tupMatButton
    class="cancel btn-action"
    (click)="onClose(false)"
  >
    Cancel
  </button>

  <button
    mat-raised-button
    tupMatButton
    color="primary"
    class="btn-action"
    (click)="onClose(true)"
  >
    Save changes
  </button>
</mat-dialog-actions>
