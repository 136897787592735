import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';

interface ExportOption {
  id: string | number;
  label: string;
  checked: boolean;
}

export interface DialogReturnData {
  schedulesNames: (string | number)[];
  targetsIds: (string | number)[];
}

enum tabs {
  schedules = 'schedules',
  targets = 'targets',
}

export class ExportOptionsDialogModel {
  schedules: ExportOption[];
  targets: ExportOption[];
  compareOption: string;
  constructor() {}
}

@Component({
  selector: 'app-export-options-dialog',
  templateUrl: './export-options-dialog.component.html',
  styleUrls: ['./export-options-dialog.component.scss'],
})
export class ExportOptionsDialogComponent implements OnInit {
  tabs = tabs;
  schedulesSelectedCount: number;
  targetsSelectedCount: number;
  noOptionSelected: boolean;
  schedulesSelectedInPlanView: ExportOption[];

  unselectedError: SnackbarGenericOptionModel = {
    type: 'error',
    message: 'Please select at least one option to proceed.',
    icon: StatusSnackbarIcon.Error,
  };

  constructor(
    public dialogRef: MatDialogRef<ExportOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExportOptionsDialogModel
  ) {}

  ngOnInit(): void {
    this.schedulesSelectedInPlanView = this.data.schedules.filter(
      (option) => option.checked
    );
    this.schedulesSelectedCount = this.schedulesSelectedInPlanView.length;
    this.targetsSelectedCount = this.data.targets.filter(
      (option) => option.checked
    ).length;
  }

  verifyIfAllTabOptionsChecked(optionTab: string) {
    switch (optionTab) {
      case tabs.schedules:
        this.schedulesSelectedCount = this.data.schedules.filter(
          (option) => option.checked
        ).length;
        break;
      case tabs.targets:
        this.targetsSelectedCount = this.data.targets.filter(
          (option) => option.checked
        ).length;
        break;
    }
  }

  verifyIfNoOptionIsChecked() {
    const tabKeys = Object.keys(this.data);
    let noOptionSelected = false;

    tabKeys.forEach((tab) => {
      if (
        typeof this.data[tab] === 'object' &&
        !this.data[tab].find((option) => option.checked)
      ) {
        noOptionSelected = true;
      }
    });
    return noOptionSelected;
  }

  onOptionChanged(optionTab: string, option: ExportOption, checked: boolean) {
    const item = this.data[optionTab].find((opt) => opt.id === option.id);
    if (item) {
      item.checked = checked;
      this.noOptionSelected = this.verifyIfNoOptionIsChecked();
      this.verifyIfAllTabOptionsChecked(optionTab);
    }
  }

  onSelectAll(optionTab: string, checked: boolean) {
    this.data[optionTab].map((option) => (option.checked = checked));
    this.noOptionSelected = this.verifyIfNoOptionIsChecked();
    this.verifyIfAllTabOptionsChecked(optionTab);
  }

  onClose(download: boolean) {
    let returnData: DialogReturnData;
    if (download) {
      returnData = {
        schedulesNames: this.data.schedules
          .filter((sch) => sch.checked)
          .map((opt) => opt.label),
        targetsIds: this.data.targets
          .filter((target) => target.checked)
          .map((opt) => opt.id),
      };
    }
    this.dialogRef.close(download ? returnData : null);
  }
}
