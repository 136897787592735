<div class="metrics-container">
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <small
      class="survey-title"
      [ngClass]="{ link: !!survey?.surveyInfo }"
      [matTooltip]="hasSurveyTitleTooltip ? survey?.surveyTitle : ''"
      (click)="onSurveyTitleClick()"
      >{{ survey?.surveyTitle }}</small
    >
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="end"
  >
    <div
      fxLayout="row wrap"
      fxLayoutAlign="start"
      class="population-container"
    >
      <strong>{{ basePopulation ? "Base" : "Target" }}</strong
      >&nbsp;
      <span> | {{ survey?.hasCustomPop ? "Custom Pop" : "Population" }}:</span>
      <span fxFlex
        >&nbsp;<strong>{{
          survey?.population * survey?.units | number : "1.0-0"
        }}</strong></span
      >
    </div>
    &nbsp;&nbsp;<mat-divider [vertical]="true"></mat-divider>&nbsp;&nbsp;
    <div
      fxLayout="row"
      fxLayoutAlign="start"
    >
      <span>Sample:</span>
      <span fxFlex
        >&nbsp;<strong>{{ survey?.sample | number : "1.0-0" }}</strong></span
      >
    </div>
  </div>
</div>
