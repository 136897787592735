<div
  class="dialog-content-container"
  [ngStyle]="{ width: '50vw' }"
>
  <tup-mat-title-bar
    title="Manage surveys"
    class="dialog-title"
  >
    <mat-icon
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-divider class="title-divider"></mat-divider>

  <mat-dialog-content>
    <div
      fxLayout="column"
      fxLayout.gt-md="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      class="survey-search-row"
    >
      <div
        fxLayout="row"
        class="survey-search-container"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          class="survey-search-field"
        >
          <mat-label>Search</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="searchText"
            autocomplete="off"
            (keyup.enter)="searchWord()"
          />
          <mat-icon matPrefix>search</mat-icon>
          <div
            matSuffix
            class="filter-and-clear-icons-container"
          >
            <mat-icon
              class="mat-icon-action"
              (click)="resetSearchText()"
              matTooltip="Reset"
              >clear</mat-icon
            >
            <div
              class="filters-icon-container"
              [ngClass]="
                filtersDialogOpen
                  ? 'filters-dialog-open'
                  : 'filters-dialog-close'
              "
              *ngIf="!isLoading"
            >
              <mat-icon
                class="mat-icon-action"
                (click)="openFiltersDialog()"
                matTooltip="Filters"
                >tune</mat-icon
              >
            </div>
          </div>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Sort by</mat-label>
          <mat-select
            [(ngModel)]="selectedSurveyOrder"
            (ngModelChange)="filterSurveys()"
          >
            <mat-select-trigger>{{
              surveyOrders[selectedSurveyOrder].name
            }}</mat-select-trigger>
            <mat-option
              *ngFor="let surveyOrder of surveyOrders"
              [value]="surveyOrder.value"
              class="sort-option single-selection-sort-option"
            >
              <div class="survey-option-title">
                <span>{{ surveyOrder.name }}</span>
                <mat-icon class="survey-order-done-icon">done</mat-icon>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="mat-chips-container">
      <mat-chip-list>
        <ng-container
          *ngFor="
            let singleSelectionFilter of singleSelectionFilters;
            let i = index
          "
        >
          <mat-chip
            *ngIf="singleSelectionFilter.visible"
            [value]="i"
          >
            <span class="filter-label">{{ singleSelectionFilter.label }}</span>
            <mat-icon
              class="material-symbols-outlined cancel-icon"
              (click)="removeFilter(i)"
              >cancel
            </mat-icon>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>

    <div
      fxLayout="row"
      class="survey-list-transfer"
    >
      <div class="survey-list single-selection-list">
        <span
          fxLayout="row"
          class="survey-list-label-wrapper"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
        >
          <span class="survey-list-label single-selection"
            >AVAILABLE SURVEYS ({{ selectedSurveys.length }}/{{
              filteredSurveyData.length
            }})
            <mat-icon
              class="available-surveys-info-icon"
              (click)="onAvailableSurveysInfoIconClick()"
              >info_outline</mat-icon
            ></span
          >
        </span>

        <div class="survey-list-container single-selection">
          <cdk-virtual-scroll-viewport
            itemSize="28"
            class="survey-list-control"
          >
            <div
              *ngIf="showNoResultsMessage"
              class="show-no-results-container"
            >
              <h4>Sorry, we couldn't find any results.</h4>
              <p>Here are some suggestions:</p>
              <br />
              <ul>
                <li>Make sure all words are spelt correctly.</li>
                <li>Search again with different keywords.</li>
                <li>Try more general keywords</li>
              </ul>
            </div>
            <div #filteredSurveysContainer>
              <div
                *ngIf="!showNoResultsMessage"
                class="clear-selection-container"
              >
                <span
                  class="clear-selection-button"
                  (click)="onClearSelectionClick()"
                  >Clear selection</span
                >
              </div>
              <div
                *ngFor="let survey of filteredSurveyData"
                (click)="onSurveyClick(survey)"
                class="survey-list-option simplified"
                [ngClass]="{
                  'disabled-survey':
                    (disableNonMrfSurveys &&
                      !survey.meta['is-mrf-profile'] &&
                      !survey.meta['is-mrf-globaldemomap']) ||
                    (disableAllSurveys && !survey.checked)
                }"
              >
                <div class="option-title-container">
                  <mat-checkbox
                    color="primary"
                    [checked]="survey.checked"
                    [indeterminate]="false"
                    [disabled]="
                      (disableNonMrfSurveys &&
                        !survey.meta['is-mrf-profile'] &&
                        !survey.meta['is-mrf-globaldemomap']) ||
                      (disableAllSurveys && !survey.checked)
                    "
                    (click)="$event.preventDefault()"
                  >
                  </mat-checkbox>
                  <span
                    *ngIf="survey.isFullAccess"
                    [matTooltip]="
                      (disableNonMrfSurveys &&
                        !survey.meta['is-mrf-profile'] &&
                        !survey.meta['is-mrf-globaldemomap']) ||
                      (disableAllSurveys && !survey.checked)
                        ? 'Survey not compatible with your current selection'
                        : survey.title + ' group: Full access to ' + survey.code
                    "
                  >
                    {{ survey.year }} {{ survey.title }} ({{ survey.code }}) -
                    Full access to {{ survey.code }}
                  </span>

                  <span
                    *ngIf="!survey.isFullAccess"
                    [matTooltip]="
                      (disableNonMrfSurveys &&
                        !survey.meta['is-mrf-profile'] &&
                        !survey.meta['is-mrf-globaldemomap']) ||
                      (disableAllSurveys && !survey.checked)
                        ? 'Survey not compatible with your current selection'
                        : survey.title + ' group: Full access to ' + survey.code
                    "
                  >
                    {{ survey.authorizationGroupDescription }}
                  </span>

                  <span
                    *ngIf="survey.occurrenceCount > 0"
                    class="survey-list-option-count-label"
                  >
                    ({{ survey.occurrenceCount }})
                  </span>
                </div>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-form-field
    appearance="outline"
    tupMatFormField
    class="survey-preference"
  >
    <mat-label>Survey preference: </mat-label>
    <mat-icon
      matPrefix
      class="material-symbols-outlined settings-icon"
      >settings</mat-icon
    >
    <mat-select
      [(ngModel)]="selectedSurveyPreference"
      class="survey-preference-select"
      panelClass="survey-preference-select-panel"
    >
      <mat-select-trigger>{{
        surveyPreferences[selectedSurveyPreference].name
      }}</mat-select-trigger>
      <mat-option
        *ngFor="let preference of surveyPreferences"
        [value]="preference.value"
        class="survey-preference-option"
      >
        <div class="survey-option-title">
          <span>{{ preference.name }}</span>
          <mat-icon class="survey-order-done-icon">done</mat-icon>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-divider class="buttons-divider"></mat-divider>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <button
      mat-button
      (click)="close()"
    >
      Cancel
    </button>
    <div class="dialog-action-group">
      <button
        mat-flat-button
        color="primary"
        cdkFocusRegionstart
        (click)="onButtonClick()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>

<ng-template #surveySelectInfo>
  <span class="survey-note-title"
    >Any survey can be selected for a CrossTab report.</span
  >
  <ul class="survey-note-list">
    <li class="survey-note-list-item">
      <img
        src="assets/images/S.svg"
        alt="S icon"
        class="survey-note-list-item-icon"
      />
      Surveys with an S icon are non trend-able.
    </li>
    <li class="survey-note-list-item">
      <img
        src="assets/images/T.svg"
        alt="T icon"
        class="survey-note-list-item-icon"
      />
      Surveys with a T icon are trend-able.
    </li>
    <li class="survey-note-list-item survey-note-list-item-without-icon">
      When a trend-able survey is selected, related surveys from the same
      provider will be automatically highlighted.
    </li>
    <li class="survey-note-list-item">
      <img
        src="assets/images/map_pin.svg"
        alt="Map pin icon"
        class="survey-note-list-item-icon"
      />
      Surveys with a pin icon may be mapped using GeoGrafix.
    </li>
  </ul>
</ng-template>

<ng-template #trendableSurveySelectInfo>
  <span class="survey-note-copy"
    >Only surveys that are compatible with the chosen survey(s) for trending
    purposes will be displayed.
  </span>
  <span class="survey-note-copy"
    >To utilize a survey that is not compatible or one from a different
    provider, you must remove the selected survey(s).</span
  >
</ng-template>

<ng-template #singleSelectionFiltersDialog>
  <span
    mat-dialog-title
    class="filters-dialog-title"
    >FILTERS</span
  >
  <div
    mat-dialog-content
    class="filters-dialog-content"
  >
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
      class="input-form-field provider-dropdown"
    >
      <mat-label>Provider</mat-label>
      <mat-select [(ngModel)]="singleSelectionFiltersSelectedProvider">
        <mat-select-trigger>{{
          providers[singleSelectionFiltersSelectedProvider].name
        }}</mat-select-trigger>
        <mat-option
          *ngFor="let provider of providers"
          [value]="provider.value"
          class="filter-option"
        >
          <div class="filter-option-title">
            <span>{{ provider.name }}</span>
            <mat-icon class="filter-option-done-icon">done</mat-icon>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
      class="input-form-field language-dropdown"
    >
      <mat-label>Language</mat-label>
      <mat-select [(ngModel)]="singleSelectionFiltersSelectedLanguage">
        <mat-select-trigger>{{
          languages[singleSelectionFiltersSelectedLanguage].name
        }}</mat-select-trigger>
        <mat-option
          *ngFor="let language of languages"
          [value]="language.value"
          class="filter-option"
        >
          <div class="filter-option-title">
            <span>{{ language.name }}</span>
            <mat-icon class="filter-option-done-icon">done</mat-icon>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="filter-by-year-label-container">
      <span class="filter-by-year-label">Filter by year</span>
    </div>
    <div
      class="custom-slider"
      *ngIf="sliderRefreshed"
    >
      <ng5-slider
        [(value)]="startValueYearInterval"
        [(highValue)]="endValueYearInterval"
        [options]="sliderOptions"
        [manualRefresh]="manualRefresh"
        (userChange)="onUserChange($event)"
      >
      </ng5-slider>
    </div>
  </div>
  <div
    mat-dialog-actions
    class="filter-dialog-actions"
  >
    <button
      tupMatButton
      mat-stroked-button
      appearance="small"
      color="primary"
      (click)="resetSingleSelectionFiltersDialog()"
    >
      Reset
    </button>
    <button
      mat-flat-button
      tupMatButton
      appearance="small"
      color="primary"
      (click)="saveSingleSelectionFiltersDialog()"
    >
      Save
    </button>
  </div>
</ng-template>
