import { Pipe, PipeTransform } from '@angular/core';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { DocumentTarget } from 'src/app/models/document.model';

@Pipe({
  name: 'FilterTarget',
})
export class FilterTargetPipe implements PipeTransform {
  transform(list: TupDocument<DocumentTarget>[], searchTarget: any): unknown {
    if (list && searchTarget) {
      return list.filter(
        (target) =>
          target.content?.title
            .toLowerCase()
            .indexOf(searchTarget.toLowerCase()) >= 0
      );
    } else {
      return list;
    }
  }
}
