import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import _ from 'lodash';
import {
  VehicleGroup,
  VehicleGroupVehicle,
} from 'src/app/classes/vehicle-groups';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { deleteLastVehicleWarning } from '../group-warning-dialog/group-warning-dialog.component';

export enum VehicleGroupAction {
  'save',
  'edit',
  'add',
  'move',
}

export class VehicleGroupsDialogModel {
  name: string;
  vehicles: VehicleGroupVehicle[];
  groups: VehicleGroup[];
  group: VehicleGroup;
  constructor(public action: VehicleGroupAction) {}
}

@Component({
  selector: 'app-vehicle-groups-dialog',
  templateUrl: './vehicle-groups-dialog.component.html',
  styleUrls: ['./vehicle-groups-dialog.component.scss'],
})
export class VehicleGroupsDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('groupName') groupName: ElementRef;

  currentGroup: string;
  duplicateGroupName: string = '';
  duplicateError: SnackbarGenericOptionModel = {
    type: 'error',
    message: '',
    icon: StatusSnackbarIcon.Error,
  };
  lastVehicleWarning: SnackbarGenericOptionModel = deleteLastVehicleWarning;

  originalVehicleGroup: VehicleGroup;
  showWarningMessageOnAddAction: boolean = false;

  private inputSubject = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<VehicleGroupsDialogComponent>,
    private mediaplannerService: MediaPlannerService,
    private messageService: TupUserMessageService,
    @Inject(MAT_DIALOG_DATA) public data: VehicleGroupsDialogModel
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    if (
      this.data.action === VehicleGroupAction.add ||
      this.data.action === VehicleGroupAction.move
    ) {
      this.currentGroup = this.data.groups[0].id;
    }

    if (this.data.action === VehicleGroupAction.move) {
      this.mediaplannerService.plan.vehicleGroups.groups.forEach((group) => {
        if (!this.data.groups.includes(group)) {
          this.originalVehicleGroup = group;
        }
      });
    }

    if (this.data.action === VehicleGroupAction.add) {
      this.showWarningMessageOnAddAction =
        this.checkWarningMessageOnAddAction();
    }

    this.inputSubject.pipe(debounceTime(300)).subscribe((groupName) => {
      if (this.data.action === VehicleGroupAction.edit) {
        this.duplicateGroupName =
          this.mediaplannerService.plan.vehicleGroups.groups.some(
            (group) => group.name.toLowerCase() === groupName.toLowerCase()
          ) &&
          this.data.group.name.toLocaleLowerCase() !== groupName.toLowerCase()
            ? groupName
            : '';
      }

      if (this.data.action === VehicleGroupAction.save) {
        this.duplicateGroupName =
          this.mediaplannerService.plan.vehicleGroups.groups.some(
            (group) => group.name.toLowerCase() === groupName.toLowerCase()
          )
            ? groupName
            : '';
      }

      if (this.duplicateGroupName !== '') {
        this.duplicateError.message = `The name "${this.duplicateGroupName.toLowerCase()}" is already being used by another group. Please choose a different name.`;
      }
    });
  }

  checkWarningMessageOnAddAction() {
    const vehicleGroups = this.mediaplannerService.plan.vehicleGroups.groups;

    const isAnyGroupFullyAdded = vehicleGroups.some((group) => {
      const isFullGroup = group.vehicles.every((groupVehicle) => {
        return this.data.vehicles.some(
          (dataVehicle) => groupVehicle.id === dataVehicle.id
        );
      });

      return isFullGroup;
    });

    return isAnyGroupFullyAdded;
  }

  ngAfterViewInit() {
    this.focusGroupName();
    this.inputSubject.next(this.data.name);
  }

  private focusGroupName() {
    setTimeout(() => {
      this.groupName.nativeElement.focus();
    }, 251);
  }

  onClose(save: boolean) {
    if (save) {
      if (this.data.action === VehicleGroupAction.add) {
        this.data.group = this.data.groups.find(
          (gr) => gr.id === this.currentGroup
        );
        this.data.name = this.data.group.name;
      }

      if (this.data.action === VehicleGroupAction.move) {
        this.data.group = this.data.groups.find(
          (gr) => gr.id === this.currentGroup
        );
        this.data.name = this.data.group.name;
      }
      if (this.data.action === VehicleGroupAction.edit) {
      }
    }

    this.dialogRef.close(save ? this.data : null);
  }

  confirmDeletionDialog(groupName: string): Observable<boolean> {
    const options = { confirmText: 'Delete group', cancelText: 'Cancel' };
    const title = `Delete the group '${groupName}'?`;
    return this.messageService.openDialog('', title, options).afterClosed();
  }

  onRemoveVehicle(removeVeh: VehicleGroupVehicle) {
    if (this.data.vehicles.length === 1) {
      this.confirmDeletionDialog(this.data.name).subscribe(
        (answer: boolean) => {
          if (answer) {
            this.data.vehicles = this.data.vehicles.filter(
              (vehicle) => vehicle.id !== removeVeh.id
            );
            this.onClose(true);
          }
        }
      );
    } else {
      this.data.vehicles = this.data.vehicles.filter(
        (vehicle) => vehicle.id !== removeVeh.id
      );
    }

    if (this.data.action === VehicleGroupAction.add) {
      this.showWarningMessageOnAddAction =
        this.checkWarningMessageOnAddAction();
    }
  }

  getDialogTitle(): string {
    return this.data.action === VehicleGroupAction.save
      ? 'Save as group'
      : this.data.action === VehicleGroupAction.add
      ? 'Add to group'
      : this.data.action === VehicleGroupAction.move
      ? 'Move to group'
      : 'Edit group';
  }

  getSaveButtonText(): string {
    return this.data.action === VehicleGroupAction.save
      ? 'Save group'
      : this.data.action === VehicleGroupAction.add
      ? 'Add to group'
      : this.data.action === VehicleGroupAction.move
      ? 'Move to group'
      : 'Update Group';
  }

  onKeyUp() {
    this.inputSubject.next(this.data.name);
  }
}
