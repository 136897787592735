import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalculationParameter } from '../classes/calc-parameters';
import { ApiService } from './api.service';
import { MediaPlannerService } from './media-planner.service';

@Injectable({
  providedIn: 'root',
})
export class ReachFreqParametersService {
  parameters: CalculationParameter[] = [];
  percentageInserts: string[] = [];

  constructor(private apiService: ApiService) {}

  /**
   * Reach and Frequency parameters fetched to help define how future R&F calls should be formatted
   * Call made early on after starting cmp and cached here.  Not survey specific.
   * @returns An Observable<boolean> to indicate a successfull call or not
   */
  getCalculationParameters(): Observable<boolean> {
    return this.parameters.length
      ? of(true)
      : new Observable((ob) => {
          this.apiService
            .request(
              'GET',
              environment.api.codebook.url,
              environment.api.codebook.endPoint.getCalculationParameters
            )
            .subscribe(
              (data) => {
                if (data?.success) {
                  // build the parameters list
                  this.parameters = data.results.map((calc) => {
                    return {
                      calculationMethod: calc.calculation,
                      name: calc.parameter_name,
                      type: calc.parameter_type,
                    };
                  });

                  // a short cut (temp) list to query inserts treated as percentages
                  this.percentageInserts = this.parameters
                    .filter(
                      (param) =>
                        param.name === 'insertions' &&
                        param.type === 'percentage'
                    )
                    .map((param) => param.calculationMethod);
                }

                ob.next(!!data?.success);
                ob.complete();
              },
              (err) => {
                ob.next(false);
                ob.complete();
              }
            );
        });
  }
}
