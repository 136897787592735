<h1 mat-dialog-title>
  {{ title }}
</h1>

<div
  mat-dialog-content
  class="dialog-content"
>
  <p>{{ message }}</p>

  <h3 style="margin: 0">Selected: {{ selection.selected.length }}</h3>
  <mat-form-field
    appearance="outline"
    tupMatFormField
  >
    <mat-label>Filter</mat-label>
    <button
      mat-icon-button
      matSuffix
      matTooltip="Reset"
      (click)="onReset()"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <input
      type="text"
      [(ngModel)]="filterText"
      matInput
      (keyup)="onApplyFilter($event)"
      placeholder=""
    />
  </mat-form-field>

  <div class="table-container">
    <mat-table
      [dataSource]="dataSource"
      matSort
    >
      <!-- Generic column definition -->
      <ng-container
        *ngFor="let column of columns; let i = index"
        [cdkColumnDef]="column.columnDef"
      >
        <mat-header-cell
          mat-sort-header
          *cdkHeaderCellDef
          >{{ column.header }}
          <mat-checkbox
            *ngIf="column.columnDef == 'select'"
            tupMatCheckbox
            appearance="small"
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </mat-header-cell>

        <!-- Checkbox Column -->
        <div *ngIf="column.type == 'select'">
          <mat-cell
            *matCellDef="let row"
            style="width: 50px"
          >
            <mat-checkbox
              tupMatCheckbox
              appearance="small"
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </div>

        <!-- text Column -->
        <div *ngIf="column.type == 'string'">
          <mat-cell
            [ngClass]="column.css"
            class="no-wrap"
            *cdkCellDef="let row"
            >{{ column.cell(row) }}</mat-cell
          >
        </div>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="toggle(row)"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="dialog-actions"
>
  <button
    mat-stroked-button
    color="primary"
    appearance="medium"
    tupMatButton
    (click)="onDismiss()"
  >
    {{ cancelButtonText }}
  </button>
  <button
    mat-flat-button
    tupMatButton
    cdkFocusRegionstart
    color="primary"
    appearance="medium"
    [disabled]="okDisabled"
    (click)="onConfirm()"
  >
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
