<div class="spot-settings-dialog-container">
  <tup-mat-title-bar title="Settings">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose(false)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>

  <div class="spot-settings-container">
    <div
      class="weeks-setings-container"
      fxLayout="row"
      fxLayoutAlign="start"
      fxLayoutGap="10px"
    >
      <div class="duration-label">
        <p>Duration</p>
        <p>(weeks)</p>
      </div>

      <number-incremental
        [(value)]="duration"
        [min]="1"
        [max]="10"
      ></number-incremental>

      <!-- temp hidden as this is required in phase 2 -->
      <mat-form-field
        *ngIf="false"
        appearance="outline"
        class="breakdown-select"
        tupMatFormField
      >
        <mat-label><span>Week breakdown</span></mat-label>
        <mat-select
          [(ngModel)]="currentWeekBreakdown"
          panelClass="breakdown-select-panel"
        >
          <mat-select-trigger>
            {{ weekBreakdownOptions[currentWeekBreakdown].name }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let option of weekBreakdownOptions"
            [value]="option.value"
          >
            {{ option.name }}
            <mat-icon *ngIf="option.value == currentWeekBreakdown"
              >check</mat-icon
            >
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="allocation-settings-container"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <p class="allocation-label">Allocation</p>
      <div>
        <mat-radio-group
          fxLayout="column"
          fxLayoutAlign="start start"
          [(ngModel)]="allocationSelectedOption"
        >
          <mat-radio-button
            *ngFor="let option of allocationOptions"
            class="radio-option"
            [checked]="allocationSelectedOption === option.value"
            [value]="option.value"
            color="primary"
          >
            <div
              fxFlex
              fxLayoutAlign=" center"
              fxLayoutGap="4px"
            >
              <span>{{ option.name }}</span>
              <mat-icon
                *ngIf="option.tooltip"
                [matTooltip]="option.tooltip"
                class="help-icon"
                >help_outline</mat-icon
              >
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="strategy-target-container">
      <mat-form-field
        appearance="outline"
        class="strategy-select"
        tupMatFormField
      >
        <mat-label><span>Strategy</span></mat-label>
        <mat-select
          [(ngModel)]="selectedStrategy"
          pannelClass="strategy-select-panel"
          [disabled]="allocationSelectedOption === 0"
        >
          <mat-select-trigger>
            {{ strategyLabel }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let column of strategyColumns"
            [value]="column.columnDef"
          >
            {{ column.header }}
            <mat-icon *ngIf="column.columnDef == selectedStrategy"
              >check</mat-icon
            >
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="target-input"
        tupMatFormField
      >
        <mat-label><span>Target</span></mat-label>
        <input
          type="number"
          matInput
          [(ngModel)]="goal"
          [disabled]="allocationSelectedOption === 0"
        />
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="16px"
      class="actions-wrapper"
    >
      <button
        tupMatButton
        mat-flat-button
        class="cancel-button"
        (click)="onClose(false)"
      >
        Cancel
      </button>
      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayouytAlign="end start"
      >
        <button
          tupMatButton
          mat-flat-button
          class="clear-spot-button"
          (click)="onClearSpots()"
        >
          Clear spots
        </button>
        <button
          mat-flat-button
          tupMatButton
          color="primary"
          (click)="onClose(true)"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
