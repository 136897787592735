import { Injectable } from '@angular/core';
import {
  MatDialogRef,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { TrainingHelpDialogComponent } from '../components/dialogs/training-help-dialog/training-help-dialog.component';
//import { SimpleInputDialogComponent, SimpleInputDialogModel } from '../components/dialogs/simple-input-dialog/simple-input-dialog.component';
import { SurveySelectionDialogComponent } from '../components/dialogs/survey-selection-dialog/survey-selection-dialog.component';
import { DocumentSurvey } from '../models/document.model';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { Observable } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import {
  ExportOptionsDialogComponent,
  ExportOptionsDialogModel,
} from '../components/dialogs/export-options-dialog/export-options.component';
import { ChartSettingsDialogComponent } from '../components/dialogs/chart-settings-dialog/chart-settings-dialog.component';
import {
  ChartSettingsMode,
  ChartSettings,
  ChartTargetMode,
  ChartTarget,
  ChartType,
} from '../models/charts.model';
import {
  SelectionDialogComponent,
  SelectionDialogModel,
} from '../components/dialogs/selection-dialog/selection-dialog.component';
import {
  VehicleGroupsDialogComponent,
  VehicleGroupsDialogModel,
} from '../components/dialogs/vehicle-groups-dialog/vehicle-groups-dialog.component';
import { ExportedGoogleFileDialogComponent } from '../components/dialogs/exported-google-file-dialog/exported-google-file-dialog.component';
import {
  MetricColumnsDialogComponent,
  MetricColumnsDialogModel,
} from '../components/dialogs/metric-columns-dialog/metric-columns-dialog.component';
import {
  RenameMediaDialogComponent,
  RenameMediaDialogModel,
} from '../components/dialogs/rename-media-dialog/rename-media-dialog.component';
import {
  AddAddressableDialogComponent,
  AddAddressableDialogModel,
} from '../add-addressable-dialog/add-addressable-dialog.component';
import {
  TargetEditorDialogComponent,
  TargetEditorDialogModel,
} from '../components/dialogs/target-editor-dialog/target-editor-dialog.component';
import {
  FrequencyDistributionGroupingDialogComponent,
  FrequencyDistributionGroupingDialogModel,
} from '../components/dialogs/frequency-distribution-grouping-dialog/frequency-distribution-grouping-dialog.component';
import {
  SurveyInfoDialogComponent,
  SurveyInfoDialogModel,
  SurveyInfoType,
} from '../components/dialogs/survey-info-dialog/survey-info-dialog.component';
import { GroupWarningDialogComponent } from '../components/dialogs/group-warning-dialog/group-warning-dialog.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
  QuestionDialogModelButtonOptions,
  QuestionDialogModelOptions,
} from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import { StatusSnackbarIcon } from '../components/dialogs/snackbar-generic/snackbar-generic.component';
import { ManageAudienceMetricsComponent } from '../components/dialogs/manage-audience-metrics/manage-audience-metrics.component';
import {
  SpotplanQuickViewDialogComponent,
  SpotplanQuickViewModel,
} from '../components/dialogs/spotplan-quick-view-dialog/spotplan-quick-view-dialog.component';
import { SpotplanSchedule } from '../classes/spotplan-schedule';
import { TargetVehicle } from '../classes/vehicle';
import {
  SpotColumnsDialogComponent,
  SpotColumnsDialogModel,
} from '../components/dialogs/spot-columns-dialog/spot-columns-dialog.component';
import {
  SpotSettingsDialogComponent,
  SpotSettingsDialogModel,
} from '../components/dialogs/spot-settings-dialog/spot-settings-dialog.component';
import {
  OptimiseDialogComponent,
  OptimiseDialogModel,
} from '../components/dialogs/optimise-dialog/optimise-dialog.component';
import {
  ImportMixedDataDialogComponent,
  MixedDataDialogModel,
} from '../components/dialogs/import-mixed-data-dialog/import-mixed-data-dialog.component';
import {
  InfoMessageDialogComponent,
  InfoMessageDialogModel,
} from '../components/dialogs/info-message-dialog/info-message-dialog.component';
import { ManualEntryDialogComponent } from '../components/dialogs/import-mixed-data-dialog/manual-entry/manual-entry-dialog/manual-entry-dialog.component';
import { ManualEntryDialogModel } from '../models/multi-survey.models';
import {
  NewCodeBuilderDialogComponent,
  NewCodeBuilderDialogModel,
} from '../components/dialogs/new-code-builder-dialog/new-code-builder-dialog.component';
import { DocumentFullSurvey } from '../models/document.model';

export enum infoDialogTemplate {
  broadcast,
  esgScore,
  importedMedia,
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private messageService: TupUserMessageService
  ) {}

  // wrapper for the message service dialog to ensure the cancel text is added so the message service dialog becomes a confirmation dialog
  question(message: string, title: string, options?: any): Observable<boolean> {
    options = options || {};
    options.cancelText = options.cancelText || 'Cancel';
    return this.messageService
      .openDialog(message, title, options)
      .afterClosed();
  }

  getHelpInfo(): MatDialogRef<TrainingHelpDialogComponent> {
    return this.dialog.open(TrainingHelpDialogComponent);
  }

  surveySelector(
    previouslySelectedSurveys: DocumentFullSurvey[] = [],
    filterSurveysBy: Map<string, boolean>,
    maxSelectedTrendableSurveys: number,
    defaultSurveyPreference: number
  ): MatDialogRef<SurveySelectionDialogComponent> {
    return this.dialog.open(SurveySelectionDialogComponent, {
      data: {
        previouslySelectedSurveys,
        filterSurveysBy,
        maxSelectedTrendableSurveys,
        defaultSurveyPreference,
        dialogService: this,
      },
    });
  }

  openSelectionDialog(
    title: string,
    message: string,
    list: string[],
    selection: string[],
    options?: any
  ): MatDialogRef<SelectionDialogComponent, SelectionDialogModel> {
    const dialogData = new SelectionDialogModel(
      title,
      message,
      list,
      selection
    );
    dialogData.multiSelect = options.multiSelect || false;
    dialogData.minimumSelection = options.minimumSelection || 0;

    options = options || {};
    options = Object.assign(options, {
      data: dialogData,
      closeOnNavigation: true,
      disableClose: true, // prevent close on backdrop click or ESC key
      width: '450px',
    });

    return this.dialog.open(SelectionDialogComponent, options);
  }

  openChartSettings(
    targetMode: ChartTargetMode,
    chartSettingsMode: ChartSettingsMode,
    config: ChartSettings,
    targets: ChartTarget[] = [],
    chartType: ChartType,
    groupName?: string
  ): MatDialogRef<ChartSettingsDialogComponent, ChartSettings | null> {
    return this.dialog.open(ChartSettingsDialogComponent, {
      data: {
        targetMode,
        chartSettingsMode,
        config,
        targets,
        chartType,
        groupName,
      },
    });
  }

  openExportOptions(exportOptions: ExportOptionsDialogModel) {
    const options = {
      data: exportOptions,
      autoFocus: false,
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '455px',
      maxWidth: '455px',
    };
    return this.dialog.open(ExportOptionsDialogComponent, options);
  }

  openVehicleGroupsDialog(vehicleGroupOptions: VehicleGroupsDialogModel) {
    const options = { data: vehicleGroupOptions, autoFocus: false };
    return this.dialog.open(VehicleGroupsDialogComponent, options);
  }

  openAddAddressableDialog(addAddressableOptions: AddAddressableDialogModel) {
    const options = { data: addAddressableOptions, autoFocus: false };
    return this.dialog.open(AddAddressableDialogComponent, options);
  }

  openRenameMediaDialog(renameMediaOptions: RenameMediaDialogModel) {
    const options = { data: renameMediaOptions, autoFocus: false };
    return this.dialog.open(RenameMediaDialogComponent, options);
  }

  openEditColumnsDialog(dialogData: MetricColumnsDialogModel) {
    const options = { data: dialogData, autoFocus: false };
    return this.dialog.open(MetricColumnsDialogComponent, options);
  }

  openEditSpotColumnsDialog(dialogData: SpotColumnsDialogModel) {
    const options = { data: dialogData, autoFocus: false };
    return this.dialog.open(SpotColumnsDialogComponent, options);
  }

  openSettingsSpotDialog(dialogData: SpotSettingsDialogModel) {
    const options = { data: dialogData, autoFocus: false };
    return this.dialog.open(SpotSettingsDialogComponent, options);
  }

  openNewCodeBuilderDialog(dialogData: NewCodeBuilderDialogModel) {
    const options = {
      data: dialogData,
      width: '600px',
      closeOnNavigation: true,
      disableClose: true,
    };

    return this.dialog.open(NewCodeBuilderDialogComponent, options);
  }

  openTargetEditorDialog(dialogData: TargetEditorDialogModel) {
    const options = {
      data: dialogData,
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '70vw',
      maxWidth: '70vw',
      autoFocus: false,
    };

    return this.dialog.open(TargetEditorDialogComponent, options);
  }

  showDeleteGroupWarningDialog(): Observable<any> {
    return this.dialog.open(GroupWarningDialogComponent).afterClosed();
  }

  showInfoMessageDialog(dialogData: InfoMessageDialogModel): Observable<any> {
    const options = {
      data: dialogData,
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '40vw',
      maxWidth: '50vw',
      autoFocus: false,
    };

    return this.dialog.open(InfoMessageDialogComponent, options).afterClosed();
  }

  showMediaAudienceMetrics() {
    const options = {
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '500px',
      maxWidth: '500px',
      autoFocus: false,
    };
    return this.dialog.open(ManageAudienceMetricsComponent, options);
  }

  showSpotplanQuickView(
    spotplanModel: SpotplanQuickViewModel
  ): Observable<SpotplanQuickViewModel> {
    const options = {
      data: spotplanModel,
      disableClose: true,
      minWidth: '30vw',
      maxWidth: '30vw',
    };

    return this.dialog
      .open(SpotplanQuickViewDialogComponent, options)
      .afterClosed();
  }

  showOptimiseDialog(dialogData: OptimiseDialogModel) {
    const options = {
      data: dialogData,
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '500px',
      maxWidth: '500px',
      autoFocus: false,
    };
    return this.dialog.open(OptimiseDialogComponent, options);
  }

  openFrequncyDistributionGroupingDialog(
    dialogData: FrequencyDistributionGroupingDialogModel
  ) {
    const options = {
      data: dialogData,
      autoFocus: false,
      id: 'freq-distribution-dialog',
    };
    return this.dialog.open(
      FrequencyDistributionGroupingDialogComponent,
      options
    );
  }

  showSnackBar(
    message: string,
    action: string,
    duration?: number
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.messageService.showSnackBar(message, action, duration);
  }

  openSurveyInfo(data: SurveyInfoDialogModel) {
    const options = {
      data,
      autoFocus: false,
      width: data.type === SurveyInfoType.info ? '40vw' : '40vw',
    };

    this.dialog.open(SurveyInfoDialogComponent, options).afterClosed();
  }

  exportedGoogleFile(
    webViewLink: string
  ): MatDialogRef<ExportedGoogleFileDialogComponent> {
    const options = {
      width: '430px',
      data: webViewLink,
    };
    return this.dialog.open(ExportedGoogleFileDialogComponent, options);
  }

  reportCampaignPreparationError(message: string) {
    const button: QuestionDialogModelButtonOptions = {
      caption: 'Return to dashboard',
      data: 'ok',
      flat: true,
    };

    const options: QuestionDialogModelOptions = {
      buttons: [button],
      closeButton: button,
      buttonAlignment: 'end',
      snackbar: {
        type: 'error',
        message,
        align: 'center',
        icon: StatusSnackbarIcon.Error,
      },
    };

    this.confirmation('', 'Unable to continue', options);
  }

  getDeleteConfirmationOptions(message: string): QuestionDialogModelOptions {
    const options: QuestionDialogModelOptions = {
      buttons: [
        { caption: 'Cancel', data: 'cancel' },
        { caption: 'Delete', data: 'delete', flat: true },
      ],
      closeButton: { caption: 'Cancel', data: 'cancel' },
      snackbar: {
        type: 'warning',
        message,
        align: 'center',
        icon: StatusSnackbarIcon.Warning,
      },
    };
    return options;
  }

  confirmation(
    message: string | string[],
    title: string,
    options?: any
  ): MatDialogRef<ConfirmDialogComponent, QuestionDialogModelButtonOptions> {
    options = options || {};
    options['autoFocus'] = false;
    options['minWidth'] = options['minWidth'] || '35vw';
    options['maxWidth'] = options['maxWidth'] || '40vw';

    if (!options.buttons) {
      options.buttons = [
        { caption: 'Cancel', data: 'cancel', flat: true },
        { caption: 'OK', data: 'ok' },
      ];
    }

    let messageArray = typeof message === 'string' ? [message] : message;
    const dialogData = new ConfirmDialogModel(title, messageArray, options);

    options = Object.assign(options, {
      data: dialogData,
    });

    return this.dialog.open(ConfirmDialogComponent, options);
  }

  openImportMixedDataDialog(data: MixedDataDialogModel) {
    const options = {
      data,
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '600px',
      maxWidth: '690px',
      autoFocus: false,
    };
    return this.dialog.open(ImportMixedDataDialogComponent, options);
  }

  openManualEntryUpdateDialog(data: ManualEntryDialogModel) {
    const options = {
      data,
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '600px',
      maxWidth: '690px',
      autoFocus: false,
    };
    return this.dialog.open(ManualEntryDialogComponent, options);
  }
}
