<div
  fxFlex
  fxLayout="column"
  class="builder-container"
>
  <div
    class="spinner-container-overlay"
    *ngIf="isLoadingSelectedNodes && isLoadingFromDrag"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>

  <!-- full sized layout  -->
  <div
    fxLayout="column"
    fxLayoutAlign="space-evenly stretch"
  >
    <ng-container [ngTemplateOutlet]="builderTab"></ng-container>

    <!--       (selectedIndexChange)="onIndexTabChange($event)" -->
    <mat-tab-group
      animationDuration="0ms"
      mat-stretch-tabs
    >
      <mat-tab label="Show original title">
        <ng-container [ngTemplateOutlet]="titleTab"></ng-container>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <ng-container [ngTemplateOutlet]="tabTwoLabel"></ng-container>
        </ng-template>
        <ng-container [ngTemplateOutlet]="codeTab"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template #builderTab>
    <div
      fxFlex
      fxLayout="column"
      fxGrow="1"
      class="target-list-container"
    >
      <div
        class="target-list"
        *ngFor="let parentTarget of targets; let rowIndex = index"
      >
        <div
          class="target-box"
          *ngFor="let target of parentTarget.targets; let columnIndex = index"
        >
          <mat-chip-list>
            <span
              class="drop-zone-menu-trigger"
              [matMenuTriggerFor]="dropZoneMenu"
              #dropZoneMenuTrigger="matMenuTrigger"
            ></span>
            <mat-chip
              #dropZoneChip
              class="code-chip"
              [class.code-chip--expanded]="isTargetExpanded(target, rowIndex)"
              selectable="false"
              [removable]="isChipRemovable(parentTarget, rowIndex)"
              (removed)="
                onRemoveTarget(parentTarget, target, rowIndex, columnIndex)
              "
              [matTooltip]="formatChipTooltip(target)"
              [dndDropzone]
              dndDragoverClass="drag-over-chip"
              [dndDisableDropIf]="
                !isChipDroppable(parentTarget, target, rowIndex)
              "
              (dndDrop)="onDrop()"
              (dragenter)="
                onChipEnter(target, rowIndex, dropZoneMenuTrigger, dropZoneChip)
              "
              (dragleave)="onChipLeave()"
              [dndDraggable]="target"
              [dndEffectAllowed]="'copy'"
              (dndStart)="
                onDragStart(parentTarget, target, rowIndex, columnIndex)
              "
              (dndEnd)="onDragEnd()"
              (click)="onTargetClick(target, rowIndex)"
              disableRipple
            >
              <mat-icon
                *ngIf="isTargetExpandable(target)"
                fontSet="material-icons-outlined"
                class="code-chip-expand-icon"
                >{{
                  isTargetExpanded(target, rowIndex)
                    ? "expand_less_outline"
                    : "expand_more_outline"
                }}
              </mat-icon>
              {{ formatChipText(target) | truncate : 30 }}
              <mat-icon
                *ngIf="isChipRemovable(parentTarget, rowIndex)"
                class="code-chip-remove-icon"
                matChipRemove
                >cancel</mat-icon
              >
            </mat-chip>
          </mat-chip-list>

          <mat-form-field
            class="operator-field"
            *ngIf="target.countCoding"
          >
            <mat-select
              class="operator-text"
              [(ngModel)]="target.countCoding.operator"
            >
              <mat-option [value]="target.countCoding.operator">
                {{ target.countCoding.operator }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-chip-list *ngIf="target.countCoding">
            <mat-chip
              class="code-chip"
              selectable="false"
              [removable]="true"
              (removed)="onRemoveCountCoding(target)"
              disableRipple
            >
              {{ target.countCoding.value }}
              <mat-icon
                matChipRemove
                class="code-chip-remove-icon"
                >cancel</mat-icon
              >
            </mat-chip>
          </mat-chip-list>

          <mat-form-field class="operator-field">
            <mat-select
              class="operator-text"
              [(ngModel)]="target.operator"
              [disabled]="false"
              (selectionChange)="onTargetOperatorChange()"
            >
              <mat-option
                *ngFor="let targetOperator of targetOperators"
                [value]="targetOperator"
              >
                {{ targetOperator }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="target-box">
          <mat-chip-list class="drop-zone-chip-list">
            <span
              class="drop-zone-menu-trigger"
              [matMenuTriggerFor]="dropZoneMenu"
              #dropZoneMenuTrigger="matMenuTrigger"
            ></span>
            <mat-chip
              #dropZoneChip
              class="drop-zone-chip"
              [style.width]="dropChipWidth"
              selectable="false"
              [style.width]="dropChipWidth"
              [removable]="false"
              [dndDropzone]
              dndDragoverClass="drag-over-chip"
              [dndDisableDropIf]="!isEmptyChipDroppable(parentTarget, rowIndex)"
              (dndDrop)="onDrop()"
              (dragenter)="
                onChipEnter(
                  parentTarget,
                  rowIndex,
                  dropZoneMenuTrigger,
                  dropZoneChip
                )
              "
              (dragleave)="onChipLeave()"
              (click)="onManualCodingClick(parentTarget, rowIndex)"
              disableRipple
            >
              Drop code items here <strong>&nbsp;OR&nbsp;</strong> Click to add
              own code
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #codeTab>
    <div class="code-list-container">
      <span class="codes">
        {{ rootTarget.coding }}
      </span>
    </div>
  </ng-template>

  <ng-template #titleTab>
    <div class="code-list-container">
      <span class="codes">
        {{
          rootTarget.ownTitle !== "" ? rootTarget.ownTitle : rootTarget.title
        }}
      </span>
    </div>
  </ng-template>
</div>

<mat-menu
  #dropZoneMenu="matMenu"
  [hasBackdrop]="false"
  class="target-list-menu"
>
  <button
    mat-menu-item
    *ngFor="let dropZoneMenuItem of dropZoneMenuItems"
    [dndDropzone]
    (dndDrop)="onDrop(dropZoneMenuItem)"
    [dndDragoverClass]="menuItemDragoverClass"
    (dragenter)="onOperatorMenuEnter($event)"
    (dragleave)="onOperatorMenuLeave($event)"
    [dndDisableDropIf]="isLoadingSelectedNodes"
    [disabled]="isLoadingSelectedNodes"
  >
    {{ dropZoneMenuItem }}
  </button>
</mat-menu>

<ng-template #tabTwoLabel>
  Show code
  <mat-icon
    color="primary"
    class="help-icon"
    matTooltip="This is the audience code combination currently applicable for this selected audience item."
    >help</mat-icon
  >
</ng-template>
