import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort, Sort } from '@angular/material/sort';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { TreeTableDatabase } from './tree-table.database';
import {
  CheckedCount,
  ColumnMenuClickEvent,
  FlatTreeNode,
  RefreshData,
  TreeTableColumn,
  TreeTableMenuItem,
  TreeTableEditEvent,
  TreeTableExpandEvent,
  TreeTableNode,
  NodeMenuClickEvent,
} from './tree-table.models';
import { CtrlShiftKeyStates } from 'src/app/directives/ctrl-shift.directive';
import { cloneDeep } from 'lodash';
import { ColumnGroup } from './column-group/table-group.models';
import { EditSelectionHandler } from './classes/edit-selection-handler';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { GAEvents } from '../../models/analytics.model';
import { EMPTY_STRING_CELL } from 'src/app/classes/planning-data';
import { VirtualScrollData } from './virtual-scroll/virtual-scroll-table.directive';
import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { Observable } from 'rxjs';

@Component({
  selector: 'tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
  providers: [TreeTableDatabase],
})
export class TreeTableComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('dndDragImage') dragImage: ElementRef;

  // tree and column data
  @Input() unitsText: string = '';
  @Input() data: TreeTableNode[] = []; // tree data
  @Input() columns: TreeTableColumn[] = []; // table columns
  @Input() columnGroups: ColumnGroup[];
  @Input() disableDragging: boolean = false;
  @Input() textOnMultipleLines: boolean = false;
  @Input() scrollUpdate: VirtualScrollData;
  @Input() virtualScrolling: boolean = false;
  @Input() inlineMenuWithIcons: boolean = false;

  // tree specific - header items
  @Input() allowSelectAll: boolean = false; // Select all checkbox in tree header
  @Input() treeHeaderInlineMenu: TreeTableMenuItem[]; //dropdowm menu to the right of the selectAll checkbox
  @Input() treeTitle: string = ''; // tree title
  @Input() treeWidth: string = '450px'; // tree width.  adds to the flex definition "flex: 0 0 {}"
  @Input() stickyColumn: string = '';

  @Input() readonly: boolean = false;
  @Input() secondLevelCheckbox?: boolean = false;
  @Input() showProcessing: boolean = false;
  @Input() cellEditProcessing: boolean = false;

  // tree selected (checked) nodes
  @Input() selectedNodes: TreeTableNode[] = [];
  @Output() selectedNodesChange: EventEmitter<TreeTableNode[]> =
    new EventEmitter<TreeTableNode[]>();
  @Output() selectedNodesChanged: EventEmitter<TreeTableNode[]> =
    new EventEmitter<TreeTableNode[]>();
  @Output() dragSelectionNodes: EventEmitter<TreeTableNode[]> =
    new EventEmitter<TreeTableNode[]>(); // selected nodes after a drag select is complete

  // table cell edited
  @Output() edited: EventEmitter<TreeTableEditEvent[]> = new EventEmitter<
    TreeTableEditEvent[]
  >();

  // sort column
  @Output() sorted: EventEmitter<Sort> = new EventEmitter<Sort>();

  // tree node expands (callback to add children), double clicks, column clicks
  @Output() nodeDblClick: EventEmitter<TreeTableNode> =
    new EventEmitter<TreeTableNode>();
  @Output() linkClick: EventEmitter<TreeTableNode> =
    new EventEmitter<TreeTableNode>();
  @Output() expand: EventEmitter<TreeTableExpandEvent> =
    new EventEmitter<TreeTableExpandEvent>();
  @Output() nodeChanged: EventEmitter<TreeTableNode> =
    new EventEmitter<TreeTableNode>();

  // menu click event callbacks
  @Output() columnMenuClick: EventEmitter<ColumnMenuClickEvent> =
    new EventEmitter<ColumnMenuClickEvent>();
  @Output() treeNodeMenuClick: EventEmitter<NodeMenuClickEvent> =
    new EventEmitter<NodeMenuClickEvent>();
  @Output() treeInlineMenuClick: EventEmitter<NodeMenuClickEvent> =
    new EventEmitter<NodeMenuClickEvent>();
  @Output() treeHeaderMenuClick: EventEmitter<NodeMenuClickEvent> =
    new EventEmitter<NodeMenuClickEvent>();
  @Output() treeHeaderMainMenuClick: EventEmitter<void> =
    new EventEmitter<void>();

  @Input() set filter(value: string) {
    this.database.filter(value);
  }

  refresh: EventEmitter<RefreshData> = new EventEmitter<RefreshData>();

  // dragging and selecting values
  isDragging: boolean = false;
  draggedItems: string[] = [];

  // keypress states
  keyStates: CtrlShiftKeyStates = {
    ctrlPressed: false,
    shiftPressed: false,
  };
  lastClicked: FlatTreeNode;

  displayedColumns: string[];
  allowResize: boolean = false;
  disableSelection: boolean = true;
  editSelectionHandler: EditSelectionHandler;

  DEFAULT_COLUMN_WIDTH: number = 150;
  emptyStringCell = EMPTY_STRING_CELL;

  constructor(
    public database: TreeTableDatabase,
    private analyticsService: TupAnalyticsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const columnGroups = changes['columnGroups'];
    if (
      columnGroups &&
      columnGroups.currentValue !== columnGroups.previousValue
    ) {
      if (this.columnGroups) {
        this.buildColumnGroups();
      }
    }

    const columns = changes['columns'];
    if (columns && columns.currentValue !== columns.previousValue) {
      this.database.initialise(this.data);
      if (this.columnGroups) {
        this.buildColumnGroups();
      }
    }

    const editedCell = changes['cellEditProcessing'];
    if (editedCell && editedCell.currentValue !== editedCell.previousValue) {
      this.database.initialise(this.data);
    }
  }

  ngOnInit(): void {
    this.database.initialise(this.data);

    // initialise the edit event interval and selection handlers
    this.editSelectionHandler = new EditSelectionHandler();
    this.initialiseSelectionHandler();

    // any changes to the data will be captured
    this.database.dataChange.subscribe((data: TreeTableNode[]) => {
      this.dataSource.data = data;
      this.displayedColumns = this.columns
        ? this.columns.map((column) => column.columnDef)
        : [];
      this.displayedColumns.unshift('name');

      // re-apply all the expanded states
      this.treeControl.dataNodes.forEach((node) => {
        if (node.treeNode.expanded) this.treeControl.expand(node);
      });
    });

    const dataSourceViewer: CollectionViewer = {
      viewChange: new Observable<ListRange>(),
    };
    this.dataSource.connect(dataSourceViewer).subscribe((data) => {
      this.database.treeNodesView = data;
    });

    // subscribe to refreshing data being sent that requires immediate update
    this.refresh.subscribe((data: RefreshData) => {
      this.lastClicked = null;
      this.internalRefresh(data.newData, data.newColumns);
    });
  }

  ngOnDestroy(): void {
    this.database.dataChange.unsubscribe();
    this.dataSource.disconnect();
    this.refresh.unsubscribe();
  }

  private internalRefresh(
    newData: TreeTableNode[] = null,
    newColumns: TreeTableColumn[] = null
  ) {
    if (newData) this.database.initialise(newData);

    if (newColumns) {
      const cols = cloneDeep(newColumns);

      this.columns = cols;
      if (this.treeTitle === 'Metrics') {
        console.log('FOUND TABLE', this.treeTitle, this.columnGroups);
      }
      if (this.columnGroups) {
        this.buildColumnGroups();
      }
    }
  }

  // build the column groups object for each of the columns that have a group.name defined
  buildColumnGroups() {
    this.columnGroups.forEach((group) => {
      // get columns configured for this group
      const columns = this.columns.filter(
        (column) => column.group?.name === group.name
      );
      const width = columns.reduce(
        (sum, column) => sum + (column.width || this.DEFAULT_COLUMN_WIDTH),
        0
      );

      // build the group object fully for the relevant columns
      columns.forEach((column: TreeTableColumn, index: number) => {
        column.group = {
          name: group.name,
          count: index === 0 ? columns.length : 0,
          headerCss: group.headerCss,
          cellCss: group.cellCss,
          width,
          hidden: !!group.hidden,
        };

        if (index === columns.length - 1) {
          column.last = true;
        }
        column.hidden = !!group.hidden;
      });
    });
  }

  initialiseSelectionHandler() {
    this.editSelectionHandler.emitEvents.subscribe(
      (editEvents: TreeTableEditEvent[]) => {
        editEvents.length ? this.edited.emit(editEvents) : null;
      }
    );
  }

  // called when an edit has been completed (edit has happened, not pristine)
  onEditorSave(column: TreeTableColumn, row: TreeTableNode, value: string) {
    // check if column object has its own editor function
    if (typeof column.editComplete === 'function') {
      column.editComplete(row, value);
    }

    // add to queue
    this.editSelectionHandler.addToQueue({
      columnDef: column.columnDef,
      row,
      value,
      type: 'edit',
    });
  }

  onDragStart(event, data: FlatTreeNode) {
    this.isDragging = true;

    this.draggedItems = this.selectDraggableItems(data.treeNode);
    (event.dataTransfer as any).setDragImage(
      this.dragImage.nativeElement,
      15,
      0
    );
  }

  onDragEnd() {
    this.isDragging = false;
  }

  // data object to include in drag data when dropping (?)
  getDragData(data: FlatTreeNode): TreeTableNode[] {
    return null;
  }

  selectDraggableItems(node: TreeTableNode): string[] {
    if (!node.checked) {
      if (this.selectedNodes.length) {
        this.selectAll(false);
      }

      this.setChecked(node, true);
      this.updateSelected();
    }

    return this.selectedNodes.map((node) => node.name);
  }

  checkboxCount(node: TreeTableNode): CheckedCount {
    let count: CheckedCount = { checkbox: 0, checked: 0 };
    if (node.children) {
      node.children.forEach((child) => {
        const childCount = this.checkboxCount(child);
        count.checkbox += childCount.checkbox;
        count.checked += childCount.checked;
      });
    } else {
      count.checkbox = node.checkbox ? 1 : 0;
      count.checked = node.checkbox && node.checked ? 1 : 0;
    }
    return count;
  }

  checkedIndeterminate(node: TreeTableNode): boolean {
    if (!this.hasChildren(node)) return false;
    const checkboxes = this.checkboxCount(node);

    return checkboxes.checked > 0 && checkboxes.checked !== checkboxes.checkbox;
  }

  // set passed in node and all children recursively
  setChecked(
    node: TreeTableNode,
    checked: boolean,
    isSecondLevelNodeWithCheckbox?: boolean
  ) {
    if (node.checkbox || isSecondLevelNodeWithCheckbox) {
      node.checked = checked;

      // set on flatnode array
      const dataNode = this.treeControl.dataNodes.find(
        (dataNode) => dataNode.treeNode === node
      );
      if (dataNode) dataNode.checked = checked;
    }

    if (node.children) {
      node.children.forEach((child) => this.setChecked(child, checked));
    }
  }

  isChecked(data: FlatTreeNode): boolean {
    const checkboxes = this.checkboxCount(data.treeNode);

    if (checkboxes.checkbox == 0) return data.checked;
    return checkboxes.checkbox - checkboxes.checked === 0;
  }

  onLabelClick(clicked: FlatTreeNode) {
    if (clicked.nodeType === 'link') {
      this.linkClick.emit(clicked.treeNode);
    }
  }

  getMediaVehicleIndexes(
    node1,
    node2
  ): { lowerIndex: number; upperIndex: number } {
    const index1 = this.treeControl.dataNodes.findIndex(
      (node) => node.id === node1.id
    );
    const index2 = this.treeControl.dataNodes.findIndex(
      (node) => node.id === node2.id
    );

    const lowerIndex = Math.min(index1, index2);
    const upperIndex = Math.max(index1, index2);

    return { lowerIndex, upperIndex };
  }

  getUpperAndLowerIndexes(clicked): { lowerIndex: number; upperIndex: number } {
    const getIndexOfNode = (parent, node) =>
      parent.children.findIndex((child) => child.data.key === node.data.key);

    const clickedIndex = getIndexOfNode(clicked.treeNode.parent, clicked);
    const lastClickedIndex = getIndexOfNode(
      this.lastClicked.treeNode.parent,
      this.lastClicked
    );

    const lowerIndex = Math.min(clickedIndex, lastClickedIndex);
    const upperIndex = Math.max(clickedIndex, lastClickedIndex);

    //if the items are from the same family (both leaves or both parents) return indexes
    if (
      (clicked.id === '' && this.lastClicked.id === '') ||
      (clicked.id !== '' && this.lastClicked.id !== '')
    ) {
      return { lowerIndex, upperIndex };
    } else {
      return { lowerIndex: 0, upperIndex: 0 };
    }
  }

  onCheckboxClick(clicked: FlatTreeNode) {
    // toggle just this, leave the rest alone
    let handled = false;
    if (this.keyStates.ctrlPressed && !clicked.disabled) {
      clicked.checked = !clicked.checked;
      clicked.treeNode.checked = clicked.checked;
      this.selectedNodesChanged.emit([clicked.treeNode]);
      handled = true;
      this.updateSelected();
    }

    // toggle all between this and the previous
    if (
      this.keyStates.shiftPressed &&
      this.lastClicked &&
      clicked.treeNode.parent
    ) {
      const checked =
        clicked.checked !== undefined
          ? clicked.checked
          : clicked.expandable === true;

      if (
        clicked.treeNode.rowCss === 'media-row' &&
        this.lastClicked.rowCss === 'media-row'
      ) {
        if (clicked.treeNode.checked === this.lastClicked.checked) {
          const treeNodes: TreeTableNode[] = [];
          const { lowerIndex, upperIndex } = this.getMediaVehicleIndexes(
            this.lastClicked,
            clicked.treeNode
          );

          this.treeControl.dataNodes.forEach((node, nodeIndex) => {
            if (
              nodeIndex < upperIndex &&
              nodeIndex > lowerIndex &&
              !node.expandable
            ) {
              node.checked = clicked.checked;
              node.treeNode.checked = clicked.checked;
              treeNodes.push(node.treeNode);
            }
          });

          this.selectedNodesChanged.emit(treeNodes);
          this.updateSelected();
        }
      } else {
        const clickedIsParentNode = !!clicked.treeNode.children;
        const lastClickedIsParentNode = !!this.lastClicked.treeNode.children;
        const { lowerIndex, upperIndex } =
          this.getUpperAndLowerIndexes(clicked);
        const nodeList: TreeTableNode[] = [];
        clicked.treeNode.parent.children.forEach((child, index) => {
          if (index <= upperIndex && index >= lowerIndex) {
            const node = this.treeControl.dataNodes.find(
              (node) => node?.data?.key === child.data.key
            );

            if (node && !node.disabled) {
              if (clickedIsParentNode && lastClickedIsParentNode) {
                this.expand.emit({
                  node: node.treeNode,
                  insert: (treeNode, children) => {
                    this.insertFromExpandCallback(treeNode, children);
                    this.setChecked(treeNode, checked, true);
                    this.updateSelected();
                  },
                });
                if (!node.treeNode.children) {
                  node.treeNode.expanding = true;
                }
                this.setChecked(node.treeNode, checked, true);
              }

              if (lowerIndex > 0 || upperIndex > 0) {
                node.checked = checked;
                node.treeNode.checked = checked;
              }

              nodeList.push(node.treeNode);
            }
          }
        });

        if (nodeList.length > 0) {
          this.selectedNodesChanged.emit(nodeList);
          handled = true;
        }
      }
    }

    if (!handled) {
      this.selectedNodesChanged.emit([clicked.treeNode]);
    }

    this.lastClicked = clicked;
    this.updateSelected();
  }

  // clicking the label next to the mat-checkbox
  onCheckboxLabelClick(clicked: FlatTreeNode) {
    if (clicked.nodeType === 'link') {
      this.onLabelClick(clicked);
      return;
    }

    // update checkbox value selected
    if (!this.keyStates.ctrlPressed) {
      if (!clicked.disabled) {
        clicked.checked = !clicked.checked;
        clicked.treeNode.checked = clicked.checked;
        this.selectedNodesChanged.emit([clicked.treeNode]);
        this.updateSelected();
      }
    }

    this.onCheckboxClick(clicked);
  }

  isSelectAllChecked(): boolean {
    let count = 0;
    this.treeControl.dataNodes.forEach((node) =>
      node.checkbox && node.checked ? count++ : null
    );
    return count === 0 ? false : count === this.treeControl.dataNodes.length;
  }

  selectAllCheckedIndeterminate(): boolean {
    if (!this.treeControl.dataNodes.length) return false;
    const hasUnchecked = !!this.treeControl.dataNodes.find((d) => !d.checked);
    if (hasUnchecked) return false;

    return true;
  }

  onSecondLevelCheckboxClick(data: FlatTreeNode, event: MouseEvent) {
    event.preventDefault();

    const checkedStatus = !this.isChecked(data);
    if (
      data.treeNode.expandable &&
      (!data.treeNode.children || data.treeNode.children.length == 0)
    ) {
      // only emit if not already done so for this node
      if (!data.expanding) {
        this.expand.emit({
          node: data.treeNode,
          insert: (node, children) => {
            node.checked = checkedStatus;

            const dataNode = this.treeControl.dataNodes.find(
              (dataNode) => dataNode.treeNode === data.treeNode
            );
            if (dataNode) dataNode.checked = checkedStatus;

            this.insertFromExpandCallback(node, children);

            this.setChecked(data.treeNode, checkedStatus, true);
            this.onCheckboxClick(data);
          },
        });
      }

      data.expanding = true;
      data.treeNode.expanding = true;
    } else {
      const dataNode = this.treeControl.dataNodes.find(
        (dataNode) => dataNode.treeNode === data.treeNode
      );
      if (dataNode) dataNode.checked = checkedStatus;

      this.setChecked(data.treeNode, checkedStatus, true);
      this.onCheckboxClick(data);
    }
  }

  onSelectAllCheckboxChange(checked: MatCheckboxChange) {
    const treeNodes: TreeTableNode[] = [];
    this.treeControl.dataNodes.forEach((node) => {
      if (node.checkbox && !node.disabled) {
        node.checked = checked.checked;
        node.treeNode.checked = checked.checked;
        treeNodes.push(node.treeNode);
      }
    });

    this.selectedNodesChanged.emit(treeNodes);
    this.updateSelected();
  }

  // clicking mat-checkbox - will toggle select item without affecting any others
  onCheckboxClicked(clicked: FlatTreeNode, event: MouseEvent) {
    event.preventDefault();
    if (clicked.disabled) return;

    this.setChecked(clicked.treeNode, !this.isChecked(clicked));
    this.onCheckboxClick(clicked);
  }

  onColumnMenuClick(column: TreeTableColumn, item: TreeTableMenuItem) {
    this.columnMenuClick.emit({ column, item });
    this.analyticsService.e(GAEvents.plan_inline_menu, {
      action: `input|${item.label.toLowerCase()}`,
    });
  }

  onTreeNodeMenuClick(row: TreeTableNode, item: TreeTableMenuItem) {
    this.treeNodeMenuClick.emit({ row, item });
  }

  onTreeHeaderMainMenuClick() {
    this.treeHeaderMainMenuClick.emit();
  }

  onTreeHeaderMenuClick(item: TreeTableMenuItem) {
    this.treeHeaderMenuClick.emit({ item });

    // columns dialog tracked separately in metric-columns-dialog.component
    if (item.data !== 'columns|edit') {
      this.analyticsService.e(GAEvents.plan_inline_menu, {
        action: `header|${item.label.toLowerCase()}`,
      });
    }
  }

  onInlineMenuClick(row: TreeTableNode, item: TreeTableMenuItem) {
    this.analyticsService.e(GAEvents.plan_inline_menu, {
      action: `item|${item.label.toLowerCase()}`,
    });
    this.treeInlineMenuClick.emit({ row, item });
  }

  // capture keystates from directive
  onCtrlShift(states: CtrlShiftKeyStates) {
    this.keyStates = states;
  }

  /**
   * recursively expand all nodes in the tree
   */
  expandAll() {
    this.treeControl.dataNodes.forEach((node) => {
      if (!node.treeNode.expanded) this.toggle(node);
    });
    this.treeControl.expandAll();
  }

  expandNode(treeNode: TreeTableNode | TreeTableNode[], value: boolean) {
    const nodes = Array.isArray(treeNode) ? treeNode : [treeNode];
    this.treeControl.dataNodes.forEach((node) => {
      if (
        node.treeNode.expanded !== value &&
        nodes.find((n) => n === node.treeNode)
      )
        this.toggle(node);
    });
  }

  // expand all nodes upto the requested level
  expandToLevel(level: number, onlyPreLoaded: boolean) {
    this.treeControl.dataNodes.forEach((node) => {
      const preLoaded =
        (onlyPreLoaded && node.treeNode.children?.length > 0) || !onlyPreLoaded;
      if (node.level <= level && !node.expanded && preLoaded) this.toggle(node);
    });
  }

  // expand nodes up the tree so every searchTerm is exposed, but not below the search term
  expandToSearchTerm(searchTerm: string, onlyPreLoaded: boolean) {
    searchTerm = searchTerm.toUpperCase();
    this.treeControl.dataNodes.forEach((node) => {
      const preLoaded =
        (onlyPreLoaded && node.treeNode.children?.length > 0) || !onlyPreLoaded;
      if (
        (node.level == 0 && preLoaded) ||
        (node.name.toUpperCase().search(searchTerm) == -1 &&
          node.expandable &&
          preLoaded)
      )
        this.toggle(node);
    });
  }

  // recursively collapse all nodes in the tree
  collapseAll() {
    this.treeControl.collapseAll();
    this.treeControl.dataNodes.forEach(
      (node) => (node.treeNode.expanded = false)
    );
  }

  applyDisabled(ids: string[]) {
    this.database.applyDisabled(ids);
  }

  isSecondLevelNodeWithCheckbox(data: FlatTreeNode) {
    return this.secondLevelCheckbox && data.treeNode.parent && !data.checkbox;
  }

  /**
   * Select all (check box)
   *
   * @param value Value to assign to each checkbox (boolean)
   */
  selectAll(value: boolean) {
    const treeNodes: TreeTableNode[] = [];

    this.treeControl.dataNodes.forEach((flatNode) => {
      flatNode.checkbox ? (flatNode.checked = value) : null;
      flatNode.checked ? (flatNode.checked = value) : null;
      if (flatNode.treeNode.checkbox) {
        flatNode.treeNode.checked = value;
        treeNodes.push(flatNode.treeNode);
      }
    });

    this.selectedNodesChanged.emit(treeNodes);
    this.updateSelected(value);
  }

  // fetch array of selected items and emit to parent
  updateSelected(fullCount: boolean = true) {
    this.selectedNodes = fullCount
      ? this.treeControl.dataNodes
          .filter((node) => node.checkbox && node.checked)
          .map((node) => node.treeNode)
      : [];
    this.selectedNodesChange.emit(this.selectedNodes);
  }

  // callback passed from the expand emitter.
  insertFromExpandCallback = (
    node: TreeTableNode,
    children: TreeTableNode[]
  ) => {
    node.expandable = false; // node now has data so will only expand if it has children
    node.expanding = false;
    node.children = children;
    this.database.initialise(this.data);
  };

  onTreeNodeDoubleClick(data: FlatTreeNode) {
    this.toggle(data);

    // not expandable, not disabled and has no children then emit a normal double click
    if (
      !data.expandable &&
      !data.disabled &&
      (!data.treeNode.children || data.treeNode.children.length == 0)
    )
      this.nodeDblClick.emit(data.treeNode);
  }

  toggle(data: FlatTreeNode) {
    this.treeControl.toggle(data);
    data.treeNode.expanded = this.treeControl.isExpanded(data);

    this.nodeChanged.emit(data.treeNode);

    if (data.treeNode.expanded) {
      if (
        data.treeNode.expandable &&
        (!data.treeNode.children || data.treeNode.children.length == 0)
      ) {
        // only emit if not already done so for this node
        if (!data.expanding) {
          this.expand.emit({
            node: data.treeNode,
            insert: this.insertFromExpandCallback,
          });
        }

        data.expanding = true;
        data.treeNode.expanding = true;
      }
    }
  }

  private _transformer = (node: TreeTableNode, level: number): FlatTreeNode => {
    return {
      expandable:
        (!!node.children && node.children.length > 0) || node.expandable,
      expanding: node.expanding,
      expanded: node.expanded,
      css: node.css,
      rowCss: node.rowCss,
      cellCss: node.cellCss,
      inlineTemplate: node.inlineTemplate,
      cellTemplate: node.cellTemplate,
      inlineMenu: node.inlineMenu,
      menu: node.menu,
      name: node.name,
      nodeType: node.nodeType,
      treeNode: node,
      checkbox: node.checkbox,
      checked: node.checked,
      disabled: node.disabled,
      editable: node.editable,
      tooltip: node.tooltip,
      tooltipCss: node.tooltipCss,
      data: node.data,
      level: level,
      id: node.id,
      _hide: node._hide,
    };
  };

  public get transformer() {
    return this._transformer;
  }
  public set transformer(value) {
    this._transformer = value;
  }

  // sort data within the children of each node of the given column
  onSortColumn(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }

    const column = this.columns.find((col) => col.columnDef === sort.active);
    const data = this.dataSource.data.slice();

    // perform iterative sorts
    this.database.sort(column, sort);
    this.sorted.emit(sort);
  }

  public columnTrackBy(index: number, column: TreeTableColumn) {
    return column.columnDef;
  }

  private compare = (
    a: number | string,
    b: number | string,
    isAsc: boolean
  ) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  };

  treeControl = new FlatTreeControl<FlatTreeNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: FlatTreeNode) => node.expandable;
  hasChildren = (node: TreeTableNode): boolean =>
    !!(node.children && node.children.length);
}
