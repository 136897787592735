export enum GAEvents {
  export_charts = 'export_charts', // export event - type charts
  export_data = 'export_data', // export event - type data
  export_file_pptx = 'export_file_pptx', // event property specifying exported file format (valid for export_charts event)
  export_file_xlsx = 'export_file_xlsx', // event property specifying exported file format (valid for export_charts and export_data event)
  export_file_googleSlides = 'export_file_googleSlides', // event property specifying exported file format (valid for export_charts event)
  export_file_googleSheets = 'export_file_googleSheets', // event property specifying exported file format (valid for export_charts event)
  custom_user_engagement = 'user engagement',
  help = 'Help', // opening help dialog (dialog not yet implemented)
  help_guide = 'Help Guide', // downloading help guide from help dialog
  platform = 'Platform', // pressing logo in side menu and confirming exit Plan app
  color_picker = 'Color picker',
  documents = 'documents', // inline menu for campaigns/templates in landing page
  columns_dialog = 'columns_dialog', // opening columns dialog. 'actions' attribute mentioning from which compare view the dialog was opened
  audience_inline_menu = 'audience_inline_menu', // pressing any inline menu option in audience screen (header menu, audience item menu)
  media_inline_menu = 'media_inline_menu', // pressing any inline menu option in media screen (header menu, media item menu)
  plan_inline_menu = 'plan_inline_menu', // pressing any inline menu option in planning screen (header menu, vehicle menu, inputs menu)
  column_group = 'column_group', // collapsing or expanding column groups in planning screen
  save = 'save', // saving campaign or saving campaign as template
  spots_inline_menu = 'spots_inline_menu', // pressing any inline menu option in spot plan screen (header menu, tabs menu)
}
