import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TreeTableMenuItem } from '../tree-table.models';
import { CellNavigationEvent } from '../classes/edit-selection-handler';

@Component({
  selector: 'cell-edit',
  templateUrl: './cell-edit.component.html',
  styleUrls: ['./cell-edit.component.scss'],
})
export class CellEditComponent implements OnInit, OnChanges {
  @Output() edited: EventEmitter<string> = new EventEmitter<string>();
  @Output() blur: EventEmitter<null> = new EventEmitter<null>();
  @Output() editTitle: EventEmitter<string> = new EventEmitter<string>();

  @Input() value: string = '';
  @Input() cellType: string = 'string';
  @Input() readonly: boolean = false;
  @Input() align: string = 'right';
  @Input() reference: string;
  @Input() menu: TreeTableMenuItem[];
  @Input() VCBTitleEditor: boolean = false;
  @Input() defaultTitle: string = '';
  @Output() itemClick: EventEmitter<TreeTableMenuItem> =
    new EventEmitter<TreeTableMenuItem>();
  @Input() editing: boolean = false;
  @Output() editingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() captureInstance: EventEmitter<CellEditComponent> =
    new EventEmitter<CellEditComponent>();
  @Output() navigation: EventEmitter<CellNavigationEvent> =
    new EventEmitter<CellNavigationEvent>();

  @ViewChild('inputBox') inputBox: ElementRef;

  startEditing: EventEmitter<null> = new EventEmitter<null>();

  formValue: FormControl = new FormControl({
    value: this.value,
    disabled: this.readonly,
  });

  constructor() {}

  focusEditor(value: boolean) {
    this.editing = value;
    this.editingChange.emit(value);

    setTimeout(() => {
      if (value && this.editorsReady()) {
        this.inputBox.nativeElement.select();
      }
    });
  }

  editorsReady(): boolean {
    return !!this.inputBox;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // editing initiated from outside
    const editing = changes['editing'];
    if (editing && editing.previousValue != editing.currentValue) {
      this.focusEditor(editing.currentValue);
    }

    // value change from outside
    const value = changes['value'];
    if (value && value.previousValue != value.currentValue) {
      this.formValue.setValue(value.currentValue);
    }
  }

  ngOnInit(): void {
    this.captureInstance.emit(this);

    this.startEditing.subscribe(() => {
      this.onStartEditing();
    });
  }

  onBlur(value: string) {
    if (!this.VCBTitleEditor) {
      this.focusEditor(false);
    }

    // only emit back if something happened
    this.blur.emit();
    if (!this.formValue.pristine) this.edited.emit(value);
  }

  onNavigation(element, direction: string) {
    element.target.blur();

    if (direction === 'blur') this.onBlur(this.formValue.value);
    else {
      this.navigation.emit({ editor: this, direction });
    }
  }

  onStartEditing() {
    if (!this.readonly) this.focusEditor(true);
  }

  onUndoTitleClick() {
    this.formValue.setValue(this.defaultTitle);
    if (this.formValue.pristine) {
      this.blur.emit();
      this.edited.emit(this.defaultTitle);
    }
  }

  onSubmitTitleClick() {
    this.focusEditor(false);
    this.editTitle.emit(this.formValue.value);
  }
}
