import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
  DocumentFullSurvey,
  DocumentSurvey,
  DocumentTarget,
} from 'src/app/models/document.model';
import { CodebookService, MatchType } from 'src/app/services/codebook.service';
import { EngineService } from 'src/app/services/engine.service';
import {
  MediaPlannerService,
  surveyType,
} from 'src/app/services/media-planner.service';
import { BaseStepComponent } from '../base-step/base-step.component';
import { cloneDeep } from 'lodash';
import {
  NodeMenuClickEvent,
  TreeTableExpandEvent,
  TreeTableMenuItem,
  TreeTableNode,
} from 'src/app/components/tree-table/tree-table.models';
import {
  CodebookStatement,
  CodebookTableComponent,
  CombineRowsOptions,
  PopulationEditEvent,
  PopulationMenuClickEvent,
} from 'src/app/components/codebook-table/codebook-table.component';
import { TreeTableComponent } from 'src/app/components/tree-table/tree-table.component';
import { NavigationLevel, Statement } from 'src/app/models/codebook.models';
import { ConverterService } from 'src/app/services/converter.service';
import { TupLoggerService } from '@telmar-global/tup-logger-angular';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { AudienceGroupsService } from 'src/app/services/audience-groups.service';
import {
  CodebookSelectionService,
  DocumentAudienceGroupItem,
  DropDataContext,
  SaveOwnCodesTargetGroup,
  SaveOwnCodesType,
  Statement as VisualCodeBookStatement,
} from '@telmar-global/tup-audience-groups';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ContextMenuComponent } from 'src/app/components/context-menu/context-menu.component';
import { Target, TargetStatus } from 'src/app/classes/target';
import { defaultIfEmpty } from 'rxjs/operators';
import { ChartSettingsService } from 'src/app/services/chart-settings.service';
import { SelectionOption } from 'src/app/components/simple-selection/simple-selection.component';
import { SearchIn } from '../../services/codebook.service';
import { TargetVehicle } from 'src/app/classes/vehicle';
import {
  CampaignPreparationResponse,
  SurveyMetrics,
} from 'src/app/models/planning.models';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CountCodingModel } from 'src/app/components/dialogs/count-coding-dialog/count-coding-dialog.component';
import { CountCodingDialogService } from 'src/app/services/count-coding-dialog.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PlanningService } from 'src/app/services/planning.service';
import { noSearchResultsMessageHTML } from 'src/app/pages/editor/editor.component';
import uniqid from 'uniqid';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from 'src/app/components/dialogs/snackbar-generic/snackbar-generic.component';
import { QuestionDialogModelOptions } from '../../components/dialogs/confirm-dialog/confirm-dialog.component';
import { FileType } from '../../models/multi-survey.models';
import { ScheduleTotalTag } from '../../classes/schedule-total';
import { NewCodeBuilderDialogModel } from 'src/app/components/dialogs/new-code-builder-dialog/new-code-builder-dialog.component';
import {
  Operator,
  VisualCodingTarget,
} from 'src/app/models/visual-code-builder.models';

const CATEGORY_ALL = 'All categories';

interface OperatorButton {
  label: string;
  action: string;
  toolTip: string;
  labelType: string;
}
const AUTO_BUTTON: OperatorButton = {
  label: 'Auto',
  action: 'auto',
  toolTip: 'Combine selected into one audience',
  labelType: 'string',
};
const JOIN_BUTTON: OperatorButton = {
  label: 'join',
  action: 'booleanLogic',
  toolTip: 'Join selected',
  labelType: 'icon',
};
const SEPARATE_BUTTON: OperatorButton = {
  label: 'keyboard_double_arrow_right',
  action: 'separate',
  toolTip: 'Add selected individually',
  labelType: 'icon',
};
const REMOVE_BUTTON: OperatorButton = {
  label: 'keyboard_double_arrow_left',
  action: 'remove',
  toolTip: 'Remove selected',
  labelType: 'icon',
};

const SEARCH_OPTION_TOGGLE: SelectionOption = {
  id: 'search_toggle',
  selectedLabel: 'Search coding',
  label: 'Search titles ',
  selected: false,
};

const SEARCH_OPTION_OPTIONS: SelectionOption[] = [
  {
    id: MatchType.SearchAnyKeyword,
    label: 'Any keyword',
    selected: true,
  },
  {
    id: MatchType.SearchAllKeyword,
    label: 'All keywords',
  },
  {
    id: MatchType.SearchExactKeyword,
    label: 'Exact phrase',
  },
  {
    id: MatchType.SearchStartingKeyword,
    label: 'Starting with',
  },
];

@Component({
  selector: 'audience-step',
  templateUrl: './audience-step.component.html',
  styleUrls: ['./audience-step.component.scss'],
})
export class AudienceStepComponent
  extends BaseStepComponent
  implements OnInit, OnChanges
{
  @Input() visible: boolean = true;
  @Input() selectedSurveyPreferenceOption: number;
  @Input() exploreReportTargets: Target[];
  @Input() surveyBarSelectedSurveys: DocumentFullSurvey[];

  @ViewChild('codingTree') codingTree: TreeTableComponent;
  @ViewChild('dragMenuTrigger') dragMenuTrigger: ContextMenuComponent;
  @ViewChild('codebookTable') codebookTable: CodebookTableComponent;
  @ViewChild('treeContainer') treeContainer: ElementRef;

  @Output() pulse: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectedSurveyUpdate: EventEmitter<void> = new EventEmitter<void>();

  surveyBarSurvey: SurveyMetrics;
  surveyBarCurrentSurvey: DocumentSurvey;
  showSelectSurveyDialog: boolean = false;
  allowNoDataPanel: boolean = false;
  label: string = 'audience';
  unitsText: string = '';
  _processing: number = 0;
  get processing(): number {
    return this._processing;
  }
  set processing(value: number) {
    this._processing = value;
    this.checkReady();
  }

  get allTargets(): Target[] {
    return this.mediaplannerService.plan.targets;
  }

  // called from the view for survey data
  get currentSurvey(): DocumentFullSurvey {
    return this.mediaplannerService.plan.currentSurvey;
  }

  get primarySurvey(): DocumentFullSurvey {
    return this.mediaplannerService.plan.primarySurvey;
  }

  set primarySurvey(survey: DocumentFullSurvey) {
    const selectedSurveys = cloneDeep(
      this.mediaplannerService.plan.selectedSurveys
    );
    selectedSurveys.forEach(
      (selectedSurvey) =>
        (selectedSurvey.isPrimary = selectedSurvey.code === survey.code)
    );
    this.mediaplannerService.plan.selectedSurveys = selectedSurveys;
  }

  // array of operators to disply between the tree and the target selection table
  operators: OperatorButton[] = [
    JOIN_BUTTON,
    AUTO_BUTTON,
    REMOVE_BUTTON,
    SEPARATE_BUTTON,
  ];

  advancedSearchOptions = {
    titleCodingToggle: SEARCH_OPTION_TOGGLE,
    options: SEARCH_OPTION_OPTIONS,
    searchInToggle: SEARCH_OPTION_TOGGLE.selected
      ? SearchIn.Codes
      : SearchIn.Titles,
    selectedOption: SEARCH_OPTION_OPTIONS.find((o) => o.selected).id as string,
  };

  booleanLogicOptions: string[] = ['and', 'or', 'not', 'count'];

  // categories for tree
  categories: string[] = [];
  currentCategory: string = '';
  importAudienceWarningData: SnackbarGenericOptionModel = {
    type: 'warning',
    message: '',
    icon: StatusSnackbarIcon.Warning,
    align: 'left',
  };

  noSearchResultsMessageHTML: string = noSearchResultsMessageHTML;

  // tree data and tree filter
  fullTreeData: TreeTableNode[] = [];
  treeData: TreeTableNode[] = [];
  treeFilter: string = '';
  preSelectedNodes: TreeTableNode[] = []; // items selected in the tree, not yet pushed to the table
  _allPreSelectedNodes: Set<TreeTableNode> = new Set<TreeTableNode>(); // all selected nodes spanning category or search changes

  // a copy of the planning targets populated through loadData().  Used in saveData() to consolidate
  planningTargets: DocumentTarget[] = [];

  // searching
  treeSearch: string = '';
  searching: boolean = false;
  showNoResultsAfterSearch: boolean = false;

  // coding statements
  targetStatements: CodebookStatement[] = [];

  firstAudiencePulse: boolean = false;
  loadingAudiences: boolean = false;

  // visual code builder
  showVisualEditor: boolean = false; // show it
  visualEditingTarget: VisualCodingTarget; // target object for the visual editor component
  visualEditingDocumentTarget: DocumentTarget; // DocumentTarget representing it's changes
  editingTarget: CodebookStatement;

  surveyHasAddressable: boolean = false;

  // dragmenu shown when dropping codes on the codebook table
  dragMenu: TreeTableMenuItem[] = [
    { label: 'Create as new combined row', data: 'auto' },
    { label: 'Create as new separate rows', data: 'separate' },
  ];

  hasMultiSurveyAudience: boolean = false;

  constructor(
    private mediaplannerService: MediaPlannerService,
    private codebookService: CodebookService,
    private userMessage: TupUserMessageService,
    private loggerService: TupLoggerService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private audienceGroupsService: AudienceGroupsService,
    private chartSettingsService: ChartSettingsService,
    private engineService: EngineService,
    private planningService: PlanningService,
    private visualCodebookService: CodebookSelectionService,
    private countCodingService: CountCodingDialogService
  ) {
    super();
  }

  ngOnInit(): void {
    // respond if the survey was changed (cleanup and initialise audience component req.)
    this.mediaplannerService.surveyChanged.subscribe(() => {
      this.cleanupAfterSurveyChanged();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const visible = changes['visible'];
    if (visible && visible.previousValue != visible.currentValue) {
      if (!visible.currentValue) this.cleanupDOM();
    }

    const exploreReportTargets = changes['exploreReportTargets'];
    if (
      exploreReportTargets &&
      exploreReportTargets.previousValue != exploreReportTargets.currentValue &&
      exploreReportTargets.currentValue.length
    ) {
      const tgts: DocumentTarget[] = [];
      const exploreImportedTargets: CodebookStatement[] = [];
      this.exploreReportTargets.forEach((exploreTarget) => {
        tgts.push(
          ConverterService.buildDocumentTargetFromExploreAudiences(
            exploreTarget,
            this.mediaplannerService.plan.currentSurvey
          )
        );
        const tgt = tgts[tgts.length - 1];
        tgt.population = -1;
        tgt.sample = -1;

        this.targetStatements.push(
          this.getTargetStatement(tgt, this.targetStatements.length)
        );

        exploreImportedTargets.push(
          this.targetStatements[this.targetStatements.length - 1]
        );
      });

      this.processing++;
      this.engineService.getMultiTargetEvaluation(tgts).subscribe(
        (res) => {
          this.processing--;
          if (res.status.success) this.planningTargets = tgts;
          exploreImportedTargets.forEach(
            (t) => (t.error = !res.status.success)
          );

          this.saveData(true).subscribe();
          this.checkPulse();

          this.refresh();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  cleanupDOM() {
    this.fullTreeData = [];
    this.treeData = [];
    this.codingTree
      ? this.codingTree.refresh.emit({ newData: this.treeData })
      : {};
  }

  saveData(passive: boolean = false): Observable<boolean> {
    this.processing++;
    if (!passive) {
      // make sure VCB is marked as closed when moving to the next tab
      this.showVisualEditor = false;
    }
    return new Observable((ob) => {
      const handleComplete = (value: boolean) => {
        passive
          ? this.mediaplannerService.dirty()
          : this.mediaplannerService.saveProgress();
        this.processing--;
        this.chartSettingsService.planningState.targetCountchanged = true;
        this.checkReady();

        ob.next(value);
        ob.complete();
      };

      super.saveData();

      // save data begin
      let copyOfVehicles: TargetVehicle[] = this.mediaplannerService.plan
        .targets.length
        ? cloneDeep(this.mediaplannerService.plan.targets[0].vehicles)
        : null;

      // copy multi survey vehicles if campaign was created with imported multi survey file without choosing plan audience from the file
      let DAUMultiSurvey = null;
      if (this.mediaplannerService.plan.multiSurveys.multiSurveys.length) {
        DAUMultiSurvey =
          this.mediaplannerService.plan.multiSurveys.multiSurveys.find(
            (ms) => ms.fileType === FileType.DAU
          );
        const multiSurveyVehicles =
          this.mediaplannerService.plan.multiSurveys.getAllVehicles();

        if (copyOfVehicles === null) {
          copyOfVehicles = cloneDeep(multiSurveyVehicles);
        } else {
          multiSurveyVehicles.forEach((msVeh) => {
            if (!copyOfVehicles.some((existing) => existing.id === msVeh.id)) {
              copyOfVehicles.push(cloneDeep(msVeh));
            }
          });
        }
      }

      // Firstly DELETE: for each plan.target that isnt in the current this.planningTargets list, delete from the plan
      const deleteCoding: string[] = [];
      this.mediaplannerService.plan.targets.forEach((target) => {
        if (!this.planningTargets.find((t) => t.coding === target.coding))
          deleteCoding.push(target.coding);
      });

      deleteCoding.forEach((coding) => {
        this.mediaplannerService.deleteTarget(coding, passive);
      });

      const reqs = this.planningTargets.map((target) =>
        this.mediaplannerService.addTarget(target, copyOfVehicles)
      );

      if (deleteCoding.length || reqs.length) this.mediaplannerService.dirty();

      // now ADD: This uses observables as it also calculates the audiences for any vehicles already selected
      if (reqs.length) {
        // process any added/edited targets
        forkJoin(reqs).subscribe((processedTargets: Target[]) => {
          // processedTargets[] array has a status flag to indicate already exists/edited.  collect Ids of all the new/edited targets only, so copy and R&F can be done on only new ones
          const editedTargets = processedTargets.filter((tgt) =>
            [TargetStatus.active, TargetStatus.updated].includes(tgt.status)
          );

          const editedTargetIds = editedTargets.map((tgt) => tgt.id);

          // reset 'updated' and 'noChange' editedTargets back to active, ready to continue
          editedTargets.forEach((tgt) => (tgt.status = TargetStatus.active));
          const evaluationReqs = [];

          // for each schedule, copy from a target that has results to all the new targets under that schedule
          this.mediaplannerService.plan.schedules.forEach((schedule) => {
            // when DAU is imported only one audience can exist in plan, and it can be edited by VCB => New audience.
            // Old target and results removed so we must create a schedule total which will trigger an evaluate call for the DAU data
            if (DAUMultiSurvey && editedTargets.length) {
              schedule.addScheduleTotal(
                'total',
                ScheduleTotalTag.total,
                editedTargets[0]
              );
            }

            // find a target that already has a results record (i.e not a newly added one)
            const existingTotal = schedule.getTotal();
            if (existingTotal) {
              // get the target object from the above id.  copy schedules to the new targets
              const fromTarget = this.mediaplannerService.plan.targets.find(
                (t) => t.id === existingTotal.targetId
              );
              const toTargets = this.mediaplannerService.plan.targets.filter(
                (t) => t.id !== fromTarget.id && editedTargetIds.includes(t.id)
              );
              schedule.copyVehicleMetricsFromTarget(fromTarget, toTargets);
              const surveyList =
                this.mediaplannerService.plan.surveyListForMultiSurvey;
              if (editedTargets.length) {
                // invalidate any exising results so results are re-built
                this.mediaplannerService.plan.schedules.forEach((schedule) => {
                  schedule.vehicleResultsDirty();
                });

                evaluationReqs.push(
                  this.engineService.evaluateMediaPlan(
                    this.mediaplannerService.plan.targets,
                    schedule,
                    this.mediaplannerService.plan.vehicleGroups.groups,
                    this.mediaplannerService.plan.columns.includeUniqueReach,
                    null,
                    this.mediaplannerService.plan.effectiveReach,
                    null,
                    this.mediaplannerService.plan.multiSurveys.multiSurveys,
                    undefined,
                    surveyList
                  )
                );
              }
            }
          });

          // perform R&F against all newly added vehicle data for targets
          forkJoin(evaluationReqs)
            .pipe(defaultIfEmpty([]))
            .subscribe(() => {
              handleComplete(true);
            });
        });
        const sortingIds = this.planningTargets.map((val) => val.coding);
        this.mediaplannerService.plan.targets.sort(
          (a, b) => sortingIds.indexOf(a.coding) - sortingIds.indexOf(b.coding)
        );
      } else {
        // no targets to add so just return
        handleComplete(true);
      }
    });
  }

  private checkMultiSurveyAudience() {
    const dauFile =
      this.mediaplannerService.plan.multiSurveys.multiSurveys.find(
        (ms) => ms.fileType === FileType.DAU
      );
    const dauUploaded =
      this.mediaplannerService.plan.multiSurveys.multiSurveys?.length > 0 &&
      dauFile !== undefined;
    const manualRF =
      this.mediaplannerService.plan.targets[0]?.vehicles.find(
        (veh) => veh.isMultiSurvey && veh.multiSurveyConfig.manualInput
      ) !== undefined;
    const customPopulation =
      this.planningTargets.find(
        (planningTarget) => planningTarget.customPopulation
      ) !== undefined;

    // warning dialog when trying to add survey audience or custom population when audiences should be limited to max 1
    this.hasMultiSurveyAudience = dauUploaded || customPopulation || manualRF;

    if (manualRF || customPopulation) {
      this.importAudienceWarningData.message =
        'You can only have a single audience target if you added a manual Reach & Frequency entry or custom population.';
    }

    if (dauUploaded) {
      this.importAudienceWarningData.message = customPopulation
        ? `The population has been set to match the audience size you selected on your file import. <br>
You may want to customize your audience to match this selection: '${
            this.planningTargets[0]?.title
          } - Population: ${this.planningTargets[0]?.customPopulation.toLocaleString()}'`
        : `You can only have a single audience target since you uploaded DAU file '<b>${dauFile.header.planFilename}</b>'`;
    }
  }

  loadData() {
    super.loadData();
    this.mediaplannerService
      .prepareSurveys()
      .subscribe((prepResponse: CampaignPreparationResponse) => {
        if (!prepResponse.success) {
          this.dialogService.reportCampaignPreparationError(
            prepResponse.message
          );
          return;
        }

        // take our own copy of the plan targets
        this.planningTargets = cloneDeep(
          this.mediaplannerService.plan.targets.map(
            (target) => target.documentTarget
          )
        );

        if (
          this.planningTargets.length === 0 &&
          this.showSelectSurveyDialog === false
        ) {
          this.showSelectSurveyDialog = true;
          this.showManageSurveyDialog(
            Number(this.selectedSurveyPreferenceOption)
          );
        }

        this.checkMultiSurveyAudience();

        if (!this.primarySurvey) {
          this.primarySurvey = this.currentSurvey;
        }
        this.refresh();
      });
  }

  cleanupAfterSurveyChanged() {
    this.planningTargets = [];
    this.setPreSelectedNodes([]);
    this.searching = false;
    this.treeSearch = '';

    this.categories = [];
    this.currentCategory = '';

    // tree data and tree filter
    this.fullTreeData = [];
    this.treeData = [];
    this.treeFilter = '';

    // selected targets
    this.planningTargets = [];

    // coding statements
    this.targetStatements = [];
    this.firstAudiencePulse = false;

    // clear here instead of in media-planner.ts clearOnSurveyChange() because that gets triggered on campaign creation
    // and removes dashboard imported file
    this.mediaplannerService.plan.multiSurveys.clearAll();

    this.showVisualEditor = false;
  }

  checkReady() {
    // check all targetStatements have coding. DAU audience doesn't have coding before survey audience is assigned to it
    const ready =
      this.targetStatements.length > 0 &&
      !this.planningTargets.find((statement) => statement.coding === '') &&
      this.processing === 0;
    this.mediaplannerService.campaignStatus.readyForMedia.next(ready);
    const planReady =
      ready && this.mediaplannerService.plan.targets.length > 0
        ? this.mediaplannerService.plan.targets[0].vehicles.length > 0
        : false;

    this.mediaplannerService.campaignStatus.readyForPlanning.next(planReady);

    this.mediaplannerService.campaignStatus.readyForSpot.next(false);
    this.mediaplannerService.campaignStatus.readyToShowSpot.next(
      this.checkBroadcast()
    );
  }

  checkBroadcast() {
    let result = false;
    this.allTargets.forEach((target) => {
      const containsBroadcast = !!target.vehicles.find(
        (veh) => veh.dayparts && veh.dayparts.length
      );
      if (containsBroadcast) {
        result = true;
      }
    });
    return result;
  }

  refresh() {
    this.surveyHasAddressable = this.mediaplannerService.plan.surveyMetaData
      .meta(this.currentSurvey.code)
      .anyAddressable();

    // build target statements for the targets selected table
    this.targetStatements = this.planningTargets.map((target, id) => {
      return {
        id,
        target: target,
        addressableTarget: target.addressableTarget,
        planningTarget: target.planningTarget,
      };
    });

    // check if any targets have not been calcuated. (if save was pressed before complete)
    const incompleteTargets = this.planningTargets.filter(
      (tgt) => tgt.population === -1
    );
    if (incompleteTargets.length)
      this.engineService
        .getMultiTargetEvaluation(incompleteTargets)
        .subscribe();

    // fetch categories and populate first level of the tree
    this.fetchPartialTree(null).subscribe((tree) => {
      this.treeData = tree;
      this.treeSearch = '';

      // load any audience groups using the service and populate the tree correctly
      this.loadAudienceGroups(this.treeData, false).subscribe(() => {
        // build the categories dropdown from the first level of the tree.
        this.categories = this.buildCategories(this.treeData);
        this.categories.unshift(CATEGORY_ALL);
        this.currentCategory = CATEGORY_ALL;

        this.applySelected(this.treeData, this.allPreSelectedNodes);

        this.fullTreeData = cloneDeep(this.treeData); // keep a copy of the complete tree so searching and filtering can be done
        this.codingTree.refresh.emit({ newData: this.treeData });

        this.unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
          this.currentSurvey.code
        ).reportUnitText;
        this.loadingAudiences = false;
      });
    });

    this.surveyBarSurvey = this.mediaplannerService.getSurveyMetrics(
      surveyType.current
    );
    this.surveyBarCurrentSurvey = this.mediaplannerService.plan.currentSurvey;

    this.checkReady();
    this.allowNoDataPanel = true;
  }

  // build a unique list for the categories dropdown
  private buildCategories(tree: TreeTableNode[]): string[] {
    const cats = tree.map((level0) => level0.data.category);
    return [...new Set(cats)];
  }

  private applyCSS(nodes: TreeTableNode[], css: string, andChildren: boolean) {
    nodes.forEach((node) => {
      node.css = css;

      if (node.children && node.children.length && andChildren)
        this.applyCSS(node.children, css, andChildren);
    });
  }

  private applySelected(tree: TreeTableNode[], selected: TreeTableNode[]) {
    const selectedIds = selected.map((s) => s.id as string);

    const applyCheckState = (node: TreeTableNode, ids: string[]) => {
      if (node.checkbox && node.id) {
        node.checked = ids.includes(node.id as string);
      }

      if (node.children && node.children.length) {
        node.children.forEach((child) => {
          applyCheckState(child, ids);
        });
      }
    };

    const applySelectedCheckState = (nodes: TreeTableNode[], ids: string[]) => {
      nodes.forEach((node) => {
        applyCheckState(node, ids);
      });
    };

    applySelectedCheckState(tree, selectedIds);
  }

  // create tree nodes after a search
  private createTreeDataAfterSearch(statement: Statement): TreeTableNode {
    const parent: TreeTableNode = {
      name: statement.description,
      id: statement.coding || statement.id,
      data: { coding: statement.coding || '', parent: null },
      children: [],
    };

    if (statement.children)
      this.createTreeNodeAfterSearch(parent, statement.children);
    return parent;
  }

  // create tree nodes after a search
  private createTreeNodeAfterSearch(
    parent: TreeTableNode,
    children: Statement[]
  ) {
    parent.children = [];
    children.forEach((child) => {
      parent.children.push({
        name: child.description,
        id: child.coding || child.id,
        expandable: child.expandable,
        data: {
          coding: child.coding || '',
          parent: parent,
          jsonCoding: child.jsonCoding,
          key: child.key,
        },
        checkbox: !!child.coding,
        children: [],
      });

      if (child.children)
        this.createTreeNodeAfterSearch(
          parent.children[parent.children.length - 1],
          child.children
        );
    });
  }

  // force load audience groups and add to top of existing tree
  updateAudienceGroupsInTree() {
    this.loadAudienceGroups(this.treeData, true).subscribe(() => {
      this.loadingAudiences = false;
      const idxOrigTree = this.fullTreeData.findIndex(
        (item) => item.data.ownCodes && item.data.ownCodes === true
      );
      const idxExpdedTree = this.treeData.findIndex(
        (item) => item.data.ownCodes && item.data.ownCodes === true
      );

      // add a copy of the custom groups; Don't clone all expanded children because reset will not collapse the tree anymore
      this.fullTreeData[idxOrigTree] = cloneDeep(this.treeData[idxExpdedTree]);
      this.codingTree.refresh.emit({ newData: this.treeData });
    });
  }

  // load audience groups and add to start of treedata
  loadAudienceGroups(
    treeData: TreeTableNode[],
    forceReload: boolean
  ): Observable<boolean> {
    return new Observable((ob) => {
      treeData = treeData || [];
      this.audienceGroupsService
        .getAudienceGroupsForTree(this.currentSurvey.code, forceReload)
        .subscribe((codes) => {
          if (codes) {
            codes.ownCodes.css = 'owncodes-label'; // consider removing this if no custom styling is needed

            // first level is User, Company, etc
            codes.ownCodes.children.forEach((child) =>
              child.children.forEach((group) => (group.checkbox = true))
            );

            // remove an old codes node
            let index = treeData.findIndex(
              (node) => node.name === codes.ownCodes.name
            );
            if (index !== -1) treeData.splice(index, 1);

            // add new structures
            treeData.unshift(codes.ownCodes);
          }

          ob.next(true);
          ob.complete();
        });
    });
  }

  private showManageSurveyDialog(selectedSurveyPreferenceOption: number) {
    if (selectedSurveyPreferenceOption === 0) {
      this.onSurveySelection();
    }
  }

  // advanced search dropdown options
  onAdvancedSearchOptionChange(opened: boolean) {
    if (!opened) {
      this.advancedSearchOptions.searchInToggle = this.advancedSearchOptions
        .titleCodingToggle.selected
        ? SearchIn.Codes
        : SearchIn.Titles;
      this.advancedSearchOptions.selectedOption =
        this.advancedSearchOptions.options.find((o) => o.selected).id as string;
    }
  }

  // respond to context menu item clicks, specifically owncodes
  onContextMenuItemClick(menuItem: TreeTableMenuItem) {
    // own codes context menu item clicked
    if (menuItem.data === 'owncodes') {
      const group: SaveOwnCodesTargetGroup = {
        title: 'Audiences',
        items: [],
        selectAll: false,
      };

      const { authorizationGroup, code } =
        this.mediaplannerService.plan.primarySurvey;
      // add statements into the first group
      this.targetStatements.forEach((statement) => {
        if (!statement.target.isMultiSurvey) {
          const targetItem: DocumentAudienceGroupItem = {
            title: statement.target.title,
            coding: statement.target.coding,
            options: {
              statement: statement.target.jsonCoding,
              target: {
                id: uniqid(),
                fileVersion: 1,
                title: statement.target.title,
                coding: statement.target.coding,
                operator: Operator.and,
                created: Date.now(),
                targets: [],
              },
            },
          };

          if (
            statement.target.survey.authorizationGroup === authorizationGroup &&
            statement.target.survey.code === code
          ) {
            group.items.push({
              targetItem,
              selected: statement.selected,
            });
          }
        }
      });
      group.selectAll =
        group.items.length &&
        !group.items.find((targetItem) => !targetItem.selected);

      this.audienceGroupsService
        .saveOwnCodes(this.currentSurvey, [group], SaveOwnCodesType.audience)
        .subscribe((res) => {
          this.loadingAudiences = true;
          this.updateAudienceGroupsInTree();
        });
    }
  }

  // context menu in the tree nodes.  Used for renaming owncodes
  onTreeNodeMenuClick(event: NodeMenuClickEvent) {
    const [action, documentId, targetId] = event.item.data.split('_');

    // own codes rename required
    if (action === 'owncoderename') {
      this.audienceGroupsService
        .editOwnCodesTargetTitle(documentId, parseInt(targetId))
        .subscribe(() => {
          this.updateAudienceGroupsInTree();
        });
    }

    if (action === 'owncoderenamegroup') {
      this.audienceGroupsService
        .renameOwnCodeGroup(documentId)
        .subscribe(() => {
          this.updateAudienceGroupsInTree();
        });
    }

    if (action === 'owncodedelete') {
      this.audienceGroupsService
        .deleteOwnCodesWithConfirmation(documentId)
        .subscribe(() => {
          this.updateAudienceGroupsInTree();
        });
    }
  }

  OnTreeExpand(event: TreeTableExpandEvent) {
    // set as expandable to enable populating children on demand.
    if (event.node.expandable) {
      this.fetchPartialTree(event.node).subscribe((branch: TreeTableNode[]) => {
        this.applySelected(branch, this.allPreSelectedNodes);
        event.insert(event.node, branch || []);
      });
    }
  }

  // call to get the requested level, return an observable in the TreeTable[] objects
  private fetchPartialTree(parent: TreeTableNode): Observable<TreeTableNode[]> {
    return new Observable((observable) => {
      this.codebookService
        .getCodebookNavigation(
          this.currentSurvey,
          parent ? parent.data.key : null
        )
        .subscribe((nodes: NavigationLevel[]) => {
          const treeBranch = this.buildPartialTreeData(nodes, parent);
          observable.next(treeBranch);
          observable.complete();
        });
    });
  }

  // convert MediaLevel (codebook) into TreeTableNode for the view, starting at the given TreeNode
  private buildPartialTreeData(
    nodes: NavigationLevel[],
    parentNode: TreeTableNode
  ): TreeTableNode[] {
    const parent: TreeTableNode = parentNode || { name: 'root' };
    parent.children = parent.children || [];

    nodes.forEach((node) => {
      parent.children.push({
        name: node.vehicle ? node.vehicle.title : node.name,
        id: node.id,
        data: {
          key: node.key,
          category: node.category || node.key,
          coding: node.id,
          jsonCoding: node.jsonCoding,
          parent,
        },
        expandable: node.type === 'node',
        checkbox: ['leaf', 'dayparts'].includes(node.type),
      });

      if (node.children && node.children.length) {
        parent.children[parent.children.length - 1].children =
          this.buildPartialTreeData(
            node.children,
            parent.children[parent.children.length - 1]
          );
        parent.children[parent.children.length - 1].expandable = false;
      }
    });
    if (parent.css) {
      this.applyCSS([parent], parent.css, true);
    }
    return parent.children;
  }

  // fetch full list of pre selected nodes including saved nodes
  get allPreSelectedNodes(): TreeTableNode[] {
    this.backupPreSelectedNodes();
    return Array.from(this._allPreSelectedNodes);
  }

  // merge the allSelected list with whatever is curently selected.
  backupPreSelectedNodes() {
    const preSelectedNodeIds = Array.from(this._allPreSelectedNodes).map(
      (allNodes) => allNodes.id
    );

    this.preSelectedNodes.forEach((node) => {
      if (!preSelectedNodeIds.includes(node.id as string)) {
        this._allPreSelectedNodes.add(node);
      }
    });
  }

  // set the node list manually
  setPreSelectedNodes(nodes: TreeTableNode[]) {
    this.preSelectedNodes = nodes;
    this._allPreSelectedNodes.clear();
    nodes.length ? this.backupPreSelectedNodes() : null;
  }

  //using the node.id, remove items from the allSelected list
  removeFromPreSelectedNodes(nodes: TreeTableNode[]) {
    const idsToRemove = nodes.map((n) => n.id as string);

    this.allPreSelectedNodes.forEach((node) => {
      idsToRemove.includes(node.id as string)
        ? this._allPreSelectedNodes.delete(node)
        : null;
    });
  }

  unSelectAll() {
    this.codingTree.selectAll(false);
    this.setPreSelectedNodes([]);
  }

  // dropping on the visual editor - supply the extra target(s) to the existing visualEditingTarget
  onVisualEditingDropNode(event: DropDataContext<Operator>) {
    const selectedNodes: VisualCodeBookStatement[] = this.allPreSelectedNodes
      .filter((node) => node.id)
      .map((node) => {
        return {
          description: node.name,
          path: node.name,
          coding: node.data.coding,
          category: node.parent.data.category,
          children: [],
        };
      });

    this.visualCodebookService.setSelectedNodes(selectedNodes);
    event.handleDropNode(Operator.or, selectedNodes);
    this.unSelectAll();
  }

  // double click on a coding code, create a target from it
  onDoubleClick(node: TreeTableNode) {
    this.setPreSelectedNodes([node]);
    this.onOperatorClick(AUTO_BUTTON);
  }

  // called from tree when nodes are checked or unchecked.
  // Filter the unchecked and remove from the allSelectedNodes array
  onSelectedNodesChanged(nodes: TreeTableNode[]) {
    this.removeFromPreSelectedNodes(
      nodes.filter((node) => node.checkbox && !node.checked)
    );
  }

  // category dropdown change
  onCategoryChange(category: string) {
    this.backupPreSelectedNodes();

    if (category === CATEGORY_ALL) {
      this.treeData = cloneDeep(this.fullTreeData);
    } else {
      //select multi categories
      const categories = this.fullTreeData.filter(
        (cats) => cats.data.category === category
      );
      this.treeData = cloneDeep(categories);
    }
    this.applySelected(this.treeData, this.allPreSelectedNodes);
    this.codingTree.refresh.emit({ newData: this.treeData });

    // expand first level
    if (category != CATEGORY_ALL && this.treeData.length === 1) {
      this.codingTree.expandNode(this.treeData[0], true);
    }

    this.treeSearch = '';
  }

  // search or search reset click
  onSearchClick(searchTerm: string) {
    this.treeSearch = searchTerm;
    this.backupPreSelectedNodes();

    // if search term is empty, show whole tree according to category
    if (!searchTerm) {
      this.showNoResultsAfterSearch = false;
      this.onCategoryChange(this.currentCategory);
    } else {
      this.searching = true;

      // pass an array of categories, or an empty string if searching within All Categories
      const catName: string | string[] =
        this.currentCategory === CATEGORY_ALL ? '' : [this.currentCategory];

      this.codebookService
        .search(
          searchTerm,
          catName,
          this.currentSurvey,
          <MatchType>this.advancedSearchOptions.selectedOption,
          <SearchIn>this.advancedSearchOptions.searchInToggle
        )
        .subscribe((searchResult) => {
          if (searchResult && !searchResult.error) {
            const parent = this.createTreeDataAfterSearch(searchResult.tree);
            this.treeData = [...parent.children];

            this.applySelected(this.treeData, this.allPreSelectedNodes);
            if (this.codingTree) {
              this.codingTree.refresh.emit({ newData: this.treeData });
              this.codingTree.expandToSearchTerm(
                searchTerm,
                searchResult.useTopLevelCategories
              );
              // searchResult.status.hits < 300 ? this.codingTree.expandToSearchTerm( searchTerm, searchResult.useTopLevelCategories ) :
              //                                  this.codingTree.expandToLevel(1, searchResult.useTopLevelCategories);
            }

            // User needs a warning about missing search results
            if (searchResult.useTopLevelCategories) {
              this.snackbarService.showWarningSnackBar(
                `${searchResult.status.hits.toLocaleString()} matches found so not all results could be displayed.  Consider searching within a category`
              );
            }

            if (searchResult.tree.children.length === 0) {
              this.showNoResultsAfterSearch = true;
            } else {
              this.showNoResultsAfterSearch = false;
            }
          }
          if (searchResult?.error) {
            this.snackbarService.showWarningSnackBar(`${searchResult.error}`);
          }

          this.searching = false;
        });
    }
  }

  onCombineRows(combineRowsData: CombineRowsOptions) {
    let operator: Operator;
    switch (combineRowsData.booleanOperator) {
      case 'and':
        operator = Operator.and;
        break;
      case 'or':
        operator = Operator.or;
        break;
      case 'andNot':
        operator = Operator.andNot;
        break;
      case 'orNot':
        operator = Operator.orNot;
        break;
      default:
        break;
    }
    const target = ConverterService.buildCombinedRowsDocumentTarget(
      combineRowsData.selectedRows,
      this.currentSurvey,
      operator
    );

    target.population = -1;
    target.sample = -1;

    //removing the combined rows
    const idsToRemove = combineRowsData.selectedRows.map((row) => row.id);
    const updatedTargetStatements = this.targetStatements.filter(
      (target) => !idsToRemove.includes(target.id)
    );
    this.targetStatements = [...updatedTargetStatements];

    //removing the combined rows for planning Targets
    this.planningTargets = this.planningTargets.filter(
      (target, index) => !idsToRemove.includes(index)
    );

    //reassign correct ids for the reordered targetStatements
    this.targetStatements.forEach((target, index) => {
      target.id = index;
    });

    this.targetStatements.push(
      this.getTargetStatement(target, this.targetStatements.length)
    );

    this.processing++;
    const lastTargetStatement =
      this.targetStatements[this.targetStatements.length - 1];
    this.engineService
      .getMultiTargetEvaluation([lastTargetStatement.target])
      .subscribe(
        (res) => {
          this.processing--;
          lastTargetStatement.error = !res.status.success;
          if (res.status.success) this.planningTargets.push(target);

          this.saveData(true).subscribe();
          this.checkPulse();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  private groupNodesByCategory(nodes: TreeTableNode[]) {
    const groupedArrays = [];

    nodes.forEach((node) => {
      const category = node.parent.data.key;
      const existingArray = groupedArrays.find(
        (arr) => arr[0]?.parent.data.key === category
      );

      if (existingArray) {
        existingArray.push(node);
      } else {
        groupedArrays.push([node]);
      }
    });

    return groupedArrays;
  }

  private buildAutoDocumentTarget(existingTarget: DocumentTarget) {
    const resultedTargets: DocumentTarget[] = [];
    const groupsOfDifferentCategories = this.groupNodesByCategory(
      this.allPreSelectedNodes
    );

    groupsOfDifferentCategories.forEach((group) => {
      const target = ConverterService.buildDocumentTarget(
        group,
        this.currentSurvey,
        true,
        false
      );
      if (group.length > 1) {
        resultedTargets.push(target);
      } else {
        target.targets = [];
        target.jsonCoding = null;
        resultedTargets.push(target);
      }
    });

    const targets = cloneDeep(existingTarget);
    if (this.allPreSelectedNodes.length === 1) {
      targets.targets = [];
    } else {
      targets.targets = resultedTargets;
    }

    return [targets];
  }

  private multiDataAudienceWarningOptions(): QuestionDialogModelOptions {
    return {
      buttons: [
        { caption: 'Cancel', data: 'cancel' },
        { caption: 'Replace', data: 'confirm', flat: true },
      ],
      closeButton: { caption: 'Close', data: 'cancel' },
      snackbar: {
        type: 'warning',
        message: `You can only have a single audience target if your data was imported. Do you want to replace the imported audience with new one selected?`,
        align: 'center',
        icon: StatusSnackbarIcon.Warning,
      },
    };
  }

  private multiSurveyAudienceWarningOptions(): QuestionDialogModelOptions {
    const options: QuestionDialogModelOptions = {
      buttons: [
        { caption: 'Cancel', data: 'cancel' },
        { caption: 'Proceed', data: 'confirm', flat: true },
      ],
      closeButton: { caption: 'Close', data: 'cancel' },
      snackbar: {
        type: 'warning',
        message: `You can only use one survey to build your audience. If you switch surveys the selected audience(s) will be removed, do you want to proceed?`,
        align: 'center',
        icon: StatusSnackbarIcon.Warning,
      },
    };

    return options;
  }

  private audienceReplaceCallback(keepAddressable: boolean = true) {
    if (keepAddressable) {
      // remove all existing targets that are not used as addressable
      this.targetStatements = this.targetStatements.filter(
        (tgt) => tgt.addressableTarget
      );
      this.targetStatements.forEach((tgt) => (tgt.planningTarget = false));

      this.planningTargets = this.planningTargets.filter(
        (tgt) => tgt.addressableTarget
      );
    } else {
      this.targetStatements = [];
      this.planningTargets = [];
    }
  }

  private isSameSurvey(
    survey1: DocumentFullSurvey | DocumentSurvey,
    survey2: DocumentFullSurvey | DocumentSurvey
  ) {
    return (
      survey1.code === survey2.code &&
      survey1.authorizationGroup === survey2.authorizationGroup
    );
  }

  private actionOnSecondarySurvey() {
    return (
      this.primarySurvey &&
      this.targetStatements.length > 0 &&
      !this.isSameSurvey(this.primarySurvey, this.currentSurvey)
    );
  }

  // center action button click
  onOperatorClick(button: OperatorButton) {
    const selectedCodebookStatements = this.targetStatements.filter(
      (target) => target.selected
    );

    if (
      (button.action === 'auto' || button.action === 'separate') &&
      this.actionOnSecondarySurvey()
    ) {
      this.dialogService
        .confirmation(
          '',
          'Are you sure?',
          this.multiSurveyAudienceWarningOptions()
        )
        .afterClosed()
        .subscribe((dialogButton) => {
          if (dialogButton.data === 'confirm') {
            this.audienceReplaceCallback();
            this.processOperation(button.action, []);
            this.unSelectAll();
          }
        });
      return;
    }

    if (!this.showVisualEditor) {
      // trying to add multiple separate audiences in multi data single audience scenario
      if (
        button.action === 'separate' &&
        this.hasMultiSurveyAudience &&
        this.preSelectedNodes.length > 1
      ) {
        this.snackbarService.showWarningSnackBar(
          'You can only have a single audience target if your data was imported.'
        );
        return;
      }
      // trying to add a second audience in multi data single audience scenario
      if (
        this.planningTargets.filter((tgt) => tgt.planningTarget).length === 1 &&
        (button.action === 'auto' ||
          (button.action === 'separate' &&
            this.preSelectedNodes.length === 1)) &&
        this.hasMultiSurveyAudience
      ) {
        this.dialogService
          .confirmation(
            '',
            'Multiple audience targets detected',
            this.multiDataAudienceWarningOptions()
          )
          .afterClosed()
          .subscribe((dialogButton) => {
            if (dialogButton.data === 'confirm') {
              this.audienceReplaceCallback();
              this.processOperation(button.action, []);
              // this.processOperation(button.action, selectedCodebookStatements);
              this.unSelectAll();
            }
          });
        return;
      }
    }

    // If the visual editor is open then add any selected nodes directly to that
    if (this.showVisualEditor) {
      const selectedNodes: VisualCodeBookStatement[] = this.allPreSelectedNodes
        .filter((node) => node.id)
        .map((node) => {
          return {
            description: node.name,
            path: node.name,
            coding: node.data.coding,
            category: node.parent.data.key,
            children: [],
          };
        });

      if (
        selectedNodes.length &&
        (button.action === 'auto' || button.action === 'separate')
      ) {
        this.codebookTable.visualCodeBuilder.addStatements(selectedNodes, true);

        this.unSelectAll();
      }
      this.saveData(true).subscribe();
      return;
    }

    // visual editor was closed, so process adding to the codebook table
    this.processOperation(button.action, selectedCodebookStatements);
    if (button.action !== 'booleanLogic') {
      this.unSelectAll();
    }
  }

  processOperation(
    buttonAction: string,
    selectedCodebookStatements: CodebookStatement[]
  ) {
    switch (buttonAction) {
      // work out the ANDs and ORs based on the section they're added from
      case 'auto':
        // filter out any parents with checkboxes (not actual data to be added)
        this.setPreSelectedNodes(
          this.allPreSelectedNodes.filter((node) => node.id)
        );
        if (this.preSelectedNodes.length === 0) return;

        // Tree Selection (not coredemo set)
        const target = ConverterService.buildDocumentTarget(
          this.allPreSelectedNodes,
          this.currentSurvey,
          true,
          false
        );
        target.population = -1;
        target.sample = -1;

        const resultedTargets = this.buildAutoDocumentTarget(target);
        if (resultedTargets[0].targets.length === 1) {
          target.targets = resultedTargets[0].targets;
        } else {
          target.targets = resultedTargets;
        }
        if (resultedTargets[0].targets.length > 1) {
          target.coding = `(${target.coding})`;
        }

        // anything checked on in the selected targets codebook table
        if (selectedCodebookStatements?.length) {
          this.applyAutoLogicBetweenTargetAndEachSelectedRow(
            target,
            selectedCodebookStatements
          );
          break;
        }

        // add final work to the coding grid
        this.targetStatements.push(
          this.getTargetStatement(target, this.targetStatements.length)
        );

        this.processing++;
        const lastTargetStatement =
          this.targetStatements[this.targetStatements.length - 1];
        this.engineService
          .getMultiTargetEvaluation([lastTargetStatement.target])
          .subscribe(
            (res) => {
              this.processing--;
              lastTargetStatement.error = !res.status.success;
              if (res.status.success) this.planningTargets.push(target);

              this.saveData(true).subscribe();
              this.primarySurvey = this.currentSurvey;
              this.checkMultiSurveyAudience();
              this.checkPulse();
            },
            (err) => {
              console.log(err);
            }
          );
        break;

      // add each node as a separate target
      case 'separate':
        // filter out any parents with checkboxes (not actual data to be added)
        this.setPreSelectedNodes(
          this.allPreSelectedNodes.filter((node) => node.id)
        );
        if (this.preSelectedNodes.length === 0) return;

        const requests = [];
        const tgts: DocumentTarget[] = [];
        const newTargetStatements: CodebookStatement[] = [];

        if (selectedCodebookStatements?.length) {
          //combine preselected tree nodes and selected codebook statements
          const target = ConverterService.buildDocumentTarget(
            this.allPreSelectedNodes,
            this.currentSurvey,
            true,
            false
          );
          target.population = -1;
          target.sample = -1;

          const resultedTargets = this.buildAutoDocumentTarget(target);
          target.targets = resultedTargets;
          if (resultedTargets[0].targets.length > 1) {
            target.coding = `(${target.coding})`;
          }

          this.applyAutoLogicBetweenTargetAndEachSelectedRow(
            target,
            selectedCodebookStatements
          );
          break;
        }

        // get them all into proper target objects
        this.allPreSelectedNodes.forEach((node) => {
          tgts.push(
            ConverterService.buildDocumentTarget(
              [node],
              this.currentSurvey,
              true,
              false
            )
          );
          const tgt = tgts[tgts.length - 1];
          tgt.population = -1;
          tgt.sample = -1;

          // add final work to the coding grid
          this.targetStatements.push(
            this.getTargetStatement(tgt, this.targetStatements.length)
          );
          newTargetStatements.push(
            this.targetStatements[this.targetStatements.length - 1]
          );
        });

        this.processing++;
        this.engineService.getMultiTargetEvaluation(tgts).subscribe((res) => {
          this.processing--;
          if (res.status.success) this.planningTargets.push(...tgts);
          newTargetStatements.forEach((t) => (t.error = !res.status.success));

          this.primarySurvey = this.currentSurvey;
          this.saveData(true).subscribe();
          this.checkMultiSurveyAudience();
          this.checkPulse();
        });

        break;

      case 'remove':
        this.targetStatements = this.targetStatements.filter(
          (target) => !target.selected
        );

        // reduce planningTargets downto targetStatemnts.  Delete those not in the coding list
        const deleteCoding = this.targetStatements.map(
          (target) => target.target.coding
        );
        this.planningTargets = this.planningTargets.filter((planningTarget) => {
          return deleteCoding.includes(planningTarget.coding);
        });

        this.checkMultiSurveyAudience();
        if (this.planningTargets.length === 0) {
          const selectedSurveys = cloneDeep(
            this.mediaplannerService.plan.selectedSurveys
          );
          selectedSurveys.forEach((val) => (val.isPrimary = false));
          this.mediaplannerService.plan.selectedSurveys = selectedSurveys;
        }

        this.saveData(true).subscribe();
        break;

      case 'unselectAll':
      default:
        break;
    }
  }

  /**
   * Combines the target of the preselected nodes with each selected codebook statements applying the auto logic
   * and updates the data in the table
   *
   * @param selectedCodebookStatements Array of selected codebook statements
   * @param treeTarget Document target created from the preselected nodes
   */
  applyAutoLogicBetweenTargetAndEachSelectedRow(
    treeTarget: DocumentTarget,
    selectedCodebookStatements: CodebookStatement[]
  ) {
    const updatedTargets: DocumentTarget[] = selectedCodebookStatements.map(
      (statement) => {
        const { title, coding, targets } = statement.target;
        const statementTargets = targets.map((target, index) => {
          if (index !== targets.length - 1) {
            return target;
          }
          return {
            ...target,
            operator: Operator.and,
          };
        });

        const codebookTargets = treeTarget.targets.length
          ? treeTarget.targets
          : [treeTarget];

        statementTargets.push(...codebookTargets);
        statementTargets[statementTargets.length - 1].operator = Operator.and;
        const updatedTarget: DocumentTarget = {
          title: `${title} ${Operator.and.toUpperCase()} ${treeTarget.title}`,
          coding: `${coding} ${Operator.and} ${treeTarget.coding}`,
          survey: this.currentSurvey,
          targets: statementTargets,
          jsonCoding: null,
          operator: Operator.and,
          population: -1,
          sample: -1,
          addressableTarget: false,
          planningTarget: true,
        };

        this.targetStatements[statement.id] = this.getTargetStatement(
          updatedTarget,
          statement.id
        );
        return updatedTarget;
      }
    );

    this.processing++;
    this.engineService
      .getMultiTargetEvaluation(updatedTargets)
      .subscribe((res) => {
        this.processing--;
        if (res.status?.success) {
          // update planningTargets with newly created targets
          selectedCodebookStatements.forEach((statement, index) => {
            this.planningTargets[statement.id] = updatedTargets[index];
          });
        }

        selectedCodebookStatements.forEach((statement) => {
          this.targetStatements[statement.id].error = !res.status?.success;
        });

        this.saveData(true).subscribe();
        this.checkMultiSurveyAudience();
        this.checkPulse();
      });
  }

  /**
   * Combines the preselected nodes with each selected codebook statement using boolean logic
   *
   * @param selectedCodebookStatements Array of selected codebook statements
   * @param booleanOperator Boolean operator that should be applied for the logic between the targets
   * @returns Array of DocumentTarget containing the combined data
   */
  combineSelectedTreeNodesAndCodebookStatements(
    selectedCodebookStatements: CodebookStatement[],
    booleanOperator: Operator,
    countCoding: CountCodingModel
  ): DocumentTarget[] {
    const updatedTargets: DocumentTarget[] = [];
    const selectedNodesTgt =
      ConverterService.buildDocumentTargetByBooleanOperator(
        this.preSelectedNodes,
        this.currentSurvey,
        booleanOperator,
        true,
        false,
        countCoding
      );

    const resultedTargets = this.buildTargetGroupByOperator(
      selectedNodesTgt,
      booleanOperator,
      countCoding
    );
    selectedNodesTgt.jsonCoding = null;
    selectedNodesTgt.targets = resultedTargets;

    if (booleanOperator === Operator.plus) {
      selectedNodesTgt.coding = `((${selectedNodesTgt.coding}) ${countCoding.operator} ${countCoding.value})`;
      selectedNodesTgt.title = `(${selectedNodesTgt.title}) ${countCoding.operator} ${countCoding.value}`;
    } else {
      selectedNodesTgt.coding = `(${selectedNodesTgt.coding})`;
    }

    selectedCodebookStatements.forEach((statement) => {
      const codebookStatementTargets = statement.target.targets;
      const updatedTarget: DocumentTarget = {
        title: `${statement.target.title} ${statement.target.operator} ${selectedNodesTgt.title}`,
        coding: `${statement.target.coding} ${statement.target.operator} ${selectedNodesTgt.coding}`,
        survey: this.currentSurvey,
        targets: [...codebookStatementTargets, ...selectedNodesTgt.targets],
        jsonCoding: null,
        operator: statement.target.operator,
        addressableTarget: false,
        planningTarget: true,
        population: -1,
        sample: -1,
      };

      codebookStatementTargets[codebookStatementTargets.length - 1].operator =
        statement.target.operator;

      this.targetStatements[statement.id] = this.getTargetStatement(
        updatedTarget,
        statement.id
      );
      updatedTargets.push(updatedTarget);
    });

    return updatedTargets;
  }

  private buildTargetGroupByOperator(
    target: DocumentTarget,
    operator: Operator,
    countCoding: CountCodingModel
  ) {
    if (operator !== Operator.plus) {
      return [
        {
          ...target,
          operator: Operator.and,
          targets: target.targets,
          ownTitle: target.title,
        },
      ];
    }

    const countTarget = {
      title: countCoding.value,
      ownTitle: countCoding.value,
      jsonCoding: null,
      operator: Operator.and,
      coding: countCoding.value,
    };

    const resultedTargets = target.targets;

    const targetsObject =
      resultedTargets.length === 1
        ? { ...resultedTargets[0], operator: countCoding.operator }
        : {
            ...target,
            targets: resultedTargets,
            operator: countCoding.operator,
          };

    return [
      {
        ...target,
        jsonCoding: null,
        operator: Operator.and,
        coding: `((${target.coding}) ${countCoding.operator} ${countCoding.value})`,
        title: `(${target.title}) ${countCoding.operator} ${countCoding.value}`,
        targets: [targetsObject, { ...countTarget }],
      },
    ];
  }

  onBooleanLogicOperatorClick(booleanOperation: string) {
    if (!this.showVisualEditor && this.hasMultiSurveyAudience) {
      this.dialogService
        .confirmation(
          '',
          'Multiple audience targets detected',
          this.multiDataAudienceWarningOptions()
        )
        .afterClosed()
        .subscribe((dialogButton) => {
          if (dialogButton.data === 'confirm') {
            this.audienceReplaceCallback();
            this.processBooleanLogic(booleanOperation);
            this.unSelectAll();
          }
        });
      return;
    }

    if (this.actionOnSecondarySurvey()) {
      this.dialogService
        .confirmation(
          '',
          'Are you sure?',
          this.multiSurveyAudienceWarningOptions()
        )
        .afterClosed()
        .subscribe((dialogButton) => {
          if (dialogButton.data === 'confirm') {
            this.audienceReplaceCallback();
            this.processBooleanLogic(booleanOperation);
            this.unSelectAll();
          }
        });
      return;
    }

    this.processBooleanLogic(booleanOperation);
  }

  processBooleanLogic(booleanOperation: string) {
    // filter out any parents with checkboxes (not actual data to be added)
    this.setPreSelectedNodes(
      this.allPreSelectedNodes.filter((node) => node.id)
    );
    if (this.preSelectedNodes.length === 0) return;

    let operator: Operator;
    switch (booleanOperation) {
      case 'and':
        operator = Operator.and;
        break;
      case 'or':
        operator = Operator.or;
        break;
      case 'not':
        operator = Operator.andNot;
        break;
      case 'count':
        operator = Operator.plus;
        break;
      default:
        break;
    }

    // filter out any parents with checkboxes (not actual data to be added)
    this.preSelectedNodes = this.preSelectedNodes.filter((node) => node.id);
    if (this.preSelectedNodes.length === 0) return;

    const selectedCodebookStatements = this.targetStatements.filter(
      (target) => target.selected
    );

    const selectedOperator =
      operator === Operator.plus
        ? this.countCodingService.openCountCoding()
        : of(null);
    selectedOperator.subscribe((countCoding: CountCodingModel) => {
      if (this.showVisualEditor) {
        const selectedNodes: VisualCodeBookStatement[] =
          this.allPreSelectedNodes
            .filter((node) => node.id)
            .map((node) => {
              return {
                description: node.name,
                path: node.name,
                coding: node.data.coding,
                category: node.parent.data.key,
                children: [],
              };
            });

        if (selectedNodes.length) {
          if (countCoding) {
            this.codebookTable.visualCodeBuilder.addStatementsWithCountCoding(
              selectedNodes,
              countCoding
            );
          } else {
            this.codebookTable.visualCodeBuilder.addStatementsWithBooleanOperator(
              selectedNodes,
              operator
            );
          }

          this.unSelectAll();

          this.saveData(true).subscribe();
          return;
        }
      }

      //if there are selected codebook statements than combine data using the selected operator
      if (selectedCodebookStatements?.length) {
        //combine preselected tree nodes and selected codebook statements and push to tgts
        const tgts: DocumentTarget[] = [];
        const resultedTargets =
          this.combineSelectedTreeNodesAndCodebookStatements(
            selectedCodebookStatements,
            operator,
            countCoding
          );
        tgts.push(...resultedTargets);

        this.processing++;
        this.engineService.getMultiTargetEvaluation(tgts).subscribe((res) => {
          this.processing--;
          if (res.status.success) this.planningTargets.push(...tgts);
          selectedCodebookStatements.forEach(
            (t) => (t.error = !res.status.success)
          );

          this.saveData(true).subscribe();
          this.checkMultiSurveyAudience();
          this.checkPulse();
        });
      } else {
        // Tree Selection (not coredemo set)
        const target = ConverterService.buildDocumentTargetByBooleanOperator(
          this.allPreSelectedNodes,
          this.currentSurvey,
          operator,
          true,
          false,
          countCoding
        );
        target.population = -1;
        target.sample = -1;
        if (operator === Operator.plus) {
          target.operator = Operator.and;
        } else {
          target.operator = operator;
        }

        //create a group
        const resultedTargets = this.buildTargetGroupByOperator(
          target,
          operator,
          countCoding
        );
        target.jsonCoding = null;
        target.targets = resultedTargets;
        target.coding = `(${target.coding})`;

        if (operator === Operator.plus) {
          target.coding = `(${target.coding} ${countCoding.operator} ${countCoding.value})`;
          target.title = `(${target.title}) ${countCoding.operator} ${countCoding.value}`;
        }

        // add final work to the coding grid
        this.targetStatements.push(
          this.getTargetStatement(target, this.targetStatements.length)
        );

        this.processing++;
        const lastTargetStatement =
          this.targetStatements[this.targetStatements.length - 1];
        this.engineService
          .getMultiTargetEvaluation([lastTargetStatement.target])
          .subscribe(
            (res) => {
              this.processing--;
              lastTargetStatement.error = !res.status.success;
              if (res.status.success) this.planningTargets.push(target);

              this.saveData(true).subscribe();
              this.checkMultiSurveyAudience();
              this.checkPulse();
            },
            (err) => {
              console.log(err);
            }
          );
      }
      this.primarySurvey = this.currentSurvey;
      this.unSelectAll();
    });
  }

  // notifiy parent comp to pulse the next tab
  checkPulse() {
    if (this.planningTargets.length && !this.firstAudiencePulse) {
      this.firstAudiencePulse = true;
      this.pulse.emit(1);
    }
  }
  // target change event from coding table
  onTargetsChanged(statements: CodebookStatement[]) {
    this.planningTargets = statements
      .filter((statement) => !statement.error)
      .map((statement) => statement.target);

    this.checkReady();
  }

  onManualCodingClick(target: VisualCodingTarget, isNew: boolean) {
    this.showManualCodingDialog(isNew ? null : target).subscribe(
      (codingModel: NewCodeBuilderDialogModel) => {
        if (codingModel) {
          const editedTarget: VisualCodingTarget = {
            ...target,
            title: codingModel.title,
            ownTitle: codingModel.title,
            shortTitle: codingModel.title,
            coding: codingModel.coding,
            manual: true,
            targets: [],
          };

          this.saveData(true).subscribe();
          this.checkPulse();
          this.snackbarService.showSuccessSnackBar(
            `Code successfully ${isNew ? 'added' : 'updated'}`
          );

          isNew
            ? this.codebookTable.visualCodeBuilder.addManuallyEditedTarget(
                target,
                editedTarget
              )
            : this.codebookTable.visualCodeBuilder.updateManuallyEditedTarget(
                editedTarget
              );
        }
      }
    );
  }

  // called from the view: manual button click
  onManualCoding() {
    this.showManualCodingDialog().subscribe(
      (res: NewCodeBuilderDialogModel) => {
        if (res) {
          const addNewStatementFromManualCoding = () => {
            const target: DocumentTarget = {
              survey: res.survey,
              title: res.title,
              ownTitle: res.title,
              shortTitle: res.title,
              coding: res.coding,
              manual: true,
              jsonCoding: res.jsonCoding,
              addressableTarget: false,
              planningTarget: true,
              isMultiSurvey: false,
              operator: Operator.and,
              population: res.population,
              sample: res.sample,
            };
            target.targets = [cloneDeep(target)];
            this.planningTargets.push(target);

            this.targetStatements.push(
              this.getTargetStatement(target, this.targetStatements.length)
            );

            this.primarySurvey = this.currentSurvey;
            this.saveData(true).subscribe();
            this.checkPulse();
            this.snackbarService.showSuccessSnackBar('Code successfully added');
          };
          const multiDataRestriction =
            this.hasMultiSurveyAudience &&
            this.planningTargets.filter((tgt) => tgt.planningTarget).length ===
              1;
          if (multiDataRestriction) {
            this.dialogService
              .confirmation(
                '',
                'Multiple audience targets detected',
                this.multiDataAudienceWarningOptions()
              )
              .afterClosed()
              .subscribe((dialogButton) => {
                if (dialogButton.data === 'confirm') {
                  this.audienceReplaceCallback();
                  addNewStatementFromManualCoding();
                }
              });
            return;
          }
          if (this.actionOnSecondarySurvey()) {
            this.dialogService
              .confirmation(
                '',
                'Are you sure?',
                this.multiSurveyAudienceWarningOptions()
              )
              .afterClosed()
              .subscribe((dialogButton) => {
                if (dialogButton.data === 'confirm') {
                  this.audienceReplaceCallback();
                  addNewStatementFromManualCoding();
                }
              });
            return;
          }

          addNewStatementFromManualCoding();
        }
      }
    );
  }

  showManualCodingDialog(
    target?: VisualCodingTarget
  ): Observable<NewCodeBuilderDialogModel> {
    return new Observable((ob) => {
      const dialogData: NewCodeBuilderDialogModel = {
        survey: this.mediaplannerService.plan.currentSurvey,
        coding: target ? target.coding : '',
        title: target ? target.title : '',
        confirmText: target ? 'Update' : 'Add',
        dialogtitle: target ? 'Edit own code' : 'Add own code',
        population: null,
        sample: null,
      };

      this.dialogService
        .openNewCodeBuilderDialog(dialogData)
        .afterClosed()
        .subscribe((res: NewCodeBuilderDialogModel) => {
          ob.next(res);
          ob.complete();
        });
    });
  }

  // edit was clicked on the selected codebook table
  onEditTarget(target: CodebookStatement) {
    // set everything else not editing
    this.targetStatements.forEach((tgt) => (tgt.editing = false));

    // build a visual editing object
    this.visualEditingTarget = ConverterService.buildVisualCodebookTarget(
      target.target
    );

    // set this as editing state
    this.editingTarget = target;
    this.editingTarget.editing = true;
    this.showVisualEditor = true;
  }

  // target was removed from the selected codebook table
  onDeleteTarget(target: CodebookStatement) {
    this.targetStatements.forEach((statement) => {
      if (statement.id === target.id) statement.selected = true;
    });

    this.onOperatorClick(REMOVE_BUTTON);
  }

  // the currently visually edited target has changed, convert progress to a DocumentTarget
  onVisualEditorTargetChanged(visualTarget: VisualCodingTarget) {
    this.visualEditingDocumentTarget =
      ConverterService.buildDocumentTargetFromVisualCodebook(
        visualTarget,
        this.currentSurvey,
        this.editingTarget.planningTarget,
        this.editingTarget.addressableTarget
      );
  }

  // save button clicked below the visual codebook editor
  onVisualEditorSaveClick() {
    // target to process?
    if (this.visualEditingDocumentTarget) {
      const docTarget = this.visualEditingDocumentTarget;
      this.editingTarget.target.population = -1;
      this.editingTarget.target.sample = -1;

      if (this.editingTarget.target.customPopulation) {
        docTarget.isMultiSurvey = this.editingTarget.target.isMultiSurvey;
        docTarget.customPopulation = this.editingTarget.target.customPopulation;
        docTarget.title = this.editingTarget.target.ownTitle;
      }

      // 1 calcuate its results
      this.engineService
        .getMultiTargetEvaluation([docTarget])
        .subscribe((res) => {
          docTarget.population = docTarget.isMultiSurvey
            ? docTarget.customPopulation
            : res.populations[0];
          docTarget.sample = res.samples[0];

          // 2 write change to planning targets array (used in saveData() )
          const idx = this.editingTarget.id;
          this.planningTargets[idx] = docTarget;

          // 3 write back to the codebook table
          this.targetStatements[idx].target = docTarget;
          this.checkReady();
        });
    }

    this.onVisualEditorCloseClick();
  }

  //called when the codebook component uses the target editor dialog to edit a target
  onEditDialogSaveClick(statement: CodebookStatement) {
    const docTarget = statement.target;

    // 1 calcuate its results
    this.engineService
      .getMultiTargetEvaluation([docTarget])
      .subscribe((res) => {
        docTarget.population = docTarget.isMultiSurvey
          ? docTarget.customPopulation
          : res.populations[0];
        docTarget.sample = res.samples[0];

        // 2 write change to planning targets array (used in saveData() )
        const idx = statement.id; //this.editingTarget.id;
        this.planningTargets[idx] = docTarget;

        // 3 write back to the codebook table
        this.targetStatements[idx].target = docTarget;
        this.checkReady();
      });
  }

  // close button clicked below the visual codebook editor
  onVisualEditorCloseClick() {
    // finish off and close
    this.visualEditingTarget = null;
    this.editingTarget.editing = false;
    this.showVisualEditor = false;
    this.visualEditingDocumentTarget = null;
  }

  getClosestRowIndex(pointerPosition: { x: number; y: number }): number | null {
    const element = document.elementFromPoint(
      pointerPosition.x,
      pointerPosition.y
    );

    if (element) {
      const rowElement = element.closest('mat-row');
      if (rowElement && rowElement.getAttribute('row-index')) {
        return Number(rowElement.getAttribute('row-index'));
      }
    }
    return null;
  }

  // codebooktable had something dropped on it.  Build further menu or process codes
  onDropCodebookItem(event: DragEvent) {
    // nothing useful was dropped.
    this.setPreSelectedNodes(
      this.allPreSelectedNodes.filter((node) => node.id)
    );
    if (this.preSelectedNodes.length === 0) return;

    const droppedRowIndex = this.getClosestRowIndex({ x: event.x, y: event.y });

    if (droppedRowIndex !== null && droppedRowIndex !== undefined) {
      const targetedCodebookStatement = this.targetStatements[droppedRowIndex];
      const target = ConverterService.buildDocumentTarget(
        this.preSelectedNodes,
        this.currentSurvey,
        true,
        false
      );
      target.population = -1;
      target.sample = -1;

      // when dropping a node on top of an existing target statement, check if they are both from the same survey
      if (
        targetedCodebookStatement.target.survey &&
        !this.isSameSurvey(
          targetedCodebookStatement.target.survey,
          target.survey
        )
      ) {
        this.dialogService
          .confirmation(
            '',
            'Are you sure?',
            this.multiSurveyAudienceWarningOptions()
          )
          .afterClosed()
          .subscribe((dialogButton) => {
            if (dialogButton.data === 'confirm') {
              this.audienceReplaceCallback();
              this.onOperatorClick(AUTO_BUTTON);
            }
          });
        return;
      }
      const resultedTargets = this.buildAutoDocumentTarget(target);
      target.targets = resultedTargets[0].targets;
      if (resultedTargets[0].targets.length > 1) {
        target.coding = `(${target.coding})`;
      }

      this.applyAutoLogicBetweenTargetAndEachSelectedRow(target, [
        targetedCodebookStatement,
      ]);
    } else {
      // one item dropped so no need for a menu
      if (this.preSelectedNodes.length === 1) {
        this.onOperatorClick(AUTO_BUTTON);
      } else {
        // show the 'dragMenu'
        this.dragMenuTrigger.openMenu(event.clientX, event.clientY);
      }
    }
  }

  private getTargetStatement(
    target: DocumentTarget,
    id: number
  ): CodebookStatement {
    return {
      id,
      target,
      addressableTarget: false,
      planningTarget: true,
      rename: false,
      editing: false,
    };
  }

  // clicks resulting from the above openMenu()  from the dragMenu
  onDragMenuClick(menu: TreeTableMenuItem) {
    switch (menu.data) {
      case 'auto':
        this.onOperatorClick(AUTO_BUTTON);
        break;

      case 'separate':
        this.onOperatorClick(SEPARATE_BUTTON);
        break;

      default:
        break;
    }
  }
  onSurveySelection() {
    this.selectedSurveyUpdate.emit();
  }

  onCurrentSurveyChange() {
    this.loadingAudiences = true;
    this.mediaplannerService
      .updateSurveyMetadata(this.currentSurvey)
      .subscribe(() => {
        this.cleanupDOM();
        this.refresh();
        this.setPreSelectedNodes([]);
      });
  }

  onTableItemsOrderChanged(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.targetStatements,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(
      this.planningTargets,
      event.previousIndex,
      event.currentIndex
    );

    //reassign correct ids for the reordered targetStatements
    this.targetStatements.forEach((target, index) => {
      target.id = index;
    });
  }

  resetSearch() {
    this.onSearchClick('');
    this.unSelectAll();
    this.treeContainer.nativeElement.scrollTo(0, 0);
  }

  private editCustomPopulation(targetIndex: number, population: number) {
    const targetStatement = this.targetStatements[targetIndex];
    if (population) {
      targetStatement.target.customPopulation = population;
      targetStatement.target.ownPopulation = targetStatement.target.population;
    } else {
      delete targetStatement.target.customPopulation;
      targetStatement.target.population = targetStatement.target.ownPopulation;
    }

    this.planningTargets[targetIndex] = targetStatement.target;
    this.checkMultiSurveyAudience();
  }

  // reset custom population to original imported population value
  onPopulationMenuClick(data: PopulationMenuClickEvent) {
    const population = this.planningTargets[data.targetIndex].isMultiSurvey
      ? this.planningTargets[data.targetIndex].population
      : 0;
    this.editCustomPopulation(data.targetIndex, population);
  }

  // save custom population
  onPopulationEdited(data: PopulationEditEvent) {
    this.editCustomPopulation(data.targetIndex, Number(data.customPopulation));
  }
}
