<div
  fxFlexLayout="column"
  fxLayoutAlign="space-between center"
  [ngClass]="[data.type]"
  [style]="data.width ? 'width:' + data.width : null"
>
  <mat-icon
    *ngIf="icon?.icon"
    style="font-size: 3em; height: 36px; width: 36px; margin-right: 5px"
    class="{{ icon?.class || '' }}"
    fxFlex="10"
    >{{ icon?.icon }}</mat-icon
  >
  <div fxFlex>
    <p
      class="content-align-{{ data.align }}"
      [innerHTML]="data.message"
    ></p>
  </div>
</div>
