import { Component, Input, OnInit } from '@angular/core';
import { MediaPlannerService } from '../../../../services/media-planner.service';

export enum preservedOptions {
  'percent' = 'percent',
  'value' = 'value',
}
@Component({
  selector: 'import-preferences',
  templateUrl: './import-preferences.component.html',
  styleUrls: ['./import-preferences.component.scss'],
})
export class ImportPreferencesComponent implements OnInit {
  @Input() multiSurveyId: string;

  importOptions = [
    { label: 'Target percentage (eg. GRPs)', value: preservedOptions.percent },
    {
      label: 'Target value (eg. Impressions)',
      value: preservedOptions.value,
    },
  ];
  preservedMetric: string = preservedOptions.percent;

  constructor(private mediaplannerService: MediaPlannerService) {}

  ngOnInit(): void {
    if (this.multiSurveyId) {
      const multiSurveyFile =
        this.mediaplannerService.plan.multiSurveys.multiSurveys.find(
          (multiSurvey) => multiSurvey.id === this.multiSurveyId
        );
      if (multiSurveyFile) {
        this.preservedMetric = multiSurveyFile.keepThousands
          ? preservedOptions.value
          : preservedOptions.percent;
      }
    }
  }
}
