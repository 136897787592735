import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-dots',
  templateUrl: './loading-dots.component.html',
  styleUrls: ['./loading-dots.component.scss'],
})
export class LoadingDotsComponent implements OnInit {
  @Input() whiteDots: boolean;
  @Input() smallDots: boolean;
  dotSize: string;
  constructor() {}

  ngOnInit(): void {
    this.dotSize = this.smallDots ? '1px' : '3px';
  }
}
