import { Injectable } from '@angular/core';
import { TargetVehicle } from '../classes/vehicle';
import {
  TreeTableColumn,
  TreeTableNode,
} from '../components/tree-table/tree-table.models';
import { MediaPlannerService } from './media-planner.service';
import {
  EMPTY_NUMBER_CELL,
  EMPTY_STRING_CELL,
  VehiclePlanningData,
} from '../classes/planning-data';
import {
  Column_BaseCPM,
  Column_BuyingCPM,
  Column_BuyingCPP,
  Column_BuyingGRPS,
  Column_BuyingImpressions,
  Column_CPM,
  Column_CPP,
  Column_Duration,
  Column_DurationDM,
  Column_FreqCapping,
  Column_GRPs,
  Column_Impressions,
  Column_Inserts,
  Column_InsertsDM,
  Column_NumberOfMailItems,
  Column_Reach000,
  Column_ReachPct,
  Column_TotalCost,
  Column_UnitCost,
  allColumns,
} from '../models/planning-veh-columns.models';

interface ESGBands {
  upto: number;
  css: string;
}

// css defined in compare-media.component.scss
const esgBands: ESGBands[] = [
  { upto: 29, css: 'esg-stability-0-29' },
  { upto: 39, css: 'esg-stability-30-39' },
  { upto: 49, css: 'esg-stability-40-49' },
  { upto: 59, css: 'esg-stability-50-59' },
  { upto: 79, css: 'esg-stability-60-79' },
  { upto: 100, css: 'esg-stability-80-100' },
];

@Injectable({
  providedIn: 'root',
})
export class PlanningValueProviderService {
  constructor(public mediaplannerService: MediaPlannerService) {}

  getFormattedCellString(
    data: VehiclePlanningData,
    columns: TreeTableColumn[],
    vehicle: TargetVehicle = null
  ): VehiclePlanningData {
    const stringData: Partial<VehiclePlanningData> = {};
    const columnDefs = Object.keys(data);
    columnDefs.forEach((columnDef) => {
      const column = columns.find((col) => col.columnDef === columnDef);
      if (column) {
        let suffix = '';
        let decimals = column.decimals;

        // column / vehicle specific changes
        if (vehicle) {
          switch (columnDef) {
            case 'inserts':
              if (
                this.mediaplannerService.plan.surveyMetaData.reachFreq.isPercentageInserts(
                  vehicle
                )
              ) {
                suffix = '%';
                decimals = 2;
              }

              if (vehicle.isMediaGroup) decimals = 2;
              break;

            case 'buyingImpressions':
              break;

            case 'esgScore':
              if (vehicle.ESG?.length) data.esgScore = vehicle.ESG[0].value;
              data.esgScore =
                typeof data.esgScore === 'undefined'
                  ? EMPTY_NUMBER_CELL
                  : data.esgScore;
              break;
          }
        }

        stringData[columnDef] =
          data[columnDef] === EMPTY_NUMBER_CELL ||
          data[columnDef] === EMPTY_STRING_CELL ||
          data[columnDef] == null
            ? EMPTY_STRING_CELL
            : this.formatFloat(data[columnDef], decimals) + suffix;
        stringData[columnDef + 'Raw'] = data[columnDef]; // keep raw value for sorting
      } else {
        // Process data that isnt representing a column
        switch (columnDef) {
          case 'esgStability':
            stringData.esgStability = this.getEsgStabilityCss(
              <number>data.esgStability
            );
            break;

          default:
            stringData[columnDef] = `${data[columnDef]}`;
            break;
        }
      }
    });
    return <VehiclePlanningData>stringData;
  }

  getEsgStabilityCss(esgStability: number): string {
    if (typeof esgStability === 'undefined' || esgStability === -1) return '';
    esgStability = Math.min(Math.max(esgStability, 0), 100);

    let i = 0;
    while (esgStability > esgBands[i].upto) {
      i++;
    }
    return esgBands[i].css;
  }

  // set columns/cells editable in the mega table based on vehicle properties
  getVehicleEditable(vehicle: TargetVehicle): {
    [columnDef: string]: boolean;
  } {
    let editable = {};
    if (vehicle.isMultiSurvey) {
      allColumns
        .filter((c) => c.editable)
        .forEach((c) => (editable[c.columnDef] = false));
      return editable;
    }

    // by default, nothing can use buying impressions, buying GRPs or Direct Mail custom fields
    editable = {
      [Column_BuyingImpressions.columnDef]: false,
      [Column_BuyingGRPS.columnDef]: false,
      [Column_FreqCapping.columnDef]: false,
      [Column_InsertsDM.columnDef]: false,
      [Column_DurationDM.columnDef]: false,
      [Column_NumberOfMailItems.columnDef]: false,
      [Column_BuyingCPM.columnDef]: false,
      [Column_BuyingCPP.columnDef]: false,
    };

    // broadcast, cannot edit inserts or duration or unit cost
    if (vehicle.dayparts && vehicle.dayparts.length) {
      editable[Column_Inserts.columnDef] = false;
      editable[Column_Duration.columnDef] = false;
      editable[Column_UnitCost.columnDef] = false;
    }

    // enable,disable columns for addressable specifics
    if (vehicle.addressableConfig) {
      editable[Column_BuyingImpressions.columnDef] = true;
      editable[Column_BuyingGRPS.columnDef] = true;
      editable[Column_FreqCapping.columnDef] = true;
      editable[Column_BuyingCPM.columnDef] = true;
      editable[Column_BuyingCPP.columnDef] = true;
      editable[Column_Impressions.columnDef] = false;
      editable[Column_GRPs.columnDef] = false;
      editable[Column_Duration.columnDef] = false;
      editable[Column_Reach000.columnDef] = false;
      editable[Column_ReachPct.columnDef] = false;
      editable[Column_CPM.columnDef] = false;
      editable[Column_CPP.columnDef] = false;
      editable[Column_BaseCPM.columnDef] = false;
      editable[Column_UnitCost.columnDef] = false;
      editable[Column_TotalCost.columnDef] = false;
      editable[Column_Inserts.columnDef] = false;
    }

    if (
      this.mediaplannerService.plan.surveyMetaData
        .meta(vehicle.survey.code)
        .isDirectMail(vehicle.mediaTypeId)
    ) {
      editable[Column_ReachPct.columnDef] = false;
      editable[Column_Reach000.columnDef] = false;
      editable[Column_GRPs.columnDef] = false;
      editable[Column_Impressions.columnDef] = false;
      editable[Column_Duration.columnDef] = false;
      editable[Column_BuyingImpressions.columnDef] = false;
      editable[Column_BuyingGRPS.columnDef] = false;
      editable[Column_FreqCapping.columnDef] = false;
      editable[Column_CPM.columnDef] = false;
      editable[Column_CPP.columnDef] = false;
      editable[Column_BaseCPM.columnDef] = false;
      editable[Column_UnitCost.columnDef] = false;
      editable[Column_TotalCost.columnDef] = false;
      editable[Column_Inserts.columnDef] = false;

      // editable fields for Direct Mail when addressable target is added
      if (vehicle.addressableConfig) {
        editable[Column_InsertsDM.columnDef] = true;
        editable[Column_DurationDM.columnDef] = true;
        editable[Column_NumberOfMailItems.columnDef] = true;
        editable[Column_BuyingCPM.columnDef] = true;
        editable[Column_BuyingCPP.columnDef] = true;
      }
    }

    return editable;
  }

  // return number as correctly formatted string, help used by getVehicleColumnValue
  private formatFloat(value: number, decimals: number = 0): string {
    return value.toLocaleString(undefined, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    }); // "1,234.57"
  }

  public getTotalColumnValue() {}
}
