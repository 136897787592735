<app-main>
  <!-- top toolbar -->
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="toolbar-container"
  >
    <!-- tab stepper -->
    <tab-stepper
      #tabs
      [activeIndex]="currentTab"
      [currentCompareOption]="currentCompareOption"
      [chartsActiveIndex]="chartsActiveIndex"
      [(viewMode)]="viewMode"
      (viewModeChange)="onViewModeChange($event)"
      (activeIndexChange)="navigateToTab($event)"
      (chartsActiveIndexChange)="onChartsActiveIndexChange($event)"
      data-tracking="tab-stepper"
    >
      <tab-stepper-tab
        viewMode="tables"
        label="Audience"
        [disabled]="!(readyForAudiences | async)"
      ></tab-stepper-tab>
      <tab-stepper-tab
        viewMode="tables"
        label="Media"
        [disabled]="!(readyForMedia | async)"
      ></tab-stepper-tab>
      <tab-stepper-tab
        viewMode="tables"
        label="Plan"
        [disabled]="!(readyForPlanning | async)"
      ></tab-stepper-tab>
      <tab-stepper-tab
        viewMode="tables"
        label="Spots"
        [disabled]="!(readyForSpot | async)"
        [visible]="readyToShowSpot | async"
      ></tab-stepper-tab>

      <tab-stepper-tab
        data-tracking="charts-button"
        viewMode="charts"
        label="Charts"
        [disabled]="currentTab < 2"
      ></tab-stepper-tab>
      <tab-stepper-tab
        viewMode="charts"
        label="Frequency Distribution"
        [disabled]="currentTab < 2"
      ></tab-stepper-tab>
    </tab-stepper>

    <div fxFlex></div>

    <!-- copyright button -->
    <button
      class="copyright-button"
      mat-icon-button
      (click)="onCopyrightClick()"
      onclick="this.blur()"
    >
      <mat-icon>copyright</mat-icon>
    </button>

    <!-- document editor -->
    <document-editor-bar
      fxFlex="600px"
      [title]="documentTitle"
      [inlineEditing]="false"
      (editClicked)="onDocumentTitleEditing()"
    >
    </document-editor-bar>

    <!-- take up the slack. right align things -->
    <div fxFlex></div>

    <!-- toolbar right of the stepper  -->
    <div
      fxFlex="0 0 auto"
      fxLayoutGap="5px"
      fxLayoutAlign="start center"
    >
      <!-- undo button -->
      <!-- <button
       mat-stroked-button
       color="primary"
       tupMatButton
       appearance="tiny"
      >
      <mat-icon class="material-icons-outlined">undo</mat-icon>
      </button> -->

      <!-- import button -->
      <button
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        (click)="onImportMediaClick()"
        [disabled]="currentTab < 1"
        data-tracking="import-button"
        matTooltip="{{ currentTab < 2 ? '' : 'Import' }}"
      >
        <mat-icon class="material-icons-outlined">drive_folder_upload</mat-icon>
      </button>

      <!-- export button -->
      <button
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        [matMenuTriggerFor]="exportMenu"
        [disabled]="currentTab < 2"
        data-tracking="download-button"
        matTooltip="{{ currentTab < 2 ? '' : 'Export' }}"
      >
        <mat-icon class="material-icons-outlined">file_download</mat-icon>
      </button>

      <mat-menu #exportMenu="matMenu">
        <div *ngIf="viewMode === 'tables'">
          <button
            mat-menu-item
            *ngFor="let item of tableExportsList"
            (click)="onExport(item.type, tableExportType)"
            [disabled]="item.disabled"
          >
            {{ item.label }}
          </button>
        </div>
        <div *ngIf="viewMode === 'charts'">
          <button
            mat-menu-item
            *ngFor="let item of chartsExportsList"
            (click)="onExport(item.type, chartsExportType)"
          >
            {{ item.label }}
          </button>
        </div>
      </mat-menu>

      <!-- save button -->
      <button
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        [matMenuTriggerFor]="saveMenu"
        data-tracking="save-menu-btn"
        matTooltip="Save"
      >
        <mat-icon class="material-icons-outlined">cloud_done</mat-icon>
      </button>

      <mat-menu #saveMenu="matMenu">
        <button
          mat-menu-item
          *ngIf="documentId !== undefined"
          (click)="onSaveCampaign(false)"
          data-tracking="save-campaign-option"
        >
          Save
        </button>
        <button
          mat-menu-item
          (click)="onSaveCampaign(true)"
          data-tracking="save-campaign-as-option"
        >
          Save as...
        </button>
        <button
          mat-menu-item
          (click)="onSaveTemplate()"
          data-tracking="save-as-template-option"
        >
          Save as template...
        </button>
      </mat-menu>

      <!-- cloud button -->
      <!-- <button
          mat-stroked-button
          color="primary"
          tupMatButton
          appearance="tiny"
        >
        <mat-icon class="material-icons-outlined">cloud_done</mat-icon>
        </button> -->

      <!-- hide it for the moment (temp solution) until the audience library is re-design -->
      <!-- <tup-mat-rightsidebar-button
        (click)="rightSidebarOpened = !rightSidebarOpened"
      ></tup-mat-rightsidebar-button> -->
    </div>
  </div>

  <!-- items to be added to the right sidebar -->
  <tup-mat-rightsidebar
    [(opened)]="rightSidebarOpened"
    [autoHide]="true"
    mode="over"
    [closeButton]="true"
  >
    <!-- Optimise Menu item -->
    <tup-mat-rightsidebar-menuitem
      menuName="Optimise (coming soon)"
      cssClass="menu-item-without-content"
    >
    </tup-mat-rightsidebar-menuitem>

    <!-- Frequency Menu item -->
    <tup-mat-rightsidebar-menuitem menuName="Frequency">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="10px"
        style="width: 200px"
      >
        <ul>
          <li>Frequency distribution table</li>
        </ul>
      </div>
    </tup-mat-rightsidebar-menuitem>

    <!-- Reports Menu item -->
    <tup-mat-rightsidebar-menuitem
      menuName="Reports (coming soon)"
      cssClass="menu-item-without-content"
    >
    </tup-mat-rightsidebar-menuitem>

    <!-- Frequency Menu item -->
    <tup-mat-rightsidebar-menuitem
      menuName="Settings (coming soon)"
      cssClass="menu-item-without-content"
    >
    </tup-mat-rightsidebar-menuitem>

    <!-- editor html containing all the tabs and step components -->
    <div
      fxLayout="column"
      fxLayoutAlign="start"
      class="editor-container"
    >
      <mat-divider></mat-divider>

      <!-- main tab group -->
      <mat-tab-group
        tab-less-tabs
        #tabGroup
        [selectedIndex]="currentTab"
      >
        <mat-tab>
          <audience-step
            #stepItem
            [visible]="currentTab === 0"
            [exploreReportTargets]="exploreReportTargets"
            (navigation)="onNavigation($event)"
            (pulse)="onShowPulse($event)"
            [selectedSurveyPreferenceOption]="selectedSurveyPreferenceOption"
            (selectedSurveyUpdate)="onSurveySelection()"
            [surveyBarSelectedSurveys]="selectedSurveys"
          >
          </audience-step>
        </mat-tab>

        <mat-tab>
          <media-step
            #stepItem
            [visible]="currentTab === 1"
            (navigation)="onNavigation($event)"
            (pulse)="onShowPulse($event)"
            [surveyBarSelectedSurveys]="selectedSurveys"
          >
          </media-step>
        </mat-tab>

        <mat-tab>
          <planning-step
            #stepItem
            [visible]="currentTab === 2"
            [(viewMode)]="viewMode"
            (navigation)="onNavigation($event)"
            (pulse)="onShowPulse($event)"
            [(chartsActiveIndex)]="chartsActiveIndex"
            (chartsActiveIndexChange)="onChartsActiveIndexChange($event)"
            (compareOptionChange)="onCompareOptionChange($event)"
            (activeScheduleTabChange)="onActiveScheduleTabChange($event)"
            [surveyBarSelectedSurveys]="selectedSurveys"
          >
          </planning-step>
        </mat-tab>

        <mat-tab>
          <spot-step
            #stepItem
            [visible]="currentTab === 3"
            [activeScheduleIndex]="activeScheduleIndex"
            [(viewMode)]="viewMode"
            (navigation)="onNavigation($event)"
            (pulse)="onShowPulse($event)"
          >
          </spot-step>
        </mat-tab>
      </mat-tab-group>
    </div>
  </tup-mat-rightsidebar>
</app-main>
