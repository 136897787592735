import { DecimalPipe } from '@angular/common';
import { TreeTableColumn } from '../components/tree-table/tree-table.models';
import { ColumnGroup } from '../components/tree-table/column-group/table-group.models';

export const Column_Group_AllWeeks: ColumnGroup = {
  name: 'all weeks',
  headerCss: 'column-group-schedule-spotplan-all-weeks',
  columnsDialogPosition: 1,
};

export const Column_Group_Monday: ColumnGroup = {
  name: 'monday',
  headerCss: 'column-group-default',
  columnsDialogPosition: 1,
};

export const Column_Group_Tuesday: ColumnGroup = {
  name: 'tuesday',
  headerCss: 'column-group-default',
  columnsDialogPosition: 2,
};

export const Column_Group_Wednesday: ColumnGroup = {
  name: 'wednesday',
  headerCss: 'column-group-default',
  columnsDialogPosition: 3,
};

export const Column_Group_Thursday: ColumnGroup = {
  name: 'thursday',
  headerCss: 'column-group-default',
  columnsDialogPosition: 4,
};

export const Column_Group_Friday: ColumnGroup = {
  name: 'friday',
  headerCss: 'column-group-default',
  columnsDialogPosition: 5,
};

export const Column_Group_Saturday: ColumnGroup = {
  name: 'saturday',
  headerCss: 'column-group-default',
  columnsDialogPosition: 6,
};

export const Column_Group_Sunday: ColumnGroup = {
  name: 'sunday',
  headerCss: 'column-group-default',
  columnsDialogPosition: 7,
};

// collection for all column groups
export const allSpotColumnGroups: ColumnGroup[] = [
  Column_Group_AllWeeks,
  Column_Group_Monday,
  Column_Group_Tuesday,
  Column_Group_Wednesday,
  Column_Group_Thursday,
  Column_Group_Friday,
  Column_Group_Saturday,
  Column_Group_Sunday,
];

export const Column_Spots: TreeTableColumn = {
  header: 'Spots',
  tooltip: '',
  columnDef: 'inserts',
  columnType: 'number',
  cell: (row) => row.data?.spots,
};

export const Column_Cost: TreeTableColumn = {
  header: 'Cost',
  tooltip: '',
  columnDef: 'cost',
  columnType: 'number',
  editable: true,
  cell: (row) => row.data?.cost,
};

export const Column_GRPs: TreeTableColumn = {
  header: 'GRPs',
  tooltip: '',
  columnDef: 'GRPs',
  columnType: 'number',
  pipe: { token: DecimalPipe as any, args: ['1.1-1'] },
  cell: (row) => row.data?.GRPs,
};

export const Column_Impressions: TreeTableColumn = {
  header: 'Impressions',
  columnDef: 'impressions',
  columnType: 'number',
  showUnits: true,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.impressions,
};

export const Column_Reach000: TreeTableColumn = {
  header: 'Reach',
  style: { width: '100px' },
  columnDef: 'reach000',
  columnType: 'number',
  showUnits: true,
  editable: false,
  pipe: { token: DecimalPipe as any, args: ['1.0-0'] },
  cell: (row) => row.data?.reach000,
};

export const Column_ReachPct: TreeTableColumn = {
  header: 'Reach %',
  style: { width: '100px' },
  columnDef: 'reachPct',
  columnType: 'number',
  decimals: 2,
  editable: false,
  pipe: { token: DecimalPipe as any, args: ['1.2-2'] },
  cell: (row) => row.data?.reachPct,
};

export const allBroadcastColumns: TreeTableColumn[] = [
  Column_Spots,
  Column_GRPs,
  Column_Impressions,
  Column_Reach000,
  Column_ReachPct,
];

export const allEditableBroadcastColumns: TreeTableColumn[] = [
  Column_Spots,
  Column_GRPs,
  Column_Impressions,
];
