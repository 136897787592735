import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './clear-existing-data-warning-dialog.component.html',
  styleUrls: ['./clear-existing-data-warning-dialog.component.scss'],
})
export class ClearExistingDataWarningDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<
      ClearExistingDataWarningDialogComponent,
      boolean
    >
  ) {}

  onButtonClick(): void {
    this.dialogRef.close(this.data.clearExisting);
  }

  public close(): void {
    this.dialogRef.close(null);
  }
}
