<div *ngIf="meadiaTebleSortFilter && meadiaTebleSortFilter.length > 0">
  <mat-chip
    selected
    color="primary"
  >
    Sort by: {{ selectedFilter }}
    <mat-icon
      class="material-symbols-outlined"
      (click)="clearFilter()"
      >cancel</mat-icon
    >
  </mat-chip>
</div>
<div
  fxFlex="100%"
  fxLayoutAlign="end center"
>
  <!-- Survey Metrics -->
  <div fxLayout="row">
    <survey-metrics
      [survey]="survey"
      [basePopulation]="true"
    ></survey-metrics>
  </div>

  <!-- Media Selector -->
  <div
    fxFlex="1 1 200px"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <ng-container *ngTemplateOutlet="mediaSelectorTemplate"></ng-container>
  </div>
</div>
