import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../pages/login/login.component';
import { EditorComponent } from '../pages/editor/editor.component';
import { DocumentsComponent } from '../pages/documents/documents.component';
import { DocumentDirtyGuard } from '../guards/document.guard';
import { ChartsGuard } from '../guards/charts.guard';
import { TabsGuard } from '../guards/tabs.guard';
import { TupErrorPageComponent } from '@telmar-global/tup-auth';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'documents', component: DocumentsComponent },
  {
    path: 'edit/:document',
    component: EditorComponent,
    canDeactivate: [DocumentDirtyGuard],
    children: [
      { path: '', redirectTo: 'data', pathMatch: 'full' },
      {
        path: 'data',
        component: EditorComponent,
        canActivate: [TabsGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'chart',
        component: EditorComponent,
        canActivate: [ChartsGuard],
      },
    ],
  },
  {
    path: 'new',
    component: EditorComponent,
    canDeactivate: [DocumentDirtyGuard],
    children: [
      { path: '', redirectTo: 'data', pathMatch: 'full' },
      {
        path: 'data',
        component: EditorComponent,
        canActivate: [TabsGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: 'chart',
        component: EditorComponent,
        canActivate: [ChartsGuard],
      },
    ],
  },
  { path: '', redirectTo: '/documents', pathMatch: 'full' },
  { path: '404', component: TupErrorPageComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
