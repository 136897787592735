import {
  MultiSurveyTarget,
  MultiSurveyVehicle,
  SurveyData,
} from '../models/multi-survey.models';
import { Result } from './result';
import uniqid from 'uniqid';

export interface DauSection {
  section: string;
  single?: boolean;
  intialfields?: string[];
  fields: string[];
  data?: { [field: string]: string[] };
  process(section: DauSection, currentTarget: DauTarget);
}

export interface DauTarget {
  survey: SurveyData;
  vehicles: MultiSurveyVehicle[];
  target: MultiSurveyTarget;
  dups: number[];
  distribution: number[];
  copyrightText: string;
  copyrightMultiBaseText: string;
}

export class DauFile {
  totSchedules: number = 0;
  singleScheduleNumbered: boolean = false;
  mix: boolean = false;
  product: string = '';
  errors: string[] = [];
  warnings: string[] = [];

  targets: DauTarget[] = [];

  // processing for the individual sections after their data has been captured (TotSchedules,Mix,ProgName,ProgVer)
  processHeader = (section: DauSection, currentTarget: DauTarget) => {
    if (!section.data['TotSchedules'].length) return;

    this.totSchedules = parseInt(section.data['TotSchedules'][0]);
    this.mix = section.data['Mix']
      ? section.data['Mix'][0].toLowerCase() === 'true'
      : false;
    this.product = `${section.data['ProgName'][0]} - ${section.data['ProgVer'][0]}`;
  };

  // process the [Target] section
  processTarget = (section: DauSection, currentTarget: DauTarget) => {
    const count = section.data['Target'].length;
    if (count) {
      currentTarget.target = {
        name: section.data['Target'][0],
        population: parseFloat(section.data['Pop'][0]),
        sample: -1,
        scale: parseFloat(section.data['Scale'][0]),
        targetCodeId: uniqid(),
      };
    }
  };

  // process the [MediaType] section
  processMediatype = (section: DauSection, currentTarget: DauTarget) => {
    currentTarget.vehicles = currentTarget.vehicles.map((vehicle) => {
      return { ...vehicle, mediaType: section.data['MediaTitle'][0] };
    });
  };

  // process the [Titles] section (Source,Client,Campaign)
  processTitles = (section: DauSection, currentTarget: DauTarget) => {
    currentTarget.survey.code = '[missing]';
    currentTarget.survey.title = section.data['Source'][0];
  };

  // process the [Media] section (MedType,VehCode,VehTitle)
  processMedia = (section: DauSection, currentTarget: DauTarget) => {
    const count = section.data['VehCode'].length;
    for (let index = 0; index < count; index++) {
      currentTarget.vehicles.push({
        title: section.data['VehTitle'][index],
        code: `${section.data['VehCode'][index]}_${uniqid()}`,
        mediaTypeId: Number(section.data['MedType'][index]),
        mediaType: '',
        audience: -1,
        cost: 0,
      });
    }
  };

  // process the [MediaData] section (Covg,Cost,Uses,ImpWgt,IntlUses,MinUses,MaxUses)
  processMediaData = (section: DauSection, currentTarget: DauTarget) => {
    const count = currentTarget.vehicles.length;
    if (count !== section.data['Covg'].length) return;

    for (let index = 0; index < count; index++) {
      currentTarget.vehicles[index].audience = parseFloat(
        section.data['Covg'][index]
      );
      currentTarget.vehicles[index].cost = parseFloat(
        section.data['Cost'][index]
      );
      const inserts = parseFloat(section.data['Uses'][index]);
      const reach = section.data['Reach']
        ? parseFloat(section.data['Reach'][index])
        : 0;
      currentTarget.vehicles[index].result = new Result(
        currentTarget.target.population,
        {
          inserts,
          unitCost: parseFloat(section.data['Cost'][index]),
          impressions:
            currentTarget.vehicles[index].audience *
            currentTarget.target.population *
            inserts,
          reach,
        }
      );
    }
  };

  // process the [Dups] section (Dups)
  processDups = (section: DauSection, currentTarget: DauTarget) => {
    if (section.data['Dups'] && section.data['Dups'].length) {
      currentTarget.dups = section.data['Dups'].map((dups) => parseFloat(dups));
    }
  };

  processSelfPairs = (section: DauSection, currentTarget: DauTarget) => {
    const count = currentTarget.vehicles.length;
    if (count !== section.data['SelfPairs'].length) return;

    for (let index = 0; index < count; index++) {
      currentTarget.vehicles[index].selfPair = parseFloat(
        section.data['SelfPairs'][index]
      );
    }
  };

  // process the [Distribution] section ('Frq', 'Decimal', 'CellWgt')
  processDistribution = (section: DauSection, currentTarget: DauTarget) => {
    if (section.data['Decimal'] && section.data['Decimal'].length) {
      currentTarget.distribution = section.data['Decimal'].map((decimal) =>
        parseFloat(decimal)
      );
    }
  };

  // process the [CopyrightInfo] section ('TgtCode', 'Text', 'MultibaseText')
  processCopyrightInfo = (section: DauSection, currentTarget: DauTarget) => {
    if (section.data['TgtCode'] && section.data['TgtCode'].length) {
      currentTarget.copyrightText = section.data['Text'][0];
      currentTarget.copyrightMultiBaseText = section.data['MultibaseText'][0];
    }
  };

  // sections and fields in the DAU file to parse
  headerSection: DauSection = {
    section: '[Header]',
    fields: ['TotSchedules', 'Mix', 'ProgName', 'ProgVer'],
    process: this.processHeader,
  };

  dauSections: DauSection[] = [
    {
      section: '[Target*]',
      fields: ['Target', 'Pop', 'Scale'],
      process: this.processTarget,
    },
    {
      section: '[Titles*]',
      fields: ['Source', 'Client', 'Campaign'],
      process: this.processTitles,
    },
    {
      section: '[Media*]',
      fields: ['MedType', 'VehCode', 'VehTitle'],
      process: this.processMedia,
    },
    {
      // moved after media processing so target.vehicles[] exists and we can add mediaType to each vehicle
      section: '[MediaType*]',
      fields: ['MediaTitle', 'VehCnt', 'PPRFCalcMeth'],
      process: this.processMediatype,
    },
    {
      section: '[MediaData*]',
      fields: [
        'Covg',
        'Cost',
        'Uses',
        'Reach',
        'ImpWgt',
        'IntlUses',
        'MinUses',
        'MaxUses',
      ],
      process: this.processMediaData,
    },
    {
      section: '[Dups*]',
      fields: ['Dups'],
      process: this.processDups,
    },
    {
      section: '[SelfPairs*]',
      fields: ['SelfPairs'],
      process: this.processSelfPairs,
    },
    {
      section: '[Distribution*]',
      intialfields: ['TotCells'],
      fields: ['Frq', 'Decimal', 'CellWgt'],
      process: this.processDistribution,
    },
    {
      section: '[CopyrightInfo*]',
      fields: ['TgtCode', 'Text', 'MultibaseText'],
      process: this.processCopyrightInfo,
    },
  ];

  constructor() {}

  clearAll() {
    this.targets = [];
    this.errors = [];
    this.warnings = [];

    this.mix = false;
    this.product = '';
  }

  addTarget(): DauTarget {
    this.targets.push({
      distribution: [],
      dups: [],
      survey: { title: '', code: '', authGroup: '' },
      vehicles: [],
      target: { name: '' },
      copyrightText: '',
      copyrightMultiBaseText: '',
    });

    return this.targets[this.targets.length - 1];
  }

  /**
   * Load and parse the content of the parsed fileContent as a DAU file format
   *
   * @param {string} fileContent string containing the contents of the DAU file
   * @returns {boolean} success of the load
   */

  load(fileContent: string): boolean {
    this.clearAll();
    const lines = fileContent.split('\r\n').map((line) => line.trim());

    // first process the header section for TotSchedules (Target count)
    let lineIndex = lines.indexOf(this.headerSection.section);
    if (lineIndex === -1) {
      this.errors.push('Not a valid file.  Missing Section: [Header]');
      return false;
    }

    this.processSection(this.headerSection, lines, lineIndex);
    this.headerSection.process(this.headerSection, null);

    // some DAUs number every schedule, some only number them if there's more than 1
    if (this.totSchedules === 1) {
      const idx = lines.indexOf('[Target1]');
      this.singleScheduleNumbered = idx !== -1;
    }

    for (let t = 0; t < this.totSchedules; t++) {
      this.addTarget();
    }

    // loop for the number of TotSchedules (actually targets)
    this.targets.forEach((currentTarget, index) => {
      this.dauSections.forEach((dauSection) => {
        const sectionCheck = dauSection.section.replace(
          '*',
          this.getCurrentSchedule(index)
        );
        lineIndex = lines.indexOf(sectionCheck);

        if (lineIndex !== -1) {
          this.processSection(dauSection, lines, lineIndex); // parse into generic data object
          dauSection.process(dauSection, currentTarget); // pull out specifics
        }
      });
    });
    return true;
  }

  private getCurrentSchedule(currentSchedule: number): string {
    return this.singleScheduleNumbered || this.totSchedules > 1
      ? `${currentSchedule + 1}`
      : '';
  }

  // split the line by commas but also take "commas inside quotes, into account", e.g this section will now parse correctly:
  //  [Target2]
  //  Target, Pop, Scale
  //  "All 25-64,HH SIZE 1,HH SIZE 2",5089,100
  private parseLine(line: string): string[] {
    const arr = line.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
    return this.stripQuotes(arr || []);
  }

  private stripQuotes(lines: string[]): string[] {
    return lines.map((line) =>
      line
        .trim()
        .replace(/^"(.*)"$/, '$1')
        .trim()
    );
  }

  private blankLine(line: string[]): boolean {
    return line.join().trim().length === 0;
  }

  // load the entire requested section of the DAU file into the data object of the array
  private processSection(
    section: DauSection,
    lines: string[],
    index: number
  ): number {
    index++;
    let dauFields = this.stripQuotes(lines[index].split(',')); //  TotSchedules,Mix,ProgName,ProgVer
    let finished = false;
    let doInitialFields = !!section.intialfields;
    const sectionData: { [field: string]: string[] } = {};

    index++;
    while (!finished) {
      const lineData = this.parseLine(lines[index]); // 1,"False","Media 360","3.0.0 Release 171"

      // check if at end of section
      if (doInitialFields) finished = false;
      // assume 1 line of data if there is an initialField array
      else finished = this.blankLine(lineData); // any amount of lines until we hit a blank line

      if (!finished) {
        dauFields.forEach((dauField, fieldIndex) => {
          sectionData[dauField] = sectionData[dauField] || [];
          sectionData[dauField].push(lineData[fieldIndex]);
        });
      }

      // working with initial fields (1 row extracted) - now switch to regular fields and continue;
      if (doInitialFields) {
        doInitialFields = false;
        index++;
        dauFields = this.stripQuotes(lines[index].split(',')); //  TotSchedules,Mix,ProgName,ProgVer
      }

      index++;
    }

    section.data = sectionData;
    return index;
  }
}
