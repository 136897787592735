import { TargetStatement } from '../models/engine.models';
import { MultiSurveyVehicleConfig } from '../models/multi-survey.models';
import { Result } from './result';

export enum DaypartDay {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export const daysOfTheWeek: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export interface TargetDaypart {
  id: string;
  mnemonic: string;
  title: string;
  startDay: DaypartDay;
  startTime: string;
  endDay: DaypartDay;
  endTime: string;
  audience: number;
  grossAudience: number;
  potentialReach: number;
  qtrHourAudience?: number;
  resps: number;
}

export interface TargetVehicle {
  id: string;
  mnemonic: string;
  originalMediaType?: string; // if target is added to custom group, mediaType becomes the new group name, but we have to save the initial media type
  customGroupId?: string;
  mediaType: string;
  mediaTypeId: number;
  addressable: boolean;
  addressableConfig?: VehicleAddressable;
  calculationMethod: string;
  isMediaGroup: boolean;
  title: string;
  originalTitle: string;
  audience: number;
  grossAudience: number;
  resps: number;
  potentialReach: number;
  compositionIndex: number;
  compositionPct: number;
  uniqueReach000?: number;
  dayparts?: TargetDaypart[];
  ESG: [{ value: number; Provider: string }];
  survey?: {
    code: string;
    authGroup: string;
    title: string;
  };
  isMultiSurvey?: boolean;
  multiSurveyConfig?: MultiSurveyVehicleConfig;
}

export interface VehicleAddressable {
  targetId?: string;
  population?: number;
  isDirectMail?: boolean;
}

export interface ScheduleVehicle {
  vehicleId: string;
  targetId: string;
  result: Result;
  isMultiSurvey?: boolean;
}
