<div class="mixed-data-dialog">
  <tup-mat-title-bar [title]="'Update manual entry'">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>

  <snackbar-generic
    class="full-width-snackbar"
    *ngIf="manualEntryForm.duplicateManualTitle !== ''"
    [data]="snackbarError"
  >
  </snackbar-generic>

  <mat-dialog-content class="content">
    <manual-entry
      #manualEntryForm
      [multiSurveyId]="multiSurveyId"
      [scheduleIndex]="scheduleIndex"
      (duplicateTitle)="onDuplicateTitleCheck($event)"
    ></manual-entry>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions fxLayoutAlign="space-between center">
    <button
      mat-button
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      [disabled]="
        !manualEntryForm.manualMediaTitle ||
        !manualEntryForm.manualReachPct ||
        !manualEntryForm.manualAvgFreq ||
        manualEntryForm.duplicateManualTitle !== ''
      "
      mat-flat-button
      tupMatButton
      appearance="small"
      color="primary"
      (click)="onClose(true)"
    >
      Update
    </button>
  </mat-dialog-actions>
</div>
