import { Injectable } from '@angular/core';
import {
  SaveDocumentDialogComponent,
  SaveDocumentDialogData,
  SaveDocumentDialogResult,
} from '../components/dialogs/save-document-dialog/save-document-dialog.component';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  StartPlanningDialogComponent,
  StartPlanningDialogModel,
} from '../components/dialogs/start-planning-dialog/start-planning-dialog.component';
import { StatusSnackbarIcon } from '../components/dialogs/snackbar-generic/snackbar-generic.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
  QuestionDialogModelButtonOptions,
  QuestionDialogModelOptions,
} from '../components/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DocumentDialogService {
  constructor(private dialog: MatDialog) {}

  public openSaveDocumentDialog(
    dialogData: SaveDocumentDialogData
  ): Observable<SaveDocumentDialogResult> {
    return new Observable<SaveDocumentDialogResult>((ob) => {
      const dialogConfig: MatDialogConfig = { data: dialogData };
      dialogConfig['autoFocus'] = false;
      this.dialog
        .open(SaveDocumentDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((data: SaveDocumentDialogResult) => {
          ob.next(data);
          ob.complete();
        });
    });
  }

  openStartPlanningDialog(planningOptions: StartPlanningDialogModel) {
    const options = {
      data: planningOptions,
      autoFocus: false,
      //  minWidth: '70vw',
      //  maxWidth: '70vw',
    };

    return this.dialog.open(StartPlanningDialogComponent, options);
  }

  openCampaignWithNotAvailableSurveysError(
    message: string = 'Survey(s) unavailable. Please contact your sales representative.'
  ) {
    const button: QuestionDialogModelButtonOptions = {
      caption: 'Close',
      data: 'ok',
      flat: true,
    };

    const options: QuestionDialogModelOptions = {
      buttons: [button],
      closeButton: button,
      buttonAlignment: 'end',
      snackbar: {
        type: 'error',
        message,
        align: 'center',
        icon: StatusSnackbarIcon.Error,
      },
    };
    options['autoFocus'] = false;
    options['minWidth'] = '35vw';
    options['maxWidth'] = '40vw';

    const dialogData = new ConfirmDialogModel(
      'Unable to continue',
      undefined,
      options
    );

    Object.assign(options, {
      data: dialogData,
    });

    return this.dialog.open(ConfirmDialogComponent, options);
  }
}
