<div class="container compare-by-table-wrapper">
  <tree-table
    [style.--columns-number]="tableColumnsNumber"
    [inlineMenuWithIcons]="true"
    #freqDistTable
    class="tree-table primary-color-string compare-by-table freq-dist-table"
    treeTitle="Frequency"
    treeWidth="238px"
    [treeHeaderInlineMenu]="inlineMenu"
    (treeHeaderMenuClick)="onTreeHeaderMenuClick($event)"
    [data]="tableData"
    [showProcessing]="processing"
    [columns]="tableColumns"
    [unitsText]="unitsText"
    [disableDragging]="true"
    [allowSelectAll]="false"
  >
  </tree-table>
</div>
