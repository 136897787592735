import uniqid from 'uniqid';
import { DocumentVehicleGroup } from '../models/document.model';
import { TargetVehicle } from './vehicle';

export interface VehicleGroupVehicle {
  id: string;
  daypartIds: string[];
  name: string;
}

export interface VehicleGroup {
  id: string;
  name: string;
  vehicles: VehicleGroupVehicle[];
}

export class VehicleGroups {
  groups: VehicleGroup[] = [];

  public group(id: string | number): VehicleGroup {
    return typeof id === 'string'
      ? this.groups.find((grp) => grp.id === id)
      : this.groups[id as number];
  }

  public get length(): number {
    return this.groups.length;
  }

  public add(name: string, vehicles: VehicleGroupVehicle[] = []): VehicleGroup {
    const existingGroup = this.groups.find((group) => group.name === name);
    if (!existingGroup) {
      this.groups.push({
        id: uniqid(),
        name,
        vehicles,
      });
    } else {
      const existingGroupIndex = this.groups.indexOf(existingGroup);
      vehicles.forEach((vehicle) => {
        this.groups[existingGroupIndex].vehicles.push(vehicle);
      });
      return this.groups[existingGroupIndex];
    }

    return this.groups[this.groups.length - 1];
  }

  public addVehicles(
    id: string | number,
    vehicles: VehicleGroupVehicle[],
    clearFirst: boolean = false
  ): VehicleGroup {
    const group = this.group(id);
    if (group) {
      group.vehicles = clearFirst ? [] : group.vehicles;
      const ids = group.vehicles.map((v) => v.id);

      group.vehicles.push(...vehicles.filter((veh) => !ids.includes(veh.id)));
    }
    return group;
  }

  public removeVehicle(id: string | number, vehicleId: string) {
    const group = this.group(id);
    if (group) {
      const vehicleToRemove = group.vehicles.find(
        (vehicle) => vehicle.id === vehicleId
      );
      const indexOfVehicleToRemove = group.vehicles.indexOf(vehicleToRemove);
      group.vehicles.splice(indexOfVehicleToRemove, 1);
    }
  }

  public delete(id: String | number): number {
    if (typeof id === 'string') {
      this.groups = this.groups.filter((group) => group.id !== id);
    } else {
      this.groups.splice(id as number, 1);
    }

    return this.groups.length;
  }

  public reconcileVehicles(vehicles: TargetVehicle[]) {
    const ids = vehicles.map((veh) => veh.id);
    this.groups.forEach((group) => {
      group.vehicles = group.vehicles.filter((vehicle) =>
        ids.includes(vehicle.id)
      );
    });
  }

  // prepare groups to be included in the campaign file saved by doc service
  public asDocument(): DocumentVehicleGroup[] {
    return this.groups as DocumentVehicleGroup[];
  }

  // receive the groups from the saved campaign and add to object arrays
  public loadCampaign(documentGroups: DocumentVehicleGroup[]) {
    this.groups = documentGroups as VehicleGroup[];
  }

  clearAll() {
    this.groups = [];
  }
}
