import { Pipe, PipeTransform, QueryList } from '@angular/core';
import { TabStepperTabComponent } from '../components/tab-stepper/tab-stepper-tab/tab-stepper-tab.component';

@Pipe({
  name: 'viewModeFilter',
})
export class ViewModeFilterPipe implements PipeTransform {
  transform(
    queryList: QueryList<TabStepperTabComponent>,
    viewMode: string
  ): TabStepperTabComponent[] {
    return queryList.filter(
      (tabComponent) => tabComponent.viewMode === viewMode
    );
  }
}
