import { Pipe, PipeTransform } from '@angular/core';
import { TreeTableColumn } from '../components/tree-table/tree-table.models';

@Pipe({
  name: 'appendUnits',
})
export class AppendUnitsPipe implements PipeTransform {
  transform(column: TreeTableColumn, unitsText: string): string {
    if (column.showUnits === true && column.hasOwnProperty('showUnits')) {
      return column.header + ' ' + unitsText;
    }
    return column.header;
  }
}
