<div fxLayout="column">
  <mat-progress-bar
    mode="determinate"
    [value]="data.progress | async"
  ></mat-progress-bar>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    [fxLayoutGap]="!collapsed ? '16px' : '4px'"
    class="main-content"
  >
    <loading-dots *ngIf="(data.progress | async) != 100"></loading-dots>
    <mat-icon
      class="complete-icon"
      *ngIf="(data.progress | async) == 100"
      >check_circle</mat-icon
    >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      [fxLayoutGap]="!collapsed ? '8px' : '0px'"
    >
      <div class="percentage">{{ data.progress | async }}%</div>
      <div
        class="info-text"
        [ngClass]="{ 'collpsed-item': collapsed }"
      >
        <span *ngIf="(data.progress | async) == 100"
          >Optimisation completed.
        </span>
        <span *ngIf="(data.progress | async) != 100">Optimising plan... </span>
      </div>
    </div>
    <div
      fxLayout="row"
      [fxLayoutGap]="!collapsed ? '8px' : '0px'"
      fxLayoutAlign="start center"
    >
      <button
        tupMatButton
        mat-flat-button
        *ngIf="!collapsed && (data.progress | async) != 100"
        (click)="canceOptimisation()"
      >
        Cancel
      </button>
      <mat-icon
        [matTooltip]="
          !collapsed ? 'Collapse progress info' : 'Expand progress info'
        "
        (click)="collapsed = !collapsed"
        class="collapse-icon"
        >{{ collapsed ? "chevron_left" : "chevron_right" }}</mat-icon
      >
    </div>
  </div>
</div>
