import { cloneDeep } from 'lodash';
import {
  MediaTypeDataResponse,
  SurveyMetaDataResponse,
} from '../models/codebook.models';
import { CalculationParameter, ReachFreqParameters } from './calc-parameters';
import { MediaPlanner } from './media-planner';
import { TargetVehicle } from './vehicle';

export interface SurveyDataWeights {
  index: number;
  description: string;
}

export interface SurveyDataMediaType {
  insertionType: string;
  isAddressable: boolean;
  mediaTypeId: number;
  PlanningType: string;
  subtype: string;
}

export interface MeasurementBasisList {
  planningType: string;
  basis: string;
}

export class SurveysMetaData {
  surveyMetaData: { [surveyCode: string]: SurveyMetaData } = {};
  reachFreq: ReachFreqParameters;

  constructor() {
    this.reachFreq = new ReachFreqParameters();
  }

  meta(surveyCode: string): SurveyMetaData {
    return this.surveyMetaData[surveyCode];
  }

  copyMediaTypeData(surveyCode: string, mediaTypedata: MediaTypeDataResponse) {
    const meta = this.addSurvey(surveyCode);
    meta.copyMediaTypeData(mediaTypedata);
  }

  copyMetaData(surveyCode: string, metaData: SurveyMetaDataResponse) {
    const meta = this.addSurvey(surveyCode);
    meta.copyMetaData(metaData);
  }

  // get the global RF calculation parameters and save in a class.  Used for processing R&F and UI displays
  copyReachFreqData(
    parameters: CalculationParameter[],
    percentageInserts: string[]
  ) {
    this.reachFreq.parameters = parameters;
    this.reachFreq.percentageInserts = percentageInserts;
    this.reachFreq.ready = parameters.length > 0;
  }

  ready(surveyCode: string): boolean {
    return this.reachFreq.ready && this.meta(surveyCode).mediaTypes.length > 0;
  }

  addSurvey(surveyCode: string): SurveyMetaData {
    let meta = this.surveyMetaData[surveyCode];
    if (!meta) {
      this.surveyMetaData[surveyCode] = new SurveyMetaData();
      meta = this.surveyMetaData[surveyCode];
    }
    return meta;
  }

  clear() {}
}

export class SurveyMetaData {
  surveyCode: string;
  reportUnitText: string;
  weightsDescription: SurveyDataWeights[];
  weightsCount: number;
  reportUnits: number;
  respondentCount: number;
  firstLevelStability: number;
  secondLevelStability: number;
  mediaTypes: SurveyDataMediaType[];
  measurementBasis: MeasurementBasisList[] = [
    { planningType: 'general', basis: 'Audience 000' },
    { planningType: 'press', basis: 'Readership' },
    { planningType: 'tv', basis: 'Av.1/4 hr. aud' },
    { planningType: 'radio', basis: 'Av.1/4 hr. aud' },
    { planningType: 'outdoor', basis: 'Av.weekly contacts' },
    { planningType: 'sms', basis: 'Audience 000' },
    { planningType: 'cinema', basis: 'Av.weekly admissions' },
    { planningType: 'vod', basis: 'Avg. weekly hours' },
    { planningType: 'web', basis: 'Avg. daily page views' },
    { planningType: 'addr', basis: 'Audience 000' },
    { planningType: 'mobile', basis: 'Weekly time spent (hrs)' },
    { planningType: 'onlineaudio', basis: 'Weekly frequency' },
    { planningType: 'diarysim', basis: 'Avg. weekly quarter hours' },
  ];
  plan: MediaPlanner; // current media plan

  constructor() {
    this.clear();
  }

  clear() {
    this.surveyCode = '';
    this.reportUnitText = '';
    this.weightsDescription = [];
    this.weightsCount = 0;
    this.reportUnits = 0;
    this.respondentCount = 0;
    this.firstLevelStability = 0;
    this.secondLevelStability = 0;
    this.mediaTypes = [];
  }

  // any mediatypes in the list marked as addressable
  anyAddressable(): boolean {
    return !!this.mediaTypes.find((medtype) => medtype.isAddressable);
  }

  // addressable status of the supplied mediatypeId
  isAddressable(mediaTypeId: number): boolean {
    const media = this.mediaTypes.find(
      (mtype) => mtype.mediaTypeId === mediaTypeId
    );
    return media ? !!media.isAddressable : false;
  }

  // direct mail media type of the supplied mediatypeId
  isDirectMail(mediaTypeId: number): boolean {
    const media = this.mediaTypes.find(
      (mtype) => mtype.mediaTypeId === mediaTypeId
    );
    return media ? media.PlanningType === 'directmail' : false;
  }

  // any press or broadcast in the campaign
  anyPressOrBroadcast(vehicles: TargetVehicle[]): boolean {
    const planningTypes: string[] = ['press', 'tv', 'radio'];
    const mediaTypeIds = this.mediaTypes
      .filter((media) => planningTypes.includes(media.PlanningType))
      .map((media) => media.mediaTypeId);

    return !!vehicles.find((vehicle) =>
      mediaTypeIds.includes(vehicle.mediaTypeId)
    );
  }

  onlyPressOrBroadcast(vehicles: TargetVehicle[], group: string): boolean {
    const groupVehicles =
      group === 'total'
        ? vehicles
        : vehicles.filter((veh) => veh.mediaType === group);

    const planningTypes: string[] = ['press', 'tv', 'radio'];
    const mediaTypeIds = this.mediaTypes
      .filter((media) => planningTypes.includes(media.PlanningType))
      .map((media) => media.mediaTypeId);

    return groupVehicles.every((vehicle) =>
      mediaTypeIds.includes(vehicle.mediaTypeId)
    );
  }

  hasFreqCapping(mediaTypeId: number): boolean {
    const planningTypes: string[] = ['vod', 'web', 'addr', 'online'];

    const media = this.mediaTypes.find(
      (mtype) => mtype.mediaTypeId === mediaTypeId
    );
    return media ? planningTypes.includes(media.PlanningType) : false;
  }

  getMeasurementBasis(mediaTypeId: number) {
    let mBasis;
    const mTypes = this.mediaTypes.find(
      (mediaType) => mediaType.mediaTypeId === mediaTypeId
    );
    if (mTypes) {
      mBasis = this.measurementBasis.find(
        (mB) => mB.planningType === mTypes.PlanningType
      );
    }
    return mBasis ? mBasis.basis : 'Audience';
  }

  // consume results from the MetaData endpoint
  copyMetaData(metaData: SurveyMetaDataResponse) {
    if (!metaData) return;

    this.reportUnits = metaData.reportUnits;
    if (this.reportUnits) {
      const units = ('' + this.reportUnits).substring(1);
      this.reportUnitText = units ? `(${units})` : '';
    }

    // grab the weights in one hit
    this.weightsDescription = cloneDeep(metaData.weightsDescription);
  }

  // consume results from the mediatypeId endpoint
  copyMediaTypeData(mediaTypedata: MediaTypeDataResponse) {
    if (!mediaTypedata || !Array.isArray(mediaTypedata['media-types'])) return;

    this.mediaTypes = mediaTypedata['media-types'].map((mediatype) => {
      return {
        insertionType: mediatype['insertion-type'] || '',
        isAddressable: !!mediatype['is-addressable'],
        mediaTypeId: parseInt('' + mediatype['media-type-id']) || 0,
        PlanningType: mediatype['planning-type'] || '',
        subtype: mediatype['Subtype'] || mediatype['subtype'] || '',
      };
    });
  }
}
