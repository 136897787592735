import { ColumnGroup } from '../components/tree-table/column-group/table-group.models';
import {
  TreeTableColumn,
  TreeTableMenuItem,
} from '../components/tree-table/tree-table.models';
export const OPTIMISE_MENU_ITEM: TreeTableMenuItem = {
  label: 'Optimise',
  data: 'optimise',
  matIcon: 'rocket_launch',
  disabled: false,
};

// hardcoded menu for the editable columns
const EDIT_MENU: TreeTableMenuItem[] = [
  OPTIMISE_MENU_ITEM,
  {
    label: 'Fill selected with',
    data: 'fill_sel',
    matIcon: 'select_check_box',
  },
  {
    label: 'Fill all with',
    data: 'fill_all',
    matIcon: 'tv_options_edit_channels',
  },
  { label: 'Clear all', data: 'clear_all', matIcon: 'backspace' },
];

// column group definitions
export const Column_Group_Audience: ColumnGroup = {
  name: 'COMPOSITION',
  headerCss: 'column-group-default',
  columnsDialogPosition: 2,
};

export const Column_Group_Survey: ColumnGroup = {
  name: 'SURVEY',
  headerCss: 'column-group-default',
  columnsDialogPosition: 1,
};

export const Column_Group_Duration: ColumnGroup = {
  name: 'DURATION',
  headerCss: 'column-group-default',
  columnsDialogPosition: 1,
};

export const Column_Group_AddlReach: ColumnGroup = {
  name: 'ADDITIONAL REACH',
  headerCss: 'column-group-default',
  columnsDialogPosition: 1,
};

export const Column_Group_Addressable: ColumnGroup = {
  name: 'ADDRESSABLE AUDIENCE',
  headerCss: 'column-group-default',
  cellCss: 'cell-group-addressable',
  columnsDialogPosition: 2,
};

export const Column_Group_DirectMail: ColumnGroup = {
  name: 'MAIL AUDIENCE',
  headerCss: 'column-group-default',
  columnsDialogPosition: 1,
};

export const Column_Group_Costs: ColumnGroup = {
  name: 'COSTS',
  headerCss: 'column-group-default',
  columnsDialogPosition: 3,
};

export const Column_Group_PotentialReach: ColumnGroup = {
  name: 'POTENTIAL REACH',
  headerCss: 'column-group-default',
  columnsDialogPosition: 2,
};

// column definitions
export const Column_Audience000: TreeTableColumn = {
  header: 'Measurement',
  columnDef: 'audience',
  tooltip:
    'The number of people in a target audience who have seen a single (or average) issue of a publication/media vehicle (Non-digital media).  The number Page Views/Impressions for a given media vehicle (Digital media). See Measurement Basis',
  columnType: 'number',
  showUnits: true,
  cell: (row) => row.data?.audience,
  sort: (row) => row.data?.audienceRaw,
};

export const Column_AudiencePct: TreeTableColumn = {
  header: 'Measurement %',
  columnDef: 'audiencePct',
  tooltip:
    'The percentage of Measurement 000, that your target audience represents eg Ratings (TV), Readership % (Print)',
  columnType: 'number',
  decimals: 2,
  cell: (row) => row.data?.audiencePct,
  sort: (row) => row.data?.audiencePctRaw,
};

export const Column_AudienceBasis: TreeTableColumn = {
  header: 'Measurement basis',
  tooltip: 'Indicates the basis for the Measurement 000 calculation',
  columnDef: 'audienceBasis',
  columnType: 'string',
  cell: (row) => row.data?.audienceBasis,
  sort: (row) => row.data?.audienceBasis,
};

export const Column_CompositionIndex: TreeTableColumn = {
  header: 'Composition Index',
  tooltip:
    'An index showing how likely someone in a target audience is to have seen a media vehicle.',
  columnDef: 'compositionIndex',
  columnType: 'number',
  group: { name: Column_Group_Audience.name },
  cell: (row) => row.data?.compositionIndex,
  sort: (row) => row.data?.compositionIndexRaw,
};

export const Column_CompositionPct: TreeTableColumn = {
  header: 'Composition %',
  tooltip:
    'The number of people within a target audience who have seen a media vehicle, expressed as a percentage of those who have seen it in the composition base.',
  columnDef: 'compositionPct',
  columnType: 'number',
  decimals: 2,
  group: { name: Column_Group_Audience.name },
  cell: (row) => row.data?.compositionPct,
  sort: (row) => row.data?.compositionPctRaw,
};

export const Column_Inserts: TreeTableColumn = {
  header: 'Insertions',
  tooltip: 'Insertions (Print) / Spots (TV, Radio)',
  columnDef: 'inserts',
  columnType: 'number',
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.inserts,
  sort: (row) => row.data?.insertsRaw,
};

export const Column_BuyingImpressions: TreeTableColumn = {
  header: 'Addressable impressions',
  tooltip: 'Addressable impressions',
  columnDef: 'buyingImpressions',
  columnType: 'number',
  group: { name: Column_Group_Addressable.name },
  menu: EDIT_MENU,
  showUnits: true,
  editable: true,
  cell: (row) => row.data?.buyingImpressions,
  sort: (row) => row.data?.buyingImpressionsRaw,
};

export const Column_DurationDM: TreeTableColumn = {
  header: 'Mail duration',
  tooltip: 'The number of subsequent weeks that the campaign is repeated for',
  columnDef: 'durationDM',
  columnType: 'number',
  group: { name: Column_Group_DirectMail.name },
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.durationDM,
  sort: (row) => row.data?.durationDMRaw,
};

export const Column_InsertsDM: TreeTableColumn = {
  header: '% Audience targeted',
  tooltip:
    'The percentage of a population or target audience that you want to target with your campaign.',
  columnDef: 'insertsDM',
  columnType: 'number',
  group: { name: Column_Group_DirectMail.name },
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.insertsDM,
  sort: (row) => row.data?.insertsDMRaw,
};

export const Column_NumberOfMailItems: TreeTableColumn = {
  header: 'No of mail items [000]',
  tooltip: 'No of mail items',
  columnDef: 'noOfMailItems',
  columnType: 'number',
  group: { name: Column_Group_DirectMail.name },
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.noOfMailItems,
  sort: (row) => row.data?.noOfMailItemsRaw,
};

export const Column_BuyingGRPS: TreeTableColumn = {
  header: 'Addressable GRPs',
  tooltip: 'Addressable GRPs',
  columnDef: 'buyingGrps',
  columnType: 'number',
  group: { name: Column_Group_Addressable.name },
  menu: EDIT_MENU,
  editable: true,
  decimals: 2,
  cell: (row) => row.data?.buyingGrps,
  sort: (row) => row.data?.buyingGrpsRaw,
};

export const Column_Survey: TreeTableColumn = {
  header: 'Survey',
  columnDef: 'surveyCode',
  columnType: 'string',
  group: { name: Column_Group_Survey.name },
  menu: EDIT_MENU,
  editable: false,
  cell: (row) => row.data?.surveyCode,
  sort: (row) => row.data?.surveyCodeRaw,
};

export const Column_Duration: TreeTableColumn = {
  header: 'Duration',
  tooltip:
    'No. of days / weeks needed to achieve buying inputs (see Measurement Basis)',
  columnDef: 'duration',
  columnType: 'number',
  group: { name: Column_Group_Duration.name },
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.duration,
  sort: (row) => row.data?.durationRaw,
};

export const Column_Impressions: TreeTableColumn = {
  header: 'Impressions',
  tooltip:
    'The total number of exposures of a target audience to a schedule regardless of the number of times one person is exposed',
  columnDef: 'impressions',
  columnType: 'number',
  menu: EDIT_MENU,
  showUnits: true,
  editable: true,
  cell: (row) => row.data?.impressions,
  sort: (row) => row.data?.impressionsRaw,
};

export const Column_GRPs: TreeTableColumn = {
  header: 'GRPs',
  tooltip: 'Gross Rating Points - The sum of the ratings for a schedule',
  columnDef: 'grps',
  columnType: 'number',
  menu: EDIT_MENU,
  decimals: 2,
  editable: true,
  cell: (row) => row.data?.grps,
  sort: (row) => row.data?.grpsRaw,
};

export const Column_ReachPct: TreeTableColumn = {
  header: 'Reach %',
  tooltip:
    'The number of people exposed to the schedule at least once, shown as a percentage of the target audience',
  columnDef: 'reachPct',
  columnType: 'number',
  menu: EDIT_MENU,
  decimals: 2,
  editable: true,
  cell: (row) => row.data?.reachPct,
  sort: (row) => row.data?.reachPctRaw,
};

export const Column_Reach000: TreeTableColumn = {
  header: 'Reach',
  tooltip:
    'The number of people in the target audience (shown in units of 1000) exposed to a schedule at least once',
  columnDef: 'reach000',
  columnType: 'number',
  menu: EDIT_MENU,
  showUnits: true,
  editable: true,
  cell: (row) => row.data?.reach000,
  sort: (row) => row.data?.reach000Raw,
};

export const Column_UniqueReachPct: TreeTableColumn = {
  header: 'Unique reach %',
  tooltip:
    'The number of people exposed to the schedule that would be reached only by that Media Type',
  columnDef: 'uniqueReachPct',
  group: { name: Column_Group_AddlReach.name },
  columnType: 'number',
  decimals: 2,
  cell: (row) => row.data?.uniqueReachPct,
  sort: (row) => row.data?.uniqueReachPctRaw,
};

export const Column_UniqueReach000: TreeTableColumn = {
  header: 'Unique reach',
  tooltip:
    'The number of people exposed to the schedule that would be reached only by that Media Type',
  columnDef: 'uniqueReach000',
  group: { name: Column_Group_AddlReach.name },
  columnType: 'number',
  showUnits: true,
  cell: (row) => row.data?.uniqueReach000,
  sort: (row) => row.data?.uniqueReach000Raw,
};

export const Column_PotentialReach000: TreeTableColumn = {
  header: 'Potential reach',
  tooltip:
    'The max number of people in a target audience who can be reached by the selected media',
  columnDef: 'potentialReach000',
  group: { name: Column_Group_PotentialReach.name },
  columnType: 'number',
  showUnits: true,
  cell: (row) => row.data?.potentialReach000,
  sort: (row) => row.data?.potentialReach000Raw,
};

export const Column_PotentialReachPct: TreeTableColumn = {
  header: 'Potential reach %',
  tooltip:
    'The max number of people who can be reached by the selected media, shown as a percentage of the target audience',
  columnDef: 'potentialReachPct',
  group: { name: Column_Group_PotentialReach.name },
  columnType: 'number',
  decimals: 2,
  cell: (row) => row.data?.potentialReachPct,
  sort: (row) => row.data?.potentialReachPctRaw,
};

export const Column_AvgFreq: TreeTableColumn = {
  header: 'Avg. freq',
  tooltip:
    'The average number of times each person is exposed to the media schedule',
  columnDef: 'avgFrequency',
  columnType: 'number',
  decimals: 2,
  cell: (row) => row.data?.avgFrequency,
  sort: (row) => row.data?.avgFrequencyRaw,
};

export const Column_EffReach: TreeTableColumn = {
  header: 'Eff. Reach',
  tooltip:
    'The number of people reached who are exposed at least n times to the advert',
  columnDef: 'effReach',
  columnType: 'number',
  group: { name: Column_Group_AddlReach.name },
  showUnits: true,
  cell: (row) => row.data?.effReach,
  sort: (row) => row.data?.effReachRaw,
};
export const Column_EffReachPct: TreeTableColumn = {
  header: 'Effective Reach %',
  tooltip:
    'The number of people reached who are exposed at least n times to the advert',
  columnDef: 'effReachPct',
  columnType: 'number',
  decimals: 2,
  group: { name: 'ADDITIONAL REACH' },
  cell: (row) => row.data?.effReachPct,
  sort: (row) => row.data?.effReachPctRaw,
};

// export const Column_AvgMinOnSite: TreeTableColumn = {
//   header: 'Avg. Min on Site',
//   tooltip:
//     'Average Min. on Site is the average duration of time spent on a website',
//   columnDef: 'avgMinOnSite',
//   columnType: 'number',
//   decimals: 1,
//   cell: (row) => row.data?.avgMinOnSite,
//   sort: (row) => row.data?.avgMinOnSiteRaw,
// };

export const Column_FreqCapping: TreeTableColumn = {
  header: 'Frequency capping',
  tooltip:
    'Specified limit for the number of times ads appear to the same person',
  columnDef: 'freqCapping',
  columnType: 'number',
  group: { name: Column_Group_Addressable.name },
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.freqCapping,
  sort: (row) => row.data?.freqCappingRaw,
};

export const Column_MediaType: TreeTableColumn = {
  header: 'Media type',
  tooltip:
    'Choosing media types, such as TV, radio, print, and digital is part of media planning and determining when and where to place ads.',
  columnDef: 'mediaType',
  columnType: 'string',
  cell: (row) => row.data?.mediaType,
  sort: (row) => row.data?.mediaType,
};

export const Column_CPM: TreeTableColumn = {
  header: 'Target CPM',
  tooltip:
    'Cost per thousand - The cost of reaching 1,000 people/ impressions for selected target audience. For non-digital media, this is calculated on All Adults',
  columnDef: 'cpm',
  columnType: 'number',
  group: { name: Column_Group_Costs.name },
  decimals: 2,
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.cpm,
  sort: (row) => row.data?.cpmRaw,
};

export const Column_BuyingCPM: TreeTableColumn = {
  header: 'Buying CPM',
  tooltip:
    'Cost per thousand - The cost of reaching 1,000 people/ impressions for this audience if buying on a different target audience',
  columnDef: 'buyingCpm',
  columnType: 'number',
  group: { name: Column_Group_Costs.name },
  decimals: 2,
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.buyingCpm,
  sort: (row) => row.data?.buyingCpmRaw,
};

export const Column_CPP: TreeTableColumn = {
  header: 'Target CPP',
  tooltip: 'Cost Per Point - The cost of buying one GRP/TRP',
  columnDef: 'cpp',
  columnType: 'number',
  group: { name: Column_Group_Costs.name },
  decimals: 2,
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.cpp,
  sort: (row) => row.data?.cppRaw,
};

export const Column_BuyingCPP: TreeTableColumn = {
  header: 'Buying CPP',
  tooltip: 'Cost Per Point - The cost of buying one GRP/TRP',
  columnDef: 'buyingCpp',
  columnType: 'number',
  group: { name: Column_Group_Costs.name },
  decimals: 2,
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.buyingCpp,
  sort: (row) => row.data?.buyingCppRaw,
};

export const Column_BaseCPM: TreeTableColumn = {
  header: 'Base CPM',
  tooltip:
    'Cost Per Thousand impressions/impacts achieved against the base audience, usually All Adults',
  columnDef: 'baseCpm',
  columnType: 'number',
  group: { name: Column_Group_Costs.name },
  decimals: 2,
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.baseCpm,
  sort: (row) => row.data?.baseCpmRaw,
};

export const Column_UnitCost: TreeTableColumn = {
  header: 'Unit cost',
  tooltip:
    'The cost of a single insertion/ use in a given publication/ media vehicle',
  columnDef: 'unitCost',
  columnType: 'number',
  group: { name: Column_Group_Costs.name },
  decimals: 2,
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.cost,
  sort: (row) => row.data?.costRaw,
};

export const Column_TotalCost: TreeTableColumn = {
  header: 'Total cost',
  tooltip:
    'The number of Impressions/ GRPs/ Uses multiplied by the Buying CPM/ CPP/ Unit Cost times the unit cost',
  columnDef: 'totalCost',
  columnType: 'number',
  group: { name: Column_Group_Costs.name },
  decimals: 2,
  menu: EDIT_MENU,
  editable: true,
  cell: (row) => row.data?.totalCost,
  sort: (row) => row.data?.totalCostRaw,
};

export const Column_EsgScore: TreeTableColumn = {
  header: 'ESG score',
  tooltip:
    'The ESG score shows how an organization is managing risks and opportunities related to environmental, social, and governance criteria.',
  columnDef: 'esgScore',
  columnType: 'number',
  decimals: 0,
  cell: (row) => row.data?.esgScore,
  sort: (row) => row.data?.esgScore,
};

// collection for all column groups
export const allColumnGroups: ColumnGroup[] = [
  Column_Group_PotentialReach,
  Column_Group_Audience,
  Column_Group_Survey,
  Column_Group_Duration,
  Column_Group_AddlReach,
  Column_Group_Addressable,
  Column_Group_DirectMail,
  Column_Group_Costs,
];

// collection of all columns. IMPORTANT: order should match excel definition
export const allColumns: TreeTableColumn[] = [
  Column_ReachPct,
  Column_Reach000,
  Column_BuyingGRPS,
  Column_BuyingImpressions,
  Column_FreqCapping,
  Column_AvgFreq,
  Column_EsgScore,
  Column_EffReach,
  Column_EffReachPct,
  Column_UniqueReachPct,
  Column_UniqueReach000,
  Column_GRPs,
  Column_Impressions,
  Column_Inserts,
  Column_Survey,
  Column_Duration,
  Column_PotentialReach000,
  Column_PotentialReachPct,
  Column_InsertsDM,
  Column_DurationDM,
  Column_NumberOfMailItems,
  Column_AudiencePct,
  Column_Audience000,
  Column_AudienceBasis,
  Column_CompositionIndex,
  Column_CompositionPct,
  // Column_AvgMinOnSite,
  Column_MediaType,
  Column_CPM,
  Column_CPP,
  Column_BuyingCPM,
  Column_BuyingCPP,
  Column_BaseCPM,
  Column_UnitCost,
  Column_TotalCost,
];

// object containing the 'columnDef' of every column that's editable, set to false.  Applied to totals only rows
export const READONLY_ROW = {};
allColumns
  .filter((c) => c.editable)
  .forEach((c) => (READONLY_ROW[c.columnDef] = false));

export const columnsThatRetriggerCalculation = [
  Column_EffReach,
  Column_EffReachPct,
  Column_UniqueReach000,
  Column_UniqueReachPct,
];
