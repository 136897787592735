<app-main>
  <div
    fxFill
    fxLayout="column"
  >
    <!-- show spinner after creating new campaign -->
    <mat-spinner
      *ngIf="initialising"
      style="position: fixed; top: 50%; left: 50%; z-index: 1000"
    ></mat-spinner>

    <!-- position="first" -->
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="top-section"
    >
      <div fxLayout="row">
        <div
          class="top-left-icon"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-icon
            class="button-icon-align-centre"
            color="primary"
          >
            live_tv
          </mat-icon>
        </div>
        <tup-mat-ichabods-tabs
          #ichabodsTab
          [ichabodsBody]="ichabodsBody"
        ></tup-mat-ichabods-tabs>
      </div>
      <div fxFlex></div>

      <!-- user info -->
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        data-tracking="user-name"
      >
        <div class="user-name">Hello {{ userName }}</div>
        <div
          class="user-initials"
          fxLayoutAlign="center center"
          fxFlex
        >
          {{ userInitials }}
        </div>
      </div>
    </div>

    <!-- tup-mat-content-wrapper position="middle" gap="5"  -->
    <div
      overflow="auto"
      fxFlex
      tupDocumentEventEmitter
      tupDocumentEventHandler
      class="tup-mat-content-wrapper--compact"
    >
      <mat-tab-group
        #ichabodsBody
        tupMatIchabodsBody
        fxFlex
        color="primary"
        class="header-less-tabs"
        style="padding: 0px 20px 0px 20px"
        [selectedIndex]="selectedIndex"
        (selectedIndexChange)="changeSelectedIndex($event)"
      >
        <mat-tab>
          <ng-template mat-tab-label>
            <span data-tracking="dashboard-tab">{{ navigationTabs[0] }}</span>
          </ng-template>
          <div
            fxLayout="column"
            fxLayoutGap="24px"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="16px"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="27px"
                data-tracking="dashboard-mat-cards"
              >
                <mat-card
                  class="total-documents-card"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <span>{{ campaignTable.dataSource.length || 0 }}</span>
                  {{
                    campaignTable.dataSource.length === 1
                      ? "campaign"
                      : "campaigns"
                  }}
                </mat-card>
                <mat-card
                  class="total-documents-card"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <span>{{ templateTable.dataSource.length || 0 }}</span>
                  {{
                    templateTable.dataSource.length === 1
                      ? "template"
                      : "templates"
                  }}
                </mat-card>
              </div>
              <div
                fxLayoutAlign="start center"
                fxLayoutGap="16px"
              >
                <file-upload
                  data-tracking="import-document-btn"
                  [disabled]="initialising"
                  (uploaded)="onFileUploaded($event)"
                  [text]="'Import campaign'"
                >
                </file-upload>

                <button
                  data-tracking="create-document-btn"
                  mat-flat-button
                  tupMatButton
                  class="documents-header-btn"
                  color="primary"
                  (click)="createNewCampaign()"
                  [disabled]="initialising"
                >
                  <mat-icon>add</mat-icon>
                  New campaign
                </button>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start stretch"
              fxLayoutGap="24px"
              data-tracking="quickLinks-container"
              class="images-container"
              *ngIf="quickLinks?.length > 0"
            >
              <div
                fxFlex="20"
                class="chart-container"
                *ngFor="
                  let quickLink of quickLinks | slice : 0 : 5;
                  let i = index
                "
                (click)="openExistingCampaign(quickLink)"
              >
                <div class="container-title">
                  <h3>{{ quickLink.metadata.name }}</h3>
                  <p>{{ quickLink.metadata.description }}</p>
                </div>
                <div>
                  <img
                    class="chart"
                    src="assets/images/dashboard_charts/{{ i }}.png"
                  />
                </div>
              </div>
            </div>
            <div
              fxFlex
              fxLayout="row"
              fxLayoutGap="24px"
              class="dashboard-tables-wrapper"
            >
              <div fxFlex="50">
                <dashboard-latest-campaigns
                  #myLatestCampaigns
                  (onSeeAllClick)="changeSelectedIndex($event)"
                  (onCreateNewCampaign)="createNewCampaign()"
                ></dashboard-latest-campaigns>
              </div>
              <div fxFlex="50">
                <dashboard-latest-tamplates
                  (onSeeAllClick)="changeSelectedIndex($event)"
                >
                </dashboard-latest-tamplates>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span data-tracking="campaigns-tab">{{ navigationTabs[1] }}</span>
          </ng-template>

          <documents-toolbar
            [initialising]="initialising"
            [dataSource]="campaignTable.dataSource"
            singleDocumentText="campaign"
            multipleDocumentsText="campaigns"
            searchLabel="Search campaigns"
            (onNewCampaignClick)="createNewCampaign()"
            (onImportFromLocalFileClick)="onFileUploaded($event)"
          ></documents-toolbar>
          <!-- <tup-mat-content-wrapper fxFlex gap="16" fxLayout="column" position="last"> -->
          <div
            class="doc-container own-tab"
            fxFlex
            fxLayout="column"
          >
            <tup-document-storage
              #campaignTable
              [options]="campaignTableOptions"
            >
              <!-- Content projection: inserts messaging / signposting indicating if / when there is no content to display -->
              <ng-template tupDocumentStorageCardNoContent>
                <p
                  class="mat-error"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <mat-icon fontSet="material-icons-outlined">warning</mat-icon>
                  <span>No campaigns yet</span>
                </p>
              </ng-template>

              <!-- Content projection: inserts a custom card 'action' after the table, in the card footer -->
              <ng-template tupDocumentStorageCardActions>
                <!-- placeholder, nothing to see here -->
              </ng-template>
            </tup-document-storage>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <span data-tracking="templates-tab">{{ navigationTabs[2] }}</span>
          </ng-template>

          <documents-toolbar
            [initialising]="initialising"
            [dataSource]="templateTable.dataSource"
            singleDocumentText="template"
            multipleDocumentsText="templates"
            searchLabel="Search templates"
            (onNewCampaignClick)="createNewCampaign()"
          ></documents-toolbar>

          <!-- <tup-mat-content-wrapper fxFlex gap="0" fxLayout="column" position="last"> -->
          <div
            class="doc-container own-tab"
            fxFlex
            fxLayout="column"
          >
            <tup-document-storage
              #templateTable
              [options]="templateTableOptions"
            >
            </tup-document-storage>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</app-main>
