import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TargetVehicle } from '../../../../classes/vehicle';
import { MediaPlannerService } from '../../../../services/media-planner.service';

@Component({
  selector: 'manual-entry',
  templateUrl: './manual-entry.component.html',
  styleUrls: ['./manual-entry.component.scss'],
})
export class ManualEntryComponent implements OnInit, AfterViewInit {
  @Input() multiSurveyId: string;
  @Input() scheduleIndex: number;
  @ViewChild('manualInputTitleRef') manualInputTitleRef: ElementRef;

  @Output() duplicateTitle: EventEmitter<string> = new EventEmitter<string>();

  private inputSubject = new Subject<string>();
  manualMediaTitle: string;
  manualReachPct: number;
  manualGRPs: number;
  manualAvgFreq: number;
  manualInserts: number;
  manualTotalCost: number;
  duplicateManualTitle: string = '';
  maxReachExceededMessage: string = '';

  constructor(private mediaplannerService: MediaPlannerService) {}

  ngOnInit(): void {
    if (this.multiSurveyId) {
      const target = this.mediaplannerService.plan.targets[0];
      const manualEntry = this.mediaplannerService.plan.schedules[
        this.scheduleIndex
      ].vehicle(target, this.multiSurveyId);
      this.manualMediaTitle = target.vehicle(this.multiSurveyId).title;
      this.manualReachPct = Math.round(manualEntry.result.reachPct);
      this.manualGRPs = Math.round(manualEntry.result.grps);
      this.manualAvgFreq = Math.round(manualEntry.result.avgFrequency);
      this.manualInserts = Math.round(manualEntry.result.inserts);
      this.manualTotalCost = manualEntry.result.totalCost;
    }
    this.checkManualTitleDuplicate();
  }

  ngAfterViewInit() {}

  onKeyUp() {
    this.inputSubject.next(this.manualMediaTitle);
  }

  onReachInputChange(event: any) {
    const errorMessage = `Maximum reach % value can't be higher than 100`;
    const parsedValue = parseFloat(event.target.value.replace(/[^0-9.]/g, ''));

    this.maxReachExceededMessage = parsedValue > 100 ? errorMessage : '';
    this.manualReachPct = !isNaN(parsedValue) ? parsedValue : null;
  }

  private parseFloat(value: number) {
    return parseFloat(value.toFixed(2));
  }

  onRFInputBlur(input: string = '', value: number) {
    if (value) {
      switch (input) {
        case 'reach':
          if (this.manualGRPs)
            this.manualAvgFreq = this.parseFloat(
              this.manualGRPs / this.manualReachPct
            );
          else if (this.manualAvgFreq)
            this.manualGRPs = this.parseFloat(
              this.manualReachPct * this.manualAvgFreq
            );
          break;
        case 'grp':
          if (this.manualReachPct)
            this.manualAvgFreq = this.parseFloat(
              this.manualGRPs / this.manualReachPct
            );
          else if (this.manualAvgFreq)
            this.manualReachPct = this.parseFloat(
              this.manualGRPs / this.manualAvgFreq
            );
          break;
        case 'avgFreq':
          if (this.manualReachPct)
            this.manualGRPs = this.parseFloat(
              this.manualReachPct * this.manualAvgFreq
            );
          else if (this.manualGRPs)
            this.manualReachPct = this.parseFloat(
              this.manualGRPs / this.manualAvgFreq
            );

          break;
      }
    }
  }

  private checkManualTitleDuplicate() {
    this.inputSubject.pipe(debounceTime(300)).subscribe((title) => {
      let vehicles: TargetVehicle[] = [];
      if (this.multiSurveyId) {
        // when editing, omit checking edited item title
        vehicles = this.mediaplannerService.plan.targets[0].vehicles.filter(
          (vehicle) =>
            vehicle.multiSurveyConfig &&
            vehicle.multiSurveyConfig.multiSurveyId !== this.multiSurveyId
        );
      } else {
        vehicles = this.mediaplannerService.plan.targets[0].vehicles;
      }
      this.duplicateManualTitle = vehicles.some(
        (vehicle) => vehicle.title.toLowerCase() === title.toLowerCase().trim()
      )
        ? title
        : '';
      this.duplicateTitle.emit(this.duplicateManualTitle);
    });
  }
}
