import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'number-incremental',
  templateUrl: './number-incremental.component.html',
  styleUrls: ['./number-incremental.component.scss'],
})
export class NumberIncrementalComponent implements OnInit {
  @Input() value: number;
  @Input() suffix?: string;
  @Input() max?: number;
  @Input() min?: number;

  @Output() valueChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  onChange(value: number) {
    this.updateValue(value);
  }

  updateValue(newValue: number) {
    if (newValue === undefined || newValue === null) {
      this.value = newValue;
    } else if (this.value < newValue) {
      if (this.max !== undefined) {
        if (this.min && this.min >= newValue) {
          this.value = this.min;
        } else if (newValue <= this.max) {
          this.value = newValue;
        } else {
          this.value = this.max;
        }
      } else {
        this.value = newValue;
      }
    } else {
      if (this.min !== undefined) {
        if (this.max && this.max <= newValue) {
          this.value = this.max;
        } else if (newValue >= this.min) {
          this.value = newValue;
        } else {
          this.value = this.min;
        }
      } else {
        this.value = newValue;
      }
    }
    this.valueChange.emit(this.value);
  }
}
