import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { UserAction } from '@telmar-global/tup-auth';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { StateService } from 'src/app/services/state.service';
import { environment } from 'src/environments/environment';
import { version } from '../../../../package.json';
import { SideNavRoute } from '@telmar-global/tup-auth/lib/tup-side-nav/tup-side-nav.component';
import { APPLICATION_NAME } from 'src/app/models/document.model';
import { MatSelect } from '@angular/material/select';
import { UNSAVED_DOCUMENT } from 'src/app/models/document.model';
import { GAEvents } from '../../models/analytics.model';
import { InternetConnectionService } from 'src/app/services/internet-connection.service';
import { Subscription } from 'rxjs';

const appsOptions = [
  { id: 'explore_dashboard', label: 'My Explore dashboard', icon: 'bar_chart' },
  { id: 'plan_dashboard', label: 'My Plan dashboard', icon: 'live_tv' },
];

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  sidebarOpened: boolean;
  appVersion: string;
  isOnline: boolean = true;
  onlineStatusSubscription: Subscription;

  docRoutesConfig: SideNavRoute[];
  dashboardRouteConfig: SideNavRoute;
  appsOptions;

  @ViewChild(MatSelect) matSelect: MatSelect;

  constructor(
    private dialogService: DialogService,
    private mediaplannerService: MediaPlannerService,
    private userMessage: TupUserMessageService,
    private stateService: StateService,
    private router: Router,
    private analyticsService: TupAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private internetConnectionService: InternetConnectionService
  ) {
    this.buildNavConfig();
    this.appsOptions = appsOptions;
  }

  ngOnInit() {
    this.sidebarOpened = false; // this.stateService.state$.getValue().isSidebarOpen;
    this.appVersion = version;
    this.onlineStatusSubscription = this.internetConnectionService
      .isOnline()
      .subscribe((onlineStatus) => {
        if (onlineStatus !== this.isOnline) {
          if (onlineStatus) {
            this.internetConnectionService.showOnlineDialog();
          } else {
            this.internetConnectionService.showOfflineDialog();
          }
          this.isOnline = onlineStatus;
        }
      });
  }

  ngOnDestroy() {
    if (this.onlineStatusSubscription) {
      this.onlineStatusSubscription.unsubscribe();
    }
  }

  onToggleSidePanel(value: boolean) {
    this.stateService.state$.next({
      isSidebarOpen: value,
    });
    this.sidebarOpened = value;
  }

  onLogoClick() {
    const title = 'Return to platform home';
    const message =
      'Are you sure you want to leave Plan and return to platform home?';

    this.userMessage
      .openDialog(message, title)
      .afterClosed()
      .subscribe((yes) => {
        if (yes) {
          this.analyticsService.e(GAEvents.platform, { action: 'platform' });
          window.open(environment.appOptions.platformHomepage, '_self');
        }
      });
  }

  getHelpInfo() {
    this.analyticsService.e(GAEvents.help, { action: 'help_section' });
    this.dialogService.getHelpInfo();
  }

  onUserAction(event: UserAction) {
    if (
      event == UserAction.changePassword ||
      event == UserAction.changePasswordFailed
    ) {
      this.analyticsService.e(GAEvents.custom_user_engagement, {
        action: event,
      });
    }
  }

  private buildNavConfig(): void {
    let dataUrl: string = '';
    let chartUrl: string = '';

    const param = this.activatedRoute.snapshot.params['document'];
    if (param === undefined) {
      dataUrl = '/new/data';
      chartUrl = '/new/chart';
    } else {
      dataUrl = `/edit/${param}/data`;
      chartUrl = `/edit/${param}/chart`;
    }

    this.dashboardRouteConfig = {
      url: '/documents',
      attributeData: 'dashboard-btn',
      tooltip: 'Campaigns',
      matIcon: 'live_tv',
      label: `My ${APPLICATION_NAME} dashboard`,
    };

    this.docRoutesConfig = [
      {
        url: dataUrl,
        queryParams: {
          tab: 0,
        },
        attributeData: `data-btn`,
        label: 'Data',
        tooltip: 'Data',
        matIcon: `table_chart`,
        children: [
          {
            url: dataUrl,
            label: 'Audience',
            tooltip: 'Audience',
            attributeData: 'audience-btn',
            queryParams: { tab: 0 },
          },
          {
            url: dataUrl,
            label: 'Media',
            tooltip: 'Media',
            attributeData: 'media-btn',
            queryParams: { tab: 1 },
          },
          {
            url: dataUrl,
            label: 'Plan',
            tooltip: 'Plan',
            attributeData: 'plan-btn',
            queryParams: { tab: 2 },
          },
        ],
      },
      {
        url: chartUrl,
        attributeData: `visualisation-btn`,
        tooltip: 'Visualisation',
        matIcon: `pie_chart`,
        label: 'Visualisation',
        children: [
          {
            url: chartUrl,
            label: 'Charts',
            tooltip: 'Charts',
            attributeData: 'charts-btn',
            queryParams: { tab: 4 },
          },
          {
            url: chartUrl,
            label: 'Frequency distribution',
            tooltip: 'Frequency distribution',
            attributeData: 'frequency-distribution-btn',
            queryParams: { tab: 5 },
          },
        ],
      },
    ];
  }
}
