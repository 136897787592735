import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MediaPlannerService } from '../services/media-planner.service';
import { UNSAVED_DOCUMENT } from '../models/document.model';

@Injectable({
  providedIn: 'root',
})
export class TabsGuard implements CanActivate {
  steps: string[] = ['audience', 'media', 'planning', 'spots'];

  constructor(
    private router: Router,
    private mediaplannerService: MediaPlannerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const queryParams = route.queryParams;
    const tab = Number(queryParams.tab);

    let campaignStageIndex = this.steps.indexOf(
      this.mediaplannerService.plan.getCampaignStage()
    );

    let tabReadyIndex = this.steps.indexOf(
      this.mediaplannerService.plan.tabReady
    );

    //If we're cleared to use planning, but requesting to jump to Spotplans,
    //as spotplan isnt part of the required steps, we need an additional conditon
    if (tab === 3 && tabReadyIndex === 2) {
      campaignStageIndex = 3;
    }

    if (!this.mediaplannerService.plan.tabReady) {
      return true;
    }
    if (tab > tabReadyIndex) {
      const currentUrl = route.pathFromRoot
        .map((v) => v.url.map((segment) => segment.toString()).join('/'))
        .join('/');

      if (currentUrl.includes('/new/data')) {
        this.router.navigate(['/new/data'], {
          queryParams: {
            tab: campaignStageIndex,
          },
        });
      } else {
        const document = route.parent.params.document;
        this.router.navigate([`/edit/${document}/data`], {
          queryParams: {
            tab: campaignStageIndex,
          },
        });
      }
      return false;
    } else {
      return true;
    }
  }
}
