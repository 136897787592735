<div class="manage-audience-metrics-dialog multiple-tabs-dialog">
  <tup-mat-title-bar title="Manage audience metrics">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>
  <snackbar-generic
    class="metric-column-dialog-error"
    *ngIf="noMetricSelected"
    [data]="unselectedError"
  >
  </snackbar-generic>
  <mat-tab-group
    mat-stretch-tabs
    animationDuration="0ms"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <span data-tracking="metrics-select-label"> Select </span>
      </ng-template>
      <div class="tab-container">
        <p class="column-item">
          <mat-checkbox
            id="select-all"
            color="primary"
            data-tracking="metric-select-all"
            [checked]="allSelected"
            (change)="onSelectAll($event)"
          >
            Select All
          </mat-checkbox>
        </p>
        <div *ngFor="let item of filters">
          <p class="column-item">
            <mat-checkbox
              [attr.data-tracking]="'checkbox-' + item.columnDef"
              color="primary"
              [checked]="item.visible"
              (change)="onColumnChanged($event, item)"
              [disabled]="item.disabled"
            >
              {{ item | appendUnits : unitsText }}
            </mat-checkbox>
            <mat-icon
              *ngIf="item.tooltip"
              [matTooltip]="item.tooltip"
              >help_outline</mat-icon
            >
          </p>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span data-tracking="metrics-reorder-label"> Reorder </span>
      </ng-template>
      <div
        class="tab-container reorder"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <div
          *ngFor="let item of reorderFilters"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          cdkDrag
          class="drag-item"
          [attr.data-tracking]="item.columnDef"
        >
          {{ item | appendUnits : unitsText }}
          <mat-icon>reorder</mat-icon>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span data-tracking="metrics-sort-label"> Sort </span>
      </ng-template>
      <div
        class="tab-container"
        fxFlex
        fxLayout="column"
        fxLayoutGap="8px"
      >
        <mat-form-field
          appearance="outline"
          class="regular-select full-width sort-select"
          tupMatFormField
        >
          <mat-label>Sorting by:</mat-label>
          <mat-select
            [(value)]="sortValue"
            panelClass="sort-select-panel sort"
            data-tracking="sort-by-select"
          >
            <mat-select-trigger>
              {{ selectedSortValue }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let item of filters"
              [value]="item.columnDef"
            >
              {{ item | appendUnits : unitsText }}
              <mat-icon *ngIf="item.columnDef == sortValue">check</mat-icon>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="regular-select full-width sort-select"
          tupMatFormField
        >
          <mat-label>Audience:</mat-label>
          <mat-select
            [(value)]="column"
            panelClass="sort-select-panel audience"
            data-tracking="audience-select"
          >
            <mat-select-trigger>
              {{ selectedColumn }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let item of columns"
              [value]="item.columnDef"
            >
              {{ item.title }}
              <mat-icon *ngIf="item.columnDef == column">check</mat-icon>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="regular-select full-width sort-select"
          tupMatFormField
        >
          <mat-label>Order:</mat-label>
          <mat-select
            [(ngModel)]="orderValue"
            panelClass="sort-select-panel order"
          >
            <mat-select-trigger>
              {{ selectedOrderValues }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let item of orderValues"
              [value]="item.value"
            >
              {{ item.title }}
              <mat-icon *ngIf="item.value == orderValue">check</mat-icon>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    fxLayoutGap="16px"
    class="actions-wrapper"
  >
    <button
      tupMatButton
      mat-flat-button
      data-tracking="metrics-cancel-btn"
      (click)="onClose()"
    >
      Cancel
    </button>
    <div
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayoutAlign="end start"
    >
      <button
        tupMatButton
        mat-flat-button
        (click)="resetToDefault()"
        data-tracking="metrics-reset-btn"
      >
        Reset to default
      </button>
      <button
        mat-flat-button
        tupMatButton
        color="primary"
        [disabled]="noMetricSelected"
        data-tracking="metrics-save-btn"
        (click)="save()"
      >
        Save changes
      </button>
    </div>
  </div>
</div>
