import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CountCodingModel,
  DisplayMode,
  DisplayType,
  DropDataContext,
  Operator,
  Statement,
  StatementFactory,
  VisualCodingTarget,
} from '../../models/visual-code-builder.models';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import {
  CodebookSelectionService,
  LoadingNode,
  LoadingSource,
} from '@telmar-global/tup-audience-groups';
import { TargetService } from 'src/app/services/target.service';

@Component({
  selector: 'visual-code-builder',
  templateUrl: './visual-code-builder.component.html',
  styleUrls: ['./visual-code-builder.component.scss'],
})
export class VisualCodeBuilderComponent
  implements OnInit, OnChanges, OnDestroy
{
  public readonly menuItemDragoverClass = 'drag-over-menu-button';

  public displayText = '';
  public displayModeType: typeof DisplayMode = DisplayMode;
  public displayMode = DisplayMode.title;

  public operator: typeof Operator = Operator;

  @Input() target: VisualCodingTarget;
  @Input() layout: 'compact' | 'full' = 'compact';
  @Input() dropChipWidth: string = '800px';
  @Output() targetChange = new EventEmitter<VisualCodingTarget>();
  @Output() dropNode = new EventEmitter<DropDataContext<Operator>>();
  @Output() manualCodingClick = new EventEmitter<VisualCodingTarget>();
  @Output() newManualCodingClick = new EventEmitter<VisualCodingTarget>();
  @Output() cleanupVCBDataAfterDestroy = new EventEmitter<any>();

  public rootTarget: VisualCodingTarget;
  private rootTitleMode: DisplayType;
  public targets: VisualCodingTarget[] = [];

  public targetOperators: Operator[] = [
    Operator.and,
    Operator.or,
    Operator.andNot,
    Operator.orNot,
    Operator.plus,
    Operator.lessThan,
    Operator.lessThanOrEqual,
    Operator.greaterThan,
    Operator.greaterThanOrEqual,
    Operator.equal,
    Operator.notEqual,
    Operator.range,
  ];

  public dropZoneMenuItems: Operator[] = [
    Operator.count,
    Operator.auto,
    Operator.and,
    Operator.or,
    Operator.andNot,
    Operator.plus,
  ];

  private selectedNodes$: Subscription;
  private selectedNodes: Statement[] = [];

  private loadingSelectedNodes$: Subscription;
  public isLoadingSelectedNodes = false;
  public isLoadingFromDrag = false;

  private dropTarget: VisualCodingTarget;
  private manualTargetEditing: VisualCodingTarget;
  private dropRowIndex: number;

  private chipOverIntentCount = 0;

  private menuTarget: EventTarget;
  public isDraggingTarget = false;
  public draggingTarget: {
    parentTarget: VisualCodingTarget;
    target: VisualCodingTarget;
    rowIndex: number;
    columnIndex: number;
    expand: boolean;
  };

  private isOverOperatorMenu = false;
  private isDropzoneMenuOpen = false;
  private activeMenuTrigger: MatMenuTrigger;

  private changesFromWithin = false;

  constructor(
    private targetService: TargetService,
    private codebookSelectionService: CodebookSelectionService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.listenToSelectedNodesChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.target && changes.target.currentValue) {
      this.buildTargetList();
      this.updateDisplayText();
      this.changesFromWithin = false;
    }
  }

  ngOnDestroy(): void {
    this.isLoadingSelectedNodes = false;
    this.loadingSelectedNodes$.unsubscribe();
    this.selectedNodes$.unsubscribe();
    this.cleanupVCBDataAfterDestroy.emit();
  }

  public addStatements(newStatements: Statement[], groupStatements: boolean) {
    if (groupStatements) {
      const childTarget: VisualCodingTarget[] = [];
      const statementsGroupedByCategory =
        this.groupNodesByCategory(newStatements);
      statementsGroupedByCategory.forEach((categoryGroup) => {
        const targets =
          this.targetService.convertStatementsToTargets(categoryGroup);
        const grouped = this.targetService.groupTargets(targets, Operator.or);
        if (grouped[0].targets.length === 0) {
          grouped[0].coding = `(${grouped[0].coding})`;
        }
        childTarget.push(grouped[0]);
      });

      const finalGroup = this.targetService.groupTargets(
        childTarget,
        Operator.and
      );
      this.targets[0].targets.push(finalGroup[0]);
    } else {
      const newTargets =
        this.targetService.convertStatementsToTargets(newStatements);
      this.targets[0].targets.push(...newTargets);
    }

    this.updateTargetChange();
  }

  public addStatementsWithCountCoding(
    newStatements: Statement[],
    countCoding: CountCodingModel
  ) {
    const targets =
      this.targetService.convertStatementsToTargets(newStatements);
    const groupedTargets = this.targetService.groupTargets(
      targets,
      Operator.plus
    );
    groupedTargets[0].targets = cloneDeep(groupedTargets);

    const countTarget = this.targetService.createTarget({
      title: countCoding.value,
      ownTitle: countCoding.value,
      operator: Operator.and,
      coding: countCoding.value,
    });

    groupedTargets[0].targets.push(countTarget);
    const finalGroup = this.targetService.groupTargets(
      groupedTargets[0].targets,
      countCoding.operator
    );

    this.targets[0].targets.push(finalGroup[0]);
    this.updateTargetChange();
  }

  public addStatementsWithBooleanOperator(
    newStatements: Statement[],
    booleanOperator: Operator
  ) {
    const childTarget: VisualCodingTarget[] = [];
    const targets =
      this.targetService.convertStatementsToTargets(newStatements);
    const grouped = this.targetService.groupTargets(targets, booleanOperator);
    if (grouped[0].targets.length === 0) {
      grouped[0].coding = `(${grouped[0].coding})`;
    }
    childTarget.push(grouped[0]);

    const finalGroup = this.targetService.groupTargets(
      childTarget,
      booleanOperator
    );

    this.targets[0].targets.push(finalGroup[0]);
    this.updateTargetChange();
  }

  addManuallyEditedTarget(parentTarget, addTarget: VisualCodingTarget) {
    const tgts = [];
    tgts.push(this.findTarget(this.rootTarget, parentTarget.id));
    tgts.push(this.findTarget(this.target, parentTarget.id));

    tgts
      .filter((tgt) => tgt)
      .forEach((tgt) => {
        tgt.targets.push(cloneDeep(addTarget));
      });
    this.updateTargetChange();
  }

  updateManuallyEditedTarget(editedTarget: VisualCodingTarget) {
    if (this.manualTargetEditing) {
      this.manualTargetEditing.coding = editedTarget.coding;
      this.manualTargetEditing.title = editedTarget.title;
      this.manualTargetEditing.ownTitle = editedTarget.title;
      this.manualTargetEditing.shortTitle = editedTarget.title;
    }
    this.updateTargetChange();
  }

  private findTarget(
    target: VisualCodingTarget,
    id: string
  ): VisualCodingTarget {
    if (!target) return undefined;
    if (target.id === id) return target;

    for (let tgt of target.targets || []) {
      if (tgt.id === id) return tgt;
      if (tgt.targets) return this.findTarget(tgt, id);
    }
    return undefined;
  }

  public setDisplayMode(mode: DisplayMode): void {
    if (this.displayMode !== mode) {
      this.displayMode = mode;
      this.updateDisplayText();
    }
  }

  public isChipRemovable(
    parentTarget: VisualCodingTarget,
    rowIndex: number
  ): boolean {
    return rowIndex !== 0 || parentTarget.targets.length > 1;
  }

  public formatChipTooltip(target: VisualCodingTarget): string {
    return this.draggingTarget ? '' : target.title;
  }

  public isChipDroppable(
    parentTarget: VisualCodingTarget,
    target: VisualCodingTarget,
    rowIndex: number
  ): boolean {
    if (this.isLoadingSelectedNodes) {
      return false;
    }

    if (!this.draggingTarget) {
      return true;
    }

    if (
      this.draggingTarget.target === target ||
      this.draggingTarget.target === parentTarget
    ) {
      return false;
    }

    return !(
      this.draggingTarget.expand && this.draggingTarget.rowIndex < rowIndex
    );
  }

  public isEmptyChipDroppable(
    parentTarget: VisualCodingTarget,
    rowIndex: number
  ): boolean {
    if (this.isLoadingSelectedNodes) {
      return false;
    }

    if (!this.draggingTarget) {
      return true;
    }

    if (this.draggingTarget.target === parentTarget) {
      return false;
    }

    return !(
      rowIndex === 0 &&
      this.draggingTarget.rowIndex === 0 &&
      parentTarget.targets.length < 2
    );
  }

  public formatChipText(target: VisualCodingTarget) {
    return this.displayMode === DisplayMode.title
      ? target[this.rootTitleMode]
      : target.coding;
  }

  public onManualCodingClick(
    parentTarget: VisualCodingTarget,
    rowIndex: number
  ) {
    this.newManualCodingClick.emit(parentTarget);
  }

  public onTargetClick(target: VisualCodingTarget, rowIndex: number): void {
    if (target.manual) {
      this.manualTargetEditing = target;
      this.manualCodingClick.emit(target);
    }

    if (!this.isTargetExpandable(target)) {
      return;
    }

    const isCurrentTargetExpanded = this.isTargetExpanded(target, rowIndex);
    // the expanded children targets may be from different parent target
    if (this.hasExpandedTarget(rowIndex)) {
      this.collapseChildrenTargets(rowIndex);
    }

    if (!isCurrentTargetExpanded) {
      this.expandTarget(target);
    }
  }

  public onDrop(operator?: Operator) {
    if (this.isLoadingSelectedNodes) {
      return;
    }

    this.dropNode.emit({
      selectedNodes: this.selectedNodes,
      context: operator,
      handleDropNode: (dropOperator: Operator) =>
        this.handleDropNode(dropOperator),
    });
  }

  public handleDropNode(operator?: Operator) {
    const selectedOperator =
      operator === Operator.auto ? Operator.or : operator;
    if (selectedOperator !== Operator.count) {
      this.handleDrop(selectedOperator);
    }
  }

  public onRemoveTarget(
    parentTarget: VisualCodingTarget,
    target: VisualCodingTarget,
    rowIndex: number,
    columnIndex: number,
    shouldRemoveFromParent = true
  ): void {
    this.handleRemoveTarget(
      parentTarget,
      target,
      rowIndex,
      columnIndex,
      shouldRemoveFromParent
    );
    this.updateTargetChange();
  }

  public onRemoveCountCoding(target: VisualCodingTarget): void {
    target.countCoding = undefined;
    this.updateTargetChange();
  }

  public isTargetExpanded(
    target: VisualCodingTarget,
    rowIndex: number
  ): boolean {
    return (
      this.hasExpandedTarget(rowIndex) && this.targets[rowIndex + 1] === target
    );
  }

  public onTargetOperatorChange() {
    this.updateTargetChange();
  }

  public onChipEnter(
    dropTarget: VisualCodingTarget,
    rowIndex: number,
    trigger: MatMenuTrigger,
    dropZoneChip: HTMLElement
  ): void {
    this.chipOverIntentCount++;
    this.dropTarget = dropTarget;
    this.dropRowIndex = rowIndex;
    setTimeout(() => {
      if (
        this.chipOverIntentCount === 0 ||
        this.activeMenuTrigger === trigger ||
        !this.shouldShowOperatorMenu()
      ) {
        return;
      }
      if (
        this.activeMenuTrigger &&
        (this.isDropzoneMenuOpen || this.chipOverIntentCount > 1)
      ) {
        this.closeOperatorMenu();
      }
      this.activeMenuTrigger = trigger;
      this.isDropzoneMenuOpen = true;
      trigger.openMenu();
      // need to focus on something else, otherwise the first item of the menu gets focused for some reason
      dropZoneChip.focus();
    }, 300);
  }

  public onChipLeave(): void {
    this.chipOverIntentCount--;
    setTimeout(() => {
      if (this.shouldShowOperatorMenu()) {
        this.closeOperatorMenu();
      }
    }, 200);
  }

  public onOperatorMenuEnter(event: DragEvent): void {
    this.menuTarget = event.currentTarget;
    this.isOverOperatorMenu = true;
  }

  public onOperatorMenuLeave(event: DragEvent): void {
    const target = event.currentTarget;
    // note: dndDragoverClass doesn't always get removed after leaving the target
    if (target && target instanceof Element) {
      target.classList.remove(this.menuItemDragoverClass);
    }
    if (this.menuTarget === target) {
      this.isOverOperatorMenu = false;
      this.closeOperatorMenu();
    }
  }

  public onDragStart(
    parentTarget: VisualCodingTarget,
    target: VisualCodingTarget,
    rowIndex: number,
    columnIndex: number
  ): void {
    this.isDraggingTarget = true;
    this.draggingTarget = {
      parentTarget,
      target,
      rowIndex,
      columnIndex,
      expand: this.isTargetExpanded(target, rowIndex),
    };
  }

  public onDragEnd(): void {
    this.isDraggingTarget = false;
    this.draggingTarget = null;
  }

  public isTargetExpandable(target: VisualCodingTarget): boolean {
    const numberOfChildren = target.targets.length;
    const hasChildrenTargets = numberOfChildren > 0;
    if (!hasChildrenTargets) {
      return false;
    }

    return !this.hasUneditableChildTarget(target);
  }

  private listenToSelectedNodesChanges(): void {
    this.loadingSelectedNodes$ =
      this.codebookSelectionService.loadingSelectedNodes$.subscribe(
        (isLoading: LoadingNode) => {
          this.isLoadingSelectedNodes = isLoading.inProgress;
          this.isLoadingFromDrag = isLoading.origin === LoadingSource.fromDrag;
        }
      );

    this.selectedNodes$ =
      this.codebookSelectionService.selectedNodes$.subscribe(
        (selectedNodes: Statement[]) => {
          this.selectedNodes = selectedNodes;
        }
      );
  }

  private buildTargetList(): void {
    const sameTarget =
      this.targets.length > 1 && this.targets[0].id === this.target.id;
    if (this.changesFromWithin && sameTarget) {
      return;
    }
    this.targets = [cloneDeep(this.target)];
    this.rootTarget = this.targets[0];
    this.rootTitleMode = this.rootTarget.activeTitleMode ?? DisplayType.title;
  }

  private updateDisplayText(): void {
    this.displayText =
      this.displayMode === DisplayMode.title
        ? this.rootTarget.ownTitle
        : this.rootTarget.coding;
  }

  private handleDrop(operator: Operator): void {
    //countCoding?: CountCodingModel
    const lastOperator: Operator = this.dropTarget?.targets.length
      ? (this.dropTarget.targets[this.dropTarget.targets.length - 1]
          ?.operator as Operator)
      : Operator.and;
    let targets: VisualCodingTarget[] = this.formatDraggedTargets(
      operator,
      lastOperator
    );

    const isDroppedToParentTarget =
      this.dropTarget === this.targets[this.dropRowIndex];
    // when a none-root node has no children nodes, reuse the node but add itself to the children targets
    if (!isDroppedToParentTarget && this.dropTarget.targets.length === 0) {
      this.dropTarget.targets.push(cloneDeep(this.dropTarget));
    }

    // to handle SOLUS operator exception
    if (this.hasUneditableChildTarget(this.dropTarget)) {
      this.dropTarget.targets[0] = cloneDeep(this.dropTarget);
    }

    this.dropTarget.targets.push(...targets);

    this.targetService.updateTitleAndCoding(this.dropTarget);
    if (
      !isDroppedToParentTarget &&
      this.targets[this.dropRowIndex] !== undefined
    ) {
      this.targetService.updateTitleAndCoding(this.targets[this.dropRowIndex]);
    }
    this.unsetDraggingState();
    this.updateTargetChange();
  }

  private handleRemoveTarget(
    parentTarget: VisualCodingTarget,
    target: VisualCodingTarget,
    rowIndex: number,
    columnIndex: number,
    shouldRemoveFromParent = true
  ): void {
    const onlyOneChildTargetLeft = parentTarget.targets.length === 1;
    if (onlyOneChildTargetLeft) {
      const parentRowIndex = rowIndex - 1;
      this.handleRemoveTarget(
        this.targets[parentRowIndex],
        parentTarget,
        parentRowIndex,
        this.targets[parentRowIndex].targets.indexOf(parentTarget),
        shouldRemoveFromParent
      );
    } else {
      if (this.isTargetExpanded(target, rowIndex)) {
        this.collapseChildrenTargets(rowIndex);
      }
      parentTarget.targets.splice(columnIndex, 1);

      if (shouldRemoveFromParent) {
        this.removeLastChildTargetFromParent(parentTarget, rowIndex);
      }

      if (parentTarget.targets.length === 1) {
        parentTarget.targets[0].operator = Operator.and;
      }
    }
  }

  private removeLastChildTargetFromParent(
    parentTarget: VisualCodingTarget,
    rowIndex: number
  ): void {
    const onlyOneChildTargetLeft = parentTarget.targets.length === 1;
    const hasCountCoding = false;
    const isRootRow = rowIndex === 0;

    if (onlyOneChildTargetLeft && !hasCountCoding && !isRootRow) {
      parentTarget = Object.assign(parentTarget, parentTarget.targets[0]);

      const parentRowIndex = rowIndex - 1;
      this.collapseChildrenTargets(parentRowIndex);
      this.removeLastChildTargetFromParent(
        this.targets[parentRowIndex],
        parentRowIndex
      );
    }
  }

  private groupNodesByCategory(nodes: Statement[]): Statement[][] {
    const groupedArrays = [];

    nodes.forEach((node) => {
      const category = node.category;
      const existingArray = groupedArrays.find(
        (arr) => arr[0]?.category === category
      );

      if (existingArray) {
        existingArray.push(node);
      } else {
        groupedArrays.push([node]);
      }
    });

    return groupedArrays;
  }

  private formatDraggedTargets(
    operator: Operator,
    lastOperator: Operator
  ): VisualCodingTarget[] {
    let targets: VisualCodingTarget[];
    if (this.isDraggingTarget) {
      this.handleRemoveTarget(
        this.draggingTarget.parentTarget,
        this.draggingTarget.target,
        this.draggingTarget.rowIndex,
        this.draggingTarget.columnIndex,
        this.draggingTarget.rowIndex !== this.dropRowIndex
      );
      targets = [this.draggingTarget.target];
    } else {
      const selectedOperator =
        operator === Operator.count ? Operator.plus : operator;
      const multipleCategoriesTargets: VisualCodingTarget[] = [];
      const groupsOfDifferentCategories = this.groupNodesByCategory(
        this.selectedNodes
      );
      groupsOfDifferentCategories.forEach((group) => {
        const groupSelectedTargets =
          this.targetService.convertStatementsToTargets(group);
        //create group with OR between elements for audiences from the same category
        const groupTargets = this.targetService.groupTargets(
          groupSelectedTargets,
          selectedOperator
        );

        if (groupTargets[0].targets.length === 0) {
          groupTargets[0].coding = `(${groupTargets[0].coding})`;
        }

        multipleCategoriesTargets.push(groupTargets[0]);
      });

      //create group with AND between groups
      targets = this.targetService.groupTargets(
        multipleCategoriesTargets,
        Operator.and
      );

      targets[0].operator = lastOperator;
    }
    return targets;
  }

  private shouldShowOperatorMenu(): boolean {
    return (
      !this.isDraggingTarget &&
      (this.targetService.convertStatementsToTargets(this.selectedNodes)
        .length > 1 ||
        StatementFactory.getMissingNodes(this.selectedNodes).length > 0)
    );
  }

  private unsetDraggingState(): void {
    this.isDraggingTarget = false;
    this.draggingTarget = null;
    this.isOverOperatorMenu = false;
    this.chipOverIntentCount = 0;
    this.closeOperatorMenu();
    this.codebookSelectionService.unselectNodes();
  }

  private closeOperatorMenu(): void {
    if (this.isDropzoneMenuOpen && !this.isOverOperatorMenu) {
      this.isDropzoneMenuOpen = false;
      this.activeMenuTrigger.closeMenu();
      this.activeMenuTrigger = null;
    }
  }

  private hasExpandedTarget(rowIndex: number): boolean {
    return this.targets[rowIndex + 1] !== undefined;
  }

  private collapseChildrenTargets(rowIndex: number): void {
    while (this.targets.length > 1 && this.targets.length !== rowIndex + 1) {
      this.targets.pop();
    }
  }

  private expandTarget(target: VisualCodingTarget): void {
    this.targets.push(target);
  }

  private updateTargetChange(): void {
    this.updateTargetListWithNewTitleAndCodingStatement();
    this.emitTargetChange();
  }

  private emitTargetChange(): void {
    this.changesFromWithin = true;
    this.targetChange.emit(this.rootTarget);
  }

  private updateTargetListWithNewTitleAndCodingStatement(): void {
    for (let i = this.targets.length - 1; i >= 0; i--) {
      this.targetService.updateTitleAndCoding(this.targets[i]);
    }
    this.updateDisplayText();
  }

  private hasUneditableChildTarget(target: VisualCodingTarget): boolean {
    const numberOfChildren = target.targets.length;
    if (numberOfChildren === 1 && target.targets[0].countCoding) {
      return !!target.targets[0].titlePrefix;
    }

    return false;
  }

  onIndexTabChange(index: number) {
    if (this.layout === 'compact') {
      this.setDisplayMode(
        index === 1 ? this.displayModeType.code : this.displayModeType.title
      );
    } else {
      this.setDisplayMode(
        index === 0 ? this.displayModeType.title : this.displayModeType.code
      );
    }
  }
}
