import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import {
  FlatTreeNode,
  TreeTableColumn,
  TreeTableNode,
} from './tree-table.models';

// SelectionTreeDatabase database
@Injectable()
export class TreeTableDatabase {
  dataChange: BehaviorSubject<TreeTableNode[]> = new BehaviorSubject<
    TreeTableNode[]
  >([]);
  get data(): TreeTableNode[] {
    return this.dataChange.value;
  }

  filterText: string;
  treeNodesView: FlatTreeNode[] = [];

  constructor() {}

  /**
   * Initialise and configure database with the data
   *
   * @param treeData Actual tree table data
   */
  initialise(treeData: TreeTableNode[]) {
    treeData = treeData || [];
    this.setParent(treeData, null);

    this.dataChange.next(treeData);
  }

  /**
   * filtering is applied to nodes without children
   *
   * @param filterText Text to use when filtering
   * @return willl use the database subscription to return new data
   */
  public filter(filterText: string) {
    this.filterText = filterText;
    let filteredTreeData = this.deepCopy(this.dataChange.value); //this.unFiltered
    if (filterText) {
      const text = filterText.toLowerCase();
      filteredTreeData = filteredTreeData.filter((node) =>
        this.includes(text, node)
      );
    }

    this.dataChange.next(filteredTreeData);
  }

  /**
   * trigger a data update if something has changed in the data
   *
   */
  public update() {
    this.dataChange.next(this.data);
  }

  /**
   * sort by the given column using sort criteria
   *
   * @param column column object to use when sorting
   * @param sort Sort object describing column and direction
   * @return willl use the database subscription to return new data
   */
  public sort(column: TreeTableColumn, sort: Sort) {
    const sortFunction = !column
      ? null
      : typeof column.sort === 'function'
      ? column.sort
      : typeof column.cell === 'function'
      ? column.cell
      : null;

    let nodes = this.dataChange.value; // this.unFiltered;
    nodes = nodes.sort((a, b) => {
      if (a.skipSort || b.skipSort) {
        // exclude 'Total' row from sort
        return;
      }
      const isAsc = sort.direction === 'asc';
      let v1 = sortFunction ? sortFunction(a) : a.name;
      let v2 = sortFunction ? sortFunction(b) : b.name;

      const colType = column && column.columnType ? column.columnType : '';
      // handle columns that don't have totals per media type: calc children sum on the fly
      if (colType === 'number') {
        if (v1 === -1) {
          v1 = a.children ? this.calculateGroupTotal(a.children, column) : 0;
          a.data[column.columnDef + 'Raw'] = v1;
        }
        if (v2 === -1) {
          v2 = b.children ? this.calculateGroupTotal(b.children, column) : 0;
          b.data[column.columnDef + 'Raw'] = v2;
        }
      }

      return this.compare(v1, v2, isAsc);
    });

    nodes.forEach((node) => {
      node.children ? this.doSort(node.children, column, sort) : {};
    });
    this.filter(this.filterText); //re-aply filter (if any)
  }

  calculateGroupTotal(nodes: TreeTableNode[], column: TreeTableColumn): number {
    let groupTotal: number = 0;
    nodes.forEach((node) => {
      groupTotal +=
        (column.sort(node) as number) > 0 ? <number>column.sort(node) : 0;
    });
    return groupTotal;
  }
  // internal sort used to sort iteratively within nodes
  private doSort = (
    nodes: TreeTableNode[],
    column: TreeTableColumn,
    sort: Sort
  ) => {
    nodes = nodes.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      const v1 =
        column && typeof column.sort === 'function' ? column.sort(a) : a.name;
      const v2 =
        column && typeof column.sort === 'function' ? column.sort(b) : b.name;
      return this.compare(v1, v2, isAsc);
    });

    nodes.forEach((node) => {
      node.children ? this.doSort(node.children, column, sort) : {};
    });
  };

  // compare helper function used for sorting
  private compare = (
    a: number | string,
    b: number | string,
    isAsc: boolean
  ) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  };

  // set up the parent field of all nodes
  private setParent(treeData: TreeTableNode[], parent: TreeTableNode) {
    treeData.forEach((node) => {
      node.parent = parent;
      if (node.children) this.setParent(node.children, node);
    });
  }

  applyDisabled(ids: string[]) {
    const apply = (nodes: TreeTableNode[]) => {
      nodes.forEach((node) => {
        node.id ? (node.disabled = ids.includes(node.id as string)) : null;
        apply(node.children || []);
      });
    };

    apply(this.data);
    this.update();
  }

  // used when filtering, iteratively
  private includes(text: string, node: TreeTableNode): TreeTableNode {
    const found = node.name.toLowerCase().includes(text);
    if (found) return node; // if found return node and all children (if any)
    if (!node.children) return null; // if no children (and not found) no need to carry on

    let filteredChildren = node.children.filter((child) =>
      this.includes(text, child)
    );
    if (!filteredChildren.length) return null; // no children so null

    let filteredNode = node;
    filteredNode.children = filteredChildren;
    return filteredNode;
  }

  private deepCopy(value: TreeTableNode[]): TreeTableNode[] {
    return value;
    //return lodash.cloneDeep(value);
  }
}
