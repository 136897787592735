<tup-mat-title-bar title="{{ title }}">
  <span fxFlex></span>
  <button
    mat-icon-button
    tupMatButton
    appearance="tiny"
    [disabled]="!canCancel"
    (click)="onClose(false)"
  >
    <mat-icon>clear</mat-icon>
  </button>
</tup-mat-title-bar>

<mat-divider class="divider"></mat-divider>

<mat-dialog-content class="container">
  <mat-spinner
    [diameter]="80"
    class="spinner-middle"
    *ngIf="processing"
  ></mat-spinner>

  <div class="media-container">Media: {{ data.vehicle.originalTitle }}</div>
  <div class="selection-container">
    <mat-form-field
      class="full-width"
      appearance="outline"
      tupMatFormField
    >
      <mat-label>Rename media</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="data.newTitle"
      />
    </mat-form-field>
  </div>

  <mat-tab-group
    animationDuration="0ms"
    mat-stretch-tabs
    class="addressable-audience-tabs"
  >
    <mat-tab label="Addressable audience">
      <ng-container [ngTemplateOutlet]="addressableTab"></ng-container>
    </mat-tab>
    <mat-tab label="Target audience">
      <ng-container [ngTemplateOutlet]="planningTab"></ng-container>
    </mat-tab>
  </mat-tab-group>
  <div
    class="create-new-addressable-container"
    fxLayout="row"
    fxLayoutAlign="center"
  >
    <button
      mat-stroked-button
      tupMatButton
      color="primary"
      class="btn-action create-new-addressable-button"
      (click)="onCreateNewAudience()"
    >
      <mat-icon color="primary">add</mat-icon>
      Create new addressable audience
    </button>
  </div>

  <!-- addessable tab template -->
  <ng-template #addressableTab>
    <div
      class="tab-content-container"
      fxLayout="column"
      fxLayoutAlign="top"
    >
      <app-audience-item
        class="audience-list-item"
        [checked]="!data.selectedTarget"
        (selected)="
          onSelected($event, addressableTargetSources.addressableTarget)
        "
      ></app-audience-item>

      <app-audience-item
        class="audience-list-item"
        *ngFor="
          let target of targets
            | filterAddressable : addressableTargetSources.addressableTarget
        "
        [audienceTarget]="target"
        [vehicleSurvey]="data.survey"
        [checked]="target.id === data.selectedTarget?.id"
        (selected)="
          onSelected($event, addressableTargetSources.addressableTarget)
        "
        (editClicked)="onItemEditClicked($event)"
      ></app-audience-item>

      <p
        class="padd"
        *ngIf="
          (
            targets
            | filterAddressable : addressableTargetSources.addressableTarget
          ).length === 0
        "
      >
        There are no addressable audiences assigned to this campaign yet.
      </p>
    </div>
  </ng-template>

  <!-- planning tab template -->
  <ng-template #planningTab>
    <div style="overflow: auto">
      <div
        class="tab-content-container"
        fxLayout="column"
        fxLayoutAlign="center"
      >
        <app-audience-item
          class="audience-list-item"
          *ngFor="
            let target of targets
              | filterAddressable : addressableTargetSources.planningTarget
          "
          [checked]="
            target.id === data.selectedTarget?.id &&
            addressableSource === addressableTargetSources.planningTarget
          "
          [audienceTarget]="target"
          [vehicleSurvey]="data.survey"
          (selected)="
            onSelected($event, addressableTargetSources.planningTarget)
          "
          (editClicked)="onItemEditClicked($event)"
        ></app-audience-item>
        <div fxFlex></div>
      </div>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-divider class="divider bottom-divider"></mat-divider>

<mat-dialog-actions
  class="app-dialog-actions"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="8px"
>
  <button
    mat-button
    tupMatButton
    [disabled]="!canCancel"
    class="cancel btn-action"
    (click)="onClose(false)"
    data-tracking="addressable-cancel-btn"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    tupMatButton
    color="primary"
    class="btn-action"
    (click)="onClose(true)"
    data-tracking="addressable-save-btn"
  >
    Save
  </button>
</mat-dialog-actions>
