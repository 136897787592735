import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  TupAnalyticsService,
  BasicUserDetails,
  BasicAccountDetails,
} from '@telmar-global/tup-analytics';
import {
  AuthEvent,
  TupAuthService,
  UserDetails,
  UserContainer,
} from '@telmar-global/tup-auth';
import { DocumentService } from './services/document.service';
import { productFruits } from 'product-fruits';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private authService: TupAuthService,
    router: Router,
    private analyticsService: TupAnalyticsService,
    private documentService: DocumentService,
    @Inject(Window)
    private window: Window & {
      hj?: (...args: Array<any>) => void;
    }
  ) {
    this.authService.authEvents.subscribe((e: AuthEvent) => {
      if (AuthEvent.AuthEventUserDataChanged === e) {
        if (this.authService.user) {
          this.trackUserLogin(this.authService.user);
          this.setMyDriveContainer();
        } else {
          this.analyticsService.logout();
        }
      }
    });
  }

  public browserClosingMessage(event) {
    event.returnValue = 'MMMMMM';
  }

  private trackUserLogin(user: UserDetails): void {
    const UUID = user?.uuid;
    const email = user?.attributes.email;
    const firstname = user?.attributes.given_name;
    const lastname = user?.attributes.family_name;
    const company =
      user.containers && user.containers.length
        ? user.containers[0].label
        : '[missing]';

    const accountDetails: BasicAccountDetails =
      this.getUserAccountDetails(user);
    const loginUser: BasicUserDetails = {
      id: UUID,
      attributes: {
        email,
      },
    };

    this.analyticsService.login(loginUser, accountDetails);
    this.identifyHotjarUser(UUID, email);

    productFruits.init(
      environment.analytics.productFruits.workspaceCode,
      environment.analytics.productFruits.language,
      {
        username: email,
        firstname,
        lastname,
        props: {
          company,
        },
      }
    );
  }

  private identifyHotjarUser(userId: string, email: string): void {
    if (this.window.hj) {
      this.window.hj('identify', userId, {
        email,
      });
    }
  }

  private getUserAccountDetails(user: UserDetails) {
    const defaultContainer = user.containers.find(
      (container: UserContainer) => container.default
    );
    const businessName = defaultContainer
      ? defaultContainer.label
      : user.containers[0].label;
    const businessId = defaultContainer
      ? defaultContainer.name
      : user.containers[0].name;
    return {
      id: businessId,
      name: businessName,
    };
  }

  private setMyDriveContainer(): void {
    const user = this.authService.user;
    const userEmail = user.attributes.email;
    const usersContainer = this.authService.user.containers.find(
      (container) => container.name === userEmail
    );
    this.documentService.myDriveContainer = usersContainer;
  }
}
