<tup-mat-title-bar title="Are you sure?">
  <span fxFlex></span>
  <button
    mat-icon-button
    tupMatButton
    appearance="tiny"
    (click)="onClose(false)"
  >
    <mat-icon>clear</mat-icon>
  </button>
</tup-mat-title-bar>

<mat-dialog-content class="container">
  <snackbar-generic [data]="lastVehicleWarning"></snackbar-generic>
</mat-dialog-content>

<mat-dialog-actions
  class="app-dialog-actions"
  fxLayoutAlign="space-between center"
>
  <button
    mat-stroked-button
    tupMatButton
    (click)="onClose(false)"
    data-tracking="cancel-btn"
  >
    Cancel
  </button>

  <button
    mat-flat-button
    tupMatButton
    color="primary"
    (click)="onClose(true)"
    data-tracking="submit-btn"
  >
    Delete
  </button>
</mat-dialog-actions>
