<input
  type="file"
  class="file-input"
  (change)="onFileSelected($event)"
  [accept]="acceptedFileType"
  #fileUpload
/>

<div class="file-upload">
  <button
    mat-flat-button
    tupMatButton
    class="documents-header-btn"
    color="primary"
    [disabled]="disabled"
    (click)="fileUpload.click()"
  >
    <mat-icon *ngIf="showIcon">file_upload</mat-icon>
    {{ text }}
  </button>
</div>
