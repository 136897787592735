import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { cloneDeep } from 'lodash';
import { Schedule } from '../classes/schedule';
import { ScheduleTotalTag } from '../classes/schedule-total';
import { Target } from '../classes/target';
import { TargetVehicle } from '../classes/vehicle';
import { SelectionOption } from '../components/simple-selection/simple-selection.component';
import {
  ChartSettingsMode,
  ChartTargetMode,
  ChartSettings,
  DEFAULT_CHART_SETTINGS,
  PlanningState,
  DEFAULT_CHART_SETTINGS_FREQ_DISTR,
  ChartType,
} from '../models/charts.model';
import {
  Column_Tot_ReachPct,
  Column_Tot_AvgFreq,
  Column_Tot_GRPs,
  Column_Tot_Reach000,
  Column_Tot_Impressions,
  Column_Tot_Inserts,
  Column_Tot_UniqueReachPct,
  Column_Tot_EffReach,
  Column_Tot_UniqueReach000,
} from '../models/planning-tot-columns.models';
import { ChartSettingsService } from '../services/chart-settings.service';
import { DialogService } from '../services/dialog.service';
import { MediaPlannerService } from '../services/media-planner.service';
import { MEDIATYPE_ALL } from '../steps/planning-step/planning-step.component';
import uniqid from 'uniqid';
import { ChartComponent } from '../components/chart/chart.component';
import {
  Column_Impressions_FD,
  Column_Reached_At_Least_Pct,
  Column_Reached_Exactly_Pct,
} from '../models/planning-freq-dist-columns.model';
import { buildFreqRows } from '../utils/exportUtils';
import { AppendUnitsPipe } from '../pipes/append-units.pipe';
import { EMPTY_STRING_CELL } from '../classes/planning-data';
import { PlanningService } from '../services/planning.service';

export interface PlanningChart {
  data: any;
  id: string;
  settings: ChartSettings;
  fullWidth: boolean;
}

@Component({
  selector: 'planning-charts',
  templateUrl: './planning-charts.component.html',
  styleUrls: ['./planning-charts.component.scss'],
})
export class PlanningChartsComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() chartsActiveIndex: number;
  @Input() unitsText: string;

  @ViewChild('mediaSelect') mediaSelect: MatSelect;
  @ViewChild('chartsToolbarContent') chartsToolbarContent: TemplateRef<any>;
  @ViewChildren(ChartComponent) activeCharts: QueryList<ChartComponent>;

  charts: PlanningChart[] = [];
  defaultChartSettings: ChartSettings;
  chartType: ChartType = ChartType.DEFAULT;
  showTable: boolean = false;
  _processing: boolean = false;

  get processing(): boolean {
    return this._processing;
  }

  set processing(value: boolean) {
    this._processing = value;
  }

  // targets and schedules and vehicles to use in charts
  filterTargets: SelectionOption[];
  filterSchedules: SelectionOption[];
  filterMedia: TargetVehicle[];

  targetModeToggle: SelectionOption = {
    id: 'aud_toggle',
    selectedLabel: 'Combined audiences',
    label: 'Single audience',
    selected: false,
  };
  planModeToggle: SelectionOption = {
    id: 'planmode_toggle',
    label: 'Single plan',
    selectedLabel: 'Combined plans',
    selected: false,
  };

  mediaModes: SelectionOption[] = [
    { id: 'vehicles', label: 'Media vehicles' },
    { id: 'mediatypes', label: 'Media channels' },
  ];
  currentMediaMode: string = this.mediaModes[0].id as string;

  // keeping track of combination changes
  combineTargets: boolean = this.targetModeToggle.selected;
  combineSchedules: boolean = this.planModeToggle.selected;

  appendUnits = new AppendUnitsPipe();

  private readonly MAX_TOP_ROWS: number = 999;

  get currentMediaModeLabel(): string {
    return this.mediaModes.find((m) => m.id === this.currentMediaMode).label;
  }

  // surveytime specific

  constructor(
    private dialogService: DialogService,
    private userMessageService: TupUserMessageService,
    private chartSettingsService: ChartSettingsService,
    private mediaplannerService: MediaPlannerService,
    private planningService: PlanningService
  ) {}

  ngOnInit(): void {
    if (this.chartsActiveIndex === 4) {
      this.chartType = ChartType.DEFAULT;
    } else {
      this.chartType = ChartType.FREQ_DISTR;
    }
    this.loadInitialData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const chartsActiveIndex = changes['chartsActiveIndex'];
    if (
      chartsActiveIndex &&
      chartsActiveIndex.previousValue !== chartsActiveIndex.currentValue &&
      !chartsActiveIndex.firstChange
    ) {
      if (this.chartsActiveIndex === 4) {
        this.chartType = ChartType.DEFAULT;
        this.showTable = false;
        this.loadInitialData();
        this.buildChartData(true);
      } else {
        this.processing = true;
        this.chartType = ChartType.FREQ_DISTR;

        const selectedFilterSchedule = this.filterSchedules.find(
          (filterSchedule) => filterSchedule.selected
        );
        const currentSchedule =
          this.mediaplannerService.plan.schedules[
            Number(selectedFilterSchedule.id)
          ];

        this.planningService
          .evaluate(
            this.mediaplannerService.plan.targets,
            currentSchedule,
            this.mediaplannerService.plan.vehicleGroups.groups,
            false,
            true
          )
          .subscribe((success) => {
            if (success) {
              this.processing = false;
              this.showTable = false;
              this.loadInitialData();
              this.buildChartData(true);
            }
            this.processing = false;
          });
      }
    }
  }

  ngAfterViewInit() {}

  loadInitialData(): void {
    const defaultChartSettings = {
      default: DEFAULT_CHART_SETTINGS,
      'freq-distr': DEFAULT_CHART_SETTINGS_FREQ_DISTR,
    };
    this.defaultChartSettings = cloneDeep(defaultChartSettings[this.chartType]);
    if (this.chartType === ChartType.DEFAULT) {
      this.mediaModes = [
        { id: 'vehicles', label: 'Media vehicles' },
        { id: 'mediatypes', label: 'Media channels' },
      ];
      this.currentMediaMode = this.mediaModes[0].id as string;
      (this.defaultChartSettings.primaryDataItem =
        Column_Tot_ReachPct.columnDef),
        (this.defaultChartSettings.primaryChartType = 'tupVerticalBar'),
        (this.defaultChartSettings.secondaryDataItem = 'None'),
        (this.defaultChartSettings.secondaryChartType = 'tupLine'),
        this.chartSettingsService.saveGlobalChartSettings(
          this.defaultChartSettings
        );
    } else {
      this.mediaModes = [{ id: 'mediatypes', label: 'Media channels' }];
      this.currentMediaMode = this.mediaModes[0].id as string;
      (this.defaultChartSettings.primaryDataItem =
        Column_Reached_At_Least_Pct.columnDef),
        (this.defaultChartSettings.primaryChartType = 'tupLine'),
        this.chartSettingsService.saveGlobalChartSettings(
          this.defaultChartSettings
        );
    }
  }

  // charts appearing from the planning screen tab
  loadData(state: PlanningState) {
    const rebuild = this.chartSettingsService.planningStateChanged(state);
    this.chartSettingsService.planningState = state;

    // get real targets from filter list
    this.filterTargets = this.mediaplannerService.plan.targets
      .filter((target) => target.planningTarget)
      .map((target, index) => {
        return { id: target.id, label: target.title, selected: true };
      });

    // get real schedules
    this.filterSchedules = this.mediaplannerService.plan.schedules.map(
      (schedule, id) => {
        return {
          id,
          label: schedule.name,
          selected: state.currentSchedule === id || this.combineSchedules,
        };
      }
    );

    // always based on the vehicles in target[0].  Might/Might not be a problem later
    this.filterMedia = this.mediaplannerService.plan.targets[0].vehicles.filter(
      (veh) =>
        veh.mediaType === state.currentMediaType ||
        state.currentMediaType === MEDIATYPE_ALL
    );
    this.buildChartData(true);
  }

  // open the global settings dialog
  onOpenGlobalSettings() {
    //const chartTargets: ChartTarget[] = this.mediaplannerService.plan.targets.map( (target, index)=> { return { id: index+"", title: target.title, visible: true } } );
    const chartTargets = this.charts[0].data.targets;
    const chartSettings = this.chartSettingsService.getGlobalChartSettings();

    // only allow color editing in certain situations
    chartSettings.allowSeriesColorEdit = !(
      (this.planModeToggle.selected && this.targetModeToggle.selected) ||
      (!this.planModeToggle.selected && this.targetModeToggle.selected)
    );

    chartSettings.showTargetSelection = !this.combineTargets;
    const prevColors = chartSettings.seriesColor;

    this.dialogService
      .openChartSettings(
        ChartTargetMode.combined,
        ChartSettingsMode.global,
        chartSettings,
        chartTargets,
        this.chartType,
        ''
      )
      .afterClosed()
      .subscribe((settings: ChartSettings) => {
        if (settings) {
          if (!this.combineSchedules && !this.combineTargets) {
            this.mediaplannerService.plan.chartSettings = {};
          }
          const colorsChanged = !Object.keys(settings.seriesColor).every(
            (key) => settings.seriesColor[key] === prevColors[key]
          );
          if (colorsChanged) {
            this.chartSettingsService.saveColumnColors(
              settings.seriesColor,
              settings.id
            );
          }

          this.charts.forEach((val) => {
            this.chartSettingsService.saveColumnColors(
              settings.seriesColor,
              val.id
            );
          });

          this.chartSettingsService.saveGlobalChartSettings(settings);
          this.buildChartData(true);
        }
      });
  }

  onDropdownToggle(opened: boolean) {
    const rebuild =
      this.combineTargets !== this.targetModeToggle.selected ||
      this.combineSchedules !== this.planModeToggle.selected;
    if (this.chartType === ChartType.FREQ_DISTR) {
      if (this.targetModeToggle.selected) {
        this.planModeToggle.disabled = true;
      } else {
        this.planModeToggle.disabled = false;
      }
      if (this.planModeToggle.selected) {
        this.targetModeToggle.disabled = true;
      } else {
        this.targetModeToggle.disabled = false;
      }
    }

    const schCount = this.filterSchedules.filter((sch) => sch.selected).length;
    const tgtCount = this.filterTargets.filter((tgt) => tgt.selected).length;

    if (!opened) {
      // i.e closed

      schCount * tgtCount === 0
        ? this.userMessageService.openMessageDialog(
            'Please select at least 1 plan and audience to chart',
            'options'
          )
        : this.buildChartData(true);
    }
  }

  // media or mediatype selection in the dropdown media dialog UI
  onSelectMedia(buttonEvent: PointerEvent, mediaMode: string) {
    buttonEvent.stopPropagation();

    const target = this.mediaplannerService.plan.targets[0];
    const allMedia =
      mediaMode === 'vehicles'
        ? target.vehicles.map(
            (vehicle) =>
              `${vehicle.title}${this.addressableTargetTitle(vehicle)}`
          )
        : this.getMediaTypes(target.vehicles);
    const selectedMedia =
      mediaMode === 'vehicles'
        ? this.filterMedia.map(
            (ve) => `${ve.title}${this.addressableTargetTitle(ve)}`
          )
        : this.getMediaTypes(this.filterMedia);

    const options = {
      multiSelect: true,
      minimumSelection: 1,
    };

    this.dialogService
      .openSelectionDialog(
        'Select media',
        'Select the media to use in your charts',
        allMedia,
        selectedMedia,
        options
      )
      .afterClosed()
      .subscribe((res) => {
        this.mediaSelect.close();

        if (res) {
          // res.selection will be either a list of selected vehicle titles or a list of selected mediatypes
          this.currentMediaMode = mediaMode;
          this.filterMedia =
            this.mediaplannerService.plan.targets[0].vehicles.filter(
              (vehicle) =>
                res.selection.includes(
                  mediaMode === 'vehicles'
                    ? `${vehicle.title}${this.addressableTargetTitle(vehicle)}`
                    : vehicle.mediaType
                )
            );

          this.buildChartData(true);
        }
      });
  }

  onChartModeChange() {
    this.buildChartData(true);
  }

  refreshViewColorChange() {
    this.buildChartData(false);
  }

  // append addressable target title to media
  private addressableTargetTitle(vehicle: TargetVehicle): string {
    if (vehicle.addressableConfig) {
      const addrTgt = this.mediaplannerService.plan.targets.find(
        (tgt) => tgt.id === vehicle.addressableConfig.targetId
      );
      return addrTgt ? ` - ${addrTgt.title}` : '';
    }
    return '';
  }

  onScroll() {}

  // *******************************************************************
  // main starter function for building all the chart settings and data
  // *******************************************************************
  buildChartData(rebuild: boolean) {
    //combine targets and schedules rules:  https://docs.google.com/spreadsheets/d/13WwBpRvKWErbEmD_9EUf-_oIK686p53zrJKApMMTu6U/edit#gid=1636287335
    this.combineTargets = this.targetModeToggle.selected;
    this.combineSchedules = this.planModeToggle.selected;

    const selectedTargets = this.filterTargets
      .filter((target) => target.selected)
      .map((target) =>
        this.mediaplannerService.plan.targets.find((t) => t.id === target.id)
      );
    const selectedSchedules = this.filterSchedules
      .filter((schedule) => schedule.selected)
      .map(
        (schedule) =>
          this.mediaplannerService.plan.schedules[schedule.id as number]
      );

    let globalChartSettings: ChartSettings = null;

    // if rebuilding from scratch, start with the global settings
    if (rebuild) {
      this.charts = [];
      globalChartSettings = this.chartSettingsService.getGlobalChartSettings();
      globalChartSettings.seriesColor =
        this.chartSettingsService.loadColumnColors(globalChartSettings.id);
      globalChartSettings.footer = this.mediaplannerService.plan.title;
      globalChartSettings.surveySource =
        this.mediaplannerService.plan.currentSurvey.title; // assuming all targets use the same survey
      globalChartSettings.copyright =
        this.mediaplannerService.plan.currentSurvey.copyrightInfo;
    }

    // *******************************************************************
    // single plan, single audience:  X-axis as media, metrics as series
    // *******************************************************************
    if (!this.combineSchedules && !this.combineTargets) {
      const singleSchedule = selectedSchedules[0];

      // the metrics to use as series in the chart
      const chartDataKeys: { [key: string]: Record<string, string> } = {
        [ChartType.DEFAULT]: {
          [Column_Tot_GRPs.columnDef]: this.appendUnits.transform(
            Column_Tot_GRPs,
            this.unitsText
          ),
          [Column_Tot_Impressions.columnDef]: this.appendUnits.transform(
            Column_Tot_Impressions,
            this.unitsText
          ),
          [Column_Tot_ReachPct.columnDef]: this.appendUnits.transform(
            Column_Tot_ReachPct,
            this.unitsText
          ),
          [Column_Tot_Reach000.columnDef]: this.appendUnits.transform(
            Column_Tot_Reach000,
            this.unitsText
          ),
          [Column_Tot_Inserts.columnDef]: this.appendUnits.transform(
            Column_Tot_Inserts,
            this.unitsText
          ),
          [Column_Tot_AvgFreq.columnDef]: this.appendUnits.transform(
            Column_Tot_AvgFreq,
            this.unitsText
          ),
          [Column_Tot_UniqueReach000.columnDef]: this.appendUnits.transform(
            Column_Tot_UniqueReach000,
            this.unitsText
          ),
          [Column_Tot_EffReach.columnDef]: this.appendUnits.transform(
            Column_Tot_EffReach,
            this.unitsText
          ),
          [Column_Tot_UniqueReachPct.columnDef]: this.appendUnits.transform(
            Column_Tot_UniqueReachPct,
            this.unitsText
          ),
        },
        [ChartType.FREQ_DISTR]: {
          [Column_Reached_At_Least_Pct.columnDef]: this.appendUnits.transform(
            Column_Reached_At_Least_Pct,
            this.unitsText
          ),
          [Column_Reached_Exactly_Pct.columnDef]: this.appendUnits.transform(
            Column_Reached_Exactly_Pct,
            this.unitsText
          ),
          [Column_Impressions_FD.columnDef]: this.appendUnits.transform(
            Column_Impressions_FD,
            this.unitsText
          ),
        },
      };

      // hardcoded list of metrics to default to visible.
      const chartDataTitlessVisible: { [key: string]: string[] } = {
        [ChartType.DEFAULT]: [
          this.appendUnits.transform(Column_Tot_GRPs, this.unitsText),
          this.appendUnits.transform(Column_Tot_ReachPct, this.unitsText),
          this.appendUnits.transform(Column_Tot_AvgFreq, this.unitsText),
        ],
        [ChartType.FREQ_DISTR]: [
          this.appendUnits.transform(
            Column_Reached_At_Least_Pct,
            this.unitsText
          ),
        ],
      };

      this.charts = this.charts.concat(
        this.buildMediaByMetrics(
          selectedTargets,
          singleSchedule,
          this.filterMedia,
          this.currentMediaMode,
          chartDataKeys[this.chartType],
          chartDataTitlessVisible[this.chartType],
          globalChartSettings,
          this.charts,
          this.chartType === ChartType.DEFAULT
            ? this.mediaplannerService.plan.chartSettings
            : {}
        )
      );
    }

    // *******************************************************************
    // single plan, combined audiences: X-axis as targets, media as series.  X-axis as media, targets as series
    // *******************************************************************
    if (!this.combineSchedules && this.combineTargets) {
      const singleSchedule = selectedSchedules[0];

      let ch = this.buildTargetsByMedia(
        selectedTargets,
        singleSchedule,
        this.filterMedia,
        this.currentMediaMode,
        globalChartSettings,
        this.charts[0]
      );
      if (rebuild) this.charts.push(ch);

      ch = this.buildMediaByTargets(
        selectedTargets,
        singleSchedule,
        this.filterMedia,
        this.currentMediaMode,
        globalChartSettings,
        this.charts[1]
      );
      if (rebuild) this.charts.push(ch);
    }

    // *******************************************************************
    // combined plans, single audiences: X-axis as schedules, media as series
    // *******************************************************************
    if (this.combineSchedules && !this.combineTargets) {
      this.charts = this.charts.concat(
        this.buildScheduleByMedia(
          selectedTargets,
          selectedSchedules,
          this.filterMedia,
          this.currentMediaMode,
          globalChartSettings,
          this.charts
        )
      );
    }

    // *******************************************************************
    // combined plans, combined audiences: X-axis as targets, schedules as series.   X-Axis as series, targets as series
    // *******************************************************************
    if (this.combineSchedules && this.combineTargets) {
      let ch = this.buildTargetsBySchedule(
        selectedTargets,
        selectedSchedules,
        globalChartSettings,
        this.charts[0]
      );
      this.charts.push(ch);

      ch = this.buildScheduleByTargets(
        selectedTargets,
        selectedSchedules,
        globalChartSettings,
        this.charts[1]
      );
      this.charts.push(ch);
    }
  }

  buildTargetsBySchedule(
    targets: Target[],
    schedules: Schedule[],
    globalChartSettings: ChartSettings,
    currentChart: PlanningChart
  ): PlanningChart {
    const settingsRebuild = globalChartSettings !== null;
    const chartData = {
      targetsLongTitles: [],
      resps: [],
      targets: [],
      labels: [],
      targetTitles: [],
    };

    const globalSettings = settingsRebuild || !currentChart;
    const settings = globalSettings
      ? cloneDeep(globalChartSettings)
      : currentChart.settings;

    const allSchedules = schedules.map((t) => t.name);
    targets.forEach((target) => {
      schedules.forEach((schedule, scheduleIndex) => {
        const totalsData = this.getScheduleTotals(
          target,
          schedule,
          settings.chartDataKeys
        );

        Object.keys(totalsData).forEach((key) => {
          chartData[key] =
            chartData[key] ||
            Array.apply(null, Array(allSchedules.length)).map((i) => undefined);
          chartData[key][scheduleIndex] = chartData[key][scheduleIndex] || [];
          chartData[key][scheduleIndex].push(totalsData[key][0]);
        });
      });
    });

    chartData.targetsLongTitles = allSchedules;
    chartData.targetTitles = allSchedules;
    chartData.targets = allSchedules.map((title, id) => {
      return { id, title, visible: true };
    });
    chartData.labels = targets.map((target) => target.title); // labels now an array of an array so reduce down

    // copy in visible flag if they're there
    if (
      !globalSettings &&
      settings.targetSelection.length === chartData.targets.length
    ) {
      chartData.targets.forEach((target, index) => {
        target.visible = settings.targetSelection[index].visible;
      });
    }

    if (settingsRebuild) {
      settings.id = uniqid();
      settings.topRowsCount = this.MAX_TOP_ROWS;
      settings.title = this.titleTrim(targets.map((t) => t.title).join(','));
      settings.subTitle = '';
      settings.footer = `${
        this.mediaplannerService.plan.title
      } - ${allSchedules.join(',')} `;
      this.chartSettingsService.saveChartSettings(settings, settings.id);
    }

    currentChart ? (currentChart.data = chartData) : {};
    return { data: chartData, settings, fullWidth: false, id: settings.id };
  }

  buildScheduleByTargets(
    targets: Target[],
    schedules: Schedule[],
    globalChartSettings: ChartSettings,
    currentChart: PlanningChart
  ): PlanningChart {
    const settingsRebuild = globalChartSettings !== null;

    const chartData = {
      targetsLongTitles: [],
      resps: [],
      targets: [],
      labels: [],
      targetTitles: [],
    };

    const globalSettings = settingsRebuild || !currentChart;
    const settings = globalSettings
      ? cloneDeep(globalChartSettings)
      : currentChart.settings;

    const allTargets = targets.map((t) => t.title);
    schedules.forEach((schedule) => {
      targets.forEach((target, targetIndex) => {
        const totalsData = this.getScheduleTotals(
          target,
          schedule,
          settings.chartDataKeys
        );

        Object.keys(totalsData).forEach((key) => {
          chartData[key] =
            chartData[key] ||
            Array.apply(null, Array(allTargets.length)).map((i) => undefined);
          chartData[key][targetIndex] = chartData[key][targetIndex] || [];
          chartData[key][targetIndex].push(totalsData[key][0]);
        });
      });
    });

    chartData.targetsLongTitles = allTargets;
    chartData.targetTitles = allTargets;
    chartData.targets = allTargets.map((title, id) => {
      return { id, title, visible: true };
    });

    chartData.labels = schedules.map((schedule) => schedule.name); // labels now an array of an array so reduce down

    // copy in visible flag if they're there
    if (
      !globalSettings &&
      settings.targetSelection.length === chartData.targets.length
    ) {
      chartData.targets.forEach((target, index) => {
        target.visible = settings.targetSelection[index].visible;
      });
    }

    if (settingsRebuild) {
      settings.id = uniqid();
      settings.topRowsCount = this.MAX_TOP_ROWS;
      settings.title = this.titleTrim(targets.map((t) => t.title).join(','));
      settings.subTitle = '';
      settings.footer = `${this.mediaplannerService.plan.title} - ${schedules
        .map((s) => s.name)
        .join(',')}`;
      this.chartSettingsService.saveChartSettings(settings, settings.id);
    }

    currentChart ? (currentChart.data = chartData) : {};
    return { data: chartData, settings, fullWidth: false, id: settings.id };
  }

  buildScheduleByMedia(
    targets: Target[],
    schedules: Schedule[],
    vehicles: TargetVehicle[],
    mediaMode: string,
    globalChartSettings: ChartSettings,
    currentCharts: PlanningChart[]
  ): PlanningChart[] {
    const settingsRebuild = globalChartSettings !== null;
    const charts: PlanningChart[] = [];

    targets.forEach((target, targetIndex) => {
      const chartData = {
        targetsLongTitles: [],
        resps: [],
        targets: [],
        labels: [],
        targetTitles: [],
      };

      const globalSettings = settingsRebuild || !currentCharts[targetIndex];
      const settings = globalSettings
        ? cloneDeep(globalChartSettings)
        : currentCharts[targetIndex].settings;

      if (this.chartType === ChartType.DEFAULT) {
        const allMedia =
          mediaMode === 'vehicles'
            ? vehicles.map(
                (vehicle) =>
                  `${vehicle.title}${this.addressableTargetTitle(vehicle)}`
              )
            : this.getMediaTypes(vehicles);

        allMedia.forEach((media, mediaIndex) => {
          schedules.forEach((schedule) => {
            const data = this.getMediaPlanningData(
              mediaMode,
              target,
              vehicles,
              schedule,
              settings.chartDataKeys
            );
            Object.keys(data).forEach((key) => {
              chartData[key] =
                chartData[key] ||
                Array.apply(null, Array(allMedia.length)).map((i) => undefined);
              chartData[key][mediaIndex] = chartData[key][mediaIndex] || [];
              chartData[key][mediaIndex].push(data[key][mediaIndex]);
            });
          });
        });
        chartData.targetsLongTitles = allMedia;
        chartData.targetTitles = allMedia;
        chartData.targets = allMedia.map((title, id) => {
          return { id, title, visible: true };
        });
      } else {
        const freqDistributionSettings =
          this.mediaplannerService.plan.freqDistributionSettings;
        const freq = buildFreqRows(
          freqDistributionSettings.freqLevelTo,
          freqDistributionSettings.isGroupingOn,
          freqDistributionSettings.freqUpTo,
          freqDistributionSettings.groupsOf
        );
        freq.forEach((freq, freqIndex) => {
          schedules.forEach((schedule) => {
            const data = this.mediaplannerService.plan.getFreqDistData(
              'total',
              ScheduleTotalTag.total,
              target,
              schedule,
              freq.data,
              freqDistributionSettings.freqLevelTo
            );

            Object.keys(data).forEach((key) => {
              chartData[key] =
                chartData[key] ||
                Array.apply(null, Array(freq.length)).map((i) => undefined);
              chartData[key][freqIndex] = chartData[key][freqIndex] || [];
              chartData[key][freqIndex].push(data[key]);
            });
          });

          chartData.targetsLongTitles.push(freq.label);
          chartData.targetTitles.push(freq.label);
          chartData.targets.push({
            id: freqIndex,
            title: freq.label,
            visible: true,
          });
        });
      }

      chartData.labels = schedules.map((schedule) => schedule.name);

      // copy in visible flag if they're there
      if (
        !globalSettings &&
        settings.targetSelection.length === chartData.targets.length
      ) {
        chartData.targets.forEach((target, index) => {
          target.visible = settings.targetSelection[index].visible;
        });
      }

      if (settingsRebuild) {
        settings.id = uniqid();
        settings.topRowsCount = this.MAX_TOP_ROWS;
        settings.title = this.titleTrim(target.title);
        settings.subTitle = '';
        settings.footer = `${this.mediaplannerService.plan.title} - ${schedules
          .map((schedule) => schedule.name)
          .join(',')}`;
        this.chartSettingsService.saveChartSettings(settings, settings.id);
        charts.push({
          data: chartData,
          settings,
          fullWidth: false,
          id: settings.id,
        });
      }

      if (currentCharts && currentCharts[targetIndex])
        currentCharts[targetIndex].data = chartData;
    });

    return charts;
  }

  buildMediaByMetrics(
    targets: Target[],
    schedule: Schedule,
    vehicles: TargetVehicle[],
    mediaMode: string,
    chartDataKeys: Record<string, string>,
    chartKeysVisible: string[],
    globalChartSettings: ChartSettings,
    currentCharts: PlanningChart[],
    chartSettings: { [key: string]: ChartSettings }
  ): PlanningChart[] {
    const SERIES_DATA_KEY = 'series';
    const charts: PlanningChart[] = [];
    const settingsRebuild = globalChartSettings !== null;

    targets.forEach((target) => {
      const chartData = {
        targetsLongTitles: [],
        resps: [],
        targets: [],
        labels: [],
        targetTitles: [],
        surveysVehicle: [],
      };

      // new cloned settings for each target
      const globalSettings = settingsRebuild || !currentCharts[target.id];
      const settings = chartSettings[target.id]
        ? cloneDeep(chartSettings[target.id])
        : globalSettings
        ? cloneDeep(globalChartSettings)
        : currentCharts[target.id].settings;

      settings.chartDataKeys = { [SERIES_DATA_KEY]: SERIES_DATA_KEY };
      settings.chartDataItems = [
        { title: SERIES_DATA_KEY, value: SERIES_DATA_KEY },
      ];
      settings.primaryDataItem = SERIES_DATA_KEY;
      settings.secondaryDataItem = 'None';
      settings.showTargetSelection = true; // allow showing/hiding series within the settings dialog

      const allMedia =
        mediaMode === 'vehicles'
          ? vehicles.map(
              (vehicle) =>
                `${vehicle.title}${this.addressableTargetTitle(vehicle)}`
            )
          : this.getMediaTypes(vehicles);

      const data = this.getMediaPlanningData(
        mediaMode,
        target,
        vehicles,
        schedule,
        chartDataKeys
      );

      Object.entries(chartDataKeys).forEach((key, chartDataKeyIndex) => {
        const [columnDef, columnLabel] = key;

        //multiple datasets, array of targets inserted chartData['impressions'][targetindex][labelindex]
        Object.entries(chartDataKeys).forEach((key, columnIndex) => {
          const [columnDef, columnLabel] = key;

          chartData[SERIES_DATA_KEY] =
            chartData[SERIES_DATA_KEY] ||
            Array.apply(null, Array(chartDataKeys.length)).map(
              (i) => undefined
            );
          chartData[SERIES_DATA_KEY][columnIndex] =
            chartData[SERIES_DATA_KEY][columnIndex] || [];

          data[columnDef].forEach((mediaValue) => {
            chartData[SERIES_DATA_KEY][columnIndex].push(
              mediaValue === EMPTY_STRING_CELL ? 0 : mediaValue
            );
          });
        });

        chartData.targetsLongTitles.push(columnLabel);
        chartData.targetTitles.push(columnLabel);
        chartData.targets.push({
          id: chartDataKeyIndex,
          title: columnLabel,
          visible: true,
        });
      });

      // globalSettings and not been edited so apply the default column visibility by matching titles
      if (!settings.editedTargetSelection && globalSettings) {
        chartData.targets.forEach((target, index) => {
          target.visible = chartKeysVisible.includes(target.title);
        });
      } else {
        // copy in visible flag if they're there from settings targetSelection
        if (settings.targetSelection.length === chartData.targets.length) {
          chartData.targets.forEach((target, index) => {
            target.visible = settings.targetSelection[index].visible;
          });
        }
      }

      chartData.labels =
        this.chartType === ChartType.DEFAULT ? allMedia : data.labels;
      chartData.surveysVehicle = vehicles.map((val) => val.survey);

      if (settingsRebuild) {
        if (!chartSettings[target.id]) {
          settings.title = this.titleTrim(target.title);
          settings.subTitle = '';
          settings.footer = `${this.mediaplannerService.plan.title} - ${schedule.name}`;
          settings.id = target.id;
          settings.topRowsCount = this.MAX_TOP_ROWS;
        }

        this.chartSettingsService.saveChartSettings(
          settings,
          settings.id,
          this.chartType === ChartType.DEFAULT
            ? this.mediaplannerService.plan.chartSettings
            : undefined
        );
        charts.push({
          data: chartData,
          settings,
          fullWidth: false,
          id: settings.id,
        });
      }

      if (currentCharts && currentCharts[target.id])
        currentCharts[target.id].data = chartData;
    });

    return charts;
  }

  buildMediaByTargets(
    targets: Target[],
    schedule: Schedule,
    vehicles: TargetVehicle[],
    mediaMode: string,
    globalChartSettings: ChartSettings,
    currentChart: PlanningChart
  ): PlanningChart {
    const settingsRebuild = globalChartSettings !== null;

    const charts: PlanningChart[] = [];
    const chartData = {
      targetsLongTitles: [],
      resps: [],
      targets: [],
      labels: [],
      targetTitles: [],
    };

    const globalSettings = settingsRebuild || !currentChart;
    const settings = globalSettings
      ? cloneDeep(globalChartSettings)
      : currentChart.settings;

    targets.forEach((target, index) => {
      const data = this.getMediaPlanningData(
        mediaMode,
        target,
        vehicles,
        schedule,
        settings.chartDataKeys
      );

      // for each result, write the result to an array in the chartData object
      Object.keys(data).forEach((key) => {
        chartData[key] = chartData[key] || [];
        chartData[key].push(data[key]);
      });

      chartData.targetsLongTitles.push(target.title);
      chartData.targetTitles.push(target.title);
      chartData.targets.push({ id: index, title: target.title, visible: true });
    });

    // copy in visible flag if they're there
    if (
      !globalSettings &&
      settings.targetSelection.length === chartData.targets.length
    ) {
      chartData.targets.forEach((target, index) => {
        target.visible = settings.targetSelection[index].visible;
      });
    }

    chartData.labels = chartData.labels[0]; // labels now an array of an array so reduce down
    if (settingsRebuild) {
      settings.id = uniqid();
      settings.topRowsCount = this.MAX_TOP_ROWS;
      settings.title = this.titleTrim(targets.map((t) => t.title).join(','));
      settings.subTitle = '';
      settings.footer = `${this.mediaplannerService.plan.title} - ${schedule.name}`;
      this.chartSettingsService.saveChartSettings(settings, settings.id);
    }

    currentChart ? (currentChart.data = chartData) : {};
    const chart: PlanningChart = {
      data: chartData,
      settings,
      fullWidth: false,
      id: settings.id,
    };
    return chart;
  }

  buildTargetsByMedia(
    targets: Target[],
    schedule: Schedule,
    vehicles: TargetVehicle[],
    mediaMode: string,
    globalChartSettings: ChartSettings,
    currentChart: PlanningChart
  ): PlanningChart {
    const settingsRebuild = globalChartSettings !== null;
    const charts: PlanningChart[] = [];
    const chartData = {
      targetsLongTitles: [],
      resps: [],
      targets: [],
      labels: [],
      targetTitles: [],
    };

    const globalSettings = settingsRebuild || !currentChart;
    const settings = globalSettings
      ? cloneDeep(globalChartSettings)
      : currentChart.settings;
    if (this.chartType === ChartType.DEFAULT) {
      const allMedia =
        mediaMode === 'vehicles'
          ? vehicles.map(
              (vehicle) =>
                `${vehicle.title}${this.addressableTargetTitle(vehicle)}`
            )
          : this.getMediaTypes(vehicles);

      allMedia.forEach((media, mediaIndex) => {
        targets.forEach((target) => {
          const data = this.getMediaPlanningData(
            mediaMode,
            target,
            vehicles,
            schedule,
            settings.chartDataKeys
          );

          Object.keys(data).forEach((key) => {
            chartData[key] =
              chartData[key] ||
              Array.apply(null, Array(allMedia.length)).map((i) => undefined);
            chartData[key][mediaIndex] = chartData[key][mediaIndex] || [];
            chartData[key][mediaIndex].push(data[key][mediaIndex]);
          });
        });

        // series as media
        chartData.targetsLongTitles.push(media);
        chartData.targetTitles.push(media);
        chartData.targets.push({ id: mediaIndex, title: media, visible: true });
      });
    } else {
      const freqDistributionSettings =
        this.mediaplannerService.plan.freqDistributionSettings;
      const freq = buildFreqRows(
        freqDistributionSettings.freqLevelTo,
        freqDistributionSettings.isGroupingOn,
        freqDistributionSettings.freqUpTo,
        freqDistributionSettings.groupsOf
      );
      freq.forEach((freq, freqIndex) => {
        targets.forEach((target) => {
          const data = this.mediaplannerService.plan.getFreqDistData(
            'total',
            ScheduleTotalTag.total,
            target,
            schedule,
            freq.data,
            freqDistributionSettings.freqLevelTo
          );

          Object.keys(data).forEach((key) => {
            chartData[key] =
              chartData[key] ||
              Array.apply(null, Array(freq.length)).map((i) => undefined);
            chartData[key][freqIndex] = chartData[key][freqIndex] || [];
            chartData[key][freqIndex].push(data[key]);
          });
        });

        chartData.targetsLongTitles.push(freq.label);
        chartData.targetTitles.push(freq.label);
        chartData.targets.push({
          id: freqIndex,
          title: freq.label,
          visible: true,
        });
      });
    }

    // copy in visible flag if they're there
    if (
      !globalSettings &&
      settings.targetSelection.length === chartData.targets.length
    ) {
      chartData.targets.forEach((target, index) => {
        target.visible = settings.targetSelection[index].visible;
      });
    }

    chartData.labels = targets.map((t) => t.title);

    if (settingsRebuild) {
      settings.id = uniqid();
      settings.topRowsCount = this.MAX_TOP_ROWS;
      settings.title = this.titleTrim(targets.map((t) => t.title).join(','));
      settings.subTitle = '';
      settings.footer = `${this.mediaplannerService.plan.title} - ${schedule.name}`;
      this.chartSettingsService.saveChartSettings(settings, settings.id);
    }

    currentChart ? (currentChart.data = chartData) : {};
    const chart: PlanningChart = {
      data: chartData,
      settings,
      fullWidth: false,
      id: settings.id,
    };
    return chart;
  }

  // get either vehicle or totals data based on media or totals
  private getMediaPlanningData(
    mediaMode: string,
    target: Target,
    vehicles: TargetVehicle[],
    schedule: Schedule,
    chartDataKeys: Record<string, string>,
    chartData: any = null
  ): any {
    return mediaMode === 'vehicles'
      ? this.getVehiclePlanningData(
          target,
          vehicles,
          schedule,
          chartDataKeys,
          chartData
        )
      : this.getMediaTypePlanningData(
          target,
          vehicles,
          schedule,
          chartDataKeys,
          chartData
        );
  }

  private getScheduleTotals(
    target: Target,
    schedule: Schedule,
    chartDataKeys: Record<string, string>,
    chartData: any = null
  ): any {
    const totalsData = schedule.getScheduleTotal(
      'total',
      ScheduleTotalTag.total,
      target
    ).result;
    chartData = chartData || {};

    // copy all the totals data into chartData
    Object.keys(chartDataKeys).forEach((key) => {
      chartData[key] = chartData[key] || [];
      chartData[key].push(totalsData[key]);
    });
    return chartData;
  }

  // get mediatype data for the given target and schedule
  private getMediaTypePlanningData(
    target: Target,
    vehicles: TargetVehicle[],
    schedule: Schedule,
    chartDataKeys: Record<string, string>,
    chartData: any = null
  ): any {
    const labels: string[] = [];
    chartData = chartData || {};
    if (this.chartType === ChartType.DEFAULT) {
      const mediatypes = this.getMediaTypes(vehicles);

      mediatypes.forEach((mediatype) => {
        labels.push(mediatype);
        const totalsData = schedule.getScheduleTotal(
          mediatype,
          [ScheduleTotalTag.mediatype, ScheduleTotalTag.multiSurvey],
          target
        ).result;

        // copy all the totals data into chartData
        Object.keys(chartDataKeys).forEach((key) => {
          chartData[key] = chartData[key] || [];
          chartData[key].push(totalsData[key]);
        });
      });
    } else {
      const freqDistributionSettings =
        this.mediaplannerService.plan.freqDistributionSettings;
      const freq = buildFreqRows(
        freqDistributionSettings.freqLevelTo,
        freqDistributionSettings.isGroupingOn,
        freqDistributionSettings.freqUpTo,
        freqDistributionSettings.groupsOf
      );
      freq.forEach((freq) => {
        labels.push(freq.label);
        const totalsData = this.mediaplannerService.plan.getFreqDistData(
          'total',
          ScheduleTotalTag.total,
          target,
          schedule,
          freq.data,
          freqDistributionSettings.freqLevelTo
        );
        // copy all the totals data into chartData
        Object.keys(chartDataKeys).forEach((key) => {
          chartData[key] = chartData[key] || [];
          chartData[key].push(totalsData[key]);
        });
      });
    }

    chartData['labels'] = labels;
    return chartData;
  }

  // vehicle data for the given target and schedule
  private getVehiclePlanningData(
    target: Target,
    vehicles: TargetVehicle[],
    schedule: Schedule,
    chartDataKeys: Record<string, string>,
    chartData: any = null
  ): any {
    const labels: string[] = [];
    chartData = chartData || {};

    vehicles.forEach((vehicle) => {
      labels.push(`${vehicle.title}${this.addressableTargetTitle(vehicle)}`);
      const vehData = this.mediaplannerService.plan.getVehiclePlanningData(
        target,
        schedule,
        vehicle
      );

      // copy all the vehicle data into chartData
      Object.keys(chartDataKeys).forEach((key) => {
        chartData[key] = chartData[key] || [];
        chartData[key].push(vehData[key]);
      });
    });
    chartData['labels'] = labels;
    return chartData;
  }

  private getMediaTypes(vehicles: TargetVehicle[]): string[] {
    const vehWithoutCustomGroup = vehicles.filter((veh) => !veh.customGroupId);
    const vehWithCustomGroup = vehicles.filter((veh) => veh.customGroupId);

    const medTypes: string[] = vehWithoutCustomGroup
      .concat(vehWithCustomGroup)
      .map((veh) => veh.mediaType);

    return [...new Set(medTypes)];
  }

  private titleTrim(title: string, length: number = 60): string {
    const suffix = title.length > length - 3 ? '...' : '';
    return title.slice(0, length) + suffix;
  }
  onFullWidthNeededChange(event: boolean, index: number) {
    if (this.charts[index]) {
      this.charts[index].fullWidth = event;
    }
  }
  openTables() {
    this.activeCharts.forEach((chart) => {
      chart.localChartSettings.showDataTable = this.showTable;
      chart.openTable();
    });
    this.showTable = !this.showTable;
  }
}
