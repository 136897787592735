import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

export interface SelectionOption {
  id: number | String;
  label: string;
  selectedLabel?: string;
  selected?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'simple-selection',
  templateUrl: './simple-selection.component.html',
  styleUrls: ['./simple-selection.component.scss'],
})
export class SimpleSelectionComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() width: string = '250px';
  @Input() noneText: string = '[none]';
  @Input() display: 'dropdown' | 'list' | 'button' = 'dropdown';
  @Input() multi: boolean = true;
  @Input() buttonTemplateToggleTitle?: string;
  @Input() buttonTemplateOptionsTitle?: string;
  @Input() buttonIcon: string = 'settings';
  @Input() topOption: SelectionOption = null;
  @Input() options: SelectionOption[] = [];
  @Output() optionsChange: EventEmitter<SelectionOption[]> = new EventEmitter<
    SelectionOption[]
  >();
  @Output() change: EventEmitter<SelectionOption[]> = new EventEmitter<
    SelectionOption[]
  >();
  @Output() dropdownToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  selected: string = '';
  default: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.default =
      this.options && this.options.length ? (this.options[0].id as string) : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const options = changes['options'];
    if (options && options.previousValue !== options.currentValue) {
      if (options.currentValue && options.currentValue.length)
        this.default = this.options[0].id as string;
    }

    const multi = changes['multi'];
    if (multi && multi.previousValue !== multi.currentValue) {
      this.fixMulti();
    }
  }

  onMenuItemClick(event) {
    event.stopPropagation();
    this.onChange();
  }

  onRadioButtonChange(option: SelectionOption, event: MatRadioChange) {
    this.options.forEach((opt) => (opt.selected = opt === option));
  }

  onChange() {
    this.optionsChange.emit(this.options);
    this.change.emit(this.options);
  }

  onDropdownToggle(opened: boolean) {
    this.dropdownToggle.emit(opened);
  }

  private fixMulti() {
    // setTimeout(() => {
    //   if ( !this.multi ) {
    //     let selIndex = this.options.findIndex( o=> o.selected );
    //     this.options.forEach( (option, index) => option.selected = index === selIndex );
    //     this.changeDetectorRef.detectChanges();
    //   }
    //   console.log( this.options );
    // }, 100);
  }

  showSelected(): string {
    if (this.topOption)
      return this.topOption.selected
        ? this.topOption.selectedLabel
        : this.topOption.label;
    return this.options
      ? this.options
          .filter((op) => op.selected)
          .map((op) => op.label)
          .join(', ') || this.noneText
      : '';
  }

  showTooltip() {
    return this.options
      ? this.options
          .filter((op) => op.selected)
          .map((op) => op.label)
          .join(', ') || this.noneText
      : '';
  }

  selectedCount(): string {
    return `(${
      this.options ? this.options.filter((op) => op.selected).length : 0
    })`;
  }
}
