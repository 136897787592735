import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnCollection } from '../../../classes/column-collection';
import { TreeTableColumn } from '../../tree-table/tree-table.models';
import { EffectiveReach } from '../../../classes/media-planner';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';

import {
  Column_Group_DirectMail,
  Column_Group_Addressable,
  Column_EsgScore,
} from '../../../models/planning-veh-columns.models';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { GAEvents } from '../../../models/analytics.model';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { ColumnGroup } from '../../tree-table/column-group/table-group.models';

const addressableGroupName = Column_Group_Addressable.name;
const directMailGroupName = Column_Group_DirectMail.name;

export interface MetricColumnsDialogModel {
  planColumnData: ColumnCollection;
  effectiveReachData?: EffectiveReach;
  currentCompareOption: string;
  disabledGroups?: {
    addressable: boolean;
    directMail: boolean;
    esgScore: boolean;
  };
}
@Component({
  selector: 'app-metric-columns-dialog',
  templateUrl: './metric-columns-dialog.component.html',
  styleUrls: ['./metric-columns-dialog.component.scss'],
})
export class MetricColumnsDialogComponent implements OnInit {
  columnCollection: ColumnCollection;
  allColumns: TreeTableColumn[];
  columnGroups: ColumnGroup[];

  groupPositionList: number[];
  allSelected: boolean;
  noColumnsSelected: boolean = false;

  effReachChecked: boolean = false;
  effReachPctChecked: boolean = false;
  editEffReach: boolean;
  effReachFromValue: number;
  effReachToValue: number;
  effReachToFocused: boolean = false;
  unitsText: string;

  unselectedError: SnackbarGenericOptionModel = {
    type: 'error',
    message: 'Please select at least one metric to proceed.',
    icon: StatusSnackbarIcon.Error,
  };
  disabledTooltipMessage =
    'These metrics are not available as you have no addressable audience assigned.';

  selectedGroupColumnsCount = {};

  constructor(
    public dialogRef: MatDialogRef<MetricColumnsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MetricColumnsDialogModel,
    private analyticsService: TupAnalyticsService,
    private mediaplannerService: MediaPlannerService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
      this.mediaplannerService.plan.currentSurvey.code
    ).reportUnitText;

    this.columnCollection = this.data.planColumnData;
    this.allColumns = this.columnCollection.allColumns.map((column) => {
      return { ...column };
    });
    this.columnGroups = this.columnCollection.columnGroups;

    this.groupPositionList = [
      ...new Set(this.columnGroups.map((group) => group.columnsDialogPosition)),
    ].sort();

    this.allSelected = this.checkAllSelected();
    this.effReachFromValue =
      this.data.effectiveReachData?.effectiveReachFrom || 3;
    this.effReachToValue = this.data.effectiveReachData?.effectiveReachTo;
    this.updateEffReachVisibility();
    this.analyticsService.e(GAEvents.columns_dialog, {
      action: this.data.currentCompareOption,
    });
    this.checkDisabledGroupsAndColumns();
    this.setSelectedGroupColumnsCount();
  }

  checkDisabledGroupsAndColumns() {
    this.columnGroups.map((group) => {
      if (group.name === addressableGroupName) {
        group.disabled = this.data?.disabledGroups?.addressable;
      }
      if (group.name === directMailGroupName) {
        group.disabled = this.data?.disabledGroups?.directMail;
      }

      this.disableGroupColumns(group.name, group.disabled);
    });
  }

  // build selectedGroupColumnsCount object: { intermediate: boolean, allSelected: boolean }
  setSelectedGroupColumnsCount() {
    this.columnGroups.forEach((group) => {
      const groupColumns = this.getColumnsByGroup(group.name);
      const selectedColumns = groupColumns.filter(
        (col) => col.visible === true
      );
      this.selectedGroupColumnsCount[group.name] = {
        intermediate:
          selectedColumns.length > 0 &&
          selectedColumns.length < groupColumns.length,
        allSelected: selectedColumns.length === groupColumns.length,
      };
    });
  }

  disableGroupColumns(groupName: string, disabled: boolean) {
    this.getColumnsByGroup(groupName).map((col) => {
      col.disabled = disabled;
    });
  }

  checkAllSelected() {
    return !this.allColumns.find((col) => !col.visible);
  }

  getColumnsByGroup(groupName: string) {
    return this.allColumns.filter((col) => col.group?.name === groupName);
  }

  getColumnsWithoutGroup() {
    return this.allColumns.filter((col) => !col.group);
  }

  getGroupsByPosition(colPosition: number) {
    return this.columnGroups.filter(
      (col) => col.columnsDialogPosition === colPosition
    );
  }

  // when clicking 'Select All' checkbox all items should be checked; when clicking a group - items from that group should be checked
  onSelectAll(data, groupName: string = null) {
    let columns = this.allColumns;
    if (groupName) {
      columns = this.getColumnsByGroup(groupName);
    }
    if (!groupName) {
      this.allSelected = data.checked;
    }
    columns.map((col) => {
      if (!col.disabled) {
        col.visible = data.checked;
      }
    });
    this.updateEffReachVisibility();
    this.noColumnsSelected = this.verifyIfNoColumnSelected();
    this.setSelectedGroupColumnsCount();
  }

  onColumnChanged(data, col: TreeTableColumn = null) {
    col.visible = data.checked;
    this.updateEffReachVisibility();
    this.allSelected = this.checkAllSelected();
    this.noColumnsSelected = this.verifyIfNoColumnSelected();
    this.setSelectedGroupColumnsCount();
  }

  // update visibility status of eff reach columns (are checkboxes checked or not) and eff reach form
  updateEffReachVisibility() {
    this.effReachChecked = this.isColumnVisible('effReach');
    this.effReachPctChecked = this.isColumnVisible('effReachPct');

    this.editEffReach =
      (this.effReachChecked || this.effReachPctChecked) &&
      this.data.currentCompareOption === 'media';
  }

  verifyIfNoColumnSelected() {
    return this.allColumns.find((col) => col.visible === true) === undefined;
  }

  isColumnVisible(columnDef: string) {
    return this.allColumns.find((col) => col.columnDef === columnDef).visible;
  }

  onClose(save: boolean) {
    let returnData = {};
    if (save) {
      this.data.planColumnData.allColumns = [...this.allColumns];

      returnData = {
        columns: this.data.planColumnData.allColumns.filter(
          (col) =>
            this.allColumns.find((c) => c.columnDef === col.columnDef).visible
        ),
        effectiveReach: {
          effectiveReachFrom: this.effReachFromValue,
          effectiveReachTo: this.effReachToValue,
          active:
            this.isColumnVisible('effReach') ||
            this.isColumnVisible('effReachPct'),
        },
      };
    }
    this.dialogRef.close(save ? returnData : null);
  }

  onResetToDefault() {
    this.allSelected = false;
    this.noColumnsSelected = false;
    this.allColumns = this.columnCollection.buildDefaultColumns();
    this.setSelectedGroupColumnsCount();
    this.checkDisabledGroupsAndColumns();
  }
}
