export const EMPTY_NUMBER_CELL: number = -1;
export const EMPTY_STRING_CELL: string = '-';

export interface VehiclePlanningData {
  audience: number | string;
  audiencePct: number | string;
  audienceBasis: string;
  reach000: number | string;
  reachPct: number | string;
  // avgMinOnSite: number | string;
  potentialReachPct: number | string;
  potentialReach000: number | string;
  uniqueReachPct: number | string;
  uniqueReach000: number | string;
  avgFrequency: number | string;
  effReach: number | string;
  effReachPct: number | string;
  impressions: number | string;
  surveyCode?: string;
  duration: number | string;
  grps: number | string;
  buyingImpressions: number | string;
  buyingGrps: number | string;
  freqCapping: number | string;
  mediaType: string;
  resps: number | string;
  inserts: number | string;
  cpm: number | string;
  cpp: number | string;
  buyingCpm: number | string;
  buyingCpp: number | string;
  baseCpm?: number;
  unitCost: number | string;
  totalCost: number | string;
  esgScore: number | string;
  esgStability: number | string;
  compositionPct?: number | string;
  compositionIndex?: number | string;
  insertsDM?: number;
  noOfMailItems?: number;
  durationDM?: number;

  metaData?: { [key: string]: unknown };
}
