export class Compression {
  static compress<T>(object: T): string[] {
    const s = JSON.stringify(object);
    const parts = s.match(/.{1,8192}/g); // split long strings into 8k chunks
    const compressed: string[] = parts.map((part) => this.compressPart(part));
    return compressed;
  }

  static compressPart(s: string): string {
    try {
      const dict = {};
      const data = (s + '').split('');
      const out = [];
      let currChar;
      let phrase = data[0];
      let code = 256;
      for (var i = 1; i < data.length; i++) {
        currChar = data[i];
        if (dict[phrase + currChar] != null) {
          phrase += currChar;
        } else {
          out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
          dict[phrase + currChar] = code;
          code++;
          phrase = currChar;
        }
      }
      out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
      for (var j = 0; j < out.length; j++) {
        out[j] = String.fromCharCode(out[j]);
      }

      const str = out.join('');
      return this.utoa(str);
    } catch (e) {
      console.log('Failed to compress string; returning empty string', e);
      return '';
    }
  }

  static decompress<T>(base64ZippedStrings: string[]): T {
    const parts: string[] = base64ZippedStrings.map((part) =>
      this.decompressPart(part)
    );
    return JSON.parse(parts.join(''));
  }

  static decompressPart(base64ZippedString: string): string {
    try {
      const s = this.atou(base64ZippedString);
      const dict = {};
      const data = (s + '').split('');
      let currChar = data[0];
      let oldPhrase = currChar;
      const out = [currChar];
      let code = 256;
      let phrase;
      for (var i = 1; i < data.length; i++) {
        var currCode = data[i].charCodeAt(0);
        if (currCode < 256) {
          phrase = data[i];
        } else {
          phrase = dict[currCode] ? dict[currCode] : oldPhrase + currChar;
        }
        out.push(phrase);
        currChar = phrase.charAt(0);
        dict[code] = oldPhrase + currChar;
        code++;
        oldPhrase = phrase;
      }
      return out.join('');
    } catch (e) {
      console.log('Failed to decompress string; returning empty string', e);
      return '';
    }
  }

  // ucs-2 string to base64 encoded ascii
  static utoa(str: string): string {
    return window.btoa(unescape(encodeURIComponent(str)));
  }
  // base64 encoded ascii to ucs-2 string
  static atou(str: string): string {
    return decodeURIComponent(escape(window.atob(str)));
  }
}
