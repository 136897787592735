import { TupDocumentEventHandlerDirective } from '@telmar-global/tup-document-storage';
import { invert } from 'lodash';

export interface Metrics {
  reach?: number;
  customReach000?: number;
  impressions?: number;
  buyingImpressions?: number;
  buyingGrps?: number;
  duration?: number;
  inserts?: number;
  reference?: string;
  freqCapping?: number;
  type?: ResultType;
  effectiveReach?: number;
  effectiveReachPct?: number;
  reachedExactly?: number[];
  reachedExactlyPct?: number[];
  reachedAtLeast000?: number[];
  reachedAtLeastPct?: number[];
  impressionsFD?: number[];
  uniqueReach000?: number;
  esgScore?: number;
  esgStability?: number;
  insertsDM?: number;
  noOfMailItems?: number;
  durationDM?: number;
  population?: number;
  cpp?: number;
  cpm?: number;
  buyingCpp?: number;
  buyingCpm?: number;
  baseCpm?: number;
  unitCost?: number;
  totalCost?: number;
  costMethod?: CostMethod;
  costTargetId?: string;
  dirty?: boolean;
}

// call addResults() with this to clear inputs
export const EMPTY_RESULTS: Metrics = {
  inserts: 0,
  reach: 0,
  customReach000: 0,
  impressions: 0,
  buyingImpressions: 0,
  buyingGrps: 0,
  effectiveReach: 0,
  effectiveReachPct: 0,
  reachedExactly: [],
  reachedExactlyPct: [],
  reachedAtLeast000: [],
  reachedAtLeastPct: [],
  impressionsFD: [],
  uniqueReach000: 0,
  insertsDM: 0,
  noOfMailItems: 0,
  esgStability: -1,
  dirty: false,
};

export const EMPTY_COSTS: Metrics = {
  cpp: 0,
  cpm: 0,
  buyingCpp: 0,
  buyingCpm: 0,
  baseCpm: 0,
  unitCost: 0,
  totalCost: 0,
};

export enum CostMethod {
  none,
  unitCost,
  cpm,
  cpp,
  baseCpm,
}

export enum ResultType {
  vehicle,
  daypart, // Only used when loading older campaigns that dont have dayparts organised into the Spotplan objects
  broadcast,
}

/**
 * Result class
 * Contains the basic properites for calcuating most of the result metrex required in various grids
 * Supply valid reach, populations and impacts through the constructor is enough to supply most of the known results
 */
export class Result {
  reference: string;
  type: ResultType = ResultType.vehicle;
  broadcastSpotplanId: string;
  dirty: boolean = false;

  costMethod: CostMethod = CostMethod.none;
  inserts: number = 0;
  duration: number = 1;
  reach: number = 0;
  customReach000: number = undefined; // used exclusively by DAU files evaluaiton results, already multiplied out by the correct population
  impressions: number = 0;
  buyingImpressions: number = 0;
  buyingGrps: number = 0;
  freqCapping: number = 0;
  effectiveReach: number = 0;
  effectiveReachPct: number = 0;
  reachedExactly: number[] = [];
  reachedExactlyPct: number[] = [];
  reachedAtLeast000: number[] = [];
  reachedAtLeastPct: number[] = [];
  impressionsFD: number[] = [];
  uniqueReach000: number = 0;
  esgScore: number = -1;
  esgStability: number = -1;
  insertsDM: number = 0;
  noOfMailItems: number = 0;
  durationDM: number = 1;

  cpp: number = 0;
  cpm: number = 0;
  buyingCpp: number = 0;
  buyingCpm: number = 0;
  baseCpm?: number = 0;
  unitCost: number = 0;
  totalCost: number = 0;
  costTargetId: string = '';

  get reach000(): number {
    return this.customReach000 || this.reach * this.population;
  }

  get reachPct(): number {
    return this.reach * 100;
  }

  get GRPs(): number {
    return this.population ? (this.impressions / this.population) * 100 : 0;
  }

  get grps(): number {
    return this.GRPs;
  }

  get avgFrequency(): number {
    return this.reach000 ? this.impressions / this.reach000 : 0;
  }

  get effReach(): number {
    return this.effectiveReach;
  }

  get effReachPct(): number {
    return this.effectiveReachPct;
  }

  get uniqueReachPct(): number {
    return this.population
      ? ((this.uniqueReach000 || 0) / this.population) * 100
      : 0;
  }

  constructor(public population: number = 0, results: Metrics = null) {
    if (results) this.addResults(results);
  }

  addResults(values: Metrics) {
    if (typeof values.dirty === 'undefined') {
      values.dirty = this.checkDirty(values);
    }

    const keys = Object.keys(values).filter(
      (key) => typeof values[key] !== 'undefined'
    );
    keys.forEach((key) => {
      this[key] = Array.isArray(values[key]) ? [...values[key]] : values[key];
    });
  }

  zeroResults(): void {
    // no inserts, exclude from evaluation and zero the results
    this.addResults(EMPTY_RESULTS);
    this.dirty = false;

    // determine which costs to zero (preserve the cost method cost, but zero the rest as they're based on impressions or GRPs)
    switch (this.costMethod) {
      case CostMethod.unitCost:
        this.addResults({
          cpp: 0,
          cpm: 0,
          buyingCpm: 0,
          buyingCpp: 0,
          baseCpm: 0,
        });
        break;
      case CostMethod.cpm:
      case CostMethod.baseCpm:
        // clear everything thats not CPM so the user entry remains
        this.addResults({
          cpp: 0,
          buyingCpp: 0,
          totalCost: 0,
        });
        break;
      case CostMethod.cpp:
        // clear everything thats not CPP so the user entry remains
        this.addResults({
          cpm: 0,
          baseCpm: 0,
          buyingCpm: 0,
          totalCost: 0,
        });
        break;

      default:
        break;
    }
  }

  anyInput(): boolean {
    return !!(this.inserts || this.buyingImpressions || this.insertsDM);
  }

  // if any of the fields in the string array are changed then set dirty: true
  checkDirty(values: Metrics): boolean {
    // includes cost fields as they also need R&F currently
    const dirtyFieldsTest = [
      'inserts',
      'buyingImpressions',
      'insertsDM',
      'cpp',
      'cpm',
      'buyingCpp',
      'buyingCpm',
      'baseCpm',
    ];
    let dirty = this.dirty;
    dirtyFieldsTest.forEach((field) => {
      if (typeof values[field] !== 'undefined') {
        dirty = dirty || values[field] !== this[field];
      }
    });
    return dirty;
  }

  copy(): Metrics {
    return {
      reach: this.reach,
      customReach000: this.customReach000,
      impressions: this.impressions,
      buyingImpressions: this.buyingImpressions,
      buyingGrps: this.buyingGrps,
      duration: this.duration,
      inserts: this.inserts,
      reference: this.reference,
      type: this.type,
      effectiveReach: this.effectiveReach,
      effectiveReachPct: this.effectiveReachPct,
      reachedExactly: this.reachedExactly,
      reachedExactlyPct: this.reachedExactlyPct,
      reachedAtLeast000: this.reachedAtLeast000,
      reachedAtLeastPct: this.reachedAtLeastPct,
      impressionsFD: this.impressionsFD,
      uniqueReach000: this.uniqueReach000,
      esgScore: this.esgScore,
      esgStability: this.esgStability,
      insertsDM: this.insertsDM,
      noOfMailItems: this.noOfMailItems,
      durationDM: this.durationDM,
      population: this.population,
      cpp: this.cpp,
      cpm: this.cpm,
      buyingCpp: this.buyingCpp,
      buyingCpm: this.buyingCpm,
      baseCpm: this.baseCpm,
      unitCost: this.unitCost,
      totalCost: this.totalCost,
      costMethod: this.costMethod,
      costTargetId: this.costTargetId,
    };
  }
}
