import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { TreeTableMenuItem } from '../tree-table.models';

@Component({
  selector: 'column-menu',
  templateUrl: './column-menu.component.html',
  styleUrls: ['./column-menu.component.scss'],
})
export class ColumnMenuComponent implements OnInit {
  @Input() main: boolean = false; // if main = true then the mouse fadein/fadeout will be ignored (column icon vs main menu icon)
  @Input() menu: TreeTableMenuItem[] = [];
  @Output() itemClick: EventEmitter<TreeTableMenuItem> =
    new EventEmitter<TreeTableMenuItem>();
  @ViewChild('columnMenu') columnMenu: MatMenu;

  constructor() {}

  ngOnInit(): void {}

  onClick(item: TreeTableMenuItem) {
    this.itemClick.emit(item);
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault();
  }
}
