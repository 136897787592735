import { Component, OnInit } from '@angular/core';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { UserAction } from '@telmar-global/tup-auth';
import { GAEvents } from '../../models/analytics.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  appVersion: string = '0';

  constructor(
    private analyticsService: TupAnalyticsService,
    private dialogService: MatDialog
  ) {}

  ngOnInit(): void {
    this.dialogService.closeAll();
  }

  onUserAction(userAction: UserAction) {
    console.log(userAction);
    this.analyticsService.e(GAEvents.custom_user_engagement, {
      action: userAction.toString(),
    });
  }
}
