import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { MediaPlannerService } from '../services/media-planner.service';
import { Observable, of } from 'rxjs';
import { EditorComponent } from '../pages/editor/editor.component';
import {
  TupQuestionDialogModelButton,
  TupUserMessageService,
} from '@telmar-global/tup-user-message';
import { QuestionDialogModelOptions } from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import { StatusSnackbarIcon } from '../components/dialogs/snackbar-generic/snackbar-generic.component';
import { DialogService } from '../services/dialog.service';

@Injectable({ providedIn: 'root' })
export class DocumentDirtyGuard implements CanDeactivate<EditorComponent> {
  constructor(
    private mediaplannerService: MediaPlannerService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  canDeactivate(
    component: EditorComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> {
    const redirecting = this.mediaplannerService.plan.campaignRedirecting;
    this.mediaplannerService.plan.campaignRedirecting = false;

    return !this.mediaplannerService.plan.campaignStarted ||
      !this.mediaplannerService.plan.campaignDirty ||
      redirecting
      ? of(true)
      : new Observable((canDeactivate) => {
          const options: QuestionDialogModelOptions = {
            buttons: [
              { caption: 'Discard', data: 'nosave' },
              { caption: 'Save', data: 'save', flat: true },
            ],
            closeButton: { caption: 'Close', data: 'cancel' },
            snackbar: {
              type: 'warning',
              message:
                'Please note that you have unsaved changes in this campaign.<br>Leaving without saving will result in losing your work.',
              align: 'center',
              icon: StatusSnackbarIcon.Warning,
            },
          };

          this.dialogService
            .confirmation('', 'Save your work?', options)
            .afterClosed()
            .subscribe((button) => {
              // close button was pressed
              if (button.data === 'cancel') {
                canDeactivate.next(false);
                canDeactivate.complete();
              } else {
                // save / nosave pressed
                const saveComplete =
                  button.data === 'save'
                    ? this.mediaplannerService.saveCampaignWithPrompts(
                        false,
                        false,
                        true
                      )
                    : of(true);

                saveComplete.subscribe((result) => {
                  canDeactivate.next(result);
                  canDeactivate.complete();
                });
              }
            });
        });
  }
}
