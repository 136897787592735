import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectMenuOption } from 'src/app/models/application.model';
import {
  CHART_TYPES,
  CHART_TYPES_FREQ_DISTR,
  ChartColor,
  ChartColors,
  ChartFilter,
  ChartFilterOperator,
  ChartSettings,
  ChartSettingsMode,
  ChartTargetMode,
  COLUMN_SORT_ORDERS,
  ColumnSortOrder,
  // DataItemSelection,
  // EXTRA_TABLE_SETTING_OPTIONS,
  FILTER_OPERATORS,
  GraphSelectionValue,
  NO_SECONDARY_CHART_TYPES,
  SECONDARY_CHART_TYPES,
  SECONDARY_CHART_TYPES_FREQ_DISTR,
  SelectMenuOptionChart,
  unsuitableChartMixed,
  ChartTarget,
  ChartType,
} from 'src/app/models/charts.model';
import { ColorPickerService } from 'src/app/services/color-picker.service';
import { cloneDeep } from 'lodash';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DocumentService } from 'src/app/services/document.service';

export interface ChartSettingsDialogDataModel {
  targetMode: ChartTargetMode;
  chartSettingsMode: ChartSettingsMode;
  config: ChartSettings;
  targets: ChartTarget[];
  chartType: ChartType;
  groupName?: string;
}

@Component({
  templateUrl: './chart-settings-dialog.component.html',
  styleUrls: ['./chart-settings-dialog.component.scss'],
})
export class ChartSettingsDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public readonly placeholderColor = '#f65354';
  public readonly maxAxisLabelAngle = 360;
  public readonly maxDecimalPlaces = 9;
  public readonly chartTypes: SelectMenuOptionChart<GraphSelectionValue>[] =
    CHART_TYPES; //.slice(0, -1);
  //  public readonly chartDataItems: SelectMenuOption<string>[] = CHART_DATA_ITEMS;
  public readonly secondaryChartTypes: SelectMenuOptionChart<GraphSelectionValue>[] =
    SECONDARY_CHART_TYPES;
  public readonly columnSortOrders: ColumnSortOrder[] = COLUMN_SORT_ORDERS;
  // public readonly extraTableSettings: DataItemSelection[] =
  //   EXTRA_TABLE_SETTING_OPTIONS;
  public readonly filterOperators: ChartFilterOperator[] = FILTER_OPERATORS;
  public readonly chartTargetModeType: typeof ChartTargetMode = ChartTargetMode;
  public readonly chartSettingsModeType: typeof ChartSettingsMode =
    ChartSettingsMode;

  get chartDataItems(): SelectMenuOption<string>[] {
    return this.data.config.chartDataItems;
  }

  public chartTargetMode: ChartTargetMode;
  public chartSettingsMode: ChartSettingsMode;
  public chartSettings: ChartSettings;
  public targets: ChartTarget[];
  public groupName: string;

  public isSecondaryChartTypeDisabled = false;
  public isSuitableChartForMixing: boolean;
  public isColorExpanded = false;
  public isAdditionalSeries = false;
  public chartColors: ChartColor[];

  public ready: boolean = false;

  //public displayType: DisplayType = DisplayType.shortTitle;
  public displayType: any;

  constructor(
    public dialogRef: MatDialogRef<ChartSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChartSettingsDialogDataModel,
    private colorPicker: ColorPickerService,
    private documentService: DocumentService
  ) {
    this.chartTypes =
      data.chartType === ChartType.FREQ_DISTR
        ? CHART_TYPES_FREQ_DISTR
        : CHART_TYPES;
    this.secondaryChartTypes =
      data.chartType === ChartType.FREQ_DISTR
        ? SECONDARY_CHART_TYPES_FREQ_DISTR
        : SECONDARY_CHART_TYPES;
    this.chartTargetMode = data.targetMode;
    this.chartSettingsMode = data.chartSettingsMode;
    this.chartSettings = cloneDeep(data.config);
    this.targets = data.targets;
    this.groupName = data.groupName;
    this.ready = false;
  }

  ngOnInit(): void {
    this.formatChartColors(this.getColumnColours());
    this.isSecondaryChartTypeDisabled =
      this.shouldDisableSecondaryChartType(
        this.chartSettings.primaryChartType
      ) || this.chartSettings.secondaryDataItem === 'None';
    this.isSuitableChartForMixing = !unsuitableChartMixed.includes(
      this.chartSettings.primaryChartType
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ready = true;
    });
  }

  public getColumnColours(): Record<string, string> {
    const columnColors: Record<string, string> = {};
    this.targets.forEach((target: ChartTarget, index: number) => {
      const finalIndex =
        index >= ChartColors.length ? index % ChartColors.length : index;
      columnColors[target.id] = ChartColors[finalIndex];
    });
    return columnColors;
  }

  ngOnDestroy(): void {}

  // public onEditColorToggle(): void {
  //   this.isColorExpanded = !this.isColorExpanded;
  // }

  // public onTargetSelectionToggle(): void {
  //   this.isAdditionalSeries = !this.isAdditionalSeries;
  // }

  public onPrimaryChartTypeChange(): void {
    if (
      this.shouldDisableSecondaryChartType(
        this.chartSettings.primaryChartType
      ) ||
      this.chartSettings.secondaryDataItem === 'None'
    ) {
      this.isSecondaryChartTypeDisabled = true;
      this.chartSettings.secondaryChartType = 'None';
    } else {
      this.isSecondaryChartTypeDisabled = false;
    }

    // exception: set a default data item for secondary data item if the primary chart type is scatter
    if (
      this.chartSettings.primaryChartType === 'tupScatter' &&
      this.chartSettings.secondaryDataItem === 'None'
    ) {
      this.onSecondaryDataItemChange('%Row');
      this.chartSettings.secondaryDataItem = '%Row';
    }

    this.isSuitableChartForMixing = !unsuitableChartMixed.includes(
      this.chartSettings.primaryChartType
    );
    if (
      !this.isSuitableChartForMixing &&
      this.chartSettings.primaryDataItem !==
        this.chartSettings.secondaryDataItem
    ) {
      //this.setExtraTableSettingsSelected( this.chartSettings.secondaryDataItem, false );
      this.chartSettings.secondaryDataItem = 'None';
    }
  }

  public onPrimaryDataItemChange(dataItem: string): void {
    // if ( this.chartSettings.primaryDataItem !== this.chartSettings.secondaryDataItem) {
    //   this.setExtraTableSettingsSelected( this.chartSettings.primaryDataItem, false );
    // }
    // this.setExtraTableSettingsSelected(dataItem, true);
  }

  public onSecondaryDataItemChange(dataItem: string): void {
    this.isSecondaryChartTypeDisabled = dataItem === 'None';

    if (this.isSecondaryChartTypeDisabled) {
      this.chartSettings.secondaryChartType = 'None';
    }

    // if (
    //   this.chartSettings.primaryDataItem !== this.chartSettings.secondaryDataItem ) {
    //   this.setExtraTableSettingsSelected(this.chartSettings.secondaryDataItem, false );
    // }
    // this.setExtraTableSettingsSelected(dataItem, true);
  }

  public onNumberOfChartInAxisLabelChange(): void {
    if (
      this.isInvalidNoneNegativeNumber(
        this.chartSettings.numberOfChartInAxisLabel
      )
    ) {
      this.chartSettings.numberOfChartInAxisLabel = 0;
    }
  }

  public onTopRowsCountChange(): void {
    if (this.isInvalidNoneNegativeNumber(this.chartSettings.topRowsCount)) {
      this.chartSettings.topRowsCount = 0;
    }

    if (
      !!this.chartSettings.maxRowsCount &&
      this.chartSettings.topRowsCount > this.chartSettings.maxRowsCount
    ) {
      this.chartSettings.topRowsCount = this.chartSettings.maxRowsCount;
    }
  }

  public onAxisLabelAngleChange(): void {
    if (this.isInvalidNoneNegativeNumber(this.chartSettings.axisLabelAngle)) {
      this.chartSettings.axisLabelAngle = 0;
    }

    if (this.chartSettings.axisLabelAngle > this.maxAxisLabelAngle) {
      this.chartSettings.axisLabelAngle = this.maxAxisLabelAngle;
    }
  }

  public onFlagRowRespsChange(): void {
    if (
      this.isInvalidNoneNegativeNumber(this.chartSettings.flagRowRespsValue)
    ) {
      this.chartSettings.flagRowRespsValue = 0;
    }
  }

  public onDecimalPlacesChange(): void {
    if (this.isInvalidNoneNegativeNumber(this.chartSettings.decimalPlaces)) {
      this.chartSettings.decimalPlaces = 0;
    }

    if (this.chartSettings.decimalPlaces > this.maxDecimalPlaces) {
      this.chartSettings.decimalPlaces = this.maxDecimalPlaces;
    }
  }

  // public onExtraTableSettingsChange(
  //   event: MatCheckboxChange,
  //   dataItem: string
  // ): void {
  //   this.setExtraTableSettingsSelected(dataItem, event.checked);
  // }

  public onFilterCancelClick(filter: ChartFilter): void {
    if (this.chartSettingsMode !== ChartSettingsMode.global) {
      filter.target = 'None';
    }
    filter.dataItem = 'None';
    filter.operator = ChartFilterOperator.none;
    filter.value[0] = 0;
    filter.value[1] = 0;
  }

  public onFilterValueChange(filter: ChartFilter): void {
    if (this.isInvalidNoneNegativeNumber(filter.value[0])) {
      filter.value[0] = 0;
    }

    if (this.isInvalidNoneNegativeNumber(filter.value[1])) {
      filter.value[1] = 0;
    }
  }

  public colorPickerDialog(index: number): void {
    this.colorPicker
      .chartColor({
        target: this.chartColors[index].title,
        colors: undefined,
      })
      .afterClosed()
      .subscribe((color: string) => {
        if (color) {
          this.changeColor(
            index,
            this.chartColors[index].colors.length - 1,
            color.toUpperCase()
          );
        }
      });
  }

  public changeColor(index: number, colorIndex: number, color: string): void {
    const prevSelectedColorIndex = this.chartColors[index].colors.findIndex(
      (colorItem) => colorItem.isSelected
    );
    this.chartColors[index].colors[prevSelectedColorIndex].isSelected = false;
    this.chartColors[index].colors[colorIndex].color = color;
    this.chartColors[index].colors[colorIndex].isSelected = true;
  }

  public onButtonClick(OkButton: boolean): void {
    if (OkButton) {
      this.chartSettings.targetSelection = this.targets;
      this.normaliseSeriesColors();
    }

    this.dialogRef.close(OkButton ? this.chartSettings : null);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  //receive the columnColors from the documentService and spread across the targets
  private formatChartColors(columnColors: Record<string, string>): void {
    const colors: string[] = ChartColors.slice(0, 6);
    const seriesColors: Record<string, string> = this.chartSettings.seriesColor;
    this.chartColors = this.targets.map((target: ChartTarget) => {
      const targetColor =
        target.id in seriesColors
          ? seriesColors[target.id]
          : columnColors[target.id];
      const isDefaultColor = colors.includes(targetColor);
      return {
        title: target.title,
        colors: [
          ...colors.map((color: string) => ({
            isSelected: targetColor === color,
            color,
          })),
          {
            isSelected: !(
              isDefaultColor && targetColor !== this.placeholderColor
            ),
            color: isDefaultColor ? this.placeholderColor : targetColor,
          },
        ],
        targetId: target.id,
      };
    });
  }

  private normaliseSeriesColors(): void {
    if (this.chartSettingsMode === ChartSettingsMode.single) {
      this.chartSettings.seriesColor = {
        [this.targets[0].id]: this.chartColors[0].colors.find(
          (colorItem) => colorItem.isSelected
        ).color,
      };
    } else {
      this.chartSettings.seriesColor = this.chartColors.reduce(
        (prev, chartColor: ChartColor) => ({
          ...prev,
          [chartColor.targetId]: chartColor.colors.find(
            (colorItem) => colorItem.isSelected
          ).color,
        }),
        {}
      );
    }
  }

  private shouldDisableSecondaryChartType(
    primaryChartType: GraphSelectionValue
  ): boolean {
    return NO_SECONDARY_CHART_TYPES.includes(primaryChartType);
  }

  private isInvalidNoneNegativeNumber(value: any): boolean {
    return value < 0 || value === null || isNaN(value);
  }

  // extra table settings were hardcoded to Cols,Rows,Tables, etc and not needed
  // private setExtraTableSettingsSelected( dataItem: string, selected: boolean ): void {
  //   if (dataItem === 'None') {
  //     return;
  //   }
  //   if (selected) {
  //     if (this.chartSettings.extraTableSettings.indexOf(dataItem) === -1) {
  //       this.chartSettings.extraTableSettings.push(dataItem);
  //     }
  //   } else {
  //     this.chartSettings.extraTableSettings =
  //       this.chartSettings.extraTableSettings.filter(
  //         (item: string) => item !== dataItem
  //       );
  //   }
  // }
}
