import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  SpotplanAllocation,
  SpotplanAllocationText,
  SpotplanNaturalDelivery,
  SpotplanStrategy,
  SpotplanStrategyText,
  SpotplanWeekBreakdown,
  SpotplanWeekBreakdownText,
} from 'src/app/classes/spotplan-schedule';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { TreeTableColumn } from '../../tree-table/tree-table.models';

export interface AllocationModel {
  name: string;
  value: SpotplanAllocation;
  tooltip: string;
}

export interface WeekBreakdownModel {
  name: string;
  value: SpotplanWeekBreakdown;
}

export interface SpotSettingsDialogModel {
  duration?: number;
  allocation?: SpotplanAllocation;

  naturalDelivery?: SpotplanNaturalDelivery;
  clearSpots?: boolean;
}

@Component({
  selector: 'spot-settings-dialog',
  templateUrl: './spot-settings-dialog.component.html',
  styleUrls: ['./spot-settings-dialog.component.scss'],
})
export class SpotSettingsDialogComponent implements OnInit {
  allocationOptions: AllocationModel[] = [
    {
      name: SpotplanAllocationText[SpotplanAllocation.manual],
      value: SpotplanAllocation.manual,
      tooltip: '',
    },
    {
      name: SpotplanAllocationText[SpotplanAllocation.naturalDelivery],
      value: SpotplanAllocation.naturalDelivery,
      tooltip:
        'Ratings are divided among media vehicles in ratio to their audience size resulting in average reach for the media',
    },
  ];

  weekBreakdownOptions: WeekBreakdownModel[] = [
    {
      name: SpotplanWeekBreakdownText[SpotplanWeekBreakdown.weekdays],
      value: SpotplanWeekBreakdown.weekdays,
    },
    {
      name: SpotplanWeekBreakdownText[SpotplanWeekBreakdown.weekend],
      value: SpotplanWeekBreakdown.weekend,
    },
    {
      name: SpotplanWeekBreakdownText[SpotplanWeekBreakdown.allweek],
      value: SpotplanWeekBreakdown.allweek,
    },
  ];

  get strategyLabel() {
    return (
      this.strategyColumns.find(
        (col) => col.columnDef === this.selectedStrategy
      )?.header || ''
    );
  }

  //Only examples to see some values. to be removed after will be saved in ngOnInit()
  duration: number = 1;
  currentWeekBreakdown: SpotplanWeekBreakdown;
  allocationSelectedOption: SpotplanAllocation;
  strategyColumns: TreeTableColumn[];
  selectedStrategy: SpotplanStrategy;
  goal: number = 0;

  constructor(
    public dialogRef: MatDialogRef<SpotSettingsDialogComponent>,
    private mediaplannerSevice: MediaPlannerService,
    @Inject(MAT_DIALOG_DATA) public data: SpotSettingsDialogModel
  ) {}

  ngOnInit(): void {
    this.duration = this.data.duration;
    this.currentWeekBreakdown = this.data.naturalDelivery.breakdown;
    this.allocationSelectedOption = this.data.allocation;
    this.selectedStrategy = this.data.naturalDelivery.strategy;
    this.goal = this.data.naturalDelivery.goal;

    this.strategyColumns =
      this.mediaplannerSevice.plan.spotColumns.allSpotColumns;
  }

  onClose(save: boolean) {
    if (save) {
      this.data.duration = this.duration;
      this.data.allocation = this.allocationSelectedOption;
      this.data.naturalDelivery = {
        breakdown: this.currentWeekBreakdown,
        strategy: this.selectedStrategy,
        goal: this.goal,
      };
    }
    this.dialogRef.close(save ? this.data : null);
  }

  onDistributionChange(event: any) {
    this.strategyColumns =
      this.mediaplannerSevice.plan.spotColumns.allSpotColumns.filter(
        (column) => column.columnDef !== event
      );
  }

  onClearSpots() {
    this.dialogRef.close({ clearSpots: true });
  }
}
