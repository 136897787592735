import { CostMethod } from './result';

// call addResults() with this to clear inputs
export const EMPTY_DAYPART_RESULTS: DaypartMetrics = {
  week: undefined,
  targetId: undefined,
  inserts: 0,
  reachPct: 0,
  reach000: 0,
  impressions: 0,
  GRPs: 0,
  unitCost: 0,
  totalCost: 0,
  dirty: false,
};

// week and target the results are part of
export interface DaypartMetrics {
  // week and target the results are part of
  day?: number;
  week?: number;
  targetId?: string;

  // results for the above week and target
  inserts?: number;
  duration?: number;
  reachPct?: number;
  reach000?: number;
  impressions?: number;
  GRPs?: number;
  unitCost?: number;
  totalCost?: number;
  cpp?: number;
  cpm?: number;
  baseCpm?: number;
  costMethod?: CostMethod;
  costTargetId?: string;
  dirty?: boolean;
}

export class DaypartResult {
  day: number = 0;
  week: number = 0;
  targetId: string = '';
  dirty: boolean = false;

  inserts: number = 0;
  reachPct: number = 0;
  reach000: number = 0;
  impressions: number = 0;
  GRPs: number = 0;
  baseCpm: number;
  unitCost: number;
  totalCost: number;
  cpp: number;
  cpm: number;
  costTargetId: string;
  duration?: number;

  costMethod: CostMethod = CostMethod.none;

  constructor(values?: DaypartMetrics) {
    if (values) this.addResults(values);
  }

  addResults(values: DaypartMetrics) {
    if (typeof values.dirty === 'undefined') {
      values.dirty = this.checkDirty(values);
    }

    const keys = Object.keys(values).filter(
      (key) => typeof values[key] !== 'undefined'
    );
    keys.forEach((key) => {
      this[key] = Array.isArray(values[key]) ? [...values[key]] : values[key];
    });
  }

  checkDirty(values: DaypartMetrics): boolean {
    // includes cost fields as they also need R&F currently
    const dirtyFieldsTest = [
      'inserts',
      'buyingImpressions',
      'insertsDM',
      'cpp',
      'cpm',
      'buyingCpp',
      'buyingCpm',
      'baseCpm',
    ];
    let dirty = this.dirty;
    dirtyFieldsTest.forEach((field) => {
      if (typeof values[field] !== 'undefined') {
        dirty = dirty || values[field] !== this[field];
      }
    });
    return dirty;
  }

  zeroResults(): void {
    // no inserts, exclude from evaluation and zero the results
    this.addResults(EMPTY_DAYPART_RESULTS);

    // determine which costs to zero (preserve the cost method cost, but zero the rest as they're based on impressions or GRPs)
    switch (this.costMethod) {
      case CostMethod.unitCost:
        this.addResults({
          week: this.week,
          targetId: this.targetId,
          cpp: 0,
          cpm: 0,
        });
        break;
      case CostMethod.cpm:
        // clear everything thats not CPM so the user entry remains
        this.addResults({
          week: this.week,
          targetId: this.targetId,
          cpp: 0,
          totalCost: 0,
        });
        break;
      case CostMethod.cpp:
        // clear everything thats not CPP so the user entry remains
        this.addResults({
          week: this.week,
          targetId: this.targetId,
          cpm: 0,
          totalCost: 0,
        });
        break;

      default:
        break;
    }
  }

  copy(): DaypartMetrics {
    return {
      week: this.week,
      targetId: this.targetId,
      inserts: this.inserts,
      reachPct: this.reachPct,
      reach000: this.reach000,
      impressions: this.impressions,
      GRPs: this.GRPs,
      unitCost: this.unitCost,
      totalCost: this.totalCost,
      cpp: this.cpp,
      cpm: this.cpm,
      costMethod: this.costMethod,
      costTargetId: this.costTargetId,
    };
  }
}
