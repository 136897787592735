<div
  fxLayout="row"
  class="container spacing cell-editor"
  [ngClass]="{
    readonly: readonly,
    focused: editing,
    notfocused: !editing
  }"
>
  <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>

  <div fxFlex>
    <ng-container
      [ngTemplateOutlet]="editing ? editorTemplate : staticTemplate"
    >
    </ng-container>
  </div>
</div>

<!-- template showing value only, waiting to enter edit mode -->
<ng-template #staticTemplate>
  <div
    (click)="onStartEditing()"
    style="width: 100%"
    [ngStyle]="{ 'text-align': align }"
  >
    {{ formValue.value }}
  </div>
</ng-template>

<!-- one of two edit modes active -->
<ng-template #editorTemplate>
  <input
    *ngIf="!VCBTitleEditor"
    #inputBox
    matInput
    fxFlex
    [ngStyle]="{ 'text-align': align }"
    [formControl]="formValue"
    type="text"
    (blur)="onBlur($event.target.value)"
    (keyup.enter)="onNavigation($event, 'blur')"
    (keyup.arrowup)="onNavigation($event, 'up')"
    (keyup.arrowdown)="onNavigation($event, 'down')"
    (keydown.tab)="onNavigation($event, 'down')"
    (keydown.shift.tab)="onNavigation($event, 'up')"
  />

  <div *ngIf="VCBTitleEditor">
    <input
      #inputBox
      matInput
      fxFlex
      [ngStyle]="{ 'text-align': align }"
      [formControl]="formValue"
      type="text"
      (blur)="onBlur($event.target.value)"
      (keyup.enter)="onNavigation($event, 'blur')"
      (keyup.arrowup)="onNavigation($event, 'up')"
      (keyup.arrowdown)="onNavigation($event, 'down')"
      (keydown.tab)="onNavigation($event, 'down')"
      (keydown.shift.tab)="onNavigation($event, 'up')"
    />
    <mat-icon
      matSuffix
      class="material-symbols-outlined undo-icon"
      (click)="onUndoTitleClick()"
      >undo</mat-icon
    >
    <mat-icon
      matSuffix
      class="check-icon"
      (click)="onSubmitTitleClick()"
      >check_box</mat-icon
    >
  </div>
</ng-template>

<!-- menu template -->
<ng-template #menuTemplate>
  <tree-inline-menu
    *ngIf="menu"
    class="hidden-menu"
    [menu]="menu"
    (itemClick)="itemClick.emit($event)"
    appearance="tiny"
    panelClass="cell-edit-inline-menu"
  ></tree-inline-menu>
</ng-template>
