import { Injectable } from '@angular/core';
import { CrossMediaXlsxChartsBuilder } from '../builders/planning-chart-xlsx.builder';
import { CrossMediaPptxBuilder } from '../builders/planning-pptx.builder';
import {
  CrossMediaTableXlsxBuilder,
  ExcelCell,
  xlsxCellType,
} from '../builders/planning-xlsx.builder';
import { Schedule } from '../classes/schedule';
import { ScheduleTotalTag } from '../classes/schedule-total';
import { CrossMediaProps, ExportFileType } from '../models/export.model';
import { MediaPlannerService } from './media-planner.service';
import { PptxService } from './pptx.service';
import { XlsxChartsService } from './xlsx-charts.service';
import { XlsxService } from './xlsx.service';
import { EMPTY_NUMBER_CELL, EMPTY_STRING_CELL } from '../classes/planning-data';
import { frequencyDistributionColumns } from '../models/planning-freq-dist-columns.model';
import { Target } from '../classes/target';
import { TreeTableColumn } from '../components/tree-table/tree-table.models';
import { buildFreqRows } from '../utils/exportUtils';
import { AppendUnitsPipe } from '../pipes/append-units.pipe';
import { TargetVehicle } from '../classes/vehicle';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { DocumentCampaign } from '../models/document.model';

@Injectable({
  providedIn: 'root',
})
export class ExporterService {
  mediaType_All: String;
  appendUnits = new AppendUnitsPipe();

  constructor(
    private mediaplannerService: MediaPlannerService,
    private xlsxService: XlsxService,
    private pptxService: PptxService,
    private xlsxChartsService: XlsxChartsService
  ) {}

  /**
   * export to standard XLSX standalone file then trigger a download
   *
   * @param schedules The schedules list to be included in the export
   * @returns void
   */

  exportToXLSX(
    schedules: Schedule[],
    targets: Target[],
    compareTable: string
  ): void {
    switch (compareTable) {
      case 'media':
        this.exportMediaViewToXlsx(schedules, targets);
        break;
      case 'audiences':
        this.exportAudienceViewToXlsx(schedules, targets);
        break;
      case 'plans':
        this.exportPlanViewToXlsx(schedules, targets);
        break;
      case 'freqDistribution':
        this.exportFreqDistrViewToXlsx(schedules, targets);
        break;
    }
  }

  exportMediaViewToXlsx(schedules: Schedule[], targets: Target[]) {
    const unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
      targets[0].survey.code
    ).reportUnitText;

    const metricColumns =
      this.mediaplannerService.plan.columns.getVisibleColumns();
    const xlsxTableColumns = metricColumns.map((column) =>
      this.appendUnits.transform(column, unitsText)
    );

    // each schedule is a different XLSX document
    schedules.forEach((schedule) => {
      const xlsx: CrossMediaTableXlsxBuilder = new CrossMediaTableXlsxBuilder();
      xlsx.init('Telmar');
      const documentName = `${this.mediaplannerService.plan.title} - ${schedule.name} - Compare Media`;
      const worksheets: string[] = [];

      // each target in its own sheet
      targets.forEach((target) => {
        const result = this.buildCompareMediaData(
          target,
          schedule,
          metricColumns,
          xlsx,
          worksheets
        );
        xlsx.addTable(xlsxTableColumns, result.rows, result.tableData);
      });

      xlsx.build();
      this.xlsxService.saveAs(xlsx, documentName);
    });
  }

  exportAudienceViewToXlsx(schedules: Schedule[], targets: Target[]) {
    const metricColumns =
      this.mediaplannerService.plan.columns.getVisibleColumns();
    const xlsxTableColumns = targets.map((target) => target.title);
    const xlsx: CrossMediaTableXlsxBuilder = new CrossMediaTableXlsxBuilder();
    xlsx.init('Telmar');
    const schedulesNames = schedules.map((sch) => sch.name).join(' + ');
    const documentName = `${this.mediaplannerService.plan.title} - ${schedulesNames} - Compare Audiences`;
    const worksheets: string[] = [];

    // each schedule in a separate sheet
    schedules.forEach((schedule) => {
      const result = this.buildCompareAudienceData(
        targets,
        schedule,
        metricColumns,
        xlsx,
        worksheets
      );
      xlsx.addTable(xlsxTableColumns, result.rows, result.tableData);
    });

    xlsx.build();
    this.xlsxService.saveAs(xlsx, documentName);
  }

  exportPlanViewToXlsx(schedules: Schedule[], targets: Target[]) {
    const metricColumns =
      this.mediaplannerService.plan.columns.getVisibleColumns();
    const xlsxTableColumns = schedules.map((schedule) =>
      schedule.name === 'tots' ? 'Total' : schedule.name
    );
    const xlsx: CrossMediaTableXlsxBuilder = new CrossMediaTableXlsxBuilder();
    xlsx.init('Telmar');

    const schedulesNames = schedules
      .filter((sch) => sch.name !== 'tots')
      .map((sch) => sch.name)
      .join(' + ');
    const documentName = `${this.mediaplannerService.plan.title} - ${schedulesNames} - Compare Plans`;
    const worksheets: string[] = [];

    // each target in its own sheet
    targets.forEach((target) => {
      const result = this.buildComparePlansData(
        target,
        schedules,
        metricColumns,
        xlsx,
        worksheets
      );
      xlsx.addTable(xlsxTableColumns, result.rows, result.tableData);
    });

    xlsx.build();
    this.xlsxService.saveAs(xlsx, documentName);
  }

  exportFreqDistrViewToXlsx(schedules: Schedule[], targets: Target[]) {
    const unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
      targets[0].survey.code
    ).reportUnitText;

    const metricColumns = frequencyDistributionColumns;
    const xlsxTableColumns = metricColumns.map((column) =>
      this.appendUnits.transform(column, unitsText)
    );

    // each schedule is a different XLSX document
    schedules.forEach((schedule) => {
      const xlsx: CrossMediaTableXlsxBuilder = new CrossMediaTableXlsxBuilder();
      xlsx.init('Telmar');
      const documentName = `${this.mediaplannerService.plan.title} - ${schedule.name} - Compare Freq. Distribution`;
      const worksheets: string[] = [];

      // each target in its own sheet
      targets.forEach((target) => {
        const result = this.buildFrequencyDistributionData(
          target,
          schedule,
          metricColumns,
          xlsx,
          worksheets
        );
        xlsx.addTable(xlsxTableColumns, result.rows, result.tableData);
      });
      xlsx.build();
      this.xlsxService.saveAs(xlsx, documentName);
    });
  }

  buildWorksheetName(planOrTargetName: string, existingWorksheets: string[]) {
    const sanitise = (text: string): string => {
      return text
        .trim()
        .replace(/[\*\?\:\\\/\[\]]/g, '_')
        .slice(0, 27); // worksheet name: limit to 30 chars and strip out * ? : \ / [ ]
    };

    let worksheet = sanitise(planOrTargetName);
    let duplicate = existingWorksheets.find((wrk) => wrk === worksheet);
    let idx = 0;
    const original = worksheet;
    while (duplicate) {
      worksheet = `${original} (${++idx})`;
      duplicate = existingWorksheets.find((wrk) => wrk === worksheet);
    }

    return worksheet;
  }

  buildHeaderData(
    xlsx: CrossMediaTableXlsxBuilder,
    schedule: Schedule,
    target: Target,
    compareView: string
  ) {
    const date = new Date();
    xlsx.addEmptyRow().addRow(['Report Date:', `${date.toLocaleDateString()}`]);
    xlsx
      .addEmptyRow()
      .addRow(['Campaign:', this.mediaplannerService.plan.title]);
    xlsx.addRow(['Source:', target.survey.title]);
    xlsx.addRow([
      'Copyright:',
      this.mediaplannerService.plan.currentSurvey.copyrightInfo ||
        this.mediaplannerService.plan.currentSurvey.provider,
    ]);
    xlsx.addRow(['Plan:', schedule.name]);
    xlsx.addRow(['Plan view:', compareView]);
    xlsx.addEmptyRow().addRow(['Audience:', target.title]);
    xlsx.addRow([
      { value: 'Population:' },
      { value: target.population * (target.survey.units || 1), decimals: 0 },
    ]);
    xlsx.addRow([{ value: 'Sample:' }, { value: target.sample, decimals: 0 }]);

    xlsx.addEmptyRow();
    xlsx.setColumnWidths(15);
  }

  getMediaTypes(target: Target) {
    const mediaTypes: string[] = [];
    target.vehicles.forEach((vehicle) => {
      if (!mediaTypes.includes(vehicle.mediaType))
        mediaTypes.push(vehicle.mediaType);
    });
    return mediaTypes;
  }

  addHighlightedEmptyCells(numberOfCells: number, cellType?: string) {
    const line: ExcelCell[] = [];
    for (let i = 0; i < numberOfCells; i++) {
      line.push({
        value: '',
        cellType,
      });
    }
    return line;
  }

  parseCellValue(cellValue: number | string) {
    return cellValue !== EMPTY_NUMBER_CELL ? cellValue : EMPTY_STRING_CELL;
  }

  getAddressableData(vehicle: TargetVehicle) {
    let addressableData = '';
    if (vehicle.addressableConfig) {
      const addrTgt = this.mediaplannerService.plan.targets.find(
        (t) => t.id === vehicle.addressableConfig.targetId
      );
      addressableData = addrTgt ? ` - ${addrTgt.title}` : '';
    }
    return addressableData;
  }

  buildFrequencyDistributionData(
    target: Target,
    schedule: Schedule,
    vehicleColumns: TreeTableColumn[],
    xlsx: CrossMediaTableXlsxBuilder,
    worksheets: string[]
  ) {
    const worksheet = this.buildWorksheetName(target.title, worksheets);
    worksheets.push(worksheet);
    xlsx.addWorksheet(worksheet);

    // build header for the given target
    this.buildHeaderData(xlsx, schedule, target, 'Frequency Distribution');

    let tableData: ExcelCell[][] = [];
    let line: ExcelCell[] = [];

    const rows: ExcelCell[] = [
      { value: `All media`, cellType: xlsxCellType.total },
    ];
    tableData.push(
      this.addHighlightedEmptyCells(vehicleColumns.length, xlsxCellType.total)
    );
    const mediaTypes = this.getMediaTypes(target);

    const { freqLevelTo, isGroupingOn, freqUpTo, groupsOf } =
      this.mediaplannerService.plan.freqDistributionSettings;

    const freqRows = [
      ...buildFreqRows(freqLevelTo, isGroupingOn, freqUpTo, groupsOf),
    ];
    freqRows.forEach((row) => {
      const data = this.mediaplannerService.plan.getFreqDistData(
        'total',
        ScheduleTotalTag.total,
        target,
        schedule,
        row.data,
        freqLevelTo
      );

      if (data.reachedAtLeast000 >= 20) {
        rows.push({ value: row.label });
        line = [];
        vehicleColumns.forEach((column) => {
          line.push({
            value: this.parseCellValue(data[column.columnDef]),
            decimals: column.decimals | 0,
          });
        });
        tableData.push(line);
      }
    });

    while (freqRows.length > rows.length - 1) {
      freqRows.pop();
    }

    mediaTypes.forEach((mediaType) => {
      rows.push({ value: mediaType, cellType: xlsxCellType.mediaType });
      tableData.push(
        this.addHighlightedEmptyCells(
          vehicleColumns.length,
          xlsxCellType.mediaType
        )
      );
      freqRows.forEach((row) => {
        const data = this.mediaplannerService.plan.getFreqDistData(
          mediaType,
          ScheduleTotalTag.mediatype,
          target,
          schedule,
          row.data,
          freqLevelTo
        );

        rows.push({ value: row.label });
        line = [];

        vehicleColumns.forEach((column) => {
          line.push({
            value: this.parseCellValue(data[column.columnDef]),
            decimals: column.decimals | 0,
          });
        });
        tableData.push(line);
      });
    });

    return { rows, tableData };
  }

  buildCompareMediaData(
    target: Target,
    schedule: Schedule,
    vehicleColumns: TreeTableColumn[],
    xlsx: CrossMediaTableXlsxBuilder,
    worksheets: string[]
  ) {
    const worksheet = this.buildWorksheetName(target.title, worksheets);
    worksheets.push(worksheet);
    xlsx.addWorksheet(worksheet);

    // build header for the given target
    this.buildHeaderData(xlsx, schedule, target, 'Compare Media');

    let tableData: ExcelCell[][] = [];
    let line: ExcelCell[] = [];

    const rows: ExcelCell[] = [
      { value: 'Total', cellType: xlsxCellType.total },
    ];
    const mediaTypes = this.getMediaTypes(target);

    // schedule totals
    let data = this.mediaplannerService.plan.getTotalsPlanningData(
      'total',
      ScheduleTotalTag.total,
      target,
      schedule
    );
    vehicleColumns.forEach((column) => {
      line.push({
        value: this.parseCellValue(data[column.columnDef]),
        decimals: column.decimals | 0,
        cellType: xlsxCellType.total,
      });
    });
    tableData.push(line);

    // each media type
    mediaTypes.forEach((mediaType) => {
      line = [];
      data = this.mediaplannerService.plan.getTotalsPlanningData(
        mediaType,
        ScheduleTotalTag.mediatype,
        target,
        schedule
      );
      vehicleColumns.forEach((column) => {
        line.push({
          value: this.parseCellValue(data[column.columnDef]),
          decimals: column.decimals | 0,
          cellType: xlsxCellType.mediaType,
        });
      });
      tableData.push(line);
      rows.push({ value: mediaType, cellType: xlsxCellType.mediaType });

      // add rows for each vehicle in media type
      const vehicles = target.vehicles.filter((v) => v.mediaType === mediaType);
      vehicles.forEach((vehicle) => {
        line = [];
        const vehData = this.mediaplannerService.plan.getVehiclePlanningData(
          target,
          schedule,
          vehicle
        );
        const isPercentageInserts =
          this.mediaplannerService.plan.surveyMetaData.reachFreq.isPercentageInserts(
            vehicle
          );
        vehicleColumns.forEach((column) => {
          const vehicleValue =
            isPercentageInserts && column.columnDef === 'inserts'
              ? this.parseCellValue(
                  Number(vehData[column.columnDef]).toFixed(2)
                ) + '%'
              : this.parseCellValue(vehData[column.columnDef]);
          line.push({
            value: vehicleValue,
            decimals: column.decimals | 0,
          });
        });

        tableData.push(line);

        const addressableData = this.getAddressableData(vehicle);
        rows.push({ value: `${vehicle.title}${addressableData}` });
      });
    });

    return { rows, tableData };
  }

  buildCompareAudienceData(
    targets: Target[],
    schedule: Schedule,
    vehicleColumns: TreeTableColumn[],
    xlsx: CrossMediaTableXlsxBuilder,
    worksheets: string[]
  ) {
    const worksheet = this.buildWorksheetName(schedule.name, worksheets);
    worksheets.push(worksheet);
    xlsx.addWorksheet(worksheet);

    // build header
    const date = new Date();
    xlsx.addEmptyRow().addRow(['Report Date:', `${date.toLocaleDateString()}`]);
    xlsx
      .addEmptyRow()
      .addRow(['Campaign:', this.mediaplannerService.plan.title]);
    xlsx.addRow(['Source:', targets[0].survey.title]);
    xlsx.addRow([
      'Copyright:',
      this.mediaplannerService.plan.currentSurvey.copyrightInfo ||
        this.mediaplannerService.plan.currentSurvey.provider,
    ]);
    xlsx.addRow(['Plan:', schedule.name]);
    xlsx.addRow(['Plan view:', 'Compare Audiences']);

    xlsx.addEmptyRow();
    xlsx.setColumnWidths(15);

    const tableData: ExcelCell[][] = [];
    let line: ExcelCell[] = [];

    // build data for totals section
    const rows: ExcelCell[] = [
      { value: 'Total', cellType: xlsxCellType.total },
    ];

    const mediaTypes = this.getMediaTypes(targets[0]);
    tableData.push(
      this.addHighlightedEmptyCells(targets.length, xlsxCellType.total)
    );

    // totals data
    vehicleColumns.forEach((row) => {
      rows.push({ value: row.header });
      line = [];
      targets.forEach((target) => {
        const data = this.mediaplannerService.plan.getTotalsPlanningData(
          'total',
          ScheduleTotalTag.total,
          target,
          schedule
        );
        line.push({
          value: this.parseCellValue(data[row.columnDef]),
          decimals: row.decimals || 0,
        });
      });
      tableData.push(line);
    });

    // build rows for each media type
    mediaTypes.forEach((mediaType) => {
      rows.push({ value: mediaType, cellType: xlsxCellType.mediaType });
      tableData.push(
        this.addHighlightedEmptyCells(targets.length, xlsxCellType.mediaType)
      );

      // total per media type
      vehicleColumns.forEach((row) => {
        rows.push({ value: row.header });
        line = [];
        targets.forEach((target) => {
          const data = this.mediaplannerService.plan.getTotalsPlanningData(
            mediaType,
            ScheduleTotalTag.mediatype,
            target,
            schedule
          );
          line.push({
            value: this.parseCellValue(data[row.columnDef]),
            decimals: row.decimals || 0,
          });
        });
        tableData.push(line);
      });

      // add rows for each media vehicle in current media type
      rows.push({
        value: 'Per Media Vehicle',
        cellType: xlsxCellType.boldCell,
      });
      tableData.push(
        this.addHighlightedEmptyCells(targets.length, xlsxCellType.boldCell)
      );

      const vehiclesForCurrentMediaType = targets[0].vehicles.filter(
        (vehicle) => vehicle.mediaType === mediaType
      );

      vehiclesForCurrentMediaType.forEach((vehicle) => {
        const isPercentageInserts =
          this.mediaplannerService.plan.surveyMetaData.reachFreq.isPercentageInserts(
            vehicle
          );
        const addressableData = this.getAddressableData(vehicle);

        rows.push({
          value: `${vehicle.title}${addressableData}`,
          cellType: xlsxCellType.highlightedVehicle,
        });
        tableData.push(
          this.addHighlightedEmptyCells(
            targets.length,
            xlsxCellType.highlightedVehicle
          )
        );
        vehicleColumns.forEach((row) => {
          if (row.columnDef === 'inserts' && isPercentageInserts) {
            rows.push({ value: row.header + ' %' });
          } else {
            rows.push({ value: row.header });
          }
          line = [];
          targets.forEach((target) => {
            const data = this.mediaplannerService.plan.getVehiclePlanningData(
              target,
              schedule,
              vehicle
            );
            line.push({
              value: this.parseCellValue(data[row.columnDef]),
              decimals: row.decimals || 0,
            });
          });
          tableData.push(line);
        });
      });
    });

    return { rows, tableData };
  }

  buildComparePlansData(
    target: Target,
    schedules: Schedule[],
    vehicleColumns: TreeTableColumn[],
    xlsx: CrossMediaTableXlsxBuilder,
    worksheets: string[]
  ) {
    const worksheet = this.buildWorksheetName(target.title, worksheets);
    xlsx.addWorksheet(worksheet);

    // build header for the given target
    const date = new Date();
    xlsx.addEmptyRow().addRow(['Report Date:', `${date.toLocaleDateString()}`]);
    xlsx
      .addEmptyRow()
      .addRow(['Campaign:', this.mediaplannerService.plan.title]);
    xlsx.addRow(['Source:', target.survey.title]);
    xlsx.addRow([
      'Copyright:',
      this.mediaplannerService.plan.currentSurvey.copyrightInfo ||
        this.mediaplannerService.plan.currentSurvey.provider,
    ]);
    xlsx.addRow(['Plan view:', 'Compare Plans']);

    xlsx.addEmptyRow().addRow(['Audience:', target.title]);
    xlsx.addRow([
      { value: 'Population:' },
      { value: target.population * (target.survey.units || 1), decimals: 0 },
    ]);
    xlsx.addRow([{ value: 'Sample:' }, { value: target.sample, decimals: 0 }]);
    xlsx.addEmptyRow();
    xlsx.setColumnWidths(15);

    let tableData: ExcelCell[][] = [];
    let line: ExcelCell[] = [];

    // build data for totals section
    const rows: ExcelCell[] = [
      { value: 'Total', cellType: xlsxCellType.total },
    ];
    const mediaTypes = this.getMediaTypes(target);
    tableData.push(
      this.addHighlightedEmptyCells(schedules.length, xlsxCellType.total)
    );

    vehicleColumns.forEach((row) => {
      rows.push({ value: row.header });
      line = [];
      schedules.forEach((schedule) => {
        const data = this.mediaplannerService.plan.getTotalsPlanningData(
          'total',
          ScheduleTotalTag.total,
          target,
          schedule
        );
        line.push({
          value: this.parseCellValue(data[row.columnDef]),
          decimals: row.decimals || 0,
        });
      });
      tableData.push(line);
    });

    // build rows for each media type
    mediaTypes.forEach((mediaType) => {
      rows.push({ value: mediaType, cellType: xlsxCellType.mediaType });
      tableData.push(
        this.addHighlightedEmptyCells(schedules.length, xlsxCellType.mediaType)
      );

      // total per media type
      vehicleColumns.forEach((row) => {
        rows.push({ value: row.header });
        line = [];
        schedules.forEach((schedule) => {
          const data = this.mediaplannerService.plan.getTotalsPlanningData(
            mediaType,
            ScheduleTotalTag.mediatype,
            target,
            schedule
          );
          line.push({
            value: this.parseCellValue(data[row.columnDef]),
            decimals: row.decimals || 0,
          });
        });
        tableData.push(line);
      });

      // add rows for each media vehicle in current media type
      rows.push({
        value: 'Per Media Vehicle',
        cellType: xlsxCellType.boldCell,
      });
      tableData.push(
        this.addHighlightedEmptyCells(schedules.length, xlsxCellType.boldCell)
      );

      const vehiclesForCurrentMediaType = target.vehicles.filter(
        (vehicle) => vehicle.mediaType === mediaType
      );

      vehiclesForCurrentMediaType.forEach((vehicle) => {
        const isPercentageInserts =
          this.mediaplannerService.plan.surveyMetaData.reachFreq.isPercentageInserts(
            vehicle
          );
        const addressableData = this.getAddressableData(vehicle);
        rows.push({
          value: `${vehicle.title}${addressableData}`,
          cellType: xlsxCellType.highlightedVehicle,
        });
        tableData.push(
          this.addHighlightedEmptyCells(
            schedules.length,
            xlsxCellType.highlightedVehicle
          )
        );
        vehicleColumns.forEach((row) => {
          if (row.columnDef === 'inserts' && isPercentageInserts) {
            rows.push({ value: row.header + ' %' });
          } else {
            rows.push({ value: row.header });
          }
          line = [];
          schedules.forEach((schedule) => {
            const data = this.mediaplannerService.plan.getVehiclePlanningData(
              target,
              schedule,
              vehicle
            );
            line.push({
              value: this.parseCellValue(data[row.columnDef]),
              decimals: row.decimals || 0,
            });
          });
          tableData.push(line);
        });
      });
    });
    return { rows, tableData };
  }

  async exportToPPTX(props: CrossMediaProps[]): Promise<void> {
    const builder = new CrossMediaPptxBuilder(ExportFileType.pptx);
    const initialisedBuilder = await builder.init(props);
    const documentName = this.mediaplannerService.plan.title;
    console.log('props initialisedBuilder', initialisedBuilder);
    console.log('props documentName', documentName);
    this.pptxService.saveAs(initialisedBuilder, documentName);
  }

  async exportToSlides(props: CrossMediaProps[]): Promise<void> {
    const builder = new CrossMediaPptxBuilder(ExportFileType.googleSlides);
    const initialisedBuilder = await builder.init(props);
    const documentName = this.mediaplannerService.plan.title;
    console.log('props initialisedBuilder', initialisedBuilder);
    console.log('props documentName', documentName);
    this.pptxService.exportToSlides(initialisedBuilder, documentName);
  }

  async exportChartsToXlsx(props: CrossMediaProps[]): Promise<void> {
    const builder = new CrossMediaXlsxChartsBuilder();
    const initialisedBuilder = await builder.init(props);
    const documentName = this.mediaplannerService.plan.title;
    this.xlsxChartsService.saveAs(initialisedBuilder, documentName);
  }

  async exportChartsToSheets(props: CrossMediaProps[]): Promise<void> {
    const builder = new CrossMediaXlsxChartsBuilder();
    const initialisedBuilder = await builder.init(props);
    const documentName = this.mediaplannerService.plan.title;
    this.xlsxChartsService.exportToSheets(initialisedBuilder, documentName);
  }

  exportToJSON(documentCampaign: TupDocument<DocumentCampaign>) {
    try {
      const jsonStr = JSON.stringify(documentCampaign, null, 2);
      const blob = new Blob([jsonStr], { type: 'text/json' });
      const url = URL.createObjectURL(blob);

      const docTitle = documentCampaign.metadata.name + '.plan';

      this.downloadFile(url, docTitle);
    } catch (error) {
      console.error('Error exporting to JSON:', error);
    }
  }

  private downloadFile(url: string, filename: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    URL.revokeObjectURL(url);
  }
}
