import { Component, Inject, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-internet-connection-dialog',
  templateUrl: './internet-connection-dialog.component.html',
  styleUrls: ['./internet-connection-dialog.component.scss'],
})
export class InternetConnectionDialogComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<InternetConnectionDialogComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { type: 'online' | 'offline' }
  ) {}

  ngOnInit(): void {}
}
