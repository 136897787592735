import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import {
  DisplayType,
  levelSeparator,
  Operator,
  Statement,
  SURVEYTIME_TARGET_VERSION,
  VisualCodingTarget,
  titleSeparator,
} from '../models/visual-code-builder.models';

class TargetFactory {
  public static create(
    options?: Partial<VisualCodingTarget>
  ): VisualCodingTarget {
    return {
      id: uuidv4(),
      fileVersion: SURVEYTIME_TARGET_VERSION,
      title: '',
      coding: '',
      operator: Operator.and,
      created: Date.now(),
      audience: 0,
      resps: 0,
      percent: 0,
      targets: [],
      manual: false,
      ...options,
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class TargetService {
  public titleMode = DisplayType.title;

  public titleModeUpdate: Subject<DisplayType> = new Subject<DisplayType>();

  constructor() {
    this.titleModeUpdate.subscribe((value) => (this.titleMode = value));
  }

  public updateTitleMode(newTitleMode: any) {
    this.titleModeUpdate.next(newTitleMode);
  }

  public convertStatementsToTargets(
    statements: Statement[]
  ): VisualCodingTarget[] {
    let categoryArray = [];
    if (statements.length && statements[0].category) {
      categoryArray = statements[0].category.split('|');
    }
    const parentName = categoryArray[categoryArray.length - 1] || '';

    return this.findRecursiveTargets(statements, parentName);
  }

  public createTarget(
    options?: Partial<VisualCodingTarget>
  ): VisualCodingTarget {
    return this.updateTitleAndCoding(TargetFactory.create(options));
  }

  public formatGroupName(target: VisualCodingTarget): string {
    let temp = target;
    while (temp.targets.length !== 0) {
      temp = temp.targets[0];
    }
    const titles = temp.title.split(titleSeparator);

    return titles.length > 1 ? titles[titles.length - 1].trim() : temp.title;
  }

  public addChildTarget(
    parentTarget: VisualCodingTarget,
    childTarget: VisualCodingTarget,
    operator: Operator = Operator.and
  ): void {
    if (parentTarget.targets.length > 1 && operator !== Operator.and) {
      const clonedParentTarget = cloneDeep(parentTarget);
      parentTarget.targets = [clonedParentTarget];
    }
    if (parentTarget.targets.length > 0) {
      parentTarget.targets[parentTarget.targets.length - 1].operator = operator;
    }
    parentTarget.targets.push(childTarget);
    this.updateTitleAndCoding(parentTarget);
  }

  public updateTitleAndCoding(target: VisualCodingTarget): VisualCodingTarget {
    Object.keys(DisplayType).forEach((key: string) => {
      target[key] = this.formatDisplayByType(target, key as DisplayType);
    });
    return target;
  }

  public formatDisplayByType(
    target: VisualCodingTarget,
    type: DisplayType
  ): string {
    const childrenTargets = target.targets;
    const numberOfChildrenTargets = childrenTargets.length;

    if (numberOfChildrenTargets < 1) {
      return target[type];
    }

    return childrenTargets
      .map((childTarget: VisualCodingTarget, index: number) => {
        const shouldGroupTarget =
          childTarget.targets.length > 1 ||
          (type !== DisplayType.coding &&
            childTarget.countCoding &&
            childTarget.titlePrefix);
        const shouldShowCountCoding =
          childTarget.countCoding &&
          (type === DisplayType.coding || !childTarget.titlePrefix);
        const shouldShowTitlePrefix =
          childTarget.countCoding &&
          type !== DisplayType.coding &&
          childTarget.titlePrefix;
        const additionalCoding = shouldShowCountCoding
          ? ` ${childTarget.countCoding.operator} ${childTarget.countCoding.value}`
          : '';
        return `${shouldShowTitlePrefix ? childTarget.titlePrefix : ''}${
          shouldShowCountCoding ? '(' : ''
        }${shouldGroupTarget ? '(' : ''}${childTarget[type]}${
          shouldGroupTarget ? ')' : ''
        }${additionalCoding}${shouldShowCountCoding ? ')' : ''}${
          index + 1 < numberOfChildrenTargets ? ` ${childTarget.operator}` : ''
        }`;
      })
      .join(' ');
  }

  public groupTargets(
    targets: VisualCodingTarget[],
    operator: Operator,
    forceGrouping = false
  ): VisualCodingTarget[] {
    const hasMultipleSelectedTargets = targets.length > 1;
    if (hasMultipleSelectedTargets || forceGrouping) {
      return [
        this.createTarget({
          targets: this.formatTargets(targets, operator),
        }),
      ];
    } else {
      return targets;
    }
  }

  public separateTargets(targets: VisualCodingTarget[]): VisualCodingTarget[] {
    return targets.map((target: VisualCodingTarget) => {
      // should create a new group for target with count coding on the second level
      const isTargetAlreadyGrouped =
        target.targets.length > 0 && !target.targets[0].countCoding;
      if (isTargetAlreadyGrouped) {
        return target;
      }
      const ownTitle =
        target.activeTitleMode === DisplayType.ownTitle
          ? target.ownTitle
          : null;
      const groupTarget = this.createTarget({
        targets: [target],
      });
      if (ownTitle) {
        groupTarget.activeTitleMode = DisplayType.ownTitle;
      }
      return groupTarget;
    });
  }

  public getTargetTitle(target: VisualCodingTarget): string {
    return target.activeTitleMode
      ? target[target.activeTitleMode]
      : target.title;
  }

  private findRecursiveTargets(
    statements: Statement[],
    parentTitle: string
  ): VisualCodingTarget[] {
    const targets: VisualCodingTarget[] = [];
    const currentTitlePrefix = parentTitle
      ? `${parentTitle} ${levelSeparator} `
      : '';

    statements.forEach((node: Statement) => {
      const title = `${currentTitlePrefix}${node.description}`;
      if (node.children.length > 0) {
        targets.push(...this.findRecursiveTargets(node.children, title));
      } else {
        const target = this.convertStatementToTarget(title, node);
        if (target) {
          targets.push(target);
        }
      }
    });

    return targets;
  }

  private convertStatementToTarget(
    parentTitle: string,
    node: Statement
  ): VisualCodingTarget | null {
    return node.customData
      ? this.convertCustomStatementToTarget(node)
      : !node.isCustomCoding || node.coding
      ? this.createTarget({
          title: this.formatLongTitle(parentTitle),
          shortTitle: node.description,
          ownTitle: this.formatLongTitle(parentTitle),
          coding: node.coding,
        })
      : null;
  }

  private convertCustomStatementToTarget(
    node: Statement
  ): VisualCodingTarget | null {
    if (!node.customData) {
      return null;
    }
    let target = JSON.parse(node.customData.jsonTarget);
    const isNodeRenamed = target.title !== node.description;
    if (isNodeRenamed) {
      target.activeTitleMode = DisplayType.ownTitle;
      target.ownTitle = node.description;
    }
    // this could also ungroup target with count coding
    const shouldUngroupTarget = target.targets.length < 2;
    if (shouldUngroupTarget) {
      target.targets[0].activeTitleMode = target.activeTitleMode;
      target.targets[0].ownTitle = target.ownTitle;
      target = target.targets[0];
    }
    return target;
  }

  private formatLongTitle(longTitle: string): string {
    const titles = longTitle.split(levelSeparator);
    const hasParentTitle = titles.length > 1;

    return (
      titles[titles.length - 1].trim() +
      (hasParentTitle
        ? ` ${titleSeparator} ${titles[titles.length - 2].trim()}`
        : '')
    );
  }

  private formatTargets(
    targets: VisualCodingTarget[],
    operator: Operator = Operator.or
  ): VisualCodingTarget[] {
    return targets.map((target: VisualCodingTarget) => ({
      ...target,
      operator: operator,
    }));
  }
}
