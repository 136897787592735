import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Schedule } from 'src/app/classes/schedule';
import { Target } from 'src/app/classes/target';
import {
  NodeMenuClickEvent,
  TreeTableColumn,
  TreeTableMenuItem,
  TreeTableNode,
} from '../tree-table/tree-table.models';
import { frequencyDistributionColumns } from 'src/app/models/planning-freq-dist-columns.model';
import {
  MEDIATYPE_ALL,
  MediatypeView,
} from 'src/app/steps/planning-step/planning-step.component';
import { TreeTableComponent } from '../tree-table/tree-table.component';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ScheduleTotalTag } from 'src/app/classes/schedule-total';
import { FrequencyDistributionGroupingDialogModel } from '../dialogs/frequency-distribution-grouping-dialog/frequency-distribution-grouping-dialog.component';
import { PlanningService } from 'src/app/services/planning.service';
import { buildFreqRows } from 'src/app/utils/exportUtils';
interface FrequencyRow {
  label: string;
  data: number[];
}

@Component({
  selector: 'compare-freq-distribution',
  templateUrl: './compare-freq-distribution.component.html',
  styleUrls: ['./compare-freq-distribution.component.scss'],
})
export class CompareFreqDistributionComponent implements OnInit, OnChanges {
  @Input() currentTarget: Target;
  @Input() currentSchedule: Schedule;
  @Input() unitsText: string;

  _processing: boolean;
  @Input() set processing(value: boolean) {
    this._processing = value;
  }

  @ViewChild('freqDistTable') freqDistTable: TreeTableComponent;

  get processing(): boolean {
    return this._processing;
  }

  tableColumns: TreeTableColumn[] = [];
  mediaTypes: MediatypeView[] = [];

  tableData: TreeTableNode[] = [];

  inlineMenu: TreeTableMenuItem[] = [
    { label: 'Settings', data: 'settings', matIcon: 'settings' },
  ];

  freqRows: FrequencyRow[] = [];

  maxFreqLevel: number;

  tableColumnsNumber: number = 0;

  get targets(): Target[] {
    return this.mediaplannerService.plan.targets.filter(
      (target) => target.planningTarget
    );
  }

  get freqDistributionSettings() {
    return this.mediaplannerService.plan.freqDistributionSettings;
  }

  get freqLevelTo() {
    return this.freqDistributionSettings.freqLevelTo;
  }
  get isGroupingOn() {
    return this.freqDistributionSettings.isGroupingOn;
  }
  get freqUpTo() {
    return this.freqDistributionSettings.freqUpTo;
  }
  get groupsOf() {
    return this.freqDistributionSettings.groupsOf;
  }

  constructor(
    private mediaplannerService: MediaPlannerService,
    private planningService: PlanningService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.maxFreqLevel = this.freqLevelTo;
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentTarget = changes['currentTarget'];
    if (
      currentTarget &&
      currentTarget.currentValue !== currentTarget.previousValue &&
      !currentTarget.firstChange
    ) {
      this.loadData();
    }

    const currentSchedule = changes['currentSchedule'];
    if (
      currentSchedule &&
      currentSchedule.currentValue !== currentSchedule.previousValue
    ) {
      setTimeout(() => {
        this.processing = true;
        this.planningService
          .evaluate(
            this.mediaplannerService.plan.targets,
            this.currentSchedule,
            this.mediaplannerService.plan.vehicleGroups.groups,
            false,
            true
          )
          .subscribe((success) => {
            if (success) {
              this.processing = false;
              this.loadData();
            }
            this.processing = false;
          });
      });
    }
  }

  private buildMediaTypeList(): MediatypeView[] {
    const medTypes: MediatypeView[] = [];

    this.currentTarget
      ? this.currentTarget.vehicles.forEach((vehicle) => {
          if (!medTypes.find((med) => med.name === vehicle.mediaType))
            medTypes.push({ name: vehicle.mediaType });
        })
      : medTypes.push({ name: '** No audiences found **' });

    if (medTypes.length > 1) medTypes.unshift({ name: MEDIATYPE_ALL });

    return medTypes;
  }

  private loadData() {
    this.mediaTypes = [];
    this.tableColumns = frequencyDistributionColumns;
    this.freqRows = [];
    this.tableData = [];

    this.mediaTypes = this.buildMediaTypeList();
    this.freqRows.push(
      ...buildFreqRows(
        this.freqLevelTo,
        this.isGroupingOn,
        this.freqUpTo,
        this.groupsOf
      )
    );
    this.generateFreqData();
    this.tableColumnsNumber = this.tableColumns.length + 1;

    //refresh tree table
    this.freqDistTable
      ? this.freqDistTable.refresh.emit({ newData: this.tableData })
      : null;
  }

  private generateFreqData() {
    if (!this.currentTarget || !this.currentSchedule) return;

    const target: Target = this.currentTarget;
    const schedule: Schedule = this.currentSchedule;

    const totalChildren = [];
    this.freqRows.forEach((row) => {
      totalChildren.push({
        name: row.label,
        freqArr: row.data,
        id: totalChildren.length,
        rowCss: 'table-freq-value-row',
      });
    });

    //get totals data for each children
    totalChildren.forEach((child) => {
      const valueData = this.mediaplannerService.plan.getFreqDistData(
        'total',
        ScheduleTotalTag.total,
        target,
        schedule,
        child.freqArr,
        this.freqLevelTo
      );

      child.data = valueData;
    });

    const childrenArray = totalChildren.filter(
      (child) => child.data.reachedAtLeast000 >= 20
    );
    while (this.freqRows.length > childrenArray.length) {
      this.freqRows.pop();
    }

    this.tableData.push({
      name: 'All Media',
      id: this.tableData.length,
      rowCss: 'table-total-row',
      css: 'table-total-row',
      expanded: true,
      children: [...childrenArray],
      skipSort: true,
    });

    // create objects for each media type
    this.mediaTypes.forEach((mediaType) => {
      if (mediaType.name === MEDIATYPE_ALL) return;

      const nodeChildren = [];
      this.freqRows.forEach((row) => {
        nodeChildren.push({
          name: row.label,
          freqArr: row.data,
          id: nodeChildren.length,
          rowCss: 'table-freq-value-row',
        });
      });

      nodeChildren.forEach((child) => {
        const valueData = this.mediaplannerService.plan.getFreqDistData(
          mediaType.name,
          [ScheduleTotalTag.mediatype, ScheduleTotalTag.multiSurvey],
          target,
          schedule,
          child.freqArr,
          this.freqLevelTo
        );

        child.data = valueData;
      });

      const mediaTypeNode = {
        name: mediaType.name,
        id: this.tableData.length,
        rowCss: 'table-title-row',
        expanded: false,
        children: [...nodeChildren],
      };

      this.tableData.push(mediaTypeNode);
    });
  }

  onTreeHeaderMenuClick(item: NodeMenuClickEvent) {
    if (item.item.data === 'settings') {
      //TO DO: trigger dialog box
      this.dialogService
        .openFrequncyDistributionGroupingDialog({
          freqLevelTo: this.freqLevelTo,
          isGroupingOn: this.isGroupingOn,
          ...(this.freqUpTo ? { freqUpTo: this.freqUpTo } : undefined),
          ...(this.groupsOf ? { groupsOf: this.groupsOf } : undefined),
        })
        .afterClosed()
        .subscribe((updatedData: FrequencyDistributionGroupingDialogModel) => {
          if (updatedData !== null) {
            this.freqDistributionSettings.freqLevelTo = updatedData.freqLevelTo;
            this.freqDistributionSettings.isGroupingOn =
              updatedData.isGroupingOn;
            this.freqDistributionSettings.freqUpTo = updatedData.freqUpTo;
            this.freqDistributionSettings.groupsOf = updatedData.groupsOf;

            if (this.freqLevelTo > this.maxFreqLevel) {
              this.processing = true;
              this.planningService
                .evaluate(
                  this.targets,
                  this.currentSchedule,
                  this.mediaplannerService.plan.vehicleGroups.groups,
                  false,
                  true
                )
                .subscribe((success) => {
                  if (success) {
                    this.processing = false;
                    this.loadData();
                  }
                  this.processing = false;
                });
              this.maxFreqLevel = this.freqLevelTo;
            } else {
              this.loadData();
            }
          }
        });
    }
  }
}
