import { TreeTableColumn } from '../components/tree-table/tree-table.models';

export const Column_TotalPct: TreeTableColumn = {
  header: 'Total %',
  columnDef: 'totalPct',
  columnType: 'number',
  decimals: 2,
  editable: true,
  cell: (row) => row.data?.totalPct,
};
export const Column_TotalMinPct: TreeTableColumn = {
  header: 'Total Min %',
  columnDef: 'totalMinPct',
  columnType: 'number',
  decimals: 2,
  editable: true,
  cell: (row) => row.data?.totalMinPct,
};
export const Column_TotalMaxPct: TreeTableColumn = {
  header: 'Total Max %',
  columnDef: 'totalMaxPct',
  columnType: 'number',
  decimals: 2,
  editable: true,
  cell: (row) => row.data?.totalMaxPct,
};
export const Column_Actual: TreeTableColumn = {
  header: 'Actual',
  columnDef: 'actual',
  columnType: 'number',
  decimals: 2,
  editable: true,
  cell: (row) => row.data?.actual,
};

export const optimeseColumns = [
  Column_TotalPct,
  Column_TotalMinPct,
  Column_TotalMaxPct,
  Column_Actual,
];
