import { Injectable } from '@angular/core';
import {
  TupPptxBuilder,
  TupPptxExporterService,
} from '@telmar-global/tup-document-exporter';
import { GoogleDriveFileInfo } from '@telmar-global/tup-google-api';
import { DialogService } from './dialog.service';
import { RequestLoadingService } from './request-loading.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class PptxService {
  constructor(
    private exporterService: TupPptxExporterService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private requestLoadingService: RequestLoadingService
  ) {}

  public async saveAs(
    builder: TupPptxBuilder,
    filename?: string
  ): Promise<void> {
    await this.exporterService.saveAs(
      this.exporterService.build(builder),
      filename
    );
  }

  public async exportToSlides(
    builder: TupPptxBuilder,
    filename?: string
  ): Promise<void> {
    this.requestLoadingService.setLoading({
      target: 'global',
      isLoading: true,
    });
    this.exporterService
      .exportToSlides(this.exporterService.build(builder), filename)
      .then((fileInfo: GoogleDriveFileInfo) => {
        this.dialogService.exportedGoogleFile(fileInfo.webViewLink);
      })
      .catch((error) => {
        console.error(error);
        this.alertExportError(error);
      })
      .finally(() => {
        this.requestLoadingService.setLoading({
          target: 'global',
          isLoading: false,
        });
      });
  }
  private alertExportError(error: string): void {
    this.snackbarService.showErrorSnackBar(error);
  }
}
