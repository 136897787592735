import {
  DocumentSurvey,
  DocumentTarget,
  DocumentTargetResults,
  EMPTY_SURVEY,
} from '../models/document.model';
import { TargetDaypart, TargetVehicle } from './vehicle';
import uniqid from 'uniqid';
import { cloneDeep } from 'lodash';

export enum TargetStatus {
  active = 'active',
  disabled = 'disabled',
  updated = 'updated',
  noChange = 'noChange',
}

export class Target {
  id: string;
  status: TargetStatus;
  documentTarget: DocumentTarget; // actual coding and survey associated
  vehicles: TargetVehicle[] = []; // selected vehicle list for the given target (audience, resps etc unique)

  get coding(): string {
    return this.documentTarget?.coding;
  }
  get title(): string {
    return this.documentTarget?.title;
  }
  get population(): number {
    return this.documentTarget?.population;
  }
  get sample(): number {
    return this.documentTarget?.sample;
  }
  get survey(): DocumentSurvey {
    return this.documentTarget?.survey || EMPTY_SURVEY;
  }

  get planningTarget(): boolean {
    return this.documentTarget.planningTarget;
  }
  set planningTarget(value: boolean) {
    this.documentTarget.planningTarget = value;
  }
  get addressableTarget(): boolean {
    return this.documentTarget.addressableTarget;
  }
  set addressableTarget(value: boolean) {
    this.documentTarget.addressableTarget = value;
  }

  constructor() {
    this.id = uniqid();
    this.status = TargetStatus.active;
  }

  clear() {
    if (this.documentTarget) {
      this.documentTarget.coding = '#1';
      this.documentTarget.population = -1;
      this.documentTarget.sample = -1;
    }
    this.vehicles = [];
  }

  // will return a vehicle when you can be sure it's not a daypart
  vehicle(id: string | number): TargetVehicle {
    return this.vehicles.find((veh) => veh.id === (id as string));
  }

  // will return a vehicle using its mnemonic reference when you can be sure it's not a daypart
  vehicleByMnemonic(mnemonic: string): TargetVehicle {
    return this.vehicles.find((veh) => veh.mnemonic === mnemonic);
  }

  // will either return the vehicle or find the daypart within the vehicle list
  findVehicle(id: string | number): TargetVehicle | TargetDaypart {
    let returnVehicle: TargetVehicle | TargetDaypart = this.vehicle(id);

    if (!returnVehicle) {
      for (let veh of this.vehicles) {
        returnVehicle = veh.dayparts
          ? veh.dayparts.find((daypart) => daypart.id === id)
          : null;
        if (returnVehicle) break;
      }
    }
    return returnVehicle;
  }

  // called from loadDocument to correct any missing mnemonics coming from a document
  initialiseVehicles() {
    this.vehicles.forEach((vehicle) => {
      vehicle.mnemonic = vehicle.mnemonic || vehicle.id;
      vehicle.dayparts
        ? vehicle.dayparts.forEach((dp) => (dp.mnemonic = dp.mnemonic || dp.id))
        : null;
    });
  }

  addVehicles(vehicles: TargetVehicle[], includeResults: boolean): boolean {
    let added = false;
    vehicles.forEach((veh) => {
      if (!this.vehicles.find((vehicle) => vehicle.id === veh.id)) {
        this.vehicles.push(cloneDeep(veh));
        if (!includeResults && !veh.isMultiSurvey) {
          const v = this.vehicles[this.vehicles.length - 1];
          v.audience = 0;
          v.grossAudience = 0;
          v.potentialReach = 0;
          v.resps = 0;
        }
        added = true;
      }
    });
    return added;
  }

  removeVehicle(vehicleId: string): boolean {
    this.vehicles = this.vehicles.filter((vehicle) => vehicle.id !== vehicleId);
    return true;
  }

  addAddressable(
    vehicle: TargetVehicle,
    target: Target,
    isDirectMail: boolean = false
  ): boolean {
    // null the object if passing an empty target
    vehicle.addressableConfig = target ? vehicle.addressableConfig || {} : null;

    if (vehicle.addressableConfig) {
      vehicle.addressableConfig.targetId = target.id;
      vehicle.addressableConfig.population = target.population;
      vehicle.addressableConfig.isDirectMail = isDirectMail;
    }

    return !!vehicle.addressableConfig;
  }

  renameVehicle(vehicle: TargetVehicle, newTitle: string) {
    if (!vehicle.originalTitle) vehicle.originalTitle = vehicle.title;
    vehicle.title = newTitle ? newTitle : vehicle.originalTitle;
  }

  getMediaTypes(): string {
    const meds: string[] = [];
    this.vehicles.filter((veh) => {
      if (meds.indexOf(veh.mediaType) == -1) meds.push(veh.mediaType);
    });

    meds.length == 0 ? meds.push('[none]') : {};
    return meds.join(',');
  }

  asDocument(): DocumentTargetResults {
    return {
      id: this.id,
      status: this.status,
      documentTarget: this.documentTarget,
      vehicles: this.vehicles,
    };
  }
}
