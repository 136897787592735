<ng-container [ngSwitch]="columnState">
  <ng-container *ngSwitchCase="'collapsed'">
    <ng-container *ngTemplateOutlet="collapsedTemplate"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'expanded'">
    <ng-container *ngTemplateOutlet="expandedTemplate"></ng-container>
  </ng-container>
</ng-container>

<!-- colappsed template shows only a button for expanding -->
<ng-template #collapsedTemplate>
  <div class="collapsed {{ column.group.headerCss }}">
    <button
      mat-icon-button
      TupMatButton
      appearance="small"
      class="tree-table-inline-button"
      matTooltip="Expand {{ column.group.name }}"
      (click)="toggle()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</ng-template>

<!-- expanded template shows a button for collapsing and the group name -->
<ng-template #expandedTemplate>
  <div class="expanded {{ column.group.headerCss }}">
    <button
      mat-icon-button
      TupMatButton
      appearance="small"
      class="tree-table-inline-button"
      matTooltip="Collapse {{ column.group.name }}"
      (click)="toggle()"
    >
      <mat-icon>remove</mat-icon>
    </button>
    <span class="group-label"> {{ column.group.name }}</span>
  </div>
</ng-template>
