import {
  AfterViewChecked,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DocumentSurvey, DocumentTarget } from 'src/app/models/document.model';
import { cloneDeep } from 'lodash';
import { TreeTableMenuItem } from '../tree-table/tree-table.models';
import { DndDropEvent } from 'ngx-drag-drop';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  DropDataContext,
  Operator,
  VisualCodingTarget,
} from '@telmar-global/tup-audience-groups';
import { VisualCodeBuilderComponent } from '../visual-code-builder/visual-code-builder.component';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { GAEvents } from '../../models/analytics.model';
import { DialogService } from 'src/app/services/dialog.service';
import { QuestionDialogModelOptions } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { StatusSnackbarIcon } from '../dialogs/snackbar-generic/snackbar-generic.component';
import { CtrlShiftKeyStates } from 'src/app/directives/ctrl-shift.directive';
import { AddAddressableDialogModel } from 'src/app/add-addressable-dialog/add-addressable-dialog.component';
import { TargetEditorDialogModel } from '../dialogs/target-editor-dialog/target-editor-dialog.component';
import { isNotNullOrUndefined } from 'src/app/pipes/pipeable-operators';

export interface CodebookStatement {
  target: DocumentTarget;
  id: number;
  addressableTarget: boolean;
  planningTarget: boolean;
  selected?: boolean;
  editing?: boolean;
  rename?: boolean;
  error?: boolean;
  VCBTitleEditor?: boolean;
  currentTitle?: string;
}

export interface CombineRowsOptions {
  selectedRows: CodebookStatement[];
  booleanOperator: string;
}

export interface PopulationMenuClickEvent {
  targetIndex: number;
  menuItem: TreeTableMenuItem;
}

export interface PopulationEditEvent {
  targetIndex: number;
  row: CodebookStatement;
  customPopulation: number;
}

const SAVE_AS_CUSTOM_AUDIENCE_MULTISURVEY_DISABLED_MESSAGE =
  'You cannot save the selected audiences as Custom Audience as they are coming from multiple surveys';
const SAVE_AS_CUSTOM_AUDIENCE_DONOR_SURVEY_DISABLED_MESSAGE =
  'You cannot save the selected audience(s) as Custom Audience as they are not coming from the primary surveys';

@Component({
  selector: 'codebook-table',
  templateUrl: './codebook-table.component.html',
  styleUrls: ['./codebook-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CodebookTableComponent
  implements OnInit, DoCheck, OnChanges, AfterViewChecked
{
  @Input() unitsText: string = '';
  @Input() dataSource: CodebookStatement[];
  @Input() visualEditingTarget: VisualCodingTarget; // target object for the visual editor component
  @Input() surveyHasAddressable: boolean = true;
  @Input() showConfirmButtons: boolean = true;
  @Input() showHeaderMenu: boolean = true;
  @Input() showActions: boolean = true;
  @Input() visualEditorLayout: 'compact' | 'full' = 'compact';
  @Input() allowNoDataPanel: boolean = true;
  @Input() loadingTreeTable: boolean = false;
  @Input() hasMultiSurveyAudience: boolean = false;
  @Input() primarySurvey: DocumentSurvey;
  @Input() dropChipWidth: string = '800px';

  @Output() manualCoding: EventEmitter<null> = new EventEmitter<null>();
  @Output() editTarget: EventEmitter<CodebookStatement> =
    new EventEmitter<CodebookStatement>();
  @Output() deleteTarget: EventEmitter<CodebookStatement> =
    new EventEmitter<CodebookStatement>();
  @Output() dataSourceChange: EventEmitter<CodebookStatement[]> =
    new EventEmitter<CodebookStatement[]>();
  @Output() targetsChanged: EventEmitter<CodebookStatement[]> =
    new EventEmitter<CodebookStatement[]>();
  @Output() headerMenuItemClick: EventEmitter<TreeTableMenuItem> =
    new EventEmitter<TreeTableMenuItem>();
  @Output() manualCodingClick = new EventEmitter<VisualCodingTarget>();
  @Output() newManualCodingClick = new EventEmitter<VisualCodingTarget>();

  @Output() rowsCombined: EventEmitter<{
    selectedRows: CodebookStatement[];
    booleanOperator: string;
  }> = new EventEmitter<{
    selectedRows: CodebookStatement[];
    booleanOperator: string;
  }>();

  @Output() tableItemsOrderChanged: EventEmitter<CdkDragDrop<string[]>> =
    new EventEmitter<CdkDragDrop<string[]>>();

  @Output() drop: EventEmitter<any> = new EventEmitter<any>();
  @Output() targetChange: EventEmitter<VisualCodingTarget> =
    new EventEmitter<VisualCodingTarget>();
  @Output() targetHasChanged: EventEmitter<VisualCodingTarget> =
    new EventEmitter<VisualCodingTarget>();
  @Output() dropNode: EventEmitter<DropDataContext<Operator>> =
    new EventEmitter<DropDataContext<Operator>>();
  @Output() closeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() editDialogSaveClick: EventEmitter<CodebookStatement> =
    new EventEmitter<CodebookStatement>();
  @Output() populationMenuClick: EventEmitter<PopulationMenuClickEvent> =
    new EventEmitter<PopulationMenuClickEvent>();
  @Output() populationEdited: EventEmitter<PopulationEditEvent> =
    new EventEmitter<PopulationEditEvent>();

  @ViewChild('visualCodeBuilder') visualCodeBuilder: VisualCodeBuilderComponent;

  columns = [
    {
      columnDef: 'title',
      header0: 'Audience',
      header1: '',
      type: 'action',
      cell: (row: CodebookStatement) => `${row.target?.title}`,
      hidden: false,
    },
    {
      columnDef: 'surveys',
      header0: 'Survey',
      header1: '',
      type: 'string',
      tooltip: (row: CodebookStatement) => `${row.target?.survey?.title}`,
      cell: (row: CodebookStatement) => `${row.target?.survey?.code}`,
      hidden: true,
    },
    {
      columnDef: 'audienceCode',
      header0: 'Code',
      header1: '',
      type: 'string',
      cell: (row: CodebookStatement) => `${row.target?.coding}`,
      hidden: true,
    },
    {
      columnDef: 'popn',
      header0: 'Population',
      header1: '',
      type: 'value',
      cell: (row: CodebookStatement) => this.getTargetPopulation(row),
      hidden: false,
      menu: [
        {
          label: 'Reset to default',
          data: 'reset_popn',
          matIcon: 'undo',
        },
      ],
    },
    {
      columnDef: 'sample',
      header0: 'Sample',
      header1: 'size',
      type: 'value',
      cell: (row: CodebookStatement) =>
        `${row.target.sample === -1 ? '' : row.target.sample.toFixed(0)}`,
      hidden: false,
    },
    {
      columnDef: 'planningTarget',
      header0: 'Target',
      header1: 'audience',
      type: 'boolean',
      cell: (row: CodebookStatement) => row.target.planningTarget,
      hidden: true,
    },
    {
      columnDef: 'addressableTarget',
      header0: 'Addressable',
      header1: 'audience',
      type: 'boolean',
      cell: (row: CodebookStatement) => row.target.addressableTarget,
      hidden: true,
    },
  ];

  headerMenu: TreeTableMenuItem[] = [
    {
      label: 'Save as custom audience',
      data: 'owncodes',
      matIcon: 'star',
      disabled: false,
      tooltip: '',
    },
    {
      label: 'Show surveys',
      data: 'surveys',
      matIcon: 'database',
    },
    {
      label: 'Show audience codes',
      data: 'audienceCode',
      matIcon: 'visibility',
    },
    {
      label: 'Remove selected',
      data: 'sel',
      matIcon: 'delete',
      disabled: false,
    },
  ];

  combineOptions: TreeTableMenuItem[] = [
    { label: 'AND', data: 'and' },
    { label: 'OR', data: 'or' },
    { label: 'AND NOT', data: 'andNot' },
    { label: 'OR NOT', data: 'orNot' },
  ];

  combineSelectedDisabled: boolean = true;

  audienceMenu: TreeTableMenuItem[] = [
    {
      label: 'Edit audience',
      data: 'edit',
      matIcon: 'edit',
    },
    { label: 'Rename audience', data: 'rename', matIcon: 'text_format' },
    // { label: 'Add to custom audience', data: 'custom', disabled: true },
    {
      label: 'Duplicate audience',
      data: 'duplicate',
      matIcon: 'content_copy',
      disabledForMultiSurvey: true,
    },
    { label: 'Remove audience', data: 'remove', matIcon: 'delete' },
  ];

  readonly NOT_PRIMARY_SURVEY =
    'This audience can only be used as an addressable target';
  tableDataSource: MatTableDataSource<CodebookStatement>;

  displayedColumns = this.columns.map((c) => c.columnDef);
  expandedElement: CodebookStatement | null;
  visualTarget: VisualCodingTarget;
  dragDisabled = false;
  vcbForMultiSurveySaved: boolean = false;
  vcbUpdateBtnDisabled: boolean = true;

  initialTitle: string = ''; //the initial title before editing
  currentTitle: string = ''; //the new title that is not yet saved

  keyStates: CtrlShiftKeyStates = {
    ctrlPressed: false,
    shiftPressed: false,
  };

  lastRowChecked: CodebookStatement;

  getTargetPopulation(row: CodebookStatement) {
    return `${
      row.target.customPopulation
        ? row.target.customPopulation
        : row.target.population === -1
        ? ''
        : row.target.population.toFixed(0)
    }`;
  }

  constructor(
    private analyticsService: TupAnalyticsService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource(this.dataSource);
    this.expandedElement = null;
  }

  ngDoCheck(): void {
    this.tableDataSource.data = this.dataSource;
  }

  ngAfterViewChecked() {
    if (
      this.tableDataSource.data.length &&
      this.tableDataSource.data.find((data) => data.target.isMultiSurvey) &&
      !this.expandedElement
    ) {
      if (!this.vcbForMultiSurveySaved) {
        this.onActionItem(0, this.tableDataSource.data[0], 'edit');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const unitsText = changes['unitsText'];
    const surveyHasAddressable = changes['surveyHasAddressable'];
    const loadingTreeTable = changes['loadingTreeTable'];
    let column;

    if (
      surveyHasAddressable &&
      surveyHasAddressable.currentValue !== surveyHasAddressable.previousValue
    ) {
      column = this.columns.find(
        (column) => column.columnDef === 'addressableTarget'
      );
      column ? (column.hidden = !surveyHasAddressable.currentValue) : null;

      column = this.columns.find(
        (column) => column.columnDef === 'planningTarget'
      );
      column ? (column.hidden = !surveyHasAddressable.currentValue) : null;
    }

    if (unitsText && unitsText.previousValue !== unitsText.currentValue) {
      column = this.columns.find((column) => column.columnDef === 'popn');
      column ? (column.header1 = this.unitsText) : null;
    }

    if (
      loadingTreeTable &&
      loadingTreeTable.previousValue !== loadingTreeTable.currentValue
    ) {
      const menuToUpdate = this.headerMenu.find(
        (menu) => menu.label === 'Save as custom audience'
      );

      if (menuToUpdate) {
        menuToUpdate.disabled = loadingTreeTable.currentValue;
      }
    }
  }

  // target title as been edited
  onSave(column: any, row: CodebookStatement, index: number, value: string) {
    if (row.VCBTitleEditor && column.columnDef === 'title' && row.target) {
      //on title edit submit, store the value in currentTitle
      this.currentTitle = value;
    }

    if (!row.VCBTitleEditor && column.columnDef === 'title' && row.target) {
      row[column.columnDef] = value;
      row.target.title = value;
      this.dataSource[index] = row;
      this.emitChange();
    }
  }

  onEditTitle(
    column: any,
    row: CodebookStatement,
    index: number,
    value: string
  ) {
    if (row.VCBTitleEditor && column.columnDef === 'title' && row.target) {
      row[column.columnDef] = value;
      row.target.title = value;
      this.dataSource[index] = row;
      this.emitChange();

      // if VCB is not open, enable drag after title edit is finalised
      if (!this.expandedElement) {
        this.dragDisabled = false;
      }
    }
  }

  onManualCoding() {
    this.manualCoding.emit();
  }

  // send updates to the parent
  emitChange() {
    this.dataSourceChange.emit(this.dataSource);
    this.targetsChanged.emit(this.dataSource);
  }

  onVisualEditorTargetChanged(event: any) {
    this.vcbUpdateBtnDisabled = false;
    this.targetHasChanged.emit(event);
  }

  onVisualEditingDropNode(event: any) {
    this.dropNode.emit(event);
  }

  // user might move to a different tab or remove the expanded audience. Make sure VCB data is cleaned up
  onVCBDestroy() {
    if (this.expandedElement) {
      this.closeClick.emit();
      this.expandedElement = null;
    }
  }

  onManualCodingClick(target: VisualCodingTarget) {
    this.manualCodingClick.emit(target);
  }

  onNewManualCodingClick(target: VisualCodingTarget) {
    this.newManualCodingClick.emit(target);
  }

  private handleDialogConfirmation(currentRow: CodebookStatement) {
    const options: QuestionDialogModelOptions = {
      buttons: [
        { caption: 'Discard', data: 'cancel' },
        { caption: 'Save', data: 'save', flat: true },
      ],
      closeButton: { caption: 'Discard', data: 'cancel' },
      snackbar: {
        type: 'warning',
        message: `You have not saved your new title '${this.currentTitle}'.<br>Do you wish to save this as your audience title?`,
        align: 'center',
        icon: StatusSnackbarIcon.Warning,
      },
    };

    this.dialogService
      .confirmation('', 'Save new title?', options)
      .afterClosed()
      .subscribe((button) => {
        if (button.data === 'save') {
          currentRow.target.title = this.currentTitle;
        }

        if (button.data === 'cancel') {
          currentRow['title'] = this.initialTitle;
          currentRow.target.title = this.initialTitle;
        }

        currentRow.rename = false;
        this.emitChange();

        this.expandedElement = null;
      });
  }

  onVisualEditorSaveClick(row: CodebookStatement, index: number) {
    if (row.rename && row.target.title !== this.currentTitle) {
      this.handleDialogConfirmation(this.dataSource[index]);
      this.saveClick.emit();
    } else {
      this.dataSource[index].rename = false;
      this.saveClick.emit();
      this.expandedElement = null;
      this.vcbForMultiSurveySaved = true;
    }
    this.dragDisabled = false;
  }

  //on cancel set the title and target title to initial value
  onVisualEditorCloseClick(index: number) {
    if (
      this.dataSource[index].rename &&
      this.dataSource[index].target.title !== this.currentTitle
    ) {
      this.handleDialogConfirmation(this.dataSource[index]);
      this.closeClick.emit();
    } else {
      this.vcbUpdateBtnDisabled = true;
      this.dataSource[index]['title'] = this.initialTitle;
      this.dataSource[index].target.title = this.initialTitle;
      this.dataSource[index].rename = false;
      this.closeClick.emit();
      this.expandedElement = null;
    }
    this.dragDisabled = false;
  }

  onEditorDialogCloseClick(
    row: CodebookStatement,
    editResult: TargetEditorDialogModel
  ) {
    row.target = cloneDeep(editResult.documentTarget);
    row.target.population = -1;
    row.target.sample = -1;
    this.editDialogSaveClick.emit(row);
  }

  emitTargetChange() {
    this.targetChange.emit(this.visualTarget);
  }

  toggleAllRows() {
    const nonMultiSurveyData = this.dataSource.filter(
      (statement) => !statement.target.isMultiSurvey
    );
    const sel = nonMultiSurveyData.length
      ? !nonMultiSurveyData[0].selected
      : false;
    this.dataSource.forEach((d) => (d.selected = sel));

    //sel ? this.selectContainer.selectAll(): this.selectContainer.deselectItems( row=> (row as CodebookStatement).selected === sel  );
  }

  isAllSelected(indeterminate: boolean = false): boolean {
    const count = this.dataSource.filter((d) => d.selected).length;
    if (!this.dataSource.length || !count) return false;

    const val = count === this.dataSource.length;
    return indeterminate ? !val : val;
  }

  onHeaderMenuClick(menu: TreeTableMenuItem) {
    let analyticsAction = menu.label;
    if (['all', 'sel'].includes(menu.data)) {
      if (menu.data === 'all') {
        this.dataSource.forEach((row) => (row.selected = true));
      }
      this.dataSource.forEach((row) =>
        row.selected ? this.deleteTarget.emit(row) : null
      );
    }

    if (['and', 'or', 'andNot', 'orNot'].includes(menu.data)) {
      analyticsAction = `combine ${menu.label}`;
      const selectedRows = this.dataSource.filter(
        (row) => row.selected === true
      );
      const selectedRowsOptions: CombineRowsOptions = {
        selectedRows: selectedRows,
        booleanOperator: menu.data,
      };
      this.rowsCombined.emit(selectedRowsOptions);
    }

    if (menu.data === 'audienceCode') {
      const col = this.columns.find((c) => c.columnDef === menu.data);
      if (col) {
        col.hidden = !col.hidden;

        this.headerMenu.find((m) => m.data === menu.data).label = `${
          col.hidden ? 'Show' : 'Hide'
        } audience codes`;
      }
    }

    if (menu.data === 'surveys') {
      const col = this.columns.find((c) => c.columnDef === menu.data);
      if (col) {
        col.hidden = !col.hidden;

        this.headerMenu.find((m) => m.data === menu.data).label = `${
          col.hidden ? 'Show' : 'Hide'
        } surveys`;
      }
    }

    this.headerMenuItemClick.emit(menu);
    this.analyticsService.e(GAEvents.audience_inline_menu, {
      action: `header|${analyticsAction.toLowerCase()}`,
    });
  }

  // burger menu action items
  onActionItem(index: number, row: CodebookStatement, action: string) {
    switch (action) {
      // edit target
      case 'edit':
        if (
          row.target?.survey?.code &&
          this.primarySurvey?.code &&
          row.target.survey.code !== this.primarySurvey.code
        ) {
          this.openTargetEditorDialog(row);
        } else {
          this.editTarget.emit(this.dataSource[index]);
          this.expandedElement = row;
          row.rename = true;
          row.VCBTitleEditor = true;
          this.dragDisabled = true;
          this.currentTitle = row.target.title;
          this.initialTitle = row.target.title;
          this.dataSource.forEach((dataRow, idx) => {
            if (!dataRow.target.ownTitle) {
              dataRow.target.ownTitle = dataRow.target.title;
            }
            if (dataRow.rename && idx !== index) {
              dataRow.rename = false;
            }
          });
        }
        break;

      // inline rename target title
      case 'rename':
        row.rename = true;
        row.VCBTitleEditor = true;
        this.dragDisabled = true;
        this.dataSource.forEach((dataRow, idx) => {
          if (!dataRow.target.ownTitle) {
            dataRow.target.ownTitle = dataRow.target.title;
          }
          if (dataRow.rename && idx !== index) {
            dataRow.rename = false;
          }
        });
        break;

      case 'duplicate':
        this.dataSource.push(cloneDeep(this.dataSource[index]));
        this.dataSource[this.dataSource.length - 1].id =
          this.dataSource.length - 1;
        break;

      // remove
      case 'remove':
        this.deleteTarget.emit(cloneDeep(this.dataSource[index]));
        this.dataSource.splice(index, 1);
        break;
    }

    this.analyticsService.e(GAEvents.audience_inline_menu, {
      action: `item|${action.toLowerCase()}`,
    });
    this.emitChange();
  }

  openTargetEditorDialog(row: CodebookStatement) {
    const editOptions = new TargetEditorDialogModel();

    editOptions.dialogTitle = 'Update audience';
    editOptions.confirmButtonText = 'Update';
    editOptions.surveyHasAddressable = true;
    editOptions.unitsText = row.target.survey.unitsText;
    editOptions.documentTarget = row.target;
    editOptions.survey = row.target.survey;
    editOptions.primarySurvey = this.primarySurvey;
    editOptions.defaultAddressableTarget = true;
    editOptions.defaultPlanningTarget = false;

    this.dialogService
      .openTargetEditorDialog(editOptions)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((editResult: TargetEditorDialogModel) => {
        this.onEditorDialogCloseClick(row, editResult);
      });
  }

  getUpperAndLowerIndexes(
    row1: CodebookStatement,
    row2: CodebookStatement
  ): { lowerIndex: number; upperIndex: number } {
    const index1 = this.dataSource.findIndex((node) => node.id === row1.id);
    const index2 = this.dataSource.findIndex((node) => node.id === row2.id);

    const lowerIndex = Math.min(index1, index2);
    const upperIndex = Math.max(index1, index2);

    return { lowerIndex, upperIndex };
  }

  onCheckboxChange(
    row: CodebookStatement,
    columnDef: string,
    checked: boolean
  ) {
    if (columnDef !== 'title') {
      row.target[columnDef] = checked; // change the doc target itself
      row[columnDef] = checked; // change the object the codebooktable uses
      this.emitChange();
    } else {
      if (this.keyStates.shiftPressed && this.lastRowChecked) {
        const { lowerIndex, upperIndex } = this.getUpperAndLowerIndexes(
          row,
          this.lastRowChecked
        );

        this.dataSource.forEach((dataSourceRow, dataSourceRowIndex) => {
          if (
            dataSourceRowIndex > lowerIndex &&
            dataSourceRowIndex < upperIndex
          ) {
            dataSourceRow.selected = row.selected;
          }
        });
      }

      this.lastRowChecked = row;
    }
  }

  // capture keystates from directive
  onCtrlShift(states: CtrlShiftKeyStates) {
    this.keyStates = states;
  }

  // notify the parent so target objects can be built properly
  onDragDrop(event: DndDropEvent) {
    this.drop.emit(event.event);
  }

  onDragEnter(e) {}
  onDragLeave(e) {}
  onDragOver(e) {}

  onTableItemDragAndDrop(event: CdkDragDrop<string[]>) {
    this.tableItemsOrderChanged.emit(event);
  }

  onHeaderMenuOpened() {
    //if there is at least 1 item selected 'owncodes' and 'sel' will be enabled
    this.headerMenu
      .filter((menu) => menu.data === 'owncodes' || menu.data === 'sel')
      .forEach(
        (optionMenu) =>
          (optionMenu.disabled =
            this.tableDataSource.data.filter((data) => data.selected).length ===
            0)
      );

    const surveysUsed = [];
    const ownCodesMenu = this.headerMenu.find(
      (menu) => menu.data === 'owncodes'
    );

    if (ownCodesMenu) {
      this.tableDataSource.data
        .filter((data) => data.selected)
        .forEach((data) => {
          if (!surveysUsed.includes(data.target.survey.code)) {
            surveysUsed.push(data.target.survey.code);
          }
        });

      ownCodesMenu.disabled =
        surveysUsed.length !== 1 ||
        !surveysUsed.includes(this.primarySurvey.code);

      // Update tooltip only if surveysUsed has 2 or more survey codes
      if (surveysUsed.length >= 2) {
        ownCodesMenu.tooltip =
          SAVE_AS_CUSTOM_AUDIENCE_MULTISURVEY_DISABLED_MESSAGE;
      } else if (!surveysUsed.includes(this.primarySurvey.code)) {
        ownCodesMenu.tooltip =
          SAVE_AS_CUSTOM_AUDIENCE_DONOR_SURVEY_DISABLED_MESSAGE;
      } else {
        ownCodesMenu.tooltip = '';
      }
    }

    //if there are at least 2 items selected Combine Selected option will be enabled
    this.combineSelectedDisabled =
      this.tableDataSource.data.filter((data) => data.selected).length < 2;
  }

  onPopulationMenuClick(targetIndex: number, menuItem: TreeTableMenuItem) {
    this.populationMenuClick.emit({ targetIndex, menuItem });
  }

  onPopulationEdited(
    targetIndex: number,
    row: CodebookStatement,
    customPopulation: number
  ) {
    if (Number(customPopulation)) {
      this.populationEdited.emit({ targetIndex, row, customPopulation });
    } else {
      this.tableDataSource.data = cloneDeep(this.dataSource);
    }
  }
}
