import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

export interface CtrlShiftKeyStates {
  ctrlPressed: boolean;
  shiftPressed: boolean;
}

@Directive({
  selector: '[ctrlShift]',
})
export class CtrlShiftDirective implements OnInit, OnDestroy {
  private ctrlPressed = false;
  private shiftPressed = false;

  @Output('ctrlShift') ctrlShiftEvent = new EventEmitter<CtrlShiftKeyStates>();

  @HostListener('window:keydown', ['$event'])
  keyEventDown(event: KeyboardEvent) {
    this.doEmit(event);
  }

  @HostListener('window:keyup', ['$event'])
  keyEventUp(event: KeyboardEvent) {
    this.doEmit(event);
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly element: ElementRef
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}

  doEmit(event: KeyboardEvent) {
    if (
      this.ctrlPressed !== event.ctrlKey ||
      this.shiftPressed !== event.shiftKey
    ) {
      this.ctrlShiftEvent.emit({
        ctrlPressed: event.ctrlKey,
        shiftPressed: event.shiftKey,
      });
    }
    this.ctrlPressed = event.ctrlKey;
    this.shiftPressed = event.shiftKey;
  }
}
