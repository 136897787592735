<div
  fxFlexLayout="column"
  fxLayoutAlign="space-between center"
  [ngClass]="[data.type]"
>
  <mat-icon
    *ngIf="icon?.icon"
    style="font-size: 3em; height: 36px; width: 36px"
    class="{{ icon?.class || '' }}"
    fxFlex="10"
    >{{ icon?.icon }}</mat-icon
  >
  <div fxFlex>
    <h3
      *ngIf="data.title"
      style="text-align: left"
    >
      {{ data.title }}
    </h3>
    <p style="text-align: left; margin: 0 0 0 15px">{{ data.message }}</p>
  </div>
  <button
    fxFlex="none"
    mat-button
    (click)="snackBarRef.dismiss()"
  >
    {{ data.dismissButton || "OK" }}
  </button>
</div>
