import { Injectable } from '@angular/core';
import {
  ChartColors,
  ChartSettings,
  GLOBAL_SETTINGS_KEY,
  PlanningState,
} from '../models/charts.model';

@Injectable({
  providedIn: 'root',
})
export class ChartSettingsService {
  planningState: PlanningState = {
    currentMediaType: '',
    currentSchedule: -1,
    currentTarget: -1,
    scheduleCountChanged: true,
    targetCountchanged: true,
    vehicleCountChanged: true,
  };

  chartSettings: { [key: string]: ChartSettings } = {};
  chartColors: { [id: string]: Record<string, string> } = {};

  constructor() {}

  clearAll() {
    this.planningState = {
      currentMediaType: '',
      currentSchedule: -1,
      currentTarget: -1,
      scheduleCountChanged: true,
      targetCountchanged: true,
      vehicleCountChanged: true,
    };
  }

  planningStateChanged(newState: PlanningState): boolean {
    return (
      this.planningState.currentMediaType !== newState.currentMediaType ||
      this.planningState.currentSchedule !== newState.currentSchedule ||
      this.planningState.currentTarget !== newState.currentTarget ||
      this.planningState.targetCountchanged ||
      this.planningState.vehicleCountChanged ||
      this.planningState.scheduleCountChanged
    );
  }

  public saveChartSettings(
    settings: ChartSettings,
    id: string,
    planChartSettings?: { [key: string]: ChartSettings }
  ): void {
    if (planChartSettings) {
      planChartSettings[id] = settings;
    }
    this.chartSettings[id] = settings;
  }

  public getGlobalChartSettings(): ChartSettings {
    return this.findChartSettings(GLOBAL_SETTINGS_KEY);
  }

  public saveGlobalChartSettings(settings: ChartSettings): void {
    this.saveChartSettings(settings, GLOBAL_SETTINGS_KEY);
  }

  public findChartSettings(id: string): ChartSettings | undefined {
    return this.chartSettings[id];
  }

  public removeChartSettings(id: string): void {
    this.chartSettings[id] = undefined;
  }

  // loading and saving chart colors using the unique settings id
  public saveColumnColors(seriesColor: Record<string, string>, id: string) {
    this.chartColors[id] = seriesColor;
  }

  public mergeColumnColors(seriesColor: Record<string, string>, id: string) {
    const settings = this.findChartSettings(id);
    const colors = settings ? settings.seriesColor : null;

    if (colors) {
      Object.entries(seriesColor).forEach((key, columnIndex) => {
        const [colorKey, colorValue] = key;
        colors[colorKey] = colorValue;
      });

      this.saveChartSettings(settings, id);
      this.saveColumnColors(colors, id);
    }
  }

  public loadColumnColors(id: string): Record<string, string> {
    const colors = this.chartColors[id];
    return colors || this.loadDefaultColumnColors();
  }

  public loadDefaultColumnColors(): Record<string, string> {
    const columnColors: Record<string, string> = {};

    ChartColors.forEach((column, index) => {
      columnColors[index + ''] = column;
    });

    // copy in globalColors if available
    const globalColors = this.getGlobalChartSettings()?.seriesColor;
    if (globalColors) {
      Object.entries(globalColors).forEach((key, columnIndex) => {
        const [colorKey, colorValue] = key;
        columnColors[colorKey] = colorValue;
      });

      return columnColors;
    }
  }
}
