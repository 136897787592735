import { Observable } from 'rxjs';
import { LoadMultiSurveyResult, UploadFile } from '../models/file-upload-model';
import { EventEmitter, Injectable } from '@angular/core';
import { MediaPlannerService } from './media-planner.service';
import { ScheduleVehicle, TargetVehicle } from '../classes/vehicle';
import { ScheduleTotalTag } from '../classes/schedule-total';
import { ConverterService } from './converter.service';
import { DocumentTarget } from '../models/document.model';
import { EngineService } from './engine.service';
import { Target } from '../classes/target';
import { cloneDeep } from 'lodash';
import {
  MULTI_SURVEY_MANUAL,
  MultiSurveyManualInput,
} from '../models/multi-survey.models';
import { PlanningService } from './planning.service';
import { Result } from '../classes/result';
import uniqid from 'uniqid';
import { ManualInputVehicleRequest } from '../models/engine.evaluate-multi-media-plan.models';
import { ExportMrfResult } from '../models/multi-survey-mrf.models';

@Injectable({
  providedIn: 'root',
})
export class MultiSurveyService {
  multiSurveyStartProcessing: EventEmitter<any> = new EventEmitter();
  multiSurveyTargetSelected: EventEmitter<{
    currentTab: string;
    refresh?: boolean;
  }> = new EventEmitter();

  constructor(
    private mediaplannerService: MediaPlannerService,
    private engineService: EngineService,
    private planningService: PlanningService
  ) {}

  parseMultiSurveyFile(file: UploadFile) {
    return new Observable((observable) => {
      const handleComplete = (result: LoadMultiSurveyResult) => {
        observable.next(result);
        observable.complete();
      };

      // will determine file format to inside the load method itelf (DAU, MRF, etc)
      const multiSurveyResult =
        this.mediaplannerService.plan.multiSurveys.load(file);
      handleComplete(multiSurveyResult);
    });
  }

  // add vehicles of the given multiSurvey id to the plan, without creating a new target
  addMultiSurveyVehiclesToCurrentTarget(
    selectedTarget?: Target
  ): Observable<any> {
    return new Observable((obs) => {
      const planningTargetIndex =
        this.mediaplannerService.plan.targets.findIndex(
          (tgt) => tgt.planningTarget
        );
      const planTarget = selectedTarget
        ? cloneDeep(selectedTarget)
        : cloneDeep(this.mediaplannerService.plan.targets[planningTargetIndex]);

      this.prepareTargetVehicleData(planTarget).subscribe((success) => {
        if (!success) {
          this.removeMultiSurveyDataFromPlan(0);
        } else {
          this.mediaplannerService.plan.targets[planningTargetIndex] =
            cloneDeep(planTarget); // trigger compare-media onChanges
        }
        obs.next();
        obs.complete();
      });
    });
  }

  addDAUFromDashboard(useDAUPopulation: boolean): Observable<any> {
    return new Observable((ob) => {
      const multiSurveyAudience =
        this.mediaplannerService.plan.multiSurveys.getPlanAudience();

      const name = useDAUPopulation
        ? multiSurveyAudience.name
        : 'I build my own audience';
      const population = useDAUPopulation
        ? multiSurveyAudience.population
        : this.mediaplannerService.plan.currentSurvey.population;
      const scale = useDAUPopulation
        ? multiSurveyAudience.scale
        : this.mediaplannerService.plan.currentSurvey.units;

      const newTarget: DocumentTarget =
        ConverterService.buildDocumentTargetFromMultiSurveyAudience(
          name,
          population,
          scale,
          this.mediaplannerService.plan.currentSurvey
        );
      const multiSurveyVehicles =
        this.mediaplannerService.plan.multiSurveys.getAllVehicles();
      this.mediaplannerService.addTarget(newTarget).subscribe((target) => {
        target.addVehicles(multiSurveyVehicles, true);
        ob.next();
        ob.complete();
      });
    });
  }

  // audienceId can be regular survey audience Id or uploaded multi survey file id
  addDAUDataToPlan(
    audienceId: string,
    documentTarget?: DocumentTarget
  ): Observable<any> {
    return new Observable((ob) => {
      const plan = this.mediaplannerService.plan;

      // regular survey vehicles + manual input media type
      const targetVehsToCopy: TargetVehicle[] = plan.targets.length
        ? cloneDeep(
            plan.targets[0].vehicles.filter(
              (veh) => !veh.isMultiSurvey || veh.multiSurveyConfig?.manualInput
            )
          )
        : null;

      if (audienceId) {
        const selectedSurveyTarget = plan.targets.find(
          (target) => target.id === audienceId
        );
        // mark for removal every plan target that is not audienceId or addressable
        const targetCoding = plan.targets.map((target) => {
          if (target.id !== audienceId && target.documentTarget.planningTarget)
            return target.coding;
        });

        if (selectedSurveyTarget) {
          // remove un-needed targets
          targetCoding.forEach((coding) => {
            this.mediaplannerService.deleteTarget(coding, false);
          });
          this.addMultiSurveyVehiclesToCurrentTarget(
            selectedSurveyTarget
          ).subscribe(() => {
            ob.next();
            ob.complete();
          });
        } else {
          this.engineService
            .getMultiTargetEvaluation([documentTarget])
            .subscribe((res) => {
              this.mediaplannerService
                .addTarget(documentTarget, targetVehsToCopy)
                .subscribe((target) => {
                  this.prepareTargetVehicleData(
                    target,
                    targetVehsToCopy
                  ).subscribe((success) => {
                    if (!success) {
                      this.removeMultiSurveyDataFromPlan(0);
                      this.mediaplannerService.deleteTarget(
                        documentTarget.coding,
                        false
                      );
                    } else {
                      // delete other existing targets
                      targetCoding.forEach((coding) => {
                        this.mediaplannerService.deleteTarget(coding, false);
                      });
                      // make sure we assign the new data to a planning target (some targets can be only addressable)
                      this.mediaplannerService.plan.targets.find(
                        (tgt) => tgt.planningTarget
                      )[0] = cloneDeep(target); // trigger compare-media onChanges
                    }
                    ob.next();
                    ob.complete();
                  });
                });
            });
        }
      } else {
        ob.next();
        ob.complete();
      }
    });
  }

  addMRFDataToPlan(): Observable<any> {
    return new Observable((obs) => {
      this.mediaplannerService.plan.targets.forEach((target) => {
        this.mediaplannerService.plan.multiSurveys.multiSurveys.forEach(
          (multiSurvey) => {
            const targetVehs: TargetVehicle[] = multiSurvey.getTargetVehicles();
            target.addVehicles(targetVehs, true);

            this.mediaplannerService.plan.schedules.forEach((schedule) => {
              const scheduleVehs: ScheduleVehicle[] =
                multiSurvey.getScheduleVehicles(target.id);

              schedule.vehicles.push(...scheduleVehs);

              const scheduleTotal = schedule.addScheduleTotal(
                targetVehs[0].mediaType,
                ScheduleTotalTag.multiSurvey,
                target
              );

              scheduleTotal.result.population =
                scheduleVehs[0].result.population;
            });
          }
        );
      });
      this.planningService
        .evaluate(
          this.mediaplannerService.plan.targets,
          this.mediaplannerService.plan.schedules[0],
          this.mediaplannerService.plan.vehicleGroups.groups,
          this.mediaplannerService.plan.columns.includeUniqueReach,
          false
        )
        .subscribe((success) => {
          if (!success) {
            this.removeMultiSurveyDataFromPlan(0);
          }
          obs.next(success);
          obs.complete();
        });
    });
  }

  removeMultiSurveyDataFromPlan(multiSurveyIndex: number) {
    const multiSurvey =
      this.mediaplannerService.plan.multiSurveys.multiSurveys[multiSurveyIndex];
    if (multiSurvey) {
      const vehicles = multiSurvey.getTargetVehicles();
      vehicles.forEach((vehicle) => {
        this.mediaplannerService.plan.removeVehicle(vehicle.id);
      });
      this.mediaplannerService.plan.multiSurveys.remove(multiSurveyIndex); // remove last uploaded file
    }
  }

  // add manual input as media type total
  addManualInput(
    manualInputData: MultiSurveyManualInput,
    scheduleIndex: number = 0,
    audienceId?: string
  ): Observable<any> {
    return new Observable((obs) => {
      if (audienceId) {
        // remove every plan target that is not audienceId
        this.mediaplannerService.plan.targets.forEach((tgt) => {
          if (tgt.id !== audienceId && tgt.documentTarget.planningTarget) {
            this.mediaplannerService.deleteTarget(tgt.coding, false);
          }
        });
      }
      const vehId = `MAN_VEH_${uniqid()}`;

      const targetManualVehs: TargetVehicle = {
        id: vehId,
        mnemonic: vehId,
        mediaType: manualInputData.title,
        mediaTypeId: -1,
        addressable: false,
        calculationMethod: MULTI_SURVEY_MANUAL,
        isMediaGroup: false,
        title: manualInputData.title,
        originalTitle: manualInputData.title,
        audience: 0,
        grossAudience: 0,
        resps: 0, // ?
        potentialReach: 0, // ?
        compositionIndex: 0, // ?
        compositionPct: 0, // ?
        dayparts: [],
        ESG: [{ value: -1, Provider: '?' }],
        isMultiSurvey: true,
        multiSurveyConfig: { multiSurveyId: vehId, manualInput: true },
      };

      // add new manual vehicle to all targets, to make sure it is added to addressable too
      const targets = this.mediaplannerService.plan.targets;
      targets.forEach((tgt) => {
        tgt.addVehicles([targetManualVehs], false);
      });

      const planningTargets = this.mediaplannerService.plan.targets.filter(
        (tgt) => tgt.documentTarget.planningTarget
      );
      const target = planningTargets[0];

      this.mediaplannerService.plan.schedules.forEach((schedule) => {
        const scheduleVeh: ScheduleVehicle = {
          vehicleId: vehId,
          targetId: target.id,
          isMultiSurvey: true,
          result: new Result(target.population, {
            inserts: manualInputData.inserts,
          }),
        };

        schedule.vehicles.push(scheduleVeh);

        const scheduleTotal = schedule.addScheduleTotal(
          manualInputData.title,
          ScheduleTotalTag.multiSurvey,
          target
        );
        scheduleTotal.result.population = target.population;
        scheduleTotal.result.addResults({ inserts: manualInputData.inserts });
      });

      const manualVehicleRequest: ManualInputVehicleRequest = {
        reach: manualInputData.reachPct,
        grp: manualInputData.grp,
        frequency: manualInputData.avgFreq,
        numberOfSpots: manualInputData.inserts,
        cost: manualInputData.totalCost,
        manualEntryId: vehId,
      };

      this.planningService
        .evaluate(
          this.mediaplannerService.plan.targets,
          this.mediaplannerService.plan.schedules[scheduleIndex],
          this.mediaplannerService.plan.vehicleGroups.groups,
          this.mediaplannerService.plan.columns.includeUniqueReach,
          false,
          null,
          manualVehicleRequest
        )
        .subscribe((isSuccess) => {
          if (!isSuccess) {
            this.mediaplannerService.plan.removeVehicle(vehId);
          } else {
            this.mediaplannerService.plan.targets = cloneDeep(targets);
          }
          obs.next();
          obs.complete();
        });
    });
  }

  updateManualInput(
    manualInputData: MultiSurveyManualInput,
    manualEntryId: string,
    scheduleIndex: number = 0
  ): Observable<any> {
    return new Observable((obs) => {
      const manualVehicleRequest: ManualInputVehicleRequest = {
        reach: manualInputData.reachPct,
        grp: manualInputData.grp,
        frequency: manualInputData.avgFreq,
        cost: manualInputData.totalCost,
        manualEntryId,
      };

      this.planningService
        .evaluate(
          this.mediaplannerService.plan.targets,
          this.mediaplannerService.plan.schedules[scheduleIndex],
          this.mediaplannerService.plan.vehicleGroups.groups,
          this.mediaplannerService.plan.columns.includeUniqueReach,
          false,
          null,
          manualVehicleRequest
        )
        .subscribe(() => {
          obs.next();
          obs.complete();
        });
    });
  }

  private migrateVehiclesToTarget(target: Target, vehicles: TargetVehicle[]) {
    if (vehicles) {
      const plan = this.mediaplannerService.plan;
      const vehIds = vehicles.map((veh) => veh.id);
      const surveyScheduleVehs: ScheduleVehicle[] = plan.schedules[0].vehicles
        .length
        ? cloneDeep(
            plan.schedules[0].vehicles.filter((veh) =>
              vehIds.includes(veh.vehicleId)
            )
          )
        : null;
      if (surveyScheduleVehs) {
        surveyScheduleVehs.forEach((schVeh) => (schVeh.targetId = target.id));
        plan.schedules.forEach((schedule) => {
          schedule.vehicles.push(...surveyScheduleVehs);
        });
      }
    }
  }

  // add vehicles from imported file to target and schedule
  private prepareTargetVehicleData(
    target: Target,
    vehiclesToCopy?: TargetVehicle[]
  ): Observable<any> {
    return new Observable((ob) => {
      // make sure existing survey vehicles are assigned to the new target
      if (vehiclesToCopy) {
        this.migrateVehiclesToTarget(target, vehiclesToCopy);
      }

      this.mediaplannerService.plan.multiSurveys.multiSurveys.forEach(
        (multiSurvey) => {
          const targetVehs: TargetVehicle[] = multiSurvey.getTargetVehicles();
          target.addVehicles(targetVehs, true);
          this.mediaplannerService.plan.schedules.forEach((schedule) => {
            const scheduleVehs: ScheduleVehicle[] =
              multiSurvey.getScheduleVehicles(target.id);

            // keep only current target vehicles, then add multi survey vehicles
            schedule.vehicles = schedule.vehicles.filter(
              (schVeh) => schVeh.targetId === target.id
            );
            // add multi survey vehicles to schedule, if they don't exist
            scheduleVehs.forEach((multiSurveyVeh) => {
              const newSchVeh = schedule.vehicle(
                target,
                multiSurveyVeh.vehicleId
              );
              newSchVeh.result = multiSurveyVeh.result;
            });
            if (scheduleVehs.length && targetVehs.length) {
              const scheduleTotal = schedule.addScheduleTotal(
                targetVehs[0].mediaType,
                ScheduleTotalTag.multiSurvey,
                target
              );

              scheduleTotal.result.population =
                scheduleVehs[0].result.population;
              this.mediaplannerService.plan.multiSurveys.calculateTotals(
                scheduleVehs,
                scheduleTotal
              );
            }
          });
        }
      );

      const addrTargets = this.mediaplannerService.plan.targets.filter(
        (tgt) => tgt.addressableTarget && !tgt.planningTarget
      );
      this.planningService
        .evaluateAllSchedules(
          [target, ...addrTargets],
          this.mediaplannerService.plan.schedules,
          this.mediaplannerService.plan.vehicleGroups.groups,
          this.mediaplannerService.plan.columns.includeUniqueReach,
          false
        )
        .subscribe((success) => {
          ob.next(success);
          ob.complete();
        });
    });
  }

  removeMultiSurveyFile(multiSurveyId: string) {
    const index =
      this.mediaplannerService.plan.multiSurveys.multiSurveys.findIndex(
        (ms) => ms.id === multiSurveyId
      );
    index !== -1
      ? this.mediaplannerService.plan.multiSurveys.remove(index)
      : null;
  }

  renameImportedMediaType(initialName: string, newName: string) {
    const newTargets = this.mediaplannerService.plan.targets.map((target) => {
      target.vehicles.forEach((vehicle) => {
        if (vehicle.mediaType === initialName) {
          vehicle.mediaType = newName;
        }
      });
      this.mediaplannerService.plan.schedules.forEach((schedule) => {
        const initialMediaTypeTotal = schedule.getScheduleTotal(
          initialName,
          ScheduleTotalTag.multiSurvey,
          target
        );
        const newMediaTypeTotal = schedule.addScheduleTotal(
          newName,
          ScheduleTotalTag.multiSurvey,
          target
        );
        newMediaTypeTotal.result = initialMediaTypeTotal.result;
        schedule.removeScheduleTotal(
          initialName,
          ScheduleTotalTag.multiSurvey,
          target
        );
      });

      return target;
    });

    this.mediaplannerService.plan.targets = newTargets;
  }

  downloadMrfFile(mrfData: ExportMrfResult) {
    try {
      const jsonStr = JSON.stringify(mrfData);
      const blob = new Blob([jsonStr], { type: 'text/json' });
      const url = URL.createObjectURL(blob);

      this.downloadFile(
        url,
        `${mrfData.header?.campaignTitle || 'Multi Survey file'}.MRF`
      );
    } catch (error) {
      console.error('Error exporting to JSON:', error);
    }
  }

  private downloadFile(url: string, filename: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }
}
