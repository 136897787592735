<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <tup-mat-title-bar
    title="Your current survey selection"
    class="dialog-title"
  >
    <mat-icon
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <mat-table
      [dataSource]="dataSource"
      class="survey-media-types-table"
    >
      <ng-container matColumnDef="title">
        <mat-header-cell
          *matHeaderCellDef
          class="survey-column"
        >
          Survey
        </mat-header-cell>
        <mat-cell
          *matCellDef="let survey"
          class="survey-column"
        >
          {{ survey.title }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mediaTypes">
        <mat-header-cell
          *matHeaderCellDef
          class="media-types-column"
        >
          Media type(s)
        </mat-header-cell>
        <mat-cell
          *matCellDef="let survey"
          class="media-types-column"
        >
          {{ survey.mediaTypes }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </mat-dialog-content>

  <mat-divider class="divider-horizontal"></mat-divider>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end"
  >
    <button
      mat-flat-button
      color="primary"
      cdkFocusRegionstart
      (click)="close()"
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
