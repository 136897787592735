import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SurveyMetrics } from 'src/app/models/planning.models';
import { cloneDeep } from 'lodash';

export enum SurveyInfoType {
  info = 'info',
  copyright = 'copyright',
}

export class SurveyInfoDialogModel {
  type: SurveyInfoType;
  surveys: SurveyMetrics[];
}

@Component({
  selector: 'app-survey-info-dialog',
  templateUrl: './survey-info-dialog.component.html',
  styleUrls: ['./survey-info-dialog.component.scss'],
})
export class SurveyInfoDialogComponent {
  public type: SurveyInfoType = SurveyInfoType.copyright;
  public SurveyInfoType = SurveyInfoType;
  public surveys: SurveyMetrics[] = [];

  constructor(
    public dialogRef: MatDialogRef<SurveyInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SurveyInfoDialogModel
  ) {
    this.type = data.type;
    this.surveys = cloneDeep(data.surveys);
  }

  public onClose(action: string): void {
    if (action === 'download') {
      this.downloadFile(this.surveys[0].surveyInfo);
    }
    this.dialogRef.close(null);
  }

  downloadFile(data: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    var anchor = document.createElement('a');
    anchor.download = `${this.surveys[0].surveyTitle}.txt`;
    anchor.href = url;
    anchor.click();

    this.dialogRef.close(null);
  }
}
