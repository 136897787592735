import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarGenericOptionModel } from '../snackbar-generic/snackbar-generic.component';

export interface QuestionDialogModelOptions {
  buttons?: QuestionDialogModelButtonOptions[];
  closeButton?: QuestionDialogModelButtonOptions;
  snackbar?: SnackbarGenericOptionModel;
  buttonAlignment?: string;
  minWidth?: string;
  maxWidth?: string;
}

export interface QuestionDialogModelButtonOptions {
  caption: string;
  data: string;
  flat?: boolean;
}

export class ConfirmDialogModel {
  buttons: QuestionDialogModelButtonOptions[];
  closeButton: QuestionDialogModelButtonOptions;
  snackbar: SnackbarGenericOptionModel;
  buttonAlignment: string = 'space-between';

  constructor(
    public title: string,
    public message: string[],
    options?: QuestionDialogModelOptions
  ) {
    if (options) {
      this.buttons = options.buttons || this.buttons;
      this.closeButton = options.closeButton;
      this.snackbar = options.snackbar;
      this.buttonAlignment = options.buttonAlignment || this.buttonAlignment;
    }
  }
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string[];
  buttons: QuestionDialogModelButtonOptions[];
  closeButton: QuestionDialogModelButtonOptions;
  buttonAlignment: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.buttons = data.buttons;
    this.closeButton = data.closeButton;
    this.buttonAlignment = data.buttonAlignment;
  }

  ngOnInit() {}

  onButtonClick(but: QuestionDialogModelButtonOptions): void {
    this.dialogRef.close(but);
  }
}
