import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { DocumentFullSurvey } from '../models/document.model';
import {
  CodebookNavigationType,
  Statement,
} from '../models/visual-code-builder.models';
import { ApiService } from './api.service';
import { ENVIRONMENT } from '@telmar-global/tup-user-preference-storage';

export enum LoadingSource {
  unknown,
  fromDrag,
  fromMenu,
  fromButton,
}

export class LoadingNode {
  inProgress: boolean;
  origin: LoadingSource;

  constructor(
    inProgress: boolean = false,
    origin: LoadingSource = LoadingSource.unknown
  ) {
    this.inProgress = inProgress;
    this.origin = origin;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CodebookSelectionService {
  private selectedNavigationType = CodebookNavigationType.codebook;
  private codebookSelectedNodes: Statement[] = [];
  private workspaceSelectedNodes: Statement[] = [];

  private selectedNodesSubject = new BehaviorSubject<Statement[]>([]);
  public selectedNodes$ = this.selectedNodesSubject.asObservable();

  private loadingSelectedNodesSubject = new BehaviorSubject<LoadingNode>(
    new LoadingNode()
  );
  public loadingSelectedNodes$ =
    this.loadingSelectedNodesSubject.asObservable();

  constructor(
    @Inject(ENVIRONMENT) private environment,
    private apiService: ApiService
  ) {}

  getSurveys(
    filterSurveysBy: Map<string, boolean>,
    matchText?: string
  ): Observable<DocumentFullSurvey[]> {
    const addFilters = {};
    filterSurveysBy
      ? filterSurveysBy.forEach((value, key) => (addFilters[key] = value))
      : null;

    return new Observable((observable) => {
      const options = {
        body: {
          ...(matchText && { matchText }),
          ...addFilters,
        },
      };
      this.apiService
        .request(
          'POST',
          this.environment.api.codebook.url,
          this.environment.api.codebook.endPoint.getSurveys,
          options
        )
        .subscribe((data) => {
          const surveyResults: DocumentFullSurvey[] = [];
          if (data?.success) {
            data.surveyInstances.forEach((surv) => {
              surveyResults.push(...this.formatSurveyInstance(surv));
            });
          }

          observable.next(surveyResults);
          observable.complete();
        });
    });
  }

  public setLoadingChildrenNodes(
    isLoading: boolean,
    origin: LoadingSource = LoadingSource.unknown
  ): void {
    this.loadingSelectedNodesSubject.next(new LoadingNode(isLoading, origin));
  }

  public setSelectedNodes(selectedNodes: Statement[]): void {
    if (this.selectedNavigationType === CodebookNavigationType.codebook) {
      this.codebookSelectedNodes = selectedNodes;
    } else {
      this.workspaceSelectedNodes = selectedNodes;
    }
    this.selectedNodesSubject.next(this.getSelectedNodes());
  }

  public unselectNodes(): void {
    this.setSelectedNodes([]);
  }

  public setSelectedNavigationType(type: CodebookNavigationType): void {
    this.selectedNavigationType = type;
    this.selectedNodesSubject.next(this.getSelectedNodes());
  }

  public getSelectedNodes(): Statement[] {
    return this.selectedNavigationType === CodebookNavigationType.codebook
      ? this.codebookSelectedNodes
      : this.workspaceSelectedNodes;
  }

  private formatSurveyInstance(surveyInstance: any): DocumentFullSurvey[] {
    const authGroup = {};
    surveyInstance['available-authorization-groups'].map((auth) => {
      Object.assign(authGroup, auth);
    });
    return surveyInstance['authorization-groups'].map((group: string) => ({
      code: surveyInstance['survey-instance'],
      title: surveyInstance['survey-name'],
      year: surveyInstance.year,
      occurrenceCount: surveyInstance.codebookMatches || 0,
      isMappable:
        surveyInstance['is-geografix'] ||
        surveyInstance['is-mappable'] ||
        false,
      isMb3Enabled: surveyInstance['is-mb3-enabled'],
      isMultibased: surveyInstance['is-multibased'],
      provider: surveyInstance['survey-provider'],
      language: surveyInstance['survey-language'] || 'English',
      isTrendable: surveyInstance['survey-stream'] !== '',
      hasVehicles: surveyInstance['has-vehicles'],
      meta: Object.keys(surveyInstance).reduce(
        (acc, key) => ({ ...acc, [key]: surveyInstance[key] }),
        {}
      ),
      authorizationGroup: group,
      authorizationGroupDescription: authGroup[group]
        ? `${authGroup[group]} (${surveyInstance['survey-instance']})`
        : `${surveyInstance.year} ${surveyInstance['survey-name']} (${surveyInstance['survey-instance']})`,
      isFullAccess: group === '_ALL_',
      mediaTypes: surveyInstance['media-types'],
    }));
  }
}
