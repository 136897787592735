import { chunk, cloneDeep, isArray } from 'lodash';

import { TupChartsComponent } from '@telmar-global/tup-charts';
import {
  percentToInches,
  TupChartData,
  TupChartType,
  TupPptxBuilder,
  CIRCULAR_CHART_TYPES,
  UNSUPPORTED_PPTX_CHART_TYPES,
} from '@telmar-global/tup-document-exporter';

import { getExportProps, getComboChartData } from '../utils/exportUtils';
import { provideColorsToCharts, rgbaToHex } from '../utils/colorHelper';
import { CrossMediaProps, ExportFileType } from '../models/export.model';

/**
 * Custom Cross Media PptxBuilder
 */
export class CrossMediaPptxBuilder extends TupPptxBuilder {
  constructor(public exportType: ExportFileType) {
    super();
  }

  public async init(props: CrossMediaProps[]): Promise<CrossMediaPptxBuilder> {
    this.setLayout().addTitleSlide();

    await Promise.all(
      props.map(
        async ({
          settings,
          primaryData,
          secondaryData,
          charts,
          title,
          subtitle,
          source,
          copyright,
        }: CrossMediaProps) => {
          const {
            primaryChartType,
            primaryChartData,
            secondaryChartType,
            secondaryChartData,
            chartColors,
            chartOpts,
          } = getExportProps(settings, primaryData, secondaryData);

          console.log(
            'props getExportProps',
            getExportProps(settings, primaryData, secondaryData)
          );
          // Unsupported chart types (which includes combined target scatter
          // charts, for now): Displayed as a single image, or multiple images
          // (up to 4 per slide)
          if (
            UNSUPPORTED_PPTX_CHART_TYPES.includes(primaryChartType) ||
            (primaryChartType === TupChartType.tupScatter &&
              isArray(secondaryData) &&
              secondaryData.length > 1)
          ) {
            await this.addChunkedImages(
              charts.map((chart: TupChartsComponent) => chart.toBase64Image()),
              title,
              subtitle,
              source,
              copyright
            );
            // Donut and pie charts: Displayed as a single chart, or multiple charts (up to 4 per slide)
          } else if (CIRCULAR_CHART_TYPES.includes(primaryChartType)) {
            this.addChunkedCharts(
              primaryChartType,
              primaryChartData,
              chartOpts,
              title,
              subtitle,
              source,
              copyright
            );
            // Supported core and combo chart types: Displayed as a single chart
          } else {
            this.addDefaultSlide(title, subtitle, source, copyright);

            if (secondaryChartType) {
              this.addComboChart(
                getComboChartData(
                  settings,
                  primaryChartData,
                  secondaryChartData,
                  chartColors.map((color) => rgbaToHex(color.toLowerCase()))
                ),
                chartOpts
              );
            } else {
              this.addCoreChart(primaryChartType, primaryChartData, chartOpts);
            }
          }
        }
      )
    );

    return this;
  }

  private addDefaultSlide(
    title: string,
    subtitle: string,
    source: string,
    copyright: string
  ): CrossMediaPptxBuilder {
    this.addSlide()
      .addChartTitle(title)
      .addChartSubtitle(subtitle)
      .addChartSource(source)
      .addChartCopyright(copyright);
    return this;
  }

  /**
   * Should these guys be extracted into the shared library? They seem quite
   * Survey Time specific and provideColorsToCharts is already replicated in
   * multiple libraries.
   */
  private addChunkedCharts(
    type: TupChartType,
    data: TupChartData[],
    options: { [key: string]: any },
    title: string,
    subtitle: string,
    source: string,
    copyright: string
  ): CrossMediaPptxBuilder {
    chunk(data, 4).forEach((chunks: TupChartData[], i: number) => {
      this.addDefaultSlide(title, subtitle, source, copyright);

      chunks.forEach(
        // tslint:disable-next-line:no-shadowed-variable
        (chunk: TupChartData, j: number, array: TupChartData[]) => {
          // tslint:disable-next-line:variable-name
          const _options: { [key: string]: any } = cloneDeep(options);

          if (data.length > 1) {
            _options.w = percentToInches('30%', 'h');
            _options.legendPos = 'l!important';

            this.adjustPosition(_options, j, array);

            const startColor: string = !_options.chartColors[i + j].startsWith(
              '#'
            )
              ? `#${_options.chartColors[i + j]}`
              : _options.chartColors[i + j];

            _options.chartColors = provideColorsToCharts(
              startColor,
              chunk.values.length
            );
          }

          this.addCoreChart(type, [chunk], _options);
        }
      );
    });

    return this;
  }

  private async addChunkedImages(
    data: string[],
    title: string,
    subtitle: string,
    source: string,
    copyright
  ): Promise<CrossMediaPptxBuilder> {
    for (const [, chunks] of chunk(data, 4).entries()) {
      this.addDefaultSlide(title, subtitle, source, copyright);

      // tslint:disable-next-line:no-shadowed-variable
      for (const [j, chunk] of chunks.entries()) {
        let options: { [key: string]: any };

        if (data.length > 1) {
          options = {
            sizing: {
              w: percentToInches('30%', 'h'),
              h: percentToInches('50%', 'v'),
            },
          };

          this.adjustPosition(options, j, chunks);
        }

        await this.addImage(chunk, options);
      }
    }

    return this;
  }

  private adjustPosition(
    options: { [key: string]: any },
    index: number,
    array: any[]
  ): void {
    // Move odd number charts to the right
    if (index % 2) {
      options.x = percentToInches('65%', 'h');
    } else {
      // Leave even number charts where they are, unless it is the first
      // (and only) *or* the last chart, in which case, move it to the middle
      if (index === array.length - 1) {
        options.x = percentToInches('35%', 'h');
      }
    }

    // Move the last two charts down to the bottom
    if (index > 1) {
      options.y = percentToInches('50%', 'v');
    }
  }
}
