import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeTableMenuItem } from '../tree-table.models';

@Component({
  selector: 'tree-inline-menu',
  templateUrl: './tree-inline-menu.component.html',
  styleUrls: ['./tree-inline-menu.component.scss'],
})
export class TreeInlineMenuComponent implements OnInit {
  @Input() appearance: 'tiny' | 'small' | 'normal' = 'normal';
  @Input() menu: TreeTableMenuItem[];
  @Input() panelClass: string;
  @Output() itemClick: EventEmitter<TreeTableMenuItem> =
    new EventEmitter<TreeTableMenuItem>();
  @Output() menuClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClick(item: TreeTableMenuItem) {
    this.itemClick.emit(item);
  }
  onMenuclick() {
    this.menuClick.emit();
  }
}
