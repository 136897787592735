import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseStepComponent } from '../base-step/base-step.component';
import {
  NodeMenuClickEvent,
  TreeTableExpandEvent,
  TreeTableMenuItem,
  TreeTableNode,
} from 'src/app/components/tree-table/tree-table.models';
import {
  CodebookService,
  MatchType,
  SearchFor,
  SearchIn,
} from 'src/app/services/codebook.service';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { TreeTableComponent } from 'src/app/components/tree-table/tree-table.component';
import {
  ESGColumnStatus,
  MediaStatement,
  MediaTableComponent,
} from 'src/app/media-table/media-table.component';
import {
  MediaVehicle,
  NavigationLevel,
  Statement,
} from 'src/app/models/codebook.models';
import { EngineService } from 'src/app/services/engine.service';
import { TargetVehicle } from 'src/app/classes/vehicle';
import { Observable, of } from 'rxjs';
import {
  DocumentFullSurvey,
  DocumentSurvey,
} from 'src/app/models/document.model';
import { TupLoggerService } from '@telmar-global/tup-logger-angular';
import { AudienceGroupsService } from 'src/app/services/audience-groups.service';
import {
  DocumentMediaGroupItem,
  SaveOwnCodesTargetGroup,
  SaveOwnCodesType,
} from '@telmar-global/tup-audience-groups';
import { TargetAudienceSetVehicle } from 'src/app/models/engine.media-evaluation.models';
import { ConverterService } from 'src/app/services/converter.service';
import { cloneDeep } from 'lodash';
import { SurveyMetrics } from 'src/app/models/planning.models';
import { PlanningService } from 'src/app/services/planning.service';
import { map, tap } from 'rxjs/operators';
import { Target } from 'src/app/classes/target';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { noSearchResultsMessageHTML } from 'src/app/pages/editor/editor.component';
import { SelectionOption } from 'src/app/components/simple-selection/simple-selection.component';
import { MultiSurveyService } from '../../services/multi-survey.service';

const CATEGORY_ALL = 'All mediatypes';
const MEDIATYPE_ALL = 'All Mediatypes';

enum OperatorButtonAction {
  ADD = 'add',
  REMOVE = 'remove',
}

const SEARCH_OPTION_TOGGLE: SelectionOption = {
  id: 'search_toggle',
  selectedLabel: 'Search coding',
  label: 'Search titles ',
  selected: false,
};

interface OperatorButton {
  label: string;
  toolTip: string;
  action: OperatorButtonAction;
}

const SEARCH_OPTION_OPTIONS: SelectionOption[] = [
  {
    id: MatchType.SearchAnyKeyword,
    label: 'Any keyword',
    selected: true,
  },
  {
    id: MatchType.SearchAllKeyword,
    label: 'All keywords',
    selected: false,
  },
  {
    id: MatchType.SearchExactKeyword,
    label: 'Exact phrase',
    selected: false,
  },
  {
    id: MatchType.SearchStartingKeyword,
    label: 'Starting with',
    selected: false,
  },
];

@Component({
  selector: 'media-step',
  templateUrl: './media-step.component.html',
  styleUrls: ['./media-step.component.scss'],
})
export class MediaStepComponent extends BaseStepComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() surveyBarSelectedSurveys: DocumentFullSurvey[];

  @Output() navigation: EventEmitter<number> = new EventEmitter<number>();
  @Output() pulse: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('mediaTree') mediaTree: TreeTableComponent;
  @ViewChild('mediaTable') mediaTable: MediaTableComponent;
  @ViewChild('treeContainer') treeContainer: ElementRef;

  label: string = 'media';

  surveyBarSurvey: SurveyMetrics;
  surveyBarCurrentSurvey: DocumentSurvey;
  fullTreeData: TreeTableNode[] = [];
  mediaTypes: string[] = [];
  currentMediaType: string;
  _treeData: TreeTableNode[] = [];
  get treeData(): TreeTableNode[] {
    // dont expose root level to the tree component
    return this._treeData.length ? this._treeData[0].children : [];
  }

  // called from the view for survey data
  get currentSurvey(): DocumentFullSurvey {
    return this.mediaplannerService.plan.currentSurvey;
  }
  get meadiaTebleSortFilter() {
    return this.mediaplannerService.plan.mediaSettings.sortBy;
  }

  get mediaPlannerTargets(): Target[] {
    return this.mediaplannerService.plan.targets;
  }

  preSelectedNodes: TreeTableNode[] = [];
  unitsText: string;
  targets: { title: string; population: number }[];
  processing: boolean = false;
  mediaTableProcessing: boolean = false;
  loadingVehicles: boolean = false;

  buttons: OperatorButton[] = [
    {
      label: 'keyboard_double_arrow_left',
      action: OperatorButtonAction.REMOVE,
      toolTip: 'Remove selected from plan',
    },
    {
      label: 'keyboard_double_arrow_right',
      action: OperatorButtonAction.ADD,
      toolTip: 'Add selected to plan',
    },
  ];

  // categories for tree
  categories: string[] = [];
  currentCategory: string = '';

  noSearchResultsMessageHTML: string = noSearchResultsMessageHTML;

  loadingAudiences: number = 0;
  loadingMedia: boolean = false;

  firstMediaPulse: boolean = false;

  // searching
  searching: boolean = false;
  treeSearch: string;
  showNoResultsAfterSearch: boolean = false;
  //searchMatchType: MatchType.SearchAnyKeyword;

  advancedSearchOptions = {
    options: SEARCH_OPTION_OPTIONS,
    selectedOption: SEARCH_OPTION_OPTIONS.find((o) => o.selected).id as string,
  };

  mediaSelection: MediaStatement[] = [];
  showESGColumn: ESGColumnStatus = ESGColumnStatus.unavailable;

  constructor(
    private codebookService: CodebookService,
    private planningService: PlanningService,
    private engineService: EngineService,
    private audienceGroupsService: AudienceGroupsService,
    private loggerService: TupLoggerService,
    private mediaplannerService: MediaPlannerService,
    private snackbarService: SnackbarService,
    private multiSurveyService: MultiSurveyService
  ) {
    super();
  }

  ngOnInit(): void {
    // It is possible that we got here on a refresh so see if there is a warmup required.
    this.engineService.warmUpEngine();
    this.mediaplannerService.surveyChanged.subscribe(() => {
      this.cleanupAfterSurveyChanged();
    });
    this.multiSurveyService.multiSurveyStartProcessing.subscribe(
      (currentTab) => {
        if (currentTab === 'media') {
          this.processing = true;
          this.loadingAudiences++;
          this.checkReady();
        }
      }
    );
    this.multiSurveyService.multiSurveyTargetSelected.subscribe((eventData) => {
      if (eventData.currentTab === 'media') {
        this.loadingAudiences--;
        this.loadSelectedFromPlan();
        this.processing = false;
      }
    });
  }

  saveData(passive: boolean = false): Observable<boolean> {
    super.saveData();

    if (passive) {
      this.mediaplannerService.dirty();
      this.checkReady();
      return of(true);
    } else {
      const recalcRequired = this.saveSelectedToPlan();
      this.processing = recalcRequired;
      return recalcRequired
        ? this.evaluateAllSchedules().pipe(
            map((b: boolean[]) => !b.includes(false)),
            tap(() => {
              this.mediaplannerService.saveProgress();
              this.processing = false;
              this.checkReady();
            })
          )
        : of(true);
    }
    return of(true);
  }

  loadData() {
    super.loadData();

    const surveyCode = this.mediaplannerService.plan.primarySurvey.code;
    this.unitsText =
      this.mediaplannerService.plan.surveyMetaData.meta(
        surveyCode
      ).reportUnitText;

    this._treeData = [];
    this.treeSearch = '';
    this.fetchPartialTree(null).subscribe(() => {
      // this.categories = this.treeData.map( node => node.name);
      this.categories = this.buildCategories(this.treeData);

      this.categories.unshift(CATEGORY_ALL);
      this.currentCategory = CATEGORY_ALL;
      this.fullTreeData = cloneDeep(this._treeData); // keep a copy of the complete tree so searching and filtering can be done
      this.mediaTree.refresh.emit({ newData: this.treeData });
    });

    this.loadSelectedFromPlan();
    this.buildMediaTypeList();
    this.currentMediaType = this.mediaTypes[0];
    this.surveyBarSurvey = this.mediaplannerService.getSurveyMetrics();
    this.surveyBarCurrentSurvey = this.mediaplannerService.plan.currentSurvey;
  }

  // set the node list manually
  setPreSelectedNodes(nodes: TreeTableNode[]) {
    this.preSelectedNodes = nodes;
  }

  unSelectAll() {
    this.mediaTree.selectAll(false);
    this.setPreSelectedNodes([]);
  }

  // build a unique list for the categories dropdown
  private buildCategories(tree: TreeTableNode[]): string[] {
    const cats = tree.map((level0) => level0.name);
    return [...new Set(cats)];
  }

  private cleanupAfterSurveyChanged() {
    this.mediaSelection = [];
    this.preSelectedNodes = [];
    this.loadingAudiences = 0;
    this.firstMediaPulse = false;
    this.searching = false;
    this.treeSearch = '';

    this.categories = [];
    this.currentCategory = '';

    // clear current tree.
    this._treeData = [];
    this.mediaTree
      ? this.mediaTree.refresh.emit({ newData: this.treeData })
      : null;
  }

  checkReady() {
    const ready =
      this.loadingAudiences === 0 && // any pending calculations
      this.mediaSelection.filter((media) => !media.error).length > 0; // stuff in the list, no errors

    this.mediaplannerService.campaignStatus.readyToShowSpot.next(
      this.checkBroadcast()
    );
    this.mediaplannerService.campaignStatus.readyForSpot.next(false);
    this.mediaplannerService.campaignStatus.readyForPlanning.next(ready);
    this.mediaplannerService.campaignStatus.readyForMedia.next(true);
    this.mediaplannerService.campaignStatus.readyForAudiences.next(true);
  }

  checkBroadcast() {
    let result = false;

    this.mediaSelection.forEach((media) => {
      const containsBroadcast =
        media.vehicle.dayparts && media.vehicle.dayparts.length;
      if (containsBroadcast) {
        result = true;
      }
    });

    return result;
  }

  // tree double click.  Single node to transfer across
  onDoubleClick(node: TreeTableNode) {
    this.preSelectedNodes = [node];
    this.onOperationClick(OperatorButtonAction.ADD);
  }

  OnTreeExpand(event: TreeTableExpandEvent) {
    // set as expandable to enable populating children on demand.
    if (event.node.expandable) {
      this.fetchPartialTree(event.node).subscribe((branch) => {
        event.insert(event.node, branch || []);
      });
    }
  }

  onDropCodebookItem(e) {
    this.onOperationClick(OperatorButtonAction.ADD);
  }

  getSurvey(): string {
    return this.mediaplannerService.plan.currentSurvey?.title;
  }

  // category dropdown change
  onCategoryChange(category: string) {
    if (category === CATEGORY_ALL) {
      this._treeData = cloneDeep(this.fullTreeData);
    } else {
      //select multi categories
      const categories = this.fullTreeData[0].children.filter(
        (cats) => cats.name === category
      );
      this._treeData[0].children = cloneDeep(categories);
    }
    this.mediaTree.refresh.emit({ newData: this.treeData });
    this.treeSearch = '';
  }

  // search or search reset click
  onSearchClick(searchTerm: string) {
    this.treeSearch = searchTerm;

    if (this.treeSearch === '') {
      this.showNoResultsAfterSearch = false;
      this._treeData = [];
      this.fetchPartialTree(null).subscribe(() => {
        this.mediaTree.refresh.emit({ newData: this.treeData });
      });
    } else {
      const catName: string | string[] =
        this.currentCategory === CATEGORY_ALL ? '' : [this.currentCategory];
      const surveyMetaData = this.mediaplannerService.plan.surveyMetaData;
      const updateChildSearchResult = (child: Statement) => {
        if (child.vehicle) {
          child.vehicle.mediaType =
            surveyMetaData
              .meta(this.mediaplannerService.plan.currentSurvey.code)
              .mediaTypes.find(
                (val) => val.mediaTypeId === child.vehicle.mediaTypeId
              )?.subtype || child.vehicle.mediaType;
        }
        if (child.children) {
          child.children.forEach((child) => {
            child = updateChildSearchResult(child);
          });
        }
        return child;
      };
      this.searching = true;
      this.codebookService
        .search(
          searchTerm,
          catName,
          this.currentSurvey,
          <MatchType>this.advancedSearchOptions.selectedOption,
          SearchIn.Titles,
          SearchFor.Vehicles
        )
        .subscribe((searchResult) => {
          if (searchResult && !searchResult.error) {
            searchResult.tree.children.forEach((child) => {
              if (child.children) {
                child = updateChildSearchResult(child);
              }
            });
            const parent = this.createTreeData(searchResult.tree);
            this._treeData = [parent];
            this.mediaTree.refresh.emit({ newData: this.treeData });
            searchResult.status.hits < 300
              ? this.mediaTree.expandToSearchTerm(searchTerm, false)
              : this.mediaTree.expandToLevel(1, false);

            if (searchResult.status.hits === 0) {
              this.showNoResultsAfterSearch = true;
            } else {
              this.showNoResultsAfterSearch = false;
            }
            this.applyDisabled();
          }
          if (searchResult?.error) {
            this.snackbarService.showWarningSnackBar(`${searchResult.error}`);
            this.treeSearch = '';
          }
          this.searching = false;
        });
    }
  }

  onMediaDbleClick(row: MediaStatement) {
    this.onOperationClick(OperatorButtonAction.REMOVE, row);
  }

  // bulk delete of rows, event from the <media-table>
  onMediaDeleteRows(rows: MediaStatement[]) {
    // select the correct items in the media list based on passed rows
    this.mediaSelection.forEach((media) => {
      media.selected = !!rows.find((row) => row.id === media.id);
    });

    this.onOperationClick(OperatorButtonAction.REMOVE);
  }

  removeMediaBySurveyCode(surveyCodes: string[]) {
    this.mediaSelection.forEach((media) => {
      media.selected = !!surveyCodes.find(
        (surveyCode) => surveyCode === media.vehicle.survey.code
      );
    });

    // filter all those not selected (so, exclude the selected ones)
    this.mediaSelection = this.mediaSelection.filter(
      (media) => !media.selected
    );

    // remove from the base target vehicles
    this.mediaplannerService.plan.baseTarget.vehicles =
      this.mediaplannerService.plan.baseTarget.vehicles.filter(
        (val) => !!this.mediaSelection.find((media) => media.id === val.id)
      );

    // remove from each target
    this.mediaplannerService.plan.targets.forEach((target) => {
      target.vehicles = target.vehicles.filter(
        (val) => !!this.mediaSelection.find((media) => media.id === val.id)
      );
    });

    this.applyDisabled();
    this.mediaplannerService.dirty();

    this.buildMediaTypeList();
    // if filter was applied and all vehicles from that category were removed, display all vehicles
    if (!this.mediaTypes.includes(this.currentMediaType)) {
      this.onMediaTypeChange(this.mediaTypes[0]);
    }

    this.mediaplannerService.plan.targets.forEach((target) => {
      target.vehicles = target.vehicles.filter(
        (vehicle) => !surveyCodes.includes(vehicle.survey.code)
      );
    });
  }

  // context menu in the tree nodes.  Used for renaming owncodes
  onTreeNodeMenuClick(event: NodeMenuClickEvent) {
    const [action, documentId, targetId] = event.item.data.split('_');

    if (action === 'owncoderenamegroup') {
      this.audienceGroupsService
        .renameOwnCodeGroup(documentId)
        .subscribe(() => {
          // refresh tree to include any new owncodes
          this.fetchPartialTree(null, true).subscribe(() => {
            this.mediaTree.refresh.emit({ newData: this.treeData });
          });
        });
    }

    if (action === 'owncodedelete') {
      this.audienceGroupsService
        .deleteOwnCodesWithConfirmation(documentId)
        .subscribe(() => {
          // refresh tree to include any new owncodes
          this.fetchPartialTree(null, true).subscribe(() => {
            this.mediaTree.refresh.emit({ newData: this.treeData });
          });
        });
    }
  }

  onOperationClick(action: OperatorButtonAction, row: MediaStatement = null) {
    // validate vehicles based on their id, the fetch all daypart ID array if available
    const fetchVehicles = (
      preSelectedNodes: TreeTableNode[]
    ): TargetAudienceSetVehicle[] => {
      const vehicles: TargetAudienceSetVehicle[] = [];
      const validVehs = preSelectedNodes.filter((veh) => veh.id);

      validVehs.forEach((veh) => {
        const vehId = veh.id as string;
        vehicles.push({
          id: vehId,
          mnemonic: vehId.split('^')[0],
          surveyCode: this.mediaplannerService.plan.currentSurvey.code,
          daypartIds: veh.data.vehicle.dayparts
            ? veh.data.vehicle.dayparts.map((dp) => dp.id)
            : [],
        });
      });
      return vehicles;
    };

    // REMOVE MEDIA
    if (action === OperatorButtonAction.REMOVE) {
      // remove 'row' from the list
      if (row) {
        this.mediaSelection = this.mediaSelection.filter(
          (media) => media.id !== row.id
        );
      } else {
        // filter all those not selected (so, exclude the selected ones)
        this.mediaSelection = this.mediaSelection.filter(
          (media) => !media.selected
        );
      }

      // remove from the base target vehicles
      this.mediaplannerService.plan.baseTarget.vehicles =
        this.mediaplannerService.plan.baseTarget.vehicles.filter(
          (val) => !!this.mediaSelection.find((media) => media.id === val.id)
        );

      // remove from each target
      this.mediaplannerService.plan.targets.forEach((target) => {
        target.vehicles = target.vehicles.filter(
          (val) => !!this.mediaSelection.find((media) => media.id === val.id)
        );
      });

      this.applyDisabled();
      this.saveData(true).subscribe();

      this.buildMediaTypeList();
      // if filter was applied and all vehicles from that category were removed, display all vehicles
      if (!this.mediaTypes.includes(this.currentMediaType)) {
        this.onMediaTypeChange(this.mediaTypes[0]);
      }
    }

    // ADD MEDIA
    if (action === OperatorButtonAction.ADD) {
      this.mediaTableProcessing = true;
      this.onMediaTypeChange(MEDIATYPE_ALL);
      this.savePreSelected(); // copy from preSelectedNodes (tree) to mediaSelection (table) with results as 0
      this.applyDisabled();

      this.loadingAudiences++;
      this.checkReady();

      const plan = this.mediaplannerService.plan;
      const vehicles: TargetAudienceSetVehicle[] = fetchVehicles(
        this.preSelectedNodes
      ); //this is daypartId aware

      // get audiences and potential reaches for added vehicles
      this.engineService
        .getAudiencesForTargetSet(
          plan.primarySurvey.code,
          [...plan.targets, plan.baseTarget],
          vehicles,
          this.mediaplannerService.plan.selectedSurveys.length > 1
            ? this.mediaplannerService.plan.selectedSurveys.map((survey) => ({
                surveyCode: survey.code,
                authorizationGroup: survey.authorizationGroup,
                ESGProvider: survey.esgProviders[0],
              }))
            : undefined
        )
        .subscribe(
          (results) => {
            if (results.success) {
              const baseTarget = results.results[plan.targets.length];
              results.results.forEach((resultTarget) => {
                // update target results either in the plan targets array or for the base target
                const isBaseTarget =
                  resultTarget.targetId === plan.targets.length;
                const planTarget = isBaseTarget
                  ? plan.baseTarget
                  : plan.targets[resultTarget.targetId];
                planTarget.documentTarget.population = resultTarget.universe;
                planTarget.documentTarget.sample = resultTarget.sample;
                // iterate results for the given target
                resultTarget.vehicles.forEach((resultVehicle, index) => {
                  let mediaVehicle = this.mediaSelection.find(
                    (node) => node.id === resultVehicle.Id
                  );

                  // find and populate vehicle results or add it fresh to the plan service vehicle array
                  // also update the mediaSelection array (UI table, stops spinner)
                  if (mediaVehicle) {
                    if (!isBaseTarget) {
                      mediaVehicle.targets[resultTarget.targetId].audience =
                        resultVehicle.audience;
                      mediaVehicle.targets[
                        resultTarget.targetId
                      ].grossAudience = resultVehicle.grossaudience;
                      mediaVehicle.targets[resultTarget.targetId].resps =
                        resultVehicle.sample;
                      mediaVehicle.targets[
                        resultTarget.targetId
                      ].compositionIndex =
                        resultTarget.universe * baseTarget.universe
                          ? (resultVehicle.audience /
                              resultTarget.universe /
                              (baseTarget.vehicles[index].audience /
                                baseTarget.universe)) *
                            100
                          : 0;
                      mediaVehicle.targets[
                        resultTarget.targetId
                      ].compositionPct = baseTarget.universe
                        ? (resultVehicle.audience /
                            baseTarget.vehicles[index].audience) *
                          100
                        : 0;
                      mediaVehicle.targets[
                        resultTarget.targetId
                      ].potentialReach = resultVehicle.potentialReach;
                    }

                    let targetVehicle: TargetVehicle = planTarget.vehicle(
                      resultVehicle.Id
                    );

                    const newVehicle = !targetVehicle;
                    if (newVehicle) {
                      mediaVehicle.vehicle.survey = {
                        code: this.mediaplannerService.plan.currentSurvey.code,
                        authGroup:
                          this.mediaplannerService.plan.currentSurvey
                            .authorizationGroup,
                        title:
                          this.mediaplannerService.plan.currentSurvey.title,
                      };
                      targetVehicle = ConverterService.copyMediaVehicle(
                        mediaVehicle.vehicle
                      );
                      targetVehicle.id = resultVehicle.Id;
                      targetVehicle.mnemonic = resultVehicle.Id.split('^')[0];
                    }
                    targetVehicle.audience = resultVehicle.audience;
                    targetVehicle.grossAudience = resultVehicle.grossaudience;
                    targetVehicle.resps = resultVehicle.sample;
                    targetVehicle.potentialReach = resultVehicle.potentialReach;
                    targetVehicle.compositionIndex =
                      resultTarget.universe * baseTarget.universe
                        ? (resultVehicle.audience /
                            resultTarget.universe /
                            (baseTarget.vehicles[index].audience /
                              baseTarget.universe)) *
                          100
                        : 0;
                    targetVehicle.compositionPct = baseTarget.universe
                      ? (resultVehicle.audience /
                          baseTarget.vehicles[index].audience) *
                        100
                      : 0;

                    // contains dayparts so consume those as well
                    if (
                      resultVehicle.dayparts &&
                      resultVehicle.dayparts.length
                    ) {
                      targetVehicle.dayparts.forEach((vehDaypart) => {
                        const resultDaypart = resultVehicle.dayparts.find(
                          (dp) => dp.Id === vehDaypart.mnemonic
                        );
                        if (resultDaypart) {
                          vehDaypart.audience = resultDaypart.audience;
                          vehDaypart.grossAudience =
                            resultDaypart.grossaudience;
                          vehDaypart.potentialReach =
                            resultDaypart.potentialReach;
                        }
                      });
                    }
                    newVehicle
                      ? planTarget.addVehicles([targetVehicle], true)
                      : null;
                  } // vehicles within target
                }); // target
              });

              //re-asign values to update the media table
              this.mediaSelection = [...this.mediaSelection];
            }
            // was a processing error. Handle gracefully, Stop all the spinning for the vehicles added
            else {
              vehicles.forEach((vehicle) => {
                const veh = this.mediaSelection.find(
                  (sel) => sel.id === vehicle.id
                );
                if (veh) {
                  veh.error = true;
                  veh.targets.forEach((tgt) => {
                    tgt.audience = 0;
                    tgt.resps = 0;
                    tgt.potentialReach = 0;
                    tgt.compositionIndex = 0;
                    tgt.compositionPct = 0;
                  });
                }
              });
            }

            this.loadingAudiences--;

            this.saveData(true).subscribe();
            this.mediaTableProcessing = false;
            this.checkPulse();
            this.buildMediaTypeList();
          },
          (err) => {
            console.log('ERRR', err);
          }
        );
    }

    //this.treeData.forEach( veh=> veh.checked = false);
    this.mediaTree.selectAll(false);
  }

  // evaluation can be started if any vehicles have been removed from the campaign.  This will affect results
  evaluateAllSchedules(): Observable<boolean[]> {
    return this.planningService.evaluateAllSchedules(
      this.mediaplannerService.plan.targets,
      this.mediaplannerService.plan.schedules,
      this.mediaplannerService.plan.vehicleGroups.groups,
      this.mediaplannerService.plan.columns.includeUniqueReach,
      false
    );
  }

  checkPulse() {
    if (
      this.mediaSelection.filter((media) => !media.error).length &&
      !this.firstMediaPulse
    ) {
      this.firstMediaPulse = true;
      this.pulse.emit(2);
    }
  }

  // call to get the requested level, return an observable in the TreeTable[] objects
  fetchPartialTree(
    parent: TreeTableNode,
    forceReload: boolean = false
  ): Observable<TreeTableNode[]> {
    this.loadingMedia = parent === null;
    const surveyMetaData = this.mediaplannerService.plan.surveyMetaData;

    return new Observable((observable) => {
      this.codebookService
        .getVehicleNavigation(
          this.mediaplannerService.plan.currentSurvey,
          parent ? parent.data.key : null
        )
        .subscribe((nodes: NavigationLevel[]) => {
          const parsedNodes = nodes.map((node) => {
            if (node.vehicle) {
              node.vehicle.mediaType =
                surveyMetaData
                  .meta(this.mediaplannerService.plan.currentSurvey.code)
                  .mediaTypes.find(
                    (val) => val.mediaTypeId === node.vehicle.mediaTypeId
                  )?.subtype || node.vehicle.mediaType;
            }
            return node;
          });
          const treeBranch = this.buildpartialTreeData(parsedNodes, parent);
          this.applyDisabled();

          if (parent === null) {
            this.loadAudienceGroups(treeBranch, forceReload).subscribe(() => {
              this.loadingMedia = false;
              observable.next(treeBranch);
              observable.complete();
            });
          } else {
            this.loadingMedia = false;
            observable.next(treeBranch);
            observable.complete();
          }
        });
    });
  }

  // advanced search dropdown options
  onAdvancedSearchOptionChange(opened: boolean) {
    if (!opened) {
      this.advancedSearchOptions.selectedOption =
        this.advancedSearchOptions.options.find((o) => o.selected).id as string;
    }
  }

  // respond to context menu item clicks, specifically owncodes
  onContextMenuItemClick(menuItem: TreeTableMenuItem) {
    // own codes context menu item clicked

    if (menuItem.data === 'esg') {
      this.showESGColumn =
        this.showESGColumn === ESGColumnStatus.hidden
          ? ESGColumnStatus.shown
          : ESGColumnStatus.hidden;
    }

    if (menuItem.data === 'owncodes') {
      const group: SaveOwnCodesTargetGroup = {
        title: 'Media',
        items: [],
        selectAll: false,
      };

      // should be able to be here only if at least 1 mediaSelection is selected
      const selectedVehicles = this.mediaSelection.filter(
        (media) => media.selected
      );
      const selectedVehiclesSurvey = selectedVehicles[0].vehicle.survey;

      // build the media into the first group
      group.items = this.mediaSelection
        .filter(
          (media) =>
            media.vehicle.survey.code === selectedVehiclesSurvey.code &&
            media.vehicle.survey.authGroup === selectedVehiclesSurvey.authGroup
        )
        .map((media) => {
          const mediaItem: DocumentMediaGroupItem = {
            id: media.id,
            title: media.title,
            calculationMethod: media.vehicle.calculationMethod,
            survey: media.vehicle.survey,
            mediaType: media.vehicle.mediaType,
            mediaTypeId: media.vehicle.mediaTypeId,
            dayparts: media.vehicle.dayparts || [],
            ESG: media.vehicle.ESG || [],
          } as DocumentMediaGroupItem;

          return {
            mediaItem,
            selected: media.selected,
          };
        });

      group.selectAll = !group.items.find((media) => !media.selected);

      // start dialog
      this.audienceGroupsService
        .saveOwnCodes(this.currentSurvey, [group], SaveOwnCodesType.media)
        .subscribe(() => {
          // refresh tree to include any new owncodes
          this.loadingVehicles = true;
          this.fetchPartialTree(null, true).subscribe(() => {
            this.mediaTree.refresh.emit({ newData: this.treeData });
          });
        });
    }
  }

  // convert MediaLevel (codebook) into TreeTableNode for the view, starting at the given TreeNode
  buildpartialTreeData(
    nodes: NavigationLevel[],
    parentNode: TreeTableNode
  ): TreeTableNode[] {
    if (this._treeData.length === 0)
      this._treeData.push({ name: 'root', children: [] });

    const parent: TreeTableNode = parentNode || this._treeData[0];
    parent.children = [];

    nodes.forEach((node) => {
      parent.children.push({
        name: node.vehicle ? node.vehicle.title : node.name,
        id: node.vehicle
          ? `${node.vehicle.id}^${this.mediaplannerService.plan.currentSurvey.code}^${this.mediaplannerService.plan.currentSurvey.authorizationGroup}`
          : '',
        data: { key: node.key, vehicle: node.vehicle },
        expandable: node.type === 'node',
        checkbox: ['leaf', 'dayparts'].includes(node.type),
      });

      if (node.children && node.children.length) {
        parent.children[parent.children.length - 1].children =
          this.buildpartialTreeData(
            node.children,
            parent.children[parent.children.length - 1]
          );
        parent.children[parent.children.length - 1].expandable = false;
      }
    });

    return parent.children;
  }

  applyDisabled() {
    const ids: string[] = this.mediaSelection.map((media) => media.id);

    this.mediaTree.applyDisabled(ids);
    const apply = (nodes: TreeTableNode[]) => {
      nodes.forEach((node) => {
        node.id ? (node.disabled = ids.includes(node.id as string)) : null;
        apply(node.children || []);
      });
    };

    apply(this.treeData);
  }

  // load audience groups and add to start of treedata
  loadAudienceGroups(
    treeData: TreeTableNode[],
    forceReload: boolean
  ): Observable<boolean> {
    return new Observable((ob) => {
      treeData = treeData || [];
      this.audienceGroupsService
        .getAudienceGroupsForTree(this.currentSurvey.code, forceReload)
        .subscribe((codes) => {
          if (codes) {
            this.loadingVehicles = false;
            codes.ownMedia.css = 'owncodes-label';

            // first level is User, Company, etc
            codes.ownMedia.children.forEach((child) =>
              child.children.forEach((group) => (group.checkbox = true))
            );

            // remove an old media node
            let index = treeData.findIndex(
              (node) => node.name === codes.ownMedia.name
            );
            if (index !== -1) treeData.splice(index, 1);

            // add new structure
            treeData.unshift(codes.ownMedia);
          }

          ob.next(true);
          ob.complete();
        });
    });
  }

  // get selected vehicles from the service and format as needed for UI
  loadSelectedFromPlan() {
    const planningTargets = this.mediaplannerService.plan.targets.filter(
      (target) => target.planningTarget
    );
    const targets = planningTargets.length
      ? planningTargets
      : this.mediaplannerService.plan.targets;

    // only work with targets that are set as planning targets (?).
    this.targets = targets.map((target) => ({
      title: target.title,
      population: target.population,
    }));
    const mediaSelection = [];
    let esg: ESGColumnStatus = ESGColumnStatus.unavailable;

    // get vehicles from target 1
    targets[0].vehicles.forEach((vehicle) => {
      const mediaVehicle: MediaVehicle = {
        title: vehicle.title,
        id: vehicle.id,
        mnemonic: vehicle.mnemonic,
        mediaType: vehicle.mediaType,
        mediaTypeId: vehicle.mediaTypeId,
        isMediaGroup: vehicle.isMediaGroup,
        calculationMethod: vehicle.calculationMethod,
        survey: vehicle.survey,
        dayparts: ConverterService.copyDayparts(vehicle.dayparts),
        addressable: vehicle.addressable,
        addressableConfig: vehicle.addressableConfig,
        ESG: vehicle.ESG,
        isMultiSurvey: vehicle.isMultiSurvey,
      };

      if (mediaVehicle.ESG?.length && esg === ESGColumnStatus.unavailable) {
        esg = ESGColumnStatus.shown;
      }

      mediaSelection.push({
        selected: false,
        id: vehicle.id,
        title: vehicle.title,
        addressableMessage: this.getAddressableMessage(vehicle),
        vehicle: mediaVehicle,
        targets: [].constructor(targets.length),
      });
    });

    this.showESGColumn = esg;

    // now get audiences from each of the planning targets into the selected media table component
    mediaSelection.forEach((media) => {
      targets.forEach((target, index) => {
        const veh = target.vehicles.find((veh) => veh.id === media.id);
        media.targets[index] = {
          audience: veh.audience,
          grossAudience: veh.grossAudience,
          resps: veh.resps,
          potentialReach: veh.potentialReach,
          compositionIndex: veh.compositionIndex,
          compositionPct: veh.compositionPct,
        };
      });
    });
    this.mediaSelection = mediaSelection;
    this.checkReady();
  }

  // save selected back to service.  Performed when user navigates away from this step
  saveSelectedToPlan(): boolean {
    let dirty = false;
    const DELETE = '_MARKED_FOR_DELETE_';
    const deleteList: string[] = [];

    this.mediaplannerService.plan.targets.forEach((target, index) => {
      // if the vehicle is in the target but not the media selection then delete from target
      // also if its in the media selection but marked as an error, also exclude it
      target.vehicles.forEach((targetVehicle, index, targetVehicleArray) => {
        if (
          !this.mediaSelection.find(
            (media) => media.id === targetVehicle.id && !media.error
          )
        ) {
          deleteList.push(targetVehicle.id);
          targetVehicle.id = DELETE;
          dirty = true;
        }
      });

      // only keep vehicles not marked for delete
      target.vehicles = target.vehicles.filter(
        (targetVehicle) => targetVehicle.id !== DELETE
      );

      // check the spotplans array for each of the schedules and delete the relevamnt spotplan (if any)
      // non broadcast vehicles are skipped over so no harm sending everything
      deleteList.forEach((vehicleId) => {
        this.mediaplannerService.plan.schedules.forEach((schedule) => {
          schedule.spotplans.removeVehicle(vehicleId);
        });
      });
    });

    this.mediaplannerService.plan.reconcileVehiclesWithSchedules();
    return dirty;
  }

  // copy the preselected list to the media slection table prior to calculating results
  savePreSelected() {
    // filter to nodes with an ID (actual vehicles only)
    this.preSelectedNodes = this.preSelectedNodes.filter((node) => node.id); // TreeTableNodes
    this.preSelectedNodes.forEach((node) => {
      // only add if not already there
      const media = this.mediaSelection.find((media) => media.id === node.id);
      if (!media) {
        node.data.vehicle.addressable =
          this.mediaplannerService.plan.surveyMetaData
            .meta(this.mediaplannerService.plan.currentSurvey.code)
            .isAddressable(node.data.vehicle.mediaTypeId);
        this.mediaSelection.push({
          id: node.id as string,
          selected: false,
          vehicle: node.data.vehicle, // possibly contains vehicle.dayparts
          title: node.name,
          addressableMessage: this.getAddressableMessage(node.data.vehicle),
          targets: [],
        });

        // if ESGs are available, then show the column by default
        if (
          node.data.vehicle.ESG?.length &&
          this.showESGColumn === ESGColumnStatus.unavailable
        ) {
          this.showESGColumn = ESGColumnStatus.shown;
        }

        // build zero target aud values
        for (let i = 0; i < this.mediaplannerService.plan.targets.length; i++) {
          this.mediaSelection[this.mediaSelection.length - 1].targets[i] = {
            audience: -1,
            grossAudience: -1,
            resps: -1,
            potentialReach: -1,
            compositionIndex: -1,
            compositionPct: -1,
          };
        }
      }
    });
  }

  private getAddressableMessage(vehicle: TargetVehicle): string {
    const instances = this.mediaplannerService.plan.targets[0].vehicles.filter(
      (veh) => veh.mnemonic === vehicle.mnemonic
    );
    const active = instances.filter((veh) => veh.addressableConfig?.targetId);
    return `Addressable audiences: ${active.length} | Instances: ${instances.length}`;
  }

  // create tree nodes after a search
  private createTreeData(statement: Statement): TreeTableNode {
    const parent: TreeTableNode = {
      name: statement.description,
      id: statement.id,
      data: {
        coding: statement.coding || '',
        parent: null,
        vehicle: statement.vehicle,
      },
      children: [],
    };

    if (statement.children) this.createTreeNode(parent, statement.children);
    return parent;
  }

  // create tree nodes after a search
  private createTreeNode(parent: TreeTableNode, children: Statement[]) {
    parent.children = [];
    children.forEach((child) => {
      parent.children.push({
        name: child.description,
        id: child.id,
        data: {
          coding: child.coding || '',
          parent: parent,
          vehicle: child.vehicle,
          key: `${parent.data.key ? parent.data.key + '|' : ''}${
            child.description
          }`,
        },
        checkbox: !!child.coding, // !(child.children && child.children.length),
        expandable: !child.coding && !(child.children && child.children.length), // no coding, but also no children (more to come)
        children: [],
      });

      if (child.children)
        this.createTreeNode(
          parent.children[parent.children.length - 1],
          child.children
        );
    });
  }

  private buildMediaTypeList() {
    const medTypes = [MEDIATYPE_ALL];
    if (this.mediaSelection.length) {
      this.mediaSelection.forEach((vehicleItem) => {
        if (!medTypes.find((med) => med === vehicleItem.vehicle.mediaType))
          medTypes.push(vehicleItem.vehicle.mediaType);
      });
    }

    this.mediaTypes = medTypes;
  }

  onMediaTypeChange(mediaType: string) {
    this.currentMediaType = mediaType;
  }

  resetSearch() {
    this.onSearchClick('');
    this.treeContainer.nativeElement.scrollTo(0, 0);
  }

  onCurrentSurveyChange() {
    this.loadingMedia = true;
    this.mediaplannerService
      .updateSurveyMetadata(this.currentSurvey)
      .subscribe(() => {
        this.loadData();
      });
  }
}
