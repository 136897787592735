export enum RowType {
  vehicle,
  scheduleTotal,
  mediatypeTotal,
  groupTotal,
  groupItem,
}

export interface RowContents {
  type: RowType;
  id: string;
}

export interface SurveyMediaTypeColumn {
  surveyCode: String;
  mediaType: MediaTypeColumn[];
}

export interface MediaTypeColumn {
  mediaType: string;
  columns: ColumnData[];
}

export interface ColumnData {
  columnDef: string;
  header: string;
  tooltip: string;
}

export interface MediaTypeColumnOrder {
  mediaType: string;
  order: string[];
}
