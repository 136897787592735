<div
  mat-dialog-title
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="8px"
>
  <h1>{{ data.dialogTitle }}</h1>

  <button
    mat-icon-button
    data-tracking="save-dialog-cancel-button"
    tupMatButton
    matTooltip="close"
    (click)="cancel()"
    class="close-btn"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <mat-divider class="divider-horizontal"></mat-divider>
  <div fxLayout="column">
    <form
      [formGroup]="formGroup"
      [ngClass]="showDrives ? 'row-layout-form' : 'column-layout-form'"
    >
      <mat-form-field
        class="input-mat-form-field"
        tupMatFormField
        appearance="outline"
      >
        <mat-label>{{ data.nameFormControlLabel }}</mat-label>
        <input
          #name
          type="text"
          required
          matInput
          formControlName="name"
        />
        <mat-icon
          *ngIf="formGroup.get('name').hasError('required')"
          matSuffix
          color="warn"
          class="error-icon"
          >cancel</mat-icon
        >
        <mat-error *ngIf="formGroup.get('name').hasError('required')">
          {{ data.nameFormControlLabel }} is required
        </mat-error>
      </mat-form-field>

      <mat-form-field
        class="textarea-form-field"
        tupMatFormField
        appearance="outline"
      >
        <mat-label>{{ data.descriptionFormControlLabel }}</mat-label>
        <textarea
          #description
          matInput
          formControlName="description"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="{{ showDrives ? 1 : 3 }}"
          cdkAutosizeMaxRows="{{ showDrives ? 1 : 5 }}"
        ></textarea>
      </mat-form-field>
    </form>

    <mat-divider class="divider-horizontal"></mat-divider>
    <!--*ngIf="showDrives"-->
    <div
      *ngIf="showDrives"
      fxLayout="row"
    >
      <div class="drives-container">
        <tup-user-container-selector
          #userContainerSelector
          [options]="{ showSubheader: true, showDivider: true }"
          (selectionChange)="selectionChange($event)"
        >
        </tup-user-container-selector>
      </div>
      <mat-divider
        class="divider-vertical"
        [vertical]="true"
      ></mat-divider>
      <div class="tag-editor-container">
        <tup-tag-editor
          [document]="data.document"
          #tagEditor
          [options]="{ showSubheader: true, showDivider: true }"
        >
          <ng-template tupDocumentStorageCardNoContent>
            <p
              class="mat-error"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <mat-icon fontSet="material-icons-outlined">warning</mat-icon>
              <span>This campaign has not been tagged</span>
            </p>
          </ng-template>
        </tup-tag-editor>
      </div>
    </div>
  </div>
</div>

<div
  mat-dialog-actions
  div
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <button
    mat-button
    data-tracking="save-dialog-cancel-button"
    tupMatButton
    appearance="large"
    (click)="cancel()"
  >
    {{ data.cancelButtonText }}
  </button>
  <button
    *ngIf="data.skipButtonText"
    data-tracking="save-dialog-skip-button"
    mat-flat-button
    color="primary"
    tupMatButton
    appearance="large"
    (click)="skip()"
  >
    {{ data.skipButtonText }}
  </button>
  <button
    #confirmButton
    data-tracking="save-dialog-save-button"
    mat-flat-button
    color="primary"
    tupMatButton
    appearance="large"
    (click)="save()"
    [disabled]="disabled"
  >
    {{ data.saveButtonText }}
  </button>
</div>
