<tup-mat-furniture
  (logoClick)="onLogoClick()"
  [sidebarOpenState]="false"
  (toggleSidePanel)="onToggleSidePanel($event)"
  [appVersion]="appVersion"
  [hasHiddenTopbar]="false"
  mode="over"
>
  <!-- top bar content-->
  <tup-mat-topbar-content class="hidden-top-bar"> </tup-mat-topbar-content>

  <!-- dropdown content TO DO -->
  <!-- <tup-mat-dropdown-content>
    <mat-select class="apps-dropdown">
      <div class="placeholder-container">
        <span>TELMAR PRODUCTS</span>
      </div>
      <mat-option
        *ngFor="let option of appsOptions"
        [value]="option.id"
        fxFlex="column"
        class="option-container"
        >
          <mat-icon>{{option.icon}}</mat-icon>
          <span>{{option.label}}</span>
        </mat-option
      >
    </mat-select>
  </tup-mat-dropdown-content> -->
  <!-- side bar content-->
  <tup-mat-sidebar-content>
    <mat-divider
      *ngIf="sidebarOpened"
      class="side-divider"
      [class.side-bar-opened]="sidebarOpened"
    ></mat-divider>
    <tup-side-nav
      [sidebarOpened]="sidebarOpened"
      (userAction)="onUserAction($event)"
      [dashboardRouteConfig]="dashboardRouteConfig"
      [docRoutesConfig]="docRoutesConfig"
    ></tup-side-nav>
  </tup-mat-sidebar-content>

  <tup-mat-sidebar-footer-content></tup-mat-sidebar-footer-content>

  <!-- main content area -->
  <div
    fxFill
    fxFlex="auto"
  >
    <ng-content></ng-content>
  </div>
</tup-mat-furniture>
