<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="tab-container"
>
  <div>
    <button
      class="toggle-button"
      appearance="large"
      [matTooltip]="viewMode === 'tables' ? 'Tables' : 'Charts'"
      tupMatButton
      mat-icon-button
      (click)="onToggle()"
    >
      <mat-icon color="primary">
        {{ viewMode === "tables" ? "table_chart" : "pie_chart" }}</mat-icon
      >
    </button>
  </div>

  <mat-tab-group
    [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="onSelectedIndexChange($event)"
  >
    <mat-tab
      *ngFor="let tab of tabs | viewModeFilter : viewMode; let index = index"
      [disabled]="tab.disabled"
    >
      <ng-template mat-tab-label>
        <span
          matRipple
          [attr.data-tracking]="'tab-stepper-tab-' + tab.label.toLowerCase()"
          [matRippleDisabled]="tab.disabled"
          *ngIf="tab.visible"
        >
          {{ tab.label }}
        </span>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
