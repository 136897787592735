import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';
import {
  CHART_TYPES,
  ChartFilter,
  ChartFilterOperator,
  ChartSettings,
  ChartSettingsMode,
  chartsInSingleRowForMultipleDatasets,
  chartsRequireShadedColorsForMultipleDatasets,
  chartsUnsuitableForMultipleDatasets,
  ChartTargetMode,
  COMBINED_SETTINGS_KEY,
  GraphSelectionValue,
  NO_AXIS_LABEL_CHART_TYPES,
  OPTIMAL_TOP_ROWS_COUNT,
  SECONDARY_CHART_TYPES,
  SelectMenuOptionChart,
  ChartTarget,
  ChartType,
} from 'src/app/models/charts.model';
import { ColorPickerService } from 'src/app/services/color-picker.service';
import { DocumentService } from 'src/app/services/document.service';
import { provideColorsToCharts } from 'src/app/utils/colorHelper';
import { ChartSettingsService } from '../../services/chart-settings.service';
import { cloneDeep } from 'lodash';
import { Sort, SortDirection } from '@angular/material/sort';
import { DataRow, TupChartsComponent } from '@telmar-global/tup-charts';
import { DialogService } from 'src/app/services/dialog.service';
import { ExporterService } from 'src/app/services/exporter.service';
import { getCrossMediaProps } from 'src/app/utils/exportUtils';
import { MediaPlannerService } from 'src/app/services/media-planner.service';

Chart.register(
  ...registerables,
  ChartDataLabels,
  TreemapController,
  TreemapElement,
  WordCloudController,
  WordElement
);

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy, OnChanges {
  public exportSelected = false;
  public note = false;
  public legendWidth: string | undefined = '30%';

  public finalData;
  public finalOptions = {
    scales: {
      x: { display: true, title: { display: true, text: 'SSOMMEETHHINGG' } },
    },
  };
  public originalChartData: any;
  public secondaryDataItemValues;
  public multipleDatasets: boolean;
  public tableData: DataRow[] = [];

  public footer: string;
  public surveySource: string;
  public copyright: string;

  public targetTitle = '';
  public unsuitableMultipleCharts = chartsUnsuitableForMultipleDatasets;
  public chartsInSingleRow = chartsInSingleRowForMultipleDatasets;
  public datasetsForUnsuitableCharts = [];

  public targets: ChartTarget[] = [];
  public id: string;
  private currentTarget: ChartTarget;

  //private targetColors: Record<string, string>;
  private seriesColors: Record<string, string>;

  @Input() chartData;
  @Input() chartSettings: ChartSettings;
  @Input() chartType: ChartType;
  @Output() colorChange = new EventEmitter<boolean>();
  @Output() fullWidthNeeded = new EventEmitter<boolean>();

  public localChartSettings: ChartSettings;

  public dataItemSelection: string;
  public secondaryDataItemSelection: string;
  public activeGraph: SelectMenuOptionChart<GraphSelectionValue>;
  public secondaryChartType: SelectMenuOptionChart<GraphSelectionValue>;

  public preSortedChartData;
  public sortActive = '';
  public sortDirection: SortDirection = '';

  get chartDataKeys(): Record<string, string> {
    return this.chartSettings.chartDataKeys;
  }

  chartDataLabel(key: string, spacer: string = ''): string {
    return this.chartSettings.chartDataKeys[key]
      ? spacer + this.chartSettings.chartDataKeys[key]
      : '';
  }

  // todo: temp approach to hide chart before updating it to cause tup-charts to rerender itself
  // todo: tup-charts needs changes to render itself instead of using this trick
  public refreshChart = false;
  public tempChartPlaceholderHeight = 0;
  @ViewChild('chartContainer') chartContainer: ElementRef;
  @ViewChildren(TupChartsComponent) charts: QueryList<TupChartsComponent>;

  constructor(
    private analyticsService: TupAnalyticsService,
    private colorPicker: ColorPickerService,
    private documentService: DocumentService,
    private chartSettingsService: ChartSettingsService,
    private dialogService: DialogService,
    private exporterService: ExporterService,
    private mediaplannerService: MediaPlannerService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setUpChart();
    this.refreshView();
  }

  private setUpChart(): void {
    this.originalChartData = this.chartData;
    //this.targetColors = this.chartSettingsService.getColumnColours();
    this.seriesColors = this.chartSettingsService.loadColumnColors(
      this.chartSettings.id
    );
    //    this.seriesColors = this.chartSettings.seriesColor;
    this.targets = this.chartData.targets;

    this.footer = this.chartSettings.footer || '';
    this.surveySource = this.chartSettings.surveySource || '';
    this.copyright = this.chartSettings.copyright || '';

    this.multipleDatasets = Array.isArray(this.chartData.targetsLongTitles);
    this.targetTitle = this.multipleDatasets
      ? COMBINED_SETTINGS_KEY
      : this.chartData.targetTitles;
    this.id = this.chartSettings.id;

    if (!this.multipleDatasets) {
      this.currentTarget = this.chartData.target;
    }
  }

  public onExportSingle(): void {
    const props = getCrossMediaProps(this);
    this.exporterService.exportToPPTX([props]);
  }

  public onChartSettings(): void {
    const prevColors = this.localChartSettings.seriesColor;
    const chartSettingsMode = this.multipleDatasets
      ? ChartSettingsMode.combined
      : ChartSettingsMode.single;
    const chartTargets = this.multipleDatasets
      ? this.targets
      : [this.currentTarget];

    this.dialogService
      .openChartSettings(
        chartSettingsMode === ChartSettingsMode.combined
          ? ChartTargetMode.combined
          : ChartTargetMode.single,
        chartSettingsMode,
        this.localChartSettings,
        chartTargets,
        this.chartType,
        this.localChartSettings.subTitle
      )
      .afterClosed()
      .subscribe((settings: ChartSettings | null) => {
        if (settings) {
          // check if the colors have been changed (apply to all charts on the parent component with an emit)
          const shouldUpdateChartFromParentView = false; //

          const colorsChanged = !Object.keys(settings.seriesColor).every(
            (key) => settings.seriesColor[key] === prevColors[key]
          );
          if (colorsChanged) {
            this.chartSettingsService.saveColumnColors(
              settings.seriesColor,
              settings.id
            );
          }

          this.chartSettingsService.saveChartSettings(
            settings,
            this.id,
            this.chartType === ChartType.DEFAULT
              ? this.mediaplannerService.plan.chartSettings
              : undefined
          );

          if (shouldUpdateChartFromParentView) {
            this.colorChange.emit(true);
          } else {
            this.refreshView(colorsChanged);
          }
        }
      });
  }

  public toggleNote(): void {
    if (this.localChartSettings.showDataTable) {
      this.localChartSettings.showDataTable = false;
      this.updateDataTableVisibility();
    }
    this.note = !this.note;
    this.notifyChartWidthChanges();
  }

  public openTable(): void {
    this.note = false;
    this.localChartSettings.showDataTable =
      !this.localChartSettings.showDataTable;
    this.updateDataTableVisibility();
    this.notifyChartWidthChanges();
  }

  public onSortChange(sort: Sort): void {
    this.tempChartPlaceholderHeight =
      this.chartContainer?.nativeElement.offsetHeight;
    this.refreshChart = true;

    this.sortActive = sort.active;
    this.sortDirection = sort.direction;
    const sortHeaderId = sort.active.split('_');
    this.sortChartData(
      sortHeaderId[0],
      sortHeaderId[1] ?? '',
      this.sortDirection
    );
    this.prepareDatasets();
    this.formatTableData();

    setTimeout(() => {
      this.refreshChart = false;
    }, 0);
  }

  private sortChartData(
    sortTargetId: string,
    sortDataItem: string,
    sortDirection: SortDirection
  ): void {
    if (sortDirection === '') {
      this.chartData = this.preSortedChartData;
      return;
    }

    const sortedDataset = this.getSortedDatasets(
      sortTargetId,
      sortDataItem,
      sortDirection
    );

    this.chartData = {
      ...this.chartData,
      labels: sortedDataset.map(
        (dataItem) => this.chartData.labels[dataItem.index]
      ),
    };
    const dataKeys = [...Object.keys(this.chartDataKeys), 'dataFlags'];
    if (this.multipleDatasets) {
      dataKeys.forEach((key: string) => {
        this.chartData = {
          ...this.chartData,
          [key]: this.chartData[key].map((dataset) =>
            sortedDataset.map((dataItem) => dataset[dataItem.index])
          ),
        };
      });
    } else {
      dataKeys.forEach((key: string) => {
        this.chartData = {
          ...this.chartData,
          [key]: sortedDataset.map(
            (dataItem) => this.chartData[key][dataItem.index]
          ),
        };
      });
    }
  }

  private getSortedDatasets(
    sortTargetId: string,
    sortDataItem: string,
    sortDirection: SortDirection
  ): { data: string | number; index: number }[] {
    let sortedDataset;
    if (sortTargetId === 'label') {
      const dataset = this.chartData.labels.map(
        (data: string, index: number) => ({ data, index })
      );

      sortedDataset =
        sortDirection === 'asc'
          ? dataset.sort((a, b) => {
              const l = a.data.toLowerCase();
              const m = b.data.toLowerCase();
              return l === m ? 0 : l > m ? 1 : -1;
            })
          : dataset.sort((a, b) => {
              const l = a.data.toLowerCase();
              const m = b.data.toLowerCase();
              return l === m ? 0 : l < m ? 1 : -1;
            });
    } else {
      const selectedData = this.multipleDatasets
        ? this.chartData[sortDataItem][this.getTargetIndex(sortTargetId)]
        : this.chartData[sortDataItem];

      const dataset = selectedData.map((data, index: number) => ({
        data,
        index,
      }));
      sortedDataset =
        sortDirection === 'asc'
          ? dataset.sort((a, b) => a.data - b.data)
          : dataset.sort((a, b) => b.data - a.data);
    }

    return sortedDataset;
  }

  private refreshView(reloadColors: boolean = false): void {
    if (reloadColors) {
      this.seriesColors = this.chartSettingsService.loadColumnColors(
        this.chartSettings.id
      );
    }

    this.tempChartPlaceholderHeight =
      this.chartContainer?.nativeElement.offsetHeight;
    this.refreshChart = true;

    this.formatChartSettingsAndDatasets();

    setTimeout(() => {
      this.refreshChart = false;
    }, 0);
  }

  private formatChartSettingsAndDatasets(): void {
    this.chartData = this.originalChartData;
    this.localChartSettings = this.composeChartSettings();
    this.updateLegendWidth();
    this.dataItemSelection = this.localChartSettings.primaryDataItem;
    this.secondaryDataItemSelection = this.localChartSettings.secondaryDataItem;
    this.activeGraph = this.findChartType(
      this.localChartSettings.primaryChartType
    );
    this.secondaryChartType = this.findChartType(
      this.localChartSettings.secondaryChartType
    );

    this.changeTopRows(this.localChartSettings.topRowsCount);
    this.changeNumberOfChartsInAxisLabel(
      this.localChartSettings.numberOfChartInAxisLabel
    );
    this.filterChartData();
    this.formatDataFlags();

    this.preSortedChartData = cloneDeep(this.chartData);
    this.sortActive =
      this.localChartSettings.sortColumn + '_' + this.dataItemSelection;
    this.sortDirection =
      this.localChartSettings.columnSortOrder === 'Ascending'
        ? 'asc'
        : this.localChartSettings.columnSortOrder === 'Descending'
        ? 'desc'
        : '';

    this.sortChartData(
      this.localChartSettings.sortColumn,
      this.dataItemSelection,
      this.sortDirection
    );

    this.prepareDatasets();
    this.formatTableData();
  }

  private findChartType(
    chart: GraphSelectionValue
  ): SelectMenuOptionChart<GraphSelectionValue> {
    const chartsToSearch =
      chart === 'None' ? SECONDARY_CHART_TYPES : CHART_TYPES;
    return chartsToSearch.find(
      (type: SelectMenuOptionChart<GraphSelectionValue>) => type.value === chart
    );
  }

  private composeChartSettings(): ChartSettings {
    const localChartSettings = this.chartSettingsService.findChartSettings(
      this.id
    );
    let primaryTargetId = this.multipleDatasets
      ? this.targets[0].id
      : this.currentTarget.id;
    const secondaryTargetId = this.multipleDatasets
      ? this.targets.length > 1
        ? this.targets[1].id
        : primaryTargetId
      : this.currentTarget.id;
    const maxRowsCount = this.getMaxRowCount();

    // first time around sortColumn might be 'Primary' or 'Secondary'  next time around it is the target id to sort by (?)
    const isTargetId = !Number.isNaN(parseInt(localChartSettings.sortColumn));
    if (isTargetId) primaryTargetId = localChartSettings.sortColumn;
    const sortColumn =
      localChartSettings.sortColumn === 'Secondary'
        ? secondaryTargetId
        : primaryTargetId;

    if (localChartSettings) {
      // targets may get updated, then previously count may be outdated, use the default one as fallback
      const updatedTopRowsCount =
        localChartSettings.topRowsCount <= maxRowsCount
          ? localChartSettings.topRowsCount
          : maxRowsCount;
      return {
        ...localChartSettings,
        topRowsCount: updatedTopRowsCount,
        maxRowsCount,
        sortColumn,
      };
    }

    const globalChartSettings = cloneDeep(this.chartSettings);
    const actualTopRowsCount = globalChartSettings.topRowsCount
      ? globalChartSettings.topRowsCount <= maxRowsCount
        ? globalChartSettings.topRowsCount
        : maxRowsCount
      : this.getStartUpRowCount();
    return {
      ...globalChartSettings,
      id: this.id,
      topRowsCount: actualTopRowsCount,
      maxRowsCount,
      sortColumn:
        globalChartSettings.sortColumn === 'Secondary'
          ? secondaryTargetId
          : primaryTargetId,
      filters: [
        {
          ...globalChartSettings.filters[0],
          target: primaryTargetId,
        },
        {
          ...globalChartSettings.filters[1],
          target: secondaryTargetId,
        },
      ],
    };
  }

  // chartData.resps holds an array of values to be used to mark an asterisk against
  // dataFlags array of booleans (or undefined) will be added to the chartData object
  private formatDataFlags(): void {
    const respData = this.chartData.resps;
    const dataFlags = this.multipleDatasets
      ? respData.map((dataset, index: number) => this.getDataFlag(dataset))
      : this.getDataFlag(respData);

    this.chartData = {
      ...this.chartData,
      dataFlags,
    };
  }

  private prepareDatasets(): void {
    this.finalData = this.preparePrimaryDatasets();
    if (this.localChartSettings.primaryChartType === 'tupScatter') {
      this.secondaryDataItemValues = this.multipleDatasets
        ? this.chartData[this.chartDataKeys[this.secondaryDataItemSelection]]
        : [this.chartData[this.chartDataKeys[this.secondaryDataItemSelection]]];
    } else if (
      !this.unsuitableMultipleCharts.includes(
        this.localChartSettings.secondaryChartType
      ) &&
      this.secondaryDataItemSelection !== 'None'
    ) {
      this.secondaryDataItemValues = this.prepareSecondaryDatasets();
    } else {
      this.secondaryDataItemValues = null;
    }
  }

  private preparePrimaryDatasets() {
    this.datasetsForUnsuitableCharts = [];
    const dataSelection = this.chartDataKeys[this.dataItemSelection];
    const data = this.chartData[this.dataItemSelection]; //dataSelection
    const dataFlags = this.chartData.dataFlags;
    let labels = this.chartData.labels;
    const datasets = [];

    if (this.multipleDatasets) {
      const shouldCreateShadedColors =
        chartsRequireShadedColorsForMultipleDatasets.includes(
          this.localChartSettings.primaryChartType
        );

      data.forEach((dataset, index: number) => {
        const currentTarget = this.targets[index];

        if (currentTarget.visible) {
          const currentColor = this.findTargetColor(currentTarget.id);
          const targetColorLength = shouldCreateShadedColors
            ? dataset.length
            : 1;
          datasets.push({
            label: this.chartData.targetTitles[index],
            data: dataset,
            dataFlag: dataFlags[index],
            backgroundColor: provideColorsToCharts(
              currentColor,
              targetColorLength
            ),
            borderColor: provideColorsToCharts(currentColor, targetColorLength),
          });
        }
      });

      if (this.activeGraph.showAllDatasetLabels) {
        labels = [].concat(...Array(data.length).fill(labels));
      }
    } else {
      const currentColor = this.findTargetColor(this.currentTarget.id);
      datasets.push({
        label: this.chartData.targetTitles,
        data,
        dataFlag: dataFlags,
        backgroundColor: provideColorsToCharts(currentColor, data.length),
        borderColor: provideColorsToCharts(currentColor, data.length),
      });
    }

    datasets.forEach((dataset) => {
      const clonedDataset = cloneDeep(dataset);
      if (clonedDataset.backgroundColor.length > 0) {
        clonedDataset.backgroundColor = provideColorsToCharts(
          clonedDataset.backgroundColor[0],
          dataset.data.length
        );
        clonedDataset.borderColor = provideColorsToCharts(
          clonedDataset.borderColor[0],
          dataset.data.length
        );
      }
      this.datasetsForUnsuitableCharts.push({
        labels,
        datasets: [clonedDataset],
      });
    });

    return { labels, datasets };
  }

  private prepareSecondaryDatasets() {
    //const data =   this.chartData[ this.chartDataKeys[this.secondaryDataItemSelection]];
    const data = this.chartData[this.secondaryDataItemSelection];
    const dataFlags = this.chartData.dataFlags;
    const datasets = [];
    const labels = this.chartData.labels;

    if (this.multipleDatasets) {
      data.forEach((dataset, index: number) => {
        datasets.push({
          label: this.chartData.targetTitles[index],
          data: dataset,
          dataFlag: dataFlags[index],
        });
      });
    } else {
      datasets.push({
        label: this.chartData.targetTitles,
        data,
        dataFlag: dataFlags,
      });
    }
    return {
      labels,
      datasets,
    };
  }

  private findTargetColor(targetId: string): string {
    return this.seriesColors[targetId]; // || this.targetColors[targetId];

    //    return targetId in this.seriesColors
    //      ? this.seriesColors[targetId]
    //      : this.targetColors[targetId];
  }

  private getStartUpRowCount(): number {
    const labelsLength = this.originalChartData
      ? this.originalChartData.labels.length
      : this.chartData.labels.length;
    const targetsLength = this.originalChartData
      ? Array.isArray(this.originalChartData.targetTitles)
        ? this.originalChartData.targetTitles.length
        : 1
      : Array.isArray(this.chartData.targetTitles)
      ? this.chartData.targetTitles.length
      : 1;

    const maxLabels = Math.floor(OPTIMAL_TOP_ROWS_COUNT / targetsLength);
    return maxLabels > labelsLength
      ? labelsLength === 0
        ? 1
        : labelsLength
      : maxLabels === 0
      ? 1
      : maxLabels;
  }

  private getMaxRowCount(): number {
    return this.originalChartData.labels.length;

    let maxRowCount;
    if (this.multipleDatasets) {
      maxRowCount = this.originalChartData.labels.length;
    } else {
      if (!Array.isArray(this.chartData.row[0])) {
        maxRowCount = this.originalChartData.row.length;
      } else {
        maxRowCount = this.chartData.row[0].length;
      }
    }

    return maxRowCount;
  }

  // reduce all the data arrays down to the passed in ropRowsCount
  private changeTopRows(topRowsCount: number): void {
    if (this.multipleDatasets) {
      this.chartData = {
        ...this.chartData,
        labels: this.originalChartData.labels.slice(0, topRowsCount),
      };
      Object.keys(this.chartDataKeys).forEach((key: string) => {
        this.chartData = {
          ...this.chartData,
          [key]: this.originalChartData[key].map((data) =>
            data.slice(0, topRowsCount)
          ),
        };
      });
    } else {
      Object.keys(this.chartData).forEach((key: string) => {
        if (!Array.isArray(this.originalChartData[key])) {
          return;
        }
        this.chartData = {
          ...this.chartData,
          [key]: this.originalChartData[key].slice(0, topRowsCount),
        };
      });
    }
  }

  private changeNumberOfChartsInAxisLabel(numberOfChars: number): void {
    if (
      NO_AXIS_LABEL_CHART_TYPES.includes(
        this.localChartSettings.primaryChartType
      )
    )
      return;

    this.chartData.labels = this.chartData.labels.map((label: string) =>
      label.length > numberOfChars
        ? label.slice(0, numberOfChars).concat('...')
        : label
    );
  }

  private getDataFlag(respData: number[]): boolean[] | undefined[] {
    return this.localChartSettings.flagRowResps
      ? respData.map(
          (resp) => resp <= this.localChartSettings.flagRowRespsValue
        )
      : respData.map(() => undefined);
  }

  private filterChartData(): void {
    const validFilters = []; // this.getValidFilters();
    if (!validFilters.length) return;

    const indices = this.findFilteredDataIndices(
      validFilters,
      this.multipleDatasets
    );
    this.chartData = {
      ...this.chartData,
      labels: indices.map((index) => this.originalChartData.labels[index]),
    };

    if (this.multipleDatasets) {
      Object.keys(this.chartDataKeys).forEach((key: string) => {
        this.chartData = {
          ...this.chartData,
          [key]: this.originalChartData[key].map((dataset) =>
            indices.map((index) => dataset[index])
          ),
        };
      });
    } else {
      Object.keys(this.chartDataKeys).forEach((key: string) => {
        this.chartData = {
          ...this.chartData,
          [key]: indices.map((index) => this.originalChartData[key][index]),
        };
      });
    }
  }

  private findFilteredDataIndices(
    filters: ChartFilter[],
    hasMultipleDatasets: boolean
  ): number[] {
    return filters
      .map((filter: ChartFilter) => {
        let targetData;
        if (hasMultipleDatasets) {
          targetData =
            this.chartData[this.chartDataKeys[filter.dataItem]][
              this.getTargetIndex(filter.target)
            ];
        } else {
          targetData = this.chartData[this.chartDataKeys[filter.dataItem]];
        }

        return targetData.reduce(
          (prev: number[], data: number, index: number) => {
            return this.isFilterCriteriaMatched(
              data,
              filter.operator,
              filter.value
            )
              ? [...prev, index]
              : prev;
          },
          []
        );
      })
      .reduce((prev: number[], current: number[], index: number) => {
        return index > 0
          ? [...prev.filter((i) => current.includes(i))]
          : [...current];
      }, []);
  }

  private getValidFilters(): ChartFilter[] {
    if (!this.localChartSettings.filters) return [];
    return this.localChartSettings.filters.filter((filter: ChartFilter) => {
      return (
        filter.dataItem !== 'None' &&
        filter.operator !== 'None' &&
        filter.target !== 'None'
      );
    });
  }

  private isFilterCriteriaMatched(
    value: number,
    filterOperator: ChartFilterOperator,
    filterValues: number[]
  ): boolean {
    switch (filterOperator) {
      case ChartFilterOperator.equal:
        return value === filterValues[0];
      case ChartFilterOperator.notEqual:
        return value !== filterValues[0];
      case ChartFilterOperator.greaterThanOrEqual:
        return value >= filterValues[0];
      case ChartFilterOperator.greaterThan:
        return value > filterValues[0];
      case ChartFilterOperator.lessThanOrEqual:
        return value <= filterValues[0];
      case ChartFilterOperator.lessThan:
        return value < filterValues[0];
      case ChartFilterOperator.between:
        const max = Math.max(filterValues[0], filterValues[1]);
        const min = Math.min(filterValues[0], filterValues[1]);
        return value > min && value < max;
    }
    return false;
  }

  private formatTableData(): void {
    const selectedTargets = this.targets.filter((target) => target.visible);
    const targets = this.multipleDatasets
      ? selectedTargets
      : [this.currentTarget];

    const tableDataColumns = this.formatTableDataColumns();
    const decimalPlaces = this.localChartSettings.decimalPlaces;

    if (this.localChartSettings.showDataTable) {
      this.tableData = this.chartData.labels.map(
        (label: string, labelIndex: number) => {
          const rowData = tableDataColumns.map((dataItem: string) =>
            targets.map((target: ChartTarget) => {
              const value = this.multipleDatasets
                ? this.chartData[dataItem][target.id][labelIndex].toFixed(
                    decimalPlaces
                  )
                : this.chartData[dataItem][labelIndex].toFixed(decimalPlaces);

              return {
                columnHeader: target.title,
                columnHeaderId: target.id,
                value,
              };
            })
          );

          return {
            data: rowData.reduce(
              (previousValue, currentValue) => [
                ...previousValue,
                ...currentValue,
              ],
              [
                {
                  columnHeader: 'Plan Item',
                  columnHeaderId: 'label',
                  value: label,
                },
                {
                  ...(this.chartData.surveysVehicle &&
                  this.chartData.surveysVehicle.length > 0
                    ? {
                        columnHeader: 'Survey',
                        columnHeaderId: 'surveyCode',
                        tooltip:
                          this.chartData.surveysVehicle[labelIndex].title,
                        value: this.chartData.surveysVehicle[labelIndex].code,
                      }
                    : undefined),
                },
              ]
            ),
          };
        }
      );
    } else {
      this.tableData = [];
    }
  }

  private formatTableDataColumns(): string[] {
    const tableDataColumns = [this.localChartSettings.primaryDataItem];
    if (this.secondaryDataItemValues) {
      tableDataColumns.push(this.localChartSettings.secondaryDataItem);
    }

    return tableDataColumns;
  }

  private getTargetIndex(targetId: string): number {
    return this.targets.findIndex(
      (target: ChartTarget) => target.id === targetId
    );
  }

  private notifyChartWidthChanges(): void {
    const shouldHaveFullWidth =
      this.note || this.localChartSettings.showDataTable;
    this.fullWidthNeeded.emit(shouldHaveFullWidth);
    this.refreshView();
  }

  private updateLegendWidth(): void {
    this.legendWidth =
      this.localChartSettings.hideChartLegend ||
      (this.localChartSettings.showDataTable && !this.note)
        ? undefined
        : this.note
        ? '25%'
        : '30%';
  }

  private updateDataTableVisibility(): void {
    this.formatTableData();
    this.chartSettingsService.saveChartSettings(
      this.localChartSettings,
      this.id,
      this.chartType === ChartType.DEFAULT
        ? this.mediaplannerService.plan.chartSettings
        : undefined
    );
  }

  ngOnDestroy(): void {}
}
