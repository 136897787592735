import { TargetDefinitionRequest } from './engine.media-evaluation.models';

export enum OptimiseMetrics {
  grps = 'GRP',
  impressions = 'Impressions',
  reach = 'Reach',
  reachThenFrequency = 'ReachThenFrequency',
  avgFrequency = 'Frequency',
  budget = 'Budget',
}

export const SPLIT_ID = 'MT';

export interface OptimiseVehicleSplitValues {
  splitId: string;
  value: string;
}

export interface OptimiseVehicle {
  id: string;
  mnemonic: string;
  splitValues?: OptimiseVehicleSplitValues[];
  costPerThousand?: number;
  numberOfInserts?: number;
  duration?: number;
  buyingTargetId?: number;
  minInserts?: number;
  maxInserts?: number;
}
export interface OptimiseGoal {
  GRP?: number;
  Impressions?: number;
  Reach?: number;
  Frequency?: number;
  Budget?: number;
}

export interface SplitPercentages {
  value: string;
  percentage: number;
}

export interface OptimiseRequestConstraints {
  splitId: string;
  splitMode: string;
  splitPercentages: SplitPercentages[];
}

export interface OptimiseRequestSplitsValues {
  value: string;
  name: string;
}

export interface OptimiseRequestSplits {
  id: string;
  name: string;
  values: OptimiseRequestSplitsValues[];
}

export interface OptimiseRequest {
  surveyCode: string;
  targetDefinitions: TargetDefinitionRequest[];
  buyingTargetDefinitions?: TargetDefinitionRequest[];
  targetUniverse: number;
  weightSet: number;
  authorizationGroup: string;
  vehicles: OptimiseVehicle[];
  useIntegerInsertions: boolean;
  goal: OptimiseGoal;
  splits?: OptimiseRequestSplits[];
  constraints?: OptimiseRequestConstraints[];
  engineMode: string;
}

export interface OptimeseResponseResults {
  id: string;
  numberOfInserts: number;
  duration: number;
  impressions?: number;
}
export interface OptimiseResponse {
  duration: number;
  results: OptimeseResponseResults[];
  success: boolean;
}

export const CONNECTION_CLOSED = 'CONNECTION_CLOSED';
export const CONNECTED = 'CONNECTED';
export const OPTIMISATION_FINISHED = 'optimisation finished successfully';
export const OPTIMISATION_STARTED = 'optimisation started';
// export const OPTIMISATION_NOT_ACHIEVED = 'Warning: goal could not be achieved';
