import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CoreDemoSet,
  CoreDemoSetsRequest,
  CoreDemoSetsResponse,
} from '../models/codebook.core-demo-sets.models';
import {
  CodebookResponse,
  NavigationLevel,
  Statement,
  SurveyMetaDataResponse,
  MediaVehicle,
  MediaTypeDataResponse,
} from '../models/codebook.models';
import { DocumentSurvey, DocumentFullSurvey } from '../models/document.model';
import { ApiService } from './api.service';
import { PlanningColumnsService } from './planning-columns.service';
import { CodebookCacheService } from './codebook-cache.service';
import { CacheType } from './codebook-cache.service';
import { mergeMap } from 'rxjs/operators';

export enum SearchFor {
  Vehicles = 'vehicle',
  Codebook = 'codebook',
  VehiclesAndCodebook = 'both',
}

export enum SearchIn {
  Titles = 'title',
  Codes = 'code',
}

export enum MatchType {
  SearchExactKeyword = 'word_phrase',
  SearchAnyKeyword = 'any',
  SearchAllKeyword = 'all',
  SearchPhrase = 'phrase_match',
  SearchStartingKeyword = 'starting',
}

@Injectable({
  providedIn: 'root',
})
export class CodebookService {
  // a cache of results from previous calls
  clearedSurveys: DocumentSurvey[];
  //categories: CodebookCategory[];

  // codebook: Statement[];
  mediaTypeDisplay: {};

  constructor(
    private apiService: ApiService,
    private planningColumnsService: PlanningColumnsService,
    private codebookCacheService: CodebookCacheService
  ) {}

  getSurveys(matchText?: string): Observable<DocumentSurvey[]> {
    return matchText === undefined && this.clearedSurveys
      ? of(this.clearedSurveys)
      : new Observable((observable) => {
          const options = {
            hasVehicles: true,
            ...(matchText && { matchText }),
          };

          this.apiService
            .request(
              'POST',
              environment.api.codebook.url,
              environment.api.codebook.endPoint.getSurveys,
              { body: options }
            )
            .subscribe((data) => {
              const surveyResults: DocumentSurvey[] = [];
              if (data?.success) {
                if (data.surveyInstances) {
                  data.surveyInstances.forEach((surv) => {
                    // get the main important stuff
                    const survey: DocumentSurvey = {
                      code: surv['survey-instance'],
                      title: surv['survey-name'],
                      authorizationGroup: '',
                      authorizationGroups: surv['authorization-groups'] || [],
                      esgProviders: surv['esg-providers'] || [],
                      population: 0,
                      sample: 0,
                      units: 0,
                      unitsText: '',
                    };

                    surv['media-type-display']
                      ? this.planningColumnsService.parseColumns(
                          survey.code,
                          surv['media-type-display']
                        )
                      : null;

                    surveyResults.push(survey);
                  });
                }
              }

              this.clearedSurveys = surveyResults;
              observable.next(surveyResults);
              observable.complete();
            });
        });
  }

  /**
   * Get all info including metadata available for the given survey
   *
   * @param {string} surveyCode  Survey code
   * @returns {DocumentSurvey} survey or null if cal failed
   */
  getSurvey(
    surveyCode: string,
    authorizationGroup: string
  ): Observable<DocumentFullSurvey> {
    return new Observable((observable) => {
      const options = {
        surveyVersion: surveyCode,
      };

      let survey: DocumentFullSurvey = null;

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.getSurvey,
          { body: options }
        )
        .subscribe(
          (surveyData) => {
            if (surveyData?.survey) {
              const surv = surveyData.survey;
              // get the main important stuff
              survey = {
                code: surv['survey-instance'],
                title: surv['survey-name'] || surv['survey-instance'],
                authorizationGroup,
                hasVehicles: surv['has-vehicles'],
                language: surv['survey-language'],
                provider: surv['survey-provider'],
                surveyInfo: this.sanitise(surv['survey-info'] || ''),
                copyrightInfo: surv['copyright-info'] || '',
                year: surv['study-release-date']
                  ? parseInt(surv['study-release-date'].substring(0, 4))
                  : 0,
                esgProviders: surv['esg-providers'] || [],
                population: 0,
                sample: 0,
                units: 0,
                unitsText: '',
                isMultibased: surv['is-multibased'],
                isTrendable: false,
                isMb3Enabled: surv['is-mb3-enabled'],
                isMappable: surv['is-mappable'],
                isMrfGlobaldemomap: surv['is-mrf-globaldemomap'],
                isMrfProfile: surv['is-mrf-profile'],
                isPrimary: false,
                isCurrent: false,
                mediaTypes: this.sanitise(surv['media-types'] || ''),
              };

              surv['media-type-display']
                ? this.planningColumnsService.parseColumns(
                    survey.code,
                    surv['media-type-display']
                  )
                : null;
            }

            observable.next(survey);
            observable.complete();
          },
          (error: string) => {
            observable.next(null);
            observable.complete();
          }
        );
    });
  }

  // replace invalid chars received in response payloads from backend
  private sanitise(jsonString: string): string {
    return jsonString.replace(/‘|’/g, "'");
  }

  getSurveyMetadata(surveyCode: string): Observable<SurveyMetaDataResponse> {
    return new Observable((ob) => {
      const options = {
        surveyVersion: surveyCode,
      };

      this.apiService
        .request(
          'POST',
          environment.api.surveyInfo.url,
          environment.api.surveyInfo.endPoint.getSurveyMetadata,
          { body: options }
        )
        .subscribe((data: SurveyMetaDataResponse) => {
          if (data.success) {
            data.surveyCode = surveyCode;
          }

          ob.next(data);
          ob.complete();
        });
    });
  }

  getMediatypesId(surveyCode: string): Observable<MediaTypeDataResponse> {
    return new Observable((observable) => {
      const options = {
        surveyVersion: surveyCode,
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.getMediaTypesId,
          { body: options }
        )
        .subscribe((data: MediaTypeDataResponse) => {
          if (data.success) {
            data.surveyCode = surveyCode;
          }

          observable.next(data);
          observable.complete();
        });
    });
  }

  // codebook tree navigation expanding one level at a time
  getCodebookNavigation(
    survey: DocumentSurvey,
    key: string
  ): Observable<NavigationLevel[]> {
    return this.treeNavigation(
      environment.api.codebook.url,
      environment.api.codebook.endPoint.codebookNavigation,
      survey,
      key,
      CacheType.targets
    );
  }

  // vehicle tree navigation expanding one level at a time
  getVehicleNavigation(
    survey: DocumentSurvey,
    key: string
  ): Observable<NavigationLevel[]> {
    return this.treeNavigation(
      environment.api.codebook.url,
      environment.api.codebook.endPoint.vehicleNavigation,
      survey,
      key,
      CacheType.media
    );
  }

  /**
   * Internal function for loading branches of the vehicle / codebook tree and creating a NavigationLevel array
   *
   * @param url root Url to call for the data
   * @param path added to the Url for the complete endpoint
   * @param surveyCode Survey code to query
   * @param key key represents the parent node to start loading from, e.g  'Demographics|Age'
   * @param navigationTree The cache storage array where the final cache hierarchy will be loaded from/ stored to for cache
   * @returns Observable of type NavigationLevel[]
   */
  private treeNavigation(
    url: string,
    path: string,
    survey: DocumentSurvey,
    key: string,
    cacheType: CacheType
  ): Observable<NavigationLevel[]> {
    const level: NavigationLevel[] = this.codebookCacheService.get(
      key,
      cacheType,
      survey.code,
      survey.authorizationGroup
    );

    return level && level.length
      ? new Observable((observable) => {
          setTimeout(() => {
            observable.next(level);
            observable.complete();
          });
        })
      : new Observable((observable) => {
          const options = {
            authorizationGroup: survey.authorizationGroup,
            surveyVersion: survey.code,
            category: key,
            sortField: 'pos',
            sortOrder: 'asc',
            categoryFilter: false,
          };
          const navigationLevel: NavigationLevel[] = [];

          this.apiService
            .request('POST', url, path, { body: options })
            .subscribe(
              (data) => {
                if (data.success) {
                  data.children.sort((a, b) => a.min_pos - b.min_pos);
                  data.children.forEach((node) => {
                    const navLevel: NavigationLevel = {
                      name: node.key.split('|').pop(),
                      id: node['data-reference'] || '',
                      jsonCoding: node['data-reference-json'] || null,
                      key: node.key,
                      category: node['category'] || '',
                      type: node.type,
                      children: [],
                    };

                    // has vehicle data
                    if (node.vehicle) {
                      navLevel.vehicle = this.buildMediaVehicle(
                        node,
                        navLevel.name
                      );
                      navLevel.name = navLevel.vehicle.title;
                    }
                    navigationLevel.push(navLevel);
                  });
                }

                // insert into the cache with the right parent to maintain the full structure
                this.codebookCacheService.put(
                  key,
                  cacheType,
                  navigationLevel,
                  survey.code,
                  survey.authorizationGroup
                );

                // return our new level
                observable.next(navigationLevel);
                observable.complete();
              },
              // anything not 200
              (error) => {
                console.log('error was caught:', error);
                observable.next(navigationLevel);
                observable.complete();
              }
            );
        });
  }

  /**
   * Search function for retrive batches of data as long as there is more data to be fetched
   *
   * @param searchText String to search for
   * @param category Where to start the search from, e.g 'Demographics'
   * @param surveyCode survey to perform the search in
   * @param matchType how to match results: 'prefix', 'phrase_prefix', 'most_fields', 'phrase_match'
   * @param codebookOrVehicle A toggle to indicate returning 'codebook' items, 'vehicle' items or 'both' for everything
   * @param matchOperator If phrase_prefix is used, then words outside of quotes will be joined, e.g 'OR', 'AND'
   * @param attribute Further attributes to filter by. Key/value pair object: e.g   {"Brand": "Pennzoil"}
   * @param resultSize Maximum number of results to return
   * @returns Observable of type <CodebookResponse>
   **/
  search(
    searchText: string,
    filterCategory: string[] | string,
    survey: DocumentSurvey,
    matchType: MatchType = MatchType.SearchAnyKeyword,
    searchIn: SearchIn = SearchIn.Titles,
    searchFor: SearchFor = SearchFor.Codebook,
    matchOperator: string = 'OR',
    filterAttributes: any = null,
    resultSize: number = 500
  ): Observable<CodebookResponse> {
    return this.searchOneBatch(
      searchText,
      filterCategory,
      survey,
      matchType,
      searchIn,
      searchFor,
      matchOperator,
      filterAttributes,
      resultSize,
      null
    ).pipe(
      // Initial call for the first batch of data with scrollId = null
      mergeMap((firstBatchResult) => {
        if (!firstBatchResult.tree.status.scrollId) {
          return of(firstBatchResult.tree);
        }

        if (firstBatchResult.data?.error) {
          return of({
            ...firstBatchResult.tree,
            error: firstBatchResult.data.error,
          });
        }

        let hasMoreResults = true;
        let concatenatedResponse = [...firstBatchResult.data.searchResults];

        const fetchNextBatch = (
          scrollId: string | null
        ): Observable<CodebookResponse> => {
          if (hasMoreResults && scrollId !== null) {
            return this.searchOneBatch(
              searchText,
              filterCategory,
              survey,
              matchType,
              searchIn,
              searchFor,
              matchOperator,
              filterAttributes,
              resultSize,
              scrollId
            ).pipe(
              mergeMap((searchResult) => {
                if (!searchResult.tree.status.scrollId) {
                  hasMoreResults = false;
                }
                concatenatedResponse = [
                  ...concatenatedResponse,
                  ...searchResult.data.searchResults,
                ];
                return fetchNextBatch(searchResult.tree.status.scrollId);
              })
            );
          } else {
            const tree =
              searchFor === SearchFor.Codebook
                ? this.processCodebook({
                    ...firstBatchResult.data,
                    searchResults: concatenatedResponse,
                  })
                : this.processMediaCodebook({
                    ...firstBatchResult.data,
                    searchResults: concatenatedResponse,
                  });

            return of(tree);
          }
        };

        // Fetch next batch of data with scrollId
        return fetchNextBatch(firstBatchResult.tree.status.scrollId);
      })
    );
  }

  /**
   * Main search funtion for handling codebook and vehicle tree searches
   *
   * @param searchText String to search for
   * @param category Where to start the search from, e.g 'Demographics'
   * @param surveyCode survey to perform the search in
   * @param matchType how to match results: 'prefix', 'phrase_prefix', 'most_fields', 'phrase_match'
   * @param codebookOrVehicle A toggle to indicate returning 'codebook' items, 'vehicle' items or 'both' for everything
   * @param matchOperator If phrase_prefix is used, then words outside of quotes will be joined, e.g 'OR', 'AND'
   * @param attribute Further attributes to filter by. Key/value pair object: e.g   {"Brand": "Pennzoil"}
   * @param resultSize Maximum number of results to return
   * @param scrollId Id that exists when there is more data that needs to be fetched
   * @returns Observable of type <CodebookResponse>
   */
  searchOneBatch(
    searchText: string,
    filterCategory: string[] | string,
    survey: DocumentSurvey,
    matchType: MatchType,
    searchIn: SearchIn,
    searchFor: SearchFor,
    matchOperator: string,
    filterAttributes: any,
    resultSize: number,
    scrollId: string = null
  ): Observable<{ tree: CodebookResponse; data: any }> {
    // preserve quotes and add operators inbetween words
    const phraseMatchEncode = (
      searchText: string,
      operator: string
    ): string => {
      const pattern = /[^\s"]+|"([^"]*)"/gi;
      const list: string[] = [];
      let match: RegExpExecArray;
      do {
        match = pattern.exec(searchText);
        match ? list.push(match[0]) : null;
      } while (match);
      return list.join(` ${operator.trim().toUpperCase()} `);
    };

    // check to see if we already have this.
    return new Observable((observable) => {
      const options = {
        authorizationGroup: survey.authorizationGroup,
        surveyVersion: survey.code,
        topLevelFilterCategory: filterCategory,
        matchText:
          matchType === MatchType.SearchPhrase
            ? phraseMatchEncode(searchText, matchOperator)
            : searchText,
        matchType,
        searchIn,
        codebookOrVehicle: searchFor,
        filterAttributes,
        filterDatatype: searchFor === SearchFor.Codebook ? 'binary' : null,
        resultSize: resultSize,
        includeNodeMatch: false,
        returnNodeData: true,
        disableCatAggs: false,
        sortField: 'pos',
        sortOrder: 'asc',
        scrollId,
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.search,
          { body: options }
        )
        .subscribe((data) => {
          if (data.searchResults) {
            const tree =
              searchFor === SearchFor.Codebook
                ? this.processCodebook(data)
                : this.processMediaCodebook(data);
            observable.next({ tree, data });
            observable.complete();
          } else {
            observable.next(null);
            observable.complete();
          }
        });
    });
  }

  clearCache(andClearedSurveys: boolean = true) {
    this.codebookCacheService.clear();
    andClearedSurveys ? (this.clearedSurveys = null) : {};
  }

  // build a tree and format the data from the search call
  private processCodebook(data: any): CodebookResponse {
    let codebook: CodebookResponse = {
      status: {
        success: data.success,
        hits: data.hits,
        scrollId: data.scrollId,
        took: data.took,
      },
      fullText: [], // top level search results presented flat (on chips)
      coding: [],
      tree: { description: 'root', id: '', children: [] }, // used in the expanded search panel
      useTopLevelCategories: data.useTopLevelCategories,
    };

    // too many search results, so parse the topLevelCategories and return those instead
    // iterate through main search results as normal.
    if (data.searchResults) {
      data.searchResults.forEach((element) => {
        let category = element.category || element.key;

        codebook.fullText.push(category);
        codebook.coding.push(element['data-reference']);

        // parse the fulltext
        const levels = category.split('|');
        let children = codebook.tree.children;

        levels.forEach((level, index) => {
          let child = children.find((node) => node.description === level);
          const statement: Statement = {
            description: level,
            key: category,
            type: element['type'] || '',
            coding:
              index === levels.length - 1 ? element['data-reference'] : '',
            jsonCoding:
              index === levels.length - 1
                ? element['data-reference-json']
                : null,
            id:
              index == element['data-reference'] || levels.length - 1
                ? element['pos']
                : 0,
            expandable:
              index === levels.length - 1 && !element['data-reference'],
            children: [],
          };

          // has vehicle data - extract vehicle and dayparts (if any)
          if (element.vehicle) {
            statement.vehicle = this.buildMediaVehicle(element, level);
            statement.id = element['data-reference'];
          }

          if (!child) {
            children.push(statement);
            child = children[children.length - 1];
          }
          children = child.children;
        });
      });
    } // if data.searchResults

    // if its not the complete data, add in the topCategories at the bottom
    if (data.useTopLevelCategories && data.topLevelCategories) {
      data.topLevelCategories.forEach((category, index) => {
        // dont repeat top level categories if they were in the original searhcResults
        const found = codebook.tree.children.find(
          (child) => child.description === category.category
        );

        if (!found) {
          const statement: Statement = {
            description: category.category,
            key: category.category,
            type: '',
            coding: '',
            jsonCoding: null,
            id: index,
            expandable: category.hits > 0,
            children: [],
          };
          codebook.tree.children.push(statement);
        }
      });
    }
    return codebook;
  }

  private buildMediaVehicle(element: any, title: string): MediaVehicle {
    const vehicle: MediaVehicle = {
      id: element['data-reference'],
      mnemonic: element['data-reference'],
      title: (element.vehicle['title'] as string) || title,
      calculationMethod:
        element.vehicle.calculation?.method || element.vehicle.calculation,
      mediaType: element.vehicle['media-type'] || '',
      mediaTypeId: parseInt('' + element.vehicle['media-type-id']) || 0, // force number
      addressable: false, // defaulted false until entering a plan, then configured based on media type
      addressableConfig: undefined,
      isMediaGroup: element.vehicle['calculation-type'] === 'composedVehicle',
      ESG: element.vehicle['ESG'] || [],
    };

    // extract dayparts if found
    if (element.vehicle.dayparts) {
      vehicle.ESG =
        element.vehicle.dayparts.length > 0
          ? element.vehicle.dayparts[0]['ESG']
          : vehicle.ESG;
      vehicle.dayparts = element.vehicle.dayparts.map((daypart) => {
        return {
          id: daypart['data-reference'],
          mnemonic: daypart['data-reference'],
          title: daypart.title,
          startDay: daypart.startday,
          startTime: daypart.starttime,
          endDay: daypart.endday,
          endTime: daypart.endtime,
        };
      });

      //rebuild the vehicle id to dayparts ORed
      //vehicle.daypartIds = vehicle.dayparts.map( dp => dp.id);
    }
    return vehicle;
  }

  // process a media codebook search result
  private processMediaCodebook(data: any): CodebookResponse {
    let codebook: CodebookResponse = {
      status: {
        success: data.success,
        hits: data.hits,
        scrollId: data.scrollId,
        took: data.took,
      },
      fullText: [], // top level search results presented flat (on chips)
      coding: [],
      tree: { description: 'root', id: '', children: [] }, // used in the expanded search panel
      useTopLevelCategories: false,
    };

    // iterate through main search results
    data.searchResults?.forEach((element) => {
      let category = element.category;

      codebook.fullText.push(category);
      codebook.coding.push(element['data-reference']);

      // parse the fulltext
      const levels = category.split('|');
      let children = codebook.tree.children;

      levels.forEach((level, index) => {
        let child = children.find((node) => node.description === level);
        const statement: Statement = {
          description: level,
          key: category,
          type: element['type'] || '',
          coding: null,
          jsonCoding: null,
          id: element['pos'] || 0,
          children: [],
        };

        // has vehicle data - extract vehicle and dayparts (if any)
        if (element.vehicle && index === levels.length - 1) {
          statement.vehicle = this.buildMediaVehicle(element, level);
          statement.id = element['data-reference'];
          statement.coding = element['data-reference'];
          statement.jsonCoding = element['data-reference-json'] || null;
        }

        if (!child) {
          children.push(statement);
          child = children[children.length - 1];
        }
        children = child.children;
      });
    });

    return codebook;
  }
}
