import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ViewMode } from '../tab-stepper.component';

@Component({
  selector: 'tab-stepper-tab',
  templateUrl: './tab-stepper-tab.component.html',
  styleUrls: ['./tab-stepper-tab.component.scss'],
})
export class TabStepperTabComponent implements OnInit {
  @Input() viewMode: ViewMode;
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() visible: boolean = true;
  @ViewChild('template') template: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
