import { TreeTableMenuItem } from '../components/tree-table/tree-table.models';

export interface ResultLine {
  title?: string;
  avgQtrHourRating?: number;
  spots: number;
  reach00: number;
  reachPct: number;
  effReach00: number;
  effReachPct: number;
  impacts: number;
  grps: number;
  avgFreq: number;
  cpp: number;
  cpm: number;
  cps: number;
  totalCost: number;
  costRchPnt: number;
  universe: number;
}

export interface MultiResultLine extends ResultLine {
  longTitle?: string;
  expanded: boolean;
  stations?: ResultLine[]; // station results specific to a market (multi-market component)
}

export enum compareOptionKeys {
  media = 'media',
  audiences = 'audiences',
  plans = 'plans',
  freqDistribution = 'freqDistribution',
}

export const compareOptions: TreeTableMenuItem[] = [
  { label: 'Media', data: compareOptionKeys.media },
  { label: 'Audiences', data: compareOptionKeys.audiences },
  { label: 'Plans', data: compareOptionKeys.plans },
  { label: 'Freq. Dist.', data: compareOptionKeys.freqDistribution },
];

export interface SurveyMetrics {
  surveyCode: string;
  authorizationGroup: string;
  surveyTitle: string;
  targetTitle: string;
  surveyInfo: string;
  copyrightInfo: string;
  provider: string;
  population: number;
  sample: number;
  units: number;
  hasCustomPop?: boolean;
}

export interface CampaignPreparationResponse {
  surveyCode: string;
  authorizationGroup: string;
  success: boolean;
  message: string;
}
