<!-- main container area -->
<div fxLayout="column">
  <!-- survey selection card and toolbar -->
  <div
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="space-between center"
    class="survey-bar-container"
  >
    <survey-info-bar
      fxFlex="100"
      (surveyChangeClick)="onSurveySelection()"
      (currentSurveyChange)="onCurrentSurveyChange()"
      [allowChangeSurvey]="true"
      [showManageSurvey]="true"
      [currentSurvey]="surveyBarCurrentSurvey"
      [selectedSurveys]="surveyBarSelectedSurveys"
    >
      <audience-toolbar [survey]="surveyBarSurvey"> </audience-toolbar>
    </survey-info-bar>
  </div>
  <!-- left side and right side -->
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="24px"
  >
    <!-- left side. codebook tree -->
    <div
      fxFlex="0 0 405px"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <!-- tree content -->
      <div
        fxFlexFill
        fxLayout="column"
      >
        <!-- options section -->
        <div
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="start center"
        >
          <!-- tree search textbox -->
          <mat-form-field
            appearance="outline"
            tupMatFormField
            class="input-form-field-search"
          >
            <button
              matPrefix
              mat-icon-button
              matTooltip="Search"
              (click)="onSearchClick(treeSearch)"
              class="search-btn"
            >
              <mat-icon *ngIf="!searching">search</mat-icon>
              <processing
                *ngIf="searching"
                diameter="20"
              ></processing>
            </button>
            <input
              matInput
              type="text"
              placeholder="Search"
              [(ngModel)]="treeSearch"
              (keyup.enter)="onSearchClick(treeSearch)"
            />
            <!-- advanced search options -->
            <simple-selection
              matSuffix
              display="button"
              [topOption]="advancedSearchOptions.titleCodingToggle"
              [options]="advancedSearchOptions.options"
              [multi]="false"
              (dropdownToggle)="onAdvancedSearchOptionChange($event)"
              buttonIcon="tune"
              matTooltip="Advanced search"
              buttonTemplateToggleTitle="SEARCH BY"
              buttonTemplateOptionsTitle="ADVANCED SEARCH"
            >
            </simple-selection>
          </mat-form-field>

          <!-- option -->
          <button
            mat-stroked-button
            tupMatButton
            appearance="tiny"
            color="primary"
            matTooltip="Reset"
            (click)="resetSearch()"
            class="reset-btn"
          >
            <mat-icon color="primary">autorenew</mat-icon>
          </button>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="8px"
        >
          <div
            fxFlex="100%"
            fxLayout="column"
          >
            <!-- category dropdown -->
            <mat-form-field
              appearance="outline"
              class="select-medium"
              tupMatFormField
            >
              <mat-select
                [(ngModel)]="currentCategory"
                (ngModelChange)="onCategoryChange($event)"
                class="select-medium codebook-category"
                panelClass="category-select-wrapper"
              >
                <mat-select-trigger>
                  {{ currentCategory }}
                </mat-select-trigger>
                <mat-option
                  *ngFor="let category of categories"
                  class="category-text"
                  [value]="category"
                >
                  <div
                    fxFlex="row"
                    fxLayoutAlign="space-between center"
                    class="selected-option"
                  >
                    {{ category }}
                    <mat-icon *ngIf="category === currentCategory"
                      >done</mat-icon
                    >
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- operator buttons -->
          <button
            mat-stroked-button
            tupMatButton
            *ngFor="let operator of operators"
            class="operator-btn {{ operator.labelType }}"
            appearance="small"
            color="primary"
            [matTooltip]="operator.toolTip"
            (click)="onOperatorClick(operator)"
            [ngClass]="{ 'join-btn': operator.label === 'join' }"
          >
            <ng-container *ngIf="operator.labelType === 'string'">
              {{ operator.label }}
            </ng-container>
            <mat-icon
              *ngIf="operator.labelType === 'icon' && operator.label === 'join'"
              class="material-symbols-outlined"
              [attr.data-tracking]="
                operator.label === 'join' ? 'join-button' : ''
              "
              [matMenuTriggerFor]="menu"
            >
              {{ operator.label }}
            </mat-icon>
            <mat-icon
              *ngIf="operator.labelType === 'icon' && operator.label !== 'join'"
            >
              {{ operator.label }}
            </mat-icon>
          </button>
        </div>

        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngFor="let booleanLogicOption of booleanLogicOptions"
            (click)="onBooleanLogicOperatorClick(booleanLogicOption)"
            [attr.data-tracking]="
              booleanLogicOption === 'count' ? 'count-option' : ''
            "
          >
            {{ booleanLogicOption | uppercase }}
          </button>
        </mat-menu>

        <!-- tree -->
        <div
          class="tree-container"
          #treeContainer
        >
          <mat-spinner
            *ngIf="loadingAudiences"
            class="spinner-middle"
          ></mat-spinner>
          <div
            *ngIf="visible && treeData.length > 0 && !loadingAudiences"
            class="clear-all"
          >
            <span
              matTooltip="Unselect all"
              (click)="unSelectAll()"
            >
              Clear selection</span
            >
          </div>
          <div
            *ngIf="visible && treeData.length === 0 && showNoResultsAfterSearch"
            class="no-results-container"
          >
            <mat-icon
              class="material-symbols-outlined sentiment-dissatisfied-icon"
              >sentiment_dissatisfied</mat-icon
            >
            <div
              [innerHtml]="noSearchResultsMessageHTML"
              class="list-container"
            ></div>
          </div>

          <tree-table
            class="audience-tree-table"
            [textOnMultipleLines]="true"
            *ngIf="visible"
            #codingTree
            cdkDropList
            [hidden]="loadingAudiences"
            [(selectedNodes)]="preSelectedNodes"
            (selectedNodesChanged)="onSelectedNodesChanged($event)"
            treeWidth="auto"
            [data]="treeData"
            [disableDragging]="false"
            (expand)="OnTreeExpand($event)"
            (nodeDblClick)="onDoubleClick($event)"
            (treeNodeMenuClick)="onTreeNodeMenuClick($event)"
          ></tree-table>
        </div>
      </div>
    </div>

    <!-- right hand side - selected coding -->
    <div fxFlex="100%">
      <div
        fxLayout="column"
        style="height: calc(100vh - 108px)"
      >
        <snackbar-generic
          *ngIf="hasMultiSurveyAudience"
          [data]="importAudienceWarningData"
          class="import-data-warning-snackbar"
        ></snackbar-generic>
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="processing > 0"
        ></mat-progress-bar>
        <div
          fxFlex
          style="padding: 0"
        >
          <context-menu
            #dragMenuTrigger
            [auto]="false"
            [menu]="dragMenu"
            (itemClick)="onDragMenuClick($event)"
          ></context-menu>
          <!-- TO DO: change the condition for adding ngClass -->
          <codebook-table
            #codebookTable
            class="primary-color-string codebook-table-container"
            [ngClass]="{ 'contains-warning-snackbar': false }"
            [unitsText]="unitsText"
            [allowNoDataPanel]="allowNoDataPanel"
            [loadingTreeTable]="loadingAudiences"
            [primarySurvey]="primarySurvey"
            [dropChipWidth]="'calc(100vw - 560px)'"
            [(dataSource)]="targetStatements"
            (manualCoding)="onManualCoding()"
            (manualCodingClick)="onManualCodingClick($event, false)"
            (newManualCodingClick)="onManualCodingClick($event, true)"
            (editTarget)="onEditTarget($event)"
            (deleteTarget)="onDeleteTarget($event)"
            (targetsChanged)="onTargetsChanged($event)"
            [surveyHasAddressable]="surveyHasAddressable"
            (drop)="onDropCodebookItem($event)"
            (tableItemsOrderChanged)="onTableItemsOrderChanged($event)"
            (headerMenuItemClick)="onContextMenuItemClick($event)"
            (targetHasChanged)="onVisualEditorTargetChanged($event)"
            (dropNode)="onVisualEditingDropNode($event)"
            (saveClick)="onVisualEditorSaveClick()"
            (closeClick)="onVisualEditorCloseClick()"
            (editDialogSaveClick)="onEditDialogSaveClick($event)"
            [visualEditingTarget]="visualEditingTarget"
            (rowsCombined)="onCombineRows($event)"
            (populationMenuClick)="onPopulationMenuClick($event)"
            (populationEdited)="onPopulationEdited($event)"
            [hasMultiSurveyAudience]="hasMultiSurveyAudience"
            fxFlex
          ></codebook-table>
        </div>
      </div>
    </div>
  </div>
</div>
