import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

export interface VirtualScrollData {
  scrollTop: number;
  scrollHeight: number;
  totalHeight: number;
  itemHeight: number;
  firstChange?: boolean;
}

@Directive({
  selector: '[virtualScrollTable]',
})
export class VirtualScrollTableDirective {
  @Input('itemHeight') itemHeight: number = 42;
  @Output('scrollUpdate') rowUpdate: EventEmitter<VirtualScrollData> =
    new EventEmitter<VirtualScrollData>();

  scrollUpdate: VirtualScrollData = {
    scrollTop: 0,
    scrollHeight: 800,
    totalHeight: 1200,
    itemHeight: this.itemHeight,
    firstChange: true,
  };

  constructor(private element: ElementRef) {}

  // for scroll events
  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.scrollUpdate = {
      scrollTop: event.srcElement.scrollTop,
      scrollHeight: event.srcElement.clientHeight,
      totalHeight: event.srcElement.scrollHeight,
      itemHeight: this.itemHeight,
    };

    this.viewportUpdated();
  }

  // for window events
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.viewportUpdated();
  }

  public viewportUpdated(firstChange?: boolean) {
    if (typeof firstChange !== 'undefined') {
      this.scrollUpdate.firstChange = firstChange;
    }

    this.scrollUpdate = {
      ...this.scrollUpdate,
      scrollHeight: this.element.nativeElement.clientHeight,
      scrollTop: this.element.nativeElement.scrollTop,
    };

    this.rowUpdate.emit(this.scrollUpdate);
  }
}
