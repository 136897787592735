import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  SnackbarGenericOptionModel,
  StatusSnackbarIcon,
} from '../snackbar-generic/snackbar-generic.component';
import { MultiSurveyService } from '../../../services/multi-survey.service';
import {
  LoadMultiSurveyResult,
  UploadFile,
} from '../../../models/file-upload-model';
import { BreadcrumbsStepModel } from '../../breadcrumbs/breadcrumbs.component';
import { MediaPlannerService } from '../../../services/media-planner.service';
import {
  FileType,
  MultiSurveyManualInput,
  MultiSurveyVehicle,
  MultiSurveyVehicleConfig,
} from '../../../models/multi-survey.models';
import { SurveyMetrics } from 'src/app/models/planning.models';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../confirm-dialog/confirm-dialog.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  ImportPreferencesComponent,
  preservedOptions,
} from './import-preferences/import-preferences.component';
import { DauTarget } from '../../../classes/dau-file';
import { Target } from '../../../classes/target';
import { ManualEntryComponent } from './manual-entry/manual-entry.component';
import { CodebookSelectionService } from '../../../services/codebook-selection.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  DocumentFullSurvey,
  DocumentTarget,
} from 'src/app/models/document.model';
import {
  TargetEditorDialogComponent,
  TargetEditorDialogModel,
} from '../target-editor-dialog/target-editor-dialog.component';
import { ConverterService } from '../../../services/converter.service';

export enum dialogActionType {
  dashboardImport,
  appImport,
  preferences,
  changeAudience,
}

export enum audienceActionType {
  useExistingAud = 'useExistingAud',
  buildOwnAud = 'buildOwnAud',
}

export interface MixedDataDialogModel {
  action?: number; // dialogActionType
  continue?: boolean;
  audienceAction?: string; // audienceActionType
  planAudienceId?: string; // audience chosen as plan audience: multisurvey fileId or survey audience Id
  manualInputData?: MultiSurveyManualInput;
  config?: MultiSurveyVehicleConfig;
  keepThousands?: boolean;
  documentTarget?: DocumentTarget;
  refreshOnCloseWithoutSave?: boolean;
}

interface DialogFileItems {
  name: string;
  extraDetails: string;
  population: number;
  scale: number;
  targetCodeId: string;
  disabled: boolean;
  tooltip: string;
}

export interface MultiSurveyArray {
  fileTitle: string;
  fileItems: DialogFileItems[];
  fileId?: string;
  fileUsedAsPlanAudience?: boolean;
  fileType: FileType;
}

const useExistingAudienceLabel = `Don't change my audience`;
const buildOwnAudienceLabel = `I build my own audience`;

@Component({
  selector: 'import-mixed-data-dialog',
  templateUrl: './import-mixed-data-dialog.component.html',
  styleUrls: ['./import-mixed-data-dialog.component.scss'],
})
export class ImportMixedDataDialogComponent implements OnInit {
  @ViewChild('manualEntryForm') manualEntryForm: ManualEntryComponent;
  @ViewChild('importPreferences') importPreferences: ImportPreferencesComponent;

  private destroy$: Subject<void> = new Subject<void>();
  private searchSubject: Subject<string> = new Subject<string>();
  searchSurveyText: string = '';

  title: string;
  uploadStarted: boolean;
  fileUploaded: boolean;
  fileName: string;
  fileSize: string;
  uploadProgress: number;
  incompatibleFileFormat: boolean;
  allowedFileExtensions: string[];

  currentStep: number;
  isSingleAudienceFile: boolean;
  dialogTitle: string = 'Import mixed data';
  dialogActions = dialogActionType;
  selectedDialogTab: number;

  breadcrumbsSteps: BreadcrumbsStepModel[] = [
    {
      title: 'Name your campaign',
    },
    {
      title: 'Import mixed data',
    },
    {
      title: '',
    },
  ];
  showBreadcrumbs: boolean = false;

  snackbarError: SnackbarGenericOptionModel = {
    type: 'error',
    message: '',
    icon: StatusSnackbarIcon.Error,
  };
  selectAudienceSnackbarWarning: SnackbarGenericOptionModel = {
    type: 'warning',
    message:
      'As you are importing a DAU file or adding manual Reach & Frequency, ' +
      'you may only proceed with <u> one target population</u>. All other targets will be removed.',
    icon: StatusSnackbarIcon.Error,
  };

  preservedMetric: string;

  multiSurveyId: string;

  multiSurveyArray: MultiSurveyArray[];
  audienceActions = audienceActionType;
  selectedAudienceChanged: boolean = false;
  noSelectedAudience: boolean = false;
  selectedTargetPerFile: { [fileIndex: number]: number };
  selectedPlanAudience: string;
  filteredFileAudiences: {
    fileId: string;
    audienceName: string;
  }[] = [];
  canKeepPlanAudience: boolean = false;
  dauCont: number;

  surveyMetrics: SurveyMetrics;
  planTargets: Target[];

  duplicateManualTitle: string = '';
  surveyList: DocumentFullSurvey[] = [];
  selectedSurveyIndex: number = null;
  surveySearchLoading: boolean = false;
  disabledSearchInput: boolean = false;
  fileRemovedFromDialog: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MixedDataDialogModel,
    public dialogRef: MatDialogRef<ImportMixedDataDialogComponent>,
    private multiSurveyService: MultiSurveyService,
    private mediaplannerService: MediaPlannerService,
    private dialog: MatDialog,
    private codebookService: CodebookSelectionService
  ) {
    this.allowedFileExtensions = [`.${FileType.DAU}`];
    this.uploadStarted = false;
    this.uploadProgress = 0;
    this.fileUploaded = false;
    this.currentStep = 1;
    this.planTargets = this.mediaplannerService.plan.targets.filter(
      (target) => target.documentTarget.planningTarget
    );
  }

  ngOnInit(): void {
    if (this.data.config) {
      this.multiSurveyId = this.data.config.multiSurveyId;
    }
    if (this.data.action === dialogActionType.dashboardImport) {
      this.mediaplannerService.plan.clearAll();
      this.showBreadcrumbs = true;
      this.setupSearchSubscription();
      const filter = new Map<string, boolean>();
      filter.set('hasVehicles', true);
      this.codebookService
        .getSurveys(filter)
        .subscribe((surveys: DocumentFullSurvey[]) => {
          this.surveyList = [...surveys];
          if (this.surveyList.length === 1) {
            this.selectedSurveyIndex = 0;
            this.disabledSearchInput = true;
          }
        });
    }

    if (this.data.action === dialogActionType.appImport) {
      this.breadcrumbsSteps[1].title = 'Import file';
      this.breadcrumbsSteps[0].title = '';
      this.selectedDialogTab = 0;
    }

    if (this.data.action === dialogActionType.changeAudience) {
      this.dialogTitle = 'Change audience';
      this.currentStep = 2;
      this.breadcrumbsSteps[2].title = this.dialogTitle;

      if (this.multiSurveyId) {
        const multiSurveyFile =
          this.mediaplannerService.plan.multiSurveys.multiSurveys.find(
            (multiSurvey) => multiSurvey.id === this.multiSurveyId
          );
        this.uploadProgress = 100;
        this.fileName = multiSurveyFile.header.planFilename;
      }
    }

    if (this.data.action === dialogActionType.preferences) {
      this.dialogTitle = 'Preferences';
    }

    this.surveyMetrics = this.mediaplannerService.getSurveyMetrics();
    this.multiSurveyArray = this.buildFileAudiences();

    if (this.mediaplannerService.isSurveyMrfCompatible('import')) {
      this.allowedFileExtensions.push(`.${FileType.MRF}`);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private focusManualInputTitle() {
    setTimeout(() => {
      this.manualEntryForm.manualInputTitleRef.nativeElement.focus();
    }, 251);
  }

  private setManualInputData() {
    this.data.manualInputData = {
      title: this.manualEntryForm.manualMediaTitle.trim(),
      reachPct: Number(this.manualEntryForm.manualReachPct),
      grp: Number(this.manualEntryForm.manualGRPs),
      inserts: Number(this.manualEntryForm.manualInserts),
      avgFreq: Number(this.manualEntryForm.manualAvgFreq),
      totalCost: Number(this.manualEntryForm.manualTotalCost),
    };
  }

  onDuplicateTitleCheck(title: string) {
    this.duplicateManualTitle = title;
    this.snackbarError.message = `The name "${title}" is already being used. Please choose a different name.`;
  }

  private buildFileAudiences(): MultiSurveyArray[] {
    if (
      this.mediaplannerService.plan.multiSurveys &&
      this.mediaplannerService.plan.multiSurveys.multiSurveys.length
    ) {
      this.selectedTargetPerFile = {};
      this.selectedPlanAudience = null;
      this.dauCont = 0;

      console.log(
        'Uploaded Multi Survey files ::',
        this.mediaplannerService.plan.multiSurveys.multiSurveys
      );
      return this.mediaplannerService.plan.multiSurveys.multiSurveys.map(
        (multiSurveyFile, multiSurveyIndex) => {
          const fileItems = multiSurveyFile.getFileListItemsForDialog();

          if (multiSurveyFile.fileType === FileType.DAU) {
            this.dauCont++;
            if (multiSurveyFile.usedAsPlanAudience)
              this.selectedPlanAudience = multiSurveyFile.id;
            const selectedTarget = multiSurveyFile.targets[0];
            fileItems.forEach((tgt, tgtIndex) => {
              if (
                selectedTarget &&
                selectedTarget.targetCodeId === tgt.target.targetCodeId
              ) {
                this.selectedTargetPerFile[multiSurveyIndex] = tgtIndex;
              }
            });
          }

          return {
            fileTitle: multiSurveyFile.header.planFilename,
            fileId: multiSurveyFile.id,
            fileUsedAsPlanAudience: multiSurveyFile.usedAsPlanAudience,
            fileType: multiSurveyFile.fileType,
            fileItems: this.mapFileListItems(
              fileItems,
              multiSurveyFile.fileType
            ),
          };
        }
      );
    }
  }

  private mapFileListItems(
    rawFileItemsData,
    fileType: string
  ): DialogFileItems[] {
    const mappedFileItems: DialogFileItems[] = [];

    if (!rawFileItemsData || !rawFileItemsData.length) return [];

    switch (fileType) {
      case FileType.DAU:
        return rawFileItemsData.map((target: DauTarget) => {
          const targetData = target.target;
          const incompatiblePopulation = this.surveyMetrics
            ? targetData.population *
                (targetData.scale / this.surveyMetrics.units) >
              this.surveyMetrics.population
            : false;
          const extraDetails = ` - Population ${targetData.population.toLocaleString()} (${targetData.scale
            .toString()
            .substring(1)})`;
          return {
            name: targetData.name,
            extraDetails,
            population: targetData.population,
            scale: targetData.scale,
            targetCodeId: targetData.targetCodeId,
            disabled: incompatiblePopulation,
            tooltip: incompatiblePopulation
              ? 'Target population exceeds survey population'
              : targetData.name + extraDetails,
          };
        });
      case FileType.MRF:
        return rawFileItemsData.map((mrfVehicle: MultiSurveyVehicle) => {
          return {
            name: mrfVehicle.title,
            extraDetails: '',
            population: null,
            scale: null,
            targetCodeId: '',
            disabled: true,
            tooltip: 'Display purpose only',
          };
        });
    }

    return mappedFileItems;
  }

  onSurveyInputBlur() {
    if (this.surveyList.length === 0) {
      this.searchSurveyText = '';
      this.onSearch(this.searchSurveyText);
    }
  }

  onSearch(searchText: string) {
    this.surveySearchLoading = true;
    const filter = new Map<string, boolean>();
    filter.set('hasVehicles', true);
    this.codebookService
      .getSurveys(filter, searchText)
      .subscribe((surveys: DocumentFullSurvey[]) => {
        this.surveySearchLoading = false;
        if (
          this.selectedSurveyIndex !== null &&
          surveys[this.selectedSurveyIndex]?.code !==
            this.surveyList[this.selectedSurveyIndex]?.code
        ) {
          this.selectedSurveyIndex = null;
        }
        this.surveyList = [...surveys];
      });
  }

  triggerSearch(searchText: string) {
    this.searchSubject.next(searchText);
  }

  private setupSearchSubscription() {
    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchValue) => {
        this.onSearch(searchValue);
      });
  }

  onTabChanged(tabChanged: MatTabChangeEvent) {
    this.selectedDialogTab = tabChanged.index;
    if (this.selectedDialogTab === 1) {
      this.focusManualInputTitle();
    }
  }

  onUploadStarted(file: UploadFile) {
    this.uploadStarted = true;
    this.fileName = file.fileName;
    this.fileSize = file.fileSize;
  }

  onUploadProgress(progress: number) {
    this.uploadProgress = progress;
  }

  onFileUploaded(file: UploadFile) {
    this.multiSurveyService
      .parseMultiSurveyFile(file)
      .subscribe((loadResult: LoadMultiSurveyResult) => {
        if (loadResult.success) {
          this.fileUploaded = true;

          // TO DO: change the file type if needed
          const fileExtension = file.fileName.split('.').pop().toLowerCase();
          this.isSingleAudienceFile = fileExtension === FileType.DAU;

          this.multiSurveyArray = this.buildFileAudiences();
        } else {
          this.incompatibleFileFormat = true;
          this.snackbarError.message = 'The file format is not compatible.';
        }
        return;
      });
  }

  onBack() {
    this.onStepChange(this.currentStep - 1);
  }

  onSelectDataFromFile() {
    this.preservedMetric = this.importPreferences.preservedMetric;
    this.breadcrumbsSteps[2].title = 'Use data from file';
    this.currentStep = 2;
    this.dialogTitle = this.breadcrumbsSteps[this.currentStep].title;
    this.showBreadcrumbs = true;
  }

  onBreadCrumbsStepChange(step: number) {
    this.onStepChange(step);
  }

  onStepChange(index: number) {
    if (index === 0) {
      this.dialogRef.close(this.data);
    }

    if (this.data.action === dialogActionType.appImport && index === 1) {
      this.showBreadcrumbs = false;
    }
    this.currentStep = index;

    this.dialogTitle = this.breadcrumbsSteps[this.currentStep].title;
  }

  onSelectedAudienceChange() {
    this.selectedAudienceChanged = true;
    const noneSum = Object.values(this.selectedTargetPerFile).reduce(
      (sum, targetIndex) => sum + targetIndex,
      0
    );
    this.noSelectedAudience = noneSum === -this.multiSurveyArray.length;
  }

  onAddManualRF() {
    if (this.currentStep === 4) {
      this.data.planAudienceId = this.selectedPlanAudience;
    } else {
      // single audience plan. locally store form values and proceed with saving
      this.setManualInputData();
    }
    this.onClose(true);
  }

  onNextPressed(step: number = null) {
    this.breadcrumbsSteps.push({ title: 'Select audience' });
    this.filteredFileAudiences = [];
    const planningTargets = this.mediaplannerService.plan.targets.filter(
      (target) => target.documentTarget.planningTarget
    );

    if (planningTargets.length) {
      // media plan build with multiple regular survey audiences. Add them to the 'Select audience' list. Exclude addressable
      if (planningTargets.length > 1) {
        this.filteredFileAudiences = planningTargets.map((target) => {
          return {
            fileId: target.id,
            audienceName: target.title,
          };
        });
      } else {
        if (
          this.selectedPlanAudience &&
          this.mediaplannerService.plan.multiSurveys.multiSurvey(
            this.selectedPlanAudience
          ) !== null
        ) {
          const fileIndex =
            this.mediaplannerService.plan.multiSurveys.multiSurveys.findIndex(
              (ms) => ms.id === this.selectedPlanAudience
            );
          const multiSurveyFile =
            this.mediaplannerService.plan.multiSurveys.multiSurvey(
              this.selectedPlanAudience
            );
          const selectedTargetIndex = this.selectedTargetPerFile[fileIndex];
          const fileTargets =
            this.mediaplannerService.plan.multiSurveys.multiSurveys[
              fileIndex
            ].getAllTargets();
          // if previously selected target of fileUsedAsPlanAudience was not changed, it can be shown as "Don't change my audience"
          this.canKeepPlanAudience =
            selectedTargetIndex !== -1
              ? fileTargets[selectedTargetIndex].target.targetCodeId ===
                multiSurveyFile.targets[0].targetCodeId
              : false;
        } else {
          // targets.length === 1 and is regular survey target
          const existingTarget = planningTargets[0];
          this.canKeepPlanAudience =
            !existingTarget.documentTarget.isMultiSurvey ||
            !!existingTarget.documentTarget.customPopulation;
        }
      }
    }

    // if single existing plan target is not from multi survey files
    if (
      this.canKeepPlanAudience &&
      this.mediaplannerService.plan.multiSurveys.multiSurvey(
        this.selectedPlanAudience
      ) === null
    ) {
      const audience = planningTargets[0];
      const audiencePop = `${audience.population.toLocaleString()} (${this.mediaplannerService.plan.currentSurvey.units
        .toString()
        .substring(1)})`;
      this.filteredFileAudiences.push({
        fileId: audienceActionType.useExistingAud,
        audienceName: `${useExistingAudienceLabel} [ ${audience.title} - ${audiencePop}]`,
      });
    }

    // Add to the 'Select audience' list each target selected from each uploaded multi survey file
    if (this.selectedTargetPerFile) {
      Object.keys(this.selectedTargetPerFile).forEach((fileIndex) => {
        const selectedTargetIndex = this.selectedTargetPerFile[fileIndex];
        if (selectedTargetIndex !== -1) {
          // when option other than 'None' was selected
          const isMultiSurveySelectedAud =
            this.canKeepPlanAudience &&
            this.multiSurveyArray[fileIndex].fileUsedAsPlanAudience;

          const audience =
            this.multiSurveyArray[fileIndex].fileItems[selectedTargetIndex];
          const audiencePop = `${audience.population.toLocaleString()} (${audience.scale
            .toString()
            .substring(1)})`;
          if (isMultiSurveySelectedAud) {
            this.filteredFileAudiences.unshift({
              fileId: audienceActionType.useExistingAud,
              audienceName: `${useExistingAudienceLabel} [ ${this.multiSurveyArray[fileIndex].fileTitle} - ${audiencePop}]`,
            });
          } else {
            this.filteredFileAudiences.push({
              fileId: this.multiSurveyArray[fileIndex].fileId,
              audienceName: `${audience.name} [${this.multiSurveyArray[fileIndex].fileTitle} - ${audiencePop}]`,
            });
          }
        }
      });
    }

    if (step === 4) {
      // moving to selectAudienceForManualRF step; store form values first
      this.setManualInputData();
    }
    step ? (this.currentStep = step) : this.currentStep++;
  }

  onUpdate() {
    switch (this.data.action) {
      case dialogActionType.changeAudience:
        // save only if selected target is different from when dialog was opened
        if (this.selectedAudienceChanged) {
          this.mediaplannerService.plan.multiSurveys.selectTargetVehicles(
            this.selectedTargetPerFile
          );
          this.onClose(true);
        } else this.onClose();
        break;
      case dialogActionType.preferences:
        this.data.keepThousands =
          this.importPreferences.preservedMetric === preservedOptions.value;
        this.onClose(true);
        break;
    }
  }

  buildDAUAudienceDialog() {
    const multiSurveyAud =
      this.mediaplannerService.plan.multiSurveys.getPlanAudience();
    const dialogData: TargetEditorDialogModel = new TargetEditorDialogModel();
    dialogData.survey = this.mediaplannerService.plan.targets[0].survey;
    dialogData.dialogTitle = 'Customize imported audience to match population';
    dialogData.confirmButtonText = 'Save';
    dialogData.documentTarget =
      ConverterService.buildDocumentTargetFromMultiSurveyAudience(
        multiSurveyAud.name,
        multiSurveyAud.population,
        multiSurveyAud.scale,
        this.mediaplannerService.plan.currentSurvey
      );
    const options = {
      data: dialogData,
      closeOnNavigation: false,
      disableClose: true,
      minWidth: '70vw',
      maxWidth: '70vw',
      autoFocus: false,
    };

    this.dialog
      .open(TargetEditorDialogComponent, options)
      .afterClosed()
      .subscribe((resultData: TargetEditorDialogModel) => {
        if (resultData) {
          // TargetEditorDialog - Save
          this.data.documentTarget = resultData.documentTarget;
          this.data.documentTarget.title = multiSurveyAud.name;
          this.onClose(true);
        } else {
          // TargetEditorDialog - Cancel
          this.mediaplannerService.plan.multiSurveys.selectPlanAudience('');
          this.selectedPlanAudience = null;
          this.data.planAudienceId = null;
        }
      });
  }

  prepareData() {
    this.data.continue = true;

    this.mediaplannerService.plan.multiSurveys.setPreservedMetric(
      this.mediaplannerService.plan.multiSurveys.multiSurveys[0],
      this.preservedMetric === preservedOptions.value
    );

    // set multiSurveys.multiSurveys.targets and multiSurveys.multiSurveys.vehicles with
    // user selected target and target vehicles from according file
    this.mediaplannerService.plan.multiSurveys.selectTargetVehicles(
      this.selectedTargetPerFile
    );

    // don't set/change multi survey as plan audience when existing audience is chosen
    // OR chosen audience is not from multi survey list (i.e. one of multiple survey selected audiences)
    const setMultiSurveyAsPlanAudience =
      this.selectedPlanAudience !== audienceActionType.useExistingAud &&
      this.multiSurveyArray.some(
        (multiSurvey) => multiSurvey.fileId === this.selectedPlanAudience
      );
    if (setMultiSurveyAsPlanAudience) {
      this.mediaplannerService.plan.multiSurveys.selectPlanAudience(
        this.selectedPlanAudience
      );
    }

    // import on media/plan screen. Plan has only 1 audience (can be any type). Imported vehicles will be added to existing target.
    if (this.selectedPlanAudience === audienceActionType.useExistingAud) {
      this.data.audienceAction = audienceActionType.useExistingAud;
    }

    // starting new campaign with imported file, but not selecting a plan audience from file
    if (this.selectedPlanAudience === audienceActionType.buildOwnAud) {
      this.data.audienceAction = audienceActionType.buildOwnAud;
    }

    if (
      this.selectedSurveyIndex !== null &&
      this.surveyList[this.selectedSurveyIndex]
    ) {
      this.mediaplannerService.plan.multiSurveys.selectedSurvey =
        this.surveyList[this.selectedSurveyIndex];
    }
    this.data.planAudienceId = this.selectedPlanAudience;
  }

  onAdd() {
    console.log(
      '>>> onAdd select/change target',
      this.selectedTargetPerFile,
      this.selectedPlanAudience
    );

    if (
      [dialogActionType.appImport, dialogActionType.changeAudience].includes(
        this.data.action
      ) &&
      this.dauCont &&
      this.mediaplannerService.plan.multiSurveys.multiSurvey(
        this.selectedPlanAudience
      ) !== null
    ) {
      this.prepareData();
      this.buildDAUAudienceDialog();
    } else {
      this.prepareData();
      this.onClose(true);
    }
  }

  get selectedAudienceValue() {
    const filter = this.filteredFileAudiences.find(
      (val) => val.fileId === this.selectedPlanAudience
    );
    if (filter) return filter.audienceName;
    if (this.selectedPlanAudience === audienceActionType.buildOwnAud)
      return buildOwnAudienceLabel;
    if (this.selectedPlanAudience === audienceActionType.useExistingAud)
      return useExistingAudienceLabel;
  }

  onClose(save: boolean = false) {
    if (save) {
      this.dialogRef.close(this.data);
    } else {
      // discard last uploaded file when closing import dialog without choosing an audience
      if (this.fileUploaded) {
        this.mediaplannerService.plan.multiSurveys.remove();
      }
      this.dialogRef.close(
        this.fileRemovedFromDialog
          ? {
              refreshOnCloseWithoutSave: this.fileRemovedFromDialog,
            }
          : null
      );
    }
  }

  onRemoveFileClick(fileIndex: number) {
    const options: any = {
      buttons: [
        { caption: 'Cancel', data: 'cancel' },
        { caption: 'Delete', data: 'delete', flat: true },
      ],
      closeButton: { caption: 'Close', data: 'cancel' },
      snackbar: {
        type: 'warning',
        message: `Are you sure you want to delete "${this.multiSurveyArray[fileIndex].fileTitle}"? This action is permanent.`,
        align: 'center',
        icon: StatusSnackbarIcon.Warning,
      },
      autoFocus: false,
      minWidth: '35vw',
      maxWidth: '40vw',
    };

    const dialogData = new ConfirmDialogModel('Are you sure?', [''], options);

    Object.assign(options, {
      data: dialogData,
    });

    this.dialog
      .open(ConfirmDialogComponent, options)
      .afterClosed()
      .subscribe((button) => {
        if (button.data === 'delete') {
          this.fileRemovedFromDialog = true;
          this.multiSurveyArray.splice(fileIndex, 1);
          this.multiSurveyService.removeMultiSurveyDataFromPlan(fileIndex);
          this.buildFileAudiences(); // rebuild selectedTargetPerFile and fileUsedAsPlanAudience properties
        }
      });
  }
}
