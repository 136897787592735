<div class="columns-editor-dialog-container">
  <tup-mat-title-bar title="Add/remove columns">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose(false)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div class="columns-dialog-container">
      <p class="column-item">
        <mat-checkbox
          id="select-all"
          color="primary"
          [checked]="allSelected"
          (change)="onSelectAll($event)"
        >
          <strong>Select all</strong>
        </mat-checkbox>
      </p>

      <p
        class="column-item"
        *ngFor="let column of allColumns"
      >
        <mat-checkbox
          [attr.data-tracking]="'checkbox-' + column.columnDef"
          color="primary"
          [checked]="column.visible"
          (change)="onColumnChanged($event, column)"
          [disabled]="column.disabled"
        >
          {{ column | appendUnits : unitsText }}
        </mat-checkbox>
      </p>
    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-button
      (click)="onClose(false)"
      fxLayoutAlign="left"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="noColumnsSelected"
      (click)="onClose(true)"
      fxLayoutAlign="right"
    >
      Save changes
    </button>
  </mat-dialog-actions>
</div>
