<ng-content></ng-content>

<mat-menu #columnMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let item of menu"
    (click)="onClick(item)"
    [disabled]="item.disabled"
  >
    <mat-icon *ngIf="item.matIcon">{{ item.matIcon }}</mat-icon
    >{{ item.label }}
  </button>
</mat-menu>

<span
  *ngIf="menu && menu.length"
  [ngClass]="{ trigger: !main }"
  class="menu-item"
  #columnMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="columnMenu"
>
  <button
    mat-icon-button
    (contextmenu)="$event.preventDefault(); columnMenuTrigger.openMenu()"
  >
    <mat-icon style="color: darkgray">more_vert</mat-icon>
  </button>
</span>
