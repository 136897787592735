<span *ngIf="menu && menu.length">
  <button
    color="primary"
    mat-icon-button
    (click)="onMenuclick()"
    appearance="tiny"
    [ngClass]="{
      'tree-table-inline-button': appearance === 'small',
      'tree-table-inline-button-tiny': appearance === 'tiny'
    }"
    [matMenuTriggerFor]="inlineMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu
    #inlineMenu="matMenu"
    [class]="panelClass"
  >
    <button
      mat-menu-item
      *ngFor="let menuItem of menu"
      [attr.data-tracking]="menuItem.data"
      [matTooltip]="menuItem.tooltip || ''"
      (click)="menuItem.disabled ? null : onClick(menuItem)"
      style="text-align: center"
      [ngClass]="{ 'disabled-option': menuItem.disabled }"
    >
      <mat-icon
        class="{{ menuItem.class || '' }}"
        *ngIf="menuItem.matIcon"
        >{{ menuItem.matIcon }}</mat-icon
      >
      {{ menuItem.label }}
    </button>
  </mat-menu>
</span>
