<div
  fxFlex="100"
  fxLayout="row"
  fxLayoutGap="24px"
  class="container"
>
  <!-- survey dropdown -->
  <div
    class="survey-wrapper"
    fxLayout="column"
    *ngIf="hasSurveyDropdown"
  >
    <div
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign=" center"
    >
      <mat-form-field
        fxFlex
        appearance="outline"
        class="select-medium"
        tupMatFormField
      >
        <!-- survey select -->
        <mat-select
          #surveySelect
          [ngModel]="currentSurvey?.code"
          (selectionChange)="onSurveySelectionChange($event)"
          class="select-medium"
          panelClass="select-wrapper"
          data-tracking="survey-selection-select"
          [disabled]="disabled"
        >
          <mat-select-trigger>
            {{ currentSurvey?.title }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let survey of selectedSurveys"
            [value]="survey?.code"
            [matTooltip]="survey?.title"
          >
            <div
              *ngIf="survey"
              fxFlex="row"
              fxLayoutAlign="space-between center"
              class="selected-option"
            >
              {{ survey.title }}
              <mat-icon *ngIf="currentSurvey?.code === survey?.code"
                >done</mat-icon
              >
            </div>
          </mat-option>
          <!-- manage survey button -->
          <div
            fxFlex="column"
            *ngIf="showManageSurvey"
          >
            <button
              data-tracking="survey-selection"
              fxFlex="100%"
              mat-button
              [disabled]="!allowChangeSurvey"
              (click)="surveySelect.close(); onSurveySelection()"
            >
              <div
                fxFlex="row"
                fxLayoutAlign="space-between center"
              >
                <span class="mat-primary">Manage surveys</span>
              </div>
            </button>
          </div>
        </mat-select>
      </mat-form-field>
      <mat-icon
        *ngIf="hasSurveyInfo"
        class="survey-info-icon"
        (click)="onSurveyInfoClick()"
        >info_outline
      </mat-icon>
    </div>
  </div>
  <!-- toolbar -->
  <div
    *ngIf="hasChildContent"
    fxFlex="100%"
  >
    <ng-content></ng-content>
  </div>
</div>
