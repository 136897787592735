import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

export interface BreadcrumbsStepModel {
  title: string;
  isClickable?: boolean;
  isVisible?: boolean;
}

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnChanges {
  @Input() breadcrumbsSteps: BreadcrumbsStepModel[];
  @Input() currentStep: number = 1;

  @Output() stepChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const currStep = changes['currentStep'];
    if (currStep && currStep.previousValue !== currStep.currentValue) {
      this.breadcrumbsSteps.forEach((step, stepIndex) => {
        if (stepIndex < currStep.currentValue) {
          step.isVisible = true;
          step.isClickable = true;
        }

        if (stepIndex === currStep.currentValue) {
          step.isVisible = true;
          step.isClickable = false;
        }

        if (stepIndex > currStep.currentValue) {
          step.isVisible = false;
          step.isClickable = false;
        }
      });
    }
  }

  onStepChange(step: BreadcrumbsStepModel) {
    // Emit the index of the selected step
    this.stepChanged.emit(
      this.breadcrumbsSteps.findIndex(
        (breadcrumbsStep) => breadcrumbsStep.title === step.title
      )
    );
  }
}
