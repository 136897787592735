<span (contextmenu)="onContextMenu($event)">
  <span [matMenuTriggerFor]="rightMenu"></span>
  <ng-content></ng-content>
</span>

<!-- standard material menu -->
<mat-menu
  #rightMenu="matMenu"
  [overlapTrigger]="false"
  yPosition="below"
  xPosition="after"
>
  <ng-template
    matMenuContent
    let-item="item"
  >
    <button
      mat-menu-item
      *ngFor="let menuItem of menu"
      [disabled]="menuItem.disabled"
      (click)="onClick(menuItem)"
    >
      <mat-icon
        class="{{ menuItem.class || '' }}"
        *ngIf="menuItem.matIcon"
        >{{ menuItem.matIcon }}</mat-icon
      >
      {{ menuItem.label }}
    </button>
  </ng-template>
</mat-menu>
