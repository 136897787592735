<div
  fxFlex="100%"
  fxLayout="column"
  class="planning-step-wrapper"
>
  <!-- survey selection card and toolbar -->
  <survey-info-bar
    [disabled]="viewModeIndex === 0 ? false : true"
    [hasSurveyInfo]="
      viewModeIndex === 0 || !hasPrimarySurveyMetrics ? false : true
    "
    [hasSurveyDropdown]="viewModeIndex === 0 ? false : true"
    [allowChangeSurvey]="false"
    [showManageSurvey]="false"
    [currentSurvey]="surveyBarCurrentSurvey"
    [selectedSurveys]="surveyBarSelectedSurveys"
  >
    <div
      fxFlex="100%"
      fxLayout="row"
      fxLayoutAlign="end"
    >
      <!-- plans wrapper -->
      <div
        fxFlex="calc(100vw - 645px)"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="8px"
        class="header-container"
        *ngIf="viewModeIndex === 0"
      >
        <button
          mat-mini-fab
          (click)="onAddSchedule(false)"
          color="primary"
          class="add-plan-btn"
        >
          <mat-icon>add</mat-icon>
        </button>

        <!-- schedule tabs -->
        <div
          mat-tab-nav-bar
          #scheduleTabGroup
          fxFlex="100"
          style="width: calc(100vw - 645px)"
          [disablePagination]="false"
          class="schedule-tab-wrapper"
        >
          <a
            mat-tab-link
            *ngFor="let schedule of screenSchedules; let i = index"
            [active]="
              activeScheduleTab === i && currentCompareOption !== 'plans'
            "
            (click)="onScheduleChange(i)"
            [ngClass]="{
              'active-link':
                isPlanningStepChecked(i) && currentCompareOption === 'plans'
            }"
          >
            <div
              fxFlex="100%"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div
                fxFlex="100%"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <mat-checkbox
                  *ngIf="currentCompareOption === 'plans'"
                  color="primary"
                  [checked]="schedule.checked"
                  [ngModel]="schedule.checked"
                  (click)="onPlanCheckboxChange($event, i)"
                ></mat-checkbox>
                <button
                  *ngIf="currentCompareOption !== 'plans'"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  class="step-icon"
                >
                  <mat-icon class="step-icon">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    (click)="onEditSchedule()"
                    class="plan-button"
                    mat-menu-item
                  >
                    <span class="material-symbols-outlined"> edit </span>
                    Rename plan
                  </button>
                  <button
                    (click)="onAddSchedule(true)"
                    class="plan-button"
                    mat-menu-item
                  >
                    <span class="material-symbols-outlined">
                      content_copy
                    </span>
                    Duplicate plan
                  </button>
                  <button
                    (click)="onDeleteSchedule()"
                    class="plan-button"
                    mat-menu-item
                  >
                    <span class="material-symbols-outlined"> delete </span>
                    Delete plan
                  </button>
                </mat-menu>
                <div class="tab-name">
                  {{ schedule.name }}
                </div>
              </div>
              <mat-icon
                *ngIf="currentCompareOption !== 'plans'"
                class="step-icon info"
                [matTooltip]="scheduleTooltips[i]"
              >
                info_outline
              </mat-icon>
            </div>
          </a>
        </div>
      </div>

      <planning-toolbar
        [content]="
          viewModeIndex === 0
            ? dataToolbarContent
            : planningCharts.chartsToolbarContent
        "
        [survey]="viewModeIndex === 0 ? surveyBarSurvey : null"
      >
      </planning-toolbar>
    </div>
  </survey-info-bar>

  <ng-template #dataToolbarContent>
    <!-- Compare By dropdown -->
    <div
      fxFlex="1 1 250px"
      fxLayoutAlign="space-between center"
      fxLayout="row wrap"
      fxLayoutGap="14px"
    >
      <mat-form-field
        appearance="outline"
        class="regular-select full-width compare-by-select"
        tupMatFormField
      >
        <mat-label class="compare-by-label"
          ><mat-icon class="compare-icon">compare_arrows</mat-icon
          ><span>Compare by: </span></mat-label
        >
        <mat-select
          (selectionChange)="onCompareOptionChange($event)"
          [value]="currentCompareOption"
          panelClass="compare-by-select-panel"
        >
          <mat-select-trigger>
            {{ currentCompareOptionLabel }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let compareOption of compareOptions"
            [value]="compareOption.data"
          >
            Compare by: {{ compareOption.label }}
            <mat-icon *ngIf="compareOption.data === currentCompareOption"
              >check</mat-icon
            >
          </mat-option>
        </mat-select>
        <mat-icon class="custom-arrow">keyboard_arrow_down</mat-icon>
      </mat-form-field>
    </div>
  </ng-template>
  <mat-tab-group
    tab-less-tabs
    [selectedIndex]="viewModeIndex"
    class="main-container"
  >
    <!-- TABLES TAB -->
    <mat-tab label="Tables">
      <targets-bar
        [targetItems]="allTargets"
        [targetTooltipData]="targetTooltips"
        [selectedTargets]="selectedTargets"
        (targetSelected)="onTargetChange($event)"
        (multipleTargetsSelected)="onMultipleTargetSelect($event)"
        [isMultiSelect]="currentCompareOption === 'audiences'"
      >
      </targets-bar>

      <div class="container">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="planning-container"
        >
          <ng-container [ngSwitch]="currentCompareOption">
            <ng-container *ngSwitchCase="'media'">
              <compare-media
                #compareMediaTable
                [unitsText]="unitsText"
                [schedule]="scheduleInView"
                [target]="targetInView"
                (planUpdated)="onCompareMediaPlanUpdated($event)"
                (addNewSchedule)="onAddSchedule(true)"
                (changeActiveSchedule)="onScheduleChange($event)"
                (multiSurveyPopulationChanged)="onMultiSurveyPopulationChange()"
              ></compare-media>
            </ng-container>
            <ng-container *ngSwitchCase="'audiences'">
              <compare-audiences
                [unitsText]="unitsText"
                [currentSchedule]="scheduleInView"
                [selectedTargets]="selectedTargets"
              ></compare-audiences>
            </ng-container>
            <ng-container *ngSwitchCase="'plans'">
              <compare-plans
                #comparePlansTable
                [unitsText]="unitsText"
                [currentTarget]="targetInView"
                [selectedSchedules]="selectedSchedules"
                (scheduleTotalUpdated)="onScheduleTotalUpdated($event)"
              ></compare-plans>
            </ng-container>
            <ng-container *ngSwitchCase="'freqDistribution'">
              <compare-freq-distribution
                #compareFreqDistributionTable
                [unitsText]="unitsText"
                [currentTarget]="targetInView"
                [currentSchedule]="scheduleInView"
              ></compare-freq-distribution>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-tab>

    <!-- CHARTS TAB  -->
    <mat-tab label="Charts">
      <div
        class="charts-container"
        fxFlex="100%"
      >
        <planning-charts
          #planningCharts
          [unitsText]="unitsText"
          [chartsActiveIndex]="chartsActiveIndex"
        ></planning-charts>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div
  class="spinner-container-overlay"
  *ngIf="isLoading"
>
  <div class="center-spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
