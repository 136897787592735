import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { MediaPlannerService } from '../services/media-planner.service';

@Injectable({
  providedIn: 'root',
})
export class ChartsGuard implements CanActivate {
  steps: string[] = ['audience', 'media', 'planning', 'spots'];

  constructor(
    private router: Router,
    private mediaplannerService: MediaPlannerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const campaignStageIndex = this.steps.indexOf(
      this.mediaplannerService.plan.getCampaignStage()
    );

    if (campaignStageIndex === 2) {
      return true;
    } else {
      const currentUrl = route.pathFromRoot
        .map((v) => v.url.map((segment) => segment.toString()).join('/'))
        .join('/');
      if (currentUrl.includes('/new/chart')) {
        this.router.navigate(['/new/data'], {
          queryParams: {
            tab: campaignStageIndex,
          },
        });
      } else {
        const document = route.parent.params.document;
        this.router.navigate([`/edit/${document}/data`], {
          queryParams: {
            tab: campaignStageIndex,
          },
        });
      }
      return false;
    }
  }
}
