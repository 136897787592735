import { Injectable } from '@angular/core';
import {
  DocumentAudienceGroup,
  DocumentAudienceGroupItem,
  DocumentMediaGroupItem,
  SaveOwnCodesDialogDataModel,
  SaveOwnCodesTargetGroup,
  SaveOwnCodesType,
  TupAudienceGroupsSearchService,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import {
  Empty,
  TupDocument,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';
import { Observable, forkJoin, of } from 'rxjs';
import {
  TreeTableMenuItem,
  TreeTableNode,
} from '../components/tree-table/tree-table.models';
import { DocumentFullSurvey, DocumentSurvey } from '../models/document.model';
import { TupAuthService } from '@telmar-global/tup-auth';
import { MediaPlannerService } from './media-planner.service';

const OWN_CODE = 'Custom audiences';
const OWN_MEDIA = 'Custom media';

export interface AudienceGroupsResponse {
  ownCodes: TreeTableNode;
  ownMedia: TreeTableNode;
}

@Injectable({
  providedIn: 'root',
})
export class AudienceGroupsService {
  surveyCode: string;

  groupsBySurvey: {
    [surveyCode: string]: TupDocument<DocumentAudienceGroup>[];
  } = {};

  constructor(
    private _audienceGroupsService: TupAudienceGroupsService,
    private _audienceGroupsSearchService: TupAudienceGroupsSearchService,
    private userContainerService: TupUserContainerService,
    private mediaplannerService: MediaPlannerService
  ) {}

  // return an array of documents of the given surveycode
  private getAudienceGroups(
    surveyCode: string,
    forceReload: boolean
  ): Observable<TupDocument<DocumentAudienceGroup>[]> {
    const groupBySurvey = this.groupsBySurvey[surveyCode];

    return groupBySurvey && !forceReload
      ? of(groupBySurvey)
      : new Observable((ob) => {
          this.surveyCode = surveyCode;

          // collect the audience groups requests from each of the containers
          const containerRequests = [];

          // collate all the groups together before mapping to TupDocument<DocumentAudienceGroup>[]
          const groups: any[] = [];

          // get containers to work with
          this.userContainerService.containers.subscribe((containers) => {
            containers.forEach((container) => {
              containerRequests.push(
                this._audienceGroupsSearchService.search(container)
              );
            });

            // create a search within each container
            forkJoin(containerRequests).subscribe((groupsWithContainers) => {
              groupsWithContainers.forEach((singleContainer: any) => {
                groups.push(...singleContainer);
              });

              // We now have a groups array containing all groups from all containers

              const hits: TupDocument<DocumentAudienceGroup>[] = groups.map(
                (g) => {
                  // check the targets array
                  const targets: DocumentAudienceGroupItem[] =
                    g.content.type === SaveOwnCodesType.media
                      ? []
                      : g.content.targets.map((target) => {
                          return {
                            title: target.title,
                            coding: target.coding,
                            options: target.options || {
                              statement: target.jsonTarget,
                              target: null,
                            },
                          };
                        });

                  // check/build vehicles array
                  let hasVehicles = g.content.hasVehicles;
                  let vehicles: DocumentMediaGroupItem[] =
                    g.content.vehicles || [];
                  if (
                    g.content.type === SaveOwnCodesType.media &&
                    vehicles.length === 0
                  ) {
                    hasVehicles = true;
                    vehicles = g.content.targets.map((vehicle) => {
                      const jsonTarget = vehicle.jsonTarget
                        ? JSON.parse(vehicle.jsonTarget)
                        : {};
                      return {
                        title: vehicle.title,
                        id: vehicle.coding,
                        calculationMethod: jsonTarget.calculationMethod || '',
                        mediaType: jsonTarget.mediaType || '',
                        dayparts: [],
                      };
                    });
                  }

                  // final verified group to return
                  const group: TupDocument<DocumentAudienceGroup> = {
                    metadata: g.metadata,
                    content: {
                      survey: g.content.survey,
                      type: g.content.type,
                      hasVehicles,
                      vehicles,
                      targets,
                    },
                  };

                  return group;
                }
              ); // fixedGroups

              this.groupsBySurvey[surveyCode] = hits.filter(
                (group) => group.content?.survey.code === surveyCode
              );

              ob.next(this.groupsBySurvey[surveyCode]);
              ob.complete();
            }); // get audiences for the given containers
          }); // get  containers
        });
  }

  // format owncodes and own media into proper tree structures
  getAudienceGroupsForTree(
    surveyCode: string,
    forceReload: boolean
  ): Observable<AudienceGroupsResponse> {
    return new Observable((observable) => {
      // basics for an owncode TreeTableNode
      const getOwnCodeNode = (
        containerNames: string[],
        name: string,
        type: string
      ): TreeTableNode => {
        const node = {
          name,
          data: { key: name, category: name, ownCodes: true },
          children: containerNames.map((containerName) => {
            return { name: containerName, children: [] };
          }),
        };
        return node;
      };

      // build a dropdown menu for the tree withthe req. data
      const getOwnCodeMenu = (
        editData: string,
        renameData: string,
        deleteData: string
      ): TreeTableMenuItem[] => {
        const menu: TreeTableMenuItem[] = [];

        if (editData)
          menu.push({
            label: 'Rename title',
            matIcon: 'text_format',
            data: editData,
            class: 'material-symbols-outlined',
          });

        if (renameData)
          menu.push({
            label: 'Rename group',
            matIcon: 'text_format',
            data: renameData,
            class: 'material-symbols-outlined',
          });

        if (deleteData)
          menu.push({
            label: 'Delete group',
            matIcon: 'delete',
            data: deleteData,
            class: 'material-symbols-outlined',
          });

        return menu;
      };

      // CODING: helper function to turn own code targets[] into a TreeTableNode[]
      const getAudienceGroupTargets = (
        groupTargets: DocumentAudienceGroupItem[],
        parent: TreeTableNode,
        docId: string
      ): TreeTableNode[] => {
        return groupTargets.map((target, index) => {
          return {
            name: target.title,
            id: target.coding,
            menu: getOwnCodeMenu(`owncoderename_${docId}_${index}`, '', ''),
            checkbox: !!target.coding,
            data: {
              coding: target.coding,
              jsonCoding: target.options?.statement || null,
              ownCodes: true,
            },
          };
        });
      };

      // MEDIA: helper function to turn own code media[] into a TreeTableNode[]
      const getAudienceGroupMedia = (
        groupMedia: DocumentMediaGroupItem[],
        parent: TreeTableNode,
        docId: string
      ): TreeTableNode[] => {
        return groupMedia.map((media, index) => {
          const vehicle: DocumentMediaGroupItem = media;
          return {
            name: media.title,
            id: media.id,
            checkbox: !!media.id,
            data: { coding: media.id, jsonCoding: null, vehicle: vehicle },
          };
        });
      };

      // get a list of all the containers labels used in any group
      const getContainersFromGroups = (
        groups: TupDocument<DocumentAudienceGroup>[]
      ): string[] => {
        const containers = groups.map(
          (group) => group.metadata.container.label
        );
        return [...new Set(containers)];
      };

      const disableUnsedGroups = (node: TreeTableNode): void => {
        if (node.children) {
          node.children.forEach((containerNode) => {
            containerNode.disabled = !containerNode.children?.length;
          });
        }
      };

      // use the service to get the list, or use the cached list (depending on same surveycode)
      this.getAudienceGroups(surveyCode, forceReload).subscribe(
        (groups: TupDocument<DocumentAudienceGroup>[]) => {
          const containers = getContainersFromGroups(groups);
          const ownCodes = getOwnCodeNode(containers, OWN_CODE, 'Audiences');
          const ownMedia = getOwnCodeNode(containers, OWN_MEDIA, 'Media');

          groups.forEach((group, index) => {
            let containerIndex = 0;
            // add to own media list
            if (group.content.type === SaveOwnCodesType.media) {
              containerIndex = containers.indexOf(
                group.metadata.container.label
              );
              ownMedia.children[containerIndex].children.push({
                name: group.metadata.name,
                menu: getOwnCodeMenu(
                  '',
                  `owncoderenamegroup_${group.metadata.id}`,
                  `owncodedelete_${group.metadata.id}_${-1}`
                ),
                children: getAudienceGroupMedia(
                  group.content.vehicles,
                  ownMedia.children[containerIndex],
                  group.metadata.id
                ),
              });
            }

            // add to own codes list
            if (group.content.type === SaveOwnCodesType.audience) {
              containerIndex = containers.indexOf(
                group.metadata.container.label
              );
              ownCodes.children[containerIndex].children.push({
                name: group.metadata.name,
                data: { ownCodes: true },
                menu: getOwnCodeMenu(
                  '',
                  `owncoderenamegroup_${group.metadata.id}`,
                  `owncodedelete_${group.metadata.id}_${-1}`
                ),
                children: getAudienceGroupTargets(
                  group.content.targets,
                  ownCodes.children[containerIndex],
                  group.metadata.id
                ),
              });
            }
          });

          disableUnsedGroups(ownCodes);
          disableUnsedGroups(ownMedia);
          observable.next({ ownCodes, ownMedia });
          observable.complete();
        },
        (err) => {
          observable.next(null);
          observable.complete();
        }
      );
    });
  }

  saveOwnCodes(
    survey: DocumentFullSurvey,
    targetGroups: SaveOwnCodesTargetGroup[],
    type: SaveOwnCodesType
  ): Observable<TupDocument<DocumentAudienceGroup> | Empty> {
    const data: SaveOwnCodesDialogDataModel = {
      survey,
      type,
      targetGroups,
      fileName: '',
    };
    return this._audienceGroupsService.saveOwnCodes(data);
  }

  getAllGroups(): TupDocument<DocumentAudienceGroup>[] {
    const groups = [];
    Object.keys(this.groupsBySurvey).forEach((group) => groups.push(group));
    return groups;
  }

  editOwnCodesTargetTitle(
    documentId: string,
    targetIndex: number
  ): Observable<TupDocument<DocumentAudienceGroup>> {
    const doc = this.getAllGroups().find(
      (group) => group.metadata.id === documentId
    );
    return this._audienceGroupsService.editOwnCodesTargetTitle(
      doc,
      targetIndex
    );
  }

  deleteOwnCodesWithConfirmation(documentId: string): Observable<Empty> {
    const doc = this.getAllGroups().find(
      (group) => group.metadata.id === documentId
    );
    return doc
      ? this._audienceGroupsService.deleteOwnCodesWithConfirmation(doc)
      : of();
  }

  renameOwnCodeGroup(documentId: string): Observable<Empty> {
    const doc = this.getAllGroups().find(
      (group) => group.metadata.id === documentId
    );
    return this._audienceGroupsService.renameOwnCodesDocument(doc);
  }
}
