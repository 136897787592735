import { SelectMenuOption } from './application.model';
import * as tot from 'src/app/models/planning-tot-columns.models';
import * as freqDistrTot from 'src/app/models/planning-freq-dist-columns.model';

export enum ChartType {
  DEFAULT = 'default',
  FREQ_DISTR = 'freq-distr',
}
export interface SelectMenuOptionChart<T> {
  title: string;
  value: T;
  graphType: string | boolean;
  percentBarChart?: boolean;
  waterfall?: boolean;
  areaChart?: boolean;
  pyramidChart?: boolean;
  aspectRatio?: number;
  showAllDatasetLabels?: boolean;
}

export interface ChartTarget {
  id: string;
  title: string;
  visible: boolean;
}

export interface PlanningState {
  currentSchedule: number;
  currentTarget: number;
  currentMediaType: string;
  vehicleCountChanged?: boolean;
  scheduleCountChanged?: boolean;
  targetCountchanged?: boolean;
}

// multiple chart types removed: https://splashlight.atlassian.net/browse/CMP-416
export const CHART_TYPES: SelectMenuOptionChart<GraphSelectionValue>[] = [
  {
    title: 'Bar Chart (Horizontal)',
    value: 'tupHorizontalBar',
    graphType: 'tupHorizontalBar',
  },
  {
    title: 'Bar Chart (Vertical)',
    value: 'tupVerticalBar',
    graphType: 'tupVerticalBar',
  },
  // {
  //   title: 'Stacked Bar Chart (Horizontal)',
  //   value: 'tupStackedHorizontalBar',
  //   graphType: 'tupStackedHorizontalBar',
  // },
  // {
  //   title: 'Stacked Horizontal Bar Charts (100%)',
  //   value: 'tupPercentageHorizontal',
  //   graphType: 'tupStackedHorizontalBar',
  //   percentBarChart: true,
  // },
  {
    title: 'Stacked Bar Chart (Vertical)',
    value: 'tupStackedVerticalBar',
    graphType: 'tupStackedVerticalBar',
  },
  // {
  //   title: 'Stacked Vertical Bar Charts (100%)',
  //   value: 'tupPercentageVertical',
  //   graphType: 'tupStackedVerticalBar',
  //   percentBarChart: true,
  // },
  // {
  //   title: 'Waterfall',
  //   value: 'tupWaterfall',
  //   graphType: 'tupVerticalBar',
  //   waterfall: true,
  // },
  {
    title: 'Line Chart',
    value: 'tupLine',
    graphType: 'tupLine',
  },
  {
    title: 'Pie Chart',
    value: 'tupPie',
    graphType: 'tupPie',
    aspectRatio: 1,
  },
  {
    title: 'Donut Chart',
    value: 'tupDonut',
    graphType: 'tupDonut',
    aspectRatio: 1,
  },
  // {
  //   title: 'Multi-Series Donut Chart',
  //   value: 'tupMultiSeriesDonut',
  //   graphType: 'tupMultiSeriesDonut',
  //   aspectRatio: 1,
  //   showAllDatasetLabels: true,
  // },
  // {
  //   title: 'Radar Chart',
  //   value: 'tupRadar',
  //   graphType: 'tupRadar',
  //   aspectRatio: 1,
  // },
  // {
  //   title: 'Filled Radar Chart',
  //   value: 'tupFilledRadar',
  //   graphType: 'tupFilledRadar',
  //   aspectRatio: 1,
  // },
  // {
  //   title: 'Radar Column Chart',
  //   value: 'tupPolar',
  //   graphType: 'tupPolar',
  //   aspectRatio: 1,
  // },
  // {
  //   title: 'Scatter Chart',
  //   value: 'tupScatter',
  //   graphType: 'tupScatter',
  // },
  // {
  //   title: 'Area Chart',
  //   value: 'tupArea',
  //   graphType: 'tupLine',
  //   areaChart: true,
  // },
  // {
  //   title: 'Treemap',
  //   value: 'tupTreemap',
  //   graphType: 'tupTreemap',
  // },
  // {
  //   title: 'WordCloud',
  //   value: 'tupWordcloud',
  //   graphType: 'tupWordcloud',
  //   aspectRatio: 1,
  // },
  // {
  //   title: 'Funnel',
  //   value: 'tupFunnel',
  //   graphType: 'funnel',
  // },
  // {
  //   title: 'Pyramid Chart',
  //   value: 'tupPyramid',
  //   graphType: 'funnel',
  //   pyramidChart: true,
  // },
  // {
  //   title: 'Dot Chart',
  //   value: 'tupDot',
  //   graphType: 'tupDot',
  // },
];
// multiple chart types removed: https://splashlight.atlassian.net/browse/CMP-416
export const CHART_TYPES_FREQ_DISTR: SelectMenuOptionChart<GraphSelectionValue>[] =
  [
    {
      title: 'Line Chart',
      value: 'tupLine',
      graphType: 'tupLine',
    },
    {
      title: 'Bar Chart (Vertical)',
      value: 'tupVerticalBar',
      graphType: 'tupVerticalBar',
    },
  ];

export const SECONDARY_CHART_TYPES: SelectMenuOptionChart<GraphSelectionValue>[] =
  [
    {
      title: 'None',
      value: 'None',
      graphType: false,
    },
    {
      title: 'Area Chart',
      value: 'tupArea',
      graphType: 'tupLine',
      areaChart: true,
    },
    {
      title: 'Line Chart',
      value: 'tupLine',
      graphType: 'tupLine',
    },
    {
      title: 'Dot Chart',
      value: 'tupDot',
      graphType: 'tupDot',
    },
  ];
export const SECONDARY_CHART_TYPES_FREQ_DISTR: SelectMenuOptionChart<GraphSelectionValue>[] =
  [
    {
      title: 'None',
      value: 'None',
      graphType: false,
    },
    {
      title: 'Line Chart',
      value: 'tupLine',
      graphType: 'tupLine',
    },
  ];

export const chartsUnsuitableForMultipleDatasets: GraphSelectionValue[] = [
  'tupPie',
  'tupDonut',
  'tupTreemap',
  'tupWordcloud',
  'tupFunnel',
  'tupPyramid',
  'None',
];

export const unsuitableChartMixed: GraphSelectionValue[] = [
  'tupPie',
  'tupDonut',
  'tupMultiSeriesDonut',
  'tupRadar',
  'tupFilledRadar',
  'tupPolar',
  'tupTreemap',
  'tupWordcloud',
  'tupFunnel',
  'tupPyramid',
];

export const chartsRequireShadedColorsForMultipleDatasets: GraphSelectionValue[] =
  ['tupMultiSeriesDonut', 'tupPolar'];

export const chartsInSingleRowForMultipleDatasets: GraphSelectionValue[] = [
  'tupPie',
  'tupDonut',
];

export type GraphSelectionValue =
  | 'None'
  | 'tupVerticalBar'
  | 'tupHorizontalBar'
  | 'tupStackedHorizontalBar'
  | 'tupPercentageVertical'
  | 'tupPercentageHorizontal'
  | 'tupWaterfall'
  | 'tupStackedVerticalBar'
  | 'tupLine'
  | 'tupPie'
  | 'tupDonut'
  | 'tupMultiSeriesDonut'
  | 'tupRadar'
  | 'tupFilledRadar'
  | 'tupPolar'
  | 'tupScatter'
  | 'tupArea'
  | 'tupTreemap'
  | 'tupWordcloud'
  | 'tupFunnel'
  | 'tupPyramid'
  | 'tupDot';

// chart data items built from all the columns
export const CHART_DATA_ITEMS: SelectMenuOption<string>[] =
  tot.allChartableColumns.map((column) => {
    return { title: column.header, value: column.columnDef };
  });

// data lookup table
export const GRAPH_DATA_KEY_MAP: Record<string, string> = {};
CHART_DATA_ITEMS.forEach((item) => {
  GRAPH_DATA_KEY_MAP[item.value] = item.title;
});

// chart data items built from all the columns
export const CHART_DATA_ITEMS_FREQ_DISTR: SelectMenuOption<string>[] =
  freqDistrTot.allFreqDistrChartTableColumns.map((column) => {
    return { title: column.header, value: column.columnDef };
  });

// data lookup table
export const GRAPH_DATA_KEY_MAP_FREQ_DISTR: Record<string, string> = {};
CHART_DATA_ITEMS_FREQ_DISTR.forEach((item) => {
  GRAPH_DATA_KEY_MAP_FREQ_DISTR[item.value] = item.title;
});

export enum ChartTargetMode {
  single = 'SINGLE',
  combined = 'COMBINED',
}

export type TargetMode = ChartTargetMode.single | ChartTargetMode.combined;

export const TARGET_MODES: SelectMenuOption<TargetMode>[] = [
  {
    title: 'Combined targets',
    value: ChartTargetMode.combined,
  },
  {
    title: 'Single target',
    value: ChartTargetMode.single,
  },
];

export const ChartColors = [
  '#0054FF',
  '#FC66B4',
  '#FF8B34',
  '#DD7DF9',
  '#FFDE54',
  '#62F0CC',
  '#F65354',
  '#D7AA0B',
  '#CC5800',
  '#A5D40B',
  '#FFA300',
  '#1898B5',
  '#3BEC13',
  '#C114F5',
  '#F55714',
  '#00DBEB',
  '#BA9018',
  '#BD7800',
  '#00BCEB',
  '#FF326B',
  '#7559FF',
  '#C29B31',
];

export const HighlightColors = [
  '#0054FF',
  '#FC66B4',
  '#FF8B34',
  '#FFDE54',
  '#62F0CC',
  '#F65354',
  '#D7AA0B',
  '#CC5800',
  '#A5D40B',
  '#00BCEB',
  '#FF326B',
  '#7559FF',
];

export type SortColumn = 'Primary' | 'Secondary' | string;

export enum ColumnSortOrder {
  ascending = 'Ascending',
  descending = 'Descending',
  inputOrder = 'Input Order',
}

export enum ChartFilterOperator {
  none = 'None',
  equal = '=',
  notEqual = '!=',
  greaterThan = '>',
  greaterThanOrEqual = '>=',
  lessThan = '<',
  lessThanOrEqual = '<=',
  between = 'between',
}

export const DEFAULT_TOP_ROWS_COUNT = null;
export const OPTIMAL_TOP_ROWS_COUNT = 24;
export const GLOBAL_SETTINGS_KEY = 'global';
export const COMBINED_SETTINGS_KEY = 'All targets';

export interface ChartFilter {
  target: 'None' | string;
  dataItem: string;
  operator: ChartFilterOperator;
  value: number[];
}

export interface ChartSettings {
  id: string;

  chartDataKeys: Record<string, string>;
  chartDataItems: SelectMenuOption<string>[];

  title: string;
  subTitle: string;
  footer?: string;
  surveySource?: string;
  copyright?: string;

  targetSelection: ChartTarget[];
  showTargetSelection: boolean;
  allowSeriesColorEdit: boolean;
  editedTargetSelection: boolean;

  showFooter: boolean;

  primaryDataItem: string;
  primaryChartType: GraphSelectionValue;
  secondaryDataItem: string;
  secondaryChartType: GraphSelectionValue;
  numberOfChartInAxisLabel: number;
  topRowsCount: number;
  maxRowsCount?: number;
  axisLabelAngle: number;
  sortColumn: SortColumn;
  columnSortOrder: ColumnSortOrder;
  showChartLabel: boolean;
  flagRowResps: boolean;
  flagRowRespsValue: number;
  showDataTable: boolean;
  hideChartLegend: boolean;
  decimalPlaces: number;
  swapData: boolean;
  filters: ChartFilter[];
  seriesColor: Record<string, string>;
}

export const DEFAULT_CHART_SETTINGS: ChartSettings = {
  chartDataKeys: GRAPH_DATA_KEY_MAP,
  chartDataItems: CHART_DATA_ITEMS,
  id: GLOBAL_SETTINGS_KEY,
  targetSelection: [],
  showTargetSelection: false,
  editedTargetSelection: false,
  allowSeriesColorEdit: true,
  primaryDataItem: 'impressions',
  primaryChartType: CHART_TYPES[0].value,
  secondaryDataItem: 'None',
  secondaryChartType: SECONDARY_CHART_TYPES[0].value,
  title: '',
  subTitle: '',
  showFooter: true,
  numberOfChartInAxisLabel: 45,
  topRowsCount: DEFAULT_TOP_ROWS_COUNT,
  axisLabelAngle: 90,
  sortColumn: 'Primary',
  columnSortOrder: ColumnSortOrder.descending,
  showChartLabel: true,
  flagRowResps: false,
  flagRowRespsValue: 50,
  showDataTable: false,
  hideChartLegend: false,
  decimalPlaces: 2,
  maxRowsCount: 100,
  swapData: false,
  filters: [
    {
      target: 'None',
      dataItem: 'None',
      operator: ChartFilterOperator.none,
      value: [0, 0],
    },
    {
      target: 'None',
      dataItem: 'None',
      operator: ChartFilterOperator.none,
      value: [0, 0],
    },
  ],
  seriesColor: {},
};
export const DEFAULT_CHART_SETTINGS_FREQ_DISTR: ChartSettings = {
  chartDataKeys: GRAPH_DATA_KEY_MAP_FREQ_DISTR,
  chartDataItems: CHART_DATA_ITEMS_FREQ_DISTR,
  id: GLOBAL_SETTINGS_KEY,
  targetSelection: [],
  showTargetSelection: false,
  editedTargetSelection: false,
  allowSeriesColorEdit: true,
  primaryDataItem: 'impressions',
  primaryChartType: CHART_TYPES_FREQ_DISTR[0].value,
  secondaryDataItem: 'None',
  secondaryChartType: SECONDARY_CHART_TYPES_FREQ_DISTR[0].value,
  title: '',
  subTitle: '',
  showFooter: true,
  numberOfChartInAxisLabel: 45,
  topRowsCount: DEFAULT_TOP_ROWS_COUNT,
  axisLabelAngle: 90,
  sortColumn: 'Primary',
  columnSortOrder: ColumnSortOrder.inputOrder,
  showChartLabel: true,
  flagRowResps: false,
  flagRowRespsValue: 50,
  showDataTable: false,
  hideChartLegend: false,
  decimalPlaces: 2,
  maxRowsCount: 100,
  swapData: false,
  filters: [
    {
      target: 'None',
      dataItem: 'None',
      operator: ChartFilterOperator.none,
      value: [0, 0],
    },
    {
      target: 'None',
      dataItem: 'None',
      operator: ChartFilterOperator.none,
      value: [0, 0],
    },
  ],
  seriesColor: {},
};

export enum ChartSettingsMode {
  global = 0,
  single = 1,
  combined = 2,
}

export interface ChartColor {
  title: string;
  colors: {
    color: string;
    isSelected: boolean;
  }[];
  targetId: string;
}

export const NO_SECONDARY_CHART_TYPES: GraphSelectionValue[] = [
  'tupPie',
  'tupRadar',
  'tupPolar',
  'tupFilledRadar',
  'tupTreemap',
  'tupFunnel',
  'tupPyramid',
  'tupDonut',
  'tupWordcloud',
  'tupScatter',
];

export const NO_AXIS_LABEL_CHART_TYPES: GraphSelectionValue[] = [
  'tupPie',
  'tupDonut',
  'tupMultiSeriesDonut',
  'tupPolar',
  'tupTreemap',
  'tupFunnel',
  'tupPyramid',
  'tupScatter',
];

export const COLUMN_SORT_ORDERS: ColumnSortOrder[] = [
  ColumnSortOrder.ascending,
  ColumnSortOrder.descending,
  ColumnSortOrder.inputOrder,
];

export const FILTER_OPERATORS: ChartFilterOperator[] = [
  ChartFilterOperator.none,
  ChartFilterOperator.equal,
  ChartFilterOperator.notEqual,
  ChartFilterOperator.greaterThanOrEqual,
  ChartFilterOperator.greaterThan,
  ChartFilterOperator.lessThanOrEqual,
  ChartFilterOperator.lessThan,
  ChartFilterOperator.between,
];
