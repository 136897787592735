import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.scss'],
})
export class ProcessingComponent implements OnInit {
  @Input() diameter: number = 17;
  constructor() {}

  ngOnInit(): void {}
}
