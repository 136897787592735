import {
  DocumentFullSurvey,
  DocumentSurvey,
  DocumentTarget,
} from '../models/document.model';
import { cloneDeep } from 'lodash';
import uniqid from 'uniqid';
import { TreeTableNode } from '../components/tree-table/tree-table.models';
import { TargetStatement } from '../models/engine.models';
import { TargetDaypart, TargetVehicle } from '../classes/vehicle';
import { MediaDaypart, MediaVehicle } from '../models/codebook.models';
import { CodebookStatement } from '../components/codebook-table/codebook-table.component';
import { CountCodingModel } from '../components/dialogs/count-coding-dialog/count-coding-dialog.component';
import {
  Operator,
  VisualCodingTarget,
} from '../models/visual-code-builder.models';
import { Survey } from '@telmar-global/tup-audience-groups';

export class ConverterService {
  constructor() {}

  /**
   * Builds a VisualBuilderTarget object from a DocumentTarget input.
   *
   * @param target DocumentTarget input
   * @returns VisualBuilderTarget for use with the visual codebook editor component
   */
  static buildVisualCodebookTarget(target: DocumentTarget): VisualCodingTarget {
    const visualTarget: VisualCodingTarget = {
      fileVersion: 1,
      audience: 0,
      percent: 0,
      resps: 0,
      manual: target.manual,
      created: new Date().getTime(),
      coding: target.coding || '',
      title: target.title || '',
      ownTitle: target.ownTitle || '',
      shortTitle: target.shortTitle || '',
      operator: target.operator,
      targets: [],
      id: uniqid(),
    };

    // if there are further targets to encode
    if (target.targets) {
      if (target.targets.length === 1 && !target.targets[0]?.targets) {
        visualTarget.targets.push(cloneDeep(visualTarget));
      } else {
        visualTarget.targets = target.targets
          ? target.targets.map((childTarget) =>
              this.buildVisualCodebookTarget(childTarget)
            )
          : [];
      }
    }

    return visualTarget;
  }

  /**
   * Convert a visual target into a DocumentTarget, including all child targets[]
   *
   * @param visualTarget Visual target to convert
   * @param survey Survey associated with the coding statements
   * @returns DocumentTarget containing representation of Visual Target
   */

  static buildDocumentTargetFromVisualCodebook(
    visualTarget: VisualCodingTarget,
    survey: DocumentSurvey,
    planningTarget: boolean,
    addressableTarget: boolean
  ): DocumentTarget {
    const returnTarget: DocumentTarget = {
      survey,
      title: visualTarget.ownTitle || visualTarget.title,
      ownTitle: visualTarget.ownTitle,
      shortTitle: visualTarget.shortTitle,
      manual: visualTarget.manual,
      coding: visualTarget.coding,
      jsonCoding: null,
      planningTarget,
      addressableTarget,
      operator: visualTarget.operator,
      targets: visualTarget.targets.map((tgt) =>
        this.buildDocumentTargetFromVisualCodebook(
          tgt,
          survey,
          planningTarget,
          addressableTarget
        )
      ),
    };

    return returnTarget;
  }

  /**
   * Take the passed in nodes and the operator and output a valid DocumentTarget
   *
   * @param selectedNodes Array of selected nodes from the coding tree to be encoded into targets and jsonCoding objects
   * @param survey Survey associated with the coding statements
   * @param booleanOperator The boolean operator that should be applied between the nodes
   * @returns DocumentTarget containing representation of selected nodes, ready to fetch audience from
   */
  static buildDocumentTargetByBooleanOperator(
    selectedNodes: TreeTableNode[],
    survey: DocumentSurvey,
    booleanOperator: Operator,
    planningTarget: boolean,
    addressableTarget: boolean,
    countCoding: CountCodingModel
  ): DocumentTarget {
    const returnTarget: DocumentTarget = {
      title: '',
      ownTitle: '',
      coding: '',
      survey,
      targets: [],
      jsonCoding: null,
      planningTarget,
      addressableTarget,
    };

    selectedNodes.forEach((node, index) => {
      const json =
        typeof node.data.jsonCoding === 'string'
          ? JSON.parse(node.data.jsonCoding)
          : node.data.jsonCoding;
      returnTarget.coding +=
        index < selectedNodes.length - 1
          ? `${node.data.coding} ${booleanOperator.toUpperCase()} `
          : `${node.data.coding}`;
      const title = node.data?.ownCodes
        ? node.name
        : node.name + (node.parent ? ` ~ ${node.parent.name}` : '');
      returnTarget.title +=
        index < selectedNodes.length - 1
          ? `${title} ${booleanOperator.toUpperCase()} `
          : title;
      returnTarget.ownTitle +=
        index < selectedNodes.length - 1
          ? `${title} ${booleanOperator.toUpperCase()} `
          : title;
      returnTarget.targets.push({
        title,
        ownTitle: title,
        coding: node.data.coding,
        jsonCoding: json,
        operator: booleanOperator,
      });
    });
    return returnTarget;
  }

  /**
   * Take the passed in selected rows and the operator and output a valid DocumentTarget
   *
   * @param selectedRows Array of selected rows, each row containing nodes encoded into targets and jsonCoding objects
   * @param survey Survey associated with the coding statements
   * @param booleanOperator The boolean operator that should be applied between the rows
   * @returns DocumentTarget containing representation of selected rows, ready to fetch audience from
   */
  static buildCombinedRowsDocumentTarget(
    selectedRows: CodebookStatement[],
    survey: DocumentSurvey,
    booleanOperator: Operator
  ): DocumentTarget {
    const returnTarget: DocumentTarget = {
      title: '',
      ownTitle: '',
      coding: '',
      survey,
      targets: [],
      jsonCoding: null,
      addressableTarget: !!selectedRows.find((row) => row.addressableTarget),
      planningTarget: !!selectedRows.find((row) => row.planningTarget),
    };

    //build the Document Target for the evaluation
    selectedRows.forEach((row, index) => {
      returnTarget.coding +=
        index < selectedRows.length - 1
          ? `${row.target.coding} ${booleanOperator} `
          : row.target.coding;
      returnTarget.title +=
        index < selectedRows.length - 1
          ? `${row.target.title} ${booleanOperator.toUpperCase()} `
          : row.target.title;

      returnTarget.ownTitle +=
        index < selectedRows.length - 1
          ? `${row.target.title} ${booleanOperator.toUpperCase()} `
          : row.target.title;

      //build the targets array for the Document Target
      if (row.target.targets.length === 1) {
        const json =
          typeof row.target.jsonCoding === 'string'
            ? JSON.parse(row.target.jsonCoding)
            : row.target.jsonCoding;
        returnTarget.targets.push({
          title: row.target.title,
          ownTitle: row.target.title,
          coding: row.target.jsonCoding?.Operands[0]?.Mne || row.target.coding,
          jsonCoding: json,
          operator: booleanOperator,
        });
      } else {
        returnTarget.targets.push(...row.target.targets);
      }
    });
    return returnTarget;
  }

  static buildDocumentTargetFromExploreAudiences(
    exploreTarget,
    survey: DocumentSurvey
  ): DocumentTarget {
    const returnTarget: DocumentTarget = {
      title: exploreTarget.title,
      ownTitle: exploreTarget.ownTitle,
      coding: exploreTarget.coding,
      survey,
      planningTarget: true,
      addressableTarget: false,
      targets: [],
      jsonCoding: null,
      operator: Operator.and,
      population: -1,
      sample: -1,
    };

    exploreTarget.targets.forEach((target) => {
      const targetGroup: DocumentTarget = {
        targets: [],
        operator:
          Operator[target.operator.toLowerCase() as keyof typeof Operator],
      };

      // loop within each group target
      target.targets.forEach((tgt) => {
        let operator =
          Operator[tgt.operator.toLowerCase() as keyof typeof Operator];
        if (target.countCoding) {
          operator = tgt.operator;
        }
        const title = tgt.ownTitle;
        targetGroup.targets.push({
          title,
          ownTitle: title,
          coding: tgt.coding,
          jsonCoding: null,
          operator,
          targets: [],
          survey: survey,
          population: -1,
          sample: -1,
        });
      });
      targetGroup.title = target.title;
      targetGroup.ownTitle = target.ownTitle;
      targetGroup.coding = target.coding;
      targetGroup.jsonCoding = null;
      targetGroup.survey = survey;
      targetGroup.population = -1;
      targetGroup.sample = -1;

      if (target.targets.length === 1) {
        returnTarget.targets.push(...targetGroup.targets);
      } else {
        returnTarget.targets.push(targetGroup);
      }

      // if target countCoding exists add the value as target
      if (target.countCoding) {
        returnTarget.targets[returnTarget.targets.length - 1].operator =
          target.countCoding.operator;
        returnTarget.targets.push({
          title: target.countCoding.value,
          ownTitle: target.countCoding.value,
          coding: target.countCoding.value,
          jsonCoding: null,
          operator: Operator.and,
        });
      }
    });

    if (exploreTarget.targets.length === 0) {
      returnTarget.targets.push({
        title: exploreTarget.title,
        ownTitle: exploreTarget.ownTitle,
        coding: exploreTarget.coding,
        jsonCoding: null,
        operator:
          Operator[
            exploreTarget.operator.toLowerCase() as keyof typeof Operator
          ],
        targets: [],
        survey: survey,
        population: -1,
        sample: -1,
      });
    }

    // if there are at least 2 targets we will have multiple groups
    if (exploreTarget.targets.length) {
      return { ...returnTarget, targets: [returnTarget] };
    }

    // if there is only one target there will be one group
    return returnTarget;
  }

  /**
   * take the passed in nodes and output a valid DocumentTarget
   * Will apply the AND and OR operators automatically based on the parent of each node
   *
   * @param selectedNodes Array of selected nodes from the coding tree to be encoded into targets and jsonCoding objects
   * @param survey Survey associated with the coding statements
   * @returns DocumentTarget containing representation of selected nodes, ready to fetch audience from
   */
  static buildDocumentTarget(
    selectedNodes: TreeTableNode[],
    survey: DocumentSurvey,
    planningTarget: boolean,
    addressableTarget: boolean
  ): DocumentTarget {
    const returnTarget: DocumentTarget = {
      title: '',
      ownTitle: '',
      coding: '',
      survey,
      planningTarget,
      addressableTarget,
      targets: [],
      jsonCoding: null,
      operator: Operator.and,
    };

    const returnJsonCoding: TargetStatement = {
      Operands: [],
      Operator: Operator.and,
    };

    // determine if any jsonCoding is missing (mg's from ST).  return jsonCoding:null if so, so it can be built during target evaluation.
    let jsonCodingMissing: boolean = false;

    // separate into groups (based on parent name)
    let groups: { [key: string]: TreeTableNode[] } = {};

    selectedNodes.forEach((node) => {
      groups[node.parent.name] = groups[node.parent.name] || [];
      groups[node.parent.name].push(node);
    });

    // build the jsonCoding and DocumentTarget objects from the groups, for evaluation
    const groupKeys = Object.keys(groups);
    groupKeys.forEach((group, index) => {
      const jsonGroup: TargetStatement = {
        Operands: [],
        Operator: Operator.or,
      };
      const targetGroup: DocumentTarget = {
        targets: [],
        operator: Operator.or,
      };
      const groupTitle: string[] = [];
      const groupCoding: string[] = [];

      // loop within each group and fetch coding
      groups[group].forEach((node) => {
        jsonCodingMissing =
          jsonCodingMissing ||
          node.data.jsonCoding === null ||
          node.data.jsonCoding === undefined;

        const json =
          typeof node.data.jsonCoding === 'string'
            ? JSON.parse(node.data.jsonCoding)
            : node.data.jsonCoding;
        jsonGroup.Operands.push(json);
        jsonGroup.Operator = Operator.or;

        const title = node.data?.ownCodes
          ? node.name
          : node.name + (node.parent ? ` ~ ${node.parent.name}` : '');
        targetGroup.targets.push({
          title,
          ownTitle: title,
          coding: node.data.coding,
          jsonCoding: json,
          operator: Operator.or,
        });
        groupTitle.push(title);
        groupCoding.push(node.data.coding);
      });

      // add all the targets, or just one if there's only one in the group
      if (targetGroup.targets.length)
        returnTarget.targets.push(...targetGroup.targets);
      else returnTarget.targets.push(targetGroup);

      returnJsonCoding.Operands.push(jsonGroup);
      returnTarget.title +=
        groupTitle.length > 1
          ? ` (${groupTitle.join(' OR ')}) `
          : ` ${groupTitle.join(' OR ')} `;
      returnTarget.coding += `(${groupCoding.join(' OR ')})`;

      // add AND if there's more to come
      if (index < groupKeys.length - 1) {
        returnTarget.title += ' AND ';
        returnTarget.coding += ' AND ';
      }
    });

    // trim first opening and closing brackets
    if (returnTarget.title.length) {
      const start = returnTarget.title[0] == '(' ? 1 : 0;
      const end =
        returnTarget.title.charAt(returnTarget.title.length - 1) == ')'
          ? returnTarget.title.length - 1
          : returnTarget.title.length;
      returnTarget.title = returnTarget.title.substring(start, end).trim();
      returnTarget.ownTitle = returnTarget.title;
    }

    // move jsonCoding to top level if there's only one target
    if (jsonCodingMissing) returnTarget.jsonCoding = null;
    else {
      returnTarget.jsonCoding =
        returnJsonCoding.Operands.length == 1
          ? returnJsonCoding.Operands[0]
          : returnJsonCoding;
    }

    return returnTarget;
  }

  static buildDocumentTargetFromMultiSurveyAudience(
    title: string,
    multiSurveyPop: number,
    multiSurveyScale: number,
    survey: DocumentSurvey
  ): DocumentTarget {
    if (survey.units !== multiSurveyScale) {
      multiSurveyPop = multiSurveyPop * (multiSurveyScale / survey.units);
    }

    // const coding = `(#${population / survey.population})`;
    return {
      title,
      // coding,
      survey,
      population: multiSurveyPop,
      sample: -1,
      planningTarget: true,
      addressableTarget: false,
      targets: [],
      jsonCoding: null,
      operator: Operator.and,
      isMultiSurvey: true,
      customPopulation: multiSurveyPop,
    };
  }

  // convert the given MediaVehicle to a TargetVehicle
  static copyMediaVehicle = (mediaVehicle: MediaVehicle): TargetVehicle => {
    return {
      id: '',
      mnemonic: '',
      dayparts: mediaVehicle.dayparts
        ? (ConverterService.copyDayparts(
            mediaVehicle.dayparts
          ) as TargetDaypart[])
        : [],
      mediaType: mediaVehicle.mediaType,
      mediaTypeId: mediaVehicle.mediaTypeId,
      addressable: mediaVehicle.addressable,
      addressableConfig: mediaVehicle.addressableConfig,
      calculationMethod: mediaVehicle.calculationMethod,
      survey: mediaVehicle.survey,
      isMediaGroup: mediaVehicle.isMediaGroup,
      ESG: mediaVehicle.ESG,
      title: mediaVehicle.title,
      originalTitle: mediaVehicle.title,
      compositionIndex: 0,
      compositionPct: 0,
      audience: 0,
      grossAudience: 0,
      potentialReach: 0,
      resps: 0,
    };
  };

  static copyDayparts = (
    dayparts: TargetDaypart[] | MediaDaypart[]
  ): MediaDaypart[] | TargetDaypart[] => {
    return dayparts
      ? (dayparts as TargetDaypart[]).map((dp) => {
          return {
            id: dp.id,
            mnemonic: dp.mnemonic,
            title: dp.title,
            startDay: dp.startDay,
            startTime: dp.startTime,
            endDay: dp.endDay,
            endTime: dp.endTime,
            audience: -1,
            resps: -1,
          };
        })
      : [];
  };

  /**
   * convert a DocumentSurvey (used internally) to a Survey (ST tup-audience library)
   * Survey object saved to recent survey list (so compatible with tup-audience library)
   * @param documentSurvey DocumentSurvey object to copy
   * @returns Survey populated with the minimum info retained
   */
  static getSurvey = (documentSurvey: DocumentFullSurvey): Survey => {
    return {
      code: documentSurvey.code,
      title: documentSurvey.title,
      provider: documentSurvey.provider,
      language: documentSurvey.language,
      isMultibased: documentSurvey.isMultibased,
      year: documentSurvey.year,
      isTrendable: false,
      isMb3Enabled: documentSurvey.isMb3Enabled,
      isMappable: documentSurvey.isMappable,
      hasVehicles: documentSurvey.hasVehicles,
      authorizationGroup: documentSurvey.authorizationGroup,
    };
  };
}
