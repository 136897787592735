import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BaseStepComponent } from '../base-step/base-step.component';
import { ViewMode } from 'src/app/components/tab-stepper/tab-stepper.component';
import { SurveyMetrics, compareOptions } from 'src/app/models/planning.models';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { Target } from 'src/app/classes/target';
import { ScheduleView } from '../planning-step/planning-step.component';
import { Schedule } from 'src/app/classes/schedule';
import { MatTabNav } from '@angular/material/tabs';
import { MatSelectChange } from '@angular/material/select';
import { ScheduleTotalTag } from 'src/app/classes/schedule-total';
import { TreeTableComponent } from 'src/app/components/tree-table/tree-table.component';
import {
  ColumnMenuClickEvent,
  NodeMenuClickEvent,
  TreeTableColumn,
  TreeTableEditEvent,
  TreeTableMenuItem,
  TreeTableNode,
} from 'src/app/components/tree-table/tree-table.models';
import { ColumnGroup } from 'src/app/components/tree-table/column-group/table-group.models';
import { Observable, forkJoin, of } from 'rxjs';
import {
  PlanningService,
  ProcessInputResponse,
} from 'src/app/services/planning.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ScheduleVehicle, daysOfTheWeek } from 'src/app/classes/vehicle';
import { EngineService } from 'src/app/services/engine.service';
import { EvaluateMediaPlanResponse } from 'src/app/models/engine.evaluate-mediaplan.models';
import * as Spot_Columns from '../../models/spot-columns.models';
import {
  SpotplanAllocation,
  SpotplanSchedule,
  SpotplanWeekBreakdown,
} from 'src/app/classes/spotplan-schedule';
import { isNotNullOrUndefined } from '@telmar-global/tup-document-storage';
import {
  RenameItemType,
  RenameMediaDialogModel,
} from 'src/app/components/dialogs/rename-media-dialog/rename-media-dialog.component';
import { SpotSettingsDialogModel } from 'src/app/components/dialogs/spot-settings-dialog/spot-settings-dialog.component';
import { EMPTY_DAYPART_RESULTS } from 'src/app/classes/daypart-result';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { SelectionOption } from 'src/app/components/simple-selection/simple-selection.component';
import { GAEvents } from '../../models/analytics.model';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { VirtualScrollTableDirective } from 'src/app/components/tree-table/virtual-scroll/virtual-scroll-table.directive';
import { QuestionDialogModelOptions } from 'src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { StatusSnackbarIcon } from 'src/app/components/dialogs/snackbar-generic/snackbar-generic.component';

export interface SpotplanView {
  name: string;
  scheduleName: string;
  spotplanId: string;
  scheduleId: string;
  checked: boolean;
}

//const WHOLE_WEEK_GROUP: string = 'all weeks';

@Component({
  selector: 'spot-step',
  templateUrl: './spot-step.component.html',
  styleUrls: ['./spot-step.component.scss'],
})
export class SpotStepComponent extends BaseStepComponent implements OnInit {
  @Input() visible: boolean = true;
  @Input() viewMode: ViewMode = ViewMode.Tables;
  @Input() activeScheduleIndex: number; // index into the schedules array, or -1 to show all schedules

  @Output() surveyBarUpdate: EventEmitter<SurveyMetrics> =
    new EventEmitter<SurveyMetrics>();
  @Output() planUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('totalsRow') totalsRow: TemplateRef<any>;
  @ViewChild('nonEditableCell') nonEditableCell: TemplateRef<any>;
  @ViewChild('spotTabGroup') spotTabGroup: MatTabNav;
  @ViewChild('spotTable') spotTable: TreeTableComponent;
  @ViewChild(VirtualScrollTableDirective)
  virtualScrollTable: VirtualScrollTableDirective;

  label: string = 'spots';
  currentTarget: number = 0;

  screenSpotplans: SpotplanView[] = []; // represents the schedules/spotplans tab set
  screenSpotplanIndex: number = 0; // index into the above array

  selectedTargets: Target[] = [];
  tableData: TreeTableNode[] = [];

  _processing: boolean = false;
  get processing(): boolean {
    return this._processing;
  }
  set processing(value: boolean) {
    this._processing = value;
    this.checkReady();
  }

  dirty: boolean = false;

  treeTitle: string = '';
  tableExpanded: boolean = true;
  spotFilterOptions: SelectionOption[] = [];

  surveyBarSurvey: SurveyMetrics;
  compareOptions = compareOptions;
  currentCompareOption: string = this.compareOptions[0].data;
  targetTooltips: string[] = [];
  unitsText: string;
  spotColumns: TreeTableColumn[];
  allSpotColumns: TreeTableColumn[];
  tableColumns: TreeTableColumn[] = [];
  columnGroups: ColumnGroup[];
  schedules: Schedule[];

  scheduleTooltip: string = '';

  get targets(): Target[] {
    return this.mediaplannerService.plan.targets.filter(
      (target) => target.planningTarget
    );
  }

  get allTargets(): Target[] {
    return this.mediaplannerService.plan.targets;
  }

  get targetInView(): Target {
    return this.allTargets[this.currentTarget];
  }

  get currentSpotplan(): SpotplanSchedule {
    const res = this.getSpotplanScheduleFromIndex(this.screenSpotplanIndex);
    return res.spotplan;
  }

  get currentSchedule(): Schedule {
    const res = this.getSpotplanScheduleFromIndex(this.screenSpotplanIndex);
    return res.schedule;
  }

  get spotplans() {
    return this.currentSchedule.spotplans.spotplans;
  }

  get internalSurvey(): SurveyMetrics {
    const { primarySurvey } = this.mediaplannerService.plan;
    const tgt = this.targetInView;
    return {
      surveyCode: primarySurvey.code,
      authorizationGroup: primarySurvey.authorizationGroup,
      surveyTitle: primarySurvey.title,
      copyrightInfo: primarySurvey.copyrightInfo || '',
      surveyInfo: primarySurvey.surveyInfo || '',
      provider: primarySurvey.provider,
      targetTitle: tgt?.title,
      population: tgt?.population,
      sample: tgt?.sample,
      units: this.mediaplannerService.plan.surveyMetaData.meta(
        primarySurvey.code
      ).reportUnits,
    };
  }

  get collapsedColumnsNumber() {
    return this.tableColumns.filter((col) => col.collapsed).length;
  }

  get expandedColumnsNumber() {
    return this.tableColumns.filter(
      (col) =>
        (col.collapsed === false || col.collapsed === undefined) &&
        col.hidden === false
    ).length;
  }

  headerInlineMenu: TreeTableMenuItem[] = [
    { label: 'Add/remove columns', data: 'columns|edit' },
  ];

  constructor(
    private router: Router,
    private mediaplannerService: MediaPlannerService,
    private snackbarService: SnackbarService,
    private planningService: PlanningService,
    private engineService: EngineService,
    private dialogService: DialogService,
    private analyticsService: TupAnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    // this.populateBroadcasts();
    // this.buildTargetsTooltips();

    this.spotColumns =
      this.mediaplannerService.plan.spotColumns.getVisibleSpotColumns();
    this.columnGroups =
      this.mediaplannerService.plan.spotColumns.columnSpotGroups;
  }

  loadData() {
    this.schedules = this.mediaplannerService.plan.schedules;

    super.loadData();
    this.buildOnScreenSpotplans();
    this.tableColumns = [];
    this.spotColumns =
      this.mediaplannerService.plan.spotColumns.getVisibleSpotColumns();
    this.allSpotColumns =
      this.mediaplannerService.plan.spotColumns.getAllSpotColumns();
    this.columnGroups =
      this.mediaplannerService.plan.spotColumns.columnSpotGroups;
    this.unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
      this.mediaplannerService.plan.currentSurvey.code
    ).reportUnitText;
    this.selectedTargets = [this.allTargets[this.currentTarget]];

    // build spot filter options
    this.buildScheduleFilterOptions();
    const currSpotplan = this.currentSpotplan;
    this.treeTitle = `Weeks (${currSpotplan.weekCount})`;

    this.buildTableColumns();
    this.populateBroadcasts();
    this.surveyBarSurvey = this.internalSurvey;

    this.buildTargetsTooltips();
    this.buildScheduleChipTooltip(this.surveyBarSurvey);

    this.checkReady();
  }

  private initialiseVirtualTable() {
    this.virtualScrollTable
      ? this.virtualScrollTable.viewportUpdated(true)
      : null;
  }

  private buildOnScreenSpotplans() {
    this.screenSpotplans = [];

    const schedules =
      this.activeScheduleIndex === -1
        ? this.mediaplannerService.plan.schedules
        : [this.mediaplannerService.plan.schedules[this.activeScheduleIndex]];

    this.screenSpotplanIndex = Math.min(
      this.screenSpotplanIndex,
      schedules[0].spotplans.spotplans.length - 1
    );

    schedules.forEach((schedule) => {
      const spotplans: SpotplanView[] = schedule.spotplans.spotplans.map(
        (spotplan, index) => {
          return {
            name: spotplan.name,
            scheduleName: schedule.name,
            spotplanId: spotplan.id,
            scheduleId: schedule.id,
            checked: true,
          };
        }
      );
      this.screenSpotplans.push(...spotplans);
    });
  }

  saveData(passive: boolean = false): Observable<boolean> {
    super.saveData(passive);
    return this.dirty === false ? of(true) : this.recalculateAll();
  }

  buildTableColumns() {
    this.tableColumns = [];

    // now duplicate each schedule column within each column group
    this.columnGroups.forEach((group) => {
      this.spotColumns.forEach((column) => {
        const spotColumn: TreeTableColumn = {
          ...column,
          columnDef: `${column.columnDef}_${group.name}`,
          group: { name: group.name },
          cell: (row) => {
            return row.data[`${column.columnDef}_${group.name}`];
          },
        };

        if (group.name === 'all weeks') {
          spotColumn.header2 = '(All weeks)';
        }

        this.tableColumns.push(spotColumn);
      });
    });
  }

  populateBroadcasts() {
    this.initActiveSpotplanTab();

    const { totalsEditable, daypartsEditable } = this.getEditableRows();
    const target: Target = this.selectedTargets[0];

    // firstly do the grand total row
    const grandTotalData = {};
    const currSpotplan = this.currentSpotplan;

    daysOfTheWeek.forEach((day) => {
      const totalResult = currSpotplan.getGrandTotalByDay(day, target.id);
      this.allSpotColumns.forEach((column) => {
        grandTotalData[`${column.columnDef}_${day}`] = totalResult[
          column.columnDef
        ]
          ? totalResult[column.columnDef]
          : 0;
      });
    });

    const vehicle = this.currentSchedule.vehicle(
      this.selectedTargets[0],
      currSpotplan.vehicleId
    );

    Object.assign(grandTotalData, this.getBroadcastWholeWeekTotal(vehicle));
    const totalSpots = vehicle.result.inserts;

    this.tableData = [
      {
        id: 'total',
        name: `Totals (${totalSpots} spots)`,
        data: { ...grandTotalData },
        rowCss: 'table-total-row',
        css: 'table-total-row',
        editable: totalsEditable,
        inlineTemplate: this.totalsRow,
        cellTemplate: this.nonEditableCell,
        children: [],
        skipSort: true,
      },
    ];

    // populate for each week
    for (let weekIndex = 0; weekIndex < currSpotplan.weekCount; weekIndex++) {
      // populate results by day
      const weekGroupData = {};
      daysOfTheWeek.forEach((day) => {
        const weekResult = currSpotplan.getWeekTotalByDay(
          weekIndex,
          day,
          target.id
        );

        this.allSpotColumns.forEach((column) => {
          weekGroupData[`${column.columnDef}_${day}`] = weekResult[
            column.columnDef
          ]
            ? weekResult[column.columnDef]
            : 0;
        });
      });

      Object.assign(
        weekGroupData,
        this.getBroadcastByWeekTotal(weekIndex, target.id)
      );

      this.tableData.push({
        id: `week|${weekIndex}`,
        name: `Week ${weekIndex + 1}`,
        css: 'table-title-row',
        rowCss: 'table-title-row',
        expanded: true,
        editable: totalsEditable,
        cellTemplate: this.nonEditableCell,
        data: { ...weekGroupData },
        children: [],
      });

      // build daypart rows
      daysOfTheWeek.forEach((day, dayIndex) => {
        const daypartsByDay = currSpotplan.getDaypartsForDay(day);

        daypartsByDay.forEach((daypart, daypartIndex) => {
          const nodeData = {};
          const result =
            daypart.result.result(weekIndex, target.id) ||
            EMPTY_DAYPART_RESULTS;

          this.allSpotColumns.forEach((column) => {
            nodeData[`${column.columnDef}_${day}`] =
              result[column.columnDef] || 0;
            nodeData[
              `${column.columnDef}_${Spot_Columns.Column_Group_AllWeeks.name}`
            ] = -1;
          });
          nodeData[`daypart_${day}`] = daypart.id;

          const startTime = this.formatHour(daypart.startTime);
          const endTime = this.formatHour(daypart.endTime);

          if (dayIndex === 0) {
            this.tableData[weekIndex + 1].children.push({
              id: `daypart|${weekIndex}|${daypart.id}`,
              name: `${startTime} - ${endTime}`,
              editable: daypartsEditable,
              cellTemplate: this.nonEditableCell,
              data: { ...nodeData },
            });
          } else {
            this.tableData[weekIndex + 1].children[daypartIndex].data = {
              ...this.tableData[weekIndex + 1].children[daypartIndex].data,
              ...nodeData,
            };
          }
        });
      });
    }

    this.spotTable.refresh.emit({ newData: this.tableData });

    setTimeout(() => {
      this.initialiseVirtualTable();
    });
  }

  private formatHour(inputString: string) {
    const regex = /(\d{2}):(\d{2}):(\d{2})/;
    const match = regex.exec(inputString);

    if (match) {
      let hours = parseInt(match[1], 10);
      const minutes = match[2];

      // Subtract 24 if hours are greater than or equal to 24
      if (hours >= 24) {
        hours -= 24;
      }

      // Add 0 if needed
      const formattedHours = hours.toString().padStart(2, '0');

      return `${formattedHours}:${minutes}`;
    } else {
      return '-';
    }
  }

  private initActiveSpotplanTab() {
    // open at the correct tab if clicked directly compare-media component
    if (this.mediaplannerService.plan.campaignSpotplan) {
      this.screenSpotplanIndex = this.screenSpotplans.findIndex(
        (sp) => sp.spotplanId === this.mediaplannerService.plan.campaignSpotplan
      );
      this.mediaplannerService.plan.campaignSpotplan = '';
    }
  }

  // extract results from the vehicle object to populate the first column group for the totals row: WHOLE_WEEK_GROUP
  getBroadcastWholeWeekTotal(vehicle: ScheduleVehicle) {
    const data = {};
    Spot_Columns.allBroadcastColumns.forEach((column) => {
      data[`${column.columnDef}_${Spot_Columns.Column_Group_AllWeeks.name}`] =
        vehicle.result[column.columnDef];
    });
    return data;
  }

  // extract results from the vehicle weeks object to populate the first column group for the weekly totals row: WHOLE_WEEK_GROUP
  getBroadcastByWeekTotal(weekIndex: number, targetId: string) {
    const wholeWeekTotal = this.currentSpotplan.getWeekTotal(
      weekIndex,
      targetId
    );
    const data = {};
    Spot_Columns.allBroadcastColumns.forEach((column) => {
      data[`${column.columnDef}_${Spot_Columns.Column_Group_AllWeeks.name}`] =
        wholeWeekTotal[column.columnDef];
    });
    return data;
  }

  getEditableRows(): {
    totalsEditable: { [columnDef: string]: boolean };
    daypartsEditable: { [columnDef: string]: boolean };
  } {
    const groups = [Spot_Columns.Column_Group_AllWeeks.name, ...daysOfTheWeek];

    // build the editable object for adding to the total rows
    const totalsEditable: { [columnDef: string]: boolean } = {};
    groups.forEach((group) => {
      Spot_Columns.allEditableBroadcastColumns.forEach((column) => {
        totalsEditable[`${column.columnDef}_${group}`] = true;
      });
    });

    // build the editable object for adding to the daypart rows.  the all week group column remains uneditable for dayparts
    const daypartsEditable: { [columnDef: string]: boolean } = {};
    groups.splice(0, 1); // exclude whole week group
    groups.forEach((group) => {
      Spot_Columns.allEditableBroadcastColumns.forEach((column) => {
        daypartsEditable[`${column.columnDef}_${group}`] = true;
      });
    });
    return { totalsEditable, daypartsEditable };
  }

  processInput(
    events: TreeTableEditEvent[],
    id: string[] = null
  ): Observable<ProcessInputResponse> {
    return new Observable((inputObservable) => {
      const handleComplete = (
        success: boolean,
        reachRequired: boolean,
        messages: string[] = []
      ) => {
        inputObservable.next({
          success,
          reachRequired,
          messages,
        });
        inputObservable.complete();
      };

      this.processing = true;
      const processList: Observable<any>[] = [];
      const weeksToProcess: number[] = [];
      const currSpotplan = this.currentSpotplan;

      events.forEach((event: TreeTableEditEvent) => {
        event.value = event.value || '0';
        const thousand: string = Intl
          ? new Intl.NumberFormat().format(1000).charAt(1)
          : ',';

        const value: number = parseFloat(
          '' + event.value.replace(thousand, '')
        );

        // columnDef: contains column and day   'inserts_monday'
        // total:   row.id === 'total'
        // week:    row.id === 'week|0'
        // daypart: row.id === 'daypart|0|daypart.id'
        // daypartId: row.data['daypart'_day]

        const [rowType, w] = (event.row.id as string).split('|');
        const week: number = typeof w === 'undefined' ? -1 : Number(w); // undefined means process all weeks together (WIP)

        if (week === -1) {
          weeksToProcess.push(...Array(currSpotplan.weekCount).keys());
        } else {
          weeksToProcess.push(week);
        }

        const [column, day] = event.columnDef.split('_');
        const daypartId = event.row.data[`daypart_${day}`];

        // single daypart, all dayparts of a certain day, or simply all dayparts
        const dayparts = daypartId
          ? [currSpotplan.dayparts.find((dp) => dp.id === daypartId)]
          : day === Spot_Columns.Column_Group_AllWeeks.name
          ? currSpotplan.dayparts
          : currSpotplan.getDaypartsForDay(day);

        if (event.type === 'edit') {
          currSpotplan.allocation = SpotplanAllocation.manual;
        }

        // fetch the spots for evaluation, either manually or by naturaldelivery
        processList.push(
          this.planningService.processSpotplanSchedule(
            this.targetInView,
            this.allTargets,
            this.currentSchedule,
            currSpotplan,
            dayparts,
            column,
            week,
            value
          )
        );
      });

      // run all the spot processing and collect errors/messages
      forkJoin(processList).subscribe((results: ProcessInputResponse[]) => {
        // determine any failures or user messages.
        const success = results.findIndex((res) => !res.success) == -1;
        const messages: string[] = results
          .filter((res) => res.messages.length)
          .map((res) => res.messages.join(', '));
        const reachRequired =
          results.findIndex((res) => res.reachRequired) !== -1;

        // evaluate R&F
        if (reachRequired) {
          const vehicle = this.targetInView.vehicle(currSpotplan.vehicleId);

          this.engineService
            .evaluateSingleBroadcastMediaplan(
              this.allTargets,
              vehicle,
              this.currentSchedule,
              currSpotplan,
              weeksToProcess
            )
            .pipe(tap(() => (this.processing = false)))
            .subscribe((res: EvaluateMediaPlanResponse) => {
              handleComplete(res.success, res.success);
            });
        } else {
          handleComplete(true, false);
        }
      });
    });
  }

  onSpotplanChange(spotplanView: SpotplanView) {
    this.buildTargetsTooltips();
    this.screenSpotplanIndex = this.screenSpotplans.findIndex(
      (spt) => spt.spotplanId === spotplanView.spotplanId
    );
    this.loadData();
  }

  checkReady() {
    const value: boolean = !this.processing;
    this.mediaplannerService.campaignStatus.readyForAudiences.next(value);
    this.mediaplannerService.campaignStatus.readyForMedia.next(value);
    this.mediaplannerService.campaignStatus.readyForPlanning.next(value);
    // To always be visible in the Spotplans screen
    this.mediaplannerService.campaignStatus.readyToShowSpot.next(true);
    this.mediaplannerService.campaignStatus.readyForSpot.next(value);
  }

  onCompareOptionChange(event: MatSelectChange) {
    this.currentCompareOption = event.value;
  }

  onToggleExpand() {
    this.tableExpanded = !this.tableExpanded;

    this.tableExpanded
      ? this.spotTable.expandAll()
      : this.spotTable.collapseAll();
  }

  showEditColumnsDialog() {
    this.dialogService
      .openEditSpotColumnsDialog({
        planColumnData: this.mediaplannerService.plan.spotColumns,
      })
      .afterClosed()
      .subscribe((dialogData) => {
        if (dialogData) {
          this.spotColumns = [...dialogData.columns];
          this.buildTableColumns();
        }
      });
  }

  onSettings(spotplanIndex: number) {
    // locate the spotplan (not always the current one)
    const res = this.getSpotplanScheduleFromIndex(spotplanIndex);
    const settingsSpotplan = res.spotplan;
    let spotplanChanged = false;

    this.dialogService
      .openSettingsSpotDialog({
        duration: settingsSpotplan.weekCount,
        allocation: settingsSpotplan.allocation,
        naturalDelivery: { ...settingsSpotplan.naturalDelivery },
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((dialogData: SpotSettingsDialogModel) => {
        if (dialogData.clearSpots) {
          this.clearSpotplan(res.schedule, res.spotplan);
          spotplanChanged = true;
          this.analyticsService.e(GAEvents.spots_inline_menu, {
            action: `spot_tabs|settings|clear spots`,
          });
        } else {
          spotplanChanged = dialogData.duration !== settingsSpotplan.weekCount;
          if (spotplanChanged) {
            this.updateWeekCounts(
              res.schedule,
              res.spotplan,
              dialogData.duration
            );
          }

          settingsSpotplan.allocation = dialogData.allocation;
          settingsSpotplan.naturalDelivery = { ...dialogData.naturalDelivery };

          if (
            dialogData.allocation === SpotplanAllocation.naturalDelivery &&
            dialogData.naturalDelivery.goal > 0
          ) {
            const editEvent: TreeTableEditEvent[] = [
              {
                type: 'natural',
                columnDef: `${dialogData.naturalDelivery.strategy}_all weeks`,
                row: this.tableData[0],
                value: dialogData.naturalDelivery.goal.toString(),
              },
            ];
            spotplanChanged = false; // turn off recalc as it'll be done after the natural delivery
            this.onSpotplanChange(this.screenSpotplans[spotplanIndex]); // There's work to do, so ensure spotplan is the current one
            this.onEdited(editEvent);
          }
        }
        this.analyticsService.e(GAEvents.spots_inline_menu, {
          action: `spot_tabs|settings|update spots`,
        });

        spotplanChanged ? this.recalculateAllAndRefresh() : this.loadData();
      });
  }

  clearSpotplan(schedule: Schedule, spotplan: SpotplanSchedule) {
    // parent vehicle (holding the all weeks total)
    this.allTargets.forEach((target) => {
      schedule.vehicle(target, spotplan.vehicleId).result.zeroResults();
    });

    // daypart and week totals
    spotplan.clearSpotplan();
  }

  updateWeekCounts(
    schedule: Schedule,
    spotplan: SpotplanSchedule,
    duration: number
  ) {
    spotplan.weekCount = duration; // property to tidy internal arrays

    // add duration change to all the ScheduleVehicle (broadcast total) objects
    this.allTargets.forEach((target) => {
      const vehicle = schedule.vehicle(target, spotplan.vehicleId);
      vehicle.result.addResults({ duration });
    });
  }

  onCopyToOtherMedia() {}

  onRenameSpotplan(spotplanIndex: number) {
    // locate the spotplan (not always the current one)
    const res = this.getSpotplanScheduleFromIndex(spotplanIndex);
    const selectedSpotplan = res.spotplan;

    const renameMediaConfig = new RenameMediaDialogModel();
    renameMediaConfig.initialName = this.targetInView.vehicle(
      selectedSpotplan.vehicleId
    ).originalTitle;
    renameMediaConfig.newName = this.targetInView.vehicle(
      selectedSpotplan.vehicleId
    ).title;
    renameMediaConfig.itemType = RenameItemType.spotplan;

    this.dialogService
      .openRenameMediaDialog(renameMediaConfig)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data: RenameMediaDialogModel) => {
        this.mediaplannerService.plan.renameVehicle(
          selectedSpotplan.vehicleId,
          data.newName
        );
        this.currentSpotplan.name = data.newName;
        this.loadData();
        this.analyticsService.e(GAEvents.spots_inline_menu, {
          action: `spot_tabs|rename spot`,
        });
      });
  }

  onDuplicateSpotplan(spotplanIndex: number) {}

  onDeleteSpotplan(spotplanIndex: number) {
    const res = this.getSpotplanScheduleFromIndex(spotplanIndex);

    this.deleteSpotplanConfirmation(res.spotplan).subscribe((yes: boolean) => {
      if (!yes) return;

      // clear the totals stored at the ScheduleVehicle level
      this.mediaplannerService.plan.targets.forEach((target) => {
        const veh = res.schedule.vehicle(target, res.spotplan.vehicleId);
        veh.result.zeroResults();
      });

      // delete spotplan itself
      res.schedule.spotplans.deleteSpotplan(res.spotplan.id);
      this.dirty = true;

      this.buildOnScreenSpotplans();

      this.analyticsService.e(GAEvents.spots_inline_menu, {
        action: `spot_tabs|delete spot`,
      });

      // if all spotplans have been removed navigate to planning step
      if (this.screenSpotplans.length === 0) {
        this.screenSpotplanIndex = 0;

        const docId = this.mediaplannerService.plan.documentId;
        if (docId && docId !== 'unsaved') {
          this.router.navigate([`edit/${docId}/data`], {
            queryParams: {
              tab: 2,
            },
          });
        } else {
          this.router.navigate(['new/data'], {
            queryParams: {
              tab: 2,
            },
          });
        }
        return;
      }

      // recheck the index now the array length changed
      this.screenSpotplanIndex = Math.min(
        this.screenSpotplanIndex,
        this.screenSpotplans.length - 1
      );
      this.loadData();
    });
  }

  deleteSpotplanConfirmation(spotplan: SpotplanSchedule): Observable<boolean> {
    const confirmationMessage = `Are you sure you want to delete the spotplan '${spotplan.name}'?`;
    const options: QuestionDialogModelOptions =
      this.dialogService.getDeleteConfirmationOptions(confirmationMessage);

    return this.dialogService
      .confirmation('', 'Are you sure?', options)
      .afterClosed()
      .pipe(map((button) => button.data === 'delete'));
  }

  showInputMessages(status: ProcessInputResponse) {
    if (status.messages.length) {
      const messages = [...new Set(status.messages)]; // remove duplicate messages
      this.snackbarService.showWarningSnackBar(messages.join(', '));
    }
  }

  // inline grid editing finished
  onEdited(events: TreeTableEditEvent[]): void {
    this.processInput(events).subscribe((status) => {
      this.showInputMessages(status);
      this.mediaplannerService.dirty();
      this.dirty = true;
      this.populateBroadcasts();
    });
  }

  onTreeHeaderMenuClick(item: NodeMenuClickEvent) {
    const [section, action] = item.item.data.split('|');

    if (section === 'columns') {
      this.showEditColumnsDialog();
    }
  }

  onTreeInlineMenuClick(item: NodeMenuClickEvent) {}

  onPlanningColumnMenuClick(item: ColumnMenuClickEvent) {}

  onTreeHeaderMainMenuClick() {}

  onTargetChange(target: number) {
    this.currentTarget = target;

    this.surveyBarSurvey = this.internalSurvey;
    this.surveyBarUpdate.emit(this.surveyBarSurvey);
    this.loadData();
  }

  /**
   * Performs a full Reach and Frequency of all targets and schedules in the plan
   * this assumes all target populations and vehicle audiences are correct and up to date
   *
   * @returns Observable<boolean>
   */
  recalculateAll(): Observable<boolean> {
    this.processing = true;
    return this.planningService
      .evaluateAllSchedules(
        this.allTargets,
        this.schedules,
        this.mediaplannerService.plan.vehicleGroups.groups,
        this.mediaplannerService.plan.columns.includeUniqueReach,
        false
      )
      .pipe(
        map((b: boolean[]) => !b.includes(false)),
        tap(() => {
          this.processing = false;
          this.dirty = false;
        })
      );
  }

  recalculateAllAndRefresh() {
    this.recalculateAll().subscribe((res) => {
      this.mediaplannerService.dirty();
      this.loadData();
    });
  }

  // tooltips for the target bar
  buildTargetsTooltips() {
    this.targetTooltips = [];

    this.targets.forEach((target) => {
      const data = this.mediaplannerService.plan.getTotalsPlanningData(
        'total',
        ScheduleTotalTag.total,
        target,
        this.currentSchedule
      );

      const primarySurvey = this.mediaplannerService.plan.primarySurvey;

      this.targetTooltips.push(
        `${primarySurvey.title} \n ${target.title} \n\ Reach: ${(<number>(
          data.reachPct
        )).toFixed(2)}% | Avg. Freq.: ${(<number>data.avgFrequency).toFixed(1)}`
      );
    });
  }

  buildScheduleChipTooltip(surveyMetrics: SurveyMetrics) {
    const formatFloat = (value: number): string => {
      return value.toLocaleString(undefined, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }); // "1,234.57"
    };

    this.scheduleTooltip = `${
      surveyMetrics.surveyTitle
    }\n Population: ${formatFloat(
      surveyMetrics.population
    )} | Sample: ${formatFloat(surveyMetrics.sample)}`;
  }

  // add the schedule list to the schedule filter dialoa
  buildScheduleFilterOptions() {
    this.spotFilterOptions = this.mediaplannerService.plan.schedules.map(
      (schedule, id) => {
        return {
          id,
          selected: this.activeScheduleIndex === id,
          label: schedule.name,
          disabled: !schedule.spotplans.spotplans.length,
        };
      }
    );

    //this.scheduleFilterOptions.push({ id: -1, label: 'All plans' });
  }

  // schedule filter has changed.
  onSpotFilter(option: SelectionOption) {
    if (option) {
      this.activeScheduleIndex = option.id as number;
      this.buildOnScreenSpotplans();
      this.populateBroadcasts();
    }
  }

  // from the spotplanIndex (all tabs), return the schedule and spotplan is represents
  getSpotplanScheduleFromIndex(spotplanIndex: number): {
    schedule?: Schedule;
    spotplan?: SpotplanSchedule;
  } {
    const { scheduleId, spotplanId } = this.screenSpotplans[spotplanIndex];
    const resultObject: { schedule?: Schedule; spotplan?: SpotplanSchedule } =
      {};

    resultObject.schedule = this.mediaplannerService.plan.schedules.find(
      (sch) => sch.id === scheduleId
    );
    resultObject.spotplan = resultObject.schedule.spotplans.spotplans.find(
      (spt) => spt.id === spotplanId
    );
    return resultObject;
  }
}
