<mat-form-field
  appearance="outline"
  class="number-incremental"
>
  <input
    matInput
    type="number"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
  />
  <div
    *ngIf="suffix"
    class="custom-suffix"
  >
    {{ suffix }}
  </div>
  <div
    fxLayout="column"
    matSuffix
  >
    <button
      mat-icon-button
      class="icon-btn"
      (click)="updateValue(value + 1)"
      data-tracking="increase_btn"
    >
      <mat-icon>arrow_drop_up</mat-icon>
    </button>
    <button
      mat-icon-button
      class="icon-btn"
      (click)="updateValue(value - 1)"
      data-tracking="decrease_btn"
    >
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
</mat-form-field>
