import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  NodeMenuClickEvent,
  TreeTableExpandEvent,
  TreeTableMenuItem,
  TreeTableNode,
} from '../../tree-table/tree-table.models';
import {
  CodebookService,
  MatchType,
  SearchIn,
} from '../../../services/codebook.service';
import { SelectionOption } from '../../simple-selection/simple-selection.component';
import { Observable, of } from 'rxjs';
import {
  CodebookSelectionService,
  DropDataContext,
  Statement as VisualCodeBookStatement,
} from '@telmar-global/tup-audience-groups';
import { AudienceGroupsService } from 'src/app/services/audience-groups.service';
import { EngineService } from 'src/app/services/engine.service';
import { NavigationLevel, Statement } from 'src/app/models/codebook.models';
import { TreeTableComponent } from '../../tree-table/tree-table.component';
import {
  CodebookStatement,
  CodebookTableComponent,
  PopulationEditEvent,
  PopulationMenuClickEvent,
} from '../../codebook-table/codebook-table.component';
import { DocumentSurvey, DocumentTarget } from 'src/app/models/document.model';
import { cloneDeep } from 'lodash';
import { ConverterService } from 'src/app/services/converter.service';
import { MultiTargetEvaluationResponse } from 'src/app/models/engine.target-evaluation.models';
import { CountCodingModel } from '../count-coding-dialog/count-coding-dialog.component';
import { CountCodingDialogService } from 'src/app/services/count-coding-dialog.service';
import { BreadcrumbsStepModel } from '../../breadcrumbs/breadcrumbs.component';
import {
  Operator,
  VisualCodingTarget,
} from 'src/app/models/visual-code-builder.models';
import {
  NewCodeBuilderDialogComponent,
  NewCodeBuilderDialogModel,
} from '../new-code-builder-dialog/new-code-builder-dialog.component';
import { SnackbarService } from '../../../services/snackbar.service';

export class TargetEditorDialogModel {
  dialogTitle: string;
  confirmButtonText: string;
  surveyHasAddressable: boolean = true;
  unitsText: string;
  documentTarget: DocumentTarget;
  survey: DocumentSurvey; // survey to use for treeview and coding
  primarySurvey: DocumentSurvey; // primary survey to use when disabling planning/addressable checkboxes
  defaultAddressableTarget: boolean = true;
  defaultPlanningTarget: boolean = false;
  constructor() {}
}

const CATEGORY_ALL = 'All categories';

interface OperatorButton {
  label: string;
  action: string;
  toolTip: string;
  labelType: string;
}

const AUTO_BUTTON: OperatorButton = {
  label: 'Auto',
  action: 'auto',
  toolTip: 'Combine selected into one audience',
  labelType: 'string',
};
const JOIN_BUTTON: OperatorButton = {
  label: 'join',
  action: 'booleanLogic',
  toolTip: 'Join selected',
  labelType: 'icon',
};

const SEARCH_OPTION_TOGGLE: SelectionOption = {
  id: 'search_toggle',
  selectedLabel: 'Search coding',
  label: 'Search titles ',
  selected: false,
};

const SEARCH_OPTION_OPTIONS: SelectionOption[] = [
  {
    id: MatchType.SearchAnyKeyword,
    label: 'Any keyword',
    selected: true,
  },
  {
    id: MatchType.SearchAllKeyword,
    label: 'All keywords',
  },
  {
    id: MatchType.SearchExactKeyword,
    label: 'Exact phrase',
  },
  {
    id: MatchType.SearchStartingKeyword,
    label: 'Starting with',
  },
];

@Component({
  selector: 'app-target-editor-dialog',
  templateUrl: './target-editor-dialog.component.html',
  styleUrls: ['./target-editor-dialog.component.scss'],
})
export class TargetEditorDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('codingTree') codingTree: TreeTableComponent;
  @ViewChild('codebookTable') codebookTable: CodebookTableComponent;
  @ViewChild('treeContainer') treeContainer: ElementRef;

  // searching
  treeSearch: string = '';
  searching: boolean = false;

  // array of operators to disply between the tree and the target selection table
  operators: OperatorButton[] = [AUTO_BUTTON, JOIN_BUTTON];

  dialogTitle: string = '';
  confirmButtonText: string = '';

  advancedSearchOptions = {
    titleCodingToggle: SEARCH_OPTION_TOGGLE,
    options: SEARCH_OPTION_OPTIONS,
    searchInToggle: SEARCH_OPTION_TOGGLE.selected
      ? SearchIn.Codes
      : SearchIn.Titles,
    selectedOption: SEARCH_OPTION_OPTIONS.find((o) => o.selected).id as string,
  };

  booleanLogicOptions: string[] = ['and', 'or', 'not', 'count'];

  // categories for tree
  categories: string[] = [];
  currentCategory: string = '';

  // coding statements, target and survey
  currentSurvey: DocumentSurvey;
  targetStatements: CodebookStatement[] = []; // used by the code-book-table
  planningTargets: DocumentTarget[] = []; // used here to keep a list of targets being created / designed
  visualEditingTarget: VisualCodingTarget; // target object for the visual editor component inside the code-book-table
  visualEditingDocumentTarget: DocumentTarget; // DocumentTarget representing it's changes

  surveyHasAddressable: boolean = false;
  unitsText: string;

  // tree data and tree filter
  fullTreeData: TreeTableNode[] = [];
  treeData: TreeTableNode[] = [];
  treeFilter: string = '';
  preSelectedNodes: TreeTableNode[] = []; // items selected in the tree, not yet pushed to the table
  _allPreSelectedNodes: Set<TreeTableNode> = new Set<TreeTableNode>(); // all selected nodes spanning category or search changes

  // fetch full list of pre selected nodes including saved nodes
  get allPreSelectedNodes(): TreeTableNode[] {
    this.backupPreSelectedNodes();
    return Array.from(this._allPreSelectedNodes);
  }

  loadingAudiences: boolean = false;

  breadcrumbsSteps: BreadcrumbsStepModel[] = [
    { title: 'Apply addressable audience', isVisible: true, isClickable: true },
    { title: 'Create new addressable audience', isVisible: true },
  ];

  breadcrumbsCurrentStep: number = 1;

  constructor(
    public dialogRef: MatDialogRef<TargetEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TargetEditorDialogModel,
    private codebookService: CodebookService,
    private audienceGroupsService: AudienceGroupsService,
    private engineService: EngineService,
    private visualCodebookService: CodebookSelectionService,
    private countCodingService: CountCodingDialogService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) {
    this.dialogRef.disableClose = true;
    this.dialogRef.id;
  }

  ngOnInit(): void {
    this.refresh();
  }

  ngAfterViewInit(): void {
    if (this.targetStatements.length)
      this.codebookTable.expandedElement = this.targetStatements[0];
  }

  refresh() {
    this.surveyHasAddressable = this.data.surveyHasAddressable;
    this.currentSurvey = this.data.survey;
    this.unitsText = this.data.unitsText;
    this.dialogTitle =
      this.data.dialogTitle || 'Create new addressable audience';
    this.confirmButtonText =
      this.data.confirmButtonText || 'Save new addressable audience';

    this.planningTargets = this.data.documentTarget
      ? [this.data.documentTarget]
      : [];
    this.visualEditingTarget = this.data.documentTarget
      ? ConverterService.buildVisualCodebookTarget(this.data.documentTarget)
      : null;

    this.visualEditingDocumentTarget = this.visualEditingTarget
      ? ConverterService.buildDocumentTargetFromVisualCodebook(
          this.visualEditingTarget,
          this.currentSurvey,
          this.data.defaultPlanningTarget,
          this.data.defaultAddressableTarget
        )
      : null;

    // build target statements for the targets selected table
    this.targetStatements = this.planningTargets.map((target, id) => {
      return {
        id,
        target: target,
        addressableTarget: target.addressableTarget,
        planningTarget: target.planningTarget,
      };
    });

    // fetch categories and populate first level of the tree
    this.fetchPartialTree(null).subscribe((tree) => {
      this.treeData = tree;
      this.treeSearch = '';

      // load any audience groups using the service and populate the tree correctly
      this.loadAudienceGroups(this.treeData, false).subscribe(() => {
        // build the categories dropdown from the first level of the tree.
        this.categories = this.buildCategories(this.treeData);
        this.categories.unshift(CATEGORY_ALL);
        this.currentCategory = CATEGORY_ALL;

        this.applySelected(this.treeData, this.allPreSelectedNodes);

        this.fullTreeData = cloneDeep(this.treeData); // keep a copy of the complete tree so searching and filtering can be done
        this.codingTree.refresh.emit({ newData: this.treeData });
      });
    });
  }

  // dropping on the visual editor - supply the extra target(s) to the existing visualEditingTarget
  onVisualEditingDropNode(event: DropDataContext<Operator>) {
    const selectedNodes: VisualCodeBookStatement[] = this.allPreSelectedNodes
      .filter((node) => node.id)
      .map((node) => {
        return {
          description: node.name,
          path: node.name,
          coding: node.data.coding,
          children: [],
        };
      });

    this.visualCodebookService.setSelectedNodes(selectedNodes);
    event.handleDropNode(Operator.or, selectedNodes);
    this.unSelectAll();
  }

  // load audience groups and add to start of treedata
  loadAudienceGroups(
    treeData: TreeTableNode[],
    forceReload: boolean
  ): Observable<boolean> {
    return new Observable((ob) => {
      treeData = treeData || [];
      this.audienceGroupsService
        .getAudienceGroupsForTree(this.currentSurvey.code, forceReload)
        .subscribe((codes) => {
          if (codes) {
            codes.ownCodes.css = 'owncodes-label';

            // first level is User, Company, etc
            codes.ownCodes.children.forEach((child) =>
              child.children.forEach((group) => (group.checkbox = true))
            );

            // remove an old codes node
            let index = treeData.findIndex(
              (node) => node.name === codes.ownCodes.name
            );
            if (index !== -1) treeData.splice(index, 1);

            // add new structures
            treeData.unshift(codes.ownCodes);
          }

          ob.next(true);
          ob.complete();
        });
    });
  }

  // build a unique list for the categories dropdown
  private buildCategories(tree: TreeTableNode[]): string[] {
    const cats = tree.map((level0) => level0.data.category);
    return [...new Set(cats)];
  }

  // the currently visually edited target has changed, convert progress to a DocumentTarget
  onVisualEditorTargetChanged(visualTarget: VisualCodingTarget) {
    this.visualEditingDocumentTarget =
      ConverterService.buildDocumentTargetFromVisualCodebook(
        visualTarget,
        this.currentSurvey,
        this.data.defaultPlanningTarget,
        this.data.defaultAddressableTarget
      );

    if (this.data.documentTarget && this.data.documentTarget.customPopulation) {
      this.visualEditingDocumentTarget.customPopulation =
        this.data.documentTarget.customPopulation;
    }
  }

  // force load audience groups and add to top of existing tree
  updateAudienceGroupsInTree() {
    this.loadAudienceGroups(this.treeData, true).subscribe(() => {
      this.fullTreeData = cloneDeep(this.treeData); // keep a copy of the complete tree so searching and filtering can be done
      this.codingTree.refresh.emit({ newData: this.treeData });
    });
  }

  // codebooktable had something dropped on it.  Build further menu or process codes
  onDropCodebookItem(event: DragEvent) {
    this.onOperatorClick(AUTO_BUTTON);
  }

  /**
   * Combines the target of the preselected nodes with each selected codebook statements applying the auto logic
   * and updates the data in the table
   *
   * @param selectedCodebookStatements Array of selected codebook statements
   * @param treeTarget Document target created from the preselected nodes
   */
  applyAutoLogicBetweenTargetAndEachSelectedRow(
    treeTarget: DocumentTarget,
    selectedCodebookStatements: CodebookStatement[]
  ) {
    const updatedTargets: DocumentTarget[] = selectedCodebookStatements.map(
      (statement) => {
        const { title, coding, targets } = statement.target;
        const statementTargets = targets.map((target, index) => {
          if (index !== targets.length - 1) {
            return target;
          }
          return {
            ...target,
            operator: Operator.and,
          };
        });

        statementTargets.push(...treeTarget.targets);
        statementTargets[statementTargets.length - 1].operator = Operator.and;
        const updatedTarget = {
          title: `${title} ${Operator.and.toUpperCase()} ${treeTarget.title}`,
          coding: `${coding} ${Operator.and} ${treeTarget.coding}`,
          survey: this.currentSurvey,
          targets: statementTargets,
          jsonCoding: null,
          operator: Operator.and,
          population: -1,
          sample: -1,
        };

        this.targetStatements[statement.id] = this.getTargetStatement(
          updatedTarget,
          statement.id
        );
        return updatedTarget;
      }
    );

    this.engineService
      .getMultiTargetEvaluation(updatedTargets)
      .subscribe((res) => {
        if (res.status?.success) {
          this.planningTargets.push(...updatedTargets);
        }

        selectedCodebookStatements.forEach((statement) => {
          this.targetStatements[statement.id].error = !res.status?.success;
        });

        //this.saveData(true).subscribe();
      });
  }

  onBooleanLogicOperatorClick(booleanOperation: string) {
    // filter out any parents with checkboxes (not actual data to be added)
    this.setPreSelectedNodes(
      this.allPreSelectedNodes.filter((node) => node.id)
    );
    if (this.preSelectedNodes.length === 0) return;

    let operator: Operator;
    switch (booleanOperation) {
      case 'and':
        operator = Operator.and;
        break;
      case 'or':
        operator = Operator.or;
        break;
      case 'not':
        operator = Operator.andNot;
        break;
      case 'count':
        operator = Operator.count;
        break;
      default:
        break;
    }

    const selectedOperator =
      operator === Operator.count
        ? this.countCodingService.openCountCoding()
        : of(null);
    selectedOperator.subscribe((countCoding: CountCodingModel) => {
      // filter out any parents with checkboxes (not actual data to be added)
      this.setPreSelectedNodes(
        this.allPreSelectedNodes.filter((node) => node.id)
      );
      if (this.preSelectedNodes.length === 0) return;

      const selectedNodes: VisualCodeBookStatement[] = this.allPreSelectedNodes
        .filter((node) => node.id)
        .map((node) => {
          return {
            description: node.name,
            path: node.name,
            coding: node.data.coding,
            category: node.parent.data.key,
            children: [],
          };
        });

      if (selectedNodes.length) {
        if (countCoding) {
          this.codebookTable.visualCodeBuilder.addStatementsWithCountCoding(
            selectedNodes,
            countCoding
          );
        } else {
          this.codebookTable.visualCodeBuilder.addStatementsWithBooleanOperator(
            selectedNodes,
            operator
          );
        }
        this.unSelectAll();
        return;
      }
    });
  }

  /**
   * If starting with an empty target (no VCB showing) This function will calculate the intial target audience and set up the VCB for displaying
   *
   * @param nodes Nodes to include in the audience calc
   * @param survey Survey to tab against
   * @param operator Operator to use when combining nodes array
   * @returns VisualCodingTarget ready for VCB
   */
  CalculateFirstTimeAudience(
    target: DocumentTarget,
    survey: DocumentSurvey,
    operator: Operator
  ): Observable<VisualCodingTarget> {
    return this.visualEditingTarget
      ? of(this.visualEditingTarget)
      : new Observable((observable) => {
          target.population = -1;
          target.sample = -1;

          // add final work to the coding grid
          const statement = this.getTargetStatement(target, 0);
          this.targetStatements = [statement];

          this.engineService
            .getMultiTargetEvaluation([statement.target])
            .subscribe(
              (res: MultiTargetEvaluationResponse) => {
                statement.error = !res.status.success;
                if (res.status.success) {
                  this.planningTargets = [target];

                  this.visualEditingTarget =
                    ConverterService.buildVisualCodebookTarget(target);
                  this.codebookTable.expandedElement = statement;
                  observable.next(this.visualEditingTarget);
                  observable.complete();
                }
              },
              (err) => {
                console.log(err);
                observable.next(null);
                observable.complete();
              }
            );

          // const target = ConverterService.buildDocumentTargetByBooleanOperator(
          //   nodes,
          //   survey,
          //   operator
          // );
          // target.population = -1;
          // target.sample = -1;
          // target.addressableTarget = this.data.defaultAddressableTarget;
          // target.planningTarget = this.data.defaultPlanningTarget;

          // // add final work to the coding grid
          // const statement = this.getTargetStatement(target, 0)
          // this.planningTargets = [target];
          // this.targetStatements = [this.getTargetStatement(target, 0)]

          // this.engineService.targetEvaluation(statement.target).subscribe(
          //   (res) => {
          //     statement.error = !res.status.success;
          //     if (res.status.success) {
          //       this.visualEditingTarget = ConverterService.buildVisualCodebookTarget(target);
          //       this.codebookTable.expandedElement = statement;
          //       observable.next(this.visualEditingTarget);
          //       observable.complete();
          //   }
          //   },
          //   (err) => {
          //     console.log(err);
          //     observable.next(null);
          //     observable.complete();
          //   }
          //);
        });
  }

  /**
   * Combines the preselected nodes with each selected codebook statement using boolean logic
   *
   * @param selectedCodebookStatements Array of selected codebook statements
   * @param booleanOperator Boolean operator that should be applied for the logic between the targets
   * @returns Array of DocumentTarget containing the combined data
   */
  combineSelectedTreeNodesAndCodebookStatements(
    selectedCodebookStatements: CodebookStatement[],
    booleanOperator: Operator,
    countCoding: CountCodingModel
  ): DocumentTarget[] {
    const updatedTargets: DocumentTarget[] = [];
    const selectedNodesDocumentTargets = this.preSelectedNodes.map((node) =>
      ConverterService.buildDocumentTargetByBooleanOperator(
        [node],
        this.currentSurvey,
        booleanOperator,
        this.data.defaultPlanningTarget,
        this.data.defaultAddressableTarget,
        countCoding
      )
    );

    const documentTarget: DocumentTarget = {
      title: selectedNodesDocumentTargets
        .map((target) => target.title)
        .join(` ${booleanOperator.toUpperCase()} `),
      coding: selectedNodesDocumentTargets
        .map((target) => target.coding)
        .join(` ${booleanOperator} `),
      survey: this.currentSurvey,
      targets: selectedNodesDocumentTargets
        .map((target) => target.targets)
        .reduce((acc, targets) => [...acc, ...targets], []),
      jsonCoding: selectedNodesDocumentTargets[0].jsonCoding,
    };

    selectedCodebookStatements.forEach((statement) => {
      const codebookStatementTargets = statement.target.targets;
      const updatedTarget: DocumentTarget = {
        title: `${statement.target.title} ${booleanOperator.toUpperCase()} ${
          documentTarget.title
        }`,
        coding: `${statement.target.coding} ${booleanOperator} ${documentTarget.coding}`,
        survey: this.currentSurvey,
        targets: [...codebookStatementTargets, ...documentTarget.targets],
        jsonCoding: documentTarget.jsonCoding,
        operator: booleanOperator,
        population: -1,
        sample: -1,
      };

      codebookStatementTargets[codebookStatementTargets.length - 1].operator =
        booleanOperator;

      this.targetStatements[statement.id] = this.getTargetStatement(
        updatedTarget,
        statement.id
      );
      updatedTargets.push(updatedTarget);
    });

    return updatedTargets;
  }

  private getTargetStatement(
    target: DocumentTarget,
    id: number
  ): CodebookStatement {
    return {
      id,
      target,
      addressableTarget: this.data.defaultAddressableTarget,
      planningTarget: this.data.defaultPlanningTarget,
      rename: false,
      editing: false,
    };
  }

  // target change event from coding table
  onTargetsChanged(statements: CodebookStatement[]) {
    this.planningTargets = statements
      .filter((statement) => !statement.error)
      .map((statement) => statement.target);
  }

  // clicks resulting from the above openMenu()  from the dragMenu
  onDragMenuClick(menu: TreeTableMenuItem) {
    switch (menu.data) {
      case 'auto':
        this.onOperatorClick(AUTO_BUTTON);
        break;
      default:
        break;
    }
  }

  // category dropdown change
  onCategoryChange(category: string) {
    this.backupPreSelectedNodes();

    if (category === CATEGORY_ALL) {
      this.treeData = cloneDeep(this.fullTreeData);
    } else {
      //select multi categories
      const categories = this.fullTreeData.filter(
        (cats) => cats.data.category === category
      );
      this.treeData = cloneDeep(categories);
    }
    this.applySelected(this.treeData, this.allPreSelectedNodes);
    this.codingTree.refresh.emit({ newData: this.treeData });

    // expand first level
    if (category != CATEGORY_ALL && this.treeData.length === 1) {
      this.codingTree.expandNode(this.treeData[0], true);
    }
  }

  resetSearch() {
    this.onSearchClick('');
    this.unSelectAll();
    this.treeContainer.nativeElement.scrollTo(0, 0);
  }

  // search or search reset click
  onSearchClick(searchTerm: string) {
    this.treeSearch = searchTerm;
    this.backupPreSelectedNodes();

    // if search term is empty, show whole tree according to category
    if (!searchTerm) this.onCategoryChange(this.currentCategory);
    else {
      this.searching = true;

      // pass an array of categories, or an empty string if searching within All Categories
      const catName: string | string[] =
        this.currentCategory === CATEGORY_ALL
          ? ''
          : this.treeData
              .filter((node) => node.data.category === this.currentCategory)
              .map((node) => node.data.key);

      this.codebookService
        .search(
          searchTerm,
          catName,
          this.currentSurvey,
          <MatchType>this.advancedSearchOptions.selectedOption,
          <SearchIn>this.advancedSearchOptions.searchInToggle
        )
        .subscribe((searchResult) => {
          if (searchResult) {
            const parent = this.createTreeDataAfterSearch(searchResult.tree);
            this.treeData = [...parent.children];

            this.applySelected(this.treeData, this.allPreSelectedNodes);
            this.codingTree.refresh.emit({ newData: this.treeData });

            // User needs a warning about missing search results
            // if (searchResult.useTopLevelCategories) {
            //   const snackModel: SnackbarOptionsModel = {
            //     icon: StatusIcon.Info,
            //     message: `${searchResult.status.hits.toLocaleString()} matches found so not all results could be displayed.  Consider searching within a category`,
            //   };
            //   this.dialogService.showSnackBarCustomised(snackModel);
            // }

            this.codingTree.expandToSearchTerm(
              searchTerm,
              searchResult.useTopLevelCategories
            );
            // searchResult.status.hits < 300 ? this.codingTree.expandToSearchTerm( searchTerm, searchResult.useTopLevelCategories ) :
            //                                  this.codingTree.expandToLevel(1, searchResult.useTopLevelCategories);
          }
          this.searching = false;
        });
    }
  }

  // create tree nodes after a search
  private createTreeDataAfterSearch(statement: Statement): TreeTableNode {
    const parent: TreeTableNode = {
      name: statement.description,
      id: statement.coding || statement.id,
      data: { coding: statement.coding || '', parent: null },
      children: [],
    };

    if (statement.children)
      this.createTreeNodeAfterSearch(parent, statement.children);
    return parent;
  }

  // create tree nodes after a search
  private createTreeNodeAfterSearch(
    parent: TreeTableNode,
    children: Statement[]
  ) {
    parent.children = [];
    children.forEach((child) => {
      parent.children.push({
        name: child.description,
        id: child.coding || child.id,
        expandable: child.expandable,
        data: {
          coding: child.coding || '',
          parent: parent,
          jsonCoding: child.jsonCoding,
          key: child.key,
        },
        checkbox: !!child.coding,
        children: [],
      });

      if (child.children)
        this.createTreeNodeAfterSearch(
          parent.children[parent.children.length - 1],
          child.children
        );
    });
  }

  private groupNodesByCategory(nodes: TreeTableNode[]) {
    const groupedArrays = [];

    nodes.forEach((node) => {
      const category = node.parent.data.key;
      const existingArray = groupedArrays.find(
        (arr) => arr[0]?.parent.data.key === category
      );

      if (existingArray) {
        existingArray.push(node);
      } else {
        groupedArrays.push([node]);
      }
    });

    return groupedArrays;
  }

  private buildAutoDocumentTarget(existingTarget: DocumentTarget) {
    const resultedTargets: DocumentTarget[] = [];
    const groupsOfDifferentCategories = this.groupNodesByCategory(
      this.allPreSelectedNodes
    );

    groupsOfDifferentCategories.forEach((group) => {
      const target = ConverterService.buildDocumentTarget(
        group,
        this.currentSurvey,
        this.data.defaultPlanningTarget,
        this.data.defaultAddressableTarget
      );
      if (group.length > 1) {
        resultedTargets.push(target);
      } else {
        target.targets = [];
        target.jsonCoding = null;
        resultedTargets.push(target);
      }
    });

    const targets = cloneDeep(existingTarget);
    if (this.allPreSelectedNodes.length === 1) {
      targets.targets = [];
    } else {
      targets.targets = resultedTargets;
    }

    return [targets];
  }

  // advanced search dropdown options
  onAdvancedSearchOptionChange(opened: boolean) {
    if (!opened) {
      this.advancedSearchOptions.searchInToggle = this.advancedSearchOptions
        .titleCodingToggle.selected
        ? SearchIn.Codes
        : SearchIn.Titles;
      this.advancedSearchOptions.selectedOption =
        this.advancedSearchOptions.options.find((o) => o.selected).id as string;
    }
  }

  addToVisualCodeBuilder(operator: Operator, nodes: TreeTableNode[]) {
    const selectedNodes: VisualCodeBookStatement[] = nodes
      .filter((node) => node.id)
      .map((node) => {
        return {
          description: node.name,
          path: node.name,
          coding: node.data.coding,
          children: [],
        };
      });

    if (selectedNodes.length) {
      this.codebookTable.visualCodeBuilder.addStatements(selectedNodes, true);
    }
  }

  // center action button click
  onOperatorClick(button: OperatorButton) {
    // join operator menu button, so ignore
    if (button.action === 'booleanLogic') return;

    // we have a target so add directly to the VCB
    if (this.targetStatements.length) {
      const selectedNodes: VisualCodeBookStatement[] = this.allPreSelectedNodes
        .filter((node) => node.id)
        .map((node) => {
          return {
            description: node.name,
            path: node.name,
            coding: node.data.coding,
            category: node.parent.data.key,
            children: [],
          };
        });

      if (selectedNodes.length && button.action === 'auto') {
        this.codebookTable.visualCodeBuilder.addStatements(selectedNodes, true);
      }
      this.unSelectAll();
      return;
    }
    // there is no current target so add if if we're using auto
    else {
      // filter out any parents with checkboxes (not actual data to be added)
      this.setPreSelectedNodes(
        this.allPreSelectedNodes.filter((node) => node.id)
      );
      if (this.preSelectedNodes.length === 0) return;

      const target = ConverterService.buildDocumentTarget(
        this.allPreSelectedNodes,
        this.currentSurvey,
        this.data.defaultPlanningTarget,
        this.data.defaultAddressableTarget
      );
      target.population = -1;
      target.sample = -1;

      const resultedTargets = this.buildAutoDocumentTarget(target);
      target.targets = resultedTargets;
      if (resultedTargets[0].targets.length > 1) {
        target.coding = `(${target.coding})`;
      }

      this.CalculateFirstTimeAudience(
        target,
        this.data.survey,
        Operator.or
      ).subscribe((visualTarget) => {
        this.visualEditingDocumentTarget =
          ConverterService.buildDocumentTargetFromVisualCodebook(
            visualTarget,
            this.currentSurvey,
            this.data.defaultPlanningTarget,
            this.data.defaultAddressableTarget
          );

        this.unSelectAll();
      });
    }
  }

  // called from tree when nodes are checked or unchecked.
  // Filter the unchecked and remove from the allSelectedNodes array
  onSelectedNodesChanged(nodes: TreeTableNode[]) {
    this.removeFromPreSelectedNodes(
      nodes.filter((node) => node.checkbox && !node.checked)
    );
  }

  // context menu in the tree nodes.  Used for renaming owncodes
  onTreeNodeMenuClick(event: NodeMenuClickEvent) {
    const [action, documentId, targetId] = event.item.data.split('_');

    // own codes rename required
    if (action === 'owncoderename') {
      this.audienceGroupsService
        .editOwnCodesTargetTitle(documentId, parseInt(targetId))
        .subscribe(() => {
          this.updateAudienceGroupsInTree();
        });
    }

    if (action === 'owncoderenamegroup') {
      this.audienceGroupsService
        .renameOwnCodeGroup(documentId)
        .subscribe(() => {
          this.updateAudienceGroupsInTree();
        });
    }

    if (action === 'owncodedelete') {
      this.audienceGroupsService
        .deleteOwnCodesWithConfirmation(documentId)
        .subscribe((success) => {
          if (success) this.updateAudienceGroupsInTree();
        });
    }
  }

  OnTreeExpand(event: TreeTableExpandEvent) {
    // set as expandable to enable populating children on demand.
    if (event.node.expandable) {
      this.fetchPartialTree(event.node).subscribe((branch) => {
        this.applySelected(branch, this.allPreSelectedNodes);
        event.insert(event.node, branch || []);
      });
    }
  }

  // call to get the requested level, return an observable in the TreeTable[] objects
  private fetchPartialTree(parent: TreeTableNode): Observable<TreeTableNode[]> {
    this.loadingAudiences = parent === null;

    return new Observable((observable) => {
      this.codebookService
        .getCodebookNavigation(
          this.currentSurvey,
          parent ? parent.data.key : null
        )
        .subscribe((nodes: NavigationLevel[]) => {
          this.loadingAudiences = false;
          const treeBranch = this.buildPartialTreeData(nodes, parent);
          observable.next(treeBranch);
          observable.complete();
        });
    });
  }

  // double click on a coding code, create a target from it
  onDoubleClick(node: TreeTableNode) {
    this.setPreSelectedNodes([node]);
    this.onOperatorClick(AUTO_BUTTON);
  }

  // convert MediaLevel (codebook) into TreeTableNode for the view, starting at the given TreeNode
  private buildPartialTreeData(
    nodes: NavigationLevel[],
    parentNode: TreeTableNode
  ): TreeTableNode[] {
    const parent: TreeTableNode = parentNode || { name: 'root' };
    parent.children = parent.children || [];

    nodes.forEach((node) => {
      parent.children.push({
        name: node.vehicle ? node.vehicle.title : node.name,
        id: node.id,
        data: {
          key: node.key,
          category: node.category || node.key,
          coding: node.id,
          jsonCoding: node.jsonCoding,
          parent,
        },
        expandable: node.type === 'node',
        checkbox: ['leaf', 'dayparts'].includes(node.type),
      });

      if (node.children && node.children.length) {
        parent.children[parent.children.length - 1].children =
          this.buildPartialTreeData(
            node.children,
            parent.children[parent.children.length - 1]
          );
        parent.children[parent.children.length - 1].expandable = false;
      }
    });

    if (parent.css) {
      this.applyCSS([parent], parent.css, true);
    }
    return parent.children;
  }

  // set the node list manually
  setPreSelectedNodes(nodes: TreeTableNode[]) {
    this.preSelectedNodes = nodes;
    this._allPreSelectedNodes.clear();
    nodes.length ? this.backupPreSelectedNodes() : null;
  }

  //using the node.id, remove items from the allSelected list
  removeFromPreSelectedNodes(nodes: TreeTableNode[]) {
    const idsToRemove = nodes.map((n) => n.id as string);

    this.allPreSelectedNodes.forEach((node) => {
      idsToRemove.includes(node.id as string)
        ? this._allPreSelectedNodes.delete(node)
        : null;
    });
  }

  unSelectAll() {
    this.codingTree.selectAll(false);
    this.setPreSelectedNodes([]);
  }

  private applyCSS(nodes: TreeTableNode[], css: string, andChildren: boolean) {
    nodes.forEach((node) => {
      node.css = css;

      if (node.children && node.children.length && andChildren)
        this.applyCSS(node.children, css, andChildren);
    });
  }

  private applySelected(tree: TreeTableNode[], selected: TreeTableNode[]) {
    const selectedIds = selected.map((s) => s.id as string);

    const applyCheckState = (node: TreeTableNode, ids: string[]) => {
      if (node.checkbox && node.id) {
        node.checked = ids.includes(node.id as string);
      }

      if (node.children && node.children.length) {
        node.children.forEach((child) => {
          applyCheckState(child, ids);
        });
      }
    };

    const applySelectedCheckState = (nodes: TreeTableNode[], ids: string[]) => {
      nodes.forEach((node) => {
        applyCheckState(node, ids);
      });
    };

    applySelectedCheckState(tree, selectedIds);
  }

  // merge the allSelected list with whatever is curently selected.
  backupPreSelectedNodes() {
    const preSelectedNodeIds = Array.from(this._allPreSelectedNodes).map(
      (allNodes) => allNodes.id
    );

    this.preSelectedNodes.forEach((node) => {
      if (!preSelectedNodeIds.includes(node.id as string)) {
        this._allPreSelectedNodes.add(node);
      }
    });
  }

  onBreadCrumbsStepChange(step: number) {
    if (step === 0) {
      this.onClose(false);
    }
  }

  private editCustomPopulation(targetIndex: number, population: number) {
    const targetStatement = this.targetStatements[targetIndex];
    population
      ? (targetStatement.target.customPopulation = Number(population))
      : delete targetStatement.target.customPopulation;

    this.planningTargets[targetIndex] = targetStatement.target;
  }

  // reset custom population to original imported population value
  onPopulationMenuClick(data: PopulationMenuClickEvent) {
    this.editCustomPopulation(data.targetIndex, 0);
  }

  // save custom population
  onPopulationEdited(data: PopulationEditEvent) {
    this.editCustomPopulation(data.targetIndex, data.customPopulation);
  }

  onManualCoding() {
    this.showManualCodingDialog().subscribe(
      (res: NewCodeBuilderDialogModel) => {
        if (res) {
          const target: DocumentTarget = {
            survey: res.survey,
            title: res.title,
            ownTitle: res.title,
            shortTitle: res.title,
            coding: res.coding,
            manual: true,
            jsonCoding: res.jsonCoding,
            addressableTarget: this.data.defaultAddressableTarget,
            planningTarget: this.data.defaultPlanningTarget,
            isMultiSurvey: false,
            operator: Operator.and,
            population: res.population,
            sample: res.sample,
          };
          target.targets = [cloneDeep(target)];
          this.planningTargets = [target];

          const statement = this.getTargetStatement(target, 0);
          this.targetStatements = [statement];
          this.visualEditingTarget =
            ConverterService.buildVisualCodebookTarget(target);
          this.codebookTable.expandedElement = statement;

          this.visualEditingDocumentTarget =
            ConverterService.buildDocumentTargetFromVisualCodebook(
              this.visualEditingTarget,
              this.currentSurvey,
              this.data.defaultPlanningTarget,
              this.data.defaultAddressableTarget
            );

          this.snackbarService.showSuccessSnackBar('Code successfully added');
        }
      }
    );
  }

  onManualCodingClick(target: VisualCodingTarget, isNew: boolean) {
    this.showManualCodingDialog(isNew ? null : target).subscribe(
      (codingModel: NewCodeBuilderDialogModel) => {
        if (codingModel) {
          const editedTarget: VisualCodingTarget = {
            ...target,
            title: codingModel.title,
            ownTitle: codingModel.title,
            shortTitle: codingModel.title,
            coding: codingModel.coding,
            manual: true,
            targets: [],
          };

          this.snackbarService.showSuccessSnackBar(
            `Code successfully ${isNew ? 'added' : 'updated'}`
          );

          isNew
            ? this.codebookTable.visualCodeBuilder.addManuallyEditedTarget(
                target,
                editedTarget
              )
            : this.codebookTable.visualCodeBuilder.updateManuallyEditedTarget(
                editedTarget
              );
        }
      }
    );
  }

  showManualCodingDialog(
    target?: VisualCodingTarget
  ): Observable<NewCodeBuilderDialogModel> {
    return new Observable((ob) => {
      const dialogData: NewCodeBuilderDialogModel = {
        survey: this.currentSurvey,
        coding: target ? target.coding : '',
        title: target ? target.title : '',
        confirmText: target ? 'Update' : 'Add',
        dialogtitle: target ? 'Edit own code' : 'Add own code',
        population: null,
        sample: null,
      };
      const options = {
        data: dialogData,
        width: '600px',
        closeOnNavigation: true,
        disableClose: true,
      };

      this.dialog
        .open(NewCodeBuilderDialogComponent, options)
        .afterClosed()
        .subscribe((res: NewCodeBuilderDialogModel) => {
          ob.next(res);
          ob.complete();
        });
    });
  }

  onClose(save: boolean) {
    if (save) {
      this.data.documentTarget = this.visualEditingDocumentTarget;
      this.data.documentTarget.title =
        this.codebookTable.visualCodeBuilder.rootTarget.title;
      this.data.documentTarget.planningTarget =
        this.planningTargets[0].planningTarget;
      this.data.documentTarget.addressableTarget =
        this.planningTargets[0].addressableTarget;
    }

    this.dialogRef.close(save ? this.data : null);
  }
}
