import { Injector, Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
  name: 'customPipe',
})
export class CustomPipe implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(value: any, token: Type<PipeTransform>, args: any[]): any {
    if (token) {
      const injector: Injector = Injector.create({
        name: 'CustomPipe',
        parent: this.injector,
        providers: [{ provide: token }],
      });

      const pipe: PipeTransform = injector.get(token);
      return pipe.transform(value, ...args);
    } else {
      return value;
    }
  }
}
