<ng-container *ngIf="data.type === 'online'; else offlineTemplate">
  <ng-container *ngTemplateOutlet="online"></ng-container>
</ng-container>

<ng-template #offlineTemplate>
  <ng-container *ngTemplateOutlet="offline"></ng-container>
</ng-template>

<ng-template #offline>
  <div
    fxFlexLayout="column"
    fxLayoutAlign="space-between center"
    class="internet-connection"
    data-tracking="offline-internet-connection-snackbar"
  >
    <div
      flex="80"
      fxLayout="row"
      fxLayoutAlign="space-around center"
    >
      <mat-icon class="wifi-icon">wifi_off</mat-icon>
      <p class="connection-message">
        The internet connection appears to be offline.
      </p>
    </div>
    <button
      mat-button
      (click)="snackBarRef.dismiss()"
    >
      OK
    </button>
  </div>
</ng-template>

<ng-template #online>
  <div
    fxFlexLayout="column"
    fxLayoutAlign="space-between center"
    class="internet-connection"
    data-tracking="online-internet-connection-snackbar"
  >
    <div
      flex="80"
      fxLayout="row"
      fxLayoutAlign="space-around center"
    >
      <mat-icon class="wifi-icon">wifi</mat-icon>
      <p class="connection-message">Internet connection is back.</p>
    </div>
    <button
      mat-button
      (click)="snackBarRef.dismiss()"
    >
      OK
    </button>
  </div>
</ng-template>
