<button
  mat-stroked-button
  color="primary"
  tupMatButton
  appearance="tiny"
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  (menuOpened)="onDropdownToggle(true)"
  (menuClosed)="onDropdownToggle(false)"
  class="trigger-btn"
>
  <mat-icon>{{ buttonIcon }}</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <div class="container">
    <div
      fxLayout="column"
      fxLayoutGap="8px"
      class="toggle-wrapper filter-container"
    ></div>

    <div
      class="options-wrapper filter-container"
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <div
        fxLayoutAlign="space-between center"
        (click)="$event.stopPropagation()"
      >
        <h2 class="title">{{ title }}</h2>

        <button
          mat-icon-button
          class="close-button"
          data-tracking="save-dialog-cancel-button"
          tupMatButton
          matTooltip="close"
          (click)="onConfirmation(false)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div
        class="selections"
        (click)="$event.stopPropagation()"
      >
        <label *ngIf="subTitle">{{ subTitle }}</label>
        <mat-option
          *ngFor="let option of options"
          [value]="option.id"
          (click)="option.selected = !option.selected"
        >
          <!-- single select radio button-->
          <mat-radio-button
            tupMatCheckbox
            appearance="small"
            color="primary"
            [checked]="option.selected"
            (change)="onRadioButtonChange(option, $event)"
            [disabled]="option.disabled"
            (click)="onMenuItemClick($event)"
          >
            {{
              option.selected && option.selectedLabel
                ? option.selectedLabel
                : option.label
            }}
          </mat-radio-button>
        </mat-option>
      </div>
    </div>
    <div>
      <mat-divider class="divider"></mat-divider>
      <div fxLayoutAlign="space-between center">
        <button
          tupMatButton
          mat-flat-button
          (click)="onConfirmation(false)"
        >
          Cancel
        </button>

        <button
          tupMatButton
          mat-raised-button
          color="primary"
          (click)="onConfirmation(true)"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</mat-menu>
