<div
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <h1 class="title">{{ title }}</h1>
    <button
      *ngIf="closeButton"
      (click)="onButtonClick(closeButton)"
      [matTooltip]="closeButton.caption"
      mat-icon-button
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content>
  <snackbar-generic
    *ngIf="data.snackbar"
    [data]="data.snackbar"
  >
  </snackbar-generic>

  <div
    *ngIf="message && message.length"
    class="message-container"
  >
    <p
      *ngFor="let line of message"
      [innerHTML]="line"
    ></p>
  </div>
</div>

<div
  mat-dialog-actions
  fxLayoutAlign="{{ buttonAlignment }} center"
>
  <button
    [class.mat-stroked-button]="!but.flat"
    [class.mat-flat-button]="but.flat"
    [attr.data-tracking]="but.data"
    mat-button
    tupMatButton
    [color]="but.flat ? 'primary' : ''"
    *ngFor="let but of buttons"
    (click)="onButtonClick(but)"
  >
    {{ but.caption }}
  </button>
</div>
