<div>
  <div
    class="title-row"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-icon
      class="title-icon"
      color="primary"
      >event_available</mat-icon
    >
    <tup-mat-title-bar title="Spot - Quick view"></tup-mat-title-bar>
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose(0)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <p>
    Media: <strong> {{ vehicle.title }} </strong>
  </p>

  <div fxLayout="row">
    <p fxFlex="50">
      Duration:
      <strong
        >{{ spotplan.weekCount }} week{{ spotplan.weekCount > 1 ? "s" : "" }}
      </strong>
    </p>
    <p fxFlex>
      Week breakdown: <strong>{{ breakdown }} </strong>
    </p>
  </div>
  <p>
    Distribution: <strong>Spots ({{ spotCount }})</strong>
  </p>
  <div fxLayout="row">
    <p fxFlex="50">
      Strategy: <strong>{{ strategy }} </strong>
    </p>
    <p fxFlex>
      Target: <strong>{{ goal }}</strong>
    </p>
  </div>
  <div>
    Allocation: <strong>{{ allocation }} </strong>
  </div>

  <mat-divider class="divider-spacing"></mat-divider>
  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-button
      (click)="onClose(0)"
    >
      Close
    </button>
    <div fxLayoutAlign="right">
      <button
        mat-flat-button
        (click)="onClose(2)"
        color="primary"
      >
        Go to spots
      </button>
    </div>
  </mat-dialog-actions>
</div>
