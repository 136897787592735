export enum ColumnState {
  expanded,
  collapsed,
}

export interface ColumnGroup {
  name: string;
  headerCss?: string;
  cellCss?: string;
  hidden?: boolean;
  count?: number;
  left?: number;
  width?: number;
  columnsDialogPosition?: number;
  disabled?: boolean;
}
