<div class="table-chart-container">
  <mat-table
    [dataSource]="dataSource"
    class="table-chart"
    matSort
  >
    <ng-container
      *ngFor="let column of targetColumns; let iCol = index"
      matColumnDef="{{ column.name }}"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-column-header"
      >
        <span class="column-header-title-label">{{ column.title }}</span>
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [matTooltip]="element.data[iCol]?.tooltip"
      >
        {{ element.data[iCol].value }}
      </td>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </mat-table>
</div>
